import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import usePrevious from 'use-previous'

export type Breadcrumb = {
    label: string
    link?: string
}

export type BreadcrumbsContextType = {
    breadcrumbs: Breadcrumb[]
    updateBreadcrumbs: (newBreadcrumbs: Breadcrumb[]) => void
}

type Props = {
    children: JSX.Element
}

/**
 * React context for breadcrumbs. Breadcrumbs must be formatted in the following way
 * [
 *   {
 *     label: 'Label to show'
 *     link: 'absolute/link/to/route/in/app'
 *   }
 * ]
 */
export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType>({
    breadcrumbs: [],
    updateBreadcrumbs: () => undefined,
})

/**
 * React context which wraps every component that needs a breadcrumbs.
 * Resets breadcrumbs to be empty on every page change. It is the page's job to override the breadcrumb with the hook `useBreadcrumbs`
 *
 * Must be used in the following way:
 *
 * <BreadcrumbsProvider>
 *
 *   <Breadcrumbs /> <--- displays the breadcrumb
 *
 *   <Router>
 *      <Users path="users" /> <--- useBreadcrumbs([{ label: 'Home' }]) -> Home
 *      <Profile path="users/:id" /> <--- useBreadcrumbs([{ label: 'Home', link: '/users' }, { label: 'Profile }]) -> Home / Profile
 *
 *      <Settings path="settings" /> <--- will not have breadcrumbs by default
 *   </Router>
 *
 * </BreadcrumbsProvider>
 */
const BreadcrumbsProvider = ({ children }: Props): JSX.Element => {
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
    const location = useLocation()
    const previousLocation = usePrevious(location.pathname)

    const updateBreadcrumbs = useCallback(
        (newBreadcrumbs: Breadcrumb[]) => {
            if (
                JSON.stringify(newBreadcrumbs) !== JSON.stringify(breadcrumbs)
            ) {
                setBreadcrumbs(newBreadcrumbs)
            }
        },
        [setBreadcrumbs, breadcrumbs]
    )

    useEffect(() => {
        if (previousLocation !== location.pathname) {
            setBreadcrumbs([]) // Resets breadcrumbs to be empty on every page change
        }
    }, [location.pathname, setBreadcrumbs, previousLocation])

    return (
        <BreadcrumbsContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>
            {children}
        </BreadcrumbsContext.Provider>
    )
}

export default BreadcrumbsProvider
