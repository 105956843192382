import React, { useState, useRef, PropsWithChildren, ChangeEvent } from 'react'
import { Tooltip } from 'reactstrap'

import Button, { Props as ButtonProps } from './Button'
import Icon, { SupportedIconName } from './Icon'

type Props = PropsWithChildren<{
    id?: string
    acceptableFileTypes: string
    onFileChange: (file: File | null) => void
    icon?: SupportedIconName
    tooltipLabel?: string
}> &
    ButtonProps

/**
 * Refactored version from chrome-extension
 *
 * @params acceptableFileTypes - example: `'.jpg,.png,.pdf'`
 * @params [tooltipLabel] - If specified, id prop should be specified
 * @params [icon] - If specified the button is displayed as the icon.
 */
const FileUploadButton = ({
    id,
    acceptableFileTypes,
    onFileChange,
    icon,
    tooltipLabel,
    children,
    ...buttonProps
}: Props): JSX.Element => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const fileRef = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        fileRef?.current?.click()
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event
        const file = target.files?.item(0)

        onFileChange(file || null)

        // Clear file, to allow for duplicate uploads
        target.value = ''
    }

    return (
        <>
            {icon ? (
                <Button
                    {...buttonProps}
                    data-test="file-upload-button"
                    id={id}
                    onClick={handleClick}
                    outline
                    block={false}
                    size="md"
                    className="border-0 bg-white text-primary"
                >
                    <Icon name={icon} />
                </Button>
            ) : (
                <Button
                    {...buttonProps}
                    data-test="file-upload-button"
                    id={id}
                    block={false}
                    onClick={handleClick}
                    size="md"
                >
                    {children}
                </Button>
            )}

            {tooltipLabel && id && (
                <Tooltip
                    data-test="file-upload-tooltip"
                    target={id}
                    isOpen={isTooltipVisible}
                    toggle={() => setIsTooltipVisible(!isTooltipVisible)}
                >
                    {tooltipLabel}
                </Tooltip>
            )}

            <input
                data-test="file-upload-button-input"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept={acceptableFileTypes}
                ref={fileRef}
            />
        </>
    )
}

export default FileUploadButton
