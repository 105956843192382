import { useCallback, useMemo } from 'react'
import useFetch, { CachePolicies } from 'use-http'

import { CosmosResource, ResponseError } from '../../types'

export type CovidFeatureTypes =
    | 'requireCollection'
    | 'requireVaccination'
    | 'collectVaccinationProof'
    | 'requireTestForUnvaccinated'

export type CovidVaccinationPolicies = {
    [key in CovidFeatureTypes]?: boolean // TODO: Remove optional when `requireTestForUnvaccinated` ships
}

export type VaccinationPolicies = {
    covid19: CovidVaccinationPolicies
}

/**
 * Response from vaccination policies
 * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__vaccinationpolicies_get
 */
export type VaccinationPoliciesResource = CosmosResource<VaccinationPolicies>

type PutVaccinationPoliciesParams = {
    policyId?: string
    payload: VaccinationPolicies
}

type UseVaccinationPoliciesResource = {
    isLoading: boolean
    isLoadingPolicyChange: boolean
    getVaccinationPolicies: () => Promise<
        VaccinationPoliciesResource | ResponseError
    >
    postVaccinationPolicy: (
        payload: VaccinationPolicies
    ) => Promise<VaccinationPoliciesResource | ResponseError>
    putVaccinationPolicy: ({
        policyId,
        payload,
    }: PutVaccinationPoliciesParams) => Promise<
        VaccinationPoliciesResource | ResponseError
    >
    vaccinationPolicies?: VaccinationPoliciesResource
    error?: Error | ResponseError
    errorSavingPolicy?: Error | ResponseError
}

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

/**
 * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__vaccinationpolicies_get
 *
 * @params spaceId - space alias of the target customer.
 * @params shouldGetOnMount - If true, getVaccinationPolicies is called on mount.
 */
export default function useVaccinationPoliciesResource(
    spaceId: string,
    shouldGetOnMount?: boolean
): UseVaccinationPoliciesResource {
    const {
        loading: isLoading,
        get: getVaccinationPolicies,
        response,
        data,
        error: networkError,
    } = useFetch(
        `/spaces/${spaceId}/vaccinationPolicies`,
        fetchOptions,
        shouldGetOnMount ? [] : undefined
    )

    const {
        loading: isLoadingPolicyChange,
        post: postVaccinationPolicy,
        put,
        data: policyChangeData,
        response: policyChangeResponse,
        error: policyChangeNetworkError,
    } = useFetch(`/spaces/${spaceId}/vaccinationPolicies`, fetchOptions)

    const error = useMemo(() => {
        const isNotYetCreated =
            data?.error?.errorId === 'VACCINATION_POLICIES_NOT_FOUND'
        return isLoading || isNotYetCreated
            ? undefined
            : data?.error || networkError
    }, [isLoading, data, networkError])

    const errorSavingPolicy = useMemo(
        () =>
            isLoadingPolicyChange
                ? undefined
                : policyChangeData?.error || policyChangeNetworkError,
        [isLoadingPolicyChange, policyChangeData, policyChangeNetworkError]
    )

    const vaccinationPolicies = useMemo<
        VaccinationPoliciesResource | undefined
    >(() => {
        if (policyChangeResponse.ok) {
            return policyChangeData
        }

        if (response.ok) {
            return data
        }

        return undefined
    }, [response, data, policyChangeResponse, policyChangeData])

    const putVaccinationPolicy = useCallback(
        async ({
            policyId,
            payload,
        }: PutVaccinationPoliciesParams): Promise<
            VaccinationPoliciesResource | ResponseError
        > => {
            return put(`/${policyId}`, payload)
        },
        [put]
    )

    return {
        isLoading,
        isLoadingPolicyChange,
        getVaccinationPolicies,
        postVaccinationPolicy,
        putVaccinationPolicy,
        vaccinationPolicies,
        error,
        errorSavingPolicy,
    }
}
