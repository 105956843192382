import React, { useState } from 'react'
import { Popover, PopoverBody } from 'reactstrap'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

const EmailCount = styled.span`
    font-weight: 500;
    color: #354fa1;

    :hover {
        cursor: pointer;
    }
`

const StyledPopover = styled(Popover)`
    .popover {
        max-width: 550px;
    }
`

const EmailWithPopover = ({ emails = [], index, handleTrackEvent }) => {
    const [popoverOpen, setPopoverOpen] = useState(false)

    const toggle = () => setPopoverOpen(!popoverOpen)

    const popoverText = emails.map((email) => <div key={email}>{email}</div>)
    const count = `(${emails.length})`
    const id = `email-${index}`

    return (
        <>
            <span>{emails[0]} </span>
            {emails.length > 1 && (
                <>
                    <EmailCount id={id} onClick={handleTrackEvent}>
                        {count}
                    </EmailCount>
                    <StyledPopover
                        modifiers={{
                            preventOverflow: { boundariesElement: 'viewport' },
                        }}
                        isOpen={popoverOpen}
                        placement="bottom"
                        target={id}
                        toggle={toggle}
                    >
                        <PopoverBody>{popoverText}</PopoverBody>
                    </StyledPopover>
                </>
            )}
        </>
    )
}

EmailWithPopover.propTypes = {
    emails: PropTypes.array,
    index: PropTypes.any,
    handleTrackEvent: PropTypes.func,
}

export default EmailWithPopover
