import { useEffect } from 'react'
import useFetch from 'use-http'

/**
 * Hook for fetching spaces settings (feature flags) of a space.
 * This is intended to be used in a page component like Spaces.
 * For accessing space settings in other components, access it via useSpaces hook
 *
 * @param {string} spaceId - ID of the target space.
 */
const useFetchSpaceSettings = (spaceId) => {
    const { loading, get, response, error } = useFetch(
        `/spaces/${spaceId}/settings`
    )

    useEffect(() => {
        if (spaceId) {
            get()
        }
    }, [spaceId, get])

    return {
        loading,
        error: error ? response?.data?.error : undefined,
        spaceSettings: response.ok ? response.data : undefined,
    }
}

export default useFetchSpaceSettings
