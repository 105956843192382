import qs from 'qs'
import { useLocation, useNavigate } from '@reach/router'
import { useCallback } from 'react'
import moment from 'moment'

import { dateFormat } from '../../util/date'

export const past30Days = 'past30Days'
export const next30Days = 'next30Days'

/**
 * Returns the date range from either 'past30Days' or 'next30Days'
 * @returns {Object} from (inclusive) and until (exclusive), both YYYY-MM-DD format.
 */
export function get30DayDateRange(checkInRange) {
    if (checkInRange === past30Days) {
        return {
            from: moment().subtract(30, 'days').format(dateFormat),
            until: moment().add(1, 'days').format(dateFormat),
        }
    }

    return {
        from: moment().format(dateFormat),
        until: moment().add(31, 'days').format(dateFormat),
    }
}

/**
 * This hook is intended to be used in the workplace/trends page but can be refactored
 * to handle query params in the workplace/attendance page as well
 *
 */

const useCheckInRangeFromUrl = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname, search } = location
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

    const checkInRange = queryParams.range
    const changeCheckInRange = useCallback(
        (updatedCheckInRange) => {
            const checkInRangeQueryString = qs.stringify(updatedCheckInRange)
            navigate(`${pathname}?${checkInRangeQueryString}`)
        },
        [navigate, pathname]
    )

    const checkInDateRange = get30DayDateRange(checkInRange)

    return {
        checkInRange,
        checkInDateRange,
        changeCheckInRange,
        hasCheckInRange: Boolean(checkInRange),
    }
}

export default useCheckInRangeFromUrl
