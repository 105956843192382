import React, { useCallback, useState } from 'react'
import { Row, Col, Button, Container } from 'reactstrap'
import { Form } from 'react-final-form'
import { useNavigate } from '@reach/router'

import ButtonWithLoader from '../../../components/Button.tsx'
import BuildingForm from '../BuildingForm/index.tsx'
import EditBuildingPerimeter from '../EditBuildingPerimeter'
import useFetchResolveAddress from '../hooks/useFetchResolveAddress'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import { checkIsFormValid } from '../utility'
import useAddBuilding from '../hooks/useAddBuilding'
import useBreadCrumbs from '../../../hooks/useBreadcrumbs/index.ts'

const AddBuilding = () => {
    const navigate = useNavigate()
    const [h3Indices, setH3Indices] = useState(new Set())
    const { id: spaceId, alias } = useSpaces()

    const {
        isLoading: isMapLoading,
        hasError: hasResolvedAddressError,
        getAddressById,
        address,
        clearAddress,
    } = useFetchResolveAddress()

    const { isFormSubmitting, submitForm } = useAddBuilding(spaceId)

    const handleTypeAheadChange = useCallback(
        (address) => {
            if (address) {
                return getAddressById(address.placeId)
            }

            return clearAddress()
        },
        [getAddressById, clearAddress]
    )

    useBreadCrumbs([
        {
            label: 'Buildings',
            link: `/spaces/${alias}/settings/buildings/all`,
        },
        {
            label: 'Add a building',
        },
    ])

    const handleSubmitForm = useCallback(
        (values) => {
            const formValues = {
                ...values,
                h3Indices,
            }

            return submitForm(formValues)
        },
        [submitForm, h3Indices]
    )

    return (
        <Container size="xl">
            <Row className="mt-3">
                <Col className="px-0">
                    <h2>Add a building</h2>
                    <p className="text-muted">
                        Enter details for a new workplace location.
                    </p>
                </Col>
            </Row>

            <Form
                onSubmit={handleSubmitForm}
                initialValues={{
                    enforceCapacity: 'no',
                }}
                className="mt-3"
            >
                {({
                    handleSubmit,
                    dirtyFieldsSinceLastSubmit,
                    submitErrors,
                    values,
                }) => {
                    const isFormValid = checkIsFormValid({
                        formValues: values,
                        submitErrors,
                        dirtyFieldsSinceLastSubmit,
                    })

                    return (
                        <Row className="border rounded bg-white p-3 mb-3">
                            <Col xs={{ size: 5 }}>
                                <BuildingForm
                                    onSubmit={handleSubmit}
                                    onAddressChange={handleTypeAheadChange}
                                />
                            </Col>

                            <Col xs={{ size: 7 }}>
                                <Row>
                                    <Col>
                                        <h5>Building perimeter</h5>
                                        <div className="border-bottom" />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <small className="text-muted">
                                            A building perimeter is generated
                                            automatically from the building
                                            address. Edit the perimeter by
                                            clicking on the map. There should be
                                            about a city block&apos;s worth of
                                            space around the building inside the
                                            perimeter. We&apos;ll use this to
                                            determine when employees utilize the
                                            building.
                                        </small>
                                    </Col>
                                </Row>
                                <Row className="pl-3 mb-3">
                                    <EditBuildingPerimeter
                                        h3Indices={h3Indices}
                                        handleUpdateH3Indices={setH3Indices}
                                        onTryAgain={getAddressById}
                                        hasError={hasResolvedAddressError}
                                        coordinates={address?.coordinates}
                                        isLoading={isMapLoading}
                                        hasAddress={Boolean(values.address)}
                                    />
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-end pr-0">
                                        <Button
                                            test-attr="cancel"
                                            className="mr-3"
                                            outline
                                            color="danger"
                                            onClick={() => navigate('all')}
                                        >
                                            Cancel
                                        </Button>

                                        <ButtonWithLoader
                                            test-attr="save-button"
                                            isLoading={isFormSubmitting}
                                            isDisabled={!isFormValid}
                                            type="submit"
                                            block={false}
                                            label="Add building"
                                            loadingLabel="Add building..."
                                            onClick={handleSubmit}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }}
            </Form>
        </Container>
    )
}

export default AddBuilding
