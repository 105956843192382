import React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import { Container } from 'reactstrap'

import colors from '../../../styles/colors'
import LinkButton from '../../../components/LinkButton/index.js'

const Illustration = styled.div`
    background: ${colors.red[0]};
    border-radius: 100px;
    height: 200px;
    margin: 0 auto;
    width: 200px;
`

const WelcomePage = (props: RouteComponentProps): JSX.Element => {
    return (
        <Container
            size="xl"
            data-test="desks-welcome-page"
            className="d-flex container-full-height align-items-center justify-content-center"
        >
            <div className="w-50 text-center">
                <Illustration />
                <h4 data-test="welcome-title" className="py-3">
                    Welcome to Desks
                </h4>
                <p className="text-muted">
                    You can assign workplace desks to departments and employees.
                    Learn more about different ways to use desk assignments in
                    the Help Center.
                </p>
                <LinkButton data-test="add-desks-button" to="upload">
                    Add desks
                </LinkButton>
            </div>
        </Container>
    )
}

export default WelcomePage
