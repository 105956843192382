import React from 'react'
import { Row } from 'reactstrap'

import OverviewBody from './Body'
import DataNotReady from './DataNotReady'
import OverviewBackground from '../../images/overview-background.svg'

import { useCommute } from '../../providers/CommuteProvider'
import withReloadable from '../../hocs/withReloadable'
import useTrackView from '../../hooks/useTrackView'
import Content from '../../components/PageLayout/Content'

export const Overview = (): JSX.Element => {
    const {
        period,
        haveTripsStarted,
        handleMetricError,
        isDataReady,
    } = useCommute()

    useTrackView({ category: 'performance_action', label: 'overview' })

    return (
        <Content
            data-test="overview-page"
            hasBorder={false}
            hasBackground={false}
        >
            {isDataReady ? (
                <OverviewBody
                    period={period}
                    haveTripsStarted={haveTripsStarted}
                    onMetricError={handleMetricError}
                />
            ) : (
                <DataNotReady />
            )}

            <Row className="my-5">
                <img src={OverviewBackground} />
            </Row>
        </Content>
    )
}

export default withReloadable(Overview)
