import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormGroup, Input, Label, Spinner } from 'reactstrap'

import { FilterOption } from '.'

type Props = {
    error?: Error
    isLoading?: boolean
    onChange: (newOptions: FilterOption[]) => void
    options: FilterOption[]
}

const CheckboxList = ({
    error,
    isLoading,
    options,
    onChange,
}: Props): JSX.Element => {
    const handleChange = (
        key: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newOptions = [...options]
        const changedOptions = newOptions.map((option) => {
            if (option.key === key) {
                return {
                    ...option,
                    isSelected: event.target.checked,
                }
            }
            return option
        })

        onChange(changedOptions)
    }

    if (error) {
        return (
            <>
                <FontAwesomeIcon icon={faExclamationTriangle} color="danger" />
                Could not load filter
            </>
        )
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
            {options.map(({ isSelected, key, label }: FilterOption) => {
                const inputId = `filter-dropdown-option-${key}`

                return (
                    <FormGroup key={inputId} className="mb-1 custom-input">
                        <Input
                            data-test={inputId}
                            type="checkbox"
                            id={inputId}
                            checked={isSelected}
                            onChange={(event) => handleChange(key, event)}
                        />
                        <Label for={inputId}>{label}</Label>
                    </FormGroup>
                )
            })}
        </>
    )
}

export default CheckboxList
