import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

import RosterRecordsPreviewToggle from './RosterRecordsPreviewToggle/index.tsx'
import Button from '../../../components/Button.tsx'

const ConfirmCSV = ({
    onComplete,
    onCancel,
    rosterUploadPreview = {},
    isSubmitting,
}) => {
    const {
        create: rosterRecordsToCreate,
        restore: rosterRecordsToRestore,
        deactivate: rosterRecordsToDeactivate,
        update: rosterRecordsToUpdate,
    } = rosterUploadPreview

    let rosterRecordsToAdd
    if (rosterRecordsToCreate && rosterRecordsToRestore) {
        rosterRecordsToAdd = _.concat(
            rosterRecordsToCreate,
            rosterRecordsToRestore
        )
    }

    return (
        <>
            <Row>
                <Col className="my-3 mx-3 pl-0 border-bottom">
                    <h5>Step 2: CSV confirmation</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        This is a preview of the CSV you uploaded. Please look
                        over the changes noted below.
                    </p>
                    <div
                        className="d-flex flex-column"
                        data-test="csv-upload-preview"
                    >
                        <RosterRecordsPreviewToggle
                            rosterRecords={rosterRecordsToAdd}
                            rosterRecordActionCopy="added"
                        />
                        <RosterRecordsPreviewToggle
                            rosterRecords={rosterRecordsToUpdate}
                            rosterRecordActionCopy="updated"
                        />
                        <RosterRecordsPreviewToggle
                            rosterRecords={rosterRecordsToDeactivate}
                            rosterRecordActionCopy="deactivated"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col className="d-flex justify-content-end">
                    <Button
                        size="small"
                        block={false}
                        onClick={onCancel}
                        color="link"
                        className="nav-link text-primary text-decoration-none text-center"
                        label="Cancel"
                        test-attr="cancel-btn"
                    />
                    <Button
                        size="md"
                        block={false}
                        onClick={onComplete}
                        label="Confirm this roster"
                        loadingLabel="Confirming roster..."
                        test-attr="confirm-btn"
                        isLoading={isSubmitting}
                    />
                </Col>
            </Row>
        </>
    )
}

ConfirmCSV.propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    rosterUploadPreview: PropTypes.shape({
        create: PropTypes.array,
        restore: PropTypes.array,
        deactivate: PropTypes.array,
        update: PropTypes.array,
    }),
    isSubmitting: PropTypes.bool,
}

export default ConfirmCSV
