import { call, getContext, takeLatest, put, select } from 'redux-saga/effects'
import toast from '../../components/Toast/toast.tsx'
import {
    CARPOOLER_DEACTIVATIONS_DELETE_REQUEST,
    createCarpoolerDeactivationsDataReplace,
    createCarpoolerDeactivationsDeleteSuccess,
    createCarpoolerDeactivationsDeleteFailure,
    selectDeactivationsData,
} from '../../state/carpoolers'

export const apiResourcePath = (deactivationId) =>
    `/spaces/carpoolerDeactivations/${deactivationId}`

export function* deactivationDelete(deactivationId) {
    const cosmosApi = yield getContext('cosmosApi')
    const url = apiResourcePath(deactivationId)

    yield call([cosmosApi, 'call'], url, { method: 'DELETE' })
}

/**
 * Remove this deactivation from the deactivations data
 */
export function* removeDeactivation(deactivationId) {
    const deactivations = yield select(selectDeactivationsData)

    if (!deactivations) {
        return
    }

    const without = deactivations.filter(({ id }) => deactivationId !== id)

    if (deactivations.length !== without.length) {
        yield put(
            createCarpoolerDeactivationsDataReplace({ deactivations: without })
        )
    }
}

/**
 * Remove the deactivation row in the table and display success message.
 */
export function* succeedInDeactivation(deactivationId) {
    if (!deactivationId) {
        return
    }

    yield removeDeactivation(deactivationId)
    yield put(createCarpoolerDeactivationsDeleteSuccess({ deactivationId }))

    toast.success('Successfully reactivated commuter')
}

export function* handleCarpoolerDeactivationsDeleteRequest({ payload }) {
    const { deactivationId } = payload

    try {
        yield deactivationDelete(deactivationId)
        yield succeedInDeactivation(deactivationId)
    } catch (err) {
        // If the carpooler is already reactivated,
        // ignore the error and display success toast message.
        if (err.errorId === 'CARPOOLER_ALREADY_REACTIVATED') {
            yield succeedInDeactivation(deactivationId)
            return
        }

        yield put(
            createCarpoolerDeactivationsDeleteFailure({
                deactivationId,
                error: err,
            })
        )

        const message =
            err?.message ||
            'Something went wrong reactivating. Please try again.'
        toast.error(message)
    }
}

export default function* watchCarpoolerDeactivationDelete() {
    yield takeLatest(
        CARPOOLER_DEACTIVATIONS_DELETE_REQUEST,
        handleCarpoolerDeactivationsDeleteRequest
    )
}
