import React from 'react'
import PropTypes from 'prop-types'

const TooltipCursor = ({ x = 0, y, width = 0, height, stroke }) => {
    const barCenterX = x + width / 2

    return (
        <rect
            fill="#b5bab8"
            x={barCenterX}
            y={y}
            width={1}
            height={height}
            stroke={stroke}
        />
    )
}

TooltipCursor.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TooltipCursor
