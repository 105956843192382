import React, { useEffect, useMemo } from 'react'
import { Row, Col, Container } from 'reactstrap'
import { RouteComponentProps, useNavigate } from '@reach/router'

import Info from './Info'
import ViewBuildingPerimeter from './ViewBuildingPerimeter'
import DeleteBuildingButton from './DeleteBuildingButton'
import useSpaces from '../../../hooks/useSpaces'
import useBreadCrumbs, { Breadcrumb } from '../../../hooks/useBreadcrumbs'
import { BuildingDetails } from '../../../hooks/useBuildingDetails'
import LinkButton from '../../../components/LinkButton'
import GhostLoader from '../../../components/GhostLoader'
import toast from '../../../components/Toast/toast'

type Props = {
    buildingId?: string
    buildingDetails: BuildingDetails
} & RouteComponentProps

const Details = ({ buildingId, buildingDetails }: Props): JSX.Element => {
    const { alias } = useSpaces()
    const {
        isLoading,
        getBuildingDetails,
        building,
        hasError,
    } = buildingDetails

    const navigate = useNavigate()

    const breadcrumbs = useMemo(() => {
        const crumb: Breadcrumb[] = [
            {
                label: 'Buildings',
                link: `/spaces/${alias}/settings/buildings/all`,
            },
        ]

        if (building?.name) {
            crumb.push({ label: building.name })
        }

        return crumb
    }, [alias, building])

    useBreadCrumbs(breadcrumbs)

    useEffect(() => {
        if (buildingId) {
            getBuildingDetails(buildingId)
        }
    }, [getBuildingDetails, buildingId])

    const handleTryAgain = () => {
        if (buildingId) {
            getBuildingDetails(buildingId)
        }
    }

    return (
        <Container size="xl">
            <Row className="my-3">
                <Col className="px-0 d-flex align-items-center">
                    {isLoading ? (
                        <GhostLoader data-test="title-loader" height={28} />
                    ) : (
                        <h2 data-test="title" className="mb-0">
                            {building?.name}
                        </h2>
                    )}
                </Col>
                {!(isLoading || hasError) && building && (
                    <Col className="d-flex justify-content-end pr-0">
                        <DeleteBuildingButton
                            building={building}
                            className="mr-2"
                            onDeleted={() => {
                                toast.success('Building deleted successfully.')
                                navigate(
                                    `/spaces/${alias}/settings/buildings/all`
                                )
                            }}
                        />
                        <LinkButton
                            data-test="edit-details-button"
                            to={`/spaces/${alias}/settings/buildings/${buildingId}/edit`}
                        >
                            Edit details
                        </LinkButton>
                    </Col>
                )}
            </Row>

            <Row className="border rounded bg-white p-3 mb-3">
                <Col xs={{ size: 5 }}>
                    <Info
                        isLoading={isLoading}
                        building={building}
                        buildingDetails={buildingDetails}
                        onTryAgain={handleTryAgain}
                        hasError={hasError}
                    />
                </Col>
                {building?.geometry && (<Col xs={{ size: 7 }}>
                    <Row>
                        <Col>
                            <h5>Building perimeter</h5>
                            <div className="border-bottom" />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <small className="text-muted">
                                We&apos;ll use this perimeter to determine when
                                employees utilize this building.
                            </small>
                        </Col>
                    </Row>
                    <Row className="pl-3 mb-3">
                        <ViewBuildingPerimeter
                            isLoading={isLoading}
                            hasError={hasError}
                            buildingPerimeterMultiPolygon={
                                building?.geometry.coordinates
                            }
                        />
                    </Row>
                </Col>
                )}

            </Row>
        </Container>
    )
}

export default Details
