import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
    createCarpoolerDeactivationsGetRequest,
    createCarpoolerDeactivationsAllReset,
    selectDeactivationsMetadata,
    selectDeactivationsData,
    selectDeactivationsGetRequest,
} from '../../state/carpoolers'
import { sortOrderOptions } from '../../util/sort'

export const defaultSortBy = 'createdAt' // 'Date deactivated' column
const momentDateFormat = 'MM/DD/YY'

const useCarpoolersDeactivated = ({
    fetchData = true,
    initialSortOrder = sortOrderOptions.descending,
    initialSortBy = defaultSortBy,
} = {}) => {
    const dispatch = useDispatch()

    const getCarpoolerDeactivations = useCallback(
        ({ query, sortBy, sortOrder, page } = {}) => {
            dispatch(
                createCarpoolerDeactivationsGetRequest({
                    query,
                    sortBy,
                    sortOrder,
                    page,
                })
            )
        },
        [dispatch]
    )

    useEffect(() => {
        if (fetchData) {
            getCarpoolerDeactivations({
                sortOrder: initialSortOrder,
                sortBy: initialSortBy,
            })
        }

        // Sanitize all deactivations sub reducers on unMount to ensure
        // fresh data whenever the component remounts
        return () => dispatch(createCarpoolerDeactivationsAllReset())
    }, [
        dispatch,
        fetchData,
        getCarpoolerDeactivations,
        initialSortBy,
        initialSortOrder,
    ])

    const deactivations = useSelector(selectDeactivationsData)

    const { sortBy, sortOrder, query, page } = useSelector(
        selectDeactivationsMetadata
    )
    const { isFetching, error } = useSelector(selectDeactivationsGetRequest)

    const formatDeactivationsData = (deactivation) => ({
        deactivatedEmails: deactivation.account.deactivatedEmails,
        dateDeactivated: moment(deactivation.createdAt).format(
            momentDateFormat
        ),
        deactivatedBy: deactivation.deactivatedByUser.fullName,
        deactivationId: deactivation.id,
    })

    return {
        isFetching,
        error,
        getCarpoolerDeactivations,
        deactivations: {
            page,
            data: deactivations
                ? deactivations.map(formatDeactivationsData)
                : null,
            sortBy,
            sortOrder,
            query,
        },
    }
}

export default useCarpoolersDeactivated
