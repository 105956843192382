import { Redirect, Router } from '@reach/router'
import React, { useMemo } from 'react'

import { getDefaultRedirectPath } from './util'
import useSpaces from '../../hooks/useSpaces'
import Carpoolers from '../Carpoolers'
import NotFound from '../NotFound'
import Commute from '../Commute'
import CommuteMarketing from '../CommuteMarketing'
import EmployeeRoster from '../EmployeeRoster'
import WorkplacePlanner from '../WorkplacePlanner'
import Settings from '../Settings'
import HealthAndSafety from '../HealthAndSafety'

const SpacesRouter = (): JSX.Element => {
    const { modules } = useSpaces()

    const defaultRedirectPath = useMemo(() => getDefaultRedirectPath(modules), [
        modules,
    ])

    return (
        <Router primary={false}>
            <Carpoolers path=":spaceAlias/carpoolers/*" />

            <Commute path=":spaceAlias/commute/*" />
            <CommuteMarketing path=":spaceAlias/commute-learn-more" />

            <WorkplacePlanner path=":spaceAlias/workplace/*" />
            <EmployeeRoster path=":spaceAlias/roster/*" />
            <Settings path=":spaceAlias/settings/*" />
            <HealthAndSafety path=":spaceAlias/health-and-safety/*" />

            <NotFound path="*" />

            <Redirect
                from="/:spaceAlias/overview"
                to="/spaces/:spaceAlias/commute"
                noThrow
            />
            <Redirect from="/:spaceAlias" to={defaultRedirectPath} noThrow />

            {/* Redirects for legacy routes. `performance` is renamed to `commute`. */}
            <Redirect
                from="/:spaceAlias/performance"
                to="/spaces/:spaceAlias/commute"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/performance/activity/commuter"
                to="/spaces/:spaceAlias/commute/activity/commuter"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/performance/activity/trip"
                to="/spaces/:spaceAlias/commute/activity/trip"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/performance/reports/commuter"
                to="/spaces/:spaceAlias/commute/reports/commuter"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/performance/reports/trip"
                to="/spaces/:spaceAlias/commute/reports/trip"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/workplace/check-ins"
                to="/spaces/:spaceAlias/workplace/attendance"
                noThrow
            />
            <Redirect
                from="/:spaceAlias/workplace/check-in-trends"
                to="/spaces/:spaceAlias/workplace/trends"
                noThrow
            />
        </Router>
    )
}

export default SpacesRouter
