import { useCallback, useMemo } from 'react'
import useFetch, { CachePolicies, Res } from 'use-http'
import qs from 'qs'

import {
    ResponseError,
    Building,
    Coordinates,
    MultiPolygonGeometry,
} from '../../types'

type CreateBuildingProps = {
    name: string
    address: string
    capacity?: number
    coordinates: Coordinates
}

/**
 * geometry - Currently only MultiPolygonGeometry is used.
 *            Technically geometry can accept any supported GeoJSON format, such as Polygon.
 */
type PatchBuildingProps = {
    address: string
    buildingId: string
    name?: string
    geometry?: MultiPolygonGeometry
}

type Props = {
    spaceId: string
    shouldGetOnMount?: boolean
}

export type UseBuildingResource = {
    getBuilding: (buildingId: string) => Promise<Building | undefined>
    getBuildings: (params: {
        activeAfter?: string
    }) => Promise<Building[] | undefined>

    createBuilding: ({
        name,
        address,
        capacity,
        coordinates,
    }: CreateBuildingProps) => Promise<Building | undefined>

    patchBuilding: ({
        address,
        buildingId,
        name,
        geometry,
    }: PatchBuildingProps) => Promise<Building | ResponseError | undefined>

    endBuilding: (
        buildingId: string,
        endDate: string
    ) => Promise<Building | ResponseError>

    response: Res<Building[] | Building | ResponseError>

    error?: Error
    isLoading?: boolean

    building?: Building
    buildings?: Building[]
}

const defaultFetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

/**
 * @property props.shouldGetOnMount - If true, all buildings are fetched when this hook is on mount.
 */
const useBuildingResource = ({ spaceId }: Props): UseBuildingResource => {
    const {
        get,
        post,
        patch,
        data,
        response,
        loading: isLoading,
        error: networkError,
    } = useFetch(`/spaces/${spaceId}/buildings`, defaultFetchOptions)

    const building = useMemo(() => {
        if (!response.ok || Array.isArray(data)) {
            return undefined
        }

        return data
    }, [response, data])

    const buildings = useMemo(() => {
        if (!response.ok || !Array.isArray(data)) {
            return undefined
        }

        return data
    }, [response, data])

    /**
     * Get a single building.
     * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__buildings__buildingid__get
     */
    const getBuilding = useCallback(
        (buildingId) => {
            return get(`/${buildingId}`)
        },
        [get]
    )

    /**
     * Get all buildings.
     */
    const getBuildings = useCallback(
        ({ activeAfter }: { activeAfter?: string }) =>
            get(
                qs.stringify(
                    { activeAfter },
                    {
                        addQueryPrefix: true,
                    }
                )
            ),

        [get]
    )

    const createBuilding = useCallback(
        async ({
            name,
            address,
            capacity,
            coordinates,
        }: CreateBuildingProps): Promise<Building | undefined> => {
            const payload = {
                baseAddress: address,
                name: name,
                geometry: {
                    type: 'Polygon',
                    coordinates,
                },
                capacity,
            }

            return post(payload)
        },
        [post]
    )

    /**
     * Modify a building.
     *
     * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__buildings__buildingid__patch
     *
     * @param props.buildingId - ID of the target building
     *
     * @returns {Promise} response data of the patch request.
     */
    const patchBuilding = useCallback(
        async ({
            address,
            buildingId,
            name,
            geometry,
        }: PatchBuildingProps): Promise<Building | undefined> => {
            const payload = {
                name,
                geometry,
                baseAddress: address,
            }

            return patch(`/${buildingId}`, payload)
        },
        [patch]
    )

    const error = useMemo(() => {
        return isLoading ? undefined : data?.error || networkError
    }, [isLoading, data, networkError])

    const endBuilding = (buildingId: string, endDate: string) => {
        return patch(`/${buildingId}`, { endDate })
    }

    return {
        getBuilding,
        getBuildings,
        createBuilding,
        patchBuilding,
        endBuilding,

        response,
        error,
        isLoading,

        building,
        buildings,
    }
}

export default useBuildingResource
