import React from 'react'
import Button from '../../components/Button'
import useVaccinationProofResource from '../../hooks/resources/useVaccinationProofResource'
import { VaccinationReviewStatus } from '../../types'

const buttonColorMap = {
    rejected: 'danger',
    verified: 'primary',
}
const buttonTextMap = {
    rejected: 'Reject',
    verified: 'Approve',
}
type Props = {
    spaceId: string
    proofId: string
    disabled?: boolean
    type: VaccinationReviewStatus
    onSuccess: () => void
    onError: () => void
    'data-test'?: string
    className?: string
}
const SetVaccinationStatusProofButton = (props: Props): JSX.Element => {
    const {
        spaceId,
        proofId,
        disabled,
        type,
        onSuccess,
        onError,
        className,
        'data-test': dataTest,
    } = props
    const { isLoading, setStatus, response } = useVaccinationProofResource(
        spaceId
    )

    const handleClick = async () => {
        await setStatus(proofId, type)
        if (response.ok) {
            onSuccess()
        } else {
            onError()
        }
    }

    return (
        <Button
            className={className}
            data-test={dataTest}
            size="md"
            disabled={disabled}
            block={false}
            isLoading={isLoading}
            color={buttonColorMap[type]}
            onClick={handleClick}
        >
            {buttonTextMap[type]}
        </Button>
    )
}

export default SetVaccinationStatusProofButton
