import PropTypes from 'prop-types'

/**
 * A helper Component that conditionally renders a child Component
 * if some condition is met.
 *
 */

const ConditionalWrap = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

ConditionalWrap.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}

export default ConditionalWrap
