import { navigate } from '@reach/router'
import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import Cookies from 'universal-cookie' // Imported as part of react-cookie

import {
    createSignOutFailure,
    createSignOutSuccess,
    USER_SIGN_OUT_REQUEST,
} from '../../state/user'
import { createAuthAllReset } from '../../state/auth'

const signOutURI = '/users/sessions/deleteCurrent'

export function* signOut() {
    const cosmosApi = yield getContext('cosmosApi')
    const cookies = new Cookies()

    try {
        yield call([cosmosApi, 'call'], signOutURI, { method: 'POST' })
        yield put(createAuthAllReset())
        const signInRoute =
            cookies.get('preferred-sign-in') === 'okta' ? '/okta' : '/sign-in'

        cookies.remove('current-space-alias')
        yield call(navigate, signInRoute)
        yield put(createSignOutSuccess())
    } catch (error) {
        yield put(createSignOutFailure(error))
    }
}

export default function* watchSignOut() {
    yield takeLatest(USER_SIGN_OUT_REQUEST, signOut)
}
