import React from 'react'
import { string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'reactstrap'
import { faSortUp, faSortDown } from '@fortawesome/pro-solid-svg-icons'

import { sortOrderOptions } from '../../util/sort'

const { descending, ascending } = sortOrderOptions

export const SortArrows = ({ direction }) => {
    const upClass = direction === ascending ? 'text-dark' : 'text-secondary'
    const downClass = direction === descending ? 'text-dark' : 'text-secondary'

    // Two icons stacked on top of each other, toggling color to show which is active.
    return (
        <span className="mx-3 d-inline-block align-middle">
            <Row style={{ marginBottom: '-1.1em' }}>
                <FontAwesomeIcon
                    className={`mx-3 ${upClass}`}
                    icon={faSortUp}
                    size="lg"
                />
            </Row>
            <Row>
                <FontAwesomeIcon
                    className={`mx-3 ${downClass}`}
                    icon={faSortDown}
                    size="lg"
                />
            </Row>
        </span>
    )
}

SortArrows.propTypes = {
    direction: string,
}

export default SortArrows
