import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'
import React, { useMemo } from 'react'
import { ListGroup, ListGroupItem, UncontrolledTooltip } from 'reactstrap'
import useFetch, { CachePolicies } from 'use-http'

import { ReportsTableContainer } from './styled'
import { getReportCSVFileName } from './util'
import { getDateRangeText, getDateRange } from '../../util/date'
import Pagination from '../Pagination'
import { TimeSeriesData } from '../../pages/CommuteReports/Reports'
import toast from '../Toast/toast'
import { modalTypeMap } from '../ReduxModal/helpers'
import usePagination from '../../hooks/usePagination'
import useModal from '../../hooks/useModal'
import useAnalytics from '../../hooks/useAnalytics'

const PAGE_SIZE = 12
const INITIAL_PAGE = 1

const fetchOptions = {
    headers: {
        Accept: 'text/csv',
    },
    cachePolicy: CachePolicies.NO_CACHE,
}

type Props = {
    analytics: {
        prefix: string
    }
    spaceId: string
    reportsName: string
    data: TimeSeriesData
    modalContent: {
        title: string
        body: string
    }
}

const ReportsTable = ({
    data,
    reportsName,
    spaceId,
    modalContent: { title, body },
    analytics: { prefix },
}: Props): JSX.Element => {
    const {
        showPrevious,
        showNext,
        pagesToShow,
        fromResult,
        toResult,
        changePage,
        currentPage,
    } = usePagination({
        dataSize: data.length,
        pageSize: PAGE_SIZE,
        initialPage: INITIAL_PAGE,
    })
    const currentPageData = useMemo(() => {
        return data.slice(fromResult - 1, toResult)
    }, [data, fromResult, toResult])

    const { track } = useAnalytics()
    const openModal = useModal()

    const { get, response } = useFetch(
        `/spaces/${spaceId}/reports/${reportsName}`,
        fetchOptions
    )

    async function handleDownload(period) {
        const dateProperties = {
            month: period.month,
            year: period.year,
            granularity: period.month ? 'month' : 'year',
        }

        track(`${prefix}_action`, {
            action: 'View',
            label: 'report_download',
            ...dateProperties,
            download_state: 'downloading',
        })
        toast.loading('Preparing CSV for download', { autoClose: false })

        const { fromDate, untilDate } = getDateRange(period)

        await get(`?from=${fromDate}&until=${untilDate}`)

        if (response.ok) {
            toast.dismiss()

            if (response.status === 204) {
                openModal(modalTypeMap.NO_ACTIVITY, {
                    title,
                    body,
                })
                track(`${prefix}_action`, {
                    action: 'View',
                    label: 'no_trip_data',
                    ...dateProperties,
                })
                return
            }

            const csv = await response.blob()
            const fileName = getReportCSVFileName(reportsName, period)

            track(`${prefix}_action`, {
                action: 'View',
                label: 'report_download',
                ...dateProperties,
                download_state: 'success',
            })
            toast.success('Download complete')
            saveAs(csv, fileName)
        } else {
            toast.dismiss()
            track(`${prefix}_action`, {
                action: 'View',
                label: 'report_download',
                ...dateProperties,
                download_state: 'error',
                error_type: 'fail_to_download',
            })
            toast.error('CSV download failed. Please try again.')
        }
    }

    return (
        <ReportsTableContainer>
            <ListGroup className="rounded-0">
                {currentPageData.map((row, i) => {
                    const text = getDateRangeText(row.period)

                    return (
                        <ListGroupItem
                            className="d-flex"
                            tag="button"
                            key={`reportsRow${i}`}
                            test-attr="report-row"
                            data-test={`report-row-${text}`}
                            disabled={row.isDisabled}
                            onClick={() => handleDownload(row.period)}
                        >
                            {text}
                            <span className="ml-2">{row.extraInfo}</span>
                            <span className="downloadIconWrapper">
                                <FontAwesomeIcon
                                    className="ml-2"
                                    icon={faArrowToBottom}
                                    id={`reportsRow${i}Tooltip`}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`reportsRow${i}Tooltip`}
                                >
                                    Download
                                </UncontrolledTooltip>
                            </span>
                            <span className="ml-auto">
                                {row.extraInfoRight}
                            </span>
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
            <Pagination
                className="mt-2 d-flex justify-content-end"
                showPrevious={showPrevious}
                showNext={showNext}
                currentPage={currentPage}
                pagesToShow={pagesToShow}
                onPageChange={changePage}
            />
        </ReportsTableContainer>
    )
}

export default ReportsTable
