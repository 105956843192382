import { useState } from 'react'

const useToggleThroughStates = ({
    states = [],
    initialState,
    onChange = () => {},
}) => {
    // ensure that the initialState is defined in the list of states.
    states =
        states.indexOf(initialState) >= 0 ? states : states.concat(initialState) // if not, add it to the end
    const [firstState] = states
    const [state, setState] = useState(initialState)
    const toggleState = () =>
        setState((previous) => {
            const nextStateIndex = states.indexOf(previous) + 1
            const nextState = states[nextStateIndex] || firstState
            onChange(nextState)
            return nextState
        })
    return {
        state,
        toggleState,
    }
}

export default useToggleThroughStates
