import React, { useState } from 'react'
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import colors from '../../styles/colors'
import FileUploadButton from '../FileUploadButton'
import Icon from '../Icon'

type Props = {
    acceptedFileExtensions: string[]
    maxFileSizeInBytes: number
    onFileChange: (file: File) => void
    errorList?: string[]
}

const ErrorsWithModal = ({
    acceptedFileExtensions,
    onFileChange,
    errorList,
}: Props): JSX.Element => {
    const [modalOpen, setModal] = useState(false)

    return (
        <>
            <Icon
                color={colors.red.base}
                name="exclamation-circle"
                size="3x"
                className="mb-3"
            />

            <h5 data-test="upload-section-title" className="text-danger mb-3">
                CSV could not be uploaded.
            </h5>
            <p data-test="description" className="text-muted">
                CSV could not be uploaded due to errors.{' '}
                <a
                    data-test="view-errors-link"
                    href="#"
                    onClick={() => setModal(true)}
                >
                    View Errors.
                </a>
            </p>
            <FileUploadButton
                onFileChange={onFileChange}
                acceptableFileTypes={acceptedFileExtensions.join(',')}
            >
                Browse files
            </FileUploadButton>
            <Modal data-test="errors-modal" isOpen={modalOpen} centered>
                <ModalHeader
                    data-test="modal-header"
                    toggle={() => setModal(false)}
                >
                    CSV errors
                </ModalHeader>
                <ModalBody data-test="modal-body">
                    <p>
                        Please fix the following errors and upload your CSV
                        again.
                    </p>
                    {errorList && (
                        <ol data-test="error-list">
                            {errorList.map((error, i) => (
                                <li
                                    key={`error-item-${i}`}
                                    className="text-danger"
                                >
                                    {error}
                                </li>
                            ))}
                        </ol>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button
                        data-test="modal-close-button"
                        color="secondary"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ErrorsWithModal
