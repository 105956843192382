import React, { PropsWithChildren } from 'react'

export type SpaceModules = {
    activityGraphs?: {
        isEnabled: boolean
    }
    carpoolerReports?: {
        isEnabled: boolean
    }
    diseaseTestResults?: {
        isEnabled: boolean
    }
    integrations?: {
        isEnabled: boolean
    }
    memberManagement?: {
        isEnabled: boolean
    }
    overview?: {
        isEnabled: boolean
    }
    tripReports?: {
        isEnabled: boolean
    }
    workplacePlanner?: {
        isEnabled: boolean
        customCheckInRequirements?: {
            isEnabled: boolean
        }
        deskManagement?: {
            isEnabled: boolean
        }
    }
    rosterManagement?: {
        isEnabled: boolean
    }
    vaccinationPolicies?: {
        isEnabled: boolean
        requireVaccinationPolicy?: {
            isEnabled: boolean
        }
        collectVaccinationProofPolicy?: {
            isEnabled: boolean
        }
    }
}

/**
 * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__get
 */
export type Space = {
    alias: string
    id: string
    name: string
    publicDisplayName?: string
    customers?: { id: string }[]
    tripsStartDate?: string
    modules?: SpaceModules
}

type Props = PropsWithChildren<{
    value: Space
}>

// TODO: Make optional to avoid assigning empty strings to these fields.
const defaultContext: Space = {
    id: '',
    tripsStartDate: '',
    name: '',
    alias: '',
}

export const SpacesContext = React.createContext<Space>(defaultContext)

export { default as useSpaces } from '../hooks/useSpaces'

export default function SpacesProvider({
    children,
    value,
}: Props): JSX.Element {
    return (
        <SpacesContext.Provider value={value}>
            {children}
        </SpacesContext.Provider>
    )
}
