// TODO: Add this component to components/Policy

import React, { ChangeEvent } from 'react'
import { Col } from 'reactstrap'
import { Link } from '@reach/router'
import classNames from 'classnames'
import { AsyncToggle } from '@scoop/react-component-library'

import GhostLoader from './GhostLoader'
import PolicyFeatureRow from './Policy/PolicyFeatureRow'

function Loader(props) {
    return <GhostLoader height={20} className="my-2" {...props} />
}

type Props = {
    name: string
    description: string
    detail?: string
    link?: {
        text: string
        to: string
    }

    isLoading?: boolean
    isLoadingPolicyChange?: boolean
    isEnabled?: boolean
    isToggleHidden?: boolean
    onChange?: (checked: boolean) => void
    statusLabel?: string

    indentLevel?: 0 | 1 // could extend to more levels
}

/**
 * Feature of a policy with on/off toggle.
 *
 * @param props.name - policy name
 * @param props.description - description of the feature
 * @param props.detail - detail of the feature
 * @param props.link - If specified, the link is shown next to the toggle.
 * @param props.isLoading - If true, loaders are shown.
 * @param props.isEnabled - If true, the toggle is turned on. Off otherwise.
 * @param onChange - called if the toggle is changed.
 * @param props.isToggleHidden - If true, the toggle is hidden.
 * @param props.statusLabel - If specified, shows text to the right of the toggle.
 * @param props.indentLevel - indent level of the feature, aka how much left padding to add.
 */
const PolicyFeature = ({
    name,
    description,
    detail,
    link,
    isLoading,
    isLoadingPolicyChange,
    isEnabled,
    isToggleHidden,
    onChange,
    statusLabel,
    indentLevel = 1,
}: Props): JSX.Element => {
    const indentPadding = classNames({
        'pl-0': indentLevel === 0,
        'pl-3': indentLevel === 1,
    })
    return (
        <PolicyFeatureRow data-test={`policy-${name}`}>
            <Col className={indentPadding}>
                {isLoading ? (
                    <Loader data-test="policy-feature-row-description-loader" />
                ) : (
                    <>
                        <p className="mb-0">{description}</p>
                        {detail && (
                            <small className="text-muted">{detail}</small>
                        )}
                    </>
                )}
            </Col>
            <Col
                xs={{ size: 1 }}
                className="pr-0 d-flex justify-content-end align-items-center"
            >
                {isLoading ? (
                    <Loader data-test="policy-feature-row-toggle-loader" />
                ) : (
                    <>
                        {link && (
                            <Link
                                data-test="view-link"
                                to={link.to}
                                className="text-info text-decoration-none"
                            >
                                {link.text}
                            </Link>
                        )}

                        {/* TODO: The size of the input should be bigger. */}
                        {!isToggleHidden && (
                            <AsyncToggle
                                id={name}
                                isLoading={isLoadingPolicyChange}
                                isEnabled={Boolean(isEnabled)}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    onChange && onChange(e.target.checked)
                                }}
                            />
                        )}

                        {statusLabel && (
                            <span data-test="status-label" className="ml-2">
                                {statusLabel}
                            </span>
                        )}
                    </>
                )}
            </Col>
        </PolicyFeatureRow>
    )
}

export default PolicyFeature
