import { CachePolicies, useFetch } from 'use-http'
import { useMemo } from 'react'
import {
    CosmosError,
    ResponseError,
    VaccinationLogRecordResource,
} from '../../types'

const fetchOptions = {
    headers: {
        Accept: 'application/json',
    },
    cachePolicy: CachePolicies.NO_CACHE,
}

type VaccinationLogResource = {
    isLoading: boolean
    vaccinationLogs?: VaccinationLogRecordResource[]
    error?: Error | CosmosError
    totalCount: number
    get: () => Promise<VaccinationLogRecordResource[]>
}

/**
 *
 * @params spaceId - space alias of the target customer.
 * @params dependencies - dependency array of the hook. Each change will trigger a new request to the API.
 */
export default function useVaccinationLogResource(
    spaceId: string,
    dependencies?: []
): VaccinationLogResource {
    const { data, loading, error: networkError, get } = useFetch<
        VaccinationLogRecordResource[] | ResponseError
    >(`/spaces/${spaceId}/vaccinationLog`, fetchOptions, dependencies)

    const vaccinationLogs: VaccinationLogRecordResource[] = useMemo(() => {
        if (Array.isArray(data)) {
            return data
        }

        return []
    }, [data])

    const totalCount = vaccinationLogs.length

    const error = useMemo(
        () => (data as ResponseError)?.error || networkError,
        [data, networkError]
    )

    return {
        isLoading: loading,
        vaccinationLogs,
        totalCount,
        error,
        get,
    }
}
