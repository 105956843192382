// In lieu of relying on a response's statusText,
// which is not present in all browsers / HTTP versions.
const statusCodes = {
    200: 'OK',
    201: 'Created',
    204: 'No Content',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    408: 'Request Timeout',
    409: 'Conflict',
    413: 'Payload Too Large',
    418: "I'm a teapot",
    422: 'Unprocessable Entity',
    429: 'Too Many Requests',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
}

/**
 * Serialize the Cosmos Error Envelope into an Error object.
 * https://github.com/scoopcarpool/api-docs/wiki/Cosmos#errors
 */
export class CosmosApiError extends Error {
    constructor(code, errorId, message, headers = {}) {
        if (!code) {
            throw new TypeError('Missing status code')
        }
        if (!message) {
            message = statusCodes[code] || 'Error from Cosmos API'
        }

        super(message)

        this.name = 'cosmosApiError'
        this.message = message
        this.statusCode = code
        this.errorId = errorId
        this.headers = headers
    }
}
