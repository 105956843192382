import { combineReducers } from 'redux'
import {
    USER_ADD,
    USER_REMOVE,
    USER_CREATE_OKTA_REQUEST,
    USER_CREATE_TOKEN_PASSWORD_REQUEST,
    USER_CREATE_SUCCESS,
    USER_CREATE_FAILURE,
    USER_SIGN_OUT_SUCCESS,
} from './types'
import serializeError from '../serializeError'
import { SESSION_TRANSACTION_CHECK_SUCCESS } from '../auth'

/**
 * Store information about a user (name, emails, etc) as a singleton.
 */
export const user = (state = {}, { type, payload = {} }) => {
    const {
        userId,
        emails = [],
        fullName,
        displayName,
        type: userType,
    } = payload
    switch (type) {
        case USER_ADD:
            return {
                userId,
                emails: [...emails],
                fullName,
                displayName,
                type: userType,
            }
        case USER_REMOVE:
            return {}
        case SESSION_TRANSACTION_CHECK_SUCCESS:
            return {
                displayName,
                type: userType,
            }
        case USER_SIGN_OUT_SUCCESS:
            return {}
        default:
            return state
    }
}

/**
 * Track the `isFetching` status of a user create request.
 */
export const createRequest = (
    state = {},
    { type, payload = {}, meta = {} }
) => {
    const { userId } = payload
    const error = serializeError(payload)
    switch (type) {
        case USER_CREATE_OKTA_REQUEST:
        case USER_CREATE_TOKEN_PASSWORD_REQUEST:
            return {
                isFetching: true,
            }
        case USER_CREATE_SUCCESS:
            return {
                isFetching: false,
                userId,
            }
        case USER_CREATE_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export default combineReducers({
    user,
    createRequest,
})
