import React from 'react'
import styled from 'styled-components'

import ChartDisplay from './ChartDisplay'
import CouldNotLoadData from '../../Error/CouldNotLoadData'
import GhostLoader from '../../GhostLoader'
import RadioButtons from '../../RadioButtons'

import withMetricOrChartData from '../../../hocs/withMetricOrChartData'
import withVisibilitySensor from '../hoc/withVisibilitySensor'

import {
    isCurrentPeriodInRange,
    granularityOptions,
    getHelperText,
    getChartDataInRange,
    isFirstPeriodInRange,
    getIsFirstStatIncomplete,
} from '../../../util/chart'
import { Statistic, Granularity, RangeSize } from '../../../types/index'

const ChartHeader = styled.div`
    align-content: center;
    padding-top: 30px;
    padding-bottom: 5px;
    height: 60px;
`

type Data = {
    refreshedAt: Date
    type: string
    statistics: Statistic[]
}

type WithChartOrMetricDataProps = {
    isLoading: boolean
    data: Data
    error: Error
    refetchData: () => Promise<void>
}

type ComponentProps = {
    granularity: Granularity
    rangeSize: RangeSize
    tripsStartDate: string
    chartId: string
    onChartClick: ({ isRightClick: boolean }) => void
    onGranularityChange: (granularity: Granularity) => Granularity
    onRadioButtonClick: (granularity: Granularity) => Granularity
    onTryAgain: () => Promise<void>
    radioButtonLabel: string | undefined
}

type Props = ComponentProps & WithChartOrMetricDataProps

export const LineChart = ({
    isLoading,
    data,
    error,
    refetchData,

    granularity,
    rangeSize,
    tripsStartDate,
    chartId,

    onGranularityChange,
    onChartClick,
    onRadioButtonClick,
    onTryAgain,
    radioButtonLabel,
    ...rest
}: Props): JSX.Element => {
    const handleTryAgainClick = () => {
        onTryAgain()
        refetchData()
    }

    if (error) {
        return <CouldNotLoadData onTryAgainClick={handleTryAgainClick} />
    }

    if (isLoading || !data) {
        return <GhostLoader height={648} />
    }

    const { chartData, tripsStartData } = getChartDataInRange({
        statistics: data.statistics,
        startDate: tripsStartDate,
        granularity,
    })

    const isCurrentPeriod = isCurrentPeriodInRange(data.statistics)
    const isFirstPeriod = isFirstPeriodInRange({
        statistics: data.statistics,
        startDate: tripsStartDate,
    })

    const isFirstStatIncomplete = getIsFirstStatIncomplete({
        tripsStartData,
        isFirstPeriod,
        isCurrentPeriod,
        firstStat: chartData[0],
    })

    return (
        <div
            id={`${chartId}-chart`}
            className="bg-white border"
            onClick={() => onChartClick({ isRightClick: false })}
            onContextMenu={() => onChartClick({ isRightClick: true })}
        >
            <ChartHeader>
                {isFirstStatIncomplete && (
                    <h5 className="text-primary font-weight-semibold d-inline ml-4">
                        {getHelperText(granularity, rangeSize)}
                    </h5>
                )}

                <RadioButtons
                    chartId={chartId}
                    options={granularityOptions}
                    legend={radioButtonLabel || 'Show'}
                    value={granularity}
                    onChange={onGranularityChange}
                    isVisible={rangeSize === 'month'}
                    onClick={onRadioButtonClick}
                />
            </ChartHeader>

            <ChartDisplay
                data={chartData}
                granularity={granularity}
                rangeSize={rangeSize}
                {...rest}
            />
        </div>
    )
}

export default withMetricOrChartData(withVisibilitySensor(LineChart))
