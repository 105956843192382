import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import useFetch from 'use-http'

import Button from '../../../components/Button.tsx'
import toast from '../../../components/Toast/toast.tsx'
import useAnalytics from '../../../hooks/useAnalytics'
import srcVerifyEmailEnvelopeCheckmark from '../../../images/verify-email.svg'

const EmailSent = ({ email }) => {
    const { track } = useAnalytics()
    useEffect(() => {
        track('reset_pw_action', { action: 'View', label: 'view_email_sent' })
    }, [track])
    const {
        post: postPasswordResetEmail,
        response,
        loading: isFetching,
    } = useFetch('/users/passwordReset/send')

    async function handleResend() {
        track('reset_pw_action', { action: 'Click', label: 'resend_email' })

        await postPasswordResetEmail({ email })

        if (response.ok) {
            toast.success('Email has been resent successfully.')
        } else {
            toast.error(
                "We couldn't email your instructions. Please try again."
            )
        }
    }

    return (
        <>
            <img
                width="188"
                className="mx-auto d-block my-5"
                src={srcVerifyEmailEnvelopeCheckmark}
            />

            <h2>Email Sent</h2>
            <p>
                If {email} is associated with an account, we have sent
                instructions on how to reset your password.
            </p>
            <Button
                className="purple-link"
                color="link"
                onClick={handleResend}
                label="Resend Email"
                loadingLabel="Resending..."
                isLoading={isFetching}
            />
        </>
    )
}

EmailSent.propTypes = {
    email: PropTypes.string.isRequired,
}

export default EmailSent
