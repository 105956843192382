import { combineReducers } from 'redux'

import serializeError from '../../serializeError'
import {
    CARPOOLER_DEACTIVATIONS_DATA_REPLACE,
    CARPOOLER_DEACTIVATIONS_PAGINATION_REPLACE,
    CARPOOLER_DEACTIVATIONS_GET_REQUEST,
    CARPOOLER_DEACTIVATIONS_GET_SUCCESS,
    CARPOOLER_DEACTIVATIONS_GET_FAILURE,
    CARPOOLER_DEACTIVATIONS_DELETE_REQUEST,
    CARPOOLER_DEACTIVATIONS_DELETE_SUCCESS,
    CARPOOLER_DEACTIVATIONS_DELETE_FAILURE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST,
    CARPOOLER_DEACTIVATIONS_UPLOAD_SUCCESS,
    CARPOOLER_DEACTIVATIONS_UPLOAD_FAILURE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_REPLACE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_RESET,
    CARPOOLER_DEACTIVATIONS_METADATA_REPLACE,
    CARPOOLER_DEACTIVATIONS_TOTAL_COUNT_UPDATE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_RESET,
} from '../types'

// Initialize state to null to differentiate from an empty [] response from server
export const data = (state = null, { type, payload = [] }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_DATA_REPLACE:
            return payload
        default:
            return state
    }
}

export const METADATA_DEFAULT_STATE = {
    page: 0,
    query: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
}

export const metadata = (state = METADATA_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_METADATA_REPLACE:
            return payload
        default:
            return state
    }
}

export const pagination = (state = {}, { type, payload }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_PAGINATION_REPLACE:
            return payload
        default:
            return state
    }
}

export const getRequest = (state = {}, { type, payload }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_GET_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLER_DEACTIVATIONS_GET_SUCCESS:
            return {
                isFetching: false,
            }
        case CARPOOLER_DEACTIVATIONS_GET_FAILURE: {
            const error = serializeError(payload)
            return {
                isFetching: false,
                error,
            }
        }
        default:
            return state
    }
}

export const deleteRequests = (state = {}, { type, payload = {} }) => {
    const { deactivationId } = payload

    switch (type) {
        case CARPOOLER_DEACTIVATIONS_DELETE_REQUEST:
            return {
                ...state,
                [deactivationId]: {
                    isFetching: true,
                },
            }
        case CARPOOLER_DEACTIVATIONS_DELETE_SUCCESS:
            return {
                ...state,
                [deactivationId]: {
                    isFetching: false,
                },
            }
        case CARPOOLER_DEACTIVATIONS_DELETE_FAILURE: {
            const error = serializeError(payload.error)
            return {
                ...state,
                [deactivationId]: {
                    isFetching: false,
                    error,
                },
            }
        }
        default:
            return state
    }
}

export const uploadRequest = (state = {}, { type, payload }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLER_DEACTIVATIONS_UPLOAD_SUCCESS:
            return {
                isFetching: false,
            }
        case CARPOOLER_DEACTIVATIONS_UPLOAD_FAILURE: {
            const error = serializeError(payload)
            return {
                isFetching: false,
                error,
            }
        }
        case CARPOOLER_DEACTIVATIONS_UPLOAD_RESET:
            return {}
        default:
            return state
    }
}

export const uploadPreview = (state = {}, { type, payload = {} } = {}) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_REPLACE:
            return payload
        case CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_RESET:
            return {}
        default:
            return state
    }
}

export const totalCount = (state = null, { type, payload }) => {
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_TOTAL_COUNT_UPDATE:
            return payload.totalCount
        default:
            return state
    }
}

export default combineReducers({
    data,
    metadata,
    pagination,
    getRequest,
    uploadRequest,
    deleteRequests,
    uploadPreview,
    totalCount,
})
