import moment from 'moment'
import * as dateFns from 'date-fns'

// date formats for moment
// Deprecated. Use date-fns formats.

export const dateFormat = 'YYYY-MM-DD'
export const monthDayFormat = 'MM/DD'
export const monthDisplayFormat = 'MMMM YYYY'
export const yearDisplayFormat = 'YYYY'
export const timeDisplayFormat = 'MM/DD/YY hh:mm a'

/**
 * date formats for date-fns
 */
export const dateFormats = {
    date: 'yyyy-MM-dd',
    time: 'MM/dd/yy hh:mm aaa',
}

const dateDisplayFormat = 'MM/DD/YY'
const startOfWeek = 'monday'

/**
 * This function constructs a formatted date range.
 * @example `06/25/20 - 08/02/24`
 *
 * @param {moment} fromDate - the start date of the range, inclusive.
 * @param {moment} untilDate - the end date of the range, **exclusive**.
 * @returns {string} formatted date range in `MM/DD/YY - MM/DD/YY` format
 */
export function getFormattedDateRange(fromDate, untilDate) {
    const fromDateText = fromDate.format(dateDisplayFormat)
    const untilDateText = moment(untilDate)
        .subtract(1, 'days')
        .format(dateDisplayFormat)

    return `${fromDateText} - ${untilDateText}`
}

/**
 * This function returns updated year and quarter/month/week
 * if quarter/month/week pass the limit.
 *
 * The input of quarter, month, and week must be mutually exclusive.
 *
 * @example
 *   example 1:
 *     year = 2011, quarter = 5 are calculated as
 *     year = 2012, quarter = 1
 *
 *   example 2:
 *     year = 2011, month = 13, offset = 1 are calculated as
 *     year = 2012, month = 2
 *
 * @param {Object}} period
 * @property {number} period.year - target year in YYYY.
 * @property {number} [period.quarter] - target quarter (any integer)
 * @property {number} [period.month] - target month (any integer)
 * @property {number} [period.week] - target week (any integer)
 *
 * @param {number} [offset = 0] - offset of the target range size.
 *   If offset = -1 and the date range size is 'week', the previous week is chosen.
 */
export function getAdjustedPeriod(period, offset = 0) {
    const rangeSize = getRangeSizeFromPeriod(period)
    const adjustedPeriod = { ...period }
    adjustedPeriod[rangeSize] += offset

    if (rangeSize === 'quarter') {
        const date = moment()
            .year(adjustedPeriod.year)
            .quarter(adjustedPeriod.quarter)

        adjustedPeriod.year = date.year()
        adjustedPeriod.quarter = date.quarter()
    }

    if (rangeSize === 'month') {
        const date = moment()
            .year(adjustedPeriod.year)
            .month(adjustedPeriod.month)

        adjustedPeriod.year = date.year()
        adjustedPeriod.month = date.month()
    }

    if (rangeSize === 'week') {
        const date = moment()
            .year(adjustedPeriod.year)
            .week(adjustedPeriod.week)

        adjustedPeriod.year = date.weekYear()
        adjustedPeriod.week = date.week()
    }

    return adjustedPeriod
}

/**
 * The function infers the date range from the given period and returns its friendly text.
 *
 * @example
 *   date range text format:
 *   - quarter: "Q4 2020"
 *   - month: "January 2020"
 *   - week: "03/02/20 - 03/08/20"
 *   - year: "2020"
 *
 * - The input of quarter, month, and week must be mutually exclusive.
 * - If a period exceeds its limit, the year and the period are calculated as valid period.
 */
export function getDateRangeText(period) {
    const adjustedPeriod = getAdjustedPeriod(period)
    const rangeSize = getRangeSizeFromPeriod(adjustedPeriod)
    const { year, quarter, month, week } = adjustedPeriod

    if (rangeSize === 'quarter') {
        const quarterMoment = moment().year(year).quarter(quarter)
        const startMonth = quarterMoment.startOf('quarter').format('MMM')
        const endMonth = quarterMoment.endOf('quarter').format('MMM')

        return `${startMonth} - ${endMonth} ${year}`
    }

    if (rangeSize === 'month') {
        const date = moment().year(year).month(month)

        return date.format(monthDisplayFormat)
    }

    if (rangeSize === 'week') {
        const fromDate = moment().year(year).week(week).day(startOfWeek)
        const untilDate = moment(fromDate).add(1, 'weeks')

        return getFormattedDateRange(fromDate, untilDate)
    }

    return `${year}`
}

/**
 * The function returns the date range
 * based on the given input of year, quarter, month, and week.
 *
 * - The input of quarter, month, and week must be mutually exclusive.
 * - If a period exceeds its limit, the year and the period are calculated as valid date range.
 *
 * @example
 * year = 2011, quarter = 5 are calculated as
 * year = 2012, quarter = 1
 *
 * @property {number} params.year the target year in YYYY
 * @property {number} [params.quarter] - the target quarter (any integer)
 * @property {number} [params.month] - the target month (any integer)
 * @property {number} [params.week] - the target week (any integer)
 * @returns {Object} fromDate (inclusive), untilDate (exclusive)
 */
export function getDateRange(period) {
    const adjustedPeriod = getAdjustedPeriod(period)
    const rangeSize = getRangeSizeFromPeriod(adjustedPeriod)
    const { year, quarter, month, week } = adjustedPeriod
    let fromDate
    let untilDate

    if (rangeSize === 'quarter') {
        fromDate = moment().year(year).quarter(quarter).startOf('quarter')
        untilDate = moment(fromDate).add(1, 'quarters')
    }

    if (rangeSize === 'month') {
        fromDate = moment().year(year).month(month).startOf('month')
        untilDate = moment(fromDate).add(1, 'months')
    }

    if (rangeSize === 'week') {
        fromDate = moment().year(year).week(week).day(startOfWeek)
        untilDate = moment(fromDate).add(1, 'weeks')
    }

    if (rangeSize === 'year') {
        fromDate = moment().year(year).startOf('year')
        untilDate = moment(fromDate).add(1, 'years')
    }

    return {
        fromDate: fromDate.format(dateFormat),
        untilDate: untilDate.format(dateFormat),
    }
}

/**
 * This function is the same as `getDateRange` except:
 *   1. The date range should include the full week of the 1st day of the 1st month.
 *   2. The date range should include the full week of the last day of the last month.
 *   3. So, the first day will be always Monday and the last day will be Sunday.
 *
 * @property {number} params.period.year - target year. If all of quarter, month, week are not passed, the target date range is set yearly.
 * @property {number} [params.period.quarter] - target quarter. If passed, the target date range is set quarterly.
 * @property {number} [params.period.month] - target month. If passed, the target date range is set monthly.
 * @property {number} [params.period.week] - target week. If passed, the target date range is set weekly.
 * @returns {Object} fromDate (inclusive), untilDate (exclusive)
 */
export function getDateRangeForWeeklyChart(period) {
    const dateRange = getDateRange(period)
    const actualUntilDate = moment(dateRange.untilDate).subtract(1, 'days')

    const fromDate = moment(dateRange.fromDate).startOf('isoWeek')
    const untilDate = moment(actualUntilDate).endOf('isoWeek').add(1, 'days')

    return {
        fromDate: fromDate.format(dateFormat),
        untilDate: untilDate.format(dateFormat),
    }
}

/**
 * Check whether the target date range overlaps with the earliest/latest date range.
 * The target date range is calculated based `offset` and year/quarter/month/week.
 *
 * The input of quarter, month, and week must be mutually exclusive.
 *
 * @example
 * If `offset = -2` and `year = 2014`,
 * this function will check whether `year 2012` is available in the date range.
 *
 * If `offset = 2`, `quarter = 3`, and `year = 2019`,
 * this function will check whether `Q1 2020` is available in the date range.
 *
 * @property {Date} params.earliestDate - the earliest available date
 * @property {Date} params.latestDate - the latest available date
 * @property {number} [offset = 0] - the amount of year/quarter/month/week to be added/subtracted.
 * @property {number} params.period.year - target year. If all of quarter, month, week are not passed, the target date range is set yearly.
 * @property {number} [params.period.quarter] - target quarter. If passed, the target date range is set quarterly.
 * @property {number} [params.period.month] - target month. If passed, the target date range is set monthly.
 * @property {number} [params.period.week] - target week. If passed, the target date range is set weekly.
 */
export function isDateRangeOverlapped({
    earliestDate,
    latestDate,
    offset = 0,
    period,
}) {
    const updatedPeriod = getAdjustedPeriod(period, offset)
    const { fromDate, untilDate } = getDateRange(updatedPeriod)

    // If the date range is out of the earliest/latest date range, it is invalid.
    // But it is valid if it's overlapped with earliest/latest date range.

    const actualUntilDate = moment(untilDate).subtract(1, 'days')
    const earliestMomentDate = moment(earliestDate)
    const latestMomentDate = moment(latestDate)

    if (
        latestMomentDate.isBefore(fromDate, 'day') &&
        latestMomentDate.isBefore(actualUntilDate, 'day')
    ) {
        return false
    }

    if (
        earliestMomentDate.isAfter(fromDate, 'day') &&
        earliestMomentDate.isAfter(actualUntilDate, 'day')
    ) {
        return false
    }

    return true
}

/**
 * Infers rangeSize from the provided period.
 *
 * Example 1
 * period = { year: 2020, month: 1}
 * Returns 'month'
 *
 * Example 2
 * period = { year: 2020 }
 * Returns 'year'
 *
 * @param {Object} period
 */
export function getRangeSizeFromPeriod(period) {
    if (Number.isInteger(period.week)) {
        return 'week'
    }

    if (Number.isInteger(period.month)) {
        return 'month'
    }

    if (Number.isInteger(period.quarter)) {
        return 'quarter'
    }

    return 'year'
}

/**
 * Returns the period from the dateString
 *
 * 2020-11-02 becomes
 * {
 *  year: 2020,
 *  quarter: 4
 *  month: 10,
 *  week: 45
 * }
 *
 * @param {Date} dateString
 */

export function getPeriodFromDateString(dateString) {
    const date = moment(dateString)

    const year = date.year()
    const quarter = date.quarter()
    const month = date.month()
    const week = date.week()

    return {
        year,
        quarter,
        month,
        week,
    }
}

const MONDAY = 1
const FRIDAY = 5
const SATURDAY = 6
const SUNDAY = 7
/**
 * Determines if a day is either Saturday or Sunday
 *
 * @param {*} day - the day to test in a format that momentJS can parse
 */
export function isWeekendDay(day) {
    return [SATURDAY, SUNDAY].includes(moment(day).isoWeekday())
}

export function isFriday(day) {
    return moment(day).isoWeekday() === FRIDAY
}
export function isSaturday(day) {
    return moment(day).isoWeekday() === SATURDAY
}
export function isSunday(day) {
    return moment(day).isoWeekday() === SUNDAY
}
export function isMonday(day) {
    return moment(day).isoWeekday() === MONDAY
}

/**
 * Get the next day. Ignoring weekend days if specified in the options. Ex: Monday is the next weekday from Friday
 *
 * @param {*} currentDay
 * @param {Object} options.excludeWeekendDays - when `true`, returns Monday instead of Saturday or Sunday
 */
export function getNextDay(currentDay, { excludeWeekendDays = false } = {}) {
    const nextDay = moment(currentDay).add(1, 'day')

    if (excludeWeekendDays && isWeekendDay(nextDay)) {
        return moment(currentDay).add(1, 'week').startOf('isoWeek')
    }

    return nextDay
}

/**
 * Get the previous day. Ignoring weekend days if specified in the options. Ex: Monday is the next weekday from Friday
 *
 * @param {*} currentDay
 * @param {Object} options.excludeWeekendDays - when `true`, returns Friday instead of Saturday or Sunday
 */
export function getPreviousDay(
    currentDay,
    { excludeWeekendDays = false } = {}
) {
    const previousDay = moment(currentDay).subtract(1, 'day')

    if (excludeWeekendDays && isWeekendDay(previousDay)) {
        return moment(currentDay).subtract(1, 'week').isoWeekday(FRIDAY)
    }

    return previousDay
}

/**
 * Get all weekend days between the two given dates
 *
 * @param {*} minDate - the minimum date of the range, inclusive
 * @param {*} maxDate - the maximum date of the range, inclusive
 *
 * @returns {Array} - momentJS objects representing weekend days
 */
export function getWeekendDaysBetweenDates(minDate, maxDate) {
    const weekendDays = []
    const testDate = moment(minDate)

    while (testDate.isSameOrBefore(moment(maxDate))) {
        if (isWeekendDay(testDate)) {
            weekendDays.push(testDate.clone())
        }

        testDate.add(1, 'day')
    }

    return weekendDays
}

/**
 * Get a date representing the start of the given unit (start of the week, month, quarter, year, etc)
 *
 * Note: Moment made this easy, but date-fns doesn't because you can't pass a unit to startOf()
 * @param {Date} date
 * @param {string} unit 'week', 'month', 'quarter', 'year'
 */
export function startOf(date, unit) {
    const capitalizedUnit = unit[0].toUpperCase() + unit.slice(1)

    // eslint-disable-next-line
    return dateFns[`startOf${capitalizedUnit}`](date)
}
