import {
    startOfDay,
    isWithinInterval,
    getMonth,
    isSameDay,
    parseISO,
    startOfWeek,
} from 'date-fns'

import { getPreviousISODay } from './datefns'
import { isWeekendDay } from '../date'
import { Granularity, Statistic } from '../../types'

type FormatCurrentPeriodDataProps = {
    granularity: Granularity
    statistics: Statistic[]
}
/**
 * Finds the current day in the array of statistics and replaces
 * all stat results with 'undefined' from the current day onwards.
 *
 * The "undefined" values are necessary to render the full date range on the
 * chart, while halting the svg line at the correct date on the graph.
 *
 * @param {Object} params
 * @param {String} params.granularity
 * @param {Array<Object>} params.statistics
 *
 */

export function formatCurrentPeriodData({
    granularity,
    statistics,
}: FormatCurrentPeriodDataProps): Statistic[] {
    const today = startOfDay(new Date())

    const isTodayWeekend = isWeekendDay(today)

    let matchingStatisticIndex

    if (granularity === 'daily') {
        matchingStatisticIndex = statistics.findIndex((stat) =>
            isSameDay(parseISO(stat.context.from), today)
        )

        // Use case: statistics dont include weekends and today is a weekend
        if (matchingStatisticIndex === -1 && isTodayWeekend) {
            const fridayBeforeToday = getPreviousISODay('Fri', today)

            matchingStatisticIndex =
                statistics.findIndex((stat) =>
                    isSameDay(parseISO(stat.context.from), fridayBeforeToday)
                ) + 1
        }
    }

    if (granularity === 'weekly') {
        const startOfCurrentWeek = startOfWeek(today)
        matchingStatisticIndex = statistics.findIndex((stat) => {
            const start = startOfWeek(parseISO(stat.context.from))

            return isSameDay(start, startOfCurrentWeek)
        })
    }

    if (granularity === 'monthly') {
        const currentMonth = getMonth(today)
        matchingStatisticIndex = statistics.findIndex(
            (stat) => getMonth(parseISO(stat.context.from)) === currentMonth
        )
    }

    return statistics.map((stat, i) => {
        if (i >= matchingStatisticIndex) {
            return {
                ...stat,
                result: undefined,
            }
        }
        return stat
    })
}

/**
 * Returns a boolean to indicate if the current period
 * falls within the range of data.
 * The startDate is inclusive and the end date is exclusive.
 *
 * @example
 * So if considering the date range "2020-09-01" - "2020-10-01"
 * and today is "2020-10-01", then this will return false.
 */

export function isCurrentPeriodInRange(statistics: Statistic[]): boolean {
    const today = startOfDay(new Date())
    const start = parseISO(statistics[0].context.from)

    // isWithinInterval does an inclusive comparison, so set end to second to last statistic
    // because we want to exclude the last day in the range of statistics
    const end = parseISO(statistics[statistics.length - 2].context.until)

    return isWithinInterval(today, {
        start,
        end,
    })
}
