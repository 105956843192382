import { RouteComponentProps, Redirect, Router } from '@reach/router'
import React from 'react'
import TestResultLog from '../TestResultsLog'
import VaccinationLog from '../VaccinationLog'
import ModuleFlag from '../../components/ModuleFlag'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HealthAndSafety = (props: RouteComponentProps): JSX.Element => {
    return (
        <Router primary={false}>
            <ModuleFlag
                path="/"
                name="vaccinationPolicies"
                fallback={<Redirect to="../" noThrow />}
            >
                <VaccinationLog path="/" default />
            </ModuleFlag>
            <ModuleFlag
                path="test-results"
                name="diseaseTestResults"
                fallback={<Redirect to="../" noThrow />}
            >
                <TestResultLog path="/" default />
            </ModuleFlag>
        </Router>
    )
}

export default HealthAndSafety
