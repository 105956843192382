import { PropTypes } from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button.tsx'
import ImageContainer from '../../../components/ImageContainer'
import useAnalytics from '../../../hooks/useAnalytics'
import srcError from '../../../images/error.svg'
import { createCarpoolerDeactivationsUploadPreviewReset } from '../../../state/carpoolers/actions'
import { selectDeactivationsUploadRequest } from '../../../state/carpoolers/selectors'
import { ButtonsContainer } from '../../../styles/Layout'

const TryAgain = ({ spaceAlias, file, onSubmit, onCancel }) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()
    useEffect(() => {
        track('deactivate_carpoolers_action', {
            action: 'View',
            label: 'error_upload_csv_failed',
        })
    }, [track])
    const boundDeactivationsPreviewReset = useCallback(
        () => dispatch(createCarpoolerDeactivationsUploadPreviewReset()),
        [dispatch]
    )

    const { isFetching: isFetchingDeactivationsUpload } = useSelector(
        selectDeactivationsUploadRequest
    )

    return (
        <>
            <h3 className="mb-4">Upload CSV to deactivate commuters</h3>
            <ImageContainer src={srcError} />
            <p>Unable to upload {file.fileName} </p>
            <p>Please try again.</p>
            <ButtonsContainer>
                <Button
                    isLoading={isFetchingDeactivationsUpload}
                    onClick={() => {
                        onSubmit({
                            payload: file.payload,
                            dryRun: false,
                            redirectOnSuccess: true,
                        })
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'upload_try_again',
                        })
                    }}
                    label="Try Again"
                    loadingLabel="Deactivating commuters"
                />
                <Button
                    block
                    color="link"
                    onClick={() => {
                        boundDeactivationsPreviewReset()
                        onCancel(false)
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'upload_cancel',
                        })
                    }}
                    label="Cancel"
                    className="nav-link text-primary text-decoration-none text-center mt-4"
                />
            </ButtonsContainer>
        </>
    )
}

TryAgain.propTypes = {
    file: PropTypes.object,
    spaceAlias: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}
export default TryAgain
