import styled from 'styled-components'

const ImageContainer = styled.img.attrs({
    className: 'mx-auto mb-4 d-block',
})`
    width: ${(props) => props.width || 150}px;
    height: ${(props) => props.height || 120}px;
`

export default ImageContainer
