import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import usePrevious from 'use-previous'

/**
 * This HOC makes the target component reloadable.
 * If the the component is visible and a link for the same route is clicked,
 * the component will reload.
 *
 * https://github.com/ReactTraining/react-router/issues/1982#issuecomment-320909006
 * The github issue is for react-router but it also applies to reach-router.
 *
 * @example
 *   // Route
 *   <Router>
 *     <MemberManagementActivated path="active" {...props} />
 *     ...
 *   </Router>
 *
 *   // Component
 *   ...
 *   export default withReloadable(MemberManagementActivated)
 *
 * @param {ReactNode} RouteComponent - a react component that is a child of <Router />
 */
const withReloadable = (RouteComponent) => {
    const Reloadable = (props) => {
        const { location } = props
        const [shouldReload, setShouldReload] = useState(false)
        const prevLocation = usePrevious(location)

        // After the component is reloaded, go back to the initial state.
        useEffect(() => {
            if (shouldReload) {
                setShouldReload(false)
            }
        }, [shouldReload])

        // Location's key property changes when a route is requested.
        // If the path is the same while the key changes, reload the component.
        useEffect(() => {
            if (!location) {
                return
            }

            if (
                prevLocation?.pathname === location.pathname &&
                prevLocation?.search === location.search &&
                prevLocation?.hash === location.hash &&
                prevLocation?.key !== location.key
            ) {
                setShouldReload(true)
            }
        }, [prevLocation, location])

        return shouldReload ? null : <RouteComponent {...props} />
    }

    Reloadable.propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
            hash: PropTypes.string,
            key: PropTypes.string.isRequired,
        }),
    }

    return Reloadable
}

export default withReloadable
