import React from 'react'

import Icon from '../Icon'

const Success = (): JSX.Element => {
    return (
        <>
            <Icon name="check-circle" size="4x" className="mb-3 text-success" />
            <h5 className="mb-3">File successfully uploaded</h5>
        </>
    )
}

export default Success
