import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap'
import useFetch from 'use-http'

import Button from '../../../components/Button.tsx'
import toast from '../../../components/Toast/toast.tsx'
import useAnalytics from '../../../hooks/useAnalytics'
import isEmailValid from '../../../util/isEmailValid'

const EmailInput = ({ onEmailSent }) => {
    const [userTypedEmail, setUserTypedEmail] = useState('')
    const [showError, setShowError] = useState(false)
    const { track } = useAnalytics()

    useEffect(() => {
        track('reset_pw_action', {
            action: 'View',
            label: 'view_reset_password',
        })
    }, [track])

    // If email is typed in Sign-in page, fill out the email here.
    useEffect(() => {
        const emailFromSignIn = history.state?.email || ''
        setUserTypedEmail(emailFromSignIn)
    }, [setUserTypedEmail])

    const { loading, post: postPasswordResetEmail, response } = useFetch(
        '/users/passwordReset/send'
    )

    async function handleSubmit(event) {
        event.preventDefault()

        if (!isEmailValid(userTypedEmail)) {
            setShowError(true)
            return
        }

        track('reset_pw_action', { action: 'Click', label: 'send_email' })

        await postPasswordResetEmail({
            email: userTypedEmail,
        })

        if (response.ok) {
            onEmailSent({ email: userTypedEmail })
            return
        }

        toast.error("We couldn't email your instructions. Please try again.")
    }

    return (
        <>
            <h2>Reset password</h2>
            <p>
                Please enter the email address you used to create your account.
            </p>
            <p>We&apos;ll email you instructions to reset your password.</p>
            {showError && (
                <Alert color="error" className="d-flex flex-row my-3">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="my-auto mr-3 icon"
                    />
                    <div>Please enter a valid email.</div>
                </Alert>
            )}
            <Form onSubmit={handleSubmit} className="my-3" noValidate>
                <FormGroup>
                    <Label for="email" className="mb-1">
                        Email Address
                    </Label>
                    <Input
                        id="reset-password-email"
                        type="email"
                        value={userTypedEmail}
                        onChange={(e) => setUserTypedEmail(e.target.value)}
                        required
                    />
                    <Button
                        className="mt-5"
                        label="Send email"
                        loadingLabel="Sending email..."
                        disabled={userTypedEmail.length < 1}
                        isLoading={loading}
                    />
                </FormGroup>
            </Form>
        </>
    )
}

EmailInput.propTypes = {
    onEmailSent: PropTypes.func,
}

export default EmailInput
