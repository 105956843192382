import moment from 'moment'
import React from 'react'
import _ from 'lodash'

import formatYearToDateTimeSeries from './formatYearToDateTimeSeries.tsx'
import formatWholeYearTimeSeries from './formatWholeYearTimeSeries.tsx'
import { StaleData, StillPopulating } from '../DataNotYetReady/index.tsx'

export function formatHeader(string) {
    const result = string.replace(/([A-Z])/g, ' $1')

    return _.capitalize(result)
}

/**
 * Generate one data point per month since the start date until the month before the current date in the following format:
 *
 * [
 *      { period: { year: 2020, month: 8 }},
 *      { period: { year: 2020, month: 7 }},
 *      { period: { year: 2020, month: 6 }},
 *      { period: { year: 2020, month: 5 }},
 *      ...
 * ]
 * in reverse chronological order.
 *
 * @param {*} startDate the date as a string (YYYY-MM-DD) for which to start the series
 * @param {Datetime} lastUpdatedTimestamp - the datetime at which the last data rollup occured
 *
 */

export function getMonthlyTimeSeriesDataForReports({
    startDate,
    lastUpdatedTimestamp,
}) {
    const arrayOfMonths = []
    const endDate = moment() // We only generate months up until the month before today's date
        .startOf('month')
        .subtract(1, 'day')
    let currentDate = moment(endDate)

    while (currentDate.isAfter(startDate)) {
        const isFirstDate = currentDate.isSame(endDate)

        const month = {
            period: {
                year: currentDate.year(),
                month: currentDate.month(),
            },
        }

        if (isFirstDate) {
            const momentRefreshedAt = moment(lastUpdatedTimestamp)
            const today = moment()

            const isRefreshedAtInPreviousMonth = momentRefreshedAt.isBefore(
                today,
                'month'
            )
            const time48HoursAgo = moment().subtract(2, 'days')
            const isRefreshedAtBeforeLast48Hours = momentRefreshedAt.isBefore(
                time48HoursAgo
            )
            const isSameMonth = today.isSame(momentRefreshedAt, 'month')

            if (isRefreshedAtBeforeLast48Hours && isSameMonth) {
                month.extraInfo = <StaleData />
                month.isDisabled = true
            } else if (isRefreshedAtInPreviousMonth) {
                month.extraInfo = <StillPopulating />
                month.isDisabled = true
            }
        }

        arrayOfMonths.push(month)
        currentDate = currentDate.subtract(1, 'month')
    }

    return arrayOfMonths
}

/**
 * Generate one data point per year since the start date in the following format:
 * [
 *      { period: { year: 2020 }},
 *      { period: { year: 2019 }},
 *      { period: { year: 2018 }},
 *      { period: { year: 2017 }},
 *      ...
 * ]
 * in reverse chronological order.
 *
 * @param {Date} startDate - the date as a string (YYYY-MM-DD) for which to start the series
 * @param {DateTime} lastUpdatedTimestamp - the datetime as a string (YYYY-MM-DD hh:mm:ss) for which to end the series. Defaults to the current date.
 * @param {Boolean} isYearToDateSupported - should only be true when generating data for trip reports
 * @param {String} analyticsCategory
 *
 */

export function getYearlyTimeSeriesDataForReports({
    startDate,
    lastUpdatedTimestamp,
    isYearToDateSupported,
    analyticsCategory,
}) {
    const now = moment()
    const endDate = isYearToDateSupported ? now : now.subtract(1, 'years')
    const arrayOfYears = []

    const startYear = moment(startDate).year()
    let currentYear = moment(endDate).year()

    while (currentYear >= startYear) {
        const year = {
            period: {
                year: currentYear,
            },
        }

        arrayOfYears.push(year)
        currentYear -= 1
    }

    if (arrayOfYears.length === 0) {
        return []
    }

    if (isYearToDateSupported) {
        return formatYearToDateTimeSeries({
            arrayOfYears,
            lastUpdatedTimestamp,
            analyticsCategory,
        })
    }

    return formatWholeYearTimeSeries({
        arrayOfYears,
        lastUpdatedTimestamp,
        analyticsCategory,
    })
}
