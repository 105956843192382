import React from 'react'
import { Row, Col, Label, Button } from 'reactstrap'
import styled from 'styled-components'

import { BuildingDetails } from '../../../../hooks/useBuildingDetails'
import { Building } from '../../../../types'
import GhostLoader from '../../../../components/GhostLoader'

const loaderHeight = 24

const BoldLabel = styled(Label)`
    font-weight: 500;
`

type Props = {
    isLoading?: boolean
    maxCapacity?: number | null
    building?: Building
    buildingDetails?: BuildingDetails
    onTryAgain: () => void
    hasError?: boolean
}

const BuildingInfo = ({
    isLoading,
    building,
    buildingDetails,
    onTryAgain,
    hasError,
}: Props): JSX.Element => {
    return (
        <>
            <Row>
                <Col className="pl-0 border-bottom mr-4">
                    <h5>Building details</h5>
                </Col>
            </Row>

            {hasError && (
                <Row data-test="error">
                    <Col className="d-flex flex-column justify-content-center align-items-center my-5">
                        <h5 className="mb-4">Unable to load your data.</h5>
                        <Button
                            data-test="try-again"
                            color="primary"
                            onClick={onTryAgain}
                        >
                            Try again
                        </Button>
                    </Col>
                </Row>
            )}

            {!hasError && (
                <>
                    <Row>
                        <Col className="pl-0 mt-4 mb-3">
                            <BoldLabel>Building name</BoldLabel>
                            {isLoading ? (
                                <GhostLoader height={loaderHeight} />
                            ) : (
                                <div data-test="name">{building?.name}</div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 mt-4 mb-3">
                            <BoldLabel>Address</BoldLabel>
                            {isLoading ? (
                                <GhostLoader height={loaderHeight} />
                            ) : (
                                <div data-test="address">
                                    {building?.baseAddress}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 mt-4 mb-3">
                            <BoldLabel data-test="building-capacity-label">
                                Building capacity
                            </BoldLabel>

                            {isLoading ? (
                                <GhostLoader height={loaderHeight} />
                            ) : (
                                <>
                                    <div data-test="capacity">
                                        {buildingDetails?.maxCapacity || 'None'}
                                    </div>

                                    {buildingDetails?.isMaxCapacityLowerThanDeskCapacity && (
                                        <small
                                            data-test="capacity-lower-than-desk-capacity-label"
                                            className="text-muted"
                                        >
                                            The building capacity is lower than
                                            the number of desks in it.
                                        </small>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default BuildingInfo
