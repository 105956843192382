import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { datadogLogs } from '@datadog/browser-logs'
import CrashPage from './pages/CrashPage'

import { selectCurrentSpace } from './state/spaces'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { eventId: null }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        datadogLogs.logger.error(error.name, { ...error, ...errorInfo })
        // Reset the store
        this.props.onApplicationError()
    }

    render() {
        if (this.state.hasError) {
            // render fallback UI
            return <CrashPage />
        }

        // when there's not an error, render children untouched
        return this.props.children
    }
}

ErrorBoundary.defaultProps = {
    onApplicationError: () => {},
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    currentSpace: PropTypes.object,
    onApplicationError: PropTypes.func,
}

const mapStateToProps = (state) => ({
    currentSpace: selectCurrentSpace(state),
})

export default connect(mapStateToProps)(ErrorBoundary)
