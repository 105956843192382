import { navigate, Redirect } from '@reach/router'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from '../../components/Layout/Centered'
import Password from '../../components/Password'
import PhoneNumber from '../../components/PhoneNumber'
import RegistrationPrompt from '../../components/RegistrationPrompt'
import Verify2FACode from '../../components/Verify2FACode'
import useAnalytics from '../../hooks/useAnalytics'
import useCheckSession from '../../hooks/useCheckSession'
import {
    createFactorActivateRequest,
    createFactorEnrollRequest,
    createFactorResendRequest,
    createSessionTransactionEmailPasswordCreateRequest,
    selectSessionTransaction,
    selectSessionTransactionCreateRequest,
} from '../../state/auth'
import {
    createTokenMetadataGetRequest,
    selectTokenMetadata,
    selectTokenMetadataGetRequest,
} from '../../state/registration'
import {
    createUserCreateTokenPasswordRequest,
    selectUser,
    selectUserCreateRequest,
} from '../../state/user'

const statusSuccess = 'SUCCESS'
const statusActivate = 'MFA_ACTIVATE'
const statusEnroll = 'MFA_ENROLL'
const userAlreadyExists = 'USER_ALREADY_EXISTS'
const signInPath = '/sign-in'
const getFirstEmailAddress = ([first] = []) => first && first.emailAddress

/**
 * Guide the user through a multi-step wizard to create an account. The
 * transition between each step may be identified by global state or a
 * combination of local and global state.
 *
 * 1) Verify registration token (from query string search parameter).
 * 1a) dispatch TOKEN_METADATA action
 * 1b) show token metadata information to the user.
 * Continue Condition: after user clicks the "Get Started" button
 *
 * 2) Accept password from the user.
 * 2a) dispatch USER_CREATE action.
 * 2b) dispatch SESSION_EMAIL_PASSWORD action.
 * Continue Condition: after the user account _and_ session are created.
 *
 * 3) Accept 2FA phone number from the user.
 * 3a) dispatch FACTOR_ENROLL
 * Continue Condition: after the phone number is successfully enrolled on the
 * current session.
 *
 * 4) verify 2FA code (update account phone number)
 * 4a) dispatch FACTOR_ACTIVATE action
 * Continue Condition: after factor has been successfully activated.
 */
const Register = ({ location, navigateToOnComplete = '/' }) => {
    const dispatch = useDispatch()
    const { token, confirmed, created } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    })
    const registrationConfirmed = confirmed === 'true'
    const userCreated = created === 'true'
    const [password, setPassword] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const {
        isFetching: isFetchingUserCreate,
        error: passwordError,
    } = useSelector(selectUserCreateRequest)
    const { emails, userId } = useSelector(selectUser)
    const firstEmailAddress = getFirstEmailAddress(emails)
    const {
        isFetching: sessionTransactionCheckIsFetching,
        success: sessionTransactionCheckSuccess,
    } = useCheckSession({ redirect: false })
    const { track } = useAnalytics()
    const { isFetching: isFetchingCreateSessionTransaction } = useSelector(
        selectSessionTransactionCreateRequest
    )
    const {
        sessionTransactionId,
        stateToken,
        status: authStatus,
        factorId,
    } = useSelector(selectSessionTransaction)
    const { isFetching: tokenIsFetching, error: tokenError } = useSelector(
        selectTokenMetadataGetRequest
    )
    const {
        expiresAt,
        claimedAt,
        inviteeName,
        inviterName,
        spaceName,
    } = useSelector(selectTokenMetadata)

    useEffect(() => {
        if (registrationConfirmed) {
            track('account_creation_action', {
                action: 'View',
                label: 'create_password',
            })
        }
    }, [track, registrationConfirmed])

    const boundUserCreate = useCallback(
        (password) =>
            dispatch(createUserCreateTokenPasswordRequest({ password, token })),
        [dispatch, token]
    )
    const boundFactorEnroll = useCallback(
        (phoneNumber) =>
            dispatch(
                createFactorEnrollRequest({
                    sessionTransactionId,
                    stateToken,
                    phoneNumber,
                })
            ),
        [dispatch, sessionTransactionId, stateToken]
    )
    const boundFactorActivate = useCallback(
        (code) =>
            dispatch(
                createFactorActivateRequest({
                    sessionTransactionId,
                    factorId,
                    stateToken,
                    code,
                })
            ),
        [dispatch, stateToken, sessionTransactionId, factorId]
    )
    const boundFactorResend = useCallback(
        () =>
            dispatch(
                createFactorResendRequest({
                    factorId,
                    sessionTransactionId,
                    stateToken,
                })
            ),
        [dispatch, sessionTransactionId, stateToken, factorId]
    )
    useEffect(() => {
        dispatch(createTokenMetadataGetRequest({ tokenId: token }))
    }, [dispatch, token])
    useEffect(() => {
        if (firstEmailAddress && password) {
            dispatch(
                createSessionTransactionEmailPasswordCreateRequest({
                    email: firstEmailAddress,
                    password,
                })
            )
        }
    }, [dispatch, firstEmailAddress, password])
    const authSuccessful =
        authStatus === statusSuccess || (userId && !userCreated)
    const verifyFactor = userId && authStatus === statusActivate && userCreated
    const enrollFactor = userId && authStatus === statusEnroll && userCreated
    useEffect(() => {
        if (verifyFactor) {
            track('account_creation_action', {
                action: 'View',
                label: 'enter_code',
            })
        }
    }, [verifyFactor, track])
    useEffect(() => {
        if (enrollFactor) {
            track('account_creation_action', {
                action: 'View',
                label: 'enter_phone_number',
            })
        }
    }, [enrollFactor, track])
    let form
    if (authSuccessful) {
        return <Redirect to={navigateToOnComplete} noThrow />
    }
    if (
        passwordError?.errorId === userAlreadyExists ||
        claimedAt ||
        tokenError ||
        sessionTransactionCheckSuccess
    ) {
        // TODO: message user _why_ they were brought to the Sign In flow.
        return <Redirect to={signInPath} noThrow />
    }
    if (verifyFactor) {
        // TODO: Refactor to use <MFAActivate /> instead
        form = (
            <>
                <p className="small">Step 3 of 3</p>
                <Verify2FACode
                    message={`Please enter the 6-digit code we sent to ${phoneNumber}.`}
                    resendCode={() => {
                        boundFactorResend()
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'resend_code',
                        })
                    }}
                    onSubmit={boundFactorActivate}
                    onSubmitClick={({ disabled }) => {
                        if (disabled) {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'verify_code_grayed_out',
                            })
                        } else {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'verify_code_enabled',
                            })
                        }
                    }}
                />
            </>
        )
    } else if (enrollFactor) {
        // TODO: Refactor to use <MFAEnroll /> instead
        form = (
            <>
                <p className="small">Step 2 of 3</p>
                <PhoneNumber
                    onSubmit={(phoneNumber) => {
                        setPhoneNumber(phoneNumber)
                        boundFactorEnroll(phoneNumber)
                    }}
                    onSubmitClick={({ disabled }) => {
                        if (disabled) {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'send_code_grayed_out',
                            })
                        } else {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'send_code_enabled',
                            })
                        }
                    }}
                />
            </>
        )
    } else if (registrationConfirmed) {
        form = (
            <>
                <p className="small">Step 1 of 3</p>
                <Password
                    userCreating={
                        isFetchingUserCreate &&
                        isFetchingCreateSessionTransaction
                    }
                    onSubmit={(password) => {
                        setPassword(password)
                        boundUserCreate(password)
                        const searchParams = qs.stringify({
                            token,
                            confirmed: true,
                            created: true,
                        })
                        navigate(
                            `${location.origin}${location.pathname}?${searchParams}`
                        )
                    }}
                    onSubmitClick={({ disabled }) => {
                        if (disabled) {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'next_grayed_out',
                            })
                        } else {
                            track('account_creation_action', {
                                action: 'Click',
                                label: 'next_enabled',
                            })
                        }
                    }}
                />
            </>
        )
    } else if (token) {
        form = (
            <RegistrationPrompt
                loading={tokenIsFetching || sessionTransactionCheckIsFetching}
                expiresAt={expiresAt}
                spaceName={spaceName}
                inviteeName={inviteeName}
                inviterName={inviterName}
                onSubmit={() => {
                    const searchParams = qs.stringify({
                        token,
                        confirmed: true,
                    })
                    navigate(
                        `${location.origin}${location.pathname}?${searchParams}`
                    )
                }}
            />
        )
    } else {
        return <Redirect to="/" noThrow />
    }
    return <CenteredLayout squished>{form}</CenteredLayout>
}
Register.propTypes = {
    location: PropTypes.object,
    navigateToOnComplete: PropTypes.string,
}

export default Register
