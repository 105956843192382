import React from 'react'
import { Col } from 'reactstrap'

import { FloorWithNeighborhoods } from 'types'

import Icon from 'components/Icon'
import Overflow from 'components/Overflow'
import FileUploadButton from 'components/FileUploadButton'
import useBuildingFloorRow from '../useBuildingFloorRow'

const acceptableFileTypes = '.png, .jpeg, .pdf'

type Props = {
    floor: FloorWithNeighborhoods
}

const FloorPlan = ({ floor: { id, floorPlan } }: Props) => {
    const { isLoading, uploadAndUpdateFloorPlan } = useBuildingFloorRow(id)

    if (!floorPlan) {
        return (
            <FileUploadButton
                id={`file-upload-button-${id}`}
                onFileChange={(file) => uploadAndUpdateFloorPlan({ file })}
                acceptableFileTypes={acceptableFileTypes}
                label="Upload floor plan"
                loadingLabel="Uploading..."
                isLoading={isLoading}
            />
        )
    }

    return (
        <Col
            data-test={`saved-floorplan-${id}`}
            className="d-flex justify-content-end align-items-center px-0"
        >
            <Overflow>
                <Icon name="check-circle" className="mr-2 text-info" />
                <a
                    data-test="floor-plan-link"
                    href={floorPlan.url}
                    target={floorPlan.filename}
                >
                    {floorPlan.filename}
                </a>
            </Overflow>
            <FileUploadButton
                id={`file-upload-button-${id}`}
                onFileChange={(file) => uploadAndUpdateFloorPlan({ file })}
                acceptableFileTypes={acceptableFileTypes}
                isLoading={isLoading}
                icon="arrow-from-bottom"
                size="md"
                tooltipLabel="Re-upload floor plan"
            />
        </Col>
    )
}

export default FloorPlan
