import { format } from 'date-fns'
import React, { useCallback } from 'react'

import Button from '../../../../components/Button'
import { modalTypeMap } from '../../../../components/ReduxModal/helpers'
import useBuildingResource from '../../../../hooks/useBuildingResource'
import useModal from '../../../../hooks/useModal'
import useSpaces from '../../../../hooks/useSpaces'
import { Building, ResponseError } from '../../../../types'

type Props = {
    building: Building
    className?: string
    onDeleted?: () => void
}
const DeleteBuildingButton = ({
    building,
    className,
    onDeleted,
}: Props): JSX.Element => {
    const openModal = useModal()
    const { id: spaceId } = useSpaces()
    const { endBuilding } = useBuildingResource({
        spaceId,
        shouldGetOnMount: false,
    })

    const openConfirmationModal = useCallback(() => {
        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: 'Delete building',
            description: `Are you sure you want to delete ${building?.name} immediately? This action cannot be undone.`,
            saveButtonLabel: 'Delete',
            savingButtonLabel: 'Deleting...',
            useErrorMessageFromSave: true,
            // Wrap api call in promise so that we can 'reject' it if the response is an error. UseFetch does reject based on the api response
            onSave: async () => {
                const responseData = await endBuilding(
                    building.id,
                    format(new Date(), 'yyyy-MM-dd')
                )

                if ('error' in responseData && responseData.error) {
                    return Promise.reject(
                        (responseData as ResponseError)?.error?.message ||
                            'There was an error deleting the building.'
                    )
                }

                return responseData
            },
            onClose: ({ didSave }) => {
                if (didSave) {
                    onDeleted?.()
                }
            },
        })
    }, [building.name, building.id, endBuilding, openModal, onDeleted])

    return (
        <Button
            data-test="delete-building-button"
            label="Delete building"
            block={false}
            size="md"
            outline={true}
            className={className}
            onClick={openConfirmationModal}
        />
    )
}

export default DeleteBuildingButton
