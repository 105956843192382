import React, { useMemo } from 'react'
import { subDays, isAfter } from 'date-fns'

import LastUpdatedTime, { IconTypes } from '..'
import { LastUpdatedTimeAnalytics } from '../../../types'

function getTooltipBody({ isTimestampInLast48Hours, timestamp }) {
    if (isTimestampInLast48Hours) {
        return 'Trip data refreshes at minimum every 48 hours.'
    }

    if (timestamp) {
        return "Trip data has not refreshed in the past 48 hours. Scoop has been notified and we're working on fixing the problem."
    }

    return 'Unable to show the most up-to-date timestamp.'
}

/**
 * @param [timestamp] - `undefined` if data is loading,
 *                      `null` if data is not up-to-date.
 *                      UTC timestamp otherwise.
 */
type Props = {
    timestamp?: string | null
    analytics: LastUpdatedTimeAnalytics
}

/**
 * This component is intended to be used for last updated time of trip data.
 * (Trip Reports, Commute Overview)
 *
 */
const LastUpdatedTimeForTrips = ({
    timestamp,
    analytics,
}: Props): JSX.Element | null => {
    const isTimestampInLast48Hours = useMemo(() => {
        const last48Hours = subDays(new Date(), 2)

        if (!timestamp) {
            return false
        }

        return isAfter(new Date(timestamp), last48Hours)
    }, [timestamp])

    const content = timestamp ? null : 'Timestamp unavailable'
    const icon = isTimestampInLast48Hours ? IconTypes.info : IconTypes.warning
    const tooltipBody = getTooltipBody({
        isTimestampInLast48Hours,
        timestamp,
    })

    // While the data is loading, do not show 'Last updated'
    if (timestamp === undefined) {
        return null
    }

    return (
        <LastUpdatedTime
            timestamp={timestamp}
            isTimestampOutdated={!isTimestampInLast48Hours}
            icon={icon}
            content={content}
            tooltipBody={tooltipBody}
            analytics={analytics}
        />
    )
}

export default LastUpdatedTimeForTrips
