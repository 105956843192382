import React, { MouseEvent } from 'react'
import { Pagination as ReactstrapPagination, PaginationItem } from 'reactstrap'
import { Link } from './styled'

type Props = {
    'data-test'?: string
    showNext?: boolean
    showPrevious?: boolean
    currentPage: number
    pagesToShow?: number[]
    onPageChange?: (page: number) => void
    className?: string
    onPreviousClick?: (page: number) => void
    onNextClick?: (page: number) => void
    onPageNumberClick?: (page: number) => void
}

const Pagination = ({
    'data-test': dataTest,
    showNext,
    showPrevious,
    currentPage,
    pagesToShow = [],
    onPageChange = () => undefined,
    onPreviousClick = () => undefined,
    onNextClick = () => undefined,
    onPageNumberClick = () => undefined,
    className = '',
}: Props): JSX.Element | null => {
    const handleClick = (newPage: number) => (e: MouseEvent) => {
        e.preventDefault()
        newPage !== currentPage && onPageChange(newPage)
    }

    const pages = pagesToShow.map((pageNumber) => {
        const isActive = pageNumber === currentPage
        const dataTest = isActive
            ? `active-pagination-item-${pageNumber}`
            : `pagination-item-${pageNumber}`

        return (
            <PaginationItem
                key={pageNumber}
                data-test={dataTest}
                active={isActive}
            >
                <Link
                    key={pageNumber}
                    href="#"
                    onClick={(e) => {
                        handleClick(pageNumber)(e)
                        onPageNumberClick(pageNumber)
                    }}
                >
                    {pageNumber}
                </Link>
            </PaginationItem>
        )
    })

    // if no page or only one page available, don't render pagination
    return pagesToShow.length <= 1 ? null : (
        <ReactstrapPagination data-test={dataTest} className={className}>
            <PaginationItem disabled={!showPrevious}>
                <Link
                    first
                    href="#"
                    onClick={(e) => {
                        handleClick(currentPage - 1)(e)
                        onPreviousClick(currentPage - 1)
                    }}
                />
            </PaginationItem>
            {pages}
            <PaginationItem disabled={!showNext}>
                <Link
                    last
                    href="#"
                    onClick={(e) => {
                        handleClick(currentPage + 1)(e)
                        onNextClick(currentPage + 1)
                    }}
                />
            </PaginationItem>
        </ReactstrapPagination>
    )
}

export default Pagination
