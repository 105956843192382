import React from 'react'

import { getSizeFromBytes } from '../utils/file'
import { joinWords } from '../utils/string'

type Props = {
    acceptedFileExtensions: string[]
    maxFileSizeInBytes: number
}

const AcceptedFileInfo = ({
    acceptedFileExtensions,
    maxFileSizeInBytes,
}: Props): JSX.Element => {
    return (
        <div className="mt-3">
            <small className="text-muted">
                Accepted file formats are {joinWords(acceptedFileExtensions)}{' '}
                under {getSizeFromBytes(maxFileSizeInBytes)}
            </small>
        </div>
    )
}

export default AcceptedFileInfo
