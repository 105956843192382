import React from 'react'

import GhostLoader from '../../../../components/GhostLoader'

const WorkplaceCheckInPanelLoading = () => {
    return (
        <div>
            <GhostLoader />

            <div className="mt-4 w-50">
                {Array(3)
                    .fill(0)
                    .map((n, i) => (
                        <div key={`loader${i}`} className="my-3">
                            <GhostLoader height="15" className="mb-2" />
                            <GhostLoader height="15" />
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default WorkplaceCheckInPanelLoading
