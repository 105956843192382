import React from 'react'
import { useSelector } from 'react-redux'
import { node } from 'prop-types'
import { selectUser } from '../state/user/selectors'

export const UserContext = React.createContext()

export const useUser = () => {
    const userContext = React.useContext(UserContext)
    if (!userContext) {
        throw new Error('useUser hook must be wrapped in the UserProvider')
    }
    return userContext
}

const UserProvider = ({ children }) => {
    const user = useSelector(selectUser)

    return (
        <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
    )
}

UserProvider.propTypes = {
    children: node,
}

export default UserProvider
