import { bool, func, shape, string, object, number, arrayOf } from 'prop-types'

export const buildingType = {
    id: string.isRequired,
    baseAddress: string.isRequired,
    name: string.isRequired,

    capacity: number,

    // Leaving values below object for now because
    // the format of it varies.
    geometry: object,

    // Unused in client, so marking them as optional.
    customer: shape({
        id: string.isRequired,
    }),
    timeZone: string,
    createdAt: string,
}

export const buildingDetailsType = {
    isLoading: bool,
    getBuildingDetails: func.isRequired,
    building: shape(buildingType),
    maxCapacity: number,
    hasError: bool,
}

export const buildingPolygonProp = arrayOf(arrayOf(arrayOf(number))) // eg. [[[1.23, 4.423], [2.43, 3.45]]]

// Multipolygons are nested one level deeper than a polygon
// When the client creates a building with a polygon as the building's geometry,
// the server transforms it into a multipolygon when sending back to the client
export const buildingMultiPolygonProp = arrayOf(
    arrayOf(arrayOf(arrayOf(number)))
) // eg. [[[[1.23, 4.423], [2.43, 3.45]]]]
