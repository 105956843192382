import { shape, string, bool, PropTypes } from 'prop-types'

export const workAttendanceIndication = shape({
    id: string.isRequired,
    localCalendarDate: string.isRequired, // YYYY-MM-DD
    confirmationCode: string.isRequired, // a short string, currently capital letters and numbers,
    didIndicateGoingToWork: bool.isRequired,
    healthAndSafetySubmission: PropTypes.shape({
        id: string,
        createdAt: string,
        isAccepted: bool,
        timeZone: string, // e.g, 'America/Denver'
    }),
    rosterRecord: shape({
        id: string,
        firstName: string,
        lastName: string,
    }),
})
