import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from 'prop-types'
import React, { useState } from 'react'
import { Button, Card, Collapse, Tooltip } from 'reactstrap'

import useAnalytics from '../../../../hooks/useAnalytics'

const MatchedSummarySection = ({ matchedUsers, spaceName }) => {
    const { track } = useAnalytics()
    const [showUsers, setShowUsers] = useState(false)
    const [showMoreInfoTooltip, setMoreInfoTooltip] = useState(false)
    const toggleShowUsers = () => setShowUsers((prev) => !prev)
    const toggleTooltip = () => setMoreInfoTooltip((prev) => !prev)
    if (!matchedUsers.length) {
        return (
            <p>
                No email addresses in your CSV matched an active commuter in the{' '}
                {spaceName} Scoop program.
            </p>
        )
    }

    const emailAddressQuantityAndLabel = `${matchedUsers.length} email address${
        matchedUsers.length > 1 ? 'es' : ''
    }`

    const emailDropdownLabel = `${
        showUsers ? 'Hide' : 'Show'
    } matched email address${matchedUsers.length > 1 ? 'es' : ''}`

    return (
        <>
            <p>
                {emailAddressQuantityAndLabel} in your CSV matched an active
                commuter in the {spaceName} Scoop program and will be
                deactivated.{' '}
                <strong
                    id="matchedUsersMoreInfoTooltip"
                    className="text-primary"
                    onClick={() =>
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'matched_emails_more_info',
                        })
                    }
                >
                    More Info
                </strong>
            </p>
            <Tooltip
                placement="right"
                isOpen={showMoreInfoTooltip}
                target="matchedUsersMoreInfoTooltip"
                toggle={toggleTooltip}
            >
                These carpooler accounts will move to the Deactivated carpoolers
                tab. Deactivated carpoolers can&apos;t take any new carpools
                associated with {spaceName} Scoop program but can still access
                details about their confirmed and active trips.
            </Tooltip>
            <Button
                id="matchedUsersCollapsibleToggle"
                onClick={() => {
                    toggleShowUsers()
                    track('deactivate_carpoolers_action', {
                        action: 'Click',
                        label: 'matched_emails',
                    })
                }}
                color="link"
                style={{ textDecoration: 'none' }}
                className="pl-0 text-primary"
            >
                <FontAwesomeIcon
                    size="1x"
                    icon={showUsers ? faAngleDown : faAngleRight}
                    className="mr-2"
                />
                <strong>{emailDropdownLabel}</strong>
            </Button>
            <Collapse isOpen={showUsers}>
                <Card
                    body
                    className="p-2"
                    style={{ maxHeight: '200px', overflowY: 'scroll' }}
                >
                    {matchedUsers.map((user, i) => (
                        <p key={i} className="mb-0">
                            {user}
                        </p>
                    ))}{' '}
                </Card>
            </Collapse>
        </>
    )
}

MatchedSummarySection.propTypes = {
    matchedUsers: PropTypes.array,
    spaceName: PropTypes.string,
}

export default MatchedSummarySection
