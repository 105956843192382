import React from 'react'
import { Progress } from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const scoreToBat = {
    empty: ['grey', 'grey', 'grey', 'grey', 'grey'],
    0: ['danger', 'grey', 'grey', 'grey', 'grey'],
    1: ['caution', 'caution', 'grey', 'grey', 'grey'],
    2: ['warning', 'warning', 'warning', 'grey', 'grey'],
    3: ['success', 'success', 'success', 'success', 'grey'],
    4: ['info', 'info', 'info', 'info', 'info'],
}

const MultiProgress = styled(Progress).attrs({
    multi: true,
})`
    height: 10px !important;
    border-radius: 10px !important;
    margin-top: 5px;
    margin-bottom: 5px;
`

const BatteryBar = ({ score }) => {
    const batteryStatus =
        scoreToBat[score] === undefined ? scoreToBat.empty : scoreToBat[score]
    return (
        <>
            <MultiProgress>
                <Progress bar color={batteryStatus[0]} value="19.6" />
                <Progress bar value="0.5" color="alpha" />
                <Progress bar color={batteryStatus[1]} value="19.6" />
                <Progress bar value="0.5" color="alpha" />
                <Progress bar color={batteryStatus[2]} value="19.6" />
                <Progress bar value="0.5" color="alpha" />
                <Progress bar color={batteryStatus[3]} value="19.6" />
                <Progress bar value="0.5" color="alpha" />
                <Progress bar color={batteryStatus[4]} value="19.6" />
            </MultiProgress>
        </>
    )
}

BatteryBar.propTypes = {
    score: PropTypes.number,
}

export default BatteryBar
