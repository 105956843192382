import { Redirect, Router } from '@reach/router'
import React from 'react'

import EmployeeRosterProvider from '../../providers/EmployeeRosterProvider'
import RosterManagement from '../RosterManagement'
import RosterUpload from '../RosterUpload'
import RosterInvite from '../RosterInvite'

const Routes = (): JSX.Element => {
    return (
        <EmployeeRosterProvider>
            <Router primary={false}>
                <Redirect from="/" to="management" noThrow />
                <RosterManagement path="management" />
                <RosterUpload path="upload/*" />
                <RosterInvite path="invite/*" />
            </Router>
        </EmployeeRosterProvider>
    )
}

export default Routes
