type Environment = {
    isLocal: boolean
    isStaging: boolean
}

/**
 * This dev hook is intended to be used for client (web browser) only.
 */
function useEnvironment(): Environment {
    const environment = window.__SCOOP__?.environment

    // isProduction is not added by design for safety.
    // Environment-specific code should be applied only in
    // local and staging for development purpose, so
    // there's no check for production.
    return {
        isLocal: environment === 'local',
        isStaging: environment === 'staging',
    }
}

export default useEnvironment
