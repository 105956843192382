import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

import FeatureFlagsPopover from '../../../devTools/FeatureFlagsPopover'

const linkId = 'feature-flags-popover'

const FeatureFlagsPopoverLink = (): JSX.Element => {
    return (
        <Nav
            className="position-absolute"
            style={{ right: 5, bottom: 10, zIndex: 1000 }}
        >
            <NavItem>
                <NavLink data-test="feature-flags-button" id={linkId} href="#">
                    🤖
                </NavLink>

                <FeatureFlagsPopover targetId={linkId} />
            </NavItem>
        </Nav>
    )
}

export default FeatureFlagsPopoverLink
