import React from 'react'
import { ListGroup } from 'reactstrap'

import { BuildingByFloorAndNeighbhorhood } from '../../../types'
import BuildingWithCapacityLimits from '../BuildingWithCapacityLimits'
import BuildingFloorWithNeighborhoods from '../BuildingFloorWithNeighborhoods'

type Props = {
    buildings: BuildingByFloorAndNeighbhorhood[]
    onCapacityUpdated: () => void
    isUploadMode: boolean
}

const BuildingList = ({
    buildings,
    onCapacityUpdated,
    isUploadMode,
}: Props) => {
    return (
        <ListGroup>
            {buildings.map((building) => {
                if (building.floors.length === 0) {
                    return null
                }

                return (
                    <BuildingWithCapacityLimits
                        key={building.id}
                        building={building}
                        onCapacityUpdated={onCapacityUpdated}
                        isUploadMode={isUploadMode}
                    >
                        {building.floors.map((floor) => (
                            <BuildingFloorWithNeighborhoods
                                key={floor.id}
                                floor={floor}
                                shouldShowFloorPlan={!isUploadMode}
                            />
                        ))}
                    </BuildingWithCapacityLimits>
                )
            })}
        </ListGroup>
    )
}
export default BuildingList
