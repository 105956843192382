import { useCallback, useMemo, useEffect } from 'react'

import useLogger from 'hooks/useLogger'
import useBuildingResource from '../useBuildingResource'
import useEmployeeExperienceResource from '../useEmployeeExperienceResource'
import useAsync from '../useAsync'
import {
    Building,
    CosmosError,
    EmployeeExperienceConfigurationCheckInCapacity,
} from '../../types'

export type Props = {
    spaceId: string
}

export type GetBuildingCapacityConfiguration = (
    buildingId: string
) => { id: string } | undefined

export type BuildingDetails = {
    getBuildingDetails: (buildingId: string) => Promise<void>
    isLoading: boolean
    building?: Building
    getBuildingCapacityConfiguration: GetBuildingCapacityConfiguration

    /**
     * True if the building capacity is lower than the number of desks.
     * False otherwise.
     */
    isMaxCapacityLowerThanDeskCapacity: boolean

    maxCapacity?: number | null
    error?: Error | CosmosError
    hasError: boolean
}

/**
 * Gets the building details, including the building capacity value
 *
 * @example
 * ```js
 *   const { get } = useBuildingDetails({ spaceId })
 *   ...
 *   getBuilding('<building_id>')
 * ```
 *
 * @param {Object} params
 * @param {String} params.spaceId - Space Id
 */
export default function useBuildingDetails({
    spaceId,
}: Props): BuildingDetails {
    const logger = useLogger()
    const { getBuilding, building, error: buildingError } = useBuildingResource(
        {
            spaceId,
        }
    )

    const {
        getExperience,
        configuration,
        error: configurationError,
    } = useEmployeeExperienceResource({
        spaceId,
        type: 'checkInCapacity',
    })

    const { exec, isLoading } = useAsync(/** { status: 'loading' } */)

    useEffect(() => {
        if (!isLoading && (buildingError || configurationError)) {
            // NOTE: This should NOT HAPPEN, we want to know when it does.
            logger.error(
                buildingError
                    ? 'Building could not be retrieved'
                    : 'Building EmployeeExperienceResource for checkInCapacity could not be retrieved',
                {
                    building,
                    configuration,
                    buildingError,
                    configurationError,
                }
            )
        }
    }, [
        isLoading,
        buildingError,
        configurationError,
        configuration,
        building,
        logger,
    ])

    const hasError = isLoading
        ? false
        : Boolean(buildingError || configurationError)

    const getBuildingDetails = useCallback(
        (buildingId) => {
            return exec(Promise.all([getBuilding(buildingId), getExperience()]))
        },
        [exec, getBuilding, getExperience]
    )

    /**
     *  Get the capacity configuration of the target building.
     */
    const getBuildingCapacityConfiguration = useCallback(
        (buildingId) => {
            if (!buildingId || !configuration?.length) {
                return undefined
            }

            const buildingCapacityConfiguration = configuration.find(
                (config) =>
                    (config as EmployeeExperienceConfigurationCheckInCapacity)
                        .eligibilityProfile.building.id === buildingId
            )

            return buildingCapacityConfiguration
        },
        [configuration]
    )

    const maxCapacity = useMemo<number | null>(() => {
        const buildingCapacityConfiguration = getBuildingCapacityConfiguration(
            building?.id
        ) as EmployeeExperienceConfigurationCheckInCapacity

        return buildingCapacityConfiguration?.value
    }, [building, getBuildingCapacityConfiguration])

    const isMaxCapacityLowerThanDeskCapacity = useMemo(() => {
        if (!maxCapacity) {
            return false
        }

        if (!building?.totalAssignedDesks) {
            return false
        }

        return maxCapacity < building.totalAssignedDesks
    }, [maxCapacity, building])

    return {
        getBuildingDetails,
        isLoading,
        building,
        getBuildingCapacityConfiguration,
        maxCapacity,
        isMaxCapacityLowerThanDeskCapacity,
        error: buildingError || configurationError,
        hasError,
    }
}
