import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import React, { useCallback, useContext, useMemo } from 'react'

import styled from 'styled-components'
import MenuDropdownWithActions, {
    DropdownItemType,
} from '../../../components/MenuDropdownWithActions'
import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import toast from '../../../components/Toast/toast'
import useRosterRecordsInviteBulkResource from '../../../hooks/resources/useRosterRecordsInviteBulkResource'
import useModal from '../../../hooks/useModal'
import useSpaces from '../../../hooks/useSpaces'
import { EmployeeRosterContext } from '../../../providers/EmployeeRosterProvider'
import { InvitationStatus } from '../../../types'

const { notYetInvited, invited } = InvitationStatus

export const ParagraphRespectingLineBreaks = styled.p`
    white-space: break-spaces;
`

const getModalDescription = (
    invitationStatus: InvitationStatus,
    invitationCount: number
): string => {
    if (invitationStatus === notYetInvited && invitationCount === 1) {
        return '1 employee who has not yet been invited will receive an invite via email.\n\nThe email will be sent from Scoop, containing a link for the employee to accept the invitation and create an account.'
    }
    if (invitationStatus === notYetInvited && invitationCount > 1) {
        return `${invitationCount} employees who have not yet been invited will receive invites via email.\n\nThe email will be sent from Scoop, containing a link for the employees to accept the invitation and create an account.`
    }

    if (invitationStatus === invited && invitationCount === 1) {
        return '1 employee will receive another invite via email.\n\nThe email will be sent from Scoop, containing a link for the employee to accept the invitation and create an account.'
    }
    if (invitationStatus === invited && invitationCount > 1) {
        return `${invitationCount} employees will receive another invite via email.\n\nThe email will be sent from Scoop, containing a link for the employees to accept the invitation and create an account.`
    }

    throw new Error(`No description defined for invitation ${invitationStatus}`)
}

const getSaveButtonLabel = (
    invitationStatus: InvitationStatus,
    invitationCount: number
): string => {
    if (invitationStatus === notYetInvited && invitationCount === 1) {
        return 'Send invite'
    }
    if (invitationStatus === notYetInvited && invitationCount > 1) {
        return 'Send invites'
    }
    if (invitationStatus === invited && invitationCount === 1) {
        return 'Resend invite'
    }
    if (invitationStatus === invited && invitationCount > 1) {
        return 'Resend invites'
    }

    throw new Error(
        `No save button label defined for invitation ${invitationStatus}`
    )
}

const DropdownMenu = (): JSX.Element => {
    const openModal = useModal()
    const { id: spaceId } = useSpaces()
    const {
        getNotYetInvitedData,
        notYetInvitedCount,
        getPendingData,
        pendingCount,
        datatable: { getData },
    } = useContext(EmployeeRosterContext)
    const { inviteBulk, response } = useRosterRecordsInviteBulkResource(spaceId)

    const sendInvites = useCallback(
        async (invitationStatus: InvitationStatus, invitationCount: number) => {
            await inviteBulk(invitationStatus)

            if (!response.ok) {
                toast.error(
                    'We were unable to invite your employees to join Scoop. Please try again.'
                )
                return
            }

            await getData({})
            await getNotYetInvitedData()
            await getPendingData()

            toast.success(
                `${
                    invitationCount > 1
                        ? `${invitationCount} Employees were`
                        : '1 Employee was'
                } invited to join Scoop.`
            )
        },
        [inviteBulk, response, getData, getNotYetInvitedData, getPendingData]
    )

    const showInviteBulkModal = useCallback(
        (invitationStatus: InvitationStatus, invitationCount: number) => {
            openModal(modalTypeMap.GENERIC_CONFIRMATION, {
                title: 'Send invites',
                description: (
                    <ParagraphRespectingLineBreaks>
                        {getModalDescription(invitationStatus, invitationCount)}
                    </ParagraphRespectingLineBreaks>
                ),
                saveButtonLabel: getSaveButtonLabel(
                    invitationStatus,
                    invitationCount
                ),
                onSave: () => sendInvites(invitationStatus, invitationCount),
            })
        },
        [openModal, sendInvites]
    )

    const dropdownItems: DropdownItemType[] = useMemo(() => {
        const items: DropdownItemType[] = []

        if (notYetInvitedCount > 0) {
            items.push([
                'Send invites to uninvited employees',
                {
                    onClick: () =>
                        showInviteBulkModal(notYetInvited, notYetInvitedCount),
                },
            ])
        }

        if (pendingCount > 0) {
            items.push([
                'Resend invites to pending employees',
                {
                    onClick: () => showInviteBulkModal(invited, pendingCount),
                },
            ])
        }

        return items
    }, [showInviteBulkModal, notYetInvitedCount, pendingCount])

    return (
        <MenuDropdownWithActions
            data-test="action-dropdown"
            icon={faEllipsisH}
            isOutlineButton
            className="ml-3"
            dropdownItems={dropdownItems}
            iconProps={{
                size: 'lg',
            }}
            dropdownMenuProps={{ right: true }}
        />
    )
}

export default DropdownMenu
