import { useCallback, useEffect, useContext } from 'react'

import useLogger from 'hooks/useLogger/index.ts'
import toast from '../../Toast/toast.tsx'
import useFloorPlanResource from '../../../hooks/resources/useFloorPlanResource'
import useSpaces from '../../../hooks/useSpaces/index.ts'
import { DesksContext } from '../../../providers/DesksContextProvider'

const useBuildingFloorRow = (floorId) => {
    const logger = useLogger()
    const { id: spaceId } = useSpaces()
    const { updateFloorPlan } = useContext(DesksContext)
    const {
        isLoading,
        hasFileSizeError,
        error,
        uploadFloorPlan,
    } = useFloorPlanResource({ spaceId, floorId })

    useEffect(() => {
        if (error) {
            toast.error('Floor plan upload failed. Please try again.')
        }
    }, [error])

    useEffect(() => {
        if (hasFileSizeError) {
            toast.error('File is too large. Please upload a file under 10MB.')

            logger.warn(
                'User attempted to upload an image of a floor plan larger than 10MB.'
            )
        }
    }, [hasFileSizeError, logger])

    const uploadAndUpdateFloorPlan = useCallback(
        async (floorPlan) => {
            const data = await uploadFloorPlan(floorPlan)

            // Update the floor plan for building's floor in the Provider Context
            if (data) {
                updateFloorPlan({ floorId, floorPlan: data })
                toast.success('New floor plan saved.')
            }
        },
        [uploadFloorPlan, floorId, updateFloorPlan]
    )

    return {
        isLoading,
        hasFileSizeError,
        uploadAndUpdateFloorPlan,
    }
}

export default useBuildingFloorRow
