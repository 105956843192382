import React, { useState, useCallback } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Container } from 'reactstrap'

import UploadInstructions from './UploadInstructions'
import BulkInvitationsPreview from './BulkInvitationsPreview'

import { Header } from '../../components/PageLayout'
import FullPageModal from '../../components/FullPageModal'
import useSpaces from '../../hooks/useSpaces'
import { RosterRecord } from '../../types'
import useRosterRecordsInviteBulkResource, {
    RecordsInviteBulkResponseType,
} from '../../hooks/resources/useRosterRecordsInviteBulkResource'

const RosterInvite = (props: RouteComponentProps) => {
    const { alias: spaceAlias, id: spaceId } = useSpaces()
    const [showInstructions, setShowInstructions] = useState(true)
    const [invitations, updateInvitations] = useState<RosterRecord[]>([])
    const [file, updateFile] = useState<File>()

    const { inviteBulkWithCSV, isLoading } = useRosterRecordsInviteBulkResource(
        spaceId
    )

    const navigateToRosterManagement = useCallback(() => {
        navigate(`/spaces/${spaceAlias}/roster/management`)
    }, [spaceAlias])

    const onUploadSuccess = (response: RecordsInviteBulkResponseType) => {
        updateInvitations(response.map(({ rosterRecord }) => rosterRecord))

        setTimeout(() => {
            setShowInstructions(false)
        }, 1000)
    }

    return (
        <FullPageModal
            hideBackButton={showInstructions}
            onClickBack={() => setShowInstructions(true)}
            onClose={() => navigateToRosterManagement()}
        >
            <Container size="xl" className="p-5">
                <Header
                    title="Invite Employees"
                    description="To invite employees, upload a properly formatted CSV below"
                />
                {showInstructions ? (
                    <UploadInstructions
                        onUploadSuccess={onUploadSuccess}
                        onFileChange={(file) => updateFile(file)}
                        fileUploadURL={`/api/spaces/${spaceId}/rosterRecords/invitations/inviteBulk?force=false`}
                    />
                ) : (
                    <BulkInvitationsPreview
                        invitations={invitations}
                        onSendInvitesSuccess={navigateToRosterManagement}
                        sendInvites={() => inviteBulkWithCSV(file)}
                        isSending={isLoading}
                    />
                )}
            </Container>
        </FullPageModal>
    )
}

export default RosterInvite
