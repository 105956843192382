import {
    isBefore,
    isSameDay,
    startOfWeek,
    parseISO,
    getMonth,
} from 'date-fns/fp'
import compose from 'compose-function'

import { Granularity, Statistic } from '../../types'

type FormatFirstPeriodDataInput = {
    granularity: Granularity
    statistics: Statistic[]
    startDate: string
}

type FormatFirstPeriodDataOutput = {
    firstPeriodFormattedData: Statistic[]
    tripsStartData: Statistic
}
/**
 * Finds the tripsStartDate in the array of statistics and replaces
 * all stat results with 'undefined' leading up to the trips start date.
 *
 * The "undefined" values are necessary to render the full date range on the
 * chart, while halting the svg line at the correct date on the graph.
 *
 */

export function formatFirstPeriodData({
    granularity,
    statistics,
    startDate,
}: FormatFirstPeriodDataInput): FormatFirstPeriodDataOutput {
    const parsedStartDate = parseISO(startDate)
    let matchingStatisticIndex: number

    if (granularity === 'daily') {
        matchingStatisticIndex = statistics.findIndex(
            (stat) => stat.context.from === startDate
        )
    } else if (granularity === 'weekly') {
        const startOfTripsStartDateWeek = startOfWeek(parsedStartDate)

        matchingStatisticIndex = statistics.findIndex((stat) =>
            compose(
                isSameDay(startOfTripsStartDateWeek),
                startOfWeek,
                parseISO
            )(stat.context.from)
        )
    } else {
        // Granularity is monthly
        const startOfTripsStartDateMonth = getMonth(parsedStartDate)

        matchingStatisticIndex = statistics.findIndex((stat) => {
            const parsedStat = parseISO(stat.context.from)

            return getMonth(parsedStat) === startOfTripsStartDateMonth
        })
    }

    const firstPeriodFormattedData = statistics.map((stat, i) => {
        if (i < matchingStatisticIndex) {
            return {
                ...stat,
                result: undefined,
            }
        }
        return stat
    })

    const tripsStartData = statistics[matchingStatisticIndex]

    return { firstPeriodFormattedData, tripsStartData }
}

type IsFirstPeriodInRangeInput = {
    statistics: Statistic[]
    startDate: string
}

/**
 * Returns a boolean to indicate if the first stat in the
 * statistic array precedes or is the same as the trips start date
 *
 */

export function isFirstPeriodInRange({
    statistics,
    startDate,
}: IsFirstPeriodInRangeInput): boolean {
    if (!startDate) {
        return false
    }
    const parsedStartDate = parseISO(startDate)
    const parsedFirstDay = parseISO(statistics[0].context.from)

    return (
        isSameDay(parsedStartDate, parsedFirstDay) ||
        isBefore(parsedStartDate, parsedFirstDay)
    )
}
