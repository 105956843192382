import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import colors from '../../../../styles/colors'

type Props = {
    actual?: {
        id?: string
    }
}

const AttendanceCell = ({ actual }: Props): JSX.Element => {
    if (!actual) {
        return <span data-test="not-confirmed">Not confirmed</span>
    }

    return (
        <>
            <FontAwesomeIcon
                icon={faCheckCircle}
                color={colors.green.base}
                className="mr-1"
            />
            <span data-test="confirmed">Confirmed</span>
        </>
    )
}

export default AttendanceCell
