import { Link } from '@reach/router'
import { PropTypes } from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'

import Button from '../../components/Button.tsx'
import TotalPageCount from '../../components/TotalPageCount/index.tsx'
import EmailWithPopover from '../../components/EmailWithPopover'
import ErrorTryAgain from '../../components/MemberManagementStates/ErrorTryAgain.tsx'
import NoCarpoolersDeactivated from '../../components/MemberManagementStates/NoCarpoolersDeactivated'
import NoSearchResultsDeactivated from '../../components/MemberManagementStates/NoSearchResultsDeactivated'
import Pagination from '../../components/Pagination/index.tsx'
import { modalTypeMap } from '../../components/ReduxModal/helpers.ts'
import SearchBar from '../../components/SearchBar/index.tsx'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import Table from '../../components/Table/index.tsx'
import SortableColumn from '../../components/Table/SortableColumn'

import useAnalytics from '../../hooks/useAnalytics'
import useCallOnceWhenDefined from '../../hooks/useCallOnceWhenDefined'
import useCarpoolersDeactivated from '../../hooks/useCarpoolersDeactivated'
import usePagination from '../../hooks/usePagination/index.ts'
import useToggleThroughStates from '../../hooks/useToggleThroughStates'

import { PAGE_SIZE } from '../../sagas/activatedCarpoolers'
import {
    selectDeactivatedCarpoolerTotalCount,
    selectDeactivationsDeleteRequests,
    selectDownloadDeactivatedCarpoolersRequest,
} from '../../state/carpoolers'
import { createOpenModal } from '../../state/modal/actions'

import withReloadable from '../../hocs/withReloadable'
import { sortOrderOptions } from '../../util/sort'
import { Header } from '../../components/PageLayout'

const ReactivateButton = styled(Button)`
    color: #000;

    :hover {
        background-color: #e0e6e4;
    }
`

// Column sort order states should transition in the following order.
export const sortOrderStates = [
    sortOrderOptions.descending,
    sortOrderOptions.ascending,
]

const MemberManagementDeactivated = ({
    spaceAlias,
    spaceId,
    publicDisplayName,
}) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    const {
        isFetching: memberManagementIsFetching,

        getCarpoolerDeactivations,
        error: memberManagementError,
        deactivations,
    } = useCarpoolersDeactivated()

    const {
        page,
        query,
        sortBy,
        sortOrder,
        data: deactivationsData,
    } = deactivations

    const isPageLoading = deactivationsData === null && !memberManagementError
    const isCarpoolersLoading =
        Boolean(memberManagementIsFetching) &&
        deactivationsData !== null &&
        !memberManagementError
    const isEmptyCarpoolers =
        !query &&
        !memberManagementIsFetching &&
        deactivationsData &&
        deactivationsData.length === 0 &&
        !memberManagementError
    const isEmptySearch =
        query &&
        !memberManagementIsFetching &&
        deactivationsData &&
        deactivationsData.length === 0 &&
        !memberManagementError
    const {
        showPrevious,
        showNext,
        pagesToShow,
        fromResult,
        toResult,
    } = usePagination({
        dataSize: deactivationsData?.length,
        pageSize: PAGE_SIZE,
        initialPage: page,
    })

    useEffect(() => {
        if (isEmptyCarpoolers) {
            track('deactivate_carpoolers_action', {
                action: 'View',
                label: 'deactivated_carpoolers_first_time',
            })
        }
    }, [track, isEmptyCarpoolers])

    const { toggleState: toggleSortOrderState } = useToggleThroughStates({
        states: sortOrderStates,
        initialState: sortOrderOptions.descending,
        onChange: (nextSortOrder) => {
            getCarpoolerDeactivations({
                sortOrder: nextSortOrder,
                query, // Keep the same query
                sortBy,
                page,
            })
        },
    })

    const { isFetching: downloadCsvLoading } = useSelector(
        selectDownloadDeactivatedCarpoolersRequest
    )

    const totalCount = useSelector(selectDeactivatedCarpoolerTotalCount)

    const columnHeaders = [
        [<div key="email">Email</div>, { width: '40%' }],
        [
            <SortableColumn
                key={sortBy}
                direction={sortOrder}
                onClick={() => {
                    toggleSortOrderState()
                    track('edit_carpoolers_action', {
                        action: 'Click',
                        label: 'sort_date_deactivated',
                    })
                }}
            >
                Date deactivated
            </SortableColumn>,
        ],
        [<div key="deactivatedBy">Deactivated by</div>],
        [
            <div className="text-center" key="actions">
                Actions
            </div>,
        ],
    ]

    const placeHolderRowAmount = deactivationsData?.length || PAGE_SIZE
    const deleteRequests = useSelector(selectDeactivationsDeleteRequests)
    const transformCarpoolerData = (carpoolers) =>
        carpoolers
            .slice(fromResult - 1, toResult)
            .map(
                (
                    {
                        deactivatedEmails,
                        dateDeactivated,
                        deactivatedBy,
                        deactivationId,
                    },
                    index
                ) => [
                    <EmailWithPopover
                        key={deactivatedEmails && deactivatedEmails[0]}
                        index={index.toString()}
                        emails={deactivatedEmails}
                        handleTrackEvent={() => {
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'expand_account_emails',
                            })
                        }}
                    />,
                    dateDeactivated,
                    deactivatedBy,
                    <ReactivateButton
                        className="mx-auto d-block"
                        color="secondary"
                        isLoading={deleteRequests[deactivationId]?.isFetching}
                        key={deactivationId}
                        label="Reactivate"
                        onClick={() => {
                            dispatch(
                                createOpenModal({
                                    name: modalTypeMap.REACTIVATE_CARPOOLER,
                                    data: {
                                        displayEmail: deactivatedEmails[0],
                                        deactivationId,
                                    },
                                })
                            )
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'reactivate',
                            })
                        }}
                        outline
                        size="med"
                    />,
                ]
            )

    const searchBar = (
        <SearchBar
            initialValue={query}
            onSearch={(query) =>
                getCarpoolerDeactivations({
                    query,
                    sortBy,
                    sortOrder,
                    page,
                })
            }
            onClear={() =>
                getCarpoolerDeactivations({
                    query: undefined,
                    sortBy,
                    sortOrder,
                    page,
                })
            }
            isSearching={memberManagementIsFetching}
            onSearchClick={() =>
                track('edit_carpoolers_action', {
                    action: 'Click',
                    label: 'search_bar_click_search',
                })
            }
        />
    )

    useCallOnceWhenDefined(() => {
        track('edit_carpoolers_action', {
            action: 'View',
            label: 'deactivated_carpoolers',
        })
    }, spaceId)

    if (isPageLoading) {
        return <SpinnerFullPage currentPage="deactivated" />
    }

    if (isEmptyCarpoolers) {
        return (
            <NoCarpoolersDeactivated
                spaceAlias={spaceAlias}
                companyName={publicDisplayName}
            />
        )
    }

    if (isEmptySearch) {
        return (
            <Container size="xl">
                <Header title="Deactivated commuters" />
                <div className="w-50">{searchBar}</div>
                <Row>
                    <Col
                        md={{ size: 6, offset: 3 }}
                        sm={{ size: 6, offset: 3 }}
                        className="d-flex flex-column justify-content-center"
                    >
                        <NoSearchResultsDeactivated spaceAlias={spaceAlias} />
                    </Col>
                </Row>
            </Container>
        )
    }

    if (memberManagementError) {
        return (
            <Container size="xl">
                <h2 className="my-3">Deactivated commuters</h2>
                <div className="w-50">{searchBar}</div>
                <ErrorTryAgain errorText="We couldn't load your roster records" />
            </Container>
        )
    }

    return (
        <Container size="xl">
            <h2 className="my-3">Deactivated commuters</h2>
            <Row className="mb-4 mx-0 justify-content-between">
                <Col className="px-0">{searchBar}</Col>
                <Col className="px-0 text-right">
                    <Link
                        className="btn btn-outline-primary text-decoration-none mr-2"
                        to="upload"
                        onClick={() =>
                            track('deactivate_carpoolers_action', {
                                action: 'Click',
                                label: 'upload_deactivate_list',
                            })
                        }
                    >
                        Upload deactivate list
                    </Link>
                    <Button
                        color="primary"
                        onClick={() => {
                            dispatch(
                                createOpenModal({
                                    name: 'DOWNLOAD_CSV',
                                    data: { status: 'deactivated' },
                                })
                            )
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'confirm_download_csv',
                            })
                        }}
                        label="Download CSV"
                        loadingLabel="Preparing CSV"
                        size="md"
                        block={false}
                        isLoading={downloadCsvLoading}
                    />
                </Col>
            </Row>
            <Row className="mb-2 mx-0">
                <TotalPageCount totalCount={totalCount} />
            </Row>
            <Table
                className="bg-white px-0 mb-4"
                columnHeaders={columnHeaders}
                rows={deactivationsData}
                transformRows={transformCarpoolerData}
                isLoading={isCarpoolersLoading}
                placeHolderRowAmount={placeHolderRowAmount}
            />
            <Row className="mb-4 mx-0">
                <TotalPageCount
                    totalCount={totalCount}
                    fromResult={fromResult}
                    toResult={toResult}
                />
                <Pagination
                    className="ml-auto"
                    showPrevious={showPrevious}
                    showNext={showNext}
                    currentPage={page}
                    pagesToShow={pagesToShow}
                    onPageChange={(newPage) =>
                        getCarpoolerDeactivations({
                            query,
                            sortBy,
                            sortOrder,
                            page: newPage,
                        })
                    }
                    onPreviousClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'previous_page',
                        })
                    }
                    onNextClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'next_page',
                        })
                    }
                    onPageNumberClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'page_number',
                        })
                    }
                />
            </Row>
        </Container>
    )
}

MemberManagementDeactivated.propTypes = {
    spaceId: PropTypes.string.isRequired,
    publicDisplayName: PropTypes.string.isRequired,
    spaceAlias: PropTypes.string.isRequired,
}

export default withReloadable(MemberManagementDeactivated)
