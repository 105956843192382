import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { VaccinationLogRecordResource } from '../../types'
import VaccinationStatusBadge from '../../components/VaccinationStatusBadge'

const VaccinationStatusColumnRenderer = (
    props: ICellRendererParams
): JSX.Element => {
    const { data } = props as { data: VaccinationLogRecordResource }

    return (
        <VaccinationStatusBadge
            vaccinationStatus={data.status}
            reviewStatus={data.proof?.reviewStatus}
        />
    )
}

export default VaccinationStatusColumnRenderer
