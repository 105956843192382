import React, { useCallback } from 'react'

import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { ButtonContainer, TokenImg } from './styled'
import srcError from '../../images/error.svg'
import { createSessionTransactionOktaCreateRequest } from '../../state/auth'

export const OktaSignInError = () => {
    const dispatch = useDispatch()

    const boundHandleClickTryAgain = useCallback(
        () => dispatch(createSessionTransactionOktaCreateRequest()),
        [dispatch]
    )

    return (
        <>
            <TokenImg className="mx-auto d-block mt-5" src={srcError} />
            <h4 className="text-center mb-5 mt-3">
                We couldn&apos;t complete sign in.
            </h4>
            <ButtonContainer className="mx-auto">
                <Button
                    color="primary"
                    size="lg"
                    block
                    style={{
                        width: '350px',
                        margin: 'auto',
                    }}
                    onClick={boundHandleClickTryAgain}
                >
                    Try Again
                </Button>
            </ButtonContainer>
        </>
    )
}

export default OktaSignInError
