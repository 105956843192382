import { Redirect } from '@reach/router'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useEffect } from 'react'
import { Cookies, withCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'

import CenteredLayout from '../../components/Layout/Centered'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import UsernamePassword from '../../components/UsernamePassword'
import Verify2FACode from '../../components/Verify2FACode'
import useAnalytics from '../../hooks/useAnalytics'
import useCheckSession from '../../hooks/useCheckSession'
import {
    createFactorResendRequest,
    createFactorVerifyRequest,
    createSessionTransactionEmailPasswordCreateRequest,
    selectFactor,
    selectSessionTransaction,
    selectSessionTransactionCreateRequest,
} from '../../state/auth'

const statusSuccess = 'SUCCESS'
const status2FaVerify = 'MFA_VERIFY' // Verify a 2FA code
const status2FaEnroll = 'MFA_ENROLL' // Create a new 2FA phone number
const defaultNext = '/'
const verifyPrompt = (lastFour) =>
    `Please enter the 6-digit code we sent to your phone number ending in ${lastFour}.`

/*
 * Guide the end-user through the sign-in flow.
 *
 * 1) [if session.status === SUCCESS]
 * 1a) Redirect to the URL identified by `next` query string parameter.
 *
 * 2) [if !session]
 * 2a) Accept username and password from the end-user.
 * 2b) dispatch SESSION_EMAIL_PASSWORD action.
 *
 * 3) [if session.status === MFA_VERIFY]
 * 3a) Accept 2FA verification code from the end-user.
 * 3b) dispatch FACTOR_VERIFY action.
 *
 * 4) [if session.status === MFA_ENROLL]
 * 4a) Send user to setup a new 2FA phone number
 */
const SignIn = ({ location, cookies }) => {
    const { next = defaultNext } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    })
    const { isFetching, error: sessionCreateError } = useSelector(
        selectSessionTransactionCreateRequest
    )
    const {
        sessionTransactionId,
        status: sessionStatus,
        stateToken,
    } = useSelector(selectSessionTransaction)
    const { factorId, phoneLast4 } = useSelector(selectFactor)

    const {
        isFetching: sessionTransactionCheckIsFetching,
        success: sessionTransactionCheckSuccess,
    } = useCheckSession({ redirect: false })
    const { track } = useAnalytics()
    const sessionCreateErrorWrongCredentials =
        sessionCreateError?.statusCode === 401

    const dispatch = useDispatch()

    useEffect(() => {
        cookies.set('preferred-sign-in', 'emailAndPassword', {
            path: '/',
            httpOnly: false,
            sameSite: true,
        })
    }, [cookies])

    const boundAuthLogin = useCallback(
        ({ username, password }) =>
            dispatch(
                createSessionTransactionEmailPasswordCreateRequest({
                    email: username,
                    password,
                })
            ),
        [dispatch]
    )

    const boundFactorVerify = useCallback(
        (code) =>
            dispatch(
                createFactorVerifyRequest({
                    factorId,
                    sessionTransactionId,
                    stateToken,
                    code,
                })
            ),
        [dispatch, sessionTransactionId, factorId, stateToken]
    )
    const boundFactorResend = useCallback(
        () =>
            dispatch(
                createFactorResendRequest({
                    factorId,
                    sessionTransactionId,
                    stateToken,
                })
            ),
        [dispatch, factorId, sessionTransactionId, stateToken]
    )

    let form
    const is2faVerifyStep = sessionStatus === status2FaVerify
    const is2faVerifyEnroll = sessionStatus === status2FaEnroll

    // Handle analytics tracking
    // Each analytics event requires its own useEffect to prevent duplicate calls
    useEffect(() => {
        if (is2faVerifyStep) {
            track('sign_in_action', { action: 'View', label: 'enter_code' })
        }
    }, [is2faVerifyStep, track])

    useEffect(() => {
        track('sign_in_action', { action: 'View', label: 'pw_screen' })
    }, [track])

    if (sessionStatus === statusSuccess || sessionTransactionCheckSuccess) {
        return <Redirect to={next} noThrow />
    } else if (sessionTransactionCheckIsFetching) {
        form = <SpinnerFullPage />
    } else if (is2faVerifyStep) {
        // prompt the user to verify their 2FA code.
        form = (
            <Verify2FACode
                message={verifyPrompt(phoneLast4)}
                resendCode={boundFactorResend}
                onSubmit={(code) => {
                    boundFactorVerify(code)
                    track('sign_in_action', {
                        action: 'Click',
                        label: 'verify_code_enabled',
                    })
                }}
            />
        )
    } else if (is2faVerifyEnroll) {
        return <Redirect to="/mfa-enroll" noThrow />
    } else {
        // For all other states, start the sign-in flow from the beginning.
        form = (
            <>
                <UsernamePassword
                    onSubmit={(params) => {
                        boundAuthLogin(params)
                        track('sign_in_action', {
                            action: 'Click',
                            label: 'next_enabled',
                        })
                    }}
                    isLoading={isFetching}
                    showError={sessionCreateErrorWrongCredentials}
                />
            </>
        )
    }
    return <CenteredLayout squished>{form}</CenteredLayout>
}

SignIn.propTypes = {
    location: PropTypes.object,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
}

export default withCookies(SignIn)
