import styled from 'styled-components'

const Overflow = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ${({ type }) => type || 'ellipsis'};

    line-height: ${(props) =>
        props.lineHeight ? `${props.lineHeight}px` : 'inherit'};
`
export default Overflow
