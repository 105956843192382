import React, { useCallback, useContext } from 'react'
import { Row, Col, Container } from 'reactstrap'
import useFetch, { CachePolicies } from 'use-http'

import Table from './Table'
import DropdownMenu from './DropdownMenu'
import HrisSyncLabel from '../../components/HrisSyncLabel'
import LinkButton from '../../components/LinkButton'
import Pagination from '../../components/Pagination'
import TotalPageCount from '../../components/TotalPageCount'
import SearchBar from '../../components/SearchBar'
import IntegrationsProvider from '../../providers/IntegrationsProvider'
import { useSpaces } from '../../providers/SpacesProvider'
import toast from '../../components/Toast/toast'
import { EmployeeRosterContext } from '../../providers/EmployeeRosterProvider'
import Header from '../../components/PageLayout/Header'

const defaultFetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

type Header = {
    key: string
    isSortable: boolean
    label: string
    width?: number
}

const RosterList = (): JSX.Element => {
    const { id: spaceId, alias: spaceAlias } = useSpaces()
    const { post, response } = useFetch(defaultFetchOptions)
    const {
        getNotYetInvitedData,
        notYetInvitedCount,
        pendingCount,
        getPendingData,
        datatable: {
            getData,
            options,
            handleSort,
            handleSearch,
            isLoading,
            totalCount,
            paginatedData: records,
            pagination,
        },
    } = useContext(EmployeeRosterContext)

    const sendInvite = useCallback(
        async (rosterRecordId) => {
            await post(
                `/spaces/${spaceId}/rosterRecords/${rosterRecordId}/invitations`,
                {}
            )

            if (!response.ok) {
                toast.error(
                    'We were unable to invite your employee to join Scoop. Please try again.'
                )
                return
            }

            await getData({})
            await getNotYetInvitedData()
            await getPendingData()
            toast.success('1 employee was invited to join Scoop.')
        },
        [post, getData, response, spaceId, getNotYetInvitedData, getPendingData]
    )

    return (
        <Container fluid data-test="roster-management-page">
            <Header title="Roster Management" />
            <Row className="mb-2">
                <Col className="mb-3">
                    <SearchBar
                        onSearch={handleSearch}
                        initialValue={options.searchKeyword}
                        placeholder="Search by name, email, or employee ID"
                        isSearching={Boolean(
                            isLoading && options.searchKeyword
                        )}
                        onClear={() => handleSearch('')}
                    />
                </Col>
                <Col
                    className="d-flex align-items-start"
                    data-test="roster-option-buttons"
                >
                    <LinkButton
                        data-test="invite-bulk-button"
                        className="ml-auto"
                        to={`/spaces/${spaceAlias}/roster/invite`}
                    >
                        Invite employees
                    </LinkButton>
                    <LinkButton
                        className="ml-3"
                        to={`/spaces/${spaceAlias}/roster/upload/choose-csv`}
                    >
                        Upload employee roster
                    </LinkButton>
                    {(notYetInvitedCount > 0 || pendingCount > 0) && (
                        <DropdownMenu test-attr="bulk-table-actions" />
                    )}
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <TotalPageCount totalCount={totalCount} label="employees" />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Table
                        isLoading={isLoading}
                        rosterRecords={records}
                        sendInvite={sendInvite}
                        onSort={handleSort}
                        sortBy={options.sort.sortBy}
                        sortOrder={options.sort.sortOrder}
                    />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col className="d-flex align-items-center">
                    {pagination.pagesToShow.length > 1 && (
                        <TotalPageCount
                            data-test="total-page-count"
                            totalCount={totalCount}
                            fromResult={pagination.fromResult}
                            toResult={pagination.toResult}
                            label="employees"
                        />
                    )}
                    <Pagination
                        data-test="pagination"
                        test-attr="pagination"
                        className="ml-auto mb-0"
                        showPrevious={pagination.showPrevious}
                        showNext={pagination.showNext}
                        currentPage={pagination.currentPage}
                        onPageChange={pagination.changePage}
                        pagesToShow={pagination.pagesToShow}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <IntegrationsProvider>
                        <HrisSyncLabel />
                    </IntegrationsProvider>
                </Col>
            </Row>
        </Container>
    )
}

export default RosterList
