import { PropTypes } from 'prop-types'
import React from 'react'

import MetricTooltip from './Tooltip'
import { Title } from './styled'

const Layout = ({
    isUltraText,
    title,
    tooltipBody,
    children,
    onClick,
    onMouseEnter,
    analytics,
}) => {
    return (
        <div
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            className="d-flex flex-column justify-content-center"
            style={{ height: '130px' }}
        >
            <div className="d-flex justify-content-between align-items-center py-2">
                <Title className="mb-0 ml-4" isUltraText={isUltraText}>
                    {title}

                    {tooltipBody && (
                        <MetricTooltip
                            analytics={analytics}
                            tooltipBody={tooltipBody}
                        />
                    )}
                </Title>
            </div>
            {children}
        </div>
    )
}

Layout.propTypes = {
    isUltraText: PropTypes.bool,
    title: PropTypes.node.isRequired,
    tooltipBody: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    analytics: PropTypes.object,
}

export default Layout
