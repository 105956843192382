import _ from 'lodash'
import { combineReducers } from 'redux'
import {
    CARPOOLER_DEACTIVATE_REQUEST,
    CARPOOLER_DEACTIVATE_SUCCESS,
    CARPOOLER_DEACTIVATE_FAILURE,
    CARPOOLERS_ACTIVATED_ADD,
    CARPOOLERS_ACTIVATED_REPLACE,
    CARPOOLERS_UPDATE_QUERY_TOKEN,
    CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST,
    CARPOOLERS_QUERY_FILTER_PARAMS_SUCCESS,
    CARPOOLERS_QUERY_FILTER_PARAMS_FAILURE,
    CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST,
    CARPOOLERS_DOWNLOAD_ACTIVATED_SUCCESS,
    CARPOOLERS_DOWNLOAD_ACTIVATED_FAILURE,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_SUCCESS,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_FAILURE,
    CARPOOLERS_PAGINATE_REQUEST,
    CARPOOLERS_PAGINATE_REQUEST_SUCCESS,
    CARPOOLERS_PAGINATE_REQUEST_FAILURE,
    CARPOOLERS_TOTAL_COUNT_UPDATE,
    CARPOOLER_DEACTIVATE_RESET,
    CARPOOLER_DEACTIVATIONS_ALL_RESET,
} from './types'
import deactivations from './deactivations'
import cleanUpReducerOnEvent from '../cleanUpReducerOnEvent'
import serializeError from '../serializeError'

/**
 * Store activated carpoolers.
 */
// Initialize state to null to differentiate from an empty [] response from server
export const activated = (state = null, { type, payload = {} }) => {
    switch (type) {
        case CARPOOLERS_ACTIVATED_ADD:
            return state === null
                ? [].concat(payload.activated)
                : state.concat(payload.activated)
        case CARPOOLERS_ACTIVATED_REPLACE:
            return payload.activated
        case CARPOOLER_DEACTIVATE_FAILURE:
            if (payload.error.errorId !== 'CARPOOLER_ALREADY_DEACTIVATED') {
                return state
            }

            return _.filter(
                state,
                (carpooler) => carpooler.id !== payload.carpoolerId
            )
        case CARPOOLER_DEACTIVATE_SUCCESS:
            return _.filter(
                state,
                (carpooler) => carpooler.id !== payload.carpoolerId
            )
        default:
            return state
    }
}

export const queryFilterParametersRequest = (
    state = {},
    { type, payload = {} }
) => {
    const error = serializeError(payload)
    switch (type) {
        case CARPOOLERS_PAGINATE_REQUEST:
        case CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLERS_PAGINATE_REQUEST_SUCCESS:
        case CARPOOLERS_QUERY_FILTER_PARAMS_SUCCESS:
            return {
                isFetching: false,
            }
        case CARPOOLERS_PAGINATE_REQUEST_FAILURE:
        case CARPOOLERS_QUERY_FILTER_PARAMS_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export const queryFilterParameters = (state = {}, { type, payload = {} }) => {
    switch (type) {
        case CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST:
            return {
                queryToken: state.queryToken,
                sortBy: payload.sortBy,
                sortOrder: payload.sortOrder,
                query: payload.query,
            }
        case CARPOOLERS_UPDATE_QUERY_TOKEN:
            return {
                ...state,
                queryToken: payload.queryToken,
            }
        default:
            return state
    }
}

export const totalCount = (state = null, { type, payload }) => {
    switch (type) {
        case CARPOOLERS_TOTAL_COUNT_UPDATE:
            return payload.totalCount
        default:
            return state
    }
}

export const pagination = (state = { currentPage: 1 }, { type, payload }) => {
    switch (type) {
        case CARPOOLERS_PAGINATE_REQUEST:
            return {
                currentPage: payload.currentPage,
            }
        case CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST:
            return {
                currentPage: 1,
            }
        default:
            return state
    }
}

/**
 * Track the `isFetching` status of a deactivate carpooler request.
 */
export const deactivateCarpoolerRequest = (
    state = {},
    { type, payload = {} }
) => {
    const { carpoolerId } = payload
    switch (type) {
        case CARPOOLER_DEACTIVATE_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLER_DEACTIVATE_SUCCESS:
            return {
                isFetching: false,
                carpoolerId,
            }
        case CARPOOLER_DEACTIVATE_FAILURE:
            return {
                isFetching: false,
                error: serializeError(payload.error),
            }
        case CARPOOLER_DEACTIVATE_RESET: {
            return {}
        }
        default:
            return state
    }
}

export const downloadActivatedRequest = (
    state = {},
    { type, payload = {} }
) => {
    const error = serializeError(payload)
    switch (type) {
        case CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLERS_DOWNLOAD_ACTIVATED_SUCCESS:
            return {
                isFetching: false,
            }
        case CARPOOLERS_DOWNLOAD_ACTIVATED_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export const downloadDeactivatedRequest = (
    state = {},
    { type, payload = {} }
) => {
    const error = serializeError(payload)
    switch (type) {
        case CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST:
            return {
                isFetching: true,
            }
        case CARPOOLER_DEACTIVATIONS_DOWNLOAD_SUCCESS:
            return {
                isFetching: false,
            }
        case CARPOOLER_DEACTIVATIONS_DOWNLOAD_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export default combineReducers({
    activated,
    queryFilterParametersRequest,
    deactivateCarpoolerRequest,
    queryFilterParameters,
    pagination,
    totalCount,
    downloadActivatedRequest,
    downloadDeactivatedRequest,
    deactivations: cleanUpReducerOnEvent(
        [CARPOOLER_DEACTIVATIONS_ALL_RESET],
        deactivations
    ),
})
