import React, { useContext } from 'react'
import PropTypes from 'prop-types'

export const CommuteContext = React.createContext()

export const useCommute = () => {
    const context = useContext(CommuteContext) || {}

    return {
        ...context,
    }
}

const CommuteProvider = ({ children, value }) => {
    return (
        <CommuteContext.Provider value={value}>
            {children}
        </CommuteContext.Provider>
    )
}

CommuteProvider.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.shape({
        // Common props
        changePeriod: PropTypes.func.isRequired,
        haveTripsStarted: PropTypes.bool,
        isDataReady: PropTypes.bool,
        period: PropTypes.shape({
            month: PropTypes.number,
            quarter: PropTypes.number,
            week: PropTypes.number,
            year: PropTypes.number,
        }),

        // Metric-specific props
        handleMetricError: PropTypes.func,
    }).isRequired,
}

export default CommuteProvider
