import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormText, Label } from 'reactstrap'

import {
    ShowHideButton,
    PasswordSuggestionsList,
    PasswordSuggestionsListItem,
} from './styled'
import Button from '../Button.tsx'
import InputPassword from '../InputPassword'
import BatteryBar from '../BatteryBar'

const Password = ({
    userCreating = false,
    onSubmit = () => {},
    onSubmitClick = () => {},
}) => {
    const [passwordInfo, setPasswordInfo] = useState({
        password: '',
        score: undefined,
        strength: undefined,
        isValid: false,
        feedbackSuggestions: [],
    })
    const {
        password,
        score,
        strength,
        isValid,
        feedbackSuggestions,
    } = passwordInfo
    const [showPassword, setShowPassword] = useState(false)
    const onFormSubmit = (e) => {
        e.preventDefault()
        onSubmit(password)
    }
    const submitDisabled = userCreating || !isValid

    return (
        <Form onSubmit={onFormSubmit}>
            <h3>Create Password</h3>
            <p>Please create a unique password of at least 8 characters.</p>
            <br />
            <FormGroup>
                <Label for="password">Password</Label>
                <ShowHideButton
                    className="float-right text-decoration-none"
                    color="link"
                    onClick={() => setShowPassword((curr) => !curr)}
                >
                    {showPassword ? 'hide' : 'show'}
                </ShowHideButton>
                <InputPassword
                    valid={isValid}
                    invalid={Boolean(password) && !isValid}
                    id="password"
                    passwordVisible={showPassword}
                    onChange={setPasswordInfo}
                />
                <FormText>Password strength: {strength}</FormText>
            </FormGroup>
            <FormGroup>
                <BatteryBar score={password ? score : undefined} />
                <PasswordSuggestionsList>
                    {feedbackSuggestions.map((text, index) => (
                        <PasswordSuggestionsListItem
                            key={index}
                            className="small"
                        >
                            {text}
                        </PasswordSuggestionsListItem>
                    ))}
                </PasswordSuggestionsList>
            </FormGroup>
            <Button
                isLoading={userCreating}
                type="submit"
                disabled={submitDisabled}
                onClick={() => onSubmitClick({ disabled: submitDisabled })}
                label="Next"
                loadingLabel="Saving Password"
            />
        </Form>
    )
}

Password.propTypes = {
    userCreating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
}

export default Password
