import React from 'react'
import { Button, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

import errorImage from '../../images/error.svg'
import { ButtonsContainer } from '../../styles/Layout'

const CouldNotLoadData = ({
    isTryAgainVisible = true,
    onTryAgainClick,
    isGoBackVisible,
    onGoBackClick,
    className = '',
    style = {},
}) => {
    return (
        <Row
            className={`justify-content-center pt-5 ${className}`}
            style={style}
        >
            <Col md="6">
                <img className="mx-auto d-block mt-5" src={errorImage} />
                <h4 className="text-center mb-3 mt-3">
                    We couldn&apos;t load your data
                </h4>

                <ButtonsContainer>
                    {isTryAgainVisible && (
                        <Button
                            test-attr="try-again-button"
                            className="btn-block"
                            size="lg"
                            color="primary"
                            onClick={onTryAgainClick}
                        >
                            Try again
                        </Button>
                    )}

                    {isGoBackVisible && (
                        <Button
                            test-attr="go-back-button"
                            className="btn-block"
                            size="lg"
                            color="primary"
                            outline
                            onClick={onGoBackClick}
                        >
                            Go back
                        </Button>
                    )}
                </ButtonsContainer>
            </Col>
        </Row>
    )
}

CouldNotLoadData.propTypes = {
    isTryAgainVisible: PropTypes.bool,
    onTryAgainClick: PropTypes.func,
    isGoBackVisible: PropTypes.bool,
    onGoBackClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
}

export default CouldNotLoadData
