import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootSaga from './sagas'
import rootReducer from './state'

const configureStore = (cosmosApi, analytics) => {
    const sagaMiddleware = createSagaMiddleware({
        context: { cosmosApi, analytics },
    })
    const middlewares = [sagaMiddleware]
    const store = createStore(
        rootReducer,
        rootReducer(undefined, {}), // setup initial state using each reducer's initial state
        composeWithDevTools(applyMiddleware(...middlewares))
    )
    sagaMiddleware.run(rootSaga)

    if (module.hot) {
        module.hot.accept('./state', () => {
            const nextReducers = require('./state')
            store.replaceReducer(nextReducers)
        })
    }
    return store
}

export default configureStore
