import React, { useContext } from 'react'
import {
    oneOfType,
    array,
    shape,
    node,
    instanceOf,
    string,
    object,
} from 'prop-types'

import CosmosApi from '../CosmosApi'

export const AppConfigurationContext = React.createContext({})

export const useAppConfiguration = () => {
    const context = useContext(AppConfigurationContext) || {}

    return {
        ...context,
    }
}

const AppConfigurationProvider = ({ children, value }) => {
    return (
        <AppConfigurationContext.Provider value={value}>
            {children}
        </AppConfigurationContext.Provider>
    )
}

AppConfigurationProvider.propTypes = {
    children: node.isRequired,
    value: shape({
        // Warning: Sometimes Analytics.js is an Array! See the `Non-minified` code snippet that Segment suggests using:
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-1-copy-the-snippet
        analytics: oneOfType([array, object]),
        cosmosApi: instanceOf(CosmosApi),
        apiUrl: string,
        nebulaUrl: string,
        oktaClientId: string,
        oktaOrgUrl: string,
    }).isRequired,
}

export default AppConfigurationProvider
