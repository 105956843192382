import React, { ReactNode } from 'react'
import { Row } from 'reactstrap'

type Props = {
    'data-test'?: string
    children: ReactNode
}

const PolicyFeatureRow = ({
    'data-test': dataTest,
    children,
}: Props): JSX.Element => (
    <Row
        data-test={dataTest}
        className="bg-white border-bottom border-left border-right px-3 py-2"
    >
        {children}
    </Row>
)

export default PolicyFeatureRow
