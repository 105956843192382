import React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'

import Routes from './Routes'
import ModuleFlag from '../../components/ModuleFlag'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const EmployeeRoster = (props: RouteComponentProps): JSX.Element => {
    return (
        <ModuleFlag
            name="rosterManagement"
            fallback={<Redirect to="../" noThrow />}
        >
            <Routes />
        </ModuleFlag>
    )
}

export default EmployeeRoster
