import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'

import useAnalytics from '../../../hooks/useAnalytics'
import EmailError from '../../../images/email-error.svg'
import srcError from '../../../images/error.svg'

export const PASSWORD_RESET_TOKEN_EXPIRED = 'PASSWORD_RESET_TOKEN_EXPIRED'
export const PASSWORD_RESET_TOKEN_NOT_FOUND = 'PASSWORD_RESET_TOKEN_NOT_FOUND'

const ErrorStates = ({ error }) => {
    const { track } = useAnalytics()
    let img, title, message

    if (error?.errorId === PASSWORD_RESET_TOKEN_EXPIRED) {
        img = srcError
        title = 'Reset password link expired'
        message =
            ' This reset password link is invalid. Please resend another reset password email and try again.'

        track('reset_pw_action', {
            action: 'View',
            label: 'password_link_expired',
        })
    } else if (error?.errorId === PASSWORD_RESET_TOKEN_NOT_FOUND) {
        img = EmailError
        title = 'Reset password link invalid'
        message =
            'This reset password link is invalid. Please go through the reset password steps again.'

        track('reset_pw_action', {
            action: 'View',
            label: 'something_went_wrong',
        })
    } else {
        img = srcError
        title = 'Something went wrong'
        message =
            'This reset password link is invalid. Please go through the reset password steps again.'

        track('reset_pw_action', {
            action: 'View',
            label: 'something_went_wrong',
        })
    }

    return (
        <>
            <img className="mx-auto d-block mt-5" src={img} />
            <h3 className="my-5 mx-auto">{title}</h3>
            <p>{message}</p>
            <Link
                className="mx-auto mt-4 text-center nav-link font-weight-bold text-primary text-decoration-none"
                to="/forgot-password/email"
            >
                Request new email
            </Link>
        </>
    )
}

ErrorStates.propTypes = {
    error: PropTypes.object,
}

export default ErrorStates
