import { Link } from '@reach/router'
import React, { useContext } from 'react'
import Breadcrumb from 'reactstrap/lib/Breadcrumb'
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem'

import { BreadcrumbsContext } from '../../providers/BreadcrumbsProvider.tsx'

/**
 * Displays breadcrumbs that are stored in the Breadcrumbs React Context
 * Breadcrumbs are:
 *  @param {String} label the label to show
 *  @param {String} link optional absolute link to where to send the user
 */
const Breadcrumbs = () => {
    const { breadcrumbs } = useContext(BreadcrumbsContext)

    if (!breadcrumbs.length) {
        return null
    }

    return (
        <Breadcrumb className="border-bottom" tag="nav" listTag="div">
            {breadcrumbs.map(({ label, link }, i) => (
                <BreadcrumbItem key={`breadcrumb${label}${i}`}>
                    {link ? <Link to={link}>{label}</Link> : label}
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    )
}

export default Breadcrumbs
