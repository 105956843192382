// Copied from TextInput from Midas/chrome-extension.
// TODO: Consider combining these into a package.

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import withField from '../../hocs/withField'

/**
 *
 * This Input is intended to be used inside a FinalForm component. It
 * has been enhanced by withField HOC which injects the necessary Final Form
 * props into the Input to handle errors, validation, onChange, etc.
 *
 * It is a text input by default, but can easily be overridden by providing
 * a type
 *
 */
const FormTextInput = ({
    // Input props
    value = '',
    type = 'text',
    autoComplete = 'off',
    invalid,
    showClearButton,
    onChange = () => {},

    // Final Form props
    showError,
    validate,

    ...otherProps
}) => {
    const inputRef = useRef()
    const isClearButtonVisible = value && showClearButton

    return (
        <InputGroup>
            <Input
                innerRef={inputRef}
                invalid={invalid}
                type={type}
                value={value}
                autoComplete={autoComplete}
                onChange={onChange}
                {...otherProps}
            />

            {isClearButtonVisible && (
                <InputGroupAddon addonType="append">
                    <Button
                        outline
                        onClick={() => {
                            inputRef.current.value = ''
                            onChange()
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </InputGroupAddon>
            )}
        </InputGroup>
    )
}

FormTextInput.propTypes = {
    // Input props
    value: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    invalid: PropTypes.bool,
    onChange: PropTypes.func,
    showClearButton: PropTypes.bool,

    // Final Form props
    showError: PropTypes.bool,
    validate: PropTypes.func,
}

export default withField(FormTextInput)
