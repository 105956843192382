import {
    SESSION_TRANSACTION_ADD,
    SESSION_TRANSACTION_CLEAR,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_SUCCESS,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_FAILURE,
    SESSION_TRANSACTION_OKTA_CREATE_REQUEST,
    SESSION_TRANSACTION_OKTA_CREATE_SUCCESS,
    SESSION_TRANSACTION_OKTA_CREATE_FAILURE,
    SESSION_TRANSACTION_CHECK_REQUEST,
    SESSION_TRANSACTION_CHECK_SUCCESS,
    SESSION_TRANSACTION_CHECK_FAILURE,
    SESSION_TRANSACTION_CHECK_CLEAR,
    FACTOR_ADD,
    FACTOR_REMOVE,
    FACTOR_ENROLL_REQUEST,
    FACTOR_ENROLL_SUCCESS,
    FACTOR_ENROLL_FAILURE,
    FACTOR_ACTIVATE_REQUEST,
    FACTOR_ACTIVATE_SUCCESS,
    FACTOR_ACTIVATE_FAILURE,
    FACTOR_VERIFY_REQUEST,
    FACTOR_VERIFY_SUCCESS,
    FACTOR_VERIFY_FAILURE,
    FACTOR_RESEND_REQUEST,
    FACTOR_RESEND_SUCCESS,
    FACTOR_RESEND_FAILURE,
    AUTH_ALL_RESET,
} from './types'

export const createSessionTransactionAdd = ({
    sessionTransactionId,
    status,
    stateToken,
    factorId,
}) => ({
    type: SESSION_TRANSACTION_ADD,
    payload: {
        sessionTransactionId,
        status,
        stateToken,
        factorId,
    },
})

export const createSessionTransactionClear = () => ({
    type: SESSION_TRANSACTION_CLEAR,
})

export const createSessionTransactionEmailPasswordCreateRequest = ({
    email,
    password,
}) => ({
    type: SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST,
    payload: {
        email,
        password,
    },
})

export const createSessionTransactionEmailPasswordCreateSuccess = ({
    sessionTransactionId,
}) => ({
    type: SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_SUCCESS,
    payload: {
        sessionTransactionId,
    },
})

export const createSessionTransactionEmailPasswordCreateFailure = ({
    error,
}) => ({
    type: SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_FAILURE,
    error: true,
    payload: error,
})

export const createSessionTransactionOktaCreateRequest = () => ({
    type: SESSION_TRANSACTION_OKTA_CREATE_REQUEST,
})

export const createSessionTransactionOktaCreateSuccess = ({
    sessionTransactionId,
}) => ({
    type: SESSION_TRANSACTION_OKTA_CREATE_SUCCESS,
    payload: {
        sessionTransactionId,
    },
})

export const createSessionTransactionOktaCreateFailure = ({ error }) => ({
    type: SESSION_TRANSACTION_OKTA_CREATE_FAILURE,
    error: true,
    payload: error,
})

export const createSessionTransactionCheckRequest = () => ({
    type: SESSION_TRANSACTION_CHECK_REQUEST,
})

export const createSessionTransactionCheckSuccess = ({
    displayName,
    type,
}) => ({
    type: SESSION_TRANSACTION_CHECK_SUCCESS,
    payload: {
        displayName,
        type,
    },
})

export const createSessionTransactionCheckFailure = ({ error }) => ({
    type: SESSION_TRANSACTION_CHECK_FAILURE,
    error: true,
    payload: error,
})

export const createSessionTransactionCheckClear = () => ({
    type: SESSION_TRANSACTION_CHECK_CLEAR,
})

export const createFactorRemove = () => ({
    type: FACTOR_REMOVE,
})

export const createFactorAdd = ({ factorId, type, phoneLast4 }) => ({
    type: FACTOR_ADD,
    payload: {
        factorId,
        type,
        phoneLast4,
    },
})

export const createFactorEnrollRequest = ({
    phoneNumber,
    stateToken,
    sessionTransactionId,
}) => ({
    type: FACTOR_ENROLL_REQUEST,
    payload: {
        phoneNumber,
        stateToken,
        sessionTransactionId,
    },
})

export const createFactorEnrollSuccess = ({ factorId }) => ({
    type: FACTOR_ENROLL_SUCCESS,
    payload: {
        factorId,
    },
})

export const createFactorEnrollFailure = ({ error }) => ({
    type: FACTOR_ENROLL_FAILURE,
    error: true,
    payload: error,
})

export const createFactorActivateRequest = ({
    factorId,
    sessionTransactionId,
    stateToken,
    code,
}) => ({
    type: FACTOR_ACTIVATE_REQUEST,
    payload: {
        factorId,
        sessionTransactionId,
        stateToken,
        code,
    },
})

export const createFactorActivateSuccess = () => ({
    type: FACTOR_ACTIVATE_SUCCESS,
})

export const createFactorActivateFailure = ({ error }) => ({
    type: FACTOR_ACTIVATE_FAILURE,
    error: true,
    payload: error,
})

export const createFactorResendRequest = ({
    factorId,
    stateToken,
    sessionTransactionId,
}) => ({
    type: FACTOR_RESEND_REQUEST,
    payload: {
        factorId,
        stateToken,
        sessionTransactionId,
    },
})

export const createFactorResendSuccess = () => ({
    type: FACTOR_RESEND_SUCCESS,
})

export const createFactorResendFailure = ({ error }) => ({
    type: FACTOR_RESEND_FAILURE,
    error: true,
    payload: error,
})

export const createFactorVerifyRequest = ({
    factorId,
    sessionTransactionId,
    stateToken,
    code,
}) => ({
    type: FACTOR_VERIFY_REQUEST,
    payload: {
        factorId,
        sessionTransactionId,
        stateToken,
        code,
    },
})

export const createFactorVerifySuccess = ({ factorId }) => ({
    type: FACTOR_VERIFY_SUCCESS,
    payload: {
        factorId,
    },
})

export const createFactorVerifyFailure = ({ error }) => ({
    type: FACTOR_VERIFY_FAILURE,
    error: true,
    payload: error,
})

export const createAuthAllReset = () => ({
    type: AUTH_ALL_RESET,
})
