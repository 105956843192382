import React from 'react'
import { AgGridColumnProps } from 'ag-grid-react'

import TableActionCell from './TableActionCell'
import AgGridWrapper from '../../components/AgGridWrapper'
import {
    RosterRecord,
    StatusLabels,
    InvitationStatus,
    RosterStatus,
} from '../../types'
import { PaginatedData } from '../../hooks/useServerPagination'

export const getStatusColumn = ({
    invitationStatus,
    status,
}: RosterRecord): StatusLabels => {
    const displayStatus =
        invitationStatus === InvitationStatus.notYetInvited &&
        status === RosterStatus.pending
            ? InvitationStatus.notYetInvited
            : status

    return StatusLabels[displayStatus]
}

export const shouldHideEmploymentType = (rosterRecords): boolean => {
    const recordWithEmploymentType = rosterRecords?.find(record => !!record.employmentType)
    return !recordWithEmploymentType
}

type Props = {
    isLoading: boolean
    rosterRecords: PaginatedData<RosterRecord>
    sendInvite: (rosterRecordId: string) => void
    onSort: (colId: string) => void
    sortBy?: string
    sortOrder?: string
}

const Table = ({
    isLoading,
    rosterRecords,
    sendInvite,
    onSort,
    sortBy,
    sortOrder,
}: Props): JSX.Element => {

    const columnDefinitions: AgGridColumnProps[] = [
        {
            // Set colId to "firstName" to sort data by first name.
            colId: 'firstName',
            headerName: 'Name',
            pinned: true,
            sortable: true,
            comparator: () => 0, // Disables automatic sorting by AgGrid.
            width: 250,
            valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
        },
        {
            colId: 'email',
            field: 'email',
            headerName: 'Email',
            sortable: true,
            comparator: () => 0, // Disables automatic sorting by AgGrid.
            width: 250,
        },
        {
            colId: 'employee-id',
            field: 'externalIdentifier',
            headerName: 'Employee ID',
            width: 250,
        },
        {
            colId: 'department',
            field: 'department.label',
            headerName: 'Department',
        },
        {
            colId: 'status',
            headerName: 'Status',
            resizable: false,
            maxWidth: 150,
            valueGetter: ({ data }: { data: RosterRecord }) =>
                getStatusColumn(data),
        },
        {
            colId: 'manager',
            headerName: 'Manager',
            valueGetter: ({ data }: { data: RosterRecord }) => {
                const manager = data?.manager?.managerRosterRecord
                return manager ? `${manager.firstName} ${manager.lastName}` : ''
            },
        },
        {
            colId: 'employmentType',
            field: 'employmentType',
            headerName: 'Employment Type',
            width: 250,
            hide: shouldHideEmploymentType(rosterRecords)
        },
        {
            colId: 'action',
            headerName: 'Action',
            cellRenderer: 'actionCell',
            cellRendererParams: {
                sendInvite,
            },
            maxWidth: 100,
            resizable: false,
            cellStyle: {
                lineHeight: '1.5rem',
            },
            cellClass: 'overflow-visible',
        },
    ]


    return (
        <AgGridWrapper
            data-test="roster-table"
            isLoading={isLoading}
            columnDefs={columnDefinitions}
            rowData={rosterRecords}
            noRowsOverlayComponentParams={{
                description: 'No employees found.',
            }}
            defaultColDef={{
                resizable: true,
                suppressMovable: true,
                flex: 1,
                unSortIcon: true,
            }}
            frameworkComponents={{
                actionCell: TableActionCell,
            }}
            onSort={onSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
        />
    )
}

export default Table
