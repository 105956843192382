import moment from 'moment'
import React from 'react'

import { workAttendanceIndication } from '../../../../models/workAttendanceIndication'

const WorkplaceCheckInPanelSuccess = ({ workAttendanceIndication }) => {
    const {
        rosterRecord,
        localCalendarDate,
        healthAndSafetySubmission,
    } = workAttendanceIndication

    const name = `${rosterRecord.firstName} ${rosterRecord.lastName}`

    return (
        <>
            <h2 className="mb-5">Worker check-in confirmation</h2>

            <p>
                Name
                <br />
                <b>{name}</b>
            </p>
            <p>
                Checked-in for
                <br />
                <b>{moment(localCalendarDate).format('L')}</b>
            </p>
            <p>
                Certified the following at
                <br />
                <b>
                    {moment(healthAndSafetySubmission.createdAt).format(
                        'L hh:mm a'
                    )}
                </b>
            </p>

            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    They had not experienced any Centers for Disease Control and
                    Prevention (CDC) COVID-19 symptoms in the previous 14 days
                    which were new and not explained by another condition.
                    https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    They had not been exposed to anyone with known or suspected
                    COVID-19 in the previous 14 days.
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    If they had tested positive for COVID-19 and had no
                    symptoms, it had been at least 10 days since the positive
                    test.
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    If they had tested positive for COVID-19 and were
                    symptomatic, it had been at least 10 days since symptoms
                    first appeared and 24 hours with no fever without the use of
                    fever-reducing medications and other symptoms of COVID-19
                    were improving.
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    If they were to develop any COVID-19 symptoms, they would
                    not commute to work and would update the Scoop app.
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    If they commuted using Scoop, they agreed to Scoop&#39;s
                    Health &#038; Safety guidelines, which include a release of
                    liability and assumption of risk.
                    https://takescoop.zendesk.com/hc/en-us/articles/360046872874-Scoop-s-Coronavirus-Health-and-Safety-Guidelines
                </p>
            </div>
            <div className="d-flex flex-row">
                <div className="ml-4">-</div>
                <p className="ml-3">
                    If they used another commute mode, they agreed to follow the
                    CDC&#39;s transportation guidelines.
                    https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/using-transportation.html
                </p>
            </div>
        </>
    )
}

WorkplaceCheckInPanelSuccess.propTypes = { workAttendanceIndication }

export default WorkplaceCheckInPanelSuccess
