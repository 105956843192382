// Side Effects
export const POST_METADATA = 'POST:METADATA'
export const POST_SESSION_TRANSACTION = 'POST:SESSION_TRANSACTION'

// Action Types
export const ADD_METADATA_ERROR = 'ADD:METADATA_ERROR'
export const ADD_EMAIL = 'ADD:EMAIL'
export const ADD_PASSWORD = 'ADD:PASSWORD'
export const ADD_SESSION = 'ADD:SESSION'

export const initialState = {
    email: undefined,
    password: undefined,
    session: undefined,
    metadataError: undefined,
    sideEffect: POST_METADATA,
}

export function forgotPasswordSteps(state, { payload, type }) {
    switch (type) {
        case ADD_METADATA_ERROR:
            return {
                ...state,
                metadataError: payload,
                sideEffect: undefined,
            }
        case ADD_EMAIL:
            return {
                ...state,
                email: payload,
                sideEffect: undefined,
            }

        case ADD_PASSWORD:
            return {
                ...state,
                password: payload,
                sideEffect: POST_SESSION_TRANSACTION,
            }

        case ADD_SESSION:
            return {
                ...state,
                session: payload,
                sideEffect: undefined,
            }
        default:
            return initialState
    }
}
