import { useState, useRef } from 'react'
import useFetch from 'use-http'

import { useAppConfiguration } from '../../../../providers/AppConfigurationProvider'

const fetchOptions = {
    cachePolicy: 'no-cache',
    mode: 'cors',
}

/**
 * This hook exports coordinates for an address when making a request to the public api with a
 * Google Places ID in order to receive a "Scoop-like" representation Google Maps Place
 * https://api-docs.scoopcommute.com/api-public#places_resolve_get
 *
 */
const useFetchResolveAddress = () => {
    const { apiUrl } = useAppConfiguration()
    const [isLoading, setIsLoading] = useState(false)
    const [address, setAddress] = useState(undefined)
    const placeIdRef = useRef()

    const { get, error: networkError, response, data } = useFetch(
        `${apiUrl}/places/resolve`,
        fetchOptions
    )

    /**
     *
     * @param {String} placeId - required the first time this function is called. It stores the placeId in a ref just in case
     *                           the function is invoked again without an argument. This can occur if the user clicks
     *                           the "Try Again" button in case of an error.
     */

    async function getAddressById(placeId) {
        setIsLoading(true)

        if (placeId) {
            placeIdRef.current = placeId
        }

        const id = placeId || placeIdRef.current

        const data = await get(`?placeId=${id}`)

        if (response.ok) {
            const streetAddress = formatAddress({ ...data.data.address })
            const { lat, lng } = data.data.address.coordinates

            setAddress({
                streetAddress,
                coordinates: {
                    latitude: lat,
                    longitude: lng,
                },
            })
            setIsLoading(false)
            return streetAddress
        }

        setIsLoading(false)

        return undefined
    }

    const error = data?.error || networkError

    function clearAddress() {
        setAddress(undefined)
    }

    return {
        isLoading,
        hasError: Boolean(error),
        getAddressById,
        address,
        clearAddress,
    }
}

export default useFetchResolveAddress

function formatAddress({ streetAddress, city, state, postalCode }) {
    return `${streetAddress}, ${city}, ${state}, ${postalCode}`
}
