import { shape, string } from 'prop-types'
import React from 'react'

import useAnalytics from '../../../hooks/useAnalytics'
import ModuleFlag from '../../ModuleFlag'
import SideBarLink from '../SideBarLink'

const ReportsSideBar = ({ space }) => {
    const { track } = useAnalytics()

    return (
        <>
            <ModuleFlag name="tripReports">
                <SideBarLink
                    text="Trip Reports"
                    to={`${space.alias}/commute/reports/trip`}
                    query="" // No query params needed for this route
                    onClick={() => {
                        track('trip_reports_action', {
                            action: 'Click',
                            label: 'trip_reports_sidebar',
                        })
                    }}
                />
            </ModuleFlag>
            <ModuleFlag name="carpoolerReports">
                <SideBarLink
                    text="Commuter Reports"
                    to={`${space.alias}/commute/reports/commuter`}
                    query="" // No query params needed for this route
                    onClick={() => {
                        track('carpooler_reports_action', {
                            action: 'Click',
                            label: 'carpooler_reports_sidebar',
                        })
                    }}
                />
            </ModuleFlag>
        </>
    )
}

ReportsSideBar.propTypes = {
    space: shape({
        alias: string,
    }).isRequired,
}

export default ReportsSideBar
