import { useCallback, useMemo } from 'react'
import qs from 'qs'
import useFetch, { CachePolicies, Res } from 'use-http'
import { formatISO } from 'date-fns'
import {
    CosmosError,
    EmployeeExperienceConfiguration,
    ResponseError,
} from '../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

export type employeeExperienceConfigurationType =
    | 'checkInCapacity'
    | 'recurringCheckInRequirementOverrides'

type Props = {
    spaceId: string
    type: employeeExperienceConfigurationType
}

type CreateOrUpdateExperienceParams = {
    experienceId: string
    eligibilityProfile: {
        id: string
    }
    value: number
    startAt: string
    endAt: string
}

type UpdateExperienceCapacityParams = {
    buildingId?: string
    experienceId?: string
    capacity: number | null
}

type PatchExperienceParams = {
    experienceId?: string
    endAt: string
}

type UseEmployeeExperienceResourceReturnType = {
    isLoading: boolean
    response: Res<EmployeeExperienceConfiguration | ResponseError>
    configuration: EmployeeExperienceConfiguration[] | null | undefined
    error: Error | CosmosError
    createOrUpdateExperience: (
        params: CreateOrUpdateExperienceParams
    ) => Promise<EmployeeExperienceConfiguration | ResponseError>
    updateExperienceCapacity: (
        params: UpdateExperienceCapacityParams
    ) => Promise<EmployeeExperienceConfiguration | ResponseError>
    patchExperience: (
        params: PatchExperienceParams
    ) => Promise<EmployeeExperienceConfiguration | ResponseError>
    getExperience: () => Promise<
        EmployeeExperienceConfiguration | ResponseError
    >
}

const useEmployeeExperienceResource = ({
    spaceId,
    type,
}: Props): UseEmployeeExperienceResourceReturnType => {
    const {
        post,
        get,
        patch,
        data,
        loading: isLoading,
        response,
        error: fetchError,
    } = useFetch<EmployeeExperienceConfiguration[] & ResponseError>(
        `/spaces/${spaceId}/employeeExperienceConfigurations`,
        fetchOptions
    )

    const configuration = useMemo(() => {
        if (response.ok) {
            return data
        }
        return null
    }, [data, response])

    const error = useMemo(() => {
        return data?.error || fetchError
    }, [data, fetchError])

    const createOrUpdateExperience = useCallback(
        async ({ experienceId, eligibilityProfile, value, startAt, endAt }) => {
            return post(
                qs.stringify(
                    { configurationToReplace: experienceId },
                    { addQueryPrefix: true }
                ),
                { type, eligibilityProfile, value, startAt, endAt }
            )
        },
        [post, type]
    )

    const patchExperience = useCallback(
        async ({ experienceId, endAt = formatISO(new Date()) }) => {
            return patch(`/${experienceId}`, { endAt })
        },
        [patch]
    )

    const getExperience = useCallback(async () => get(`?type=${type}`), [
        get,
        type,
    ])

    /**
     * Make a request to update max capacity
     * and throw a custom error if needed.
     *
     * @param {string}] props.experienceId
     * @param {number | null}] props.capacity
     *
     * @returns {Object} response data
     *
     */
    const updateExperienceCapacity = useCallback(
        async ({ buildingId, experienceId, capacity }) => {
            if (type !== 'checkInCapacity') {
                throw new Error(
                    `tryUpdatingExperienceCapacity only works with when the experience type is 'checkInCapacity'`
                )
            }

            // If capacity is anything other than a number greater than 1, treat it as null
            const capacityValue = capacity ? parseInt(capacity, 10) : null

            if (capacityValue === null) {
                // Make a PATCH request for disabling capacity enforcement.
                return await patchExperience({
                    experienceId,
                })
            } else {
                // Make a POST request for updating capacity enforcement.
                const eligibilityProfile = {
                    building: { id: buildingId },
                }

                return await createOrUpdateExperience({
                    experienceId,
                    eligibilityProfile,
                    value: capacityValue,
                })
            }
        },
        [type, patchExperience, createOrUpdateExperience]
    )

    return {
        isLoading,
        response,
        configuration,
        error,
        createOrUpdateExperience,
        updateExperienceCapacity,
        patchExperience,
        getExperience,
    }
}

export default useEmployeeExperienceResource
