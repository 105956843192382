import React from 'react'
import PropTypes from 'prop-types'
import zxcvbn from 'zxcvbn'
import { Input } from 'reactstrap'

export const defaultComplexityFunc = (score) => score >= 3
export const scoreToWord = {
    0: 'very weak',
    1: 'weak',
    2: 'fair',
    3: 'strong',
    4: 'very strong',
}

/*
 * This Input field encapsulates password validation logic. Users of this
 * component should pass an `onChange` function to receive information about the
 * password (score, strength, isValid, and suggestions).
 */
const InputPassword = ({
    passwordVisible = false,
    onChange = () => {},
    complexityFunc = defaultComplexityFunc,
    valid = false,
    invalid = false,
}) => {
    const inputType = passwordVisible ? 'text' : 'password'
    const onPasswordChange = (e) => {
        const password = e.target.value
        const results = zxcvbn(password)
        onChange({
            password,
            score: results.score,
            strength: scoreToWord[results.score],
            isValid: complexityFunc(results.score),
            feedbackSuggestions: results.feedback.suggestions,
        })
    }
    return (
        <Input
            valid={valid}
            invalid={invalid}
            type={inputType}
            onChange={onPasswordChange}
        />
    )
}

InputPassword.propTypes = {
    passwordVisible: PropTypes.bool,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    onChange: PropTypes.func,
    complexityFunc: PropTypes.func,
}

export default InputPassword
