import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDom from 'react-dom'
import 'mapbox-gl/dist/mapbox-gl.css'

import BaseApp from './BaseApp'
import analytics from './analytics'
import registerBrowserConnectivityEvents from './util/browserConnectivityEvents'
import { configureDatadogRUM, configureDatadogLogs } from './datadog.ts'

const appContainer = document.getElementById('app')
const {
    apiRoot,
    apiUrl,
    nebulaUrl,
    oktaClientId,
    oktaOrgUrl,
    environment,
    mapboxApiToken,
    datadogApplicationId,
    datadogClientToken,
} = window.__SCOOP__ || {}

function render() {
    ReactDom.render(
        <BaseApp
            apiRoot={apiRoot}
            apiUrl={apiUrl}
            nebulaUrl={nebulaUrl}
            environment={environment}
            oktaClientId={oktaClientId}
            oktaOrgUrl={oktaOrgUrl}
            analytics={analytics}
            mapboxApiToken={mapboxApiToken}
        />,
        appContainer
    )
}
render()
configureDatadogRUM(datadogApplicationId, datadogClientToken, environment)
configureDatadogLogs(datadogClientToken, environment)
registerBrowserConnectivityEvents()

if (module.hot) {
    module.hot.accept('./BaseApp', () => {
        render()
    })
}
