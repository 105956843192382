import { call, getContext, takeLatest, put } from 'redux-saga/effects'
import {
    FACTOR_RESEND_REQUEST,
    createFactorResendSuccess,
    createFactorResendFailure,
} from '../../state/auth'
import toast from '../../components/Toast/toast.tsx'

const resendUri = ({ sessionTransactionId, factorId }) =>
    `/users/sessionTransactions/${sessionTransactionId}/authenticationFactors/${factorId}/resend`

export function* factorResend({ payload, meta }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { factorId, sessionTransactionId, stateToken } = payload
    const uri = resendUri({ factorId, sessionTransactionId })
    try {
        yield call([cosmosApi, 'call'], uri, {
            method: 'POST',
            body: { stateToken },
        })
        yield put(createFactorResendSuccess())
        toast.success('Code successfully resent.')
    } catch (err) {
        const errorMessage =
            err.message || `We couldn't resend your code. Please try again.`
        toast.error(errorMessage)
        return yield put(createFactorResendFailure({ error: err }))
    }
}

export default function* watchFactorResend() {
    yield takeLatest(FACTOR_RESEND_REQUEST, factorResend)
}
