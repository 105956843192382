import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from '@reach/router'

import MagnifyingGlass from '../../images/magnifying-glass.svg'

const NoSearchResultsDeactivated = ({ spaceAlias }) => {
    return (
        <>
            <img className="mx-auto d-block mt-5" src={MagnifyingGlass} />
            <h4 className="my-5 mx-auto">No commuters found</h4>
            <p>This may be due to:</p>
            <ul>
                <li>The commuter has not been deactivated</li>
                <li>
                    The commuter may be in the{' '}
                    <Link to="../active">Active commuters tab</Link>
                </li>
                <li>The e-mail is formatted incorrectly or invalid</li>
            </ul>
        </>
    )
}

NoSearchResultsDeactivated.propTypes = {
    spaceAlias: PropTypes.string.isRequired,
}

export default NoSearchResultsDeactivated
