import {
    call,
    getContext,
    takeLatest,
    put,
    select,
    all,
} from 'redux-saga/effects'
import linkParser from 'parse-link-header'
import qs from 'qs'
import {
    CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST,
    CARPOOLERS_PAGINATE_REQUEST,
    createCarpoolerPaginationRequestSuccess,
    createCarpoolerPaginationRequestFailure,
    createCarpoolersAddActivated,
    createCarpoolersReplaceActivated,
    createActivatedCarpoolersQueryFilterSuccess,
    createActivatedCarpoolersQueryFilterFailure,
    createActivatedCarpoolersQueryTokenUpdate,
    createCarpoolerTotalCountUpdate,
    selectQueryFilterParameters,
    selectActivatedCarpoolers,
    selectCarpoolerCurrentPage,
} from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'

const acceptJsonHeader = {
    accept: 'application/json',
}
export const PAGE_SIZE = 25

export const getBaseUri = (spaceId) => `/spaces/${spaceId}/activatedCarpoolers`
export const getLimitUri = (spaceId) => `${getBaseUri(spaceId)}?limit=100`

export function* fetchActivatedCarpoolers({ uri, replace }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { parsedBody: carpoolers, headers } = yield call(
        [cosmosApi, 'call'],
        uri,
        { headers: acceptJsonHeader }
    )
    const queryToken = linkParser(headers.get('Link'))?.next?.queryToken

    const totalCount = headers.get('x-total-count')

    const activated = carpoolers.map((carpooler) => ({
        id: carpooler.account.id,
        emails: carpooler.account.emails,
        firstTripAt: carpooler.firstTripAt,
        lastTripAt: carpooler.lastTripAt,
    }))
    if (totalCount) {
        yield put(createCarpoolerTotalCountUpdate(totalCount))
    }

    yield put(createActivatedCarpoolersQueryTokenUpdate({ queryToken }))

    replace
        ? yield put(createCarpoolersReplaceActivated({ activated }))
        : yield put(createCarpoolersAddActivated({ activated }))
}

export function* handleQueryParamsChange() {
    try {
        const { id: spaceId } = yield select(selectCurrentSpace)

        if (!spaceId) {
            throw new Error(
                `Can't get activated commuter data. You are currently not in any space.`
            )
        }

        const { query, sortBy, sortOrder } = yield select(
            selectQueryFilterParameters
        )
        const queryString = qs.stringify({
            q: query || undefined,
            sortBy,
            sortOrder,
        })
        const uri = `${getLimitUri(spaceId)}${
            queryString ? `&${queryString}` : ''
        }`

        yield call(fetchActivatedCarpoolers, {
            uri,
            replace: true,
        })
        yield put(createActivatedCarpoolersQueryFilterSuccess())
    } catch (err) {
        yield put(
            createActivatedCarpoolersQueryFilterFailure({
                error: err,
            })
        )
    }
}

export function* handlePaginatingCarpoolers() {
    const { queryToken } = yield select(selectQueryFilterParameters)
    const currentPage = yield select(selectCarpoolerCurrentPage)
    const carpoolers = yield select(selectActivatedCarpoolers)
    const carpoolerLen = carpoolers.length
    const lastPage = Math.floor(carpoolerLen / PAGE_SIZE)
    const requestedPageNumber = Math.max(1, currentPage)
    const paginationQueryToken =
        requestedPageNumber > lastPage && queryToken ? queryToken : undefined
    try {
        if (paginationQueryToken) {
            // Checking to see if we actually need to ask the api for more pages
            const { id: spaceId } = yield select(selectCurrentSpace)
            const uri = `${getBaseUri(spaceId)}?${qs.stringify({
                queryToken: paginationQueryToken,
            })}`

            yield call(fetchActivatedCarpoolers, {
                uri,
            })
        }
        yield put(createCarpoolerPaginationRequestSuccess())
    } catch (ex) {
        yield put(createCarpoolerPaginationRequestFailure(ex))
    }
}

export default function* watchGetActivatedCarpoolers() {
    yield all([
        takeLatest(
            CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST,
            handleQueryParamsChange
        ),
        takeLatest(CARPOOLERS_PAGINATE_REQUEST, handlePaginatingCarpoolers),
    ])
}
