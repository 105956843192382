import { shape, string } from 'prop-types'
import React from 'react'

import useAnalytics from '../../../hooks/useAnalytics'
import ModuleFlag from '../../ModuleFlag'
import SideBarLink from '../SideBarLink'

const MemberManagementSideBar = ({ space }) => {
    const { track } = useAnalytics()

    return (
        <ModuleFlag name="memberManagement">
            <strong className="d-block ml-3 py-2">Commuter Management</strong>
            <SideBarLink
                text="Active commuters"
                to={`${space.alias}/carpoolers/active`}
                onClick={() => {
                    track('edit_carpoolers_action', {
                        action: 'Click',
                        label: 'active_carpoolers_tab',
                    })
                }}
            />

            <SideBarLink
                text="Deactivated commuters"
                to={`${space.alias}/carpoolers/deactivated`}
                onClick={() =>
                    track('edit_carpoolers_action', {
                        action: 'Click',
                        label: 'deactivated_carpoolers_tab',
                    })
                }
            />
        </ModuleFlag>
    )
}

MemberManagementSideBar.propTypes = {
    space: shape({
        alias: string,
    }).isRequired,
}

export default MemberManagementSideBar
