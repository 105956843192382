import moment from 'moment'

function getFileNameTimePeriod(period) {
    if (Number.isInteger(period.month)) {
        const month = moment().month(period.month).format('MMM')

        return `${month}${period.year}`
    }

    return `${period.year}`
}

/**
 * Generates a friendly filename for report CSVs.
 *
 * @param {String} reportsName - e.g, "carpoolers" or 'trips
 * @param {Object} period  - e.g, { year: 2020, month: 0} or { year: 2020 }
 *
 */

export function getReportCSVFileName(reportsName, period) {
    const reportLabel = reportsName === 'trips' ? 'Trip' : 'Commuter'
    const dateString = moment().format('YYYY-MM-DD-hhmmss')
    const timePeriodLabel = getFileNameTimePeriod(period)

    return `Scoop${reportLabel}Report${timePeriodLabel}_${dateString}.csv`
}
