const requiredFields = ['name', 'address']

/**
 * A custom validation function.
 * If the form has all expected values, then it's valid.
 * If the form has a "name" field submission error due to "BUILDING_NAME_CONFLICT" ,
 * it's invalid, unless "name" gets updated.
 *
 * This custom validation is necessary Final-Form's because internal state of "invalid" doesn't
 * clear after the user corrects a submission error. It's still invalid no matter what.
 * See https://github.com/final-form/final-form/issues/48#issuecomment-352607401 for discussion
 *
 * @param {Object} props.formValues - current field values in the form
 * @param {Object} props.submitErrors - errors tracked by the form when a submission fails
 * @param {Object} props.dirtyFieldsSinceLastSubmit - field values that have been modified since the last submit
 *
 *
 * @returns
 */

export function checkIsFormValid({
    formValues,
    submitErrors,
    dirtyFieldsSinceLastSubmit,
}) {
    if (submitErrors?.name && !dirtyFieldsSinceLastSubmit?.name) {
        return false
    }

    // Check that formValues is truthy for each expected form value
    const formHasAllValues = requiredFields.every((value) =>
        Boolean(formValues[value])
    )

    if (!formHasAllValues) {
        return false
    }

    return true
}
