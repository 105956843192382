import React from 'react'
import { Nav, Navbar, NavItem, NavLink } from 'reactstrap'

import FeatureFlagsPopoverLink from './FeatureFlagPopoverLink'
import useEnvironment from '../../devTools/useEnvironment/index.ts'
import useAnalytics from '../../hooks/useAnalytics'

const NavBottom = () => {
    const { isLocal, isStaging } = useEnvironment()
    const { track } = useAnalytics()

    return (
        <Navbar
            style={{ height: '60px' }}
            className="bg-white border-top justify-content-center"
        >
            <Nav>
                <NavItem>
                    <NavLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.customers.scoopcommute.com/"
                        onClick={() =>
                            track('footer_action', {
                                action: 'Click',
                                label: 'footer_support',
                            })
                        }
                    >
                        Support
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.scoopcommute.com"
                        onClick={() =>
                            track('footer_action', {
                                action: 'Click',
                                label: 'footer_scoopcommute',
                            })
                        }
                    >
                        scoopcommute.com
                    </NavLink>
                </NavItem>
            </Nav>

            {(isLocal || isStaging) && <FeatureFlagsPopoverLink />}
        </Navbar>
    )
}

export default NavBottom
