import React from 'react'

type Props = {
    'data-test'?: string
    label?: string
    fromResult?: number
    toResult?: number
    totalCount?: number
}

const TotalPageCount = ({
    'data-test': dataTest,
    fromResult,
    toResult,
    totalCount,
    label = 'commuters',
}: Props): JSX.Element | null => {
    if (!totalCount) {
        return null
    }

    let paginationText = ''

    if (fromResult && toResult) {
        paginationText = `${fromResult}-${toResult} of `
    }

    return (
        <span data-test={dataTest}>
            {paginationText}
            {totalCount} {label}
        </span>
    )
}

export default TotalPageCount
