import moment from 'moment'
import React from 'react'
import { ReferenceLine } from 'recharts'

import { RangeSize, Statistic, Granularity } from '../../types'

export * from './generateYAxisTicks'
export * from './firstPeriod'
export * from './currentPeriod'
export * from './getChartDataInRange'
export * from './getIsFirstStatIncomplete'

const MONDAY = 1

/**
 * Returns an array of <ReferenceLine /> components
 * that map to Mondays on the x-axis
 */

export function generateReferenceLines(statistics: Statistic[]): JSX.Element[] {
    const mondays = statistics.filter((stat) => {
        const day = moment(stat.context.from).isoWeekday()

        return day === MONDAY
    })

    return mondays.map((stat) => (
        <ReferenceLine
            key={stat.context.from}
            x={stat.context.from}
            stroke="rgb(102, 102, 102)"
            strokeWidth={2}
        />
    ))
}

/**
 * Returns the interval of XAxis ticks to render in Recharts' <XAxis /> Component
 * <XAxis />'s props.interval accepts a number or a string
 * https://recharts.org/en-US/examples/LineChartAxisInterval
 *
 */

export function getXAxisInterval(
    rangeSize: RangeSize,
    granularity: Granularity
): string | number {
    if (rangeSize === 'month' && granularity === 'weekly') {
        return 'preserveStartEnd'
    }

    return 0
}

export function formatNumberResultTick(number: number): string | number {
    if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'K'
    }
    return number
}

export function formatDate(date: string): string {
    return moment(date).format('MM/DD/YY')
}

export function formatMonth(date: string): string {
    return moment(date).format('MMM')
}

export const granularityOptions = Object.freeze({
    weekly: 'Weekly',
    daily: 'Daily',
})

export const granularityText = Object.freeze({
    daily: 'day',
    weekly: 'week',
    monthly: 'month',
})

export function getFriendlyText(granularity: Granularity): string {
    return granularity === 'daily'
        ? 'tomorrow'
        : `next ${granularityText[granularity]}`
}

/**
 * Generates helper text if the first stat in the range of statistics is undefined depending
 * on the granularity.
 *
 * @example
 * Today is July 15, your program began on July 2nd, and you're looking at the full year.
 * -> "The first month of your program or year is incomplete. Check back next month."
 */

export function getHelperText(
    granularity: Granularity,
    rangeSize: RangeSize
): string {
    const unit = granularityText[granularity]
    const nextUnit = getFriendlyText(granularity)

    return `The first ${unit} of your program or ${rangeSize} is incomplete. Check back ${nextUnit}.`
}
