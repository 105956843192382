import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useMemo } from 'react'
import { Button, Form, Popover, PopoverBody, Spinner } from 'reactstrap'

import CheckboxList from './CheckboxList'
import TryAgain, { ImageType } from '../TryAgain'
import immutableObjectUpdate from '../../util/immutableObjectUpdate'

export type FilterOption = {
    key: string
    label: React.ReactNode
    isSelected?: boolean
}

export type OptionValue = {
    title: string
    options: FilterOption[]

    /** If true, the filter section will show a loader. */
    isLoading?: boolean

    /** If Error is passed, the error view with a retry button is displayed. */
    error?: Error
    /** Called if 'Try again' button is clicked. */
    tryAgain?: () => void
}

export type Options = {
    [key: string]: OptionValue
}

type Props = {
    options: Options
    onChange: (newOptions: Options) => void
}

const FilterDropdown = ({ options, onChange }: Props): JSX.Element => {
    const [popoverOpen, setPopoverOpen] = useState(false)

    const toggle = () => setPopoverOpen(!popoverOpen)

    const handleChange = (optionsKey: string, newOptions: FilterOption[]) => {
        const newOptionsMap = immutableObjectUpdate({
            path: [optionsKey, 'options'],
            value: newOptions,
            object: options,
        })

        onChange(newOptionsMap)
    }

    /**
     * The number of selected filters
     */
    const numSelectedFilters = useMemo(() => {
        let numSelected = 0

        for (const value of Object.values(options)) {
            for (const option of value.options) {
                if (option.isSelected) {
                    numSelected += 1
                }
            }
        }

        return numSelected
    }, [options])

    return (
        <div data-test="filter-dropdown">
            <Button
                id="filter-dropdown-button"
                data-test="filter-dropdown-button"
                type="button"
                outline={true}
                color="secondary"
                className="text-dark bg-white"
            >
                <FontAwesomeIcon className="mr-2" icon={faFilter} />
                {numSelectedFilters
                    ? `Filter (${numSelectedFilters})`
                    : 'Filter'}
            </Button>
            <Popover
                hideArrow={true}
                trigger="legacy"
                placement="bottom-end"
                isOpen={popoverOpen}
                target="filter-dropdown-button"
                toggle={toggle}
            >
                <PopoverBody>
                    <Form className="d-flex flex-column">
                        {Object.entries(options).map(([key, value]) => {
                            return (
                                <div key={key}>
                                    <div
                                        data-test="filter-set-title"
                                        className="my-2 text-muted"
                                    >
                                        {value.title}
                                    </div>

                                    {value.isLoading && (
                                        <div
                                            data-test="filter-dropdown-loader"
                                            className="my-4 d-flex justify-content-center"
                                        >
                                            <Spinner />
                                        </div>
                                    )}

                                    {!value.isLoading && (
                                        <CheckboxList
                                            options={value.options}
                                            onChange={(newOptions) =>
                                                handleChange(key, newOptions)
                                            }
                                        />
                                    )}

                                    {value.error && (
                                        <TryAgain
                                            onTryAgain={value.tryAgain}
                                            message={value.error?.message}
                                            imageType={ImageType.errorIcon}
                                            buttonProps={{
                                                size: 'sm',
                                            }}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    )
}

export default FilterDropdown
