import React from 'react'
import { Link } from '@reach/router'
import { Row, Col, Table, Button } from 'reactstrap'
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

import sampleCSVData from './sampleCSVData'
import FileUploader from '../../../../components/FileUploader'
import ErrorAlert from '../../../../components/Alert/Error/index.js'
import { PodWithNeighborhood } from '../../../../util/pods/getBuildingsFromPods'

type ListitemProps = {
    name: string
    description?: string
    children?: React.ReactNode
}

const ListItem = ({ name, description, children }: ListitemProps) => (
    <li className="py-1">
        <b>{name}:</b> {description || children}
    </li>
)

type Props = {
    hasNoBuildings: boolean
    onUploadSuccess: (file: PodWithNeighborhood[]) => void
    spaceAlias: string | undefined
    spaceId?: string | undefined
    onFileChange?: (file: File) => void
}

const UploadInstructions = ({
    hasNoBuildings,
    onUploadSuccess,
    spaceAlias,
    spaceId,
    onFileChange,
}: Props) => {
    const downloadSampleCSV = () => {
        const file = new File([sampleCSVData], 'desks-template.csv', {
            type: 'text/csv;charset=utf-8',
        })
        saveAs(file)
    }

    return (
        <div data-test="upload-instructions">
            <h4>Upload CSV</h4>
            {hasNoBuildings && (
                <ErrorAlert>
                    You must add buildings before assigning desks. Please go to
                    the{' '}
                    <Link
                        data-test="buildings-link"
                        to={`/spaces/${spaceAlias}/settings/buildings/all`}
                    >
                        buildings page
                    </Link>{' '}
                    to add buildings.
                </ErrorAlert>
            )}
            <FileUploader
                url={`/api/spaces/${spaceId}/pods/uploadBulk`}
                acceptedFileExtensions={['.csv']}
                onUploadSuccess={onUploadSuccess}
                displayErrorsAsModal
                uploadInstructions={'Drag and drop your CSV to upload'}
                isDisabled={hasNoBuildings}
                onFileChange={onFileChange}
            />
            <h5 className="py-3">How to format your CSV</h5>
            <h6>Columns</h6>
            <Row>
                <Col xs="6">
                    <ul data-test="add-desks-col-desciptions-1">
                        <ListItem name="Building">
                            The name should match the{' '}
                            <Link
                                to={`/spaces/${spaceAlias}/settings/buildings/all`}
                            >
                                buildings
                            </Link>{' '}
                            saved to your organization.
                        </ListItem>
                        <ListItem
                            name="Floor"
                            description="The name of the floor where desks are located"
                        />
                        <ListItem name="Neighborhood">
                            A group of one or more desks that is unique per
                            floor.{' '}
                            {/* TODO: Migrate link to scoopcommute */}
                            <a
                                href="https://help.customers.scoopcommute.com/hc/en-us/articles/1260806804889-How-to-create-desk-assignments"
                                target="scoop"
                                rel="noopener noreferrer"
                            >
                                Learn more.
                            </a>
                        </ListItem>
                        <ListItem
                            name="Desk Label"
                            description="An identifier that is unique per floor."
                        />
                    </ul>
                </Col>
                <Col xs="6">
                    <ul data-test="add-desks-col-desciptions-2">
                        <ListItem
                            name="Assignment Type"
                            description="Must be Department, Individual, or Open. Cannot be left blank."
                        />
                        <ListItem
                            name="Assignee"
                            description='Use an employee email for dedicated desk, a department name, e.g. "Marketing", for department desk, or leave blank when paired with the Open assignment type for anyone.'
                        />
                    </ul>
                </Col>
            </Row>
            <p
                className="text-muted"
                data-test="add-desks-columns-required-info"
            >
                <FontAwesomeIcon
                    data-test="info-icon"
                    icon={faInfoCircle}
                    className="mr-1"
                />
                All columns are required.
            </p>
            <div className="pb-4">
                <Row className="align-items-center">
                    <Col>
                        <h6>Example CSV</h6>
                    </Col>
                    <Col className="text-right">
                        <Button
                            data-test="add-desks-download-sample-csv-button"
                            onClick={downloadSampleCSV}
                            color="outline-primary"
                            className="mb-2"
                        >
                            Download CSV Template
                        </Button>
                    </Col>
                </Row>

                <Table
                    data-test="add-desks-sample-data-table"
                    className="border rounded"
                >
                    <thead data-test="table-head">
                        <tr className="font-weight-bold">
                            <td>Building</td>
                            <td>Floor</td>
                            <td>Neighborhood</td>
                            <td>Desk Label</td>
                            <td>Assignment Type</td>
                            <td>Assignee</td>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        <tr>
                            <td>Piggyback HQ</td>
                            <td>9</td>
                            <td>Blue</td>
                            <td>B1</td>
                            <td>Department</td>
                            <td>Marketing</td>
                        </tr>
                        <tr>
                            <td>Piggyback HQ</td>
                            <td>9</td>
                            <td>Blue</td>
                            <td>B2</td>
                            <td>Department</td>
                            <td>Marketing</td>
                        </tr>
                        <tr>
                            <td>Piggyback HQ</td>
                            <td>11</td>
                            <td>Green</td>
                            <td>G1</td>
                            <td>Open</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Piggyback HQ</td>
                            <td>11</td>
                            <td>Red</td>
                            <td>R1</td>
                            <td>Individual</td>
                            <td>juni@piggyback.com</td>
                        </tr>
                        <tr>
                            <td>Piggyback HQ</td>
                            <td>11</td>
                            <td>Green</td>
                            <td>G2</td>
                            <td>Department</td>
                            <td>Engineering</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default UploadInstructions
