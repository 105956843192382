import { useState, useEffect, useCallback } from 'react'
import { Paginator } from 'pagination'

export const defaultPageSize = 25
export const defaultVisiblePageCount = 3

const constrainPageNumber = (page = 1) => Math.max(1, page)

export type UsePagination = {
    changePage: (page: number) => void
    currentPage: number
    fromResult: number
    last: null | number
    toResult: number
    pagesToShow: number[]
    showNext: boolean
    showPrevious: boolean
}

type Props = {
    dataSize?: number
    initialPage?: number
    pageSize?: number
    visiblePageCount?: number
}

const usePagination = ({
    dataSize = 0,
    initialPage = 1,
    pageSize = defaultPageSize,
    visiblePageCount = defaultVisiblePageCount,
}: Props): UsePagination => {
    const [currentPage, updatePage] = useState(constrainPageNumber(initialPage))

    const changePage = useCallback((page) => {
        updatePage(constrainPageNumber(page))
    }, [])

    useEffect(() => {
        changePage(initialPage)
    }, [initialPage, changePage])

    const data = new Paginator({
        current: currentPage,
        rowsPerPage: pageSize,
        pageLinks: visiblePageCount,
        totalResult: dataSize,
    }).getPaginationData()

    const { first, last, range, fromResult, toResult } = data
    const showPrevious = Boolean(first)
    const showNext = Boolean(last)
    const pagesToShow = range

    return {
        currentPage,
        fromResult,
        toResult,
        changePage,
        showPrevious,
        showNext,
        pagesToShow,
        last,
    }
}

export default usePagination
