const sampleCSVData =
    'Building, Floor, Neighborhood, Desk label, Assignment Type, Assignee \n' +
    'Piggyback HQ, 9, West, W1, Department, Marketing \n' +
    'Piggyback HQ, 9, West, W2, Department, Engineering \n' +
    'Piggyback HQ, 9, East, E1, Department, Marketing \n' +
    'Piggyback HQ, 11, West, W1, Department, Marketing \n' +
    'Piggyback HQ, 11, West, W2, open, \n' +
    'Piggyback HQ, 11, Small Office, SO, open, \n' +
    'Piggyback HQ, 11, Large Office, LO, open, \n'

export default sampleCSVData
