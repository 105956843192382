import { Redirect } from '@reach/router'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CenteredLayout } from '../../components/Layout/Centered'
import Verify2FACode from '../../components/Verify2FACode'
import useAnalytics from '../../hooks/useAnalytics'
import {
    createFactorActivateRequest,
    createFactorResendRequest,
} from '../../state/auth/actions'
import {
    selectFactor,
    selectSessionTransaction,
    selectFactorActivateRequest,
} from '../../state/auth/selectors'

const MFAActivate = () => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    const {
        sessionTransactionId,
        stateToken,
        status: authStatus,
        factorId,
    } = useSelector(selectSessionTransaction)

    const { isFetching: isActivating } = useSelector(
        selectFactorActivateRequest
    )
    const { phoneLast4 } = useSelector(selectFactor)

    const activateFactor = useCallback(
        (code) =>
            dispatch(
                createFactorActivateRequest({
                    sessionTransactionId,
                    factorId,
                    stateToken,
                    code,
                })
            ),
        [dispatch, stateToken, sessionTransactionId, factorId]
    )
    const resendFactor = useCallback(
        () =>
            dispatch(
                createFactorResendRequest({
                    factorId,
                    sessionTransactionId,
                    stateToken,
                })
            ),
        [dispatch, sessionTransactionId, stateToken, factorId]
    )

    // Only render this page if user is trying to activate their 2FA
    if (authStatus !== 'MFA_ACTIVATE') {
        return <Redirect to="/" noThrow />
    }

    return (
        <CenteredLayout squished>
            <Verify2FACode
                codeVerifying={isActivating}
                message={`Please enter the 6-digit code we sent to the number ending in ${phoneLast4}.`}
                resendCode={() => {
                    resendFactor()
                    track('account_creation_action', {
                        action: 'Click',
                        label: 'resend_code',
                    })
                }}
                onSubmit={activateFactor}
                onSubmitClick={({ disabled }) => {
                    if (disabled) {
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'verify_code_grayed_out',
                        })
                    } else {
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'verify_code_enabled',
                        })
                    }
                }}
            />
        </CenteredLayout>
    )
}

export default MFAActivate
