import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ListGroupItem, Row, Col } from 'reactstrap'

import {
    getEmployeeExperienceConfigurationsForBuilding,
    getCapacityFromBuilding,
    getAggregateSeatCountForBuilding,
} from 'util/buildings'
import useModal from 'hooks/useModal'
import { modalTypeMap } from 'components/ReduxModal/helpers'
import Button from 'components/Button'
import { BuildingByFloorAndNeighbhorhood } from 'types'

const Section = styled.div.attrs({
    className: 'd-flex flex-column align-items-start',
})`
    strong {
        line-height: 0.8rem;
    }

    .btn-link {
        line-height: 1;
    }
`

type Props = {
    building: BuildingByFloorAndNeighbhorhood
    onCapacityUpdated: ({ buildingId: string }) => void
    children: React.ReactNode
    isUploadMode: boolean
}

const BuildingWithCapacityLimits = ({
    building,
    onCapacityUpdated,
    children,
    isUploadMode,
}: Props): JSX.Element => {
    const openModal = useModal()

    const { checkInCapacity } = useMemo(
        () => getEmployeeExperienceConfigurationsForBuilding(building),
        [building]
    )

    const assignedSeats = useMemo(
        () => getAggregateSeatCountForBuilding(building),
        [building]
    )

    const checkInLimit = useMemo(() => getCapacityFromBuilding(building), [
        building,
    ])

    const numRemaining = checkInLimit - assignedSeats

    const { id: buildingId, name: buildingName } = building

    const handleEditClick = (e) => {
        openModal(modalTypeMap.EDIT_CHECK_IN_LIMIT, {
            onCapacityUpdated,
            buildingId,
            buildingName,
            checkInCapacity,
        })
        e.target.blur()
    }

    return (
        <div className="mt-3">
            <ListGroupItem data-test="building" className="bg-light">
                <Row>
                    <Col>
                        <h6
                            data-test="building-title"
                            className="mt-0 mb-2 text-dark"
                        >
                            {buildingName}
                        </h6>
                        <Row>
                            <Col className="d-flex">
                                <Section data-test="checkin-limit">
                                    <h5 className="small font-weight-normal text-dark mb-1">
                                        Daily check-in limit
                                    </h5>
                                    <span className="small d-flex align-items-center text-muted">
                                        <span data-test="section-value">
                                            {checkInLimit}
                                        </span>
                                        {!isUploadMode && (
                                            <Button
                                                data-test="open-edit-check-in-capacity-modal"
                                                block={false}
                                                color="link"
                                                size="sm"
                                                className="py-0 my-0"
                                                label="Edit"
                                                onClick={handleEditClick}
                                            />
                                        )}
                                    </span>
                                </Section>
                                <Section className="mx-4">
                                    <strong>
                                        <big>-</big>
                                    </strong>
                                </Section>
                                <Section data-test="assigned-seats">
                                    <h5 className="small font-weight-normal text-dark mb-1">
                                        Assigned Desks
                                    </h5>
                                    <span className="small d-flex align-items-center text-muted">
                                        <span data-test="section-value">
                                            {assignedSeats}
                                        </span>
                                    </span>
                                </Section>
                                <Section className="mx-4">
                                    <strong>
                                        <big>=</big>
                                    </strong>
                                </Section>
                                <Section data-test="remaining">
                                    <h5 className="small font-weight-normal text-dark mb-1">
                                        Remaining check-ins without desks
                                    </h5>
                                    <span className="small d-flex align-items-center text-muted">
                                        <span data-test="section-value">
                                            {numRemaining > 0
                                                ? numRemaining
                                                : 0}
                                        </span>
                                    </span>
                                </Section>
                            </Col>
                        </Row>
                    </Col>
                    {isUploadMode && (
                        <Col sm="3" className="d-flex align-items-center">
                            <Section className="ml-auto">
                                <Button
                                    block={false}
                                    color="primary"
                                    size="sm"
                                    label={
                                        checkInLimit > 0
                                            ? 'Edit check-in limit'
                                            : 'Set check-in limit'
                                    }
                                    onClick={handleEditClick}
                                />
                            </Section>
                        </Col>
                    )}
                </Row>
            </ListGroupItem>
            {children}
        </div>
    )
}

export default BuildingWithCapacityLimits
