import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from '@reach/router'

import MagnifyingGlass from '../../images/magnifying-glass.svg'

const NoSearchResultsActive = ({ spaceAlias }) => {
    return (
        <>
            <img className="mx-auto d-block mt-5" src={MagnifyingGlass} />
            <h4 className="my-5 mx-auto">No commuters found</h4>
            <p>This may be due to:</p>
            <ul>
                <li>The commuter hasn&apos;t taken a trip yet</li>
                <li>The e-mail is formatted incorrectly or invalid</li>
                <li>
                    The commuter may be in the{' '}
                    <Link to="../deactivated">Deactivated commuters tab</Link>
                </li>
            </ul>
        </>
    )
}

NoSearchResultsActive.propTypes = {
    spaceAlias: PropTypes.string,
}

export default NoSearchResultsActive
