import React from 'react'

import SortableColumn from '../../components/Table/SortableColumn'
import { sortByOptions } from '../../hooks/useCarpoolers'

export default function getColumnHeaders({
    toggleFirstWeekState,
    toggleLastWeekState,
    sortBy,
    sortOrder,
    track,
}) {
    // an array of tuples of React component to be displayed in the header column,
    // and class/style options such as width and className.
    return [
        [<div key="email">Email</div>, { width: '40%' }],
        [
            <SortableColumn
                key={sortByOptions.firstTripAt}
                direction={
                    sortBy === sortByOptions.firstTripAt ? sortOrder : undefined
                }
                onClick={() => {
                    toggleFirstWeekState()
                    track('edit_carpoolers_action', {
                        action: 'Click',
                        label: 'sort_first_trip',
                    })
                }}
            >
                Week of first trip
            </SortableColumn>,
        ],

        [
            <SortableColumn
                key={sortByOptions.lastTripAt}
                direction={
                    sortBy === sortByOptions.lastTripAt ? sortOrder : undefined
                }
                onClick={() => {
                    toggleLastWeekState()
                    track('edit_carpoolers_action', {
                        action: 'Click',
                        label: 'sort_last_trip',
                    })
                }}
            >
                Week of last trip
            </SortableColumn>,
        ],

        [
            <div className="text-center" key="actions">
                Actions
            </div>,
            { width: 112 },
        ],
    ]
}
