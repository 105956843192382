import PropTypes from 'prop-types'
import React from 'react'
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    YAxis,
    XAxis,
} from 'recharts'

import colors from '../../../../styles/colors.ts'
import CustomToolTip from '../../Tooltip'
import XAxisTick from '../../XAxisTick'
import ChartFixedHeightContainer from '../../ChartFixedWidthContainer'

import {
    generateReferenceLines,
    getXAxisInterval,
    generateYAxisTicks,
    formatNumberResultTick,
    formatMonth,
    formatDate,
} from '../../../../util/chart/index.tsx'
import chartDimensionsForTesting from '../../chartDimensionsForTesting'

const ChartDisplay = ({
    yLabel,
    xLabel,
    toolTipLabel,
    rangeSize,
    granularity,
    data,
    onChartHover,
}) => {
    const xAxisInterval = getXAxisInterval(rangeSize, granularity)
    const xTickFormatter = rangeSize === 'year' ? formatMonth : formatDate
    const yTicks = generateYAxisTicks(data)
    const isMonthByDay = rangeSize === 'month' && granularity === 'daily'

    const referenceLines = generateReferenceLines(data)

    return (
        <ChartFixedHeightContainer>
            <ResponsiveContainer>
                <LineChart
                    {...chartDimensionsForTesting}
                    data={data}
                    style={{ color: colors.primary }}
                    margin={{ right: 55 }}
                >
                    <CartesianGrid />
                    {isMonthByDay && referenceLines}

                    <YAxis
                        type="number"
                        dx={-10}
                        width={100}
                        interval="preserveStartEnd"
                        scale="linear"
                        ticks={yTicks}
                        tickFormatter={formatNumberResultTick}
                    >
                        <Label
                            test-attr="y-axis-label"
                            style={{ fontSize: '20px' }}
                            dx={-40}
                            value={yLabel}
                            angle={-90}
                            position="center"
                            offset={50}
                        />
                    </YAxis>

                    <XAxis
                        dataKey="context.from"
                        height={120}
                        interval={xAxisInterval}
                        tickLine={false}
                        tick={
                            <XAxisTick
                                tickFormatter={xTickFormatter}
                                rangeSize={rangeSize}
                                isMonthByDay={isMonthByDay}
                                data={data}
                            />
                        }
                    >
                        <Label
                            test-attr="x-axis-label"
                            style={{ fontSize: '20px' }}
                            value={xLabel}
                            position={'insideBottom'}
                            offset={25}
                        />
                    </XAxis>

                    <Line
                        dataKey="result"
                        stroke={colors.primary}
                        strokeWidth={3}
                        dot={false}
                        activeDot={{ r: 5 }}
                    />

                    <Tooltip
                        content={
                            <CustomToolTip
                                dataLabel={toolTipLabel}
                                granularity={granularity}
                                dateFormatter={formatDate}
                                onChartHover={onChartHover}
                            />
                        }
                    />
                </LineChart>
            </ResponsiveContainer>
        </ChartFixedHeightContainer>
    )
}

ChartDisplay.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            context: PropTypes.shape({
                from: PropTypes.string,
                until: PropTypes.string,
            }),
            result: PropTypes.number,
            type: PropTypes.string,
        })
    ),

    yLabel: PropTypes.string.isRequired,
    xLabel: PropTypes.string.isRequired,
    toolTipLabel: PropTypes.string.isRequired,
    rangeSize: PropTypes.string.isRequired,
    granularity: PropTypes.string.isRequired,
    onChartHover: PropTypes.func.isRequired,

    // Only needed for testing
    chartDimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
}

export default ChartDisplay
