/** Copied from chrome-extension */

import { joinWords } from '../string'

/**
 *
 * Get size, like "10.20MB" from bytes.
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
export function getSizeFromBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const oneKb = 1024
    const numDecimals = decimals < 0 ? 0 : decimals
    const exponent = Math.floor(Math.log(bytes) / Math.log(oneKb))

    const number = parseFloat(
        (bytes / Math.pow(oneKb, exponent)).toFixed(numDecimals)
    )

    return `${number}${sizes[exponent]}`
}

/**
 * Get file extension from the target file.
 * If file 'hello-world.png' is passed, '.png' will be returned.
 *
 * @param [shouldIncludeDot] - default to true. If false, '.' in front is removed.
 */
export function getFileExtension(file: File, shouldIncludeDot = true): string {
    const fileNameTokens = file.name.split('.')
    const fileExtension = `${fileNameTokens[fileNameTokens.length - 1]}`

    return shouldIncludeDot ? `.${fileExtension}` : `${fileExtension}`
}

/**
 * Check whether the target file is valid
 * based on the accepted its file extensions and max file size.
 *
 * @params acceptedFileExtensions - an array of extensions such as ['.jpg', '.png', '.pdf']
 *
 * @returns undefined if valid, error message if not valid.
 *          Keep the format the same as final-form.
 */
export function validateFile(
    file: File,
    acceptedFileExtensions: string[],
    maxFileSizeInBytes: number
): string | undefined {
    const upperCasedAcceptedFileExtensions = acceptedFileExtensions.map(
        (extension) => extension.toUpperCase()
    )
    const upperCasedFileExtension = getFileExtension(file).toUpperCase()

    // If the file extension is not supported, show an error message.
    if (!upperCasedAcceptedFileExtensions.includes(upperCasedFileExtension)) {
        const errorMessage = `Accepted file formats are ${joinWords(
            acceptedFileExtensions
        )}.`

        return errorMessage
    }

    // Before uploading, check the file size in the client.
    if (file.size > maxFileSizeInBytes) {
        return 'File exceeds max file size. Please try it again.'
    }

    return undefined
}
