import { useCallback, useMemo } from 'react'
import useFetch from 'use-http'
import { createRequestInterceptor } from '../../util/requestInterceptors'

const {
    fileRequestFetchOptions,
    prepareFileForPost,
} = createRequestInterceptor()

const usePodsResource = ({ spaceId, shouldGetOnMount }) => {
    const {
        data,
        loading: isLoading,
        response,
        get,
        post,
        error: networkError,
    } = useFetch(
        `/spaces/${spaceId}/pods`,
        fileRequestFetchOptions,
        shouldGetOnMount ? [] : undefined
    )

    const pods = useMemo(() => (response.ok ? data : undefined), [
        data,
        response,
    ])

    const error = useMemo(
        () => (isLoading ? undefined : data?.error || networkError),
        [isLoading, data, networkError]
    )

    const uploadPods = useCallback(
        async ({ csvFile, force = false }) => {
            const csvData = prepareFileForPost(csvFile)
            const queryParam = force ? '?force=true' : ''
            const pods = await post(`/uploadBulk${queryParam}`, csvData)
            return response.ok ? pods : undefined
        },
        [post, response]
    )

    return {
        isLoading,
        error,
        getPods: get,
        uploadPods,
        pods,
    }
}

export default usePodsResource
