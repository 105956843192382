import _ from 'lodash'

import { Statistic } from '../../types'

const INTERVALS = [
    5,
    10,
    25,
    50,
    100,
    250,
    500,
    1000,
    2500,
    5000,
    10000,
    25000,
    50000,
    100000,
]

/**
 * Helper function that returns an array of y-axis tick values
 * based on the maximum data point in the statistics array, calculated according to a custom interval scale
 *
 *
 * @example
 *  statistics = [ {result: 12}, {result: 10}, {result: 249}, {result: 200}, {result: 175} ]
 *
 *  returns [0, 50, 100, 150, 200, 250] because the maxData point is 249
 *
 */

// Type guard. It means that if the stat is not undefined, then it IS a number.
const isNumber = (stat: number | undefined): stat is number => {
    return stat !== undefined
}

export function generateYAxisTicks(statistics: Statistic[]): number[] {
    const TICK_COUNT = 5
    const numbers: number[] = statistics
        .map((stat) => stat.result)
        .filter(isNumber)

    const dataMax = Math.max(...numbers)

    // We set the multiple based on how big dataMax is
    let multiple
    for (const interval of INTERVALS) {
        if (dataMax <= interval) {
            multiple = interval / TICK_COUNT
            break
        }
        multiple = 250000 / TICK_COUNT
    }

    // Generate ticks
    const ticks: number[] = []
    for (let i = 0; i <= TICK_COUNT; i++) {
        const tick = _.round(multiple * i, 1)

        ticks.push(tick)
    }

    return ticks
}
