import styled from 'styled-components'

export const ButtonsContainer = styled.div`
    margin: auto;
    max-width: 350px;
    margin-top: 40px;

    & > :first-child {
        margin-bottom: 15px;
    }
`
