import { PropTypes } from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MatchedSummarySection from './MatchedSummarySection'
import UnMatchedSummarySection from './UnMatchedSummarySection'
import Button from '../../../components/Button.tsx'
import useAnalytics from '../../../hooks/useAnalytics'
import { createCarpoolerDeactivationsUploadPreviewReset } from '../../../state/carpoolers/actions'
import { selectDeactivationsUploadRequest } from '../../../state/carpoolers/selectors'
import { ButtonsContainer } from '../../../styles/Layout'

const Deactivate = ({
    deactivationsPreview,
    spaceName,
    payload,
    spaceAlias,
    onError,
    onSubmit,
}) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()
    const boundDeactivationsPreviewReset = useCallback(
        () => dispatch(createCarpoolerDeactivationsUploadPreviewReset()),
        [dispatch]
    )

    const {
        isFetching: isFetchingDeactivationsUpload,
        error: deactivationsUploadError,
    } = useSelector(selectDeactivationsUploadRequest)

    const { successfulEmails = [], failedEmails = [] } = deactivationsPreview

    const matchedEmailsLength = successfulEmails.length
    const unmatchedEmailsLength = failedEmails.length

    // Track number of matched vs unmatched emails
    useEffect(() => {
        track('deactivate_carpoolers_action', {
            action: 'View',
            label: 'view_upload_deactivate_list',
            matched_emails: matchedEmailsLength,
            unmatched_emails: unmatchedEmailsLength,
        })
    }, [matchedEmailsLength, track, unmatchedEmailsLength])

    if (deactivationsUploadError) {
        onError(true)
    }

    return (
        <>
            <h3 className="mb-4">Deactivate carpoolers</h3>
            <h5>
                {failedEmails.length || 'No'} Unmatched email address
                {failedEmails.length > 1 && 'es'}
            </h5>
            <UnMatchedSummarySection
                unMatchedUsers={failedEmails}
                spaceName={spaceName}
            />
            <h5 className="mt-4">
                {matchedEmailsLength || 'No'} Matched email address
                {matchedEmailsLength > 1 && 'es'}
            </h5>
            <MatchedSummarySection
                matchedUsers={successfulEmails}
                spaceName={spaceName}
            />
            <ButtonsContainer>
                {matchedEmailsLength ? (
                    <Button
                        isLoading={isFetchingDeactivationsUpload}
                        onClick={() => {
                            onSubmit({
                                payload,
                                dryRun: false,
                                redirectOnSuccess: true,
                            })
                            track('deactivate_carpoolers_action', {
                                action: 'Click',
                                label: 'deactivate_carpoolers',
                            })
                        }}
                        label={`Deactivate ${matchedEmailsLength} commuter${
                            matchedEmailsLength > 1 ? 's' : ''
                        }`}
                        loadingLabel="Deactivating commuters"
                    />
                ) : (
                    <Button
                        label="Go back"
                        onClick={() => {
                            boundDeactivationsPreviewReset()
                            track('deactivate_carpoolers_action', {
                                action: 'Click',
                                label: 'deactivate_go_back',
                            })
                        }}
                    />
                )}
                <Button
                    onClick={() => {
                        boundDeactivationsPreviewReset()
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'deactivate_cancel',
                        })
                    }}
                    color="link"
                    className="nav-link text-primary text-decoration-none text-center mt-4"
                    label="Cancel"
                />
            </ButtonsContainer>
        </>
    )
}

Deactivate.propTypes = {
    deactivationsPreview: PropTypes.object,
    spaceName: PropTypes.string,
    payload: PropTypes.string,
    spaceAlias: PropTypes.string,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default Deactivate
