const colors = {
    alpha: 'rgba(white, 0)',
    caution: '#ea7600',
    danger: '#be3600',
    'danger-disabled': '#de9a7f',
    dark: '#152823',
    'dark-muted': 'rgba(21, 40, 35, 0.56)',
    grey: '#b4bab8',
    info: '#008353',
    light: '#f7f7f7',
    primary: '#354fa1',
    secondary: '#b4bab8',
    'secondary-alert': '#E2E3E5',
    'secondary-pressed': '#586460',
    'secondary-hover': '#e0e6e4',
    'secondary-disabled': '#d9dcdb',
    'input-border': '#d9dcdc',
    success: '#00b373',
    'success-disabled': '#7fd9b9',
    warning: '#ffb81d',

    // Added February 27, 2021 - New color palette as laid out in https://www.figma.com/file/5xphoyEKtsyCMSqxDWG5AJ/Global-Styles-v2?node-id=0%3A1
    // It is likely that the above colors will either be deprecated or need updating
    // This is a full list of all the colors used in our global color palette
    red: {
        0: '#F8EBE6',
        100: '#FFB97D',
        200: '#FF9E64',
        300: '#FF844C',
        400: '#FB6A35',
        500: '#DC501D',
        base: '#BE3600',
        700: '#A01800',
        800: '#840000',
        900: '#690000',
    },
    orange: {
        0: '#F8EFE6',
        100: '#FFDA85',
        200: '#FFC06C',
        300: '#FFA653',
        400: '#F38D3B',
        500: '#D57421',
        base: '#B85C00',
        700: '#9B4500',
        800: '#802E00',
        900: '#661600',
    },

    yellow: {
        0: '#FFF8E8',
        100: '#FFD23E',
        base: '#FFB81D',
        300: '#E19F00',
        400: '#C48600',
        500: '#A76E00',
        600: '#8C5700',
        700: '#724100',
        800: '#5A2C00',
        900: '#471600',
    },

    green: {
        0: '#E7F6F1',
        100: '#B3E8D5',
        200: '#66D1AB',
        300: '#33C28F',
        400: '#00B373',
        500: '#00995B',
        base: '#008045',
        700: '#00672F',
        800: '#004F1A',
        900: '#003804',
    },

    blue: {
        0: '#EBEDF6',
        100: '#D8E2FF',
        200: '#BDC8FF',
        300: '#A2AEFF',
        400: '#8795F0',
        500: '#6C7DD5',
        600: '#5165BB',
        base: '#354FA1',
        800: '#103A88',
        900: '#00266F',
    },

    purple: {
        0: '#F0EBF6',
        100: '#FFCAFF',
        200: '#EEB0FF',
        300: '#D396FF',
        400: '#B87DF1',
        500: '#9D64D5',
        600: '#834CBB',
        base: '#6935A1',
        800: '#4F1D88',
        900: '#36026F',
    },

    black: '#000000',
    white: '#FFFFFF',

    text: {
        dark: '#343A40',
        muted: '#6C757D',
        disabled: '#CCCCCC',
        light: '#F8F9FA',
    },
}

export default colors
