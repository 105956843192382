import React from 'react'
import { navigate } from '@reach/router'
import { Button, Row, Col } from 'reactstrap'

import errorNoAccessImage from '../../images/error-no-access.svg'
import { ButtonsContainer } from '../../styles/Layout'

const NoAccess = () => {
    return (
        <Row className="justify-content-center pt-5">
            <Col md="5">
                <img
                    className="mx-auto d-block my-5"
                    src={errorNoAccessImage}
                />
                <h4 className="text-center my-3">Something went wrong</h4>
                <p className="text-center">
                    You don&apos;t have access to the requested page.
                    <br />
                    Return to your home page or contact ScoopCare for more
                    information.
                </p>

                <ButtonsContainer>
                    <Button
                        id="home-button"
                        className="btn-block"
                        size="lg"
                        color="primary"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Go home
                    </Button>
                    <Button
                        id="scoop-care-button"
                        className="btn-block btn-lg btn-outline-primary text-decoration-none"
                        color="white"
                        onClick={() => {
                            window.open(
                                'https://help.customers.scoopcommute.com/',
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }}
                    >
                        Contact ScoopCare
                    </Button>
                </ButtonsContainer>
            </Col>
        </Row>
    )
}

export default NoAccess
