import { useCallback, Dispatch } from 'react'
import { useDispatch } from 'react-redux'
import { createOpenModal } from '../../state/modal'

type obj<T = unknown> = Record<string, T>
type createOpenModal = (params: obj) => obj

export type OpenModal = (
    modalType: string,
    data?: obj
) => Dispatch<createOpenModal>

const useModal = (): OpenModal => {
    const dispatch = useDispatch()

    return useCallback(
        (modalType, data) =>
            dispatch(createOpenModal({ name: modalType, data })),
        [dispatch]
    )
}

export default useModal
