import { datadogLogs } from '@datadog/browser-logs'

type Logger = {
    debug(message: string, messageContext?: unknown): void
    info(message: string, messageContext?: unknown): void
    warn(message: string, messageContext?: unknown): void
    error(message: string, messageContext?: unknown): void
}

function useLogger(): Logger {
    return datadogLogs.logger
}

export default useLogger
