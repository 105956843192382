import { Redirect, useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'

const UploadRedirect = ({ children, isStep1Complete }) => {
    const { pathname } = useLocation()
    const lastPathPart = pathname.slice(pathname.lastIndexOf('/') + 1)

    if (!isStep1Complete && lastPathPart !== 'choose-csv') {
        return <Redirect to="choose-csv" noThrow />
    }

    return children
}

UploadRedirect.propTypes = {
    isStep1Complete: PropTypes.bool,
}

export default UploadRedirect
