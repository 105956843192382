import React from 'react'
import PropTypes from 'prop-types'
import { Container, Navbar } from 'reactstrap'

import AccountDropdown from '../AccountDropdown'
import LogoLink from '../LogoLink/index.tsx'

const TopNavBar = ({ children, user }) => (
    <Container fluid className="border-bottom bg-white">
        <Navbar className="ml-0 py-0 px-2">
            <LogoLink user={user} />
            {children}
            <AccountDropdown user={user} />
        </Navbar>
    </Container>
)

TopNavBar.propTypes = {
    user: PropTypes.shape({
        displayName: PropTypes.string,
    }),
    children: PropTypes.node,
}

export default TopNavBar
