import { call, getContext, takeLatest, select, put } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import toast from '../../components/Toast/toast.tsx'
import { selectCurrentSpace } from '../../state/spaces/selectors'
import {
    CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST,
    createCarpoolerDeactivationsUploadSuccess,
    createCarpoolerDeactivationsUploadPreviewReplace,
    createCarpoolerDeactivationsUploadFailure,
} from '../../state/carpoolers'

export const uploadBulkUri = ({ spaceId, dryRun }) =>
    `/spaces/${spaceId}/carpoolerDeactivations/uploadBulk${
        dryRun ? '?force=false' : '?force=true'
    }`

export function* uploadBulk({ payload = {} }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { csv, dryRun, redirectOnSuccess } = payload

    try {
        const { id: spaceId, alias: spaceAlias } = yield select(
            selectCurrentSpace
        )
        if (!spaceId) {
            throw new Error(`Space ID is required to deactivate commuters.`)
        }

        const { parsedBody } = yield call(
            [cosmosApi, 'call'],
            uploadBulkUri({ spaceId, dryRun }),
            {
                method: 'POST',
                body: csv,
                headers: { 'Content-Type': 'text/csv' },
            }
        )
        const { successfulEmails, failedEmails } = parsedBody
        yield put(createCarpoolerDeactivationsUploadSuccess())
        yield put(
            createCarpoolerDeactivationsUploadPreviewReplace({
                successfulEmails,
                failedEmails,
            })
        )
        // navigate back to deactivated commuters page
        if (!dryRun && redirectOnSuccess) {
            toast.success('Commuters successfully deactivated')
            yield call(navigate, `/spaces/${spaceAlias}/carpoolers/deactivated`)
        }
    } catch (error) {
        error.dryRun = dryRun

        const isInvalidFormat = error.errorId === 'INVALID_CSV_FORMAT'
        const message = isInvalidFormat
            ? 'Please upload a CSV file with the correct formatting.'
            : error?.message || 'Something went wrong'

        toast.error(message)
        yield put(createCarpoolerDeactivationsUploadFailure({ error }))
    }
}

export default function* watchUploadBulk() {
    yield takeLatest(CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST, uploadBulk)
}
