import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import { createCarpoolerDeactivationsDeleteRequest } from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'
import { addApostropheS } from '../../util/grammar'

const ReactivateCarpooler = ({ onClose, displayEmail, deactivationId }) => {
    const { track } = useAnalytics()
    const dispatch = useDispatch()
    const { name: companyName } = useSelector(selectCurrentSpace)

    function handleReactivate() {
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'activate_confirm',
        })
        dispatch(createCarpoolerDeactivationsDeleteRequest({ deactivationId }))
        onClose()
    }

    function handleCancel() {
        onClose()
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'activate_cancel',
        })
    }

    return (
        <>
            <ModalHeader toggle={handleCancel}>
                Are you sure you want to reactivate this commuter?
            </ModalHeader>
            <ModalBody>
                <p>
                    Reactivating{' '}
                    <strong className="text-break">{displayEmail}</strong> means
                    they will be able to bill Scoop trips to{' '}
                    <strong>{addApostropheS(companyName)}</strong> Scoop account
                    again. Please contact the commuter directly to update them
                    on this change.
                </p>
                <p>
                    Once reactivated, this commuter will move to the{' '}
                    <strong>Active commuters</strong> tab.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleReactivate}>
                    Confirm
                </Button>
            </ModalFooter>
        </>
    )
}

ReactivateCarpooler.propTypes = {
    onClose: PropTypes.func,
    displayEmail: PropTypes.string,
    deactivationId: PropTypes.string,
}

export default ReactivateCarpooler
