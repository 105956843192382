import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import {
    createGetSpacesRequest,
    selectGetSpacesRequest,
    selectCurrentSpace,
} from '../../state/spaces'

/**
 * Hook for getting spaces from space alias by making a request to the server.
 * Returns the current space
 * @param {Object} object params
 * @param {string} object.spaceAlias - Target space alias you want to use to get space(s)
 */
const useFetchSpaces = ({ spaceAlias } = {}) => {
    const dispatch = useDispatch()
    const [cookies] = useCookies(['current-space-alias'])

    useEffect(() => {
        const currentSpaceAliasCookie = cookies['current-space-alias']

        if (spaceAlias) {
            dispatch(createGetSpacesRequest({ alias: spaceAlias }))
        } else if (currentSpaceAliasCookie) {
            dispatch(createGetSpacesRequest({ alias: currentSpaceAliasCookie }))
        } else {
            dispatch(createGetSpacesRequest({})) // returns all spaces you have access to
        }
    }, [dispatch, spaceAlias, cookies])

    const { isFetching, error } = useSelector(selectGetSpacesRequest)
    const currentSpace = useSelector(selectCurrentSpace)

    return {
        isFetching,
        error,
        currentSpace: _.isEmpty(currentSpace) ? undefined : currentSpace,
    }
}

export default useFetchSpaces
