import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { node } from 'prop-types'
import { format, isBefore } from 'date-fns'

import useAsync, { IDLE } from '../hooks/useAsync'
import usePodsResource from '../hooks/usePodsResource'
import useBuildingResource from '../hooks/useBuildingResource/index.ts'
import useSpaces from '../hooks/useSpaces/index.ts'
import { getAggregateFloorCountFromBuildings } from '../util/buildings/index.ts'

const defaultContext = {
    status: IDLE,
    podsError: undefined,
    savePods: () => {},
    pods: undefined,
    totalFloors: undefined,
    hasUploadedAllFloorPlans: false,
    updateFloorPlan: () => {},
    updateBuildingCapacity: () => {},
    buildings: undefined,
    getBuildings: () => {},
    isBuildingsLoading: false,
    buildingsError: undefined,
}

const DesksContext = React.createContext(defaultContext)

/**
 * Returns a updated list of employeeExperienceConfigurations
 * If the configuration is already in the list and is not expired, then it will be replaced
 * If the configuration is already in the list and is expired, then it will be removed
 * If the configuration is not in the list, then it will be added
 *
 * @param {Array} previousEmployeeExperienceConfigurations
 * @param {Object} newEmployeeExperienceConfiguration
 * @returns {Array}
 */
function getUpdatedEmployeeExperienceConfigurations(
    previousEmployeeExperienceConfigurations,
    newEmployeeExperienceConfiguration
) {
    const { type: updatedType, endAt } = newEmployeeExperienceConfiguration
    const isExpired = Boolean(endAt && isBefore(new Date(endAt), new Date()))
    const hasEECToReplace =
        previousEmployeeExperienceConfigurations.find(
            (exp) => exp.type === updatedType
        ) !== undefined

    if (isExpired) {
        // Remove the current configuration type
        return previousEmployeeExperienceConfigurations.filter(
            (exp) => exp.type !== updatedType
        )
    }

    if (!isExpired && hasEECToReplace) {
        // Replace the previous configuration type with the new one
        return [
            ...previousEmployeeExperienceConfigurations.filter(
                (exp) => exp.type !== updatedType
            ),
            newEmployeeExperienceConfiguration,
        ]
    }

    // Add the new configuration type
    return [
        ...previousEmployeeExperienceConfigurations,
        newEmployeeExperienceConfiguration,
    ]
}

/**
 * Use this hook to encapsulate the DesksContextProvider logic
 * This simplifies our testing strategy given that we shift the testing responsibility toward the hook vs
 * testing in the DesksContextProvider component.
 *
 * @returns DesksContext
 */
const __useDesksContextProvider = () => {
    const [buildings, setBuildings] = useState(undefined)
    const { id: spaceId } = useSpaces()
    const { exec, status } = useAsync()

    const {
        buildings: buildingsResource,
        getBuildings,
        isLoading: isBuildingsLoading,
        error: buildingsError,
    } = useBuildingResource({
        spaceId,
        shouldGetOnMount: false,
    })

    // Get buildings on mount
    useEffect(() => {
        getBuildings({ activeAfter: format(new Date(), 'yyyy-MM-dd') })
    }, [getBuildings])

    // If buildings are loaded, we store them in state
    useEffect(() => {
        setBuildings(buildingsResource)
    }, [buildingsResource, setBuildings])

    const buildingIdsByFloor = useMemo(() => {
        if (buildings) {
            const buildingIds = buildings.reduce((object, building) => {
                building.floors.forEach((floor) => {
                    object[floor.id] = building.id
                })

                return object
            }, {})

            return buildingIds
        }
    }, [buildings])

    const { uploadPods, error: podsError, pods } = usePodsResource({
        spaceId,
    })

    // TODO: Now that the Provider no longer needs to use pods, this could
    // probably be used to useDeskUploads
    const savePods = (csvFile) => {
        const savePodsThenRefreshBuildings = async () => {
            const pods = await uploadPods({ csvFile, force: true })
            await getBuildings({
                activeAfter: format(new Date(), 'yyyy-MM-dd'),
            })
            return pods
        }
        return exec(savePodsThenRefreshBuildings())
    }

    /** False if a floor doesn't have a floor plan. True otherwise. */
    const hasUploadedAllFloorPlans = useMemo(() => {
        if (!buildings) {
            return true
        }

        for (const building of buildings) {
            for (const floor of building.floors) {
                if (!floor.floorPlan) {
                    return false
                }
            }
        }

        return true
    }, [buildings])

    /**
     * Optimistically updates the UI by updating a building stored in state with a new
     * floor plan when the user uploads it.
     */
    const updateFloorPlan = useCallback(
        ({ floorId, floorPlan }) => {
            const buildingId = buildingIdsByFloor[floorId]

            const updatedBuildings = buildings.map((building) => {
                const isMatchingBuilding = building.id === buildingId

                if (isMatchingBuilding) {
                    const updatedFloors = building.floors.map((floor) => {
                        const isMatchingFloor = floor.id === floorId

                        if (isMatchingFloor) {
                            return {
                                ...floor,
                                floorPlan,
                            }
                        }
                        return floor
                    })

                    return {
                        ...building,
                        floors: updatedFloors,
                    }
                }
                return building
            })

            setBuildings(updatedBuildings)
        },
        [buildings, buildingIdsByFloor]
    )

    /**
     * Optimistically updates the UI by updating a building stored in state with new EmployeeExperienceConfiguration
     */
    const updateEmployeeExperienceConfiguration = useCallback(
        ({ updatedEmployeeExperienceConfiguration, buildingId }) => {
            const updatedBuildings = buildings.map((building) => {
                const isMatchingBuilding = building.id === buildingId
                if (isMatchingBuilding) {
                    return {
                        ...building,
                        employeeExperienceConfigurations: getUpdatedEmployeeExperienceConfigurations(
                            building.employeeExperienceConfigurations,
                            updatedEmployeeExperienceConfiguration
                        ),
                    }
                }

                return building
            })

            setBuildings(updatedBuildings)
        },
        [buildings]
    )

    const updateBuildingCapacity = useCallback(
        ({ updatedCheckInCapacityConfiguration, buildingId }) => {
            updateEmployeeExperienceConfiguration({
                updatedEmployeeExperienceConfiguration: updatedCheckInCapacityConfiguration,
                buildingId,
            })
        },
        [updateEmployeeExperienceConfiguration]
    )

    const totalFloors = useMemo(() => {
        return buildings || buildingsResource
            ? getAggregateFloorCountFromBuildings(
                  buildings || buildingsResource
              )
            : undefined
    }, [buildingsResource, buildings])

    return {
        podsError,
        savePods,
        pods,
        status,

        hasUploadedAllFloorPlans,
        totalFloors,
        updateFloorPlan,
        updateBuildingCapacity,
        buildings: buildings,
        getBuildings,
        isBuildingsLoading,
        buildingsError,
    }
}

const DesksContextProvider = (props) => {
    const context = __useDesksContextProvider()

    return (
        <DesksContext.Provider value={context}>
            {props.children}
        </DesksContext.Provider>
    )
}

DesksContextProvider.propTypes = {
    children: node,
}

export {
    __useDesksContextProvider,
    DesksContext,
    DesksContextProvider,
    defaultContext,
}
