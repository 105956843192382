import { getRangeSizeFromPeriod, getDateRange } from './date'

/**
 * Construct properties used for analytics.
 *
 * @param {object} period - { year, quarter, month, week }
 * @param {boolean} includeRangeSize - flag to control whether or not to include range size in analytics return value
 *
 * @returns properties used for analytics.
 * month in the properties starts with 1.
 */
export function getDateRangeProperties(
    { year, quarter, month, week },
    { includeRangeSize = true } = {}
) {
    const rangeSize = getRangeSizeFromPeriod({ year, quarter, month, week })
    const { fromDate, untilDate } = getDateRange({ year, quarter, month, week })

    return {
        range_size: includeRangeSize ? rangeSize : undefined,
        date_range_start: fromDate,
        date_range_end: untilDate,
        year,
        quarter,
        month: Number.isInteger(month) ? month + 1 : undefined,
        week,
    }
}
/**
 *
 * @param {Object} properties - example { week: undefined, month: undefined, quarter: 1, year: 2020 }
 * @param {String} term - the term to prepend each key in the properties object
 *
 * @example
 * {
 *   range_size: 'month',
 *   date_range_start: '2020-01-01',
 *   date_range_end: '2020-02-01',
 *   year: 2020,
 *   month: 1,
 * }
 * becomes
 * {
 *   previous_range_size: 'month',
 *   previous_date_range_start: '2020-01-01',
 *   previous_date_range_end: '2020-02-01',
 *   previous_year: 2020,
 *   previous_month: 1,
 * }
 *
 */

export function prefixTermToObjectKeys(properties, term) {
    const newProperties = Object.keys(properties).reduce((result, key) => {
        result[`${term}_${key}`] = properties[key]
        return result
    }, {})

    return newProperties
}
