import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import colors from '../../../styles/colors.ts'

const WeekContainer = styled.div`
    border-bottom: 1px solid #ccc;
`

const Text = styled.p`
    color: ${({ color }) => color || 'black'};
    font-weight: 500;
    margin: 5px auto;
`

function getChartDateText({
    granularity,
    dateFormatter,
    from,
    fromDay,
    actualUntilDay,
}) {
    if (granularity === 'daily') {
        return dateFormatter(from)
    }
    if (granularity === 'monthly') {
        return moment(fromDay).format('MMMM YYYY')
    }
    return `${dateFormatter(from)} - ${actualUntilDay}`
}

const Content = ({
    dataLabel,
    payload,
    granularity,
    dateFormatter,
    onChartHover,
}) => {
    const properties = payload[0].payload
    const { from, until } = properties.context

    const fromDay = moment(from)
    const untilDay = moment(until)

    const actualUntilDay = untilDay.subtract(1, 'days').format('MM/DD/YY')

    const chartDateText = getChartDateText({
        granularity,
        dateFormatter,
        from,
        fromDay,
        actualUntilDay,
    })

    useEffect(() => {
        onChartHover({
            tooltipValue: properties.result,
            tooltipDate: chartDateText,
        })
    }, [properties.result, chartDateText, onChartHover])

    return (
        <div
            style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
            }}
        >
            {granularity === 'daily' && (
                <WeekContainer>
                    <Text>{moment(fromDay).format('dddd')}</Text>
                    <Text>{chartDateText}</Text>
                </WeekContainer>
            )}

            {granularity === 'monthly' && (
                <WeekContainer>
                    <Text>{chartDateText}</Text>
                </WeekContainer>
            )}

            {granularity === 'weekly' && (
                <WeekContainer>
                    <Text>Week of</Text>
                    <Text>{chartDateText}</Text>
                </WeekContainer>
            )}

            <Text color={colors.primary}>
                {dataLabel}: {properties.result}
            </Text>
        </div>
    )
}

Content.propTypes = {
    dataLabel: PropTypes.string.isRequired,
    granularity: PropTypes.string.isRequired,
    active: PropTypes.bool,
    dateFormatter: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                result: PropTypes.number,
                context: PropTypes.object,
            }),
        })
    ),

    onChartHover: PropTypes.func.isRequired,
}

export default Content
