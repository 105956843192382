import { call, getContext, takeLatest, put, select } from 'redux-saga/effects'
import qs from 'qs'
import linkParser from 'parse-link-header'
import {
    CARPOOLER_DEACTIVATIONS_GET_REQUEST,
    createCarpoolerDeactivationsGetSuccess,
    createCarpoolerDeactivationsGetFailure,
    createCarpoolerDeactivationsDataReplace,
    createCarpoolerDeactivationsMetadataReplace,
    createCarpoolerDeactivationsPaginationReplace,
    createCarpoolerDeactivationsTotalCountUpdate,
    selectDeactivationsData,
    selectDeactivationsMetadata,
    selectDeactivationsPagination,
} from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'

export const ERROR_PAGINATION_EXPIRED = 'PAGINATION_EXPIRED'
export const DEFAULT_PAGES_TO_PREFETCH = 2
export const DEFAULT_PAGE_START = 1
export const DEFAULT_PAGE_SIZE = 25
const apiResourcePath = (spaceId) => `/spaces/${spaceId}/carpoolerDeactivations`
const acceptJsonHeader = {
    accept: 'application/json',
}

/**
 * Fetch one page of deactivations.
 */
export function* getPage({ uri, cosmosApi }) {
    if (!uri) {
        return {}
    }

    const { headers, parsedBody: deactivations = [] } = yield call(
        [cosmosApi, 'call'],
        uri,
        { headers: acceptJsonHeader }
    )
    const next = linkParser(headers.get('Link'))?.next
    const totalCount = headers.get('x-total-count')

    // Return the fetched data and the next URL
    return {
        deactivations,
        queryToken: next?.queryToken,
        totalCount,
    }
}

/**
 * Get several pages of deactivations.
 */
export function* getMultiplePages({ uri, pagesToFetch }) {
    const cosmosApi = yield getContext('cosmosApi')
    const fetchedDeactivations = []
    let pagesFetched = 0
    let latestQueryToken
    let totalCountFromFirstCall
    while (pagesFetched < pagesToFetch) {
        const { deactivations = [], queryToken, totalCount } = yield getPage({
            uri,
            cosmosApi,
        })
        // set the total count once
        totalCountFromFirstCall =
            pagesFetched === 0 ? totalCount : totalCountFromFirstCall
        fetchedDeactivations.push(...deactivations)
        pagesFetched += 1
        latestQueryToken = queryToken
        const [beforeSearchQuery] = uri.split('?')
        uri =
            beforeSearchQuery +
            qs.stringify({ queryToken }, { addQueryPrefix: true })

        if (!queryToken) {
            break
        }
    }
    return {
        fetchedDeactivations,
        pagesFetched,
        latestQueryToken,
        totalCount: totalCountFromFirstCall,
    }
}

function* appendToExistingDeactivations({
    baseUri,
    desiredPage,
    prefetchPageCount,
}) {
    const pagination = yield select(selectDeactivationsPagination)
    const existingDeactivations = yield select(selectDeactivationsData)
    const metadata = yield select(selectDeactivationsMetadata)
    const pagesToFetch = desiredPage - pagination.page + prefetchPageCount

    const isDesiredPageSecondToLastPage =
        pagination.isLastPage && desiredPage === pagination.page - 1

    if (desiredPage <= metadata.page || isDesiredPageSecondToLastPage) {
        // We already fetched this page, no need to interact with the API to get
        // this data.
        yield put(
            createCarpoolerDeactivationsMetadataReplace({
                ...metadata,
                page: desiredPage,
                isLastPage: false,
            })
        )
        return
    } else if (desiredPage >= pagination.page && pagination.isLastPage) {
        // We are already at the end of pagination, we cannot get any more
        // pages.
        yield put(
            createCarpoolerDeactivationsMetadataReplace({
                ...metadata,
                page: pagination.page,
                isLastPage: true,
            })
        )
        return
    }
    const uri =
        baseUri +
        qs.stringify(
            { queryToken: pagination.queryToken },
            { addQueryPrefix: true }
        )
    const {
        fetchedDeactivations,
        pagesFetched,
        latestQueryToken,
    } = yield call(getMultiplePages, { uri, pagesToFetch })
    const allTimePagesFetched = pagination.page + pagesFetched
    const pageToLandOn =
        allTimePagesFetched >= desiredPage ? desiredPage : allTimePagesFetched
    const deactivations = existingDeactivations.concat(fetchedDeactivations)
    // append the new pages to the existing deactivations.
    yield put(
        createCarpoolerDeactivationsDataReplace({
            deactivations,
        })
    )
    // tell the dispatcher which page to land on.
    yield put(
        createCarpoolerDeactivationsMetadataReplace({
            ...metadata,
            page: pageToLandOn,
            isLastPage: pageToLandOn === allTimePagesFetched,
        })
    )
    // update the pagination queryToken (and metadata) for the next request.
    yield put(
        createCarpoolerDeactivationsPaginationReplace({
            ...pagination,
            page: pagesFetched + pagination.page,
            queryToken: latestQueryToken,
            isLastPage: !latestQueryToken,
        })
    )
}

function* replaceDeactivations({
    baseUri,
    getParameters,
    desiredPage,
    prefetchPageCount,
}) {
    const uri =
        baseUri + qs.stringify({ ...getParameters }, { addQueryPrefix: true })
    const {
        fetchedDeactivations,
        pagesFetched,
        latestQueryToken,
        totalCount,
    } = yield call(getMultiplePages, {
        uri,
        pagesToFetch: desiredPage + prefetchPageCount,
    })
    yield put(createCarpoolerDeactivationsTotalCountUpdate(totalCount))

    const pageToLandOn =
        pagesFetched >= desiredPage ? desiredPage : pagesFetched
    // Replace the existing deactivations with the newly fetched ones.
    yield put(
        createCarpoolerDeactivationsDataReplace({
            deactivations: fetchedDeactivations,
        })
    )
    // Tell the dispatcher which page to land on.
    yield put(
        createCarpoolerDeactivationsMetadataReplace({
            ...getParameters,
            query: getParameters.q,
            page: pageToLandOn,
            isLastPage: !latestQueryToken && pageToLandOn === pagesFetched,
        })
    )
    // Update the queryToken (and metadata) for the next request.
    yield put(
        createCarpoolerDeactivationsPaginationReplace({
            ...getParameters,
            query: getParameters.q,
            page: pagesFetched,
            queryToken: latestQueryToken,
            isLastPage: !latestQueryToken,
        })
    )
}

export function* handleCarpoolerDeactivationsRequest({ payload }) {
    const request = { ...payload }
    request.page = payload.page || DEFAULT_PAGE_START
    request.limit = payload.limit || DEFAULT_PAGE_SIZE
    request.query = request.query || undefined
    const prefetchPageCount =
        payload.prefetchPageCount || DEFAULT_PAGES_TO_PREFETCH
    const { id: spaceId } = yield select(selectCurrentSpace)
    const pagination = yield select(selectDeactivationsPagination)
    const isExistingStateApplicable =
        request.limit === pagination.limit &&
        request.query === pagination.query &&
        request.sortBy === pagination.sortBy &&
        request.sortOrder === pagination.sortOrder
    const uri = apiResourcePath(spaceId)
    // Check to see if we can use the existing queryToken to satisfy the request.
    // Otherwise paginate from the beginning.
    try {
        if (isExistingStateApplicable) {
            yield call(appendToExistingDeactivations, {
                baseUri: uri,
                desiredPage: request.page,
                prefetchPageCount,
            })
        } else {
            yield call(replaceDeactivations, {
                baseUri: uri,
                getParameters: {
                    limit: request.limit,
                    q: request.query,
                    sortBy: request.sortBy,
                    sortOrder: request.sortOrder,
                },
                prefetchPageCount,
                desiredPage: request.page,
            })
        }
        yield put(createCarpoolerDeactivationsGetSuccess())
    } catch (err) {
        yield put(createCarpoolerDeactivationsGetFailure({ error: err }))
    }
}

export default function* watchCarpoolerDeactivationsGet() {
    yield takeLatest(
        CARPOOLER_DEACTIVATIONS_GET_REQUEST,
        handleCarpoolerDeactivationsRequest
    )
}
