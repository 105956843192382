import React from 'react'

// TODO: Create new scoopcommute link
const link =
    'https://help.customers.scoopcommute.com/hc/en-us/articles/1260806804889-Managing-Desk-Assignments'

const HelpCenterLink = (props) => {
    return (
        <a {...props} href={link} target="scoop" rel="noopener noreferrer">
            Help Center
        </a>
    )
}

export default HelpCenterLink
