import { ICellRendererParams } from 'ag-grid-community'
import React, { useCallback } from 'react'
import Button from '../../../components/Button'
import toast from '../../../components/Toast/toast'
import useTestResultByIdResource from '../../../hooks/resources/useTestResultById'
import useSpaces from '../../../hooks/useSpaces'
import { TestingLogRecordResource } from '../../../types'

type Props = ICellRendererParams & {
    data: TestingLogRecordResource
}

const ViewDocumentationButtonColumnRenderer = (props: Props): JSX.Element => {
    const { id: spaceId } = useSpaces()
    const { isLoading, response, get } = useTestResultByIdResource(spaceId)

    const handleClick = useCallback(async () => {
        await get(props.data.id)

        if (
            response.ok &&
            response.data &&
            'documentUrl' in response.data &&
            response.data.documentUrl
        ) {
            window.open(response.data.documentUrl, 'covid-documentation')
        } else {
            toast.error('Could not open the documentation.')
        }
    }, [get, props.data.id, response.data, response.ok])

    return (
        <Button
            data-test="view-test-result-documentation-button"
            label="Test results"
            loadingLabel="Opening..."
            isLoading={isLoading}
            onClick={handleClick}
            color="link"
            size="sm"
        />
    )
}

export default ViewDocumentationButtonColumnRenderer
