import { combineReducers } from 'redux'

import authReducer, { AUTH_ALL_RESET } from './auth'
import carpoolersReducer from './carpoolers'
import registrationReducer from './registration'
import spacesReducer from './spaces'
import userReducer from './user'
import modalReducer from './modal'

import cleanupReducerOnEvent from './cleanUpReducerOnEvent'
import { CARPOOLER_ACTIVATED_ALL_RESET } from './carpoolers/types'

export const auth = authReducer
export const carpoolers = carpoolersReducer
export const registration = registrationReducer
export const spaces = spacesReducer
export const user = userReducer
export const modal = modalReducer

export default combineReducers({
    auth: cleanupReducerOnEvent([AUTH_ALL_RESET], auth),
    carpoolers: cleanupReducerOnEvent(
        [CARPOOLER_ACTIVATED_ALL_RESET],
        carpoolers
    ),
    registration,
    spaces,
    user,
    modal,
})
