import { shape, string } from 'prop-types'
import React from 'react'
import { Navbar } from 'reactstrap'
import classnames from 'classnames'

import useSpaces from '../../hooks/useSpaces/index.ts'
import AccountDropdown from '../AccountDropdown'
import SpacesDropdown from '../SpacesDropdown/index.tsx'
import useGenericResource from '../../hooks/resources/useGenericResource.ts'

const NewTopNavBar = ({ user }) => {
    const { name: spaceName } = useSpaces()
    const { data: availableSpaces, loading } = useGenericResource('spaces', [])

    /* must be an internal user and have at least 2 spaces to see a spaces dropdown */
    const shouldDisplaySpacesDropdown =
        user.type === 'internal' &&
        !loading &&
        availableSpaces &&
        availableSpaces.length > 1

    const className = classnames(
        'bg-white border-bottom w-100 px-3',
        shouldDisplaySpacesDropdown
            ? 'justify-space-between'
            : 'justify-content-end'
    )

    return (
        <Navbar data-test="nav-bar" className={className}>
            {shouldDisplaySpacesDropdown && (
                <SpacesDropdown
                    currentSpaceName={spaceName}
                    availableSpaces={availableSpaces}
                />
            )}
            <AccountDropdown user={user} />
        </Navbar>
    )
}

NewTopNavBar.propTypes = {
    user: shape({
        displayName: string,
    }),
}

export default NewTopNavBar
