import _ from 'lodash'
import { useEffect } from 'react'
import usePrevious from 'use-previous'

import useAnalytics from '../useAnalytics'

/**
 * Tracks a functional component page view
 * @param {*} props must include props.category and props.label
 */
export const useTrackView = (props) => {
    const { track } = useAnalytics()
    const previousProps = usePrevious(props)

    useEffect(() => {
        if (_.isEqual(props, previousProps)) {
            return
        }

        const { category, label, ...additionalProps } = props
        track(category, { action: 'View', label, ...additionalProps })
    }, [previousProps, props, track])
}

export default useTrackView
