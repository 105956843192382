// Component theme colors should be based on bootstrap css classes
// Layout and padding can be defined as inline properties.

// === Component Themes

/**
 * Theme for Toast Component
 */
export const ToastInfo = {
    containerClass: 'shadow-none bg-input-border border border-secondary',
    iconClass: 'text-white',
    closeButtonClass: 'text-black-50',
}

export const ToastLoading = {
    containerClass: 'shadow-none bg-input-border border border-secondary',
    textClass: 'text-dark',
    iconClass: 'text-dark fa-spin fa-pulse',
    closeButtonClass: 'text-black-50',
}

export const ToastError = {
    containerClass: 'shadow-none bg-danger-disabled border border-danger',
    textClass: 'text-dark',
    iconClass: 'text-danger',
    closeButtonClass: 'text-black-50',
}

export const ToastSuccess = {
    containerClass: 'shadow-none bg-success-disabled border border-success',
    textClass: 'text-dark',
    iconClass: 'text-white',
    closeButtonClass: 'text-black-50',
}

export const ToastConnectionError = {
    containerClass: 'shadow-none bg-dark border border-dark text-white',
    textClass: 'text-white',
    iconClass: 'text-white',
    closeButtonClass: 'text-white',
}

export const Toast = {
    default: ToastInfo,
    info: ToastInfo,
    loading: ToastLoading,
    success: ToastSuccess,
    error: ToastError,
    connectionError: ToastConnectionError,
}

export default {
    Toast,
}
