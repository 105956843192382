import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { Button, Col, Row } from 'reactstrap'

import srcWrongExit from './404.svg'
import useAnalytics from '../../hooks/useAnalytics'
import { ButtonsContainer } from '../../styles/Layout'

const NotFound = () => {
    const { track } = useAnalytics()
    useEffect(() => {
        track('error_action', { action: 'View', label: 'view_404_page' })
    }, [track])

    return (
        <Row className="justify-content-center pt-5" data-test="page-not-found">
            <Col md="6">
                <img className="mx-auto d-block my-3" src={srcWrongExit} />
                <h4 className="text-center mb-5 mt-3">404 - Page not found</h4>
                <p>
                    Seems we took a wrong exit looking for this page. Let&apos;s
                    get you where you need to go instead.
                </p>
                <ButtonsContainer>
                    <Button
                        id="go-home-button"
                        className="btn-block"
                        size="lg"
                        color="primary"
                        onClick={() => {
                            track('error_action', {
                                action: 'Click',
                                label: 'go_home',
                            })
                            navigate('/')
                        }}
                    >
                        Go home
                    </Button>
                    <Button
                        id="contact-scoopcare-button"
                        className="btn-block btn-lg btn-outline-primary text-decoration-none"
                        color="white"
                        onClick={() => {
                            track('error_action', {
                                action: 'Click',
                                label: 'contact_scoopcare',
                            })
                            window.open(
                                'https://help.customers.scoopcommute.com/',
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }}
                    >
                        Contact ScoopCare
                    </Button>
                </ButtonsContainer>
            </Col>
        </Row>
    )
}

export default NotFound
