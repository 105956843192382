/** Copied from chrome-extension */

import React, { useState } from 'react'
import mime from 'mime/lite'
import xhr from 'xhr'

import AcceptedFileInfo from './AcceptedFileInfo'
import Container from './Container'
import FileDragAndDrop from './FileDragAndDrop'
import Upload from './Upload'
import Uploading from './Uploading'
import Success from './Success'
import Error from './Error'
import ErrorsWithModal from './ErrorsWithModal'
import { validateFile } from '../utils/file'

const tenMbInBytes = 10 * 1024 * 1024

enum Step {
    initial = 'initial',
    uploading = 'uploading',
    success = 'success',
    error = 'error',
}

type Props = {
    url?: string
    headers?: Record<string, string>
    acceptedFileExtensions?: string[]
    maxFileSizeInBytes?: number
    onBeforeUpload?: (
        fail: (message?: string) => void,
        setFileUploadUrl: (url: string) => void
    ) => Promise<void>
    onFileChange?: (file: File) => void
    onUploadSuccess?: (responseBody) => void
    displayErrorsAsModal?: boolean
    isDisabled?: boolean
    uploadInstructions?: string
}

/**
 * Only supports single file upload.
 *
 * @param [url] - URL to which the target file is uploaded.
 *                If not specified, the consumer can dynamically set the url via setFileUploadUrl()
 *                such as in onBeforeUpload() event.
 * @param [headers] - Pass any headers to add when making a request.
 * @param [onBeforeUpload] - If specified, the callback is called before the file starts to upload.
 * @param [displayErrorsAsModal] -  If specified, will display an error view with a link to open modal
 *                                  of enumerated errors from server.
 *                                  If not specified, an error view with a static message will display instead
 * @param [isDisabled] - If specified, will disable the drag and drop and upload buttons
 * @param [uploadInstructions] - If specified, a custom heading will be display on the Upload screen
 *
 */
const FileUploader = ({
    url,
    headers,
    acceptedFileExtensions = ['.jpg', '.jpeg', '.png'],
    maxFileSizeInBytes = tenMbInBytes,
    onBeforeUpload,
    onFileChange,
    onUploadSuccess,
    displayErrorsAsModal,
    isDisabled,
    uploadInstructions,
}: Props): JSX.Element => {
    const [step, setStep] = useState<Step>(Step.initial)
    const [percentUploaded, setPercentUploaded] = useState<number>(0)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [errorList, setErrorList] = useState<string[]>([])

    /**
     * Upload the file into the target URL.
     */
    function uploadFile(file: File, fileUploadUrl: string) {
        const formData = new FormData()
        formData.append('file', file)

        // Native fetch doesn't support file upload progress,
        // and it's difficult to cover all network states with XMLHttpRequest:
        // - https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState.
        //
        // Instead, use a small XMLHttpRequest wrapper library.
        xhr.post(
            {
                uri: fileUploadUrl,
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': mime.getType(file.name) || '',
                    'x-scoop-filename': file.name,
                    ...headers,
                },
                body: formData.get('file'),
                beforeSend: (xhrObject) => {
                    // Probably no need to removeEventLister() after making a request
                    // because xhrObject is destructed after making a request.
                    xhrObject.addEventListener(
                        'progress',
                        (e: ProgressEvent) => {
                            const completed = (e.loaded / e.total) * 100
                            const percentUploaded =
                                completed > 100 ? 100 : completed

                            setPercentUploaded(percentUploaded)
                        }
                    )
                },
            },
            (browserError, response) => {
                // XhrResponse  body is of type Object or string. convert to string in order to parse to JSON
                const responseBody = JSON.parse(response.body.toString())
                if ([200, 201].includes(response.statusCode)) {
                    // delay the success page so as the ensure the loader finishes
                    setTimeout(() => setStep(Step.success), 500)
                    onUploadSuccess?.(responseBody)
                    return
                }

                setErrorMessage('Something went wrong. Please try it again.')
                setTimeout(() => {
                    setPercentUploaded(0)
                    setStep(Step.error)
                }, 500)

                setErrorList(responseBody.error?.message.split('\n'))
            }
        )
    }

    /**
     *  Validate the file, call onBeforeUpload, and upload the file.
     *  Also update the view based on the upload state (uploading, success, error).
     */
    async function handleFileChange(file: File) {
        const validationError = validateFile(
            file,
            acceptedFileExtensions,
            maxFileSizeInBytes
        )

        if (validationError) {
            setErrorMessage(validationError)
            setStep(Step.error)
            return
        }

        setStep(Step.uploading)

        let fileUploadUrl = url

        // Call onBeforeUpload event before it starts uploading the file.
        await onBeforeUpload?.(
            function fail(message?: string) {
                setErrorMessage(
                    message || 'Something went wrong. Please try it again.'
                )
                setStep(Step.error)
            },
            function setUrl(url) {
                fileUploadUrl = url
            }
        )

        if (!fileUploadUrl) {
            setErrorMessage('Something went wrong. Please try it again.')
            setStep(Step.error)
            return
        }

        onFileChange?.(file)

        uploadFile(file, fileUploadUrl)
    }

    if (step === Step.uploading) {
        return (
            <Container data-test="file-uploader-uploading">
                <Uploading percentUploaded={percentUploaded} />
            </Container>
        )
    }

    if (step === Step.success) {
        return (
            <Container data-test="file-uploader-success">
                <Success />
            </Container>
        )
    }

    if (step === Step.error) {
        return (
            <Container
                data-test="file-uploader-error"
                className="position-relative"
            >
                <FileDragAndDrop
                    onFileChange={handleFileChange}
                    className="d-flex flex-column align-items-center justify-content-center"
                >
                    {displayErrorsAsModal ? (
                        <ErrorsWithModal
                            acceptedFileExtensions={acceptedFileExtensions}
                            maxFileSizeInBytes={maxFileSizeInBytes}
                            onFileChange={handleFileChange}
                            errorList={errorList}
                        />
                    ) : (
                        <Error
                            acceptedFileExtensions={acceptedFileExtensions}
                            maxFileSizeInBytes={maxFileSizeInBytes}
                            onFileChange={handleFileChange}
                            error={errorMessage}
                        />
                    )}
                </FileDragAndDrop>
            </Container>
        )
    }

    if (isDisabled) {
        return (
            <Container className="position-relative">
                <Upload
                    acceptedFileExtensions={acceptedFileExtensions}
                    onFileChange={handleFileChange}
                    uploadInstructions={uploadInstructions}
                    isDisabled={true}
                />
            </Container>
        )
    }

    return (
        <Container data-test="file-uploader" className="position-relative">
            <FileDragAndDrop
                onFileChange={handleFileChange}
                className="d-flex flex-column align-items-center justify-content-center"
            >
                <Upload
                    acceptedFileExtensions={acceptedFileExtensions}
                    onFileChange={handleFileChange}
                    uploadInstructions={uploadInstructions}
                />
                {!uploadInstructions && (
                    <AcceptedFileInfo
                        acceptedFileExtensions={acceptedFileExtensions}
                        maxFileSizeInBytes={maxFileSizeInBytes}
                    />
                )}
            </FileDragAndDrop>
        </Container>
    )
}

export default FileUploader
