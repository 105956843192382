import React from 'react'
import { shape, string } from 'prop-types'
import ModuleFlag from '../../ModuleFlag/index'
import { isModuleEnabled } from '../../ModuleFlag/util'
import SideBarLink from '../SideBarLink'

/* "/commute-learn-more" is a marketing page that displays to workplacePlanner customers that don't yet have memberManagement */
const CommuteMarketingSideBar = ({ space }) => {
    return (
        <ModuleFlag name="workplacePlanner">
            {!isModuleEnabled('memberManagement', space.modules) && (
                <>
                    <strong className="d-block ml-3 py-2">Commute</strong>
                    <SideBarLink
                        text="Learn More"
                        to={`${space.alias}/commute-learn-more`}
                    />
                </>
            )}
        </ModuleFlag>
    )
}

CommuteMarketingSideBar.propTypes = {
    space: shape({
        alias: string,
    }).isRequired,
}

export default CommuteMarketingSideBar
