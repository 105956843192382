import React, { useCallback } from 'react'
import { func, bool, string } from 'prop-types'
import useFetch from 'use-http'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'

import googleImage from '../../images/powered-by-google.png'
import pinImage from '../../images/pin.svg'
import exclamationCircle from '../../images/exclamation-circle.svg'
import { useAppConfiguration } from '../../providers/AppConfigurationProvider'
import withField from '../../hocs/withField'

const fetchOptions = {
    cachePolicy: 'no-cache',
    mode: 'cors',
}

/**
 * @property {Function} onAddressChange - Called when an address is selected in the dropdown.
 *                                              The selected address is passed in as an argument and onAddressChange calls getAddressById from useFetchResolveAddress
 *                                              to get selected address's streetAddress and coordinates.
 *                                              Returns a Promise.
 *
 */
export const AddressTypeahead = ({
    onChange, // withField onChange
    id,
    autoFocus,
    placeholder,
    onAddressChange,
    ...otherProps
}) => {
    // Technically, Nebula should only hit cosmos endpoints.
    // As an exception, this public API endpoint is added to reduce the scope of work.
    // Discussion: https://takescoop.slack.com/archives/CPLPYJ99Q/p1616177980025200
    const { apiUrl } = useAppConfiguration()

    const { get: getAddresses, loading, data, error } = useFetch(
        `${apiUrl}/places/autocomplete`,
        fetchOptions
    )

    const searchAddresses = useCallback(
        (query) => getAddresses(`?q=${query}`),
        [getAddresses]
    )

    const addresses = data?.data || []

    return (
        <AsyncTypeahead
            id={id}
            isLoading={loading}
            minLength={3}
            labelKey="description"
            placeholder={placeholder}
            onSearch={searchAddresses}
            onChange={async (addresses) => {
                const resolved = await onAddressChange(addresses[0])

                onChange(resolved)
            }}
            options={addresses}
            filterBy={() => true}
            autoFocus={autoFocus}
            renderMenu={(results, menuProps) => {
                const hasResults = results.length > 0

                return (
                    <Menu {...menuProps}>
                        {results.map((result, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    option={result}
                                    position={index}
                                    className="p-0"
                                >
                                    <div className="d-flex flex-row">
                                        <div className="px-3 d-flex">
                                            <img src={pinImage} />
                                        </div>
                                        <div className="flex-grow-1 py-2 border-bottom">
                                            <div>{result.displayLines[0]}</div>
                                            <div className="text-muted">
                                                {result.displayLines[1]}
                                                <small>
                                                    {result.displayLines[2]}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                        })}

                        {error && (
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex pt-3">
                                    <div className="px-3 d-flex">
                                        <img src={exclamationCircle} />
                                    </div>

                                    <div>
                                        <p className="text-danger my-0">
                                            Could not load address data
                                        </p>
                                        <p className="my-0">
                                            <small>
                                                Please try reloading the page
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center py-3">
                                    <img src={googleImage} />
                                </div>
                            </div>
                        )}

                        {hasResults && (
                            <div className="d-flex justify-content-center py-3">
                                <img src={googleImage} />
                            </div>
                        )}

                        {loading && (
                            <div
                                test-attr="loading-label"
                                className="px-3 text-muted"
                            >
                                Searching...
                            </div>
                        )}

                        {!loading && !hasResults && !error && (
                            <div className="px-3 text-muted">
                                No matches found
                            </div>
                        )}
                    </Menu>
                )
            }}
            {...otherProps}
        />
    )
}

AddressTypeahead.propTypes = {
    onChange: func.isRequired, // from withField

    id: string.isRequired,
    onAddressChange: func.isRequired,
    autoFocus: bool,
    placeholder: string,
}

export default withField(AddressTypeahead)
