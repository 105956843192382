import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ButtonGroup, ButtonDropdown } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import LastXDropdownItems from './LastXDropdownItems'
import {
    PrevButton,
    NextButton,
    DateDropdownToggle,
    DateDropdownMenu,
} from './styled'
import { useCommute } from '../../../providers/CommuteProvider'
import {
    dateFormat,
    getAdjustedPeriod,
    getDateRangeText,
    isDateRangeOverlapped,
} from '../../../util/date'

/**
 * changePeriod callback (accessed via useCommute() hook) is called if a date range is changed.
 * updatedQuarter, updatedMonth, updatedWeek will be mutually exclusive.
 *
 * @callback changePeriod
 * @param {Object} params
 * @property {Date} params.fromDate - the start of the date range, inclusive.
 * @property {Date} params.untilDate - the end of the date range, **exclusive**.
 * @property {number} params.updatedPeriod.year updated year
 * @property {number} [params.updatedPeriod.quarter] updated quarter
 * @property {number} [params.updatedPeriod.month] updated month
 * @property {number} [params.updatedPeriod.week] updated week
 */

/**
 * Stateless Date Range Dropdown for selecting a range of year, month, week, or quarter.
 * The format of `Date` should be `YYYY-MM-DD`.
 *
 * Properties month, week, and quarter must be mutually exclusive.
 *
 * @param {Object} props - React Props
 * @property {Date} props.earliestDate - Earliest date that the range can include.
 * @property {Date} [props.latestDate = today's Date] - Latest date that the range can include.
 *
 * @property {number} [props.period.year] - Target year in YYYY format. If all of quarter, month, and week are empty, the dropdown range is yearly.
 * @property {number} [props.period.quarter] - Target quarter (1 - 4). If passed, the dropdown range is quarterly.
 * @property {number} [props.period.month] - Target month (0 - 11). If passed, the dropdown range is monthly.
 * @property {number} [props.period.week] - Target week (1 - 52). If passed, the dropdown range is weekly.
 *
 * @property {string} [props.disabledText] text displayed on the dropdown if the dropdown is disabled.
 *
 */
const DateRangeDropdown = ({
    earliestDate,
    latestDate = moment().format(dateFormat),
    period,
    disabledText,

    // UI Events
    // These events return updated period ({year, quarter, month, week}) as a parameter
    onDropdownToggleClick = () => {},
    onPrevButtonClick = () => {},
    onNextButtonClick = () => {},
    onDropdownItemClick = () => {},
}) => {
    const { changePeriod, isDataReady, haveTripsStarted } = useCommute()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)

    const isDisabled = !isDataReady || !haveTripsStarted

    const updatePeriod = useCallback(
        ({ offset, basePeriod }, done = () => {}) => {
            const updatedPeriod = getAdjustedPeriod(basePeriod, offset)

            changePeriod(updatedPeriod)
            done(updatedPeriod)
        },
        [changePeriod]
    )

    const handlePrevButtonClick = () => {
        updatePeriod(
            {
                offset: -1,
                basePeriod: period,
            },
            onPrevButtonClick
        )
    }
    const handleNextButtonClick = () => {
        updatePeriod(
            {
                offset: 1,
                basePeriod: period,
            },
            onNextButtonClick
        )
    }

    const isPrevButtonDisabled =
        isDisabled ||
        !isDateRangeOverlapped({
            earliestDate,
            latestDate,
            offset: -1,
            period,
        })
    const isNextButtonDisabled =
        isDisabled ||
        !isDateRangeOverlapped({
            earliestDate,
            latestDate,
            offset: 1,
            period,
        })

    const dropdownText =
        isDisabled && disabledText ? disabledText : getDateRangeText(period)

    return (
        <ButtonGroup>
            <PrevButton
                outline
                color="primary"
                onClick={handlePrevButtonClick}
                disabled={isPrevButtonDisabled}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </PrevButton>

            <ButtonDropdown
                isOpen={isDropdownOpen}
                toggle={toggleDropdown}
                disabled={isDisabled}
                direction="down"
            >
                <DateDropdownToggle
                    outline
                    color="primary"
                    className="btn btn-outline-primary"
                    disabled={isDisabled}
                    onClick={() => {
                        onDropdownToggleClick(period)
                    }}
                >
                    {dropdownText}
                </DateDropdownToggle>

                <DateDropdownMenu>
                    <LastXDropdownItems
                        period={period}
                        earliestDate={earliestDate}
                        latestDate={latestDate}
                        onDateRangeUpdate={(params) => {
                            changePeriod(params)
                            onDropdownItemClick(params)
                        }}
                    />
                </DateDropdownMenu>
            </ButtonDropdown>

            <NextButton
                outline
                color="primary"
                onClick={handleNextButtonClick}
                disabled={isNextButtonDisabled}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </NextButton>
        </ButtonGroup>
    )
}

DateRangeDropdown.propTypes = {
    earliestDate: PropTypes.string,
    latestDate: PropTypes.string,
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        month: PropTypes.number,
        week: PropTypes.number,
        quarter: PropTypes.number,
    }).isRequired,
    disabledText: PropTypes.string,

    // UI Events
    onDropdownToggleClick: PropTypes.func,
    onPrevButtonClick: PropTypes.func,
    onNextButtonClick: PropTypes.func,
    onDropdownItemClick: PropTypes.func,
}

export default DateRangeDropdown
