import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useAppConfiguration } from '../../providers/AppConfigurationProvider'
import cleanLocation from '../../util/cleanLocation'
import { selectCurrentSpace } from '../../state/spaces'
import useLogger from '../useLogger/index.ts'

const safeCall = (onException) => (fn) => (...params) => {
    try {
        fn(...params)
    } catch (err) {
        onException(err)
    }
}

const useAnalytics = () => {
    const { analytics } = useAppConfiguration()
    const logger = useLogger()
    const { name, id, alias } = useSelector(selectCurrentSpace)

    // The default behavior of Analytics.js will parse the URL and search from
    // the location bar. Some of our URLs contain sensitive information so we
    // will clean these values before sending them to Segment.
    const cleaned = cleanLocation(window.location)
    const option = useMemo(
        () => ({
            page: { url: cleaned.href, search: cleaned.search },
        }),
        [cleaned.href, cleaned.search]
    )

    const spaceDetails = useMemo(
        () => ({
            space_id: id,
            space_name: name,
            space_alias: alias,
        }),
        [id, name, alias]
    )
    const track = useCallback(
        (event, properties, otherProperties = {}) => {
            safeCall(logger.error)(analytics.track)(
                event,
                {
                    ...properties,
                    ...otherProperties,
                    ...spaceDetails,
                },
                option
            )
        },
        [analytics.track, logger, option, spaceDetails]
    )
    return { track }
}

export default useAnalytics
