import { Redirect, Router } from '@reach/router'
import React from 'react'

import ReportsRouter from './ReportsRouter'
import ModuleFlag from '../../components/ModuleFlag'
import useSpaces from '../../hooks/useSpaces'

/**
 * Component that sits at route /spaces/{spaceId}/commute
 */
const CommuteReports = (): JSX.Element => {
    const { id: spaceId } = useSpaces()

    // Go back to root of commute: /spaces/{spaceId}/commute
    const fallback = <Redirect from="/" to="../../" noThrow />

    return (
        <Router primary={false}>
            {/* Redirect from reports to reports/carpooler */}
            <Redirect from="/" to="carpooler" noThrow />

            <ModuleFlag
                name="carpoolerReports"
                path="commuter"
                fallback={fallback}
            >
                <ReportsRouter
                    default
                    reportsName="carpoolers"
                    spaceId={spaceId}
                    labels={{
                        title: 'Commuter reports',
                        description:
                            'Details about commuter activity in a given time period, including number of trips taken and commuter roles.',
                        exampleMonthlyTitle: 'Example monthly commute report',
                        exampleMonthlyDescription:
                            'Details about commuter activity in a given calendar month, including number of trips taken and commute role.',
                        exampleYearlyTitle: 'Example yearly commute report',
                        exampleYearlyDescription:
                            'Details about commuter activity in a given calendar year, including number of trips taken and commute role.',
                        modalContent: {
                            title: 'No activity to report',
                            body:
                                'Unable to download report because there was no commuter activity during this time period.',
                        },
                    }}
                    analytics={{
                        prefix: 'carpooler_reports',
                    }}
                    expectedNumColumnsInExample={{
                        yearly: 5,
                        monthly: 4,
                    }}
                />
            </ModuleFlag>

            <ModuleFlag name="tripReports" path="trip" fallback={fallback}>
                <ReportsRouter
                    default
                    reportsName="trips"
                    spaceId={spaceId}
                    isYearToDateSupported
                    data-test="trip-reports-page"
                    labels={{
                        title: 'Trip reports',
                        description:
                            'Details about one-way trips that happened in a given time period, including dates, times, locations, and commuter roles.',
                        exampleMonthlyTitle: 'Example monthly trip report',
                        exampleMonthlyDescription:
                            'Details about one-way trips that happened during a calendar month. The report includes dates, times, locations, and commuter roles.',
                        exampleYearlyTitle: 'Example yearly trip report',
                        exampleYearlyDescription:
                            'Details about one-way trips that happened during a calendar year. The report includes dates, times, locations, and commuter roles.',
                        modalContent: {
                            title: 'No trips to report',
                            body:
                                'Unable to download report because no trips were taken during this time period.',
                        },
                    }}
                    analytics={{
                        prefix: 'trip_reports',
                    }}
                    expectedNumColumnsInExample={{
                        yearly: 7,
                        monthly: 7,
                    }}
                />
            </ModuleFlag>
        </Router>
    )
}

export default CommuteReports
