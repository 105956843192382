import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Col } from 'reactstrap'

import Metric from '../../../components/Metric'
import VerticalSeparator from '../../../components/Metric/VerticalSeparator'
import generatePathUrl from '../../../util/generatePathUrl'
import { getWorkplaceHeaderMetricDates } from '../util'

const urlPath = `/spaces/:spaceId/metrics/workAttendanceIndications\\?statisticType=sum&from=:fromDate&until=:untilDate`

const Metrics = ({ spaceId, excludeWeekendDays }) => {
    const metricDates = useMemo(
        () => getWorkplaceHeaderMetricDates({ excludeWeekendDays }),
        [excludeWeekendDays]
    )

    return (
        <>
            {metricDates.map((day, index) => {
                return (
                    <Col
                        key={day.date}
                        xs={Math.floor(12 / metricDates.length)}
                        className="p-0 d-flex flex-row justify-content-between"
                    >
                        <Metric
                            suffix="Going into work"
                            title={day.title}
                            urlPath={generatePathUrl(urlPath, {
                                spaceId,
                                fromDate: day.date,
                                untilDate: day.dayAfterDate,
                            })}
                            isFetchCacheDisabled={true}
                            isUltraText
                        />
                        {index < metricDates.length - 1 && (
                            <VerticalSeparator />
                        )}
                    </Col>
                )
            })}
        </>
    )
}

Metrics.propTypes = {
    excludeWeekendDays: PropTypes.bool,
    spaceId: PropTypes.string.isRequired,
}

export default Metrics
