import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { AsyncToggle } from '@scoop/react-component-library'
import PolicyFeature from '../../../components/PolicyFeature'
import useAttendanceGuidelinesPoliciesResource from '../../../hooks/resources/useAttendanceGuidelinesPoliciesResource'
import useSpaces from '../../../hooks/useSpaces'
import PolicyError from '../../../components/Policy/PolicyError'
import toast from '../../../components/Toast/toast'
import { ResponseError } from '../../../types'
import ModuleFlag from '../../../components/ModuleFlag/index'

const ListItem = styled.li`
    list-style-type: none;
`

const AttendanceGuidelinesPolicy = (): JSX.Element => {
    const { id: spaceId } = useSpaces()

    const [
        attendanceGuidelinesPolicies,
        setAttendanceGuidelinePolicies,
    ] = useState<{ isEnabled: boolean }>()

    const {
        attendanceGuidelinesPolicies: policiesFromServer,
        isLoading,
        error,
        savePolicies,
        saveResponse,
        isSavingPolicies,
    } = useAttendanceGuidelinesPoliciesResource(spaceId, [])

    useEffect(() => {
        setAttendanceGuidelinePolicies(policiesFromServer)
    }, [policiesFromServer])

    const changePolicies = useCallback(async () => {
        const newPolicies = {
            isEnabled: !attendanceGuidelinesPolicies?.isEnabled,
        }
        await savePolicies(newPolicies)

        if (saveResponse.ok) {
            setAttendanceGuidelinePolicies(newPolicies)
        } else {
            toast.error(
                `Could not save policy. ${
                    (saveResponse.data as ResponseError)?.error?.message || ''
                }`
            )
        }
    }, [
        attendanceGuidelinesPolicies,
        saveResponse,
        savePolicies,
        setAttendanceGuidelinePolicies,
    ])

    return (
        <ModuleFlag name="workAttendanceGuidelines">
            <ListItem>
                <Row data-test="attendance-guidelines-policy" className="mb-3">
                    <Col>
                        <Row className="d-flex bg-light border-top border-left border-right rounded-top">
                            <Col sm="10" className="px-3 py-3">
                                <h5>Team in-office guidelines</h5>
                                <p className="text-muted mb-0">
                                    Allow managers to specify in-office days for
                                    their their direct reports.
                                </p>
                            </Col>
                            <Col
                                sm="2"
                                className="d-flex justify-content-end align-items-center"
                            >
                                {!error && attendanceGuidelinesPolicies && (
                                    <AsyncToggle
                                        id="attendance-guidelines-policy-toggle"
                                        onChange={changePolicies}
                                        isLoading={
                                            isLoading || isSavingPolicies
                                        }
                                        isEnabled={
                                            attendanceGuidelinesPolicies.isEnabled
                                        }
                                    />
                                )}
                            </Col>
                        </Row>

                        {error && <PolicyError />}
                        {!error && attendanceGuidelinesPolicies && (
                            <>
                                <PolicyFeature
                                    name="minimumDaysPerWeek"
                                    description="Minimum days per week"
                                    statusLabel={
                                        attendanceGuidelinesPolicies?.isEnabled
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    isToggleHidden
                                    indentLevel={0}
                                    isLoading={isLoading}
                                />
                                <PolicyFeature
                                    name="maximumDaysPerWeek"
                                    description="Maximum days per week"
                                    statusLabel={
                                        attendanceGuidelinesPolicies?.isEnabled
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    isToggleHidden
                                    indentLevel={0}
                                    isLoading={isLoading}
                                />
                                <PolicyFeature
                                    name="specificDaysPerWeek"
                                    description="Specific days per week"
                                    statusLabel={
                                        attendanceGuidelinesPolicies?.isEnabled
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    isToggleHidden
                                    indentLevel={0}
                                    isLoading={isLoading}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </ListItem>
        </ModuleFlag>
    )
}

export default AttendanceGuidelinesPolicy
