import { faFileAlt } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICellRendererParams } from 'ag-grid-community'
import { VaccinationReviewStatus, VaccinationStatus } from '../../../types'
import Button from '../../../components/Button'
import useSpaces from '../../../hooks/useSpaces'
import useModal from '../../../hooks/useModal'
import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import useFeatureFlags from '../../../devTools/useFeatureFlags'
import RevokeVaccinationProofButton from '../RevokeVaccinationProofButton/index'

export const reviewStatusButtonTextMap = {
    [VaccinationReviewStatus.pending]: 'Review documentation',
    // TODO: re-implement once ready
    // [VaccinationReviewStatus.rejected]: 'Revoke rejection',
    // [VaccinationReviewStatus.verified]: 'Revoke verification',
}

type Props = ICellRendererParams & {
    refreshData: () => void
}

/**
 * Render a button to review the vaccination documentation.
 * @param {Function} props.refreshData Callback to refresh the data in the grid.
 * @returns {any} a JSX element, or an empty string. Need to return a string, because ag grid needs to render something in the cell instead of null.
 *          Using return type any because the alternative - `JSX.Element | string` - doesn't work in the component test.
 */
const ActionsButtonColumnRenderer = (props: Props): any => {
    const {
        refreshData,
        data: { proof, status },
    } = props

    const { id: spaceId } = useSpaces()
    const openModal = useModal()
    const openVaccinationReviewModal = () => {
        openModal(modalTypeMap.REVIEW_VACCINATION_DOCUMENTATION, {
            spaceId,
            vaccinationLogRecordsToReview: [props.data],
            onClose: ({ shouldRefreshData }) =>
                shouldRefreshData && refreshData(),
            size: 'lg',
        })
    }

    if (status !== VaccinationStatus.fullyVaccinated) {
        return ''
    }

    if (proof?.reviewStatus === VaccinationReviewStatus.pending) {
        return (
            <Button
                color="primary"
                size="sm"
                data-test="review-vaccination-proof-documentation"
                onClick={openVaccinationReviewModal}
            >
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                Review documentation
            </Button>
        )
    }
    if (
        proof?.reviewStatus === VaccinationReviewStatus.rejected ||
        proof?.reviewStatus === VaccinationReviewStatus.verified
    ) {
        return (
            <RevokeVaccinationProofButton
                refreshData={refreshData}
                proofId={proof?.id}
                vaccinationReviewStatus={proof.reviewStatus}
            />
        )
    }

    return ''
}

export default ActionsButtonColumnRenderer
