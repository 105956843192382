import React from 'react'
import { Spinner } from 'reactstrap'
import { VerticallyCenteredContainer } from './styled'

const SpinnerFullPage = () => {
    return (
        <VerticallyCenteredContainer>
            <div className="text-center" data-test="full-page-spinner">
                <Spinner />
            </div>
        </VerticallyCenteredContainer>
    )
}

export default SpinnerFullPage
