import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate, Link } from '@reach/router'
import { parseLocation } from 'parse-address'
import { groupBy, map } from 'lodash'
import { arrayOf, shape } from 'prop-types'

import { buildingType } from '../../../models/building'

const BuildingsList = ({ buildings }) => {
    const buildingsByCity = groupBy(buildings, (building) => {
        const { city } = parseLocation(building.baseAddress)
        return city
    })

    return (
        <ListGroup>
            <ListGroupItem
                className="d-flex align-items-center text-primary"
                tag="button"
                onClick={() => navigate('add')}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2 " />
                <span>Add a building</span>
            </ListGroupItem>

            {map(buildingsByCity, (buildings, city) => {
                return (
                    <div key={city}>
                        <ListGroupItem className="bg-light" test-attr="city">
                            <b className="text-dark">{city}</b>
                        </ListGroupItem>
                        {buildings.map((building) => {
                            const { id, name } = building

                            return (
                                <ListGroupItem
                                    test-attr="building"
                                    key={id}
                                    className="d-flex justify-content-between"
                                >
                                    <span>{name}</span>
                                    <Link
                                        className="text-decoration-none text-info"
                                        to={`../${id}`}
                                    >
                                        View
                                    </Link>
                                </ListGroupItem>
                            )
                        })}
                    </div>
                )
            })}
        </ListGroup>
    )
}

BuildingsList.propTypes = {
    buildings: arrayOf(shape(buildingType)),
}

export default BuildingsList
