import { useCallback } from 'react'
import { getToggledSortOrder } from '../../util/sort'
import useAnalytics from '../useAnalytics'

import useServerPaginationWithQuery, {
    Options,
    sortOrderOptions,
} from '../useServerPagination/useServerPaginationWithQuery'
import useSpaces from '../useSpaces'
import { PaginatedData, Pagination } from '../useServerPagination'
import { WorkAttendance } from '../../types'

export const pageSize = 25

type UseFetchCheckIns = {
    handleDate: (date: string) => void
    handleSort: (sortIdentifier: string) => void
    handleSearch: (searchTerm: string) => void
    handleFilter: (filter: Record<string, unknown>) => void
    hasRecords: boolean
    isIdle: boolean
    isLoading: boolean
    hasErrors: boolean
    error?: Error
    paginatedData: PaginatedData<WorkAttendance>
    totalCount: number
    options: Options
    pagination: Pagination
}

/**
 * A hook for fetching workplace planner work attendances.
 *
 */
export default function useFetchCheckIns(): UseFetchCheckIns {
    const { id: spaceId } = useSpaces()
    const { track } = useAnalytics()
    const {
        handleDate,
        handleSort,
        handleSearch,
        handleFilter,
        isLoading,
        error,

        paginatedData,
        totalCount,

        options,
        pagination,
    } = useServerPaginationWithQuery({
        path: `/spaces/${spaceId}/workAttendances`,
        pageSize,
        defaultSort: {
            sortBy: 'roster_records.first_name',
            sortOrder: sortOrderOptions.ascending,
        },
        defaultFilter: {
            // We supply no filter params by default which will return all records
            indicationStatuses: undefined,
            attendanceStatuses: undefined,
        },
    })

    const hasErrors = Boolean(error)
    const isIdle = !error && !isLoading && paginatedData === undefined

    const hasRecords = paginatedData !== undefined && paginatedData.length > 0

    const sortWithTrack = useCallback(
        (sortIdentifier) => {
            const { sortBy, sortOrder } = options.sort
            const sortDirection = getToggledSortOrder(sortIdentifier, {
                sortBy,
                sortOrder,
            })

            track('workplace_check_in_action', {
                action: 'Click',
                label: 'checkins_sort',
                column_name: sortIdentifier,
                sort_ascending: sortDirection === sortOrderOptions.ascending,
            })

            handleSort(sortIdentifier)
        },
        [handleSort, track, options.sort]
    )

    const searchWithTrack = useCallback(
        (searchTerm) => {
            track('workplace_check_in_action', {
                action: 'Click',
                label: 'checkins_search',
                query: searchTerm,
            })

            handleSearch(searchTerm)
        },
        [handleSearch, track]
    )

    return {
        handleDate,
        handleSort: sortWithTrack,
        handleSearch: searchWithTrack,
        handleFilter,
        hasRecords,
        isIdle,
        isLoading,
        hasErrors,
        error,

        paginatedData: paginatedData as PaginatedData<WorkAttendance>,
        totalCount,

        options,
        pagination,
    }
}
