import { useContext, useEffect } from 'react'
import usePrevious from 'use-previous'

import {
    BreadcrumbsContext,
    Breadcrumb,
    BreadcrumbsContextType,
} from '../../providers/BreadcrumbsProvider'

export type {
    Breadcrumb,
    BreadcrumbsContextType,
} from '../../providers/BreadcrumbsProvider'

/**
 * Wrapper for using the BreadcrumbsContext.
 *
 * Use either by passing in your breadcrumbs with `useBreadcrumbs([{ label: 'home'}])`
 *
 * Or dynamically  like this
 * ```
 * const { updateBreadcrumbs } = useBreadcrumbs()
 *
 * ...
 *
 * updateBreadcrumbs([{ label: 'home' }])
 * ```
 *
 * @param {*} [initialBreadcrumbs] - breadcrumbs to set on component load
 * @returns
 */
const useBreadcrumbs = (
    initialBreadcrumbs: Breadcrumb[]
): BreadcrumbsContextType => {
    const { breadcrumbs, updateBreadcrumbs } = useContext(BreadcrumbsContext)
    const previousBreadcrumbs = usePrevious(breadcrumbs)

    useEffect(() => {
        if (
            JSON.stringify(previousBreadcrumbs) !==
            JSON.stringify(initialBreadcrumbs)
        ) {
            updateBreadcrumbs(initialBreadcrumbs)
        }
    }, [updateBreadcrumbs, initialBreadcrumbs, previousBreadcrumbs])

    return { breadcrumbs, updateBreadcrumbs }
}

export default useBreadcrumbs
