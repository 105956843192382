import React from 'react'
import { Button, Row, Col } from 'reactstrap'

import errorImage from '../../images/error.svg'
import { ButtonsContainer } from '../../styles/Layout'

const TemporarilyUnavailable = (props) => {
    return (
        <Row className="justify-content-center pt-5">
            <Col md="6">
                <img className="mx-auto d-block mt-5" src={errorImage} />
                <h4 className="text-center mb-3 mt-3">
                    Temporarily unavailable
                </h4>
                <p>
                    The Scoop dashboard is temporarily unavailable and our team
                    has been notified. Try reloading the page or contact
                    ScoopCare for help.
                </p>

                <ButtonsContainer>
                    <Button
                        id="reload-button"
                        className="btn-block"
                        size="lg"
                        color="primary"
                        onClick={() => {
                            window.location.reload(true)
                        }}
                    >
                        Reload this page
                    </Button>
                    <Button
                        id="scoop-care-button"
                        className="btn-block btn-lg btn-outline-primary text-decoration-none"
                        color="white"
                        onClick={() => {
                            window.open(
                                'https://help.customers.scoopcommute.com/',
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }}
                    >
                        Contact ScoopCare
                    </Button>
                </ButtonsContainer>
            </Col>
        </Row>
    )
}

export default TemporarilyUnavailable
