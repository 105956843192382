import React from 'react'
import { bool } from 'prop-types'
import { Spinner } from 'reactstrap'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SearchIconSpinner = ({ isLoading = false }) => {
    return isLoading ? (
        <Spinner size="sm" color="secondary" data-test="spinner" />
    ) : (
        <FontAwesomeIcon icon={faSearch} className="text-secondary" />
    )
}

SearchIconSpinner.propTypes = {
    isLoading: bool,
}

export default SearchIconSpinner
