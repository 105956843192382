import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Buildings from '../Buildings'
import Integrations from '../Integrations'

const Settings = (
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    props: RouteComponentProps
): JSX.Element => {
    // The Integrations page is disabled because commute customers won't be using that feature in Scoop Commute, until
    // the feature receives a new WorkOS instance and is re-enabled.
    return (
        <>
            {/* <Integrations /> */}
            <Buildings />
        </>
    )
}

export default Settings
