import React from 'react'
import { Row, Col } from 'reactstrap'

const NextSteps = () => {
    return (
        <>
            <Row>
                <Col className="my-3 mx-3 pl-0 border-bottom">
                    <h5>Up next</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <h6 className="font-weight-bold">
                        Step 2: CSV confirmation
                    </h6>
                    <p>Preview information in your CSV and save roster.</p>
                </Col>
            </Row>
        </>
    )
}

export default NextSteps
