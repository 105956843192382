import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'reactstrap'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

import GhostLoader from '../GhostLoader'
import useAnalytics from '../../hooks/useAnalytics'
import { getDateRangeProperties } from '../../util/analytics'
import { dateFormats } from '../../util/date'
import { LastUpdatedTimeAnalytics } from '../../types'

const LAST_UPDATED_TIME_WIDTH = 260
const FONT_SIZE = 18

export enum IconTypes {
    info = 'info',
    warning = 'warning',
}

const iconTypeToIcon = new Map([
    [IconTypes.info, faInfoCircle],
    [IconTypes.warning, faExclamationTriangle],
])

const iconNameToColor = new Map([
    [IconTypes.info, 'text-secondary'],
    [IconTypes.warning, 'text-danger'],
])

type Props = {
    isLoading?: boolean
    timestamp: string | null
    isTimestampOutdated?: boolean
    content?: string | null
    icon: IconTypes
    tooltipBody: string
    analytics: LastUpdatedTimeAnalytics
}

const LastUpdatedTimestamp = ({
    isLoading,
    timestamp,
    isTimestampOutdated,
    content,
    icon,
    tooltipBody,
    analytics,
}: Props): JSX.Element => {
    const [isTooltipOpen, setTooltipOpen] = useState(false)
    const { track } = useAnalytics()

    const formattedTimestamp = useMemo(() => {
        const date = timestamp ? new Date(timestamp) : new Date()
        return format(date, dateFormats.time)
    }, [timestamp])

    const dateRangeProperties = useMemo(() => {
        return analytics.period ? getDateRangeProperties(analytics.period) : {}
    }, [analytics.period])

    useEffect(() => {
        if (isTimestampOutdated) {
            track(analytics.category, {
                action: 'View',
                label: 'stale_data',
                ...dateRangeProperties,
            })
        } else {
            track(analytics.category, {
                action: 'View',
                label: 'last_update',
                ...dateRangeProperties,
            })
        }
    }, [isTimestampOutdated, track, analytics.category, dateRangeProperties])

    const toggleTooltip = useCallback(() => {
        if (!isTooltipOpen) {
            if (isTimestampOutdated) {
                track(analytics.category, {
                    action: 'Hover',
                    label: 'stale_data_tooltip',
                    ...dateRangeProperties,
                })
            } else {
                track(analytics.category, {
                    action: 'Hover',
                    label: 'last_updated_tooltip',
                    ...dateRangeProperties,
                })
            }
        }

        setTooltipOpen(!isTooltipOpen)
    }, [
        isTooltipOpen,
        isTimestampOutdated,
        track,
        analytics.category,
        dateRangeProperties,
    ])

    if (isLoading) {
        return (
            <GhostLoader width={LAST_UPDATED_TIME_WIDTH} height={FONT_SIZE} />
        )
    }

    return (
        <span data-test="last-updated">
            Last updated: {content || formattedTimestamp}
            <FontAwesomeIcon
                style={{ pointerEvents: 'all' }}
                icon={iconTypeToIcon.get(icon) || faInfoCircle}
                className={`${iconNameToColor.get(icon)} ml-2`}
                id="lastRefreshedTimeStamp"
            />
            <Tooltip
                target="lastRefreshedTimeStamp"
                isOpen={isTooltipOpen}
                toggle={toggleTooltip}
            >
                {tooltipBody}
            </Tooltip>
        </span>
    )
}

export default LastUpdatedTimestamp
