import React from 'react'
import { Col, Container, Row } from 'reactstrap'

import CheckInRangeDropdown from './CheckInRangeDropdown'
import BarChartSection from '../../components/Chart/BarChartSection'
import useCheckInRangeFromUrl, {
    past30Days,
} from '../../hooks/useCheckInRangeFromUrl'
import useTrackView from '../../hooks/useTrackView'
import { Header } from '../../components/PageLayout'

const WorkplaceCheckInTrends = () => {
    const {
        checkInRange,
        checkInDateRange,
        changeCheckInRange,
        hasCheckInRange,
    } = useCheckInRangeFromUrl()

    useTrackView({
        category: 'check_in_trends_action',
        label: 'check_in_trends',
    })

    // Set check-in-range if not specified
    if (!hasCheckInRange) {
        changeCheckInRange({ range: past30Days })

        // navigate is async, so render nothing while redirect happens
        return null
    }

    return (
        <Container fluid>
            <Header title="Attendance trends">
                <CheckInRangeDropdown
                    onChange={changeCheckInRange}
                    checkInRange={checkInRange}
                    checkInDateRange={checkInDateRange}
                />
            </Header>
            <Row>
                <Col>
                    <BarChartSection
                        title="Workplace attendance"
                        description="Workers who who planned to come into the workplace per day"
                        path={`/spaces/:spaceId/metrics/workAttendanceIndications\\?from=${checkInDateRange.from}&until=${checkInDateRange.until}&statisticType=sum&calculationInterval=daily`}
                        granularity="daily"
                        analytics={{
                            category: 'check_in_trends_action',
                            name: 'workplace_check-ins',
                            clickLabel: 'workplace_check_click',
                            viewLabel: 'workplace_check_view',
                        }}
                    />

                    <BarChartSection
                        title="Average intended days in the workplace"
                        description="Average intended days in the workplace per worker going into work each week"
                        path={`/spaces/:spaceId/metrics/workAttendanceIndications\\?from=${checkInDateRange.from}&until=${checkInDateRange.until}&statisticType=mean&groupBy=account&calculationInterval=weekly`}
                        granularity="weekly"
                        analytics={{
                            category: 'check_in_trends_action',
                            name: 'average_check-ins',
                            clickLabel: 'average_check_click',
                            viewLabel: 'average_check_view',
                        }}
                    />

                    <BarChartSection
                        title="Workers in the workplace"
                        description="Total workers intending to use the workplace per week"
                        path={`/spaces/:spaceId/metrics/workAttendees\\?from=${checkInDateRange.from}&until=${checkInDateRange.until}&statisticType=sum&calculationInterval=weekly`}
                        granularity="weekly"
                        analytics={{
                            category: 'check_in_trends_action',
                            name: 'workers_commuting',
                            clickLabel: 'workers_commuting_click',
                            viewLabel: 'workers_commuting_view',
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default WorkplaceCheckInTrends
