import React, { useCallback, useContext, useEffect } from 'react'

import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import toast from '../../../components/Toast/toast'
import useRosterRecordsInviteBulkResource from '../../../hooks/resources/useRosterRecordsInviteBulkResource'
import useLocalStorage from '../../../hooks/useLocalStorage'
import useModal from '../../../hooks/useModal'
import useSpaces from '../../../hooks/useSpaces'
import { EmployeeRosterContext } from '../../../providers/EmployeeRosterProvider'
import { InvitationStatus } from '../../../types'

const reminderId = 'postRosterUploadSendInvites'

const useRosterInviteReminder = (): void => {
    const { id: spaceId } = useSpaces()
    const { getNotYetInvitedData, datatable } = useContext(
        EmployeeRosterContext
    )
    const openModal = useModal()
    const { value: isReminderDisabled } = useLocalStorage(reminderId)

    const {
        inviteBulk,
        response: inviteBulkResponse,
    } = useRosterRecordsInviteBulkResource(spaceId)

    const sendInvites = useCallback(async () => {
        await inviteBulk(InvitationStatus.notYetInvited)

        if (inviteBulkResponse.ok) {
            await getNotYetInvitedData()
            await datatable.getData({})
            toast.success('Employees were invited to join Scoop.')
        } else {
            toast.error('We could not send invitations. Please try again.')
        }
    }, [datatable, inviteBulk, inviteBulkResponse, getNotYetInvitedData])

    const fetchDataAndOpenModal = useCallback(async () => {
        const data = await getNotYetInvitedData()

        if (data?.length) {
            openModal(modalTypeMap.GENERIC_CONFIRMATION, {
                reminderId,
                title: 'Roster has been populated',
                description: (
                    <>
                        <p>
                            Great job! Your roster has been successfully
                            uploaded.
                        </p>
                        <p>
                            Now, let’s send invites via email to your employees
                            so they can create their Scoop account. You’ll need
                            to do this every time you add employees. You can
                            send invites later by clicking the “•••” icon.
                        </p>
                    </>
                ),
                saveButtonLabel: 'Send invites',
                savingButtonLabel: 'Sending...',
                onSave: sendInvites,
            })
        }
    }, [openModal, getNotYetInvitedData, sendInvites])

    useEffect(() => {
        if (!isReminderDisabled) {
            fetchDataAndOpenModal()
        }

        // We are ignoring fetchDataAndOpenModal because we only want to run this logic once on mount.
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isReminderDisabled])
}

export default useRosterInviteReminder
