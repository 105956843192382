import DeactivateCarpooler from '../../modals/DeactivateCarpooler'
import DownloadCSV from '../../modals/DownloadCSV'
import ConfirmationModal from '../../modals/ConfirmationModal'
import NoActivity from '../../modals/NoActivity'
import ReactivateCarpooler from '../../modals/ReactivateCarpooler'
import RosterUploadReminder from '../../modals/RosterUploadReminder'
import SignOutModal from '../../modals/SignOut'
import EditDailyCheckInLimit from '../../modals/EditDailyCheckInLimit'
import ReviewVaccinationDocumentationModal from '../../modals/ReviewVaccinationDocumentationModal'

type ModalComponent = (props: unknown) => JSX.Element | null

/**
 * This is necessary as ReduxModal needs a Component to render, even if there is no modal open
 */
export const NullComponent = (): null => null

export const modalTypeMap = {
    DOWNLOAD_CSV: 'DOWNLOAD_CSV',
    DEACTIVATE_CARPOOLER: 'DEACTIVATE_CARPOOLER',
    NO_ACTIVITY: 'NO_ACTIVITY',
    REACTIVATE_CARPOOLER: 'REACTIVATE_CARPOOLER',
    SIGN_OUT: 'SIGN_OUT',
    ROSTER_UPLOAD_REMINDER: 'ROSTER_UPLOAD_REMINDER',
    GENERIC_CONFIRMATION: 'GENERIC_CONFIRMATION',
    EDIT_CHECK_IN_LIMIT: 'EDIT_CHECK_IN_LIMIT',
    REVIEW_VACCINATION_DOCUMENTATION: 'REVIEW_VACCINATION_DOCUMENTATION',
}

export const getModalComponentByType = (type: string): ModalComponent => {
    switch (type) {
        case modalTypeMap.DOWNLOAD_CSV:
            return DownloadCSV
        case modalTypeMap.DEACTIVATE_CARPOOLER:
            return DeactivateCarpooler
        case modalTypeMap.NO_ACTIVITY:
            return NoActivity
        case modalTypeMap.REACTIVATE_CARPOOLER:
            return ReactivateCarpooler
        case modalTypeMap.ROSTER_UPLOAD_REMINDER:
            return RosterUploadReminder
        case modalTypeMap.GENERIC_CONFIRMATION:
            return ConfirmationModal
        case modalTypeMap.SIGN_OUT:
            return SignOutModal
        case modalTypeMap.EDIT_CHECK_IN_LIMIT:
            return EditDailyCheckInLimit
        case modalTypeMap.REVIEW_VACCINATION_DOCUMENTATION:
            return ReviewVaccinationDocumentationModal
        default:
            return NullComponent
    }
}
