import { CachePolicies, Res, useFetch } from 'use-http'
import { useMemo } from 'react'
import {
    CosmosError,
    ResponseError,
    TestingLogRecordResource,
} from '../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

type TestResultResource = {
    isLoading: boolean
    testResultRecord?: TestingLogRecordResource
    error?: Error | CosmosError
    get: (testResultId: string) => Promise<TestingLogRecordResource>
    response: Res<TestingLogRecordResource | ResponseError>
}

/**
 *
 * @params spaceId - space alias of the target customer.
 * @params dependencies - dependency array of the hook. Each change will trigger a new request to the API.
 */
export default function useTestResultByIdResource(
    spaceId: string
): TestResultResource {
    const { data, loading, error: networkError, get, response } = useFetch<
        TestingLogRecordResource | ResponseError
    >(`/spaces/${spaceId}/testResults`, fetchOptions)

    const error = useMemo(
        () => (data as ResponseError)?.error || networkError,
        [data, networkError]
    )

    const testResultRecord = useMemo(() => {
        if (data && 'id' in data && data.id) {
            return data as TestingLogRecordResource
        }
        return undefined
    }, [data])

    return {
        isLoading: loading,
        testResultRecord,
        error,
        response,
        get,
    }
}
