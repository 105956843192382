import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core'
import {
    faCheckCircle,
    faExclamationCircle,
    faFileUpload,
    faInfoCircle,
    faTimesCircle,
    faArrowFromBottom,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faArrowLeft,
    faTimesCircle as faTimesCircleRegular,
} from '@fortawesome/pro-regular-svg-icons'

/**
 * Icon names used in Scoop web apps.
 * Search icon names at https://fontawesome.com/v5.15/icons
 *
 * Copied from chrome-extension
 */
export type SupportedIconName =
    | 'check-circle'
    | 'exclamation-circle'
    | 'file-upload'
    | 'info-circle'
    | 'times-circle'
    | 'times-circle-regular'
    | 'arrow-left'
    | 'arrow-from-bottom'

const nameToIcon: Record<SupportedIconName, IconDefinition> = {
    'check-circle': faCheckCircle,
    'exclamation-circle': faExclamationCircle,
    'file-upload': faFileUpload,
    'info-circle': faInfoCircle,
    'times-circle': faTimesCircle,
    'times-circle-regular': faTimesCircleRegular,
    'arrow-left': faArrowLeft,
    'arrow-from-bottom': faArrowFromBottom,
}

type Props = {
    name: SupportedIconName
    size?: SizeProp
    className?: string
    color?: string
}

/**
 * @see https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
 */
const Icon = ({ name, size, className, color }: Props): JSX.Element => {
    const icon = nameToIcon[name]

    return (
        <FontAwesomeIcon
            icon={icon}
            color={color}
            size={size}
            className={className}
        />
    )
}

export default Icon
