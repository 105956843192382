import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import OktaWidget from '@okta/okta-signin-widget'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

const widgetOptions = {
    authParams: {
        pkce: false,
        responseType: 'code',
        display: 'page',
        scopes: ['openid', 'email', 'profile'],
    },
}

function useOktaWidget({
    orgUrl,
    clientId,
    oktaRedirectUri: redirectUri,
    targetElement,
}) {
    useEffect(() => {
        const signIn = new OktaWidget({
            ...widgetOptions,
            ...{ baseUrl: orgUrl, clientId, redirectUri },
        })

        signIn.renderEl(
            { el: targetElement.current },
            function success(res) {
                // Do not use this callback.
                // OpenID Connect redirect flow is used, so, success won't be called.
                // Instead, use sessionTransactionFetchSuccess in <OktaSignIn />.
                // https://github.com/okta/okta-signin-widget#openid-connect
            },
            function error(err) {
                // eslint-disable-line no-console
                console.error(err)
            }
        )

        return () => {
            signIn.remove()
        }
    }, [clientId, orgUrl, redirectUri, targetElement])
}

/**
 * Placeholder where Okta Sign-in Widget will be displayed.
 * This is intended to be displayed only if the user has signed in.
 *
 * @param {Object} props - React props
 * @param {String} props.orgUrl -
 * @param {String} props.clientId - client ID of Scoop registered in Okta
 * @param {String} props.oktaRedirectUri - The URI that Okta Sign-in server will be redirected to after authentication.
 * @param {String} props.nextPath - Next path to go after successful sign-in.
 */
export const OktaSignInWidget = ({
    className,
    orgUrl,
    clientId,
    oktaRedirectUri,
    nextPath,
}) => {
    const targetElement = useRef()
    const [, setCookie] = useCookies()
    useOktaWidget({ orgUrl, clientId, oktaRedirectUri, targetElement })

    useEffect(() => {
        // Store next path as a cookie to use it after
        // the page is refreshed in oktaRedirect.html
        setCookie('okta-next-path', nextPath, { path: '/' })
    }, [setCookie, nextPath])

    return <div ref={targetElement} className={className} />
}

OktaSignInWidget.propTypes = {
    className: PropTypes.string,
    orgUrl: PropTypes.string,
    clientId: PropTypes.string,
    oktaRedirectUri: PropTypes.string,
    nextPath: PropTypes.string.isRequired,
}

export default styled(OktaSignInWidget)`
    .button-primary {
        background: #00b373 !important;
        border-color: #00b373 !important;
    }
`
