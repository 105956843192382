import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { redirectTo } from '@reach/router'
import {
    createSessionTransactionCheckRequest,
    createSessionTransactionCheckClear,
    selectSessionTransactionCheckRequest,
    createSessionTransactionClear,
} from '../../state/auth'

const useCheckSession = ({
    redirectPath,
    next = '/',
    redirect = true,
} = {}) => {
    const dispatch = useDispatch()
    const [cookies] = useCookies(['preferred-sign-in'])

    const defaultRedirect =
        cookies['preferred-sign-in'] === 'okta' ? '/okta' : '/sign-in'

    useEffect(() => {
        // clear the existing state.
        dispatch(createSessionTransactionClear())
        // check if we have a current session transaction.
        dispatch(createSessionTransactionCheckRequest())

        return () => dispatch(createSessionTransactionCheckClear())
    }, [dispatch]) // run once and cleanup on unmount.

    const { isFetching, error } = useSelector(
        selectSessionTransactionCheckRequest
    )

    if (redirect && error) {
        const path = `${_.defaultTo(
            redirectPath,
            defaultRedirect
        )}?next=${next}`

        // WARNING - redirectTo is implemented by throwing an Error.
        // https://reach.tech/router/api/redirectTo
        return redirectTo(path)
    }

    return {
        isFetching: isFetching === true || isFetching === undefined, // undefined is initial redux state
        error,
        success: !error && isFetching === false,
    }
}

export default useCheckSession
