import React from 'react'
import { string, shape } from 'prop-types'
import moment from 'moment'

import { timeDisplayFormat } from '../../../../util/date'

const HealthAndSafetyTimeStamp = ({
    indicationId,
    healthAndSafetySubmission,
}) => {
    if (!healthAndSafetySubmission || !indicationId) {
        return null
    }

    const createdAt = moment(healthAndSafetySubmission.createdAt).format(
        timeDisplayFormat
    )

    return <div>{createdAt}</div>
}

HealthAndSafetyTimeStamp.propTypes = {
    indicationId: string,
    healthAndSafetySubmission: shape({
        id: string,
        createdAt: string,
    }),
}

export default HealthAndSafetyTimeStamp
