import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { instanceOf, func, bool } from 'prop-types'

import './style.scss'

/**
 * Custom wrapper around react-datepicker
 */
export const DatePickerCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div className="d-flex justify-content-between align-items-center px-2 mb-3">
        <FontAwesomeIcon
            className="cursor-pointer"
            icon={faChevronLeft}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            test-attr="decrease-month-icon"
        />
        <h5 className="mb-0">
            {date.toLocaleDateString('default', {
                month: 'long',
                year: 'numeric',
            })}
        </h5>
        <FontAwesomeIcon
            className="cursor-pointer"
            icon={faChevronRight}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            test-attr="increase-month-icon"
        />
    </div>
)

DatePickerCustomHeader.propTypes = {
    date: instanceOf(Date),
    decreaseMonth: func,
    increaseMonth: func,
    prevMonthButtonDisabled: bool,
    nextMonthButtonDisabled: bool,
}

/**
 * Date picker with Scoop theme.
 * Consider using FormDatePicker for Date picker wrapped with Final Form Field.
 *
 * @property {Date} props.selected - selected date.
 * @see https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md for the list of props.
 */
const CustomDatePicker = (props) => {
    return (
        <DatePicker
            calendarClassName="custom-datepicker user-select-none"
            renderCustomHeader={DatePickerCustomHeader}
            {...props}
        />
    )
}

export default CustomDatePicker
