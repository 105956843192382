import React, { useState } from 'react'
import {
    Popover,
    PopoverHeader,
    PopoverBody,
    CustomInput,
    Form,
} from 'reactstrap'

import useFeatureFlags, {
    enableFeatureFlag,
    disableFeatureFlag,
} from '../useFeatureFlags'

type Props = {
    targetId: string
}

/**
 * A popover menu where feature flags can be enabled in local & staging environments.
 */
const FeatureFlagsPopover = ({ targetId }: Props): JSX.Element => {
    const featureFlags = useFeatureFlags()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const togglePopover = () => setIsPopoverOpen(!isPopoverOpen)

    const changeFeatureFlag = (featureFlag: string, shouldEnable: boolean) => {
        if (shouldEnable) {
            enableFeatureFlag(featureFlag)
        } else {
            disableFeatureFlag(featureFlag)
        }
    }

    return (
        <Popover
            placement="top"
            trigger="legacy"
            target={targetId}
            isOpen={isPopoverOpen}
            toggle={togglePopover}
        >
            <PopoverHeader>Feature Flags</PopoverHeader>
            <PopoverBody>
                <p>Try features on your web browser.</p>

                <Form>
                    {Object.entries(featureFlags).map(
                        ([featureFlag, isEnabled]) => {
                            return (
                                <CustomInput
                                    key={featureFlag}
                                    id={featureFlag}
                                    data-test={featureFlag}
                                    label={featureFlag}
                                    type="switch"
                                    checked={isEnabled}
                                    onChange={(e) => {
                                        changeFeatureFlag(
                                            featureFlag,
                                            e.target.checked
                                        )
                                    }}
                                />
                            )
                        }
                    )}
                </Form>
            </PopoverBody>
        </Popover>
    )
}

export default FeatureFlagsPopover
