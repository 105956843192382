import PropTypes from 'prop-types'
import React from 'react'

import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const NoActivity = ({ onClose, title, body }) => {
    return (
        <>
            <ModalHeader toggle={onClose}>{title} </ModalHeader>
            <ModalBody>
                <p>{body}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </>
    )
}

NoActivity.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
}

export default NoActivity
