import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons'
import { VaccinationReviewStatus } from '../../../types'
import useModal from '../../../hooks/useModal'
import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import useVaccinationProofResource from '../../../hooks/resources/useVaccinationProofResource'
import useSpaces from '../../../hooks/useSpaces'

type Props = {
    proofId: string
    vaccinationReviewStatus: VaccinationReviewStatus
    refreshData: () => void
}

const titleMap = {
    [VaccinationReviewStatus.rejected]: 'Revoke rejection of documentation',
    [VaccinationReviewStatus.verified]: 'Revoke verification of documentation',
}

const buttonTextMap = {
    [VaccinationReviewStatus.rejected]: 'Revoke rejection',
    [VaccinationReviewStatus.verified]: 'Revoke verification',
}

const RevokeVaccinationProofButton = ({
    proofId,
    vaccinationReviewStatus,
    refreshData,
}: Props): JSX.Element => {
    const { id: spaceId } = useSpaces()
    const openModal = useModal()

    const { setStatus } = useVaccinationProofResource(spaceId)

    const openRevokeModal = () => {
        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: titleMap[vaccinationReviewStatus],
            description:
                'This will put the employee’s vaccination status back into a pending state for additional review of their documentation.',
            saveButtonLabel: 'Revoke',
            savingButtonLabel: 'Revoking...',
            saveButtonColor: 'danger',
            onSave: () => setStatus(proofId, VaccinationReviewStatus.pending),
            onClose: ({ didSave }) => didSave && refreshData(),
        })
    }

    return (
        <Button
            color="link"
            size="sm"
            data-test={`revoke-${vaccinationReviewStatus}-vaccination-proof-documentation`}
            onClick={openRevokeModal}
        >
            <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
            {buttonTextMap[vaccinationReviewStatus]}
        </Button>
    )
}

export default RevokeVaccinationProofButton
