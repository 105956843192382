import { CosmosApiError } from './Errors'
import 'whatwg-fetch' // IE11 polyfill
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import serializeError from '../state/serializeError'

const cosmosOptions = {
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
    },
    redirect: 'follow',
}

const APPLICATION_JSON = 'application/json'
const TEXT_CSV = 'text/csv'

const data = new WeakMap()

export const fetchOptions = {
    ...cosmosOptions,

    // By default, do not use cache on URLs/responses to avoid side effects.
    cachePolicy: 'no-cache',

    interceptors: {
        response({ response }) {
            if (!response.ok) {
                const errorEnvelope = response?.data?.error || {}

                // Only data can be modified at the moment.
                response.data = {
                    error: serializeError(
                        new CosmosApiError(
                            response.status,
                            errorEnvelope.id,
                            errorEnvelope.message,
                            response.headers
                        )
                    ),
                }
            }

            return response
        },
    },
}

class CosmosApi {
    constructor({ apiRoot }) {
        data[this] = { apiRoot }
    }

    get apiRoot() {
        const { apiRoot } = data[this]
        return apiRoot
    }

    async call(path, options = {}) {
        const { apiRoot } = data[this]
        const mergedHeaders = {
            ...cosmosOptions.headers,
            ...options.headers,
        }
        const mergedOptions = {
            ...cosmosOptions,
            ...options,
            ...{ headers: mergedHeaders },
        }

        if (mergedOptions.headers['Content-Type'] !== TEXT_CSV) {
            mergedOptions.body = JSON.stringify(mergedOptions.body)
        }

        const fullPath = `${apiRoot}${path}`

        const response = await fetch(fullPath, mergedOptions)

        if (!response.ok) {
            const parsedBody = await response.json()
            const errorEnvelope = parsedBody.error

            throw new CosmosApiError(
                response.status,
                errorEnvelope.id,
                errorEnvelope.message,
                response.headers
            )
        } else {
            let parsedBody
            let blob

            try {
                if (options.headers?.accept === TEXT_CSV) {
                    blob = await response.blob()
                } else if (
                    // Only parse as JSON if the response is JSON
                    response.headers
                        .get('Content-Type')
                        .includes(APPLICATION_JSON)
                ) {
                    parsedBody = await response.json()
                }
            } catch (err) {
                // ignore the error, the JSON is either empty or malformed.
            }
            const headers = response.headers
            return {
                parsedBody,
                headers,
                blob,
            }
        }
    }
}

export default CosmosApi
