import React, { useEffect, useState, useCallback } from 'react'
import { Link } from '@reach/router'
import { PropTypes } from 'prop-types'

import Layout from './Layout'
import { Number } from './styled'
import useDebounce from '../../hooks/useDebounce'
import useAnalytics from '../../hooks/useAnalytics'
import withMetricOrChartData from '../../hocs/withMetricOrChartData'
import GhostLoader from '../GhostLoader'
import ConditionalWrap from '../ConditionalWrap'

const numberFormat = new Intl.NumberFormat()
const loaderVisibilityTimeout = 1000

const MetricDisplay = ({
    suffix,
    title,
    data,
    tooltipBody,
    isLoading,
    error,
    isUnavailable,
    linkTo,
    analytics = {},
    isUltraText,
}) => {
    const includeAnalytics = Boolean(analytics.name && analytics.category)
    const { track } = useAnalytics()
    const [prevNumber, setPrevNumber] = useState()
    const number = data?.statistics?.[0]?.result
    const numberToDisplay = number === undefined ? prevNumber : number
    const isDebouncedLoading = useDebounce(isLoading, loaderVisibilityTimeout)

    // Keep displaying the current number until the new number is loaded.
    useEffect(() => {
        if (number !== undefined && number !== null) {
            setPrevNumber(number)
        }
    }, [number, setPrevNumber])

    const trackClick = useCallback(() => {
        if (includeAnalytics) {
            track(analytics.category, {
                action: 'Click',
                label: 'performance_metric_zone_click',
                metric_name: analytics.name,
                metric_value: numberToDisplay,
                ...analytics.dateRangeProperties,
            })
        }
    }, [
        track,
        analytics.category,
        analytics.name,
        analytics.dateRangeProperties,
        includeAnalytics,
        numberToDisplay,
    ])

    const trackMouseEnter = useCallback(() => {
        if (includeAnalytics) {
            track(analytics.category, {
                action: 'Hover',
                label: 'performance_metric_zone_hover',
                metric_name: analytics.name,
                metric_value: numberToDisplay,
                ...analytics.dateRangeProperties,
            })
        }
    }, [
        track,
        analytics.category,
        analytics.name,
        analytics.dateRangeProperties,
        includeAnalytics,
        numberToDisplay,
    ])

    if (isUnavailable) {
        return (
            <Layout
                title={title}
                tooltipBody={tooltipBody}
                onClick={trackClick}
                onMouseEnter={trackMouseEnter}
                analytics={analytics}
            >
                <span className="h1">None</span>
            </Layout>
        )
    }

    if (isDebouncedLoading) {
        return (
            <Layout
                isUltraText={isUltraText}
                title={title}
                tooltipBody={tooltipBody}
                analytics={analytics}
            >
                <GhostLoader height={isUltraText ? 100 : undefined} />
            </Layout>
        )
    }

    if (error || numberToDisplay === null || numberToDisplay === undefined) {
        return (
            <Layout
                isUltraText={isUltraText}
                onClick={trackClick}
                onMouseEnter={trackMouseEnter}
                title={title}
                tooltipBody={tooltipBody}
                analytics={analytics}
            >
                <div
                    className="w-100 bg-grey"
                    style={{ height: isUltraText ? 100 : 50 }}
                    test-attr="grey-box"
                />
            </Layout>
        )
    }

    return (
        <ConditionalWrap
            condition={Boolean(linkTo)}
            wrapper={(children) => {
                return (
                    <Link
                        to={linkTo}
                        className="text-decoration-none text-dark"
                    >
                        {children}
                    </Link>
                )
            }}
        >
            <Layout
                isUltraText={isUltraText}
                title={title}
                tooltipBody={tooltipBody}
                onClick={trackClick}
                onMouseEnter={trackMouseEnter}
                analytics={analytics}
            >
                <Number
                    data-test={`metric-number-${title}`}
                    className="ml-4"
                    isUltraText={isUltraText}
                >
                    {numberFormat.format(numberToDisplay)}
                </Number>

                {suffix && (
                    <p data-test={`suffix-${title}`} className="ml-4 mb-0">
                        {suffix}
                    </p>
                )}
            </Layout>
        </ConditionalWrap>
    )
}

MetricDisplay.propTypes = {
    suffix: PropTypes.string,
    isUltraText: PropTypes.bool,
    title: PropTypes.node.isRequired,
    data: PropTypes.shape({
        statistics: PropTypes.arrayOf(
            PropTypes.shape({
                result: PropTypes.number.isRequired,
            }).isRequired
        ).isRequired,
    }),
    tooltipBody: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    isUnavailable: PropTypes.bool,
    linkTo: PropTypes.string,
    analytics: PropTypes.shape({
        category: PropTypes.string,
        name: PropTypes.string,
        dateRangeProperties: PropTypes.shape({
            range_size: PropTypes.string,
            date_range_start: PropTypes.string.isRequired,
            date_range_end: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            quarter: PropTypes.number,
            month: PropTypes.number,
            week: PropTypes.number,
        }),
    }),
}

export default MetricDisplay

export const MetricDisplayWithData = withMetricOrChartData(MetricDisplay)
