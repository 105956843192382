import React from 'react'
import { shape, string } from 'prop-types'
import styled from 'styled-components'

const WarningSpan = styled.span`
    color: #d57421;
`

const CheckInId = ({ indication }) => {
    const checkInId = indication?.confirmationCode

    if (checkInId) {
        return checkInId
    }

    return <WarningSpan>None</WarningSpan>
}

CheckInId.propTypes = {
    indication: shape({
        id: string,
    }),
}

export default CheckInId
