import { isFirstPeriodInRange, formatFirstPeriodData } from './firstPeriod'
import {
    isCurrentPeriodInRange,
    formatCurrentPeriodData,
} from './currentPeriod'
import { Statistic, Granularity } from '../../types'

type Input = {
    statistics: Statistic[]
    startDate: string
    granularity: Granularity
}

type Output = {
    chartData: Statistic[]
    tripsStartData?: Statistic
}

/**
 * Inspects the array of statistics and formats it with the appropriate
 * utility formatter depending on whether the range of data falls within
 * the first or current period.
 *
 * If the range is both in the first and current period, then run the array of statistics
 * through both transformations. If this is the case, also returns tripsStartDate which can
 * be used to determine which helper text to render
 *
 * @example
 * tripsStartDate is in July 2, and the current day is July 15 and
 * you're viewing the range of data for July. We would want to format the data in such a way where
 * July 1 is undefined, July 2 - 14 has data, and then July 15 - 31 is undefined. This will ensure
 * that the svg line renders from just July 2 - 14.
 *
 */

export function getChartDataInRange({
    statistics,
    startDate,
    granularity,
}: Input): Output {
    const isFirstPeriod = isFirstPeriodInRange({
        statistics,
        startDate,
    })
    const isCurrentPeriod = isCurrentPeriodInRange(statistics)

    if (isFirstPeriod) {
        const {
            firstPeriodFormattedData,
            tripsStartData,
        } = formatFirstPeriodData({
            granularity,
            statistics,
            startDate,
        })

        if (isCurrentPeriod) {
            return {
                tripsStartData,
                chartData: formatCurrentPeriodData({
                    granularity,
                    statistics: firstPeriodFormattedData,
                }),
            }
        }

        return { chartData: firstPeriodFormattedData }
    }

    if (isCurrentPeriod) {
        return {
            chartData: formatCurrentPeriodData({
                granularity,
                statistics,
            }),
        }
    }

    return { chartData: statistics }
}
