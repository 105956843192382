import PropTypes from 'prop-types'
import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

import Button from '../../../components/Button.tsx'
import ImageContainer from '../../../components/ImageContainer'
import srcError from '../../../images/error.svg'

const TryAgain = ({ onComplete, onCancel, file = {}, isSubmitting }) => {
    return (
        <Row
            className="my-3 justify-content-center"
            data-test="roster-upload-try-again"
        >
            <Col md="5">
                <h3 className="mb-4">Upload employee roster</h3>
                <ImageContainer src={srcError} />
                <p>
                    We were unable to upload <strong>{file.name}</strong>.
                    Please try again.
                </p>

                <Button
                    size="md"
                    className="mt-4"
                    onClick={onComplete}
                    label="Try Again"
                    loadingLabel="Confirming roster..."
                    test-attr="confirm-btn"
                    isLoading={isSubmitting}
                />
                <Button
                    block
                    color="link"
                    onClick={onCancel}
                    label="Cancel"
                    className="nav-link text-primary text-decoration-none text-center mt-4"
                    test-attr="cancel-btn"
                />
            </Col>
        </Row>
    )
}

TryAgain.propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    file: PropTypes.object,
    isSubmitting: PropTypes.bool,
}

export default TryAgain
