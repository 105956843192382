import PropTypes from 'prop-types'
import React from 'react'
import {
    Button,
    Input,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap'

import useLocalStorage from '../../hooks/useLocalStorage'

const RosterUploadReminder = ({ onClose }) => {
    const { value: isReminderDisabled, write } = useLocalStorage(
        'isRosterUploadReminderDisabled'
    )

    function handleCheckboxchange(e) {
        const isChecked = e.target.checked

        write(isChecked)
    }

    return (
        <>
            <ModalHeader data-test="modal-header" toggle={onClose}>
                Reminder
            </ModalHeader>
            <ModalBody data-test="modal-body">
                <p>
                    The CSV will need to contain the entire employee roster
                    every time it is uploaded. In addition, any employee not on
                    the new roster will be deactivated.
                </p>
            </ModalBody>
            <ModalFooter>
                <FormGroup className="mr-2 custom-input">
                    <Input
                        type="checkbox"
                        id="checkbox"
                        checked={Boolean(isReminderDisabled)}
                        onChange={handleCheckboxchange}
                    />
                    <Label for="checkbox">
                        Don&apos;t show this message again
                    </Label>
                </FormGroup>
                <Button
                    data-test="continue-button"
                    color="primary"
                    onClick={onClose}
                >
                    Continue
                </Button>
            </ModalFooter>
        </>
    )
}

RosterUploadReminder.propTypes = {
    onClose: PropTypes.func,
}

export default RosterUploadReminder
