import { Redirect, Router, RouteComponentProps } from '@reach/router'
import React from 'react'

import ModuleFlag from '../../components/ModuleFlag'
import WorkplaceCheckIns from '../WorkplaceCheckIns'
import WorkplaceCheckInTrends from '../WorkplaceCheckInTrends'
import WorkplacePolicies from '../WorkplacePolicies'
import Desks from '../Desks'

const WorkplacePlanner = (
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    props: RouteComponentProps
): JSX.Element => {
    return (
        <ModuleFlag
            name="workplacePlanner"
            fallback={<Redirect to="../" noThrow />}
        >
            <Router>
                <Redirect from="/" to="attendance" noThrow />

                <WorkplaceCheckIns path="attendance" />

                <WorkplaceCheckInTrends path="trends" />

                <Desks path="desks/*" />

                <WorkplacePolicies path="policies" />
            </Router>
        </ModuleFlag>
    )
}

export default WorkplacePlanner
