import React, { useContext, useEffect } from 'react'

import { DesksContext } from '../../providers/DesksContextProvider'
import usePodsResource from '../usePodsResource'
import useSpaces from '../useSpaces/index.ts'
import { getAggregateSeatsFromPod } from '../../util/pods/getAggregateSeatsFromPod'
import { getCapacityFromBuilding } from '../../util/buildings/index.ts'
import useLogger from '../useLogger/index.ts'

const uploadStatuses = {
    idle: 'idle', // Indicates that we're at the beginning of the state cycle
    uploading: 'uploading',
    uploaded: 'uploaded',
    saving: 'saving',
    saved: 'saved',
}

const errorTypes = {
    save: 'save',
    upload: 'upload',
}

const uploadDesksStatusReducer = (state, action) => {
    switch (action) {
        case 'UPLOAD_CSV':
            return {
                status: uploadStatuses.uploading,
            }
        case 'SAVE_PODS':
            return {
                status: uploadStatuses.saving,
            }
        case 'UPLOAD_SUCCESS':
            return {
                status: uploadStatuses.uploaded,
            }
        case 'UPLOAD_ERROR':
            return {
                status: uploadStatuses.idle,
                errorType: errorTypes.upload,
            }
        case 'SAVE_SUCCESS':
            return {
                status: uploadStatuses.saved,
            }
        case 'SAVE_ERROR':
            return {
                status: uploadStatuses.idle,
                errorType: errorTypes.save,
            }
        default:
            throw new Error(`${action} is not supported`)
    }
}
const useUploadDesks = () => {
    const [{ status, errorType }, dispatch] = React.useReducer(
        uploadDesksStatusReducer,
        {
            status: uploadStatuses.idle,
        }
    )
    const logger = useLogger()
    const { id: spaceId } = useSpaces()

    const {
        savePods: confirmSavePods,
        podsError: saveError,
        buildings,
        // we will need to retrieve pods: currentPods from here for the diffing algorithm
        updateBuildingCapacity,
    } = useContext(DesksContext)

    const {
        uploadPods,
        pods: uploadedPods,
        error: uploadError,
    } = usePodsResource({
        spaceId,
    })

    const uploadCSV = React.useCallback(
        async (csvFile) => {
            dispatch('UPLOAD_CSV')
            const response = await uploadPods({ csvFile })

            if (response) {
                dispatch('UPLOAD_SUCCESS')
            } else {
                dispatch('UPLOAD_ERROR')
            }
        },
        [uploadPods, dispatch]
    )

    // Perhaps a good place to move location.redirect logic
    const savePods = React.useCallback(
        async (csvFile) => {
            dispatch('SAVE_PODS')
            const pods = await confirmSavePods(csvFile)

            if (pods) {
                dispatch('SAVE_SUCCESS')
            } else {
                dispatch('SAVE_ERROR')
            }
        },
        [confirmSavePods, dispatch]
    )

    const diffedPods = React.useMemo(() => {
        // Diffing utility goes here, for MVP we will start without the diffing algorithm
        // Make sure to pull `pods: currentPods` from DesksContext to diff the two record sets
        if (uploadedPods) {
            return uploadedPods
        }
        return undefined
    }, [uploadedPods])

    const error = React.useMemo(() => {
        if (!errorType) {
            return undefined
        }

        if (errorType === errorTypes.save) {
            return { saveError }
        }

        if (errorType === errorTypes.upload) {
            return { uploadError }
        }
    }, [saveError, uploadError, errorType])

    React.useEffect(() => {
        // Perhaps a good place to add navigating confirmation behavior
        const handleOnBeforeUnload = (e) => {
            e.preventDefault()
            e.returnValue = ''
        }

        if (status === uploadStatuses.uploaded && diffedPods) {
            window.addEventListener('beforeunload', handleOnBeforeUnload)
        } else {
            window.removeEventListener('beforeunload', handleOnBeforeUnload)
        }

        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload)
        }
    }, [status, diffedPods])

    const checkInCapacityByBuildingId = React.useMemo(
        () =>
            new Map(
                buildings?.map((building) => [
                    building.id,
                    getCapacityFromBuilding(building),
                ])
            ),
        [buildings]
    )

    const podSeatLengthByBuildingId = React.useMemo(() => {
        if (!diffedPods) {
            return {}
        }

        return diffedPods.reduce((accum, pod) => {
            const buildingId = pod.buildingFloor.building.id
            const seatLength = getAggregateSeatsFromPod(pod)
            accum[buildingId] = seatLength + (accum[buildingId] || 0)
            return accum
        }, {})
    }, [diffedPods])

    const doDesksExceedCapacity = React.useMemo(() => {
        return Boolean(
            Object.entries(podSeatLengthByBuildingId).find(
                ([buildingId, numSeatsForPod]) => {
                    return (
                        checkInCapacityByBuildingId.get(buildingId) <
                        numSeatsForPod
                    )
                }
            )
        )
    }, [podSeatLengthByBuildingId, checkInCapacityByBuildingId])

    useEffect(() => {
        if (!error) {
            return
        }

        const { saveError, uploadError } = error

        if (saveError && !saveError.errorId) {
            logger.error('Could not save desks', saveError)
        }

        if (uploadError && !uploadError.errorId) {
            logger.error('Could not upload desks', uploadError)
        }
    }, [error, logger])

    return {
        status,
        savePods,
        uploadCSV,
        diffedPods,
        buildings,
        error,
        updateBuildingCapacity,
        doDesksExceedCapacity,
    }
}

export { uploadStatuses, errorTypes }
export default useUploadDesks
