import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import errorImg from '../../images/error.svg'
import { createSignOutRequest } from '../../state/user'
import { ButtonsContainer } from '../../styles/Layout'

const CrashPage = () => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    useEffect(() => {
        track('error_action', { action: 'View', label: 'view_crash_page' })
    }, [track])

    return (
        <Row className="justify-content-center pt-5">
            <Col md="5">
                <img className="mx-auto d-block my-5" src={errorImg} />
                <h4 className="text-center my-3">Something went wrong</h4>
                <p className="text-center">
                    We&apos;ve detected an issue and our team will be looking
                    into it soon.
                    <br />
                    Reload the page to get back to your Scoop dashboard.
                </p>
                <ButtonsContainer>
                    <Button
                        block
                        size="lg"
                        color="primary"
                        onClick={() => {
                            track('error_action', {
                                action: 'Click',
                                label: 'reload_page',
                            })
                            window.location.reload(true)
                        }}
                    >
                        Reload this page
                    </Button>

                    <Button
                        block
                        size="lg"
                        color="primary"
                        outline
                        onClick={() => {
                            track('error_action', {
                                action: 'Click',
                                label: 'sign_in_again',
                            })
                            dispatch(createSignOutRequest())
                        }}
                    >
                        Sign in again
                    </Button>
                </ButtonsContainer>
            </Col>
        </Row>
    )
}

export default CrashPage
