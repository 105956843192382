import { call, takeLatest, put } from 'redux-saga/effects'
import { createSessionTransaction } from '../sessionTransaction'
import { createUserOkta } from '../createUser'
import {
    SESSION_TRANSACTION_OKTA_CREATE_REQUEST,
    createSessionTransactionOktaCreateSuccess,
    createSessionTransactionOktaCreateFailure,
} from '../../state/auth'

/* Encapsulate the logic of creating a session for an Okta user.
 *
 * 1) attempt to create a session.
 * 1a) if the session attempt resulted in 401, assume that the Okta user has not
 * been created yet and attempt to create the user.
 * 1b) re-attempt to create a session.
 * 2) return the session.
 *
 */
export function* createOktaSession() {
    let sessionResponse
    let error
    try {
        // attempt to create a session for the Okta user.
        sessionResponse = yield call(createSessionTransaction, {})
    } catch (err) {
        error = err
    }
    // OKTA_SESSION_TOKEN_CREATION_FAILED means that the Okta token is valid (but could be expired), therefore try to create the user with the token
    if (error && error.errorId === 'OKTA_SESSION_TOKEN_CREATION_FAILED') {
        try {
            // attempt to create the Okta user.
            yield call(createUserOkta)
            // re-attempt to create a session (now that the user is created)
            sessionResponse = yield call(createSessionTransaction, {})
        } catch (err) {
            error = err
        }
    }
    if (sessionResponse) {
        yield put(
            createSessionTransactionOktaCreateSuccess({
                sessionTransactionId: sessionResponse.id,
            })
        )
        return sessionResponse
    } else {
        yield put(createSessionTransactionOktaCreateFailure({ error }))
    }
}

export default function* watchCreateOktaSession() {
    yield takeLatest(SESSION_TRANSACTION_OKTA_CREATE_REQUEST, createOktaSession)
}
