import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DropdownItem } from 'reactstrap'

import {
    isDateRangeOverlapped,
    getAdjustedPeriod,
    getDateRangeText,
    getRangeSizeFromPeriod,
} from '../../../../util/date'

const dateRangeOffsets = [-3, -2, -1, 0]

/**
 * Menu items for Last X Months.
 * The dropdown item is hidden if the date range cannot be selected.
 */
const LastXDropdownItems = ({
    period,
    earliestDate,
    latestDate,
    onDateRangeUpdate,
}) => {
    const now = moment()
    const rangeSize = getRangeSizeFromPeriod(period)
    const currentPeriod = {
        year: now.year(),
        week: rangeSize === 'week' ? now.week() : undefined,
        month: rangeSize === 'month' ? now.month() : undefined,
        quarter: rangeSize === 'quarter' ? now.quarter() : undefined,
    }

    return (
        <>
            {dateRangeOffsets.map((dateRangeOffset) => {
                const isValidDateRange = isDateRangeOverlapped({
                    earliestDate,
                    latestDate,
                    period: currentPeriod,
                    offset: dateRangeOffset,
                })

                if (!isValidDateRange) {
                    return null
                }

                const offsetPeriod = getAdjustedPeriod(
                    currentPeriod,
                    dateRangeOffset
                )
                const dateRangeText = getDateRangeText(offsetPeriod)

                return (
                    <DropdownItem
                        key={dateRangeOffset}
                        onClick={() => onDateRangeUpdate(offsetPeriod)}
                    >
                        {dateRangeText}
                    </DropdownItem>
                )
            })}
        </>
    )
}

LastXDropdownItems.propTypes = {
    period: PropTypes.object.isRequired,
    earliestDate: PropTypes.string,
    latestDate: PropTypes.string,
    onDateRangeUpdate: PropTypes.func,
}

export default LastXDropdownItems
