/*
 * Do not serialize these properties
 */
const denyListedProps = ['stack', 'fileName', 'lineNumber', 'columnNumber']

/*
 * Convert an Error into a POJO that can be serialized into Redux Store.
 */
export default (err) =>
    Object.getOwnPropertyNames(err)
        .filter((prop) => !denyListedProps.includes(prop))
        .reduce((acc, curr) => Object.assign(acc, { [curr]: err[curr] }), {})
