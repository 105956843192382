import { call, getContext, takeLatest, put } from 'redux-saga/effects'
import {
    FACTOR_VERIFY_REQUEST,
    createSessionTransactionAdd,
    createFactorAdd,
    createFactorVerifyFailure,
    createFactorVerifySuccess,
} from '../../state/auth'
import toast from '../../components/Toast/toast.tsx'

const verifyUri = ({ sessionTransactionId, factorId }) =>
    `/users/sessionTransactions/${sessionTransactionId}/authenticationFactors/${factorId}/verify`

export function* factorVerify({ payload, meta }) {
    const cosmosApi = yield getContext('cosmosApi')
    try {
        const { sessionTransactionId, factorId, stateToken, code } = payload
        const { parsedBody } = yield call(
            [cosmosApi, 'call'],
            verifyUri({ sessionTransactionId, factorId }),
            {
                method: 'POST',
                body: { stateToken, code },
            }
        )
        const { sessionTransaction } = parsedBody
        const { factor } = sessionTransaction
        const factorAddPayload = {
            factorId: factor.id,
            type: factor.type,
            phoneLast4: factor?.profile?.phoneLast4,
        }
        yield put(createFactorAdd(factorAddPayload))
        yield put(
            createSessionTransactionAdd({
                ...sessionTransaction,
                sessionTransactionId: sessionTransaction.id,
                factorId: factor.id,
            })
        )
        yield put(createFactorVerifySuccess({ factorId }))
        return parsedBody
    } catch (err) {
        toast.error(`We couldn't verify your code. Please try again.`)
        return yield put(createFactorVerifyFailure({ error: err }))
    }
}

export default function* watchFactorVerify() {
    yield takeLatest(FACTOR_VERIFY_REQUEST, factorVerify)
}
