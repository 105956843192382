import styled from 'styled-components'
import { Button, DropdownMenu } from 'reactstrap'

import DropdownToggle from '../../../components/DropdownToggle'

export const PrevButton = styled(Button)`
    border-right-color: #d4d6d6;
`
export const NextButton = styled(Button)`
    border-left-color: #d4d6d6;
`

export const DateDropdownToggle = styled(DropdownToggle)`
    border-left: none;
    border-right: none;
    min-width: 175px;
    font-variant-numeric: tabular-nums;
`

export const DateDropdownMenu = styled(DropdownMenu)`
    min-width: 175px;
`
