import React from 'react'
import StatusBadge from '../../../../components/StatusBadge'

import colors from '../../../../styles/colors'
import { IndicationStatus } from '../../../../types'

type Props = {
    indication?: {
        status: IndicationStatus
        id?: string
        confirmationCode?: string
    }
}

const {
    workingFromHome,
    workingFromOffice,
    outOfOffice,
    noStatus,
} = IndicationStatus

const StatusCell = ({ indication }: Props): JSX.Element | null => {
    if (!indication || indication.status === noStatus) {
        return (
            <StatusBadge
                data-test="no-status"
                label="No status"
                color={colors.yellow[0]}
            />
        )
    }

    const { status } = indication

    if (status === workingFromOffice) {
        return (
            <StatusBadge
                data-test="working-from-office"
                label="Going into work"
                color={colors.green[100]}
            />
        )
    }
    if (status === workingFromHome) {
        return (
            <StatusBadge
                data-test="working-from-home"
                label="Working remotely"
                color={colors.blue[100]}
            />
        )
    }

    if (status === outOfOffice) {
        return (
            <StatusBadge
                data-test="out-of-office"
                label="Out of office"
                color={colors['secondary-alert']}
            />
        )
    }

    return null
}

export default StatusCell
