import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Table as TableStyled, Button } from 'reactstrap'
import { Link, useNavigate } from '@reach/router'

import { formatHeader } from '../util'
import GhostLoader from '../../../components/GhostLoader'
import CouldNotLoadData from '../../../components/Error/CouldNotLoadData'
import { Content, Header } from '../../../components/PageLayout'

const goBackPath = '../../'
const loadingRows = [...Array(10).keys()]

/**
 *
 * @param {number} expectedNumColumns - expected number of columns.
 *                                      This is used for displaying the expected size of the loading table.
 */
const Example = ({
    title,
    description,
    data,
    fetchData,
    isLoading,
    expectedNumColumns,
}) => {
    const navigate = useNavigate()
    const hasError = !data && !isLoading
    const isSuccessful = Boolean(data) && !isLoading

    const loadingColumns = useMemo(
        () => [...Array(expectedNumColumns).keys()],
        [expectedNumColumns]
    )
    const columnHeaders = useMemo(() => {
        if (!data) {
            return []
        }

        return Object.keys(data[0]).map((header) => formatHeader(header))
    }, [data])

    return (
        <Container size="xl">
            <Header title={title} description={description} />

            <Content hasBackground={false} hasBorder={false}>
                {isLoading && (
                    <Row>
                        <TableStyled
                            bordered
                            className="bg-white"
                            test-attr="loader"
                        >
                            <thead>
                                <tr>
                                    {loadingColumns.map((header) => (
                                        <th
                                            key={header}
                                            className="text-center border-bottom-dark p-3"
                                        >
                                            <GhostLoader height={15} />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {loadingRows.map((row, index) => (
                                    <tr key={index}>
                                        {loadingColumns.map((index) => (
                                            <td key={index} className="p-3">
                                                <GhostLoader height={15} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </TableStyled>
                    </Row>
                )}

                {isSuccessful && (
                    <Row>
                        <TableStyled bordered className="bg-white">
                            <thead>
                                <tr>
                                    {columnHeaders.map((header) => (
                                        <th
                                            key={header}
                                            className="text-center border-bottom-dark"
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((cell, idx) => (
                                            <td key={idx}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </TableStyled>
                    </Row>
                )}

                {hasError && (
                    <div className="bg-white pb-5 border">
                        <CouldNotLoadData
                            className="pb-5"
                            onTryAgainClick={() => fetchData()}
                            isGoBackVisible={true}
                            onGoBackClick={() => navigate(goBackPath)}
                        />
                    </div>
                )}

                {!hasError && (
                    <Row className="my-5 justify-content-center">
                        <Link
                            to={goBackPath}
                            className="w-40 text-decoration-none"
                        >
                            <Button block size="lg" color="primary" outline>
                                Go back
                            </Button>
                        </Link>
                    </Row>
                )}
            </Content>
        </Container>
    )
}

Example.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    fetchData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    expectedNumColumns: PropTypes.number.isRequired,
}

export default Example
