import styled from 'styled-components'

import { HEIGHT } from '..'

const Placeholder = styled.div.attrs({
    className: 'border rounded p-2',
})`
    height: ${HEIGHT}px;
    width: 100%;
`
export default Placeholder
