import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import {
    getBuildingsFromNeighborhoods,
    PodWithNeighborhood,
} from '../../../../util/pods/getBuildingsFromPods'
import { Building } from '../../../../types'
import BuildingList from '../../../../components/Desks/BuildingList'
import Button from '../../../../components/Button'
import usePodsResource from '../../../../hooks/usePodsResource/index.js'
import toast from '../../../../components/Toast/toast'

const FloatingContainer = styled.div`
    bottom: 2vh;
    position: sticky;
    text-align: center;
`

type Props = {
    spaceId: string
    pods: PodWithNeighborhood[]
    buildings: Building[]
    updateBuildingCapacity: () => void
    file?: File
    onSaveSuccess: () => void
}

const PreviewDeskUpload = ({
    spaceId,
    pods,
    buildings,
    updateBuildingCapacity,
    file,
    onSaveSuccess,
}: Props) => {
    const { isLoading, uploadPods } = usePodsResource({
        spaceId,
        shouldGetOnMount: false,
    })

    const handleConfirmSave = async () => {
        const response = await uploadPods({ csvFile: file, force: true })
        if (response) {
            onSaveSuccess()
        } else {
            toast.error('CSV failed to save. Please try again.')
        }
    }

    const buildingsFromPods = useMemo(() => {
        if (pods) {
            return getBuildingsFromNeighborhoods(pods, buildings)
        }

        return []
    }, [pods, buildings])

    return (
        <>
            <h4 data-test="desks-preview-title">Desk assignment preview</h4>
            <Row className="pb-3">
                <Col>
                    {buildingsFromPods && (
                        <BuildingList
                            onCapacityUpdated={updateBuildingCapacity}
                            buildings={buildingsFromPods}
                            isUploadMode
                        />
                    )}
                </Col>
            </Row>
            <FloatingContainer>
                <Button
                    data-test="save-buton"
                    label="Save desk assignments"
                    size="md"
                    loadingLabel="Saving..."
                    className="shadow ml-1"
                    isLoading={isLoading}
                    block={false}
                    onClick={handleConfirmSave}
                >
                    Save desk assignments
                </Button>
            </FloatingContainer>
        </>
    )
}

export default PreviewDeskUpload
