import { call, getContext, takeLatest, put } from 'redux-saga/effects'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import {
    FACTOR_ENROLL_REQUEST,
    createFactorAdd,
    createSessionTransactionAdd,
    createFactorEnrollFailure,
    createFactorEnrollSuccess,
} from '../../state/auth'
import toast from '../../components/Toast/toast.tsx'

const enrollUri = ({ sessionTransactionId }) =>
    `/users/sessionTransactions/${sessionTransactionId}/authenticationFactors/enroll`

const phoneUtil = PhoneNumberUtil.getInstance()

export const enrollData = ({ stateToken, phoneNumber }) => {
    const number = phoneUtil.parse(phoneNumber, 'US')
    const formattedPhoneNumber = phoneUtil.format(
        number,
        PhoneNumberFormat.E164
    )

    return {
        stateToken,
        type: 'sms',
        profile: {
            phone: formattedPhoneNumber,
        },
    }
}

export function* factorEnroll({ payload }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { phoneNumber, stateToken, sessionTransactionId } = payload
    try {
        const { parsedBody } = yield call(
            [cosmosApi, 'call'],
            enrollUri({ sessionTransactionId }),
            {
                method: 'POST',
                body: enrollData({ stateToken, phoneNumber }),
            }
        )
        const { sessionTransaction } = parsedBody
        const { factor } = sessionTransaction
        const factorAddPayload = {
            factorId: factor.id,
            type: factor.type,
            phoneLast4: factor?.profile?.phoneLast4,
        }
        yield put(
            createSessionTransactionAdd({
                factorId: factor.id,
                sessionTransactionId: sessionTransaction.id,
                ...sessionTransaction,
            })
        )
        yield put(createFactorAdd(factorAddPayload))
        yield put(createFactorEnrollSuccess({ factorId: factor.id }))
        return parsedBody
    } catch (err) {
        toast.error(`We couldn't send your code. Please try again.`)
        yield put(createFactorEnrollFailure({ error: err }))
        return err
    }
}

export default function* watchFactorEnroll() {
    yield takeLatest(FACTOR_ENROLL_REQUEST, factorEnroll)
}
