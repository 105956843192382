import React, { useMemo } from 'react'
import { ListGroupItem } from 'reactstrap'

import { FloorWithNeighborhoods } from '../../../types'
import Neighborhood from '../Neighborhood'
import CollapsingRow from '../../CollapsingRow'
import FloorPlan from '../FloorPlan'

type Props = {
    floor: FloorWithNeighborhoods
    shouldShowFloorPlan?: boolean
}

const BuildingFloorWithNeighborhoods = ({
    floor,
    shouldShowFloorPlan,
}: Props) => {
    const { neighborhoods, floorNumber } = floor
    const totalDesks = useMemo(
        () =>
            neighborhoods
                .map((neighborhood) => neighborhood.seats.length)
                .reduce((total, count) => total + count, 0),
        [neighborhoods]
    )

    const counts = [
        {
            label: 'neighborhood',
            value: neighborhoods.length,
        },
        {
            label: 'desk',
            value: totalDesks,
        },
    ]

    return (
        <ListGroupItem data-test="building-floor">
            <CollapsingRow
                header={`Floor ${floorNumber}`}
                counts={counts}
                secondaryButton={
                    shouldShowFloorPlan && <FloorPlan floor={floor} />
                }
            >
                {neighborhoods.map((neigborhood) => (
                    <Neighborhood
                        key={neigborhood.id}
                        neighborhood={neigborhood}
                    />
                ))}
            </CollapsingRow>
        </ListGroupItem>
    )
}

export default BuildingFloorWithNeighborhoods
