import { Redirect, Router } from '@reach/router'
import React from 'react'

import ForgotPasswordInputEmail from 'pages/ForgotPasswordInputEmail'
import ForgotPasswordInputNewPassword from 'pages/ForgotPasswordInputNewPassword'
import LoggedOut from 'pages/LoggedOut'
import MFAActivate from 'pages/MFAActivate'
import MFAEnroll from 'pages/MFAEnroll'
import NotFound from 'pages/NotFound'
import OktaSignIn from 'pages/OktaSignIn'
import Register from 'pages/Register'
import SignIn from 'pages/SignIn'
import Spaces from 'pages/Spaces'
import SpacesRouter from 'pages/Spaces/routes.tsx'
import WorkplaceCheckIn from 'pages/WorkplaceCheckIn/index.tsx'

const Routes = () => {
    return (
        <Router>
            {/* Signed-out routes */}
            <LoggedOut path="/">
                <SignIn path="sign-in" />
                <OktaSignIn path="okta" />
                <Register path="register" />
                <ForgotPasswordInputNewPassword path="forgot-password" />
                <ForgotPasswordInputEmail path="forgot-password/email" />
                <MFAEnroll path="mfa-enroll" />
                <MFAActivate path="mfa-activate" />
                <Redirect from="/" to="spaces" noThrow />
                <NotFound path="*" />
            </LoggedOut>

            {/* A special route that would fit in <Spaces />, but doesn't need layout */}
            <Spaces
                path="/spaces/:spaceAlias/workplace/check-ins/:checkInId"
                withLayout={false}
            >
                <WorkplaceCheckIn default />
            </Spaces>

            {/* Signed-in routes */}
            <Spaces path="/spaces">
                <SpacesRouter default />
            </Spaces>
        </Router>
    )
}

export default Routes
