import React from 'react'
import { string, bool } from 'prop-types'
import { Link } from '@reach/router'
import classnames from 'classnames'

const LinkButton = ({
    to,
    isBlock = false,
    isBordered = false,
    className,
    ...rest
}) => {
    const cls = classnames(
        'btn text-decoration-none',
        {
            'btn-block': isBlock,
            'btn-outline-primary': isBordered,
            'btn-primary': !isBordered,
        },
        className
    )
    return <Link className={cls} to={to} {...rest} />
}

LinkButton.propTypes = {
    className: string,
    isBordered: bool,
    isBlock: bool,
    to: string.isRequired,
}

export default React.memo(LinkButton)
