import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Container } from 'reactstrap'

import srcLocations from '../../images/locations.svg'
import { Header } from '../PageLayout'

const NoCarpoolersActive = ({ spaceAlias }) => (
    <Container size="xl">
        <Header title="Active commuters" />
        <Row className="justify-content-center pt-5">
            <Col md="5">
                <img className="mx-auto my-4 d-block " src={srcLocations} />
                <h4 className="text-center mb-4">No active commuters yet</h4>
            </Col>
        </Row>
    </Container>
)

NoCarpoolersActive.propTypes = {
    spaceAlias: PropTypes.string,
}

export default NoCarpoolersActive
