import React, { useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFetch } from 'use-http'

import toast from '../../components/Toast/toast.tsx'

const fiveMinutesInMilliseconds = 5 * 60 * 1000

/**
 * This HOC is intended to be used for Commute components like <Metric /> and <Chart />.
 * It handles data fetching, error handling, and caching before the UI component is rendered.
 *
 * @param {Object} props
 * @property {bool} props.isFetchCacheDisabled - If true, fetch doesn't cache results. This is useful for testing.
 */
const withMetricOrChartData = (MetricOrChartComponent) => {
    const MetricOrChartData = ({
        urlPath,
        onChange,
        isUnavailable,
        isFetchCacheDisabled,
        ...otherProps
    }) => {
        const [urlPathToData, setUrlPathToData] = useState(new Map())
        const fetchRequest = useRef()
        const {
            loading,
            error: networkError,
            data,
            get: getData,
            response,
        } = useFetch(urlPath, {
            cachePolicy: isFetchCacheDisabled ? 'no-cache' : 'cache-first',
            cacheLife: fiveMinutesInMilliseconds,
        })

        // Store a ref to the current fetch request, so we only check the current request status below
        fetchRequest.current = response

        const fetchMetricData = useCallback(async () => {
            if (isUnavailable) {
                return
            }

            const startTime = Date.now()
            const data = await getData()

            const loadingDuration = Date.now() - startTime

            if (fetchRequest.current.ok) {
                // Store the data into a url-path-to-data map so that
                // it prevents race condition of displaying wrong data
                // for the given url path.
                setUrlPathToData((prevUrlPathToData) => {
                    const updatedUrlPathToData = new Map(prevUrlPathToData)
                    updatedUrlPathToData.set(urlPath, data)

                    return updatedUrlPathToData
                })

                if (onChange) {
                    onChange({ data, loadingDuration })
                }
            } else {
                toast.debouncedError('Failed to load data.')

                if (onChange) {
                    onChange({
                        hasError: true,
                        errorId: data?.error?.errorId,
                        loadingDuration,
                    })
                }
            }
        }, [getData, isUnavailable, onChange, urlPath])

        useEffect(() => {
            fetchMetricData()
        }, [fetchMetricData])

        const cachedData = urlPathToData.get(urlPath)
        const error = data?.error || networkError

        return (
            <MetricOrChartComponent
                data={cachedData}
                isLoading={loading}
                error={error}
                isUnavailable={isUnavailable}
                refetchData={fetchMetricData}
                {...otherProps}
            />
        )
    }

    MetricOrChartData.propTypes = {
        urlPath: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        isUnavailable: PropTypes.bool,
        isFetchCacheDisabled: PropTypes.bool,
    }

    return MetricOrChartData
}

export default withMetricOrChartData
