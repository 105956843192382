import styled from 'styled-components'

export const ReportsTableContainer = styled.div`
    .list-group-item {
        .downloadIconWrapper {
            display: none;
        }

        &:hover {
            background-color: #d9dcdc;

            & .downloadIconWrapper {
                display: inline-block;
            }
        }

        &:active {
            background-color: #7e8583;
            color: #f7f7f7;

            & .downloadIconWrapper {
                display: inline-block;
            }
        }
    }
`
