import { useMemo } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { CosmosError, ResponseError } from '../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

export type useGenericResourceType<T> = {
    loading: boolean
    error?: Error | CosmosError
    data?: T
    get: () => Promise<T>
}

export const getDefaultuseGenericResource = <
    T
>(): useGenericResourceType<T> => ({
    loading: false,
    error: undefined,
    data: undefined,
    get: () => Promise.reject(new Error('No get function provided')),
})

/**
 * Manages fetching data for a given API endpoint.
 */
function useGenericResource<T>(
    path: string,
    dependencies?: unknown[]
): useGenericResourceType<T> {
    const { data, loading, error: networkError, get } = useFetch<
        T | ResponseError
    >(path, fetchOptions, dependencies)

    const error = useMemo(() => {
        if (data && 'error' in data && data.error) {
            return data.error
        }
        if (networkError) {
            return networkError
        }
        return undefined
    }, [networkError, data])

    const resolvedData = useMemo<T | undefined>(() => {
        if (data && !('error' in data)) {
            return data
        }
        return undefined
    }, [data])

    return {
        loading,
        error,
        data: resolvedData,
        get,
    }
}

export default useGenericResource
