export const selectSessionTransaction = (state) =>
    state?.auth?.sessionTransaction
export const selectSessionTransactionCreateRequest = (state) =>
    state?.auth?.sessionTransactionCreateRequest
export const selectSessionTransactionCheckRequest = (state) =>
    state?.auth?.sessionTransactionCheckRequest
export const selectFactor = (state) => state?.auth?.factor
export const selectFactorEnrollRequest = (state) =>
    state?.auth?.factorEnrollRequest
export const selectFactorActivateRequest = (state) =>
    state?.auth?.factorActivateRequest
export const selectFactorResendRequest = (state) =>
    state?.auth?.factorResendRequest
export const selectFactorVerifyRequest = (state) =>
    state?.auth?.factorVerifyRequest
