import { useState, useCallback } from 'react'
import parseLinkHeader from 'parse-link-header'
import useFetch, { CachePolicies } from 'use-http'
import qs from 'qs'

import usePagination, { UsePagination } from '../usePagination'

export const defaultPageSize = 20

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

export type PaginatedData<T> = T[] | undefined
export type Pagination = UsePagination & {
    changePage: (page: number) => void
}

type UseServerPagination<T> = {
    fetchData: (queryString: string) => void
    loading: boolean
    error: Error
    paginatedData: PaginatedData<T>
    totalCount: number
    resetPagination: () => void
    pagination: Pagination
}

type Props = {
    path?: string
    pageSize?: number
}

/**
 * # useServerSidePagination
 *
 * This hook handles server pagination, storing and returning the correct data in the given page.
 *
 * ## Pre-conditions
 *   1. The backend endpoint returns a link as a header for the next page.
 *      [Example endpoint](https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__workattendanceindications_get)
 *   2. response.data returns an array of items to be paginated.
 *
 * ## How it works
 *   1. The consumer of this hook fetches data when loading.
 *   2. The endpoint returns a query token as a link header. If you pass this in the endpoint, it returns the data of the page.
 *   2. When the data is fetched (by firstly accessing the next page), it's cached in allData state.
 *   3. This hook returns the correct data in the given page.
 *
 *  @param {String} - path - the URL path of the endpoint.
 *
 *  @returns {Object}
 *    From useFetch
 *      * fetchData - same as `get`
 *      * loading - same as `loading`
 *      * error - data error or fetch error. Undefined if there's no error.
 *
 *    Data
 *      * paginatedData - the data in the given page.
 *      * totalCount - total number of data in the **server**.
 *      * resetPagination - a function for resetting paginating, clearing the data,
 *                          and then fetch the data.
 *
 *    From usePagination - These are mainly used for <Paginator /> UI.
 *      * showPrevious
 *      * showNext
 *      * pagesToShow
 *      * currentPage
 *      * changePage
 */
export default function useServerPagination<T>({
    path,
    pageSize = defaultPageSize,
}: Props = {}): UseServerPagination<T> {
    const [allData, setAllData] = useState<PaginatedData<T>>(undefined)

    const { get, loading, data, error: fetchError, response } = useFetch(
        path,
        fetchOptions
    )

    const getHeader = (name) => response?.headers?.get(name)
    const totalCount = parseInt(getHeader('X-TOTAL-COUNT') || '0')
    const queryToken = parseLinkHeader(getHeader('Link'))?.next?.queryToken
    const error = data?.error || fetchError

    // Show the next empty page if there's query token
    const dataSize = allData ? allData.length + (queryToken ? 1 : 0) : 0

    const pagination = usePagination({
        dataSize,
        pageSize,
    })
    const { fromResult, toResult, changePage } = pagination
    const paginatedData = allData?.slice(fromResult - 1, toResult)

    const resetPagination = useCallback(() => {
        setAllData(undefined)
        changePage(1)
    }, [changePage])

    const fetchAndSetNewData = useCallback(
        (queryString) => {
            async function asyncGet() {
                const res = await get(queryString)

                if (Array.isArray(res)) {
                    setAllData((currentData) =>
                        currentData ? [...currentData, ...res] : [...res]
                    )
                }
            }

            asyncGet()
        },
        [get]
    )

    const handleChangePage = useCallback(
        (page) => {
            const isUnvisitedLastPage =
                page > 1 && page === pagination.last && Boolean(queryToken)

            // If an unvisited next page is accessed,
            // fetch the data with the next page query token.
            if (isUnvisitedLastPage) {
                const queryString = qs.stringify(
                    { queryToken },
                    { addQueryPrefix: true }
                )
                fetchAndSetNewData(queryString)
            }

            changePage(page)
        },
        [pagination.last, changePage, fetchAndSetNewData, queryToken]
    )

    return {
        // From useFetch
        fetchData: fetchAndSetNewData,
        loading,
        error,

        paginatedData,
        totalCount,
        resetPagination,

        // From usePagination
        pagination: {
            ...pagination,
            changePage: handleChangePage,
        },
    }
}
