import React, { useMemo } from 'react'

import CollapsingRow from '../../CollapsingRow'
import SeatTable from '../SeatTable'

import { NeighbhoodWithSeats, DeskAssignmentType } from '../../../types'

type Props = {
    neighborhood: NeighbhoodWithSeats
}

const Neighborhood = ({ neighborhood: { seats, name } }: Props) => {
    const deskCounts = useMemo((): {
        label: string
        pluralLabel: string
        value: number
    }[] => {
        const deskBreakdown = seats.reduce(
            (breakdown, { assignmentType }) => ({
                ...breakdown,
                [assignmentType]: breakdown[assignmentType] + 1,
            }),
            {
                department: 0,
                individual: 0,
                open: 0,
            }
        )

        return Object.keys(deskBreakdown).map(
            (assignmentType: DeskAssignmentType) => ({
                label: assignmentType,
                pluralLabel: assignmentType,
                value: deskBreakdown[assignmentType],
            })
        )
    }, [seats])

    const totalDesks = {
        label: 'desk',
        pluralLabel: 'desks',
        value: seats.length,
    }

    return (
        <CollapsingRow counts={[totalDesks, ...deskCounts]} header={name}>
            <SeatTable seats={seats} />
        </CollapsingRow>
    )
}

export default Neighborhood
