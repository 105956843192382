import { Link, RouteComponentProps } from '@reach/router'
import React, { useEffect, useRef } from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

import ErrorMessage from './ErrorMessage'
import NextSteps from './NextSteps'
import Button from '../../../components/Button'

type Props = {
    droppedFile?: File
    isInvalidCSV: boolean
    isSubmitting: boolean
    onComplete: (file: File) => void
} & RouteComponentProps

export const ChooseCSV = ({
    onComplete,
    isInvalidCSV,
    isSubmitting,
    droppedFile,
}: Props): JSX.Element => {
    const fileInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (droppedFile) {
            onComplete(droppedFile)
        }
    }, [droppedFile, onComplete])

    function handleUpload(e) {
        const payload = e.target.files[0]

        onComplete(payload)

        // Clear out file input value after use in case the user uploads a file with the same name.
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        e.target.value = null
    }

    function handleClick() {
        // fileInput is hidden so we click it via the Button
        fileInput?.current?.click()
    }

    return (
        <>
            <Row className="pb-3" data-test="roster-upload-step-1">
                <Col xs="7">
                    <Row>
                        <Col className="my-3 mx-3 pl-0 border-bottom">
                            <h5>Step 1: Upload CSV</h5>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {isInvalidCSV ? (
                                <ErrorMessage />
                            ) : (
                                <>
                                    <p>
                                        Your CSV should have at least these 4
                                        columns:
                                    </p>
                                    <ol>
                                        <li>First name</li>
                                        <li>Last name</li>
                                        <li>Email</li>
                                        <li>Employee ID</li>
                                    </ol>
                                    <p>
                                        You can also include the following
                                        optional columns:
                                    </p>
                                    <ul>
                                        <li>Department</li>
                                        <li>
                                            Manager Email (must belong to an
                                            employee on the roster)
                                        </li>
                                    </ul>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Link
                                to="../../"
                                className="nav-link text-primary text-decoration-none text-center"
                            >
                                Cancel
                            </Link>
                            <Button
                                data-test="roster-upload-button"
                                block={false}
                                size="md"
                                onClick={handleClick}
                                label="Choose CSV"
                                loadingLabel="Uploading CSV..."
                                isLoading={isSubmitting}
                            />
                            <input
                                data-test="roster-upload-input"
                                style={{ display: 'none' }}
                                ref={fileInput}
                                type="file"
                                accept=".csv"
                                onChange={handleUpload}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs="5">
                    <NextSteps />
                </Col>
            </Row>
        </>
    )
}

// TODO: Make this withDragAndDrop(ChooseCSV) to have drag-and-drop functionality. With this, layout doesn't work.
export default ChooseCSV
