import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal as ReactstrapModal } from 'reactstrap'

import { getModalComponentByType } from './helpers'
import { createCloseModal } from '../../state/modal/actions'
import { selectModal } from '../../state/modal/selectors'

const ReduxModal = (): JSX.Element => {
    const dispatch = useDispatch()
    const boundCreateCloseModal = useCallback(
        () => dispatch(createCloseModal()),
        [dispatch]
    )
    const modal = useSelector(selectModal)
    const ModalComponent = getModalComponentByType(modal.name)

    const isOpen = Boolean(modal.name)

    // A "static" backdrop will not allow the user
    // to close the modal by clicking on the overlay
    const backdrop = modal.data?.isStaticModal ? 'static' : undefined

    return (
        <ReactstrapModal
            centered
            backdrop={backdrop}
            isOpen={isOpen}
            size={modal.data?.size}
            toggle={boundCreateCloseModal}
            onClosed={boundCreateCloseModal}
        >
            <ModalComponent
                {...modal.data}
                onClose={(...args: any) => {
                    boundCreateCloseModal()
                    modal.data?.onClose?.(...args)
                }}
            />
        </ReactstrapModal>
    )
}

export default ReduxModal
