import { Link } from '@reach/router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { Button, Form } from 'reactstrap'

import asset78 from './asset-78-2-x@3x.png'
import assetIlo from './ilo@3x.png'
import { TokenImg } from './styled'
import { addApostropheS } from '../../util/grammar'
import useAnalytics from '../../hooks/useAnalytics'

const RegistrationPrompt = ({
    loading = true,
    expiresAt,
    inviteeName,
    spaceName,
    inviterName,
    onSubmit = () => {},
}) => {
    const { track } = useAnalytics()
    const handleFormSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }
    const isExpired = useMemo(
        () => moment(expiresAt).isSameOrBefore(new Date()),
        [expiresAt]
    )

    useEffect(() => {
        if (isExpired) {
            track('account_creation_action', {
                action: 'View',
                label: 'invite_expired',
            })
        }
    }, [isExpired, track])

    useEffect(() => {
        if (!loading && !isExpired) {
            track('account_creation_action', {
                action: 'View',
                label: 'setup_your_account',
            })
        }
    }, [loading, isExpired, track])

    if (loading) {
        return (
            <>
                <div className="text-center">
                    <div
                        className="m-3 spinner-border text-secondary"
                        role="status"
                    >
                        <span className="sr-only">Loading content...</span>
                    </div>
                    <h4 className="text-dark">Loading content...</h4>
                </div>
            </>
        )
    }

    if (isExpired) {
        // token is valid, but expired sometime in the past.
        return (
            <>
                <TokenImg className="mx-auto d-block" src={assetIlo} />
                <br />
                <h3>Your invitation has expired</h3>
                <p>Hi {inviteeName},</p>
                <p>
                    Your invitation has expired. Please contact your Scoop
                    Customer Success Manager to request a new invite.
                </p>
                <br />
                <Link
                    to="/sign-in"
                    className="btn-block text-center"
                    onClick={() =>
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'already_have_account',
                        })
                    }
                >
                    Already have an account? Sign in.
                </Link>
            </>
        )
    }
    return (
        <Form onSubmit={handleFormSubmit}>
            <TokenImg className="mx-auto d-block" src={asset78} />
            <br />
            <h3>Set up your account</h3>
            <p>Hi {inviteeName},</p>
            <p>
                {addApostropheS(spaceName)} Scoop dashboard is ready for you to
                join.
            </p>
            <p>
                Create a password and enter your phone number to set up your
                account.
            </p>
            <br />
            <Button
                block
                color="primary"
                type="submit"
                onClick={() =>
                    track('account_creation_action', {
                        action: 'Click',
                        label: 'get_started',
                    })
                }
            >
                Get started
            </Button>
            <br />
            <Link
                className="btn-block text-center"
                to="/sign-in"
                onClick={() =>
                    track('account_creation_action', {
                        action: 'Click',
                        label: 'already_have_account',
                    })
                }
            >
                Already have an account?
            </Link>
        </Form>
    )
}

RegistrationPrompt.propTypes = {
    loading: PropTypes.bool,
    expiresAt: PropTypes.string,
    inviteeName: PropTypes.string,
    inviterName: PropTypes.string,
    spaceName: PropTypes.string,
    onSubmit: PropTypes.func,
}

export default RegistrationPrompt
