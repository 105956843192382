import {
    DeskAssignmentType,
    BuildingByFloorAndNeighbhorhood,
    FloorWithNeighborhoods,
    NeighbhoodWithSeats,
    Floor,
    BasePodResource,
    EligibleRosterRecord,
    EligibleDepartment,
    Building,
} from '../../types'

import { sortBuildingsFloorsNeighborhoodsAndDesks } from '../buildings'

export type Neighborhood = {
    id: string
    name: string
    buildingFloor: Floor & {
        building: {
            id: string
            name: string
        }
    }
}

export type PodWithNeighborhood = BasePodResource & {
    neighborhood: Neighborhood
    eligibleRosterRecord: EligibleRosterRecord
    eligibleDepartments: EligibleDepartment[]
}

export function getSeatAssignmentType(pod: {
    eligibleRosterRecord?: EligibleRosterRecord
    eligibleDepartments?: EligibleDepartment[]
}): DeskAssignmentType {
    if (pod.eligibleRosterRecord) {
        return DeskAssignmentType.individual
    }
    if (pod.eligibleDepartments) {
        return DeskAssignmentType.department
    }

    return DeskAssignmentType.open
}

export function getSeatAssignments(pod: {
    eligibleRosterRecord?: EligibleRosterRecord
    eligibleDepartments?: EligibleDepartment[]
}): string[] {
    if (pod.eligibleRosterRecord) {
        return [pod.eligibleRosterRecord.email]
    }
    if (pod.eligibleDepartments) {
        return pod.eligibleDepartments.map((department) => department.label)
    }

    return []
}

const getNewBuildingFloorAndNeighborhoodFromPod = (
    pod: PodWithNeighborhood,
    buildings: Building[]
): {
    newBuilding: BuildingByFloorAndNeighbhorhood
    newFloor: FloorWithNeighborhoods
    newNeighborhood: NeighbhoodWithSeats
} => {
    const {
        neighborhood: { id, name, buildingFloor },
    } = pod

    const newNeighborhood = {
        id,
        name,
        seats: pod.seatLabels.map((seatLabel) => ({
            label: seatLabel,
            assignmentType: getSeatAssignmentType(pod),
            assignments: getSeatAssignments(pod),
        })),
    }

    const newFloor = { ...buildingFloor, neighborhoods: [newNeighborhood] }

    const building = buildings.find(
        (building) => building.id === buildingFloor.building.id
    )
    const employeeExperienceConfigurations =
        building && building.employeeExperienceConfigurations
            ? [...building.employeeExperienceConfigurations]
            : []

    const newBuilding = {
        ...buildingFloor.building,
        floors: [newFloor],
        employeeExperienceConfigurations,
    }

    return {
        newBuilding,
        newFloor,
        newNeighborhood,
    }
}

/**
 * Transform a series of pods into buildings with floors, neighborhoods, and seats
 */
export const getBuildingsFromNeighborhoods = (
    allPods: PodWithNeighborhood[],
    allBuildings: Building[]
): BuildingByFloorAndNeighbhorhood[] => {
    const buildingsByFloorAndNeighborhood = allPods.reduce(
        (buildings: BuildingByFloorAndNeighbhorhood[], pod) => {
            const {
                neighborhood: { id: neighborhoodId, buildingFloor },
            } = pod

            const {
                newBuilding,
                newFloor,
                newNeighborhood,
            } = getNewBuildingFloorAndNeighborhoodFromPod(pod, allBuildings)

            // Find exsiting building in list if exists
            const existingBuilding = buildings.find(
                (building) => building.id === buildingFloor.building.id
            )

            // Add the new building if it doesn't exist in the list
            if (!existingBuilding) {
                buildings.push(newBuilding)
                return buildings
            }

            // Find existing floor in the building if exists
            const existingFloor = existingBuilding.floors.find(
                (floor) => floor.id === buildingFloor.id
            )

            // Add the new floor if it doesn't exist in the building
            if (!existingFloor) {
                existingBuilding.floors.push(newFloor)
                return buildings
            }

            // find existing neighborhood on this floor if it exists
            const existingNeighborhood = existingFloor.neighborhoods.find(
                (neighborhood) => neighborhood.id === neighborhoodId
            )

            // Add the new neighborhood if it doesn't exist on the floor
            if (!existingNeighborhood) {
                existingFloor.neighborhoods.push(newNeighborhood)
                return buildings
            }

            // if the neighborhood already exists, simply add the new seats to the neighborhood
            existingNeighborhood.seats = [
                ...existingNeighborhood.seats,
                ...newNeighborhood.seats,
            ]
            return buildings
        },
        []
    )

    return sortBuildingsFloorsNeighborhoodsAndDesks(
        buildingsByFloorAndNeighborhood
    )
}
