import React from 'react'
import { Row, Col, Button } from 'reactstrap'

import srcError from '../../images/error.svg'

type Props = {
    'data-test'?: string
    searchAgain?: () => void
    errorText: string
}

const ErrorTryAgain = ({
    'data-test': dataTest,
    searchAgain = () => window.location.reload(),
    errorText,
}: Props): JSX.Element => {
    const colLayout = { size: 6, offset: 3 }

    return (
        <Row data-test={dataTest}>
            <Col
                md={colLayout}
                sm={colLayout}
                className="d-flex flex-column justify-content-center"
            >
                <img className="mx-auto d-block mt-5" src={srcError} />
                <h4 className="my-5 mx-auto">{errorText}</h4>
                <Button
                    className="mx-5 mb-5"
                    color="primary"
                    onClick={searchAgain}
                >
                    Try Again
                </Button>
            </Col>
        </Row>
    )
}

export default ErrorTryAgain
