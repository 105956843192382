import { saveAs } from 'file-saver'
import { CachePolicies, useFetch } from 'use-http'
import qs from 'qs'

import { format } from 'date-fns'
import toast from '../../components/Toast/toast'
import useAnalytics from '../useAnalytics'
import useSpaces from '../useSpaces/index'

const fetchOptions = {
    headers: {
        Accept: 'text/csv',
    },
    cachePolicy: CachePolicies.NO_CACHE,
}

type DownloadReportProps = {
    reportChoice: 'all_time' | 'current_day' | 'past_three_months'
    minDate?: string
    maxDate?: string
}

const getFileName = ({ minDate, reportChoice }: DownloadReportProps) => {
    if (reportChoice === 'all_time') {
        return `Check_ins_all_time_generated_${format(
            new Date(),
            'yyyy-MM-dd-hhmmss'
        )}.csv`
    }
    if (reportChoice === 'current_day') {
        return `Check_ins_${minDate}.csv`
    }
    if (reportChoice === 'past_three_months') {
        return `Check_ins_past_three_months_${format(
            new Date(),
            'yyyy-MM-dd-hhmmss'
        )}.csv`
    }

    throw new Error('Could not determine the correct file name.')
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const useFetchCheckInsDownloadCSVResource = (): {
    download: (reportProperties: DownloadReportProps) => Promise<void>
    loading: boolean
} => {
    const { id: spaceId } = useSpaces()
    const { track } = useAnalytics()

    const { get, response, loading } = useFetch(
        `/spaces/${spaceId}/reports/workAttendances`,
        fetchOptions
    )

    async function download(reportProperties: DownloadReportProps) {
        const { minDate, maxDate, reportChoice } = reportProperties
        const analyticsDateProperties = {
            date_range_start: minDate,
            date_range_end: maxDate,
            granularity: reportChoice,
        }

        let queryParams
        if (
            reportChoice === 'all_time' ||
            reportChoice === 'past_three_months'
        ) {
            queryParams = {
                startDate: minDate,
            }
        } else {
            queryParams = {
                date: minDate,
            }
        }

        const queryString = qs.stringify(
            { ...queryParams, timeZone },
            { addQueryPrefix: true }
        )

        await get(queryString)

        if (response.ok) {
            const csv = await response.blob()
            const fileName = getFileName(reportProperties)

            track(`workplace_check_in_action`, {
                action: 'View',
                label: 'report_download',
                ...analyticsDateProperties,
                download_state: 'success',
            })
            toast.success('Download complete')
            saveAs(csv, fileName)
        } else {
            track(`workplace_check_in_action`, {
                action: 'View',
                label: 'report_download',
                ...analyticsDateProperties,
                download_state: 'error',
            })
            toast.error('CSV download failed. Please try again.')
        }
    }

    return {
        download,
        loading,
    }
}

export default useFetchCheckInsDownloadCSVResource
