import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Spinner,
} from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'

const UsernamePassword = ({
    onSubmit = () => {},
    isLoading = false,
    showError = false,
}) => {
    const { track } = useAnalytics()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const onFormSubmit = (e) => {
        e.preventDefault()
        onSubmit({ username, password })
    }

    return (
        <Form onSubmit={onFormSubmit}>
            <h1>Sign in</h1>
            <p>To access your dashboard please sign in.</p>
            {showError && (
                <Alert color="error" className="d-flex flex-row">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="my-auto mr-3 icon"
                    />
                    <div>
                        The email and password you entered did not match our
                        records. Please double-check and try again.
                    </div>
                </Alert>
            )}
            <FormGroup>
                <Label for="email">Email Address</Label>
                <Input
                    id="email"
                    type="email"
                    onChange={(e) => setUsername(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">Password</Label>
                <Button
                    className="float-right text-decoration-none pt-0"
                    color="link"
                    type="button"
                    onClick={() => setShowPassword((curr) => !curr)}
                >
                    {showPassword ? 'hide' : 'show'}
                </Button>
                <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormGroup>

            <div className="text-center my-3">
                <Button
                    id="forgot-password-link"
                    className="purple-link"
                    color="link"
                    onClick={() => {
                        track('reset_pw_action', {
                            action: 'Click',
                            label: 'reset_password',
                        })
                        navigate('/forgot-password/email', {
                            state: { email: username },
                        })
                    }}
                >
                    Forgot your password
                </Button>
            </div>

            {isLoading ? (
                <Button block color="primary" disabled>
                    <Spinner
                        size="sm"
                        role="status"
                        className="mr-1"
                        aria-hidden="true"
                    />
                    Signing In...
                </Button>
            ) : (
                <Button
                    block
                    color="primary"
                    disabled={!(password && username)}
                >
                    Sign In
                </Button>
            )}
        </Form>
    )
}

UsernamePassword.propTypes = {
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    showError: PropTypes.bool,
}

export default UsernamePassword
