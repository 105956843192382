import React, { useState, useCallback, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Col, Row } from 'reactstrap'
import Deactivate from './Deactivate'
import UploadCSV from './UploadCSV'
import TryAgain from './TryAgain'
import { selectDeactivationsUploadPreview } from '../../state/carpoolers/selectors'
import {
    createCarpoolerDeactivationsUploadPreviewReset,
    createCarpoolerDeactivationsUploadReset,
    createCarpoolerDeactivationsUploadRequest,
} from '../../state/carpoolers/actions'

const BulkDeactivate = ({ spaceAlias, spaceName }) => {
    const dispatch = useDispatch()
    // Make sure there is a current space in the store
    // Keep uploaded File and fileName in state so it can be used for all 3 steps
    const [file, setFile] = useState({
        payload: null,
        fileName: null,
    })
    const [isTryAgain, setIsTryAgain] = useState(false)
    // Sanitize the store when this page unmounts
    useEffect(() => {
        return () => {
            dispatch(createCarpoolerDeactivationsUploadPreviewReset())
            dispatch(createCarpoolerDeactivationsUploadReset())
        }
    }, [dispatch])

    const boundDeactivationsUpload = useCallback(
        ({ payload, dryRun, redirectOnSuccess }) =>
            dispatch(
                createCarpoolerDeactivationsUploadRequest({
                    csv: payload,
                    dryRun,
                    redirectOnSuccess,
                })
            ),
        [dispatch]
    )

    const deactivationsPreview = useSelector(selectDeactivationsUploadPreview)

    let component = null
    // Step 1-Mandatory: If we DO NOT have a preview of the deactivations returned from the server, step 1 is to upload the csv
    if (_.isEmpty(deactivationsPreview)) {
        component = (
            <UploadCSV
                spaceAlias={spaceAlias}
                onUploadFile={setFile}
                onSubmit={boundDeactivationsUpload}
            />
        )
    } else if (deactivationsPreview && !isTryAgain) {
        // Step 2-Mandatory: If we DO have a preview of the deactivations, then step 2 is to commit to deactivating the commuters
        component = (
            <Deactivate
                spaceAlias={spaceAlias}
                spaceName={spaceName}
                deactivationsPreview={deactivationsPreview}
                payload={file.payload}
                onError={setIsTryAgain}
                onSubmit={boundDeactivationsUpload}
            />
        )
    } else {
        // Step 3-Optional: If step 2 fails, then we give the user another chance
        component = (
            <TryAgain
                file={file}
                spaceAlias={spaceAlias}
                onCancel={setIsTryAgain}
                onSubmit={boundDeactivationsUpload}
            />
        )
    }

    return (
        <Row className="justify-content-center pt-5">
            <Col md="6">{component}</Col>
        </Row>
    )
}

BulkDeactivate.propTypes = {
    spaceAlias: PropTypes.string.isRequired,
    spaceName: PropTypes.string.isRequired,
}

export default BulkDeactivate
