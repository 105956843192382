import React from 'react'
import styled from 'styled-components'
import { Row, Col, Table } from 'reactstrap'
import { RosterRecord } from '../../../types'
import Button from '../../../components/Button'
import { RecordsInviteBulkResponseType } from '../../../hooks/resources/useRosterRecordsInviteBulkResource'
import toast from '../../../components/Toast/toast'

const FloatingContainer = styled.div`
    bottom: 2vh;
    position: sticky;
    text-align: center;
`

export type Props = {
    invitations: RosterRecord[]
    onSendInvitesSuccess: () => void
    sendInvites: () => Promise<RecordsInviteBulkResponseType | undefined>
    isSending: boolean
}

const BulkInvitationsPreview = ({
    invitations,
    onSendInvitesSuccess,
    sendInvites,
    isSending,
}: Props): JSX.Element => {
    const handleConfirmSend = async () => {
        const data = await sendInvites()
        if (data) {
            onSendInvitesSuccess()
            const inviteTotal =
                data.length === 1 ? '1 invite' : `${data.length} invites`
            toast.success(`${inviteTotal} sent successfully.`)
        } else {
            toast.error('Failed to send roster invitations. Please try again.')
        }
    }

    return (
        <>
            <h4 data-test="bulk-invitations-preview-title">Review CSV</h4>
            <p className="text-muted mb-4">
                Please review the information to make sure it’s correct before
                sending the invities.
            </p>
            <Row className="pb-3">
                <Col>
                    <Table className="border rounded">
                        <thead data-test="table-head">
                            <tr className="font-weight-bold">
                                <td>Email</td>
                                <td>Employee ID</td>
                            </tr>
                        </thead>

                        <tbody data-test="table-body">
                            {invitations.map(
                                ({ email, externalIdentifier }) => {
                                    return (
                                        <tr key={externalIdentifier}>
                                            <td>{email}</td>
                                            <td>{externalIdentifier}</td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <FloatingContainer>
                <Button
                    data-test="send-button"
                    label="Send invites"
                    size="md"
                    loadingLabel="Sending..."
                    className="shadow ml-1"
                    isLoading={isSending}
                    block={false}
                    onClick={handleConfirmSend}
                >
                    Send invites
                </Button>
            </FloatingContainer>
        </>
    )
}

export default BulkInvitationsPreview
