import PropTypes from 'prop-types'

export const graphSectionAnalytics = {
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    clickLabel: PropTypes.string.isRequired,
    viewLabel: PropTypes.string.isRequired,
}

export const lastUpdatedTimeAnalytics = {
    category: PropTypes.string.isRequired,
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        quarter: PropTypes.number,
        month: PropTypes.number,
        week: PropTypes.number,
    }),
}
