import React from 'react'

import SideBarLink from '../SideBarLink'
import ModuleFlag from '../../ModuleFlag/index'
import { Space } from '../../../providers/SpacesProvider'

type Props = {
    space: Pick<Space, 'alias'>
}

const EmployeeRosterSideBar = ({ space }: Props): JSX.Element => {
    return (
        <>
            <ModuleFlag name="rosterManagement">
                <strong className="d-block ml-3 py-2">Employee Roster</strong>
                <SideBarLink
                    text="Roster management"
                    to={`${space.alias}/roster/management`}
                />
            </ModuleFlag>
        </>
    )
}

export default EmployeeRosterSideBar
