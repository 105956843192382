import { shape, string } from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    UncontrolledDropdown,
} from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import { createOpenModal } from '../../state/modal'
import { modalTypeMap } from '../ReduxModal/helpers.ts'

const AccountDropdown = ({ user }) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    if (!user || !user.displayName) {
        return null
    }

    const [firstInitial] = user.displayName.toUpperCase()

    return (
        <Nav>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                    onClick={() => {
                        track('account_settings_action', {
                            action: 'Click',
                            label: 'account_menu',
                        })
                    }}
                    nav
                    className="btn btn-white border border-secondary rounded text-decoration-none nav-link d-flex align-items-center justify-content-between py-1 px-2"
                    style={{ padding: '0.65rem' }}
                >
                    Account{' '}
                    <span
                        className="d-flex justify-content-center align-items-center bg-input-border border border-secondary ml-1 rounded-circle"
                        style={{ width: '2.2rem', height: '2.2rem' }}
                    >
                        {firstInitial}
                    </span>
                </DropdownToggle>

                <DropdownMenu right>
                    <DropdownItem
                        onClick={() => {
                            dispatch(
                                createOpenModal({
                                    name: modalTypeMap.SIGN_OUT,
                                })
                            )
                            track('signout_action', {
                                action: 'Click',
                                label: 'account_menu_sign_out',
                            })
                        }}
                    >
                        Sign Out
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>
    )
}

AccountDropdown.propTypes = {
    user: shape({
        displayName: string,
    }),
}

export default AccountDropdown
