import { ICellRendererParams } from 'ag-grid-community'
import { AgGridColumnProps } from 'ag-grid-react'
import React, { useRef } from 'react'

import { UncontrolledTooltip } from 'reactstrap'
import HealthAndSafetyTimeStamp from './HealthAndSafetyTimeStamp'
import CheckInId from './CheckInId'
import StatusCell from './StatusCell'
import AttendanceCell from './AttendanceCell'
import buildingAttendedIcon from '../../../images/icons/building-attended.svg'
import buildingIndicatedIcon from '../../../images/icons/building-indicated.svg'
import Overflow from '../../../components/Overflow'

// Note: if every table supports pagination, this function
// be baked into the <Table /> itself
export function getIsFooterVisible({
    isLoading,
    error,
    checkIns,
}: {
    isLoading: boolean
    error?: Error
    checkIns?: unknown[]
}): boolean {
    if (isLoading) {
        return true
    }

    if (error || !checkIns?.length) {
        return false
    }

    return true
}

export const columnDefinitions: AgGridColumnProps[] = [
    {
        colId: 'roster_records.first_name', // Keep this as roster_records.first_name because this column sorts by first_name
        headerName: 'Name',
        valueGetter: ({ data }) =>
            `${data.rosterRecord.firstName} ${data.rosterRecord.lastName}`,
        pinned: true,
        sortable: true,
        unSortIcon: true,
    },
    {
        colId: 'department',
        headerName: 'Department',
        field: 'rosterRecord.department.label',
    },
    {
        colId: 'account_work_attendance_indications.status',
        headerName: 'Status',
        cellRenderer: 'statusComponent',
        width: 200, // This is a predictable width column
        sortable: true,
        unSortIcon: true,
    },
    {
        colId: 'attendance',
        headerName: 'Attendance',
        cellRenderer: 'attendanceComponent',
        width: 150, // This is a predictable width column
    },
    {
        colId: 'building',
        headerName: 'Building',
        cellRenderer: 'buildingCellRenderer',
    },
    {
        colId: 'account_work_attendance_indications.confirmation_code',
        headerName: 'Day pass ID',
        cellRenderer: 'dayPassIdCellRenderer',
        suppressSizeToFit: true,
        width: 150, // This is a predictable width column
        sortable: true,
        unSortIcon: true,
    },
    {
        colId: 'account_health_and_safety_submissions.created_at',
        headerName: 'Policy last accepted on',
        cellRenderer: 'healthAndSafetyTimeStampCellRenderer',
        minWidth: 200,
        flex: 1, // take up remaining space
        sortable: true,
        unSortIcon: true,
    },
]

export const StatusCellRenderer = (props: ICellRendererParams): JSX.Element => {
    return <StatusCell indication={props.data.indication} />
}

export const AttendanceCellRenderer = (
    props: ICellRendererParams
): JSX.Element => {
    return <AttendanceCell actual={props.data.actual} />
}

type BuildingWithTooltip = {
    buildingName: string
    tooltipText?: string
    icon: string
    testAttr: string
    lineHeight?: number
}

// Show a building with a tooltip. Using refs to ensure Reactstrap doesn't attempt to render the tooltip before the AG grid cell has rendered
const BuildingWithTooltip = ({
    lineHeight,
    buildingName,
    tooltipText,
    icon,
    testAttr,
}: BuildingWithTooltip): JSX.Element => {
    const targetRef = useRef(null)

    if (tooltipText) {
        return (
            <Overflow
                data-test={`${testAttr}-building`}
                lineHeight={lineHeight}
            >
                <img ref={targetRef} src={icon} height="16" className="mr-1" />
                <UncontrolledTooltip target={targetRef}>
                    <span data-test={`${testAttr}-building-tooltip`}>
                        {tooltipText}
                    </span>
                </UncontrolledTooltip>
                {buildingName}
            </Overflow>
        )
    }

    return (
        <Overflow data-test={`${testAttr}-building`}>
            <img ref={targetRef} src={icon} height="16" className="mr-1" />
            {buildingName}
        </Overflow>
    )
}

// Show building from indication, then from actual.
// Structure of it must match AttendanceStatusCellRenderer because building should align with the value in the status cell.
export const BuildingCellRenderer = (
    props: ICellRendererParams
): JSX.Element | null => {
    const buildingIndicated = props.data.indication?.building
    const buildingActual = props.data.actual?.building

    if (buildingIndicated && buildingActual) {
        // If indicated and actual are the same, then just show actual with no tooltip
        if (buildingIndicated.id === buildingActual.id) {
            return (
                <BuildingWithTooltip
                    testAttr="attended"
                    buildingName={buildingActual.name}
                    icon={buildingAttendedIcon}
                />
            )
        }

        // If they're different, show both with tooltips
        // We're squeezing two items into the row, so we override the default lineheight
        const lineHeight = props.api.getSizesForCurrentTheme().rowHeight / 2

        return (
            <div>
                <BuildingWithTooltip
                    lineHeight={lineHeight}
                    buildingName={buildingIndicated.name}
                    icon={buildingIndicatedIcon}
                    tooltipText="Building indicated"
                    testAttr="indicated"
                />
                <BuildingWithTooltip
                    lineHeight={lineHeight}
                    buildingName={buildingActual.name}
                    icon={buildingAttendedIcon}
                    tooltipText="Building attended"
                    testAttr="attended"
                />
            </div>
        )
    }

    if (buildingIndicated) {
        return (
            <BuildingWithTooltip
                testAttr="indicated"
                buildingName={buildingIndicated.name}
                icon={buildingIndicatedIcon}
            />
        )
    }

    if (buildingActual) {
        return (
            <BuildingWithTooltip
                testAttr="attended"
                buildingName={buildingActual.name}
                icon={buildingAttendedIcon}
            />
        )
    }

    return null
}

export const DayPassIdCellRenderer = (
    props: ICellRendererParams
): JSX.Element => {
    return <CheckInId indication={props.data.indication} />
}

export const HealthAndSafetyTimeStampCellRenderer = (
    props: ICellRendererParams
): JSX.Element => {
    const { indication, latestHealthAndSafetySubmission } = props.data
    const date = latestHealthAndSafetySubmission?.createdAt
    return (
        <HealthAndSafetyTimeStamp
            indicationId={indication?.id}
            healthAndSafetySubmission={latestHealthAndSafetySubmission}
            key={date?.toString()}
        />
    )
}
