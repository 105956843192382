// Copied from Midas/chrome-extension.
// TODO: Consider combining these into a new package.

import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { Field } from 'react-final-form'
import { FormText, FormFeedback, Label } from 'reactstrap'
import classNames from 'classnames'

import { validateRequired } from '../../util/validators'

/**
 * This is a HOC that takes any input Component as a parameter and injects
 * the necessary props from Final Form into the input Component
 * It also sets up basic HTML boilerplate like Labels, inline Errors, and Form Hints
 *
 * <Field/> wraps a child as a function and passes input props from Final Form to the Wrapped Component
 * The destructured rest props are optional props that might be passed to the Component
 *
 * @param {React.Component} InputComponent
 */

function withField(InputComponent) {
    const FieldWrappedComponent = ({
        label,
        name,
        validate = validateRequired,
        inputHint,
        showOptionalLabel = false,
        showError = false,
        initialvalue,
        format,
        formatOnBlur,
        parse,
        onChange,
        type,
        ...otherProps
    }) => {
        const handleChange = useCallback(
            (input, e) => {
                input.onChange && input.onChange(e)
                onChange && onChange(e)
            },
            [onChange]
        )

        return (
            <Field
                name={name}
                validate={validate}
                initialValue={initialvalue}
                format={format}
                formatOnBlur={formatOnBlur}
                parse={parse}
                type={type}
            >
                {({ input, meta }) => {
                    const {
                        error,
                        submitError,
                        touched,
                        invalid,
                        dirtySinceLastSubmit,
                    } = meta
                    const showErrorIfTouched = touched && showError
                    const isFieldErrorVisible = error && showErrorIfTouched
                    const isSubmitErrorVisible =
                        submitError &&
                        showErrorIfTouched &&
                        !dirtySinceLastSubmit

                    const isErrorVisible =
                        isFieldErrorVisible || isSubmitErrorVisible

                    const isInvalid = isErrorVisible || invalid

                    const labelClassName = classNames({
                        'text-danger': isErrorVisible,
                    })

                    const inputProps = { ...input }
                    delete inputProps.onChange

                    return (
                        <div>
                            {label && (
                                <Label className="d-flex align-items-center justify-content-between">
                                    <span className={labelClassName}>
                                        {label}
                                    </span>
                                    {showOptionalLabel && (
                                        <span className="small text-dark">
                                            Optional
                                        </span>
                                    )}
                                </Label>
                            )}
                            <InputComponent
                                invalid={isInvalid}
                                onChange={(e) => handleChange(input, e)}
                                {...inputProps}
                                {...otherProps}
                            />

                            {inputHint && !isErrorVisible && (
                                <FormText>{inputHint}</FormText>
                            )}

                            {isErrorVisible && (
                                <FormFeedback className="d-block">
                                    {error || submitError}
                                </FormFeedback>
                            )}
                        </div>
                    )
                }}
            </Field>
        )
    }

    const finalFormProps = {
        name: PropTypes.string.isRequired,
        validate: PropTypes.func,
        initialvalue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
        format: PropTypes.func,
        parse: PropTypes.func,
        formatOnBlur: PropTypes.bool,
        onChange: PropTypes.func,
    }

    const inputProps = {
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        inputHint: PropTypes.string,
        showError: PropTypes.bool,
        showOptionalLabel: PropTypes.bool,
        type: PropTypes.string,
    }

    FieldWrappedComponent.propTypes = {
        ...finalFormProps,
        ...inputProps,
    }

    return FieldWrappedComponent
}

export default withField
