import React from 'react'
import { Progress as ReactstrapProgress } from 'reactstrap'
import styled from 'styled-components'

import Icon from '../Icon'
import colors from '../../styles/colors'

const Progress = styled(ReactstrapProgress)`
    width: 350px;
    height: 4px;

    .progress-bar {
        background-color: ${colors.primary};
    }
`

type Props = {
    percentUploaded: number
}

/**
 * @param percentUploaded: From 0 to 100. Decimals like `10.23` are accepted.
 */
const Uploading = ({ percentUploaded }: Props): JSX.Element => {
    return (
        <>
            <Icon name="file-upload" size="4x" className="mb-3 text-success" />
            <h5 className="mb-4">Uploading...</h5>

            <Progress value={percentUploaded} />
        </>
    )
}

export default Uploading
