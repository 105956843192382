import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Row, Col, Button, Container } from 'reactstrap'
import { Form } from 'react-final-form'
import { useNavigate, RouteComponentProps } from '@reach/router'
import { h3SetToMultiPolygon, polyfill, H3IndexInput } from 'h3-js'

import useSpaces from '../../../hooks/useSpaces'
import useEditBuilding from '../hooks/useEditBuilding'
import useBreadCrumbs, { Breadcrumb } from '../../../hooks/useBreadcrumbs'
import { BuildingDetails } from '../../../hooks/useBuildingDetails'
import { checkIsFormValid } from '../utility'
import GhostLoader from '../../../components/GhostLoader'
import ButtonWithLoader from '../../../components/Button'
import BuildingForm from '../BuildingForm'
import EditBuildingPerimeter from '../EditBuildingPerimeter'
import {
    getCenterFromPolygon,
    HEX_RESOLUTION,
} from '../../../components/BuildingPerimeterMap/utility'
import useFetchResolveAddress from '../hooks/useFetchResolveAddress'

interface Props extends RouteComponentProps {
    buildingId?: string
    buildingDetails: BuildingDetails
}

const EditBuilding = ({ buildingId, buildingDetails }: Props): JSX.Element => {
    const navigate = useNavigate()
    const { alias } = useSpaces()
    const [h3Indices, setH3Indices] = useState<Set<H3IndexInput>>(new Set())

    const {
        isLoading,
        getBuildingDetails,
        getBuildingCapacityConfiguration,
        building,
        maxCapacity,
        hasError,
    } = buildingDetails

    const {
        isLoading: isAddressLoading,
        hasError: hasResolvedAddressError,
        getAddressById,
        address,
        clearAddress,
    } = useFetchResolveAddress()

    const handleTypeAheadChange = useCallback(
        (address) => {
            if (address) {
                return getAddressById(address.placeId)
            }

            return clearAddress()
        },
        [getAddressById, clearAddress]
    )

    const { submitForm, isFormSubmitting } = useEditBuilding({
        building,
        getBuildingCapacityConfiguration,
    })

    // TODO: Fix bug - The breadcrumbs are not shown if it's navigated from BuildingDetails page.
    // But if you refresh this page, the issue is gone.
    const breadcrumbs = useMemo(() => {
        const crumb: Breadcrumb[] = [
            {
                label: 'Buildings',
                link: `/spaces/${alias}/settings/buildings/all`,
            },
        ]

        if (building?.name) {
            crumb.push({ label: building.name })
        }

        return crumb
    }, [building, alias])

    useBreadCrumbs(breadcrumbs)

    /**
     * Get building details only if it is not loaded yet.
     * This is when the user directly accessed to this page via URL.
     */
    useEffect(() => {
        if (!building && buildingId) {
            getBuildingDetails(buildingId)
        }
    }, [getBuildingDetails, buildingId, building])

    const isPerimeterUpdated = useMemo(() => {
        if (!building) {
            return false
        }
        const h3IndicesFromPolygon = polyfill(
            building?.geometry.coordinates[0],
            HEX_RESOLUTION,
            true
        )

        return (
            h3IndicesFromPolygon.length !== h3Indices.size ||
            !h3IndicesFromPolygon.every((h) => h3Indices.has(h))
        )
    }, [building, h3Indices])

    const handleSubmitForm = useCallback(
        (values, form) => {
            const { dirtyFields } = form.getState()
            const updatedValues: {
                buildingPerimeterPolygon?: number[][][]
            } = {}

            // Only get updated values
            for (const [field, isDirty] of Object.entries(dirtyFields)) {
                if (!isDirty) {
                    continue
                }

                let value = values[field]

                if (field === 'maxCapacity') {
                    // Explicitly set null to undefined value
                    value = value ? parseInt(value, 10) : null
                }

                updatedValues[field] = value
            }

            if (isPerimeterUpdated) {
                const [buildingPerimeterPolygon] = h3SetToMultiPolygon(
                    Array.from(h3Indices),
                    true
                )
                updatedValues.buildingPerimeterPolygon = buildingPerimeterPolygon
            }

            return submitForm(updatedValues)
        },
        [submitForm, h3Indices, isPerimeterUpdated]
    )

    const initialCoordinates = useMemo(
        () => getCenterFromPolygon(building?.geometry.coordinates),
        [building]
    )

    const coordinates = address?.coordinates || initialCoordinates

    const hasMapError = hasError || hasResolvedAddressError
    const isMapLoading = isLoading || isAddressLoading

    const initialBuildingPerimeterPolygon = useMemo(() => {
        if (!building) {
            return undefined
        }

        const buildingCoordinates = building.geometry.coordinates[0]

        // If the user updates the address then we don't show the original building coordinates
        if (address && address.streetAddress !== building?.baseAddress) {
            return undefined
        }

        return buildingCoordinates
    }, [address, building])

    return (
        <Form
            data-test="modify-building-form"
            onSubmit={handleSubmitForm}
            initialValues={{
                name: building?.name,
                address: building?.baseAddress,
                // Inputs only accept strings, not numbers.
                maxCapacity:
                    maxCapacity === undefined ? undefined : `${maxCapacity}`,
                enforceCapacity: 'no',
            }}
            className="mt-3"
        >
            {({
                handleSubmit,
                dirtyFieldsSinceLastSubmit,
                submitErrors,
                values,
                initialValues,
                pristine,
            }) => {
                const isFormValid = checkIsFormValid({
                    formValues: values,
                    submitErrors,
                    dirtyFieldsSinceLastSubmit,
                })

                const isDisabled = isPerimeterUpdated
                    ? false
                    : pristine || !isFormValid

                return (
                    <Container size="xl">
                        <Row className="my-3">
                            <Col className="px-0 d-flex align-items-center">
                                {isLoading ? (
                                    <GhostLoader
                                        data-test="title-loader"
                                        height={28}
                                    />
                                ) : (
                                    <h2 data-test="title" className="mb-0">
                                        {building?.name}
                                    </h2>
                                )}
                            </Col>
                            <Col className="d-flex justify-content-end pr-0">
                                <Button
                                    data-test="cancel"
                                    className="mr-3"
                                    outline
                                    color="danger"
                                    onClick={() =>
                                        navigate(
                                            `/spaces/${alias}/settings/buildings/${buildingId}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>

                                <ButtonWithLoader
                                    data-test="save-button"
                                    type="submit"
                                    size="md"
                                    block={false}
                                    label="Save changes"
                                    loadingLabel="Saving changes..."
                                    onClick={handleSubmit}
                                    isDisabled={isDisabled}
                                    isLoading={isFormSubmitting}
                                />
                            </Col>
                        </Row>
                        <Row className="border rounded bg-white p-3 mb-3">
                            <Col xs={{ size: 5 }}>
                                <BuildingForm
                                    isLoading={isLoading}
                                    onSubmit={handleSubmit}
                                    onAddressChange={handleTypeAheadChange}
                                    initialValues={initialValues}
                                />
                            </Col>

                            <Col xs={{ size: 7 }}>
                                <Row>
                                    <Col>
                                        <h5>Building perimeter</h5>
                                        <div className="border-bottom" />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <small className="text-muted">
                                            Edit the perimeter by clicking on
                                            the map. There should be about a
                                            city block&apos;s worth of space
                                            around the building inside the
                                            perimeter. We&apos;ll use this to
                                            determine when employees utilize the
                                            building.
                                        </small>
                                    </Col>
                                </Row>
                                <Row className="pl-3 mb-3">
                                    <EditBuildingPerimeter
                                        id="edit-building-perimeter"
                                        initialBuildingPerimeterPolygon={
                                            initialBuildingPerimeterPolygon
                                        }
                                        h3Indices={h3Indices}
                                        onTryAgain={getAddressById}
                                        handleUpdateH3Indices={setH3Indices}
                                        isLoading={isMapLoading}
                                        coordinates={coordinates}
                                        hasError={hasMapError}
                                        hasAddress={Boolean(values.address)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                )
            }}
        </Form>
    )
}

export default EditBuilding
