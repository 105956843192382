import PropTypes from 'prop-types'

import { getAreModulesEnabled, isModuleEnabled } from './util'
import { useSpaces } from '../../providers/SpacesProvider.tsx'

/**
 * Module flag component.
 * Takes either props.name OR props.modules.
 * If the specified module(s) (feature) are enabled, the wrapped component will be rendered.
 *
 * If props.modules is provided, it must include an array of module names and specify a comparator
 * to be applied to the array of modules. This is to determine whether the flag requires ALL or SOME
 * of the module names provided.
 *
 * Similar to useModuleFlags
 *
 * @param {Object} props - React props
 * @property {String} props.name - module name. It can be chained like `module1.module2.module3`
 * @property {Object>} props.modules - module names. An array of module names.
 * @property {Array<String>} props.modules.names - module names. An array of module names.
 * @property {String>} props.modules.comparator - The type of predicate function to apply props.modules.names. e.g, "some", or "every"
 * @property {Node} props.children - React node to be rendered
 * @return {Node} children node if the module name is enabled. Null otherwise.
 *
 */
const ModuleFlag = ({ name, modules, children, fallback }) => {
    const spaces = useSpaces()

    try {
        let areModulesEnabled
        if (name && !modules) {
            areModulesEnabled = isModuleEnabled(name, spaces?.modules)
        } else {
            areModulesEnabled = getAreModulesEnabled(
                modules.names,
                spaces?.modules,
                modules.comparator
            )
        }

        if (!areModulesEnabled) {
            return fallback || null
        }

        return children
    } catch (e) {
        // Ignore error if space can't be accessed.
        return fallback || null
    }
}

ModuleFlag.propTypes = {
    name: PropTypes.string,
    modules: PropTypes.shape({
        names: PropTypes.arrayOf(PropTypes.string).isRequired,
        comparator: PropTypes.oneOf(['some', 'every']).isRequired,
    }),
    children: PropTypes.node,
    fallback: PropTypes.node,
}

export default ModuleFlag
