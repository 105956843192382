import {
    USER_ADD,
    USER_REMOVE,
    USER_CREATE_OKTA_REQUEST,
    USER_CREATE_TOKEN_PASSWORD_REQUEST,
    USER_CREATE_SUCCESS,
    USER_CREATE_FAILURE,
    USER_SIGN_OUT_REQUEST,
    USER_SIGN_OUT_SUCCESS,
    USER_SIGN_OUT_FAILURE,
} from './types'

export const createUserAdd = ({
    userId,
    emails,
    fullName,
    displayName,
    type,
}) => ({
    type: USER_ADD,
    payload: {
        userId,
        emails,
        fullName,
        displayName,
        type,
    },
})

export const createUserRemove = () => ({
    type: USER_REMOVE,
})

export const createUserCreateOktaRequest = () => ({
    type: USER_CREATE_OKTA_REQUEST,
})

export const createUserCreateTokenPasswordRequest = ({ password, token }) => ({
    type: USER_CREATE_TOKEN_PASSWORD_REQUEST,
    payload: {
        password,
        token,
    },
})

export const createUserCreateSuccess = ({ userId }) => ({
    type: USER_CREATE_SUCCESS,
    payload: {
        userId,
    },
})

export const createUserCreateFailure = ({ error }) => ({
    type: USER_CREATE_FAILURE,
    error: true,
    payload: error,
})

export const createSignOutRequest = () => ({
    type: USER_SIGN_OUT_REQUEST,
})

export const createSignOutSuccess = () => ({
    type: USER_SIGN_OUT_SUCCESS,
})

export const createSignOutFailure = ({ error }) => ({
    type: USER_SIGN_OUT_FAILURE,
    error: true,
    payload: error,
})
