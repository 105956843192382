import { useCallback } from 'react'
import qs from 'qs'
import { useLocation, useNavigate } from '@reach/router'

/**
 * This hook is intended to be used in <Commute /> page but
 * this can be also used in any other components in <Commute />
 */
const usePeriodFromUrl = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const { pathname } = location
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

    const period = {
        year: queryParams.year && parseInt(queryParams.year),
        quarter: queryParams.quarter && parseInt(queryParams.quarter),
        month: queryParams.month && parseInt(queryParams.month),
        week: queryParams.week && parseInt(queryParams.week),
    }

    const hasPeriod = Boolean(
        queryParams.year ||
            queryParams.quarter ||
            queryParams.month ||
            queryParams.week
    )

    const changePeriod = useCallback(
        (updatedPeriod) => {
            const dateRangeQueryString = qs.stringify(updatedPeriod)
            navigate(`${pathname}?${dateRangeQueryString}`)
        },
        [navigate, pathname]
    )

    return { period, hasPeriod, changePeriod }
}

export default usePeriodFromUrl
