import React from 'react'
import { oneOfType, array, object, string } from 'prop-types'
import { Provider as ReduxProvider } from 'react-redux'
import { Provider as FetchProvider } from 'use-http'
import { CookiesProvider } from 'react-cookie'
import { ToastContainer } from 'react-toastify'

import ReduxModal from 'components/ReduxModal/index.tsx'
import AppConfigurationProvider from 'providers/AppConfigurationProvider'
import UserProvider from 'providers/UserProvider'
import ErrorBoundary from './ErrorBoundary'

import configureStore from './store'
import Routes from './routes'
import CosmosApi, { fetchOptions } from './CosmosApi'

/*
 * Setup the ErrorBoundary, Redux store provider, configuration context, and
 * finally the routes that our App responds to.
 */
const BaseApp = ({
    analytics = () => {},
    apiRoot,
    environment,
    apiUrl,
    nebulaUrl,
    oktaClientId,
    oktaOrgUrl,
    mapboxApiToken,
}) => {
    const cosmosApi = new CosmosApi({ apiRoot })
    const store = configureStore(cosmosApi, analytics)
    const config = {
        analytics,
        apiUrl,
        nebulaUrl,
        oktaClientId,
        oktaOrgUrl,
        cosmosApi,
        mapboxApiToken,
    }

    return (
        <AppConfigurationProvider value={config}>
            <ReduxProvider store={store}>
                <ErrorBoundary>
                    <FetchProvider url={apiRoot} options={fetchOptions}>
                        <CookiesProvider>
                            <UserProvider>
                                <Routes />
                            </UserProvider>
                        </CookiesProvider>
                        <ReduxModal />
                    </FetchProvider>
                    <ToastContainer className="w-auto" />
                </ErrorBoundary>
            </ReduxProvider>
        </AppConfigurationProvider>
    )
}

BaseApp.propTypes = {
    // Warning: Sometimes Analytics.js is an Array! See the `Non-minified` code snippet that Segment suggests using:
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-1-copy-the-snippet
    analytics: oneOfType([array, object]),
    apiRoot: string,
    apiUrl: string,
    nebulaUrl: string,
    environment: string,
    oktaClientId: string,
    oktaOrgUrl: string,
    mapboxApiToken: string,
}

export default BaseApp
