import { useCallback } from 'react'
import qs from 'qs'
import { useLocation, useNavigate } from '@reach/router'

/**
 * This hook is intended to be used in <WorkplaceCheckIns /> page.
 * It grabs a `date` param from the URL and exposes a function to modify this param.
 */
const useDateFromUrl = () => {
    const { pathname, search } = useLocation()
    const navigate = useNavigate()

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

    const hasDate = Boolean(queryParams.date)
    const date = queryParams?.date

    const setDateAndNavigate = useCallback(
        (updatedDate) => {
            const dateRangeQueryString = qs.stringify({ date: updatedDate })
            navigate(`${pathname}?${dateRangeQueryString}`)
        },
        [navigate, pathname]
    )

    return { date, hasDate, setDateAndNavigate }
}

export default useDateFromUrl
