import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { MetricDisplayWithData } from './MetricDisplay'
import useAnalytics from '../../hooks/useAnalytics'

/**
 * @property {Object} analytics.dateRangeProperties - This must be wrapped with useMemo in the parent component.
 */
const Metric = ({
    onMetricError,
    analytics = {},
    setLastRefreshedTime,
    ...otherProps
}) => {
    const includeAnalytics = Boolean(analytics.name)
    const { track } = useAnalytics()

    const handleDataUpdate = useCallback(
        ({ data, hasError, errorId }) => {
            if (hasError) {
                if (errorId) {
                    onMetricError && onMetricError(errorId)
                }
            } else {
                setLastRefreshedTime && setLastRefreshedTime(data.refreshedAt)
            }
        },
        [onMetricError, setLastRefreshedTime]
    )

    const trackChange = useCallback(
        ({ hasError, loadingDuration }) => {
            if (includeAnalytics) {
                track('performance_action', {
                    action: 'View',
                    label: analytics.name,
                    loaded: !hasError,
                    milliseconds_to_load: loadingDuration,
                    ...analytics.dateRangeProperties,
                })
            }
        },
        [track, analytics.name, analytics.dateRangeProperties, includeAnalytics]
    )

    const handleChange = useCallback(
        ({ data, hasError, errorId, loadingDuration }) => {
            handleDataUpdate({ data, hasError, errorId })
            trackChange({ hasError, loadingDuration })
        },
        [handleDataUpdate, trackChange]
    )

    return (
        <MetricDisplayWithData
            analytics={analytics}
            onChange={handleChange}
            {...otherProps}
        />
    )
}

Metric.propTypes = {
    suffix: PropTypes.string,
    setLastRefreshedTime: PropTypes.func,
    onMetricError: PropTypes.func,
    analytics: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dateRangeProperties: PropTypes.shape({
            range_size: PropTypes.string,
            date_range_start: PropTypes.string.isRequired,
            date_range_end: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            quarter: PropTypes.number,
            month: PropTypes.number,
            week: PropTypes.number,
        }),
    }),
}

export default Metric
