import React from 'react'

import AcceptedFileInfo from './AcceptedFileInfo'
import FileUploadButton from '../FileUploadButton'
import Icon from '../Icon'

type Props = {
    acceptedFileExtensions: string[]
    maxFileSizeInBytes: number
    onFileChange: (file: File) => void
    error?: string
}

const Error = ({
    acceptedFileExtensions,
    maxFileSizeInBytes,
    onFileChange,
    error,
}: Props): JSX.Element => {
    return (
        <>
            <Icon name="times-circle" size="4x" className="mb-3 text-danger" />
            <h5 className="text-danger mb-3">File could not be uploaded</h5>
            <p data-test="file-uploader-error-message" className="text-muted">
                {error}
            </p>
            <div className="mb-3">
                <FileUploadButton
                    onFileChange={onFileChange}
                    acceptableFileTypes={acceptedFileExtensions.join(',')}
                >
                    Browse files
                </FileUploadButton>
            </div>
            <AcceptedFileInfo
                acceptedFileExtensions={acceptedFileExtensions}
                maxFileSizeInBytes={maxFileSizeInBytes}
            />
        </>
    )
}

export default Error
