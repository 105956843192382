import React from 'react'
import GhostLoader from '../GhostLoader/index'
import { useIntegrations } from '../../providers/IntegrationsProvider.tsx'

/**
 * Show a label for which integration is currently synced.
 * Needs to be in a component that is wrapper in a <IntegrationsProvider />
 */
const HrisSyncLabel = () => {
    const {
        hris: { loading },
        connectedHrisIntegration,
    } = useIntegrations()

    if (loading) {
        return <GhostLoader />
    }

    if (connectedHrisIntegration) {
        return <span>Data synced via {connectedHrisIntegration.label}</span>
    }

    return null
}

export default HrisSyncLabel
