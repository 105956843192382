import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import qs from 'qs'

import { getDateRangeProperties } from '../../../util/analytics'
import { isModuleEnabled } from '../../../components/ModuleFlag/util'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import Metric from '../../../components/Metric'
import VerticalSeparator from '../../../components/Metric/VerticalSeparator'

const ImpactMetrics = ({
    period,
    getPath,
    setLastRefreshedTime,
    isMetricUnavailable,
    isMetricFetchCacheDisabled,
    onMetricError,
}) => {
    const { modules } = useSpaces()
    const isActivityGraphsEnabled = isModuleEnabled('activityGraphs', modules)
    const periodQueryString = qs.stringify(period)
    const dateRangeProperties = useMemo(() => getDateRangeProperties(period), [
        period,
    ])

    return (
        <>
            <Row>
                <Col xs="12" className="px-0">
                    <h4>Impact</h4>
                </Col>
            </Row>
            <Row className="bg-white flex-nowrap shadow-sm p-0">
                <Col xs="6" className="p-0">
                    <Metric
                        test-attr="average-cars-out-of-lot-metric"
                        title="Average cars out of the lot per day"
                        urlPath={getPath(
                            '/spaces/:spaceId/metrics/carsOutOfTheLot\\?groupBy=day&statisticType=:statisticType&from=:fromDate&until=:untilDate',
                            'mean'
                        )}
                        linkTo={
                            isActivityGraphsEnabled
                                ? `../activity/commuter?${periodQueryString}#cars-out-of-the-lot`
                                : undefined
                        }
                        isUnavailable={isMetricUnavailable}
                        setLastRefreshedTime={setLastRefreshedTime}
                        tooltipBody="Scoop calculates this based on the average number of Riders going to and from your site daily. Each Rider is counted only once and is assumed to free up one parking space. To better understand Scoop’s impact on your facilities, multiply this number by your local SOV rate."
                        isFetchCacheDisabled={isMetricFetchCacheDisabled}
                        onMetricError={onMetricError}
                        analytics={{
                            category: 'performance_action',
                            name: 'avg_cars_out_of_lot',
                            tooltipLabel: 'cars_out_of_lot_tooltip',
                            dateRangeProperties,
                        }}
                    />
                </Col>

                <VerticalSeparator />

                <Col xs="6" className="p-0">
                    <Metric
                        test-attr="co2-pounds-saved-metric"
                        title={
                            <span>
                                Pounds CO<sub>2</sub> saved
                            </span>
                        }
                        urlPath={getPath(
                            '/spaces/:spaceId/metrics/poundsCO2Saved\\?from=:fromDate&until=:untilDate'
                        )}
                        isUnavailable={isMetricUnavailable}
                        setLastRefreshedTime={setLastRefreshedTime}
                        tooltipBody="Scoop calculates this by taking the miles saved by Riders not driving to work and multiplying by estimated CO2 emissions per mile."
                        isFetchCacheDisabled={isMetricFetchCacheDisabled}
                        onMetricError={onMetricError}
                        analytics={{
                            category: 'performance_action',
                            name: 'co2_pounds_saved',
                            tooltipLabel: 'co2_pounds_saved_tooltip',
                            dateRangeProperties,
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

ImpactMetrics.propTypes = {
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        quarter: PropTypes.number,
        month: PropTypes.number,
        week: PropTypes.number,
    }).isRequired,
    getPath: PropTypes.func.isRequired,
    setLastRefreshedTime: PropTypes.func.isRequired,
    isMetricUnavailable: PropTypes.bool.isRequired,
    isMetricFetchCacheDisabled: PropTypes.bool,
    onMetricError: PropTypes.func.isRequired,
}

export default ImpactMetrics
