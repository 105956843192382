import WebMercatorViewport from 'viewport-mercator-project'
import { polyfill } from 'h3-js'
import turfCenter from '@turf/center'
import { polygon as turfPolygon } from '@turf/helpers'
import bbox from '@turf/bbox'

export const HEX_RESOLUTION = 12

/**
 * Taken from https://stackoverflow.com/questions/56646664/how-can-i-get-the-h3-hexagons-on-a-react-map-gl-deck-gl-viewport
 *
 * Takes a viewport viewState object and returns lat for east/west and long for north/south
 * that form the parameters of a bounding box for the map.
 *
 * @param {Object} viewState - viewState for map viewport
 * @param {Number} viewState.height
 * @param {Number} viewState.width
 * @param {Number} viewState.latitude
 * @param {Number} viewState.longitude
 * @param {Number} viewState.zoom
 *
 * @returns {Object} - bounding box for the given viewport
 */
export function getBoundingBoxFromViewport(viewState) {
    const viewport = new WebMercatorViewport(viewState)
    const { height, width } = viewState

    // unproject turns pixel coordinates into real world coordinates
    const [west, north] = viewport.unproject([0, 0])
    const [east, south] = viewport.unproject([width, height])

    return { north, south, east, west }
}

/**
 * Takes bounding box dimensions that correspond to the cardinal directions,
 * constructs a polygon with those dimensions, and uses h3.polyfill to generate 
 * the h3Indices for all hexagons contained in the polygon.
 *
 * @param {Object} - bounding box dimensions in the form of lat for east/west and lon for north/south
 * @param {Number} resolution - resolution of hexagons to return
 
 * @returns {Array<String>} - an array of h3 indices within the given bounding box
 */
export function getH3IndicesForBoundingBox(
    { north, south, east, west },
    resolution = HEX_RESOLUTION
) {
    const nw = [north, west]
    const ne = [north, east]
    const sw = [south, west]
    const se = [south, east]

    const h3Indices = polyfill([nw, ne, se, sw], resolution)

    return h3Indices
}

/**
 * Get the center of the multiPolygon.
 * @param {buildingPolygonProp} multiPolygon - example: [[[[1.23, 4.423], [2.43, 3.45]]]]
 * @returns {{latitude: number, longitude: number}} the center coordinates
 */
export function getCenterFromPolygon(multiPolygon) {
    if (!multiPolygon) {
        return null
    }

    const polygon = multiPolygon[0]

    const center = turfCenter(turfPolygon(polygon))
    const [longitude, latitude] = center.geometry.coordinates

    return {
        latitude,
        longitude,
    }
}

/**
 * Get the correct zoom level of the polygon.
 * This function is useful for getting the right zoom level
 * that makes the polygon fit in the viewport.
 *
 * @param {buildingPolygonProp} polygon - example: [[[1.23, 4.423], [2.43, 3.45]]]
 * @param {Object} viewState - viewState for map viewport
 * @returns {Number} zoom - undefined if buildingPerimeterPolygon is empty.
 */
export function getZoomFromPolygon(polygon, viewState) {
    if (!polygon) {
        return undefined
    }

    const viewport = new WebMercatorViewport(viewState)
    const [minLng, minLat, maxLng, maxLat] = bbox(turfPolygon(polygon))

    const { zoom } = viewport.fitBounds(
        [
            [minLng, minLat],
            [maxLng, maxLat],
        ],
        {
            padding: 20,
        }
    )

    return zoom
}
