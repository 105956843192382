import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import qs from 'qs'

import generatePathUrl from '../../../util/generatePathUrl'
import useAnalytics from '../../../hooks/useAnalytics'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import { getDateRangeProperties } from '../../../util/analytics'
import {
    getDateRange,
    dateFormat,
    isDateRangeOverlapped,
} from '../../../util/date'
import { isModuleEnabled } from '../../../components/ModuleFlag/util'
import Metric from '../../../components/Metric'
import CommuterMetrics from '../CommuterMetrics'
import ImpactMetrics from '../ImpactMetrics'
import LastUpdatedTimeForTrips from '../../../components/LastUpdatedTime/LastUpdatedTimeForTrips/index.tsx'
import VerticalSeparator from '../../../components/Metric/VerticalSeparator'

const OverviewBody = ({
    period,
    haveTripsStarted,
    onMetricError,
    isMetricFetchCacheDisabled,
}) => {
    const { track } = useAnalytics()

    const { id: spaceId, modules } = useSpaces()
    const [lastRefreshedTime, setLastRefreshedTime] = useState()

    const isMetricUnavailable = !haveTripsStarted
    const isActivityGraphsEnabled = isModuleEnabled('activityGraphs', modules)

    const dateRangeProperties = useMemo(() => getDateRangeProperties(period), [
        period,
    ])

    const periodQueryString = qs.stringify(period)
    const isCurrentPeriod = isDateRangeOverlapped({
        latestDate: moment().format(dateFormat),
        offset: 0,
        period,
    })

    const getPath = (path, statisticType) => {
        return generatePathUrl(path, {
            spaceId,
            statisticType,
            ...getDateRange(period),
        })
    }

    useEffect(() => {
        if (isMetricUnavailable) {
            track('performance_action', {
                action: 'View',
                label: 'trips_not_started',
            })
        }
    }, [isMetricUnavailable, track])

    return (
        <>
            <Row>
                <Col xs="6" className="px-0">
                    <h4>Trips</h4>
                </Col>
            </Row>
            <Row className="bg-white flex-nowrap shadow-sm p-0 mb-5">
                <Col xs="6" className="p-0">
                    <Metric
                        title="One-way trips"
                        urlPath={getPath(
                            '/spaces/:spaceId/metrics/tripsTaken\\?from=:fromDate&until=:untilDate&statisticType=:statisticType',
                            'sum'
                        )}
                        linkTo={
                            isActivityGraphsEnabled
                                ? `../activity/trip?${periodQueryString}#one-way-trips`
                                : undefined
                        }
                        setLastRefreshedTime={setLastRefreshedTime}
                        isUnavailable={isMetricUnavailable}
                        isFetchCacheDisabled={isMetricFetchCacheDisabled}
                        onMetricError={onMetricError}
                        analytics={{
                            category: 'performance_action',
                            name: 'one_way_trips',
                            dateRangeProperties,
                        }}
                    />
                </Col>
                <VerticalSeparator />
                <Col xs="6" className="p-0">
                    <Metric
                        title="Average trips per active commuter"
                        urlPath={getPath(
                            '/spaces/:spaceId/metrics/tripsTaken\\?groupBy=activeCarpooler&statisticType=:statisticType&from=:fromDate&until=:untilDate',
                            'mean'
                        )}
                        linkTo={
                            isActivityGraphsEnabled
                                ? `../activity/trip?${periodQueryString}#average-trips`
                                : undefined
                        }
                        setLastRefreshedTime={setLastRefreshedTime}
                        isUnavailable={isMetricUnavailable}
                        isFetchCacheDisabled={isMetricFetchCacheDisabled}
                        onMetricError={onMetricError}
                        analytics={{
                            category: 'performance_action',
                            name: 'avg_trips_per_carpooler',
                            dateRangeProperties,
                        }}
                    />
                </Col>
            </Row>

            <CommuterMetrics
                period={period}
                getPath={getPath}
                setLastRefreshedTime={setLastRefreshedTime}
                isMetricUnavailable={isMetricUnavailable}
                isMetricFetchCacheDisabled={isMetricFetchCacheDisabled}
                onMetricError={onMetricError}
            />

            <ImpactMetrics
                period={period}
                getPath={getPath}
                setLastRefreshedTime={setLastRefreshedTime}
                isMetricUnavailable={isMetricUnavailable}
                isMetricFetchCacheDisabled={isMetricFetchCacheDisabled}
                onMetricError={onMetricError}
            />

            <Row className="mt-5">
                <Col
                    test-attr="lastRefreshedTime"
                    className={`px-0 ${
                        isCurrentPeriod ? 'visible' : 'invisible'
                    }`}
                >
                    {lastRefreshedTime && (
                        <LastUpdatedTimeForTrips
                            timestamp={lastRefreshedTime}
                            analytics={{
                                category: 'performance_action',
                                period,
                            }}
                        />
                    )}
                </Col>
            </Row>
        </>
    )
}

OverviewBody.propTypes = {
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        quarter: PropTypes.number,
        month: PropTypes.number,
        week: PropTypes.number,
    }).isRequired,
    haveTripsStarted: PropTypes.bool.isRequired,
    onMetricError: PropTypes.func.isRequired,
    isMetricFetchCacheDisabled: PropTypes.bool,
}

export default OverviewBody
