// Copied from Midas/chrome-extension
// Validators for form inputs.
// The format of the validators are the same as react-final-form's.

/**
 * Validate whether the value (string or number) is empty.
 */
export function validateRequired(value, errorMessage = 'Required') {
    if (value === undefined || value === null) {
        return errorMessage
    }

    if (typeof value === 'string' && value.length === 0) {
        return errorMessage
    }

    return null
}

export function validateOptional() {
    return null
}

/**
 * Takes in numeric min value and returns validation function
 *
 * @param {Number} min
 * @returns {Function} validator
 */
export function validateMinValue(min) {
    if (isNaN(min)) {
        throw new Error('Be sure to use a Number for min value')
    }

    return function validate(value) {
        if (value === undefined || value === '') {
            return undefined
        }

        if (parseInt(value, 10) >= min) {
            return undefined
        }

        return `Please enter a number greater than or equal to ${min}.`
    }
}

export function validateNumber(value) {
    return value === '' || value === undefined || !isNaN(value)
        ? undefined
        : 'Please enter a valid number.'
}

/**
 * Validator function for domain. Get's run on each domain. Invoked by react-final-form's validator function (https://final-form.org/docs/react-final-form/types/FieldProps#validate)
 *
 * @param {String} value the value of the field currently being evaluated
 * @param {Object} allValues the other domain name values
 * @param {Object} meta React Final Form FieldStat
 * @returns undefined if valid. Error message if invalid
 */
export const domainNameValidator = (value, allValues, meta) => {
    // Match <any text>.<any text>
    if (value && !/^.+\..+/.test(value)) {
        return 'Please enter a domain in the correct format. Ex: google.com'
    }

    // Make sure that there are no duplicate values
    const otherValues = { ...allValues }
    delete otherValues[meta.name]
    if (value && Object.values(otherValues).includes(value)) {
        return 'All domains must be unique'
    }
}

export const composeValidators = (...validators) => {
    return function validate(value) {
        return validators.reduce((error, validator) => {
            return error || validator(value)
        }, undefined)
    }
}

export const buildingMaxCapacityValidator = composeValidators(
    validateOptional,
    validateNumber,
    validateMinValue(1)
)
