import React, { useEffect, useMemo } from 'react'
import { Row, Col, Button, Container } from 'reactstrap'

import styled from 'styled-components'
import { format } from 'date-fns'
import { RouteComponentProps } from '@reach/router'
import BuildingsList from './BuildingsList'
import useSpaces from '../../hooks/useSpaces'
import useBuildingResource from '../../hooks/useBuildingResource'
import GhostLoader from '../../components/GhostLoader'
import { Header } from '../../components/PageLayout'

const Placeholder = styled.div`
    height: 60vh;
    background: white;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AllBuildings = (routeProps: RouteComponentProps): JSX.Element => {
    const { id: spaceId } = useSpaces()
    const { getBuildings, buildings, isLoading, error } = useBuildingResource({
        spaceId,
    })
    const activeAfterForBuildings = useMemo(
        () => format(new Date(), 'yyyy-MM-dd'),
        []
    )

    useEffect(() => {
        getBuildings({ activeAfter: activeAfterForBuildings })
    }, [getBuildings, activeAfterForBuildings])

    return (
        <Container size="xl">
            <Header
                title="Buildings"
                description="Manage your company's workplace locations"
            />
            <Row>
                <Col>
                    {isLoading && (
                        <Placeholder>
                            <GhostLoader height={25} />
                        </Placeholder>
                    )}

                    {error && (
                        <Placeholder
                            className="d-flex align-items-center justify-content-center"
                            test-attr="try-again"
                        >
                            <div className="text-center">
                                <h5>Unable to load your data</h5>
                                <Button
                                    className="mt-2"
                                    color="primary"
                                    onClick={() =>
                                        getBuildings({
                                            activeAfter: activeAfterForBuildings,
                                        })
                                    }
                                >
                                    Try again
                                </Button>
                            </div>
                        </Placeholder>
                    )}

                    {buildings && !error && (
                        <BuildingsList buildings={buildings} />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default AllBuildings
