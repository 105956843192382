import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    DropdownMenuProps,
} from 'reactstrap'
import { faCog, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'

import colors from '../../styles/colors'

type DropdownItemType = [
    name: string,
    action?: {
        'data-test'?: string
        onClick?: (e?: MouseEvent) => void
        disabled?: boolean
    }
]

type Props = {
    'data-test'?: string
    onClick?: (e: MouseEvent) => void
    dropdownItems?: DropdownItemType[]
    disabled?: boolean
    className?: string
    isOutlineButton?: boolean
    icon?: IconDefinition
    iconProps?: Partial<FontAwesomeIconProps>
    dropdownMenuProps?: Partial<DropdownMenuProps>
}

const MenuDropdownWithActions = ({
    'data-test': dataTest,
    onClick,
    dropdownItems = [],
    disabled = false,
    className,
    isOutlineButton,
    icon = faCog,
    iconProps,
    dropdownMenuProps,
}: Props): JSX.Element => {
    const dropdownClassName = classNames('text-center', className)

    const toggleClassName = classNames({
        'border border-primary': isOutlineButton,
    })
    const iconColor = disabled ? colors.text.disabled : colors.text.muted

    return (
        <UncontrolledDropdown
            data-test={dataTest}
            disabled={disabled}
            className={dropdownClassName}
        >
            <DropdownToggle
                data-test="dropdown-toggle"
                color="Link"
                onClick={onClick}
                className={toggleClassName}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={icon} {...iconProps} color={iconColor} />
            </DropdownToggle>
            <DropdownMenu {...dropdownMenuProps}>
                {dropdownItems.map(([name, action]) => {
                    return (
                        <DropdownItem
                            key={name}
                            data-test={action?.['data-test']}
                            disabled={action?.disabled}
                            onClick={action?.onClick}
                        >
                            {name}
                        </DropdownItem>
                    )
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export { DropdownItemType }
export default MenuDropdownWithActions
