import React from 'react'

import useAnalytics from '../../../hooks/useAnalytics'
import useSpaces from '../../../hooks/useSpaces'
import ModuleFlag from '../../ModuleFlag'
import SideBarLink from '../SideBarLink'

const WorkplacePlannerSideBar = (): JSX.Element => {
    const space = useSpaces()
    const { track } = useAnalytics()

    return (
        <ModuleFlag name="workplacePlanner">
            <strong className="d-block ml-3 py-2">Workplace</strong>

            <SideBarLink
                text="Attendance"
                to={`${space.alias}/workplace/attendance`}
                onClick={() => {
                    track('workplace_check_in_action', {
                        action: 'Click',
                        label: 'workplace_check_in_sidebar',
                    })
                }}
            />

            <SideBarLink
                text="Attendance trends"
                to={`${space.alias}/workplace/trends`}
                onClick={() => {
                    track('check_in_trends_action', {
                        action: 'Click',
                        label: 'check_in_trends_sidebar',
                    })
                }}
            />

            <ModuleFlag name="workplacePlanner.deskManagement">
                <SideBarLink
                    test-attr="desks-link"
                    text="Desks"
                    to={`${space.alias}/workplace/desks`}
                    onClick={() => {
                        track('workplace_desks', {
                            action: 'Click',
                            label: 'workplace_desks_sidebar',
                        })
                    }}
                />
            </ModuleFlag>

            {/* Change the name of the module flag when there are other policies than 'custom check-in requirements' */}
            <ModuleFlag name="workplacePlanner.customCheckInRequirements">
                <SideBarLink
                    test-attr="policies-link"
                    text="Policies"
                    to={`${space.alias}/workplace/policies`}
                    onClick={() => {
                        track('workplace_policies', {
                            action: 'Click',
                            label: 'workplace_policies_sidebar',
                        })
                    }}
                />
            </ModuleFlag>
        </ModuleFlag>
    )
}

export default WorkplacePlannerSideBar
