import { parseISO, startOfDay, isWithinInterval } from 'date-fns'

import { Statistic } from '../../types'

type GetIsFirstStatIncompleteInput = {
    tripsStartData: Statistic | undefined
    isFirstPeriod: boolean
    isCurrentPeriod: boolean
    firstStat: Statistic
}
/**
 * Determines if the first stat in a range of statistics is considered incomplete.
 * Because this needs to handle use cases where a range of statistics could include both
 * first and current period, there is some extra logic needed.
 *
 * If FIRST PERIOD and CURRENT PERIOD
 * Does the current day overlap with the tripsStartDate?
 * tripsStartDate can be a single day (as in the case of viewing daily granularity)
 * or this could be a range of data (as in the case of viewing a week or year of data)
 * If so, then we return "true" because the period that trips begin happens to include the current day
 * and we can't view complete info for the current day. THe user has to check back later.
 *
 * IF JUST CURRENT PERIOD
 * We simply have to check if we're viewing currentPeriod and the first stat's result is undefined.
 * It would be undefined because getChartDataInRange would format current period data to be undefined
 * up until the current day, so if the first stat is undefined, then the first stat must be or include today.
 *
 */

export function getIsFirstStatIncomplete({
    tripsStartData,
    isFirstPeriod,
    isCurrentPeriod,
    firstStat,
}: GetIsFirstStatIncompleteInput): boolean {
    const today = startOfDay(new Date())

    if (isFirstPeriod && isCurrentPeriod && tripsStartData) {
        const { from, until } = tripsStartData.context

        return isWithinInterval(today, {
            start: parseISO(from),
            end: parseISO(until),
        })
    }

    return isCurrentPeriod && firstStat.result === undefined
}
