import {
    getAreModulesEnabled,
    isModuleEnabled,
} from '../../components/ModuleFlag/util'

/**
 * Returns which route to direct the browser based on the enabled space modules
 * @param {*} modules
 */
export const getDefaultRedirectPath = (modules) => {
    const urlPathStart = '/spaces/:spaceAlias'

    // Any of the following are enabled, route to /commute
    const commuteModules = [
        'overview',
        'tripReports',
        'carpoolerReports',
        'activityGraphs',
    ]

    if (getAreModulesEnabled(commuteModules, modules, 'some')) {
        return `${urlPathStart}/commute`
    }

    // `memberManagement` is enabled
    if (isModuleEnabled('memberManagement', modules)) {
        return `${urlPathStart}/carpoolers`
    }

    // Only `workplacePlanner` is enabled
    if (isModuleEnabled('workplacePlanner', modules)) {
        return `${urlPathStart}/workplace`
    }

    // Didn't match any modules, or no modules enabled
    return `${urlPathStart}/notFound`
}
