import React from 'react'
import { ButtonProps } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import srcError from '../../images/error.svg'
import Button from '../Button'
import ImageContainer from '../ImageContainer'

export enum ImageType {
    errorImage = 'error-image',
    errorIcon = 'error-icon',
}

type Props = {
    onTryAgain?: () => void
    title?: string
    message?: string
    imageType?: ImageType
    buttonProps?: ButtonProps
}

/**
 * This can be used for a page or a smaller view component.
 */
const TryAgain = ({
    onTryAgain,
    title,
    message,
    imageType = ImageType.errorImage,
    buttonProps,
}: Props): JSX.Element => {
    return (
        <div
            data-test="try-again"
            className="p-3 d-flex flex-column align-items-center"
        >
            {title}

            {imageType === ImageType.errorImage && (
                <ImageContainer src={srcError} />
            )}
            {imageType === ImageType.errorIcon && (
                <FontAwesomeIcon
                    size="lg"
                    icon={faExclamationCircle}
                    className="mb-2 text-danger"
                />
            )}

            <p className="text-center">{message}</p>

            <div>
                <Button
                    size="md"
                    color="primary"
                    onClick={onTryAgain}
                    label="Try again"
                    data-test="try-again-btn"
                    {...buttonProps}
                />
            </div>
        </div>
    )
}

export default TryAgain
