import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faInfoCircle,
    faSpinner,
    faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'

import { ToastType } from './toast'

const LeftCol = styled.div`
    padding-left: 20px !important;
    padding-right: 0px !important;
`

const MessageCol = styled.div`
    text-align: left;
    padding-left: 15px;
    padding-right: 0px;
    margin-bottom: 0px;
`

const ToastBody = styled(Row)`
    align-items: center;
    min-width: 350px;
    margin-top: 13px;
    margin-bottom: 13px;
    margin-left: 0px;
    margin-right: 0px;

    :hover {
        cursor: default;
    }
`

const typeToIcon = {
    default: faInfoCircle,
    loading: faSpinner,
    info: faInfoCircle,
    connectionError: faInfoCircle,
    error: faExclamationTriangle,
    success: faCheck,
}

type Props = {
    message?: JSX.Element | string | number
    type?: ToastType
    iconClass?: string
}

const ToastContent = ({ message, type, iconClass }: Props): JSX.Element => {
    const icon = useMemo(() => {
        if (!type) {
            return typeToIcon.default
        }

        return typeToIcon[type] || typeToIcon.default
    }, [type])

    return (
        <ToastBody data-test={`toast-${type}`}>
            <LeftCol data-test="toast-left-col">
                <FontAwesomeIcon className={iconClass} icon={icon} />
            </LeftCol>
            <MessageCol data-test="toast-message">{message}</MessageCol>
        </ToastBody>
    )
}

export default ToastContent
