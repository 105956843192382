import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'

import OldTopNavBar from '../../components/OldTopNavBar'
import OldNavBottom from '../../components/OldNavBottom'
import { CalculatedHeightContainer } from '../../components/LayoutWithBreadcrumbs/styled'

const LoggedOut = ({ children }) => {
    return (
        <>
            <OldTopNavBar />

            <Container fluid>
                <Row>
                    <Col>
                        <CalculatedHeightContainer>
                            {children}
                        </CalculatedHeightContainer>
                    </Col>
                </Row>
            </Container>

            <OldNavBottom />
        </>
    )
}

LoggedOut.propTypes = {
    children: PropTypes.node,
}

export default LoggedOut
