import { Link } from '@reach/router'
import { PropTypes } from 'prop-types'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import srcLocations from '../../images/locations.svg'

const NoCarpoolersDeactivated = ({ spaceAlias, companyName }) => {
    const { track } = useAnalytics()

    return (
        <Container size="xl">
            <h2 className="my-3">Deactivated commuters</h2>
            <Row className="justify-content-center pt-5">
                <Col md="5">
                    <img className="mx-auto my-4 d-block " src={srcLocations} />
                    <h4 className="text-center mb-4">
                        No deactivated commuters yet
                    </h4>
                    <p>
                        Scoop manages access to the {companyName} Scoop program
                        with commuter work email verification and trip location
                        requirements.
                    </p>
                    <p>
                        To deactivate commuters no longer with your company,
                        upload a CSV of corresponding email addresses or
                        deactivate them individually from the{' '}
                        <strong>Active commuters</strong> tab.
                    </p>
                    <Link
                        className="btn btn-primary text-decoration-none mt-4 d-block"
                        to="upload"
                        onClick={() => {
                            track('deactivate_carpoolers_action', {
                                action: 'Click',
                                label: 'upload_deactivate_list',
                            })
                        }}
                    >
                        Upload deactivate list
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

NoCarpoolersDeactivated.propTypes = {
    spaceAlias: PropTypes.string,
    companyName: PropTypes.string,
}

export default NoCarpoolersDeactivated
