import { useContext } from 'react'

import { Space, SpacesContext } from '../../providers/SpacesProvider'

/**
 * @returns current space selected from the redux store and its space settings.
 * It returns am empty object if spaces are not fetched.
 */
const useSpaces = (): Space => {
    const context = useContext(SpacesContext) || {}

    return {
        ...context,
    }
}

export default useSpaces
