import {
    CHANGE_CURRENT_SPACE,
    GET_SPACES_REQUEST,
    GET_SPACES_SUCCESS,
    GET_SPACES_FAILURE,
    SPACES_ADD,
} from './types'

export const createChangeCurrentSpace = ({
    alias,
    customers,
    id,
    name,
    publicDisplayName,
    tripsStartDate,
}) => ({
    type: CHANGE_CURRENT_SPACE,
    payload: {
        alias,
        customers,
        id,
        name,
        publicDisplayName,
        tripsStartDate,
    },
})

/**
 * @param {Space[]} spaces
 * @param {String} space.alias
 * @param {Object[]} space.customers - with 'id' key in each object
 * @param {UUID} space.id
 * @param {String} space.name
 * @param {String} space.publicDisplayName
 */
export const createSpacesAdd = (spaces) => ({
    type: SPACES_ADD,
    payload: {
        spaces,
    },
})

export const createGetSpacesRequest = ({ alias }) => ({
    type: GET_SPACES_REQUEST,
    payload: {
        alias,
    },
})

export const createGetSpacesSuccess = () => ({
    type: GET_SPACES_SUCCESS,
    payload: {},
})

export const createGetSpacesFailure = ({ error }) => ({
    type: GET_SPACES_FAILURE,
    error: true,
    payload: error,
})
