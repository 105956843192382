import React from 'react'
import { getYear, subDays, isBefore } from 'date-fns'

import LastUpdatedTimeForTrips from '../../../components/LastUpdatedTime/LastUpdatedTimeForTrips'
import { TooltipWithWarningIcon } from '../DataNotYetReady'

export type YearsData = {
    period: {
        year: number
    }
    extraInfo?: JSX.Element
    extraInfoRight?: JSX.Element
}[]

type Props = {
    arrayOfYears: YearsData
    lastUpdatedTimestamp?: string
    analyticsCategory: string
}

/**
 * Mutates the arrayOfYears to address edge cases where lastUpdatedTimeStamp is either
 * 1. over 48 hours old
 * 2. equal to "null"
 * 3. in the previous year
 *
 * @param arrayOfYears - Should be in reverse chronological order e.g., [ { period: { year: 2020 }}, { period: { year: 2019 }} ].
 * @param lastUpdatedTimestamp - the datetime at which the last data rollup occurred. undefined if the data is loading, null if the data isn't up to date.
 * @param analyticsCategory
 *
 */
function formatWholeYearTimeSeries({
    arrayOfYears,
    lastUpdatedTimestamp,
    analyticsCategory,
}: Props): YearsData {
    const firstYear = { ...arrayOfYears[0] }
    const today = new Date()

    const refreshedAt = lastUpdatedTimestamp
        ? new Date(lastUpdatedTimestamp)
        : new Date()
    const time48HoursAgo = subDays(today, 2)
    const isRefreshedAtBeforeLast48Hours = isBefore(refreshedAt, time48HoursAgo)
    const isRefreshedAtInPreviousYear = getYear(refreshedAt) < getYear(today)

    // Timestamp is over 48 hours old
    if (isRefreshedAtBeforeLast48Hours) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: (
                <TooltipWithWarningIcon content="Trip data has not refreshed in the past 48 hours. Scoop has been notified and we're working on fixing the problem." />
            ),
            extraInfoRight: (
                <LastUpdatedTimeForTrips
                    timestamp={lastUpdatedTimestamp}
                    analytics={{
                        category: analyticsCategory,
                        period: { year: firstYear.period.year },
                    }}
                />
            ),
            isDisabled: true,
        }

        return [firstYearDuplicate, ...arrayOfYears.slice(1)]
    }

    // Data is not the most up-to-date.
    if (lastUpdatedTimestamp === null) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: (
                <TooltipWithWarningIcon
                    content="Trip data isn't the most up-to-date. We're aware of and
                working on fixing the problem."
                />
            ),
            extraInfoRight: (
                <LastUpdatedTimeForTrips
                    timestamp={lastUpdatedTimestamp}
                    analytics={{
                        category: analyticsCategory,
                        period: { year: firstYear.period.year },
                    }}
                />
            ),
            isDisabled: true,
        }

        return [firstYearDuplicate, ...arrayOfYears.slice(1)]
    }

    // Timestamp is in previous year
    if (isRefreshedAtInPreviousYear) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: (
                <TooltipWithWarningIcon content="This report is populating and will be available in less than 48 hours." />
            ),
            extraInfoRight: (
                <LastUpdatedTimeForTrips
                    timestamp={lastUpdatedTimestamp}
                    analytics={{
                        category: analyticsCategory,
                        period: { year: firstYear.period.year },
                    }}
                />
            ),
            isDisabled: true,
        }

        return [firstYearDuplicate, ...arrayOfYears.slice(1)]
    }

    return arrayOfYears
}

export default formatWholeYearTimeSeries
