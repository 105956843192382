import React from 'react'
import { Link } from '@reach/router'
import { Button } from 'reactstrap'

import { TimeSeriesData } from '..'
import ReportsTable from '../../../../components/ReportsTable'

type Props = {
    data: TimeSeriesData
    spaceId: string
    reportsName: string
    modalContent: {
        title: string
        body: string
    }
    analytics: {
        prefix: string
    }
}

const YearlyReports = ({
    data,
    spaceId,
    reportsName,
    modalContent,
    analytics,
}: Props): JSX.Element => {
    return (
        <div data-test="yearly-reports" className="my-5 p-4 bg-white border">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">Yearly Reports</h5>

                <Link to="example/yearly">
                    <Button color="primary" outline>
                        View an example
                    </Button>
                </Link>
            </div>

            <ReportsTable
                analytics={analytics}
                modalContent={modalContent}
                data={data}
                spaceId={spaceId}
                reportsName={reportsName}
            />
        </div>
    )
}

export default YearlyReports
