import '../../styles/ag_grid_theme.scss'

import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community'
import { AgGridColumnProps, AgGridReact, AgGridReactProps } from 'ag-grid-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import MagnifyingGlassWithCityBackground from '../../images/magnifying-glass-with-city-background.svg'
import { SearchIconSpinner } from '../SearchBar/SearchIconSpinner'

type Props = AgGridReactProps & {
    'data-test'?: string
    isLoading: boolean
    onSort?: (columnId: string) => void
    sortBy?: string
    sortOrder?: string
    children?: React.ReactNode
}

const Loader = () => <SearchIconSpinner isLoading={true} />
const NoRows = ({ description }) => (
    <div
        data-test="datatable-no-rows"
        className="d-flex flex-column align-items-center justify-content-center"
    >
        <img
            className="mb-3"
            src={MagnifyingGlassWithCityBackground}
            height="70"
        />
        <h5>{description}</h5>
    </div>
)

/**
 * A component that applies the AG Grid theme and dynamic overrides on that theme.
 * We are setting the min-height of an internal class to be unset when there is data, and 150px when there is no data.
 * This is so that the 'No Data' overlay has space to render, and so that when the the table only has one or two rows, the table doesn't have an arbitrary height of 150px.
 * Reference: https://www.ag-grid.com/react-data-grid/grid-size/#grid-auto-height
 */
const AgGridTheme = styled.div.attrs({ className: 'ag-theme-alpine' })`
    height: ${(props) =>
        props.containerHeight ? props.containerHeight : 'auto'};
    .ag-center-cols-clipper {
        min-height: ${({ hasData }) =>
            hasData ? 'unset !important' : '150px'};
    }

    // Allow dropdown menus to be visible without clipping in a cell by having proper z-indexes and overflow.
    // https://stackoverflow.com/questions/51389069/ag-grid-cell-containing-bootstrap-dropdown-menu-clips-menu
    .ag-row {
        z-index: 0;

        &.ag-row-focus {
            z-index: 1;
        }

        .ag-cell.overflow-visible {
            .ag-cell-value {
                overflow: visible;
            }
        }
    }
`

/**
 * ## How to display a component larger than the cell (i.e. dropdown menu) without clipping.
 *
 * 1. Add `cellClass: 'overflow-visible'` to the column definition
 *    in which you want to display a component bigger than the cell.
 *
 * Check the action column definition in pages/RosterManagement/Table.tsx for details.
 */
const AgGridWrapper = ({
    'data-test': dataTest,
    rowData,
    isLoading,
    columnDefs,
    onSort,
    sortBy,
    sortOrder,
    ...agGridProps
}: Props): JSX.Element => {
    const [agGridApi, setAgGridApi] = useState<GridApi>()

    // Manage the grid's loading state manually because we're using the "client-side" data model so it doesn't do it for us
    // https://www.ag-grid.com/react-grid/client-side-model/
    useEffect(() => {
        if (!agGridApi) {
            return
        }

        if (isLoading) {
            agGridApi.showLoadingOverlay()
            return
        }

        if (rowData) {
            agGridApi.hideOverlay()
            agGridApi.setRowData(rowData)
        }
    }, [isLoading, rowData, agGridApi])

    /**
     * Update columns to reflect the current status of sorting.
     */
    const columnDefsWithSort = useMemo(() => {
        return columnDefs?.map((column: AgGridColumnProps) => ({
            ...column,
            sort: column?.colId === sortBy ? sortOrder : null,
        }))
    }, [columnDefs, sortBy, sortOrder])

    /**
     * After a column header is clicked for sorting,
     * make a request to a server endpoint for sorting.
     */
    const applySort = useCallback(
        (event: SortChangedEvent) => {
            const sortedColumn = event.columnApi
                .getColumnState()
                .find((column) => column.sortIndex === 0) // only one column can be sorted at a time

            if (
                onSort &&
                sortedColumn?.colId &&
                (sortedColumn.sort !== sortOrder ||
                    sortedColumn.colId !== sortBy) // Avoid redundant calls
            ) {
                onSort(sortedColumn.colId)
            }
        },
        [onSort, sortOrder, sortBy]
    )

    return (
        <AgGridTheme
            data-test={dataTest}
            hasData={rowData?.length}
            containerHeight={agGridProps.containerStyle?.height}
        >
            <AgGridReact
                columnDefs={columnDefsWithSort}
                domLayout="autoHeight"
                enableCellTextSelection
                loadingOverlayComponent="loadingOverlayComponent"
                noRowsOverlayComponent="noRowsOverlayComponent"
                onSortChanged={applySort}
                suppressCellSelection
                onGridReady={(params: GridReadyEvent) => {
                    setAgGridApi(params.api)
                }}
                {...agGridProps}
                defaultColDef={{
                    suppressCellFlash: true,
                    ...agGridProps.defaultColDef,
                }}
                frameworkComponents={{
                    loadingOverlayComponent: Loader,
                    noRowsOverlayComponent: NoRows,
                    ...agGridProps.frameworkComponents,
                }}
            />
        </AgGridTheme>
    )
}

export default AgGridWrapper
