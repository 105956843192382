import { useEffect } from 'react'
import { useFetch } from 'use-http'

// TODO ACTUALS: Remove this temporary query parameter after actuals ships. The backend endpoint uses this query param
// as a feature flag in order to control the method for fetching the SpaceWorkAttendanceIndication
const useFetchCheckInById = ({ checkInId, spaceId }) => {
    const { loading, get, response, error } = useFetch(
        `/spaces/${spaceId}/workAttendanceIndications/${checkInId}`
    )

    useEffect(() => {
        if (spaceId && checkInId) {
            get('?asSpaceWorkAttendanceIndication=false')
        }
    }, [spaceId, checkInId, get])

    return {
        loading,
        error: response?.data?.error || error || undefined,
        data: response.ok ? response.data : undefined,
    }
}

export default useFetchCheckInById
