import React from 'react'

import Button from '../../components/Button'
import { modalTypeMap } from '../../components/ReduxModal/helpers'
import toast from '../../components/Toast/toast'
import useModal from '../../hooks/useModal'
import useSpaces from '../../hooks/useSpaces'
import useVaccinationLogDownloadCsvResource from '../../hooks/resources/useVaccinationLogDownloadCsvResource'

const DownloadCsvButton = (): JSX.Element => {
    const openModal = useModal()
    const { id } = useSpaces()
    const { downloadCsv, isLoading } = useVaccinationLogDownloadCsvResource(
        id,
        {
            onSuccess: () => toast.success('Download complete'),
            onError: () =>
                toast.error('CSV download failed. Please try again.'),
        }
    )

    const openModalForCsvDownload = () => {
        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: 'Download vaccination log list',
            description: (
                <>
                    <p>Your CSV download will contain vaccination logs.</p>
                    <p>Your download may take a few minutes to complete.</p>
                </>
            ),
            saveButtonLabel: 'Download',
            savingButtonLabel: 'Preparing...',
            onSave: () => downloadCsv(),
        })
    }

    return (
        <Button
            data-test="download-csv-button"
            block={false}
            size="md"
            onClick={openModalForCsvDownload}
            isLoading={isLoading}
            loadingLabel="Preparing..."
            outline
        >
            Download CSV
        </Button>
    )
}

export default DownloadCsvButton
