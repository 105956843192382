import { RouteComponentProps } from '@reach/router'
import { format } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'

import CheckInsTable from './CheckInsTable'
import CheckInTableFilters from './CheckInTableFilters'
import WorkplaceCheckInsDatepicker from './Datepicker'
import WorkplaceCheckInsDownloadDropdown from './DownloadDropdown'
import Metrics from './Metrics'
import { getDateOfLastOpenTimeCard } from './util'
import useTrackView from '../../hooks/useTrackView'
import useSpaces from '../../hooks/useSpaces'
import useBuildingResource from '../../hooks/useBuildingResource'
import useFetchCheckIns from '../../hooks/useFetchCheckIns'
import useDateFromUrl from '../../hooks/useDateFromUrl'
import SearchBar from '../../components/SearchBar'
import Header from '../../components/PageLayout/Header'
import { dateFormats } from '../../util/date'

const EXCLUDE_WEEKEND_DAYS = true

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const WorkplaceCheckIns = (props: RouteComponentProps): JSX.Element | null => {
    const {
        date: selectedDate,
        hasDate: hasDateQueryParam,
        setDateAndNavigate,
    } = useDateFromUrl()
    const { id: spaceId, tripsStartDate } = useSpaces()

    // Load buildings in advance to avoid showing a loader when the filters are displayed.
    const buildingResource = useBuildingResource({
        spaceId,
    })

    useEffect(() => {
        buildingResource.getBuildings({})
    }, [buildingResource.getBuildings])

    const {
        handleDate,
        handleSort,
        handleSearch,
        handleFilter,
        isLoading,
        isIdle,
        error,

        paginatedData: checkIns,
        totalCount,

        options,
        pagination,
    } = useFetchCheckIns()

    const dateOfFirstCheckIn = tripsStartDate
    const dateOfLastOpenTimeCard = useMemo(
        () => getDateOfLastOpenTimeCard(),
        []
    )

    useTrackView({
        category: 'workplace_check_in_action',
        label: 'workplace_check_in',
    })

    useEffect(() => {
        if (hasDateQueryParam) {
            handleDate(selectedDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDateQueryParam, selectedDate])

    // Set the current date if not specified.
    if (!hasDateQueryParam) {
        setDateAndNavigate(format(new Date(), dateFormats.date))

        // navigate is async so render null
        return null
    }

    return (
        <Container fluid>
            <Header title="Workplace attendance" />

            <Row className="mb-5 mx-0 p-5 bg-white shadow-sm ">
                <Metrics
                    spaceId={spaceId}
                    excludeWeekendDays={EXCLUDE_WEEKEND_DAYS}
                />
            </Row>

            <Row className="mb-3 d-flex justify-content-between">
                <Col sm="5">
                    <SearchBar
                        placeholder="Search by name or day pass ID"
                        onSearch={(query) => {
                            handleSearch(query)
                        }}
                        onClear={() => handleSearch('')}
                        isSearching={isLoading}
                    />
                </Col>

                <Col
                    sm={{ size: 7 }}
                    className="d-flex flex-row justify-content-end"
                >
                    <div className="mr-2">
                        <WorkplaceCheckInsDownloadDropdown
                            selectedDate={selectedDate}
                            selectedDateDataSize={checkIns?.length}
                        />
                    </div>
                    <div className="mr-2">
                        <WorkplaceCheckInsDatepicker
                            excludeWeekendDays={EXCLUDE_WEEKEND_DAYS}
                            selectedDate={selectedDate}
                            onSelectedDateChange={(date) => {
                                setDateAndNavigate(date)
                            }}
                            minDate={dateOfFirstCheckIn}
                            maxDate={dateOfLastOpenTimeCard}
                            isLoading={isLoading}
                        />
                    </div>
                    <CheckInTableFilters
                        buildingResource={buildingResource}
                        activeFilters={options.filter}
                        onChange={handleFilter}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <CheckInsTable
                        onSort={handleSort}
                        sortBy={options.sort.sortBy}
                        sortOrder={options.sort.sortOrder}
                        isLoading={isLoading || isIdle}
                        checkIns={checkIns}
                        totalCount={totalCount}
                        error={error}
                        pagination={pagination}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default WorkplaceCheckIns
