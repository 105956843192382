import { OPEN_MODAL, CLOSE_MODAL } from './types'

const initialState = {
    name: null,
    data: null,
}

function status(state = initialState, { type, payload = {} }) {
    const { name, data } = payload

    switch (type) {
        case OPEN_MODAL:
            return {
                name,
                data,
            }
        case CLOSE_MODAL:
            return initialState
        default:
            return state
    }
}

export default status
