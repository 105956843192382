import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import usePrevious from 'use-previous'

import { graphSectionAnalytics } from '../../../models/analytics'
import { useCommute } from '../../../providers/CommuteProvider'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import useAnalytics from '../../../hooks/useAnalytics'

import {
    getDateRange,
    getDateRangeForWeeklyChart,
    getRangeSizeFromPeriod,
} from '../../../util/date'
import getXAxisLabel from '../../../util/chart/getXAxisLabel'
import { getDateRangeProperties } from '../../../util/analytics'
import generatePathUrl from '../../../util/generatePathUrl'

import LineChart from '../LineChart/index.tsx'

/**
 * @property {string} props.path - The path of an API endpoint to request the metric data.
 */
const LineChartSection = ({
    id,
    title,
    abbreviatedTitle,
    description,
    path,
    analytics,
    radioButtonLabel,
}) => {
    const { track } = useAnalytics()
    const { period } = useCommute()
    const { id: spaceId, tripsStartDate } = useSpaces()
    const [granularity, setGranularity] = useState('weekly') // options are [daily, weekly, monthly]
    const rangeSize = getRangeSizeFromPeriod(period)
    const previousRangeSize = usePrevious(rangeSize)

    const xLabel = getXAxisLabel(rangeSize, granularity)
    const dateRange = useMemo(
        () =>
            granularity === 'weekly'
                ? getDateRangeForWeeklyChart(period)
                : getDateRange(period),
        [granularity, period]
    )
    const urlPath = generatePathUrl(path, {
        fromDate: dateRange.fromDate,
        untilDate: dateRange.untilDate,
        spaceId,
        granularity,
    })

    const trackChartLoading = ({ loadingDuration, hasError }) => {
        track(analytics.category, {
            action: 'Load',
            label: 'chart_load_duration',
            milliseconds_to_load: loadingDuration,
            chart_name: analytics.name,
            loaded: !hasError,
        })
    }

    const trackChartViewing = ({ isVisible, chartState, isDataIncomplete }) => {
        if (!isVisible) {
            return
        }

        track(analytics.category, {
            action: 'View',
            label: analytics.viewLabel,
            chart_state: chartState,
            incomplete_data: isDataIncomplete,
            chart_name: analytics.name,
            granularity,
            ...getDateRangeProperties(period),
        })
    }

    const trackChartTryAgain = () => {
        track(analytics.category, {
            action: 'Click',
            label: 'chart_try_again',
            chart_name: analytics.name,
            granularity,
            ...getDateRangeProperties(period),
        })
    }

    const trackChartClick = ({ isRightClick }) => {
        track(analytics.category, {
            action: 'Click',
            is_right_click: isRightClick,
            label: analytics.clickLabel,
            granularity,
            ...getDateRangeProperties(period),
        })
    }

    const trackTooltipHover = ({ tooltipValue, tooltipDate }) => {
        track(analytics.category, {
            action: 'Hover',
            label: 'chart_tooltip',
            chart_name: analytics.name,
            tooltip_value: tooltipValue,
            tooltip_date: tooltipDate,
            granularity,
            ...getDateRangeProperties(period),
        })
    }

    const trackChartRadioButtonClick = (radioOption) => {
        track(analytics.category, {
            action: 'Click',
            chart_name: analytics.name,
            label: `show_${radioOption}`,
            granularity: radioOption,
            ...getDateRangeProperties(period),
        })
    }

    useEffect(() => {
        if (rangeSize === 'week') {
            setGranularity('daily')
        } else if (rangeSize === 'month') {
            if (previousRangeSize === 'quarter') {
                return
            }

            setGranularity('daily')
        } else if (rangeSize === 'quarter') {
            setGranularity('weekly')
        } else if (rangeSize === 'year') {
            setGranularity('monthly')
        }
    }, [rangeSize, previousRangeSize])

    return (
        <section id={id} className="mb-5">
            <h4>{title}</h4>
            <p className="text-muted mb-4">{description}</p>

            <LineChart
                chartId={id}
                urlPath={urlPath}
                rangeSize={rangeSize}
                granularity={granularity}
                xLabel={xLabel}
                yLabel={title}
                toolTipLabel={abbreviatedTitle || title}
                radioButtonLabel={radioButtonLabel}
                tripsStartDate={tripsStartDate}
                onChange={trackChartLoading}
                onVisible={trackChartViewing}
                onChartHover={trackTooltipHover}
                onChartClick={trackChartClick}
                onGranularityChange={setGranularity}
                onRadioButtonClick={trackChartRadioButtonClick}
                onTryAgain={trackChartTryAgain}
            />
        </section>
    )
}

LineChartSection.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    abbreviatedTitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    radioButtonLabel: PropTypes.string,
    analytics: PropTypes.shape(graphSectionAnalytics).isRequired,
}

export default LineChartSection
