import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap'
import { PhoneNumberUtil, AsYouTypeFormatter } from 'google-libphonenumber'

const PhoneNumber = ({
    onSubmit = () => {},
    onSubmitClick = () => {},
    formatterRegion = 'US',
    initialHelperText = 'Use a mobile number.',
}) => {
    const formatter = useMemo(() => {
        return new AsYouTypeFormatter(formatterRegion)
    }, [formatterRegion])
    const phoneUtil = PhoneNumberUtil.getInstance()
    const [isValidNumber, setIsValidNumber] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [helperText, setHelperText] = useState(initialHelperText)
    const onPhoneNumberChange = (e) => {
        const inputVal = e.target.value
        if (!inputVal) {
            setHelperText(initialHelperText)
            setPhoneNumber('')
        }
        formatter.clear()
        let parsed
        for (const digit of inputVal) {
            if (digit.trim() && !isNaN(digit)) {
                parsed = formatter.inputDigit(digit)
            }
        }
        try {
            const number = phoneUtil.parseAndKeepRawInput(inputVal, 'US')
            if (phoneUtil.isValidNumber(number)) {
                setIsValidNumber(true)
                setHelperText('The phone number is valid.')
            } else {
                setIsValidNumber(false)
                setHelperText('Please enter a valid phone number.')
            }
        } catch (err) {
            // phone number may not be valid.
        }
        if (parsed) {
            setPhoneNumber(parsed)
        } else {
            setIsValidNumber(false)
            setPhoneNumber(inputVal)
        }
    }
    const onFormSubmit = (e) => {
        e.preventDefault()
        onSubmit(phoneNumber)
        setHelperText(initialHelperText)
    }

    return (
        <Form onSubmit={onFormSubmit}>
            <h3>Enter phone number</h3>
            <p>
                Enter your phone number and we&apos;ll send you a one-time code
                to set up 2-factor authentication.
            </p>
            <FormGroup>
                <Label for="phone-number">Phone number</Label>
                <Input
                    valid={isValidNumber}
                    invalid={Boolean(phoneNumber) && !isValidNumber}
                    id="phone-number"
                    type="text"
                    value={phoneNumber}
                    placeholder="(_ _ _) _ _ _-_ _ _ _"
                    onChange={onPhoneNumberChange}
                />
                <FormFeedback valid={isValidNumber}>{helperText}</FormFeedback>
            </FormGroup>
            <br />
            <Button
                type="submit"
                block
                color="primary"
                disabled={!isValidNumber}
                onClick={() => onSubmitClick({ disabled: !isValidNumber })}
            >
                Send code
            </Button>
        </Form>
    )
}

PhoneNumber.propTypes = {
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
    formatterRegion: PropTypes.string,
    initialHelperText: PropTypes.string,
}

export default PhoneNumber
