import React, { useState, useMemo } from 'react'
import ReactMapGL from 'react-map-gl'
import DeckGL, { H3HexagonLayer, PolygonLayer, IconLayer } from 'deck.gl'
import { bool, func, instanceOf, number, shape, string } from 'prop-types'
import { h3SetToMultiPolygon, h3ToGeo, h3IndexesAreNeighbors } from 'h3-js'

import {
    getBoundingBoxFromViewport,
    getH3IndicesForBoundingBox,
    getZoomFromPolygon,
} from './utility'
import iconsSprite from './editIconsSprite.png'
import { useAppConfiguration } from '../../providers/AppConfigurationProvider'
import { buildingPolygonProp } from '../../models/building'

// The map itself is responsive, but a pixel value
// is needed to calculate map projection values in getBoundingBoxFromViewport
export const WIDTH = 740
export const HEIGHT = 457

// rgba format
const ORANGE_TINT = [242, 141, 59, 40]
const TRANSPARENT = [0, 0, 0, 1] // a = 1 makes the hex clickable
const BLACK = [52, 58, 64]
const GRAY_TINT = [52, 58, 64, 49]

const VIEW_PORT_CONFIG = {
    zoom: 18,
    width: WIDTH,
    height: HEIGHT,
}

const iconMapping = {
    minus: {
        x: 0,
        y: 0,
        width: 40,
        height: 40,
    },
    plus: {
        x: 40,
        y: 0,
        width: 40,
        height: 40,
    },
}

const Map = ({
    id,
    isEditable,
    buildingPerimeterPolygon,
    selectedH3Indices,
    onHexUpdate,
    initialCoordinates,
}) => {
    const [hoveredHex, setHoveredHex] = useState()

    /**
     * Calculate the zoom level that fits buildingPerimeterPolygon into the map.
     * initialZoom is undefined if buildingPerimeterPolygon is empty.
     */
    const initialZoom = useMemo(
        () => getZoomFromPolygon(buildingPerimeterPolygon, VIEW_PORT_CONFIG),
        [buildingPerimeterPolygon]
    )

    const { mapboxApiToken } = useAppConfiguration()
    const [viewState, setViewState] = useState({
        ...VIEW_PORT_CONFIG,
        ...initialCoordinates,
        ...(Boolean(initialZoom) && { zoom: initialZoom }),
    })

    const layers = [
        new PolygonLayer({
            data: buildingPerimeterPolygon,
            getPolygon: (d) => d,
            getFillColor: ORANGE_TINT,
            getLineColor: BLACK,
            extruded: false,
            getLineWidth: 1, // only applies if extruded = false
        }),
    ]

    // The h3 Hexagon and Icon layers are only necessary when the map is editable.
    const editingLayers = useMemo(() => {
        const boundingBox = getBoundingBoxFromViewport(viewState)
        const h3Indices = getH3IndicesForBoundingBox(boundingBox)
        const h3IndicesToCoordinates = h3Indices.map((h3Index) => ({
            h3Index,
            coordinates: h3ToGeo(h3Index),
        }))

        const hexagonLayer = new H3HexagonLayer({
            id: 'h3-hexagon-layer',
            data: h3Indices,
            pickable: true,
            filled: true,
            extruded: false,
            getLineWidth: 1, // only applies if extruded = false
            elevationScale: 0,
            getHexagon: (d) => d,
            getLineColor: GRAY_TINT,
            getFillColor: (h3Index) => {
                const isSelected = selectedH3Indices.has(h3Index)

                return isSelected ? ORANGE_TINT : TRANSPARENT
            },
            opacity: 1,
            onClick: onHexUpdate,
            onHover: ({ object }) => {
                setHoveredHex(object)
            },
        })

        const iconLayer = new IconLayer({
            data: h3IndicesToCoordinates,
            pickable: false,

            iconAtlas: iconsSprite,
            iconMapping,
            id: 'icon',
            sizeUnits: 'meters',
            sizeScale: 10,
            getPosition: ({ coordinates }) => {
                const [lat, lng] = coordinates

                // getPosition expects longitude, latitude in this order 🤷
                return [lng, lat]
            },
            getIcon: ({ h3Index }) => {
                if (h3Index !== hoveredHex) {
                    return
                }

                const h3IndicesCopy = new Set(selectedH3Indices)

                if (selectedH3Indices.has(h3Index)) {
                    h3IndicesCopy.delete(h3Index)

                    // Check to make sure that the selected h3 indices do NOT create two distinct polygons.
                    // This is to prevent that removing a polygon does NOT create an island, i.e, a detached polygon
                    const buildingPerimeterMultiPolygon = h3SetToMultiPolygon(
                        Array.from(h3IndicesCopy),
                        true
                    )

                    if (buildingPerimeterMultiPolygon.length > 1) {
                        return
                    }

                    // Now check if removing the selected hex results in a geometry with a hole, then return early
                    // This ensures we only render a 'minus' icon for a hex that is an edge node in the polygon
                    // because the user can only remove edge nodes
                    const buildingPerimeterPolygon =
                        buildingPerimeterMultiPolygon[0]
                    if (
                        !buildingPerimeterPolygon ||
                        buildingPerimeterPolygon.length > 1
                    ) {
                        return
                    }

                    return 'minus'
                }

                h3IndicesCopy.add(h3Index)

                // Check to see if adding the new H3 index creates a hole in the polygon
                const [buildingPerimeterPolygon] = h3SetToMultiPolygon(
                    Array.from(h3IndicesCopy),
                    true
                )

                if (buildingPerimeterPolygon.length > 1) {
                    return
                }

                // Similarly, the user can only add hexes that are adjacent to the polygon,
                // so we only render the 'plus' icon for adjacent nodes
                const isAdjacent = Array.from(h3IndicesCopy).some((h) =>
                    h3IndexesAreNeighbors(h, h3Index)
                )

                if (!isAdjacent) {
                    return
                }

                return 'plus'
            },
        })

        return [hexagonLayer, iconLayer]
    }, [hoveredHex, onHexUpdate, selectedH3Indices, viewState])

    if (isEditable) {
        layers.push(...editingLayers)
    }

    return (
        <DeckGL
            id={id}
            style={{ position: 'relative' }}
            height={HEIGHT}
            width="100%"
            initialViewState={viewState}
            onViewStateChange={({ viewState }) => setViewState(viewState)}
            controller={true}
            layers={layers}
        >
            <ReactMapGL
                mapStyle={'mapbox://styles/mapbox/streets-v11'}
                mapboxApiAccessToken={mapboxApiToken}
            />
        </DeckGL>
    )
}

Map.propTypes = {
    id: string,
    isEditable: bool,
    buildingPerimeterPolygon: buildingPolygonProp,
    initialCoordinates: shape({
        longitude: number.isRequired,
        latitude: number.isRequired,
    }).isRequired,
    selectedH3Indices: instanceOf(Set), // This is a Set of strings
    onHexUpdate: func,
}

export default Map
