export const SESSION_TRANSACTION_ADD = 'AUTH:SESSION_TRANSACTION:ADD'
export const SESSION_TRANSACTION_CLEAR = 'AUTH:SESSION_TRANSACTION:CLEAR'
export const SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST =
    'AUTH:SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE:REQUEST'
export const SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_SUCCESS =
    'AUTH:SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE:SUCCESS'
export const SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_FAILURE =
    'AUTH:SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE:FAILURE'
export const SESSION_TRANSACTION_OKTA_CREATE_REQUEST =
    'AUTH:SESSION_TRANSACTION_OKTA_CREATE:REQUEST'
export const SESSION_TRANSACTION_OKTA_CREATE_SUCCESS =
    'AUTH:SESSION_TRANSACTION_OKTA_CREATE:SUCCESS'
export const SESSION_TRANSACTION_OKTA_CREATE_FAILURE =
    'AUTH:SESSION_TRANSACTION_OKTA_CREATE:FAILURE'
export const SESSION_TRANSACTION_CHECK_REQUEST =
    'AUTH:SESSION_TRANSACTION_CHECK:REQUEST'
export const SESSION_TRANSACTION_CHECK_SUCCESS =
    'AUTH:SESSION_TRANSACTION_CHECK:SUCCESS'
export const SESSION_TRANSACTION_CHECK_FAILURE =
    'AUTH:SESSION_TRANSACTION_CHECK:FAILURE'
export const SESSION_TRANSACTION_CHECK_CLEAR =
    'AUTH:SESSION_TRANSACTION_CHECK:CLEAR'
export const FACTOR_ADD = 'AUTH:FACTOR:ADD'
export const FACTOR_REMOVE = 'AUTH:FACTOR:REMOVE'
export const FACTOR_ENROLL_REQUEST = 'AUTH:FACTOR:ENROLL:REQUEST'
export const FACTOR_ENROLL_SUCCESS = 'AUTH:FACTOR:ENROLL:SUCCESS'
export const FACTOR_ENROLL_FAILURE = 'AUTH:FACTOR:ENROLL:FAILURE'
export const FACTOR_ACTIVATE_REQUEST = 'AUTH:FACTOR:ACTIVATE:REQUEST'
export const FACTOR_ACTIVATE_SUCCESS = 'AUTH:FACTOR:ACTIVATE:SUCCESS'
export const FACTOR_ACTIVATE_FAILURE = 'AUTH:FACTOR:ACTIVATE:FAILURE'
export const FACTOR_RESEND_REQUEST = 'AUTH:FACTOR:RESEND:REQUEST'
export const FACTOR_RESEND_SUCCESS = 'AUTH:FACTOR:RESEND:SUCCESS'
export const FACTOR_RESEND_FAILURE = 'AUTH:FACTOR:RESEND:FAILURE'
export const FACTOR_VERIFY_REQUEST = 'AUTH:FACTOR:VERIFY:REQUEST'
export const FACTOR_VERIFY_SUCCESS = 'AUTH:FACTOR:VERIFY:SUCCESS'
export const FACTOR_VERIFY_FAILURE = 'AUTH:FACTOR:VERIFY:FAILURE'

export const AUTH_ALL_RESET = 'AUTH:ALL:RESET'
