import React, { useCallback } from 'react'
import { RouteComponentProps } from '@reach/router'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledAlert, Row, Col, Container } from 'reactstrap'
import styled from 'styled-components'

import Covid19Policies from './Covid19Policies/index'
import AttendanceGuidelinesPolicy from './AttendanceGuidelinesPolicy'
import ModuleFlag from '../../components/ModuleFlag'
import { Header } from '../../components/PageLayout'
import Policy from '../../components/Policy'
import useVaccinationPoliciesResource, {
    VaccinationPolicies,
    CovidVaccinationPolicies,
    CovidFeatureTypes,
} from '../../hooks/resources/useVaccinationPoliciesResource'
import { useSpaces } from '../../providers/SpacesProvider'
import useModal from '../../hooks/useModal'
import { modalTypeMap } from '../../components/ReduxModal/helpers'

export const defaultVaccinationPolicies: CovidVaccinationPolicies = {
    requireCollection: false,
    requireVaccination: false,
    collectVaccinationProof: false,
    requireTestForUnvaccinated: false,
}

const ListItem = styled.li`
    list-style-type: none;
`

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const WorkplacePolicies = (props: RouteComponentProps): JSX.Element => {
    const { id: spaceId, modules } = useSpaces()

    const isVaccinationPoliciesModuleEnabled =
        modules?.vaccinationPolicies?.isEnabled

    const {
        isLoading,
        isLoadingPolicyChange,
        putVaccinationPolicy,
        postVaccinationPolicy,
        vaccinationPolicies,
        error,
        errorSavingPolicy,
    } = useVaccinationPoliciesResource(
        spaceId,
        // Get vaccination policies only if the module is enabled.
        isVaccinationPoliciesModuleEnabled
    )

    const openModal = useModal()

    const confirmDisablingThePolicy = useCallback(
        async ({
            description,
            title = 'Disable policy',
        }: {
            description: string
            title?: string
        }): Promise<'confirmed' | 'cancel'> => {
            return await new Promise<'confirmed' | 'cancel'>((resolve) => {
                openModal(modalTypeMap.GENERIC_CONFIRMATION, {
                    onSave: async () => {
                        return resolve('confirmed')
                    },
                    onClose: () => {
                        return resolve('cancel')
                    },
                    title,
                    description,
                    saveButtonLabel: 'Disable',
                })
            })
        },
        [openModal]
    )

    /**
     * Returns the confirmation description based on which feature is being disabled
     *    and which corresponding feature is enabled
     */
    const getConfirmationDescription = useCallback(
        (policyToDisable: CovidFeatureTypes): string => {
            if (policyToDisable === 'requireCollection') {
                if (
                    vaccinationPolicies?.covid19?.requireVaccination &&
                    vaccinationPolicies?.covid19?.collectVaccinationProof
                ) {
                    return 'Employees will no longer be required to set their vaccination status, be vaccinated, or upload a photo of their vaccination card to go into the workplace.'
                } else if (
                    vaccinationPolicies?.covid19?.requireVaccination &&
                    !vaccinationPolicies?.covid19?.collectVaccinationProof
                ) {
                    return 'Employees will no longer be required to set their vaccination status or be vaccinated to go into the workplace.'
                } else if (
                    !vaccinationPolicies?.covid19?.requireVaccination &&
                    vaccinationPolicies?.covid19?.collectVaccinationProof
                ) {
                    return 'Employees will no longer be required to upload a photo of their vaccination card to go into the workplace.'
                } else {
                    return 'Employees will no longer be required to set their vaccination status.'
                }
            }

            if (policyToDisable === 'collectVaccinationProof') {
                return 'Employees will no longer be prompted to upload a photo of their vaccination card.'
            }

            if (policyToDisable === 'requireTestForUnvaccinated') {
                return 'Unvaccinated employees will no longer be able to go into the workplace with a weekly negative test.'
            }

            // default to requireVaccination
            return 'Employees will no longer be required to be vaccinated to go into the workplace.'
        },
        [vaccinationPolicies]
    )

    /**
     * Determines what the payload shape should look like based on the current state
     *    and the state we want to update
     */
    const getCovidPolicyPayload = useCallback(
        (
            featureName: CovidFeatureTypes,
            isChecked: boolean
        ): CovidVaccinationPolicies => {
            const currentCovid19VaccinationPolicies =
                vaccinationPolicies?.covid19 || defaultVaccinationPolicies
            const {
                requireVaccination: currentRequireVaccination,
                collectVaccinationProof: currentCollectVaccinationProof,
                requireTestForUnvaccinated: currentrequireTestForUnvaccinated,
            } = currentCovid19VaccinationPolicies

            if (featureName === 'requireVaccination' && isChecked) {
                return {
                    requireCollection: true,
                    requireVaccination: true,
                    requireTestForUnvaccinated: false, // Cannot have both requireVaccination and requireTestForUnvaccinated enabled
                    collectVaccinationProof: currentCollectVaccinationProof,
                }
            }

            if (featureName === 'requireTestForUnvaccinated' && isChecked) {
                return {
                    requireCollection: true,
                    requireVaccination: false,
                    requireTestForUnvaccinated: true, // Cannot have both requireVaccination and requireTestForUnvaccinated enabled
                    collectVaccinationProof: currentCollectVaccinationProof,
                }
            }

            if (featureName === 'collectVaccinationProof' && isChecked) {
                return {
                    requireCollection: true,
                    requireVaccination: currentRequireVaccination,
                    requireTestForUnvaccinated: currentrequireTestForUnvaccinated,
                    collectVaccinationProof: true,
                }
            }

            if (featureName === 'requireCollection' && !isChecked) {
                return {
                    requireCollection: false,
                    requireVaccination: false,
                    requireTestForUnvaccinated: false,
                    collectVaccinationProof: false,
                }
            }

            // Otherwise return the updated toggle value
            const modifiedCovid19PoliciesState = {
                ...currentCovid19VaccinationPolicies,
                [featureName]: isChecked,
            }

            return modifiedCovid19PoliciesState
        },
        [vaccinationPolicies]
    )

    /**
     * Called when a feature toggle of a policy is turned on/off.
     *
     * @param policyName - Policy name. This is passed in case there may be the same feature names in several policies.
     * @param featureName - Feature name of the changed toggle.
     * @param checked - True if the toggle is turned on. False otherwise.
     */
    const handleCovidFeatureChanges = useCallback(
        async (featureName: CovidFeatureTypes, checked: boolean) => {
            const policies = getCovidPolicyPayload(featureName, checked)

            const payload: VaccinationPolicies = {
                covid19: policies,
            }

            if (!checked) {
                const description = getConfirmationDescription(featureName)
                const confirmationResult = await confirmDisablingThePolicy({
                    description,
                })

                if (confirmationResult === 'confirmed') {
                    putVaccinationPolicy({
                        policyId: vaccinationPolicies?.id,
                        payload,
                    })
                }
            } else {
                vaccinationPolicies
                    ? putVaccinationPolicy({
                          policyId: vaccinationPolicies.id,
                          payload,
                      })
                    : postVaccinationPolicy(payload)
            }
        },
        [
            vaccinationPolicies,
            getConfirmationDescription,
            getCovidPolicyPayload,
            confirmDisablingThePolicy,
            postVaccinationPolicy,
            putVaccinationPolicy,
        ]
    )

    return (
        <Container size="xl">
            <Header
                title="Policies"
                description="Manage your company's workplace and employee policies."
            >
                {errorSavingPolicy && (
                    <UncontrolledAlert
                        data-test="save-policy-error"
                        color="error"
                        className="d-flex"
                    >
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="my-auto mr-3 icon"
                        />
                        <div>Changes couldn't be saved. Please try again.</div>
                    </UncontrolledAlert>
                )}
            </Header>
            <Row className="pl-3">
                <Col>
                    <ul className="pl-0">
                        <Covid19Policies
                            error={error}
                            covid19VaccinationPolicies={
                                vaccinationPolicies?.covid19
                            }
                            isLoading={isLoading}
                            isLoadingPolicyChange={isLoadingPolicyChange}
                            onChange={handleCovidFeatureChanges}
                        />

                        <AttendanceGuidelinesPolicy />
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default WorkplacePolicies
