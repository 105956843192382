import { saveAs } from 'file-saver'
import moment from 'moment'
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects'

import toast from '../../components/Toast/toast.tsx'
import {
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST,
    createCarpoolerDeactivationsDownloadFailure,
    createCarpoolerDeactivationsDownloadSuccess,
} from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'

export const getUri = (spaceId) => `/spaces/${spaceId}/carpoolerDeactivations`

export function* downloadDeactivatedCarpoolersCsv() {
    const dateString = moment().format('YYYY-MM-DD-HHMMSS')
    const { track } = yield getContext('analytics')
    let blobToSave
    try {
        const { id: spaceId } = yield select(selectCurrentSpace)
        if (!spaceId) {
            throw new Error(
                `Space ID is required to download deactivated commuter csv.`
            )
        }
        const cosmosApi = yield getContext('cosmosApi')
        const { blob } = yield call([cosmosApi, 'call'], getUri(spaceId), {
            headers: { accept: 'text/csv' },
        })
        blobToSave = blob
    } catch (error) {
        toast.error('Unable to prepare your CSV. Please try again.')
        yield put(createCarpoolerDeactivationsDownloadFailure({ error }))
        track('deactivate_carpoolers_action', {
            action: 'View',
            label: 'modal_CSV_unable_to_prepare',
        })
    }

    try {
        const fileName = `DeactivatedCarpoolers_${dateString}.csv`
        yield call(saveAs, blobToSave, fileName)
        toast.success('Successfully downloaded CSV')
        yield put(createCarpoolerDeactivationsDownloadSuccess())
    } catch (error) {
        toast.error('CSV download failed. Please try again.')
        yield put(createCarpoolerDeactivationsDownloadFailure({ error }))
        track('deactivate_carpoolers_action', {
            action: 'View',
            label: 'modal_CSV_download_failed',
        })
    }
}

export default function* watchDownloadDeactivatedCarpoolersCsv() {
    yield takeLatest(
        CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST,
        downloadDeactivatedCarpoolersCsv
    )
}
