import { sortBy } from 'lodash'

import {
    getSeatAssignmentType,
    getSeatAssignments,
} from '../pods/getBuildingsFromPods'

import {
    Building,
    BuildingByFloorAndNeighbhorhood,
    EmployeeExperienceConfiguration,
    EmployeeExperienceConfigurationTypes,
    NeighbhoodWithSeats,
} from '../../types'

export const sortBuildingsFloorsNeighborhoodsAndDesks = (
    buildings: BuildingByFloorAndNeighbhorhood[]
): BuildingByFloorAndNeighbhorhood[] => {
    for (const building of buildings) {
        for (const floor of building.floors) {
            for (const neighborhood of floor.neighborhoods) {
                // Sort desks alphabetically by seat label
                neighborhood.seats = sortBy(neighborhood.seats, 'label')
            }
            // Sort neighborhoods alphabetically by name
            floor.neighborhoods = sortBy(floor.neighborhoods, 'name')
        }
        // Sort floors in ascending order
        building.floors = sortBy(building.floors, 'floorNumber')
    }

    // Sort buildings alphabetically by name
    return sortBy(buildings, 'name')
}

/**
 *
 * Transforms a building's pods into neighborhoods with seats
 */
export function transformBuildingsWithNeighborhoodSeats(
    buildings: Building[]
): BuildingByFloorAndNeighbhorhood[] {
    const transformedBuildings = buildings.map((building) => {
        const floorsWithNeighborhoods = building.floors.map((floor) => {
            const neighborhoods = floor.pods.reduce(
                (neighborhoodsFromPods: NeighbhoodWithSeats[], pod) => {
                    const neighborhood = {
                        // use neighborhood name + floor id to identify neighborhoods, as only
                        // neighborhood name is returned from buildings request
                        id: pod.neighborhoodName + floor.id,
                        name: pod.neighborhoodName || '',
                        seats: pod.seatLabels.map((label) => {
                            // return seat label along with its assignment
                            return {
                                label,
                                assignmentType: getSeatAssignmentType(pod),
                                assignments: getSeatAssignments(pod),
                            }
                        }),
                    }

                    // find neighborhood for pod in list of neighborhoods
                    const existingNeighborhood = neighborhoodsFromPods.find(
                        ({ id }) => id === neighborhood.id
                    )

                    // If neighborhood is already on floor, add this pod's seats to the neighborhood
                    if (existingNeighborhood) {
                        existingNeighborhood.seats = [
                            ...existingNeighborhood.seats,
                            ...neighborhood.seats,
                        ]
                    } else {
                        // Otherwise, create a new neighborhood with these seats
                        neighborhoodsFromPods.push(neighborhood)
                    }

                    return neighborhoodsFromPods
                },
                []
            )

            return {
                ...floor,
                neighborhoods,
            }
        })

        return {
            ...building,
            floors: floorsWithNeighborhoods,
        }
    })

    return sortBuildingsFloorsNeighborhoodsAndDesks(transformedBuildings)
}

/**
 * Takes a building and an employeeExperienceConfiguration and returns its employeeExperienceConfiguration of that type
 *
 */
const geteEployeeExperienceConfigurationByType = (
    building: BuildingByFloorAndNeighbhorhood,
    type: EmployeeExperienceConfigurationTypes
): EmployeeExperienceConfiguration | undefined => {
    return building.employeeExperienceConfigurations.find(
        (employeeExperienceConfiguration) =>
            employeeExperienceConfiguration.type === type
    )
}

/**
 * Takes a building and returns a hashed map for each employeeExperienceConfiguration type
 *
 */
export function getEmployeeExperienceConfigurationsForBuilding(
    building: BuildingByFloorAndNeighbhorhood
): Record<
    EmployeeExperienceConfigurationTypes,
    EmployeeExperienceConfiguration
> {
    const checkInCapacity = geteEployeeExperienceConfigurationByType(
        building,
        EmployeeExperienceConfigurationTypes.checkInCapacity
    )
    const recurringCheckInRequirementOverrides = geteEployeeExperienceConfigurationByType(
        building,
        EmployeeExperienceConfigurationTypes.recurringCheckInRequirementOverrides
    )

    const configurations: Record<string, EmployeeExperienceConfiguration> = {}

    if (checkInCapacity) {
        configurations.checkInCapacity = checkInCapacity
    }

    if (recurringCheckInRequirementOverrides) {
        configurations.recurringCheckInRequirementOverrides = recurringCheckInRequirementOverrides
    }

    return configurations
}

/**
 *
 *  Returns the current check in capacity for a building
 *  Will return 0 if there is no capacity configuration set
 */
export function getCapacityFromBuilding(
    building: BuildingByFloorAndNeighbhorhood
): number {
    const value = getEmployeeExperienceConfigurationsForBuilding(building)
        .checkInCapacity?.value

    if (value && typeof value === 'number') {
        return value
    }

    return 0
}

/**
 * Calculates the sum of seats for a building
 */
export function getAggregateSeatCountForBuilding(
    building: BuildingByFloorAndNeighbhorhood
): number {
    return building.floors
        .map((floor) =>
            floor.neighborhoods
                .map((neighborhood) => neighborhood.seats.length)
                .reduce((total, count) => total + count, 0)
        )
        .reduce((total, count) => total + count, 0)
}

/**
 * Calculates the sum of floors for all buildings
 */
export function getAggregateFloorCountFromBuildings(
    buildings: Building[]
): number {
    return buildings
        .map((building) => building.floors.length)
        .reduce((accum, count) => accum + count, 0)
}
