import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Collapse } from 'reactstrap'

import Count from '../Count'
import CollapsibleButton from '../CollapsibleButton'

const HeadingRow = styled(Row)`
    button,
    .count {
        font-size: 13px;
    }
`

type CountProps = {
    value: number
    label: string
    pluralLabel?: string
}

type Props = {
    counts?: CountProps[]
    header: string
    secondaryButton?: React.ReactNode
    children?: JSX.Element | JSX.Element[]
}

const CountWithSeparator = ({
    count: { value, label, pluralLabel },
    isLastItem,
}: {
    count: CountProps
    isLastItem: boolean
}) => {
    return (
        <>
            <Count
                count={value}
                label={label}
                pluralLabel={pluralLabel || `${label}s`}
                className="text-muted"
            />
            {!isLastItem && <span className="text-muted px-1">•</span>}
        </>
    )
}

const CollapsingRow = ({
    counts = [],
    header,
    secondaryButton,
    children,
}: Props) => {
    const [isExpanded, updateExpanded] = useState(false)
    const handleToggle = () => {
        updateExpanded((isExpanded) => !isExpanded)
    }

    return (
        <div className="align-items-center" data-test="collapsing-row">
            <HeadingRow className="my-2">
                <Col md="4" xs="6">
                    <h6 data-test="header" className="my-0 font-weight-normal">
                        {header}
                    </h6>
                    <Row>
                        <Col
                            className="d-flex align-items-center"
                            data-test="counts"
                        >
                            {counts.map((count, i) => (
                                <CountWithSeparator
                                    key={`${header}-count-${i}`}
                                    count={count}
                                    isLastItem={i === counts.length - 1}
                                />
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col md="8" xs="6">
                    <Row
                        data-test="collapsing-row-button"
                        className="justify-content-end flex-nowrap"
                    >
                        {secondaryButton}
                        <CollapsibleButton
                            onClick={handleToggle}
                            isExpanded={isExpanded}
                            className="text-muted"
                            iconProps={{ size: 'lg' }}
                        />
                    </Row>
                </Col>
            </HeadingRow>
            <Collapse isOpen={isExpanded} data-test="collapse">
                {children}
            </Collapse>
        </div>
    )
}

export default CollapsingRow
