import React from 'react'
import {
    Button,
    DropdownItem,
    DropdownMenu,
    Spinner,
    UncontrolledButtonDropdown,
} from 'reactstrap'

import { add, format, parseISO, sub, startOfMonth } from 'date-fns'
import DropdownToggle from '../../../components/DropdownToggle'
import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import useAnalytics from '../../../hooks/useAnalytics'
import useModal from '../../../hooks/useModal'
import useFetchCheckInsDownloadCSVResource from '../../../hooks/resources/useFetchCheckInsDownloadCSVResource'
import useSpaces from '../../../hooks/useSpaces/index'

type Props = {
    selectedDate: string // YYYY-MM-DD
    selectedDateDataSize?: number
}

const WorkplaceCheckInsDownloadDropdown = ({
    selectedDate,
    selectedDateDataSize,
}: Props): JSX.Element => {
    const openModal = useModal()
    const { track } = useAnalytics()
    const { tripsStartDate } = useSpaces()
    const {
        loading: checkInsCSVDownloading,
        download: fetchCheckInsDownload,
    } = useFetchCheckInsDownloadCSVResource()

    const startOfMonthThreeMonthsAgo = startOfMonth(
        sub(new Date(), { months: 3 })
    )

    function trackDownload({
        isDownloadConfirmed,
        reportChoice,
        minDate,
        maxDate,
    }: {
        isDownloadConfirmed: boolean
        reportChoice: string
        minDate?: string
        maxDate?: string
    }) {
        track('workplace_check_in_action', {
            action: 'Click',
            label: 'download_report_choice',
            granularity: reportChoice,
            date_range_start: minDate,
            date_range_end: maxDate,
            confirm_download: isDownloadConfirmed,
        })
    }

    function openSingleDayDownloadModal() {
        const reportChoice = 'current_day'
        const currentDateFormatted = parseISO(selectedDate).toLocaleDateString(
            'default'
        )
        const minDate = selectedDate
        const maxDate = format(
            add(parseISO(selectedDate), { days: 1 }),
            'yyyy-MM-dd'
        )

        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: 'Download workplace attendance data',
            description: (
                <>
                    <p>
                        Your CSV download will contain workplace attendance data
                        for {currentDateFormatted}.
                    </p>
                    <p>Your download may take a few minutes to complete.</p>
                </>
            ),
            saveButtonLabel: 'Download',
            savingButtonLabel: 'Preparing...',
            onSave: async () => {
                await fetchCheckInsDownload({ reportChoice, minDate, maxDate })

                trackDownload({
                    isDownloadConfirmed: true,
                    reportChoice,
                    minDate,
                    maxDate,
                })
            },
            onCancel: () => {
                trackDownload({
                    isDownloadConfirmed: false,
                    reportChoice,
                    minDate,
                    maxDate,
                })
            },
        })
    }

    function openPastThreeMonthsDownloadModal() {
        const reportChoice = 'past_three_months'

        const minDate = format(startOfMonthThreeMonthsAgo, 'yyyy-MM-dd')

        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: 'Download workplace attendance data',
            description: (
                <>
                    <p>
                        Your CSV download will contain workplace attendance data
                        the last three months (since{' '}
                        {format(startOfMonthThreeMonthsAgo, 'LLL do')}).
                    </p>
                    <p>Your download may take a few minutes to complete.</p>
                </>
            ),
            saveButtonLabel: 'Download',
            savingButtonLabel: 'Preparing...',
            onSave: async () => {
                await fetchCheckInsDownload({
                    reportChoice,
                    minDate,
                    maxDate: format(new Date(), 'yyyy-MM-dd'),
                })

                trackDownload({
                    isDownloadConfirmed: true,
                    reportChoice,
                })
            },
            onCancel: () => {
                trackDownload({ isDownloadConfirmed: false, reportChoice })
            },
        })
    }

    function openAllTimeDownloadModal() {
        const reportChoice = 'all_time'

        openModal(modalTypeMap.GENERIC_CONFIRMATION, {
            title: 'Download workplace attendance data',
            description: (
                <>
                    <p>
                        Your CSV download will contain all workplace check-ins
                        from your organization.
                    </p>
                    <p>Your download may take a few minutes to complete.</p>
                </>
            ),
            saveButtonLabel: 'Download',
            savingButtonLabel: 'Preparing...',
            onSave: async () => {
                await fetchCheckInsDownload({
                    reportChoice,
                    minDate: tripsStartDate,
                    maxDate: format(new Date(), 'yyyy-MM-dd'),
                })

                trackDownload({
                    isDownloadConfirmed: true,
                    reportChoice,
                })
            },
            onCancel: () => {
                trackDownload({ isDownloadConfirmed: false, reportChoice })
            },
        })
    }

    const isCurrentDayDisabled = !selectedDateDataSize

    return (
        <UncontrolledButtonDropdown group={false}>
            {checkInsCSVDownloading ? (
                <Button className="w-100" color="primary" disabled>
                    <Spinner size="sm" color="white" /> Preparing...
                </Button>
            ) : (
                <DropdownToggle
                    data-test="download-csv-toggle"
                    onClick={() => {
                        track('workplace_check_in_action', {
                            action: 'Click',
                            label: 'download_report_dropdown',
                            current_date: selectedDate,
                        })
                    }}
                    hasCaret={false}
                    className="w-100"
                    color="primary"
                >
                    Download CSV
                </DropdownToggle>
            )}
            <DropdownMenu>
                <DropdownItem
                    data-test="current-day"
                    onClick={openSingleDayDownloadModal}
                    disabled={isCurrentDayDisabled}
                >
                    Current day
                </DropdownItem>
                <DropdownItem
                    data-test="past-3-months"
                    onClick={openPastThreeMonthsDownloadModal}
                >
                    Past 3 months (everything since{' '}
                    {format(startOfMonthThreeMonthsAgo, 'LLL do')} )
                </DropdownItem>
                <DropdownItem
                    data-test="all-time"
                    onClick={openAllTimeDownloadModal}
                >
                    All time
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )
}

export default WorkplaceCheckInsDownloadDropdown
