export const USER_ADD = 'USER:ADD'
export const USER_REMOVE = 'USER:REMOVE'
export const USER_CREATE_OKTA_REQUEST = 'USER:CREATE_OKTA:REQUEST'
export const USER_CREATE_TOKEN_PASSWORD_REQUEST =
    'USER:CREATE_TOKEN_PASSWORD:REQUEST'
export const USER_CREATE_SUCCESS = 'USER:CREATE:SUCCESS'
export const USER_CREATE_FAILURE = 'USER:CREATE:FAILURE'

export const USER_SIGN_OUT_REQUEST = 'USER:SIGN_OUT:REQUEST'
export const USER_SIGN_OUT_SUCCESS = 'USER:SIGN_OUT:SUCCESS'
export const USER_SIGN_OUT_FAILURE = 'USER:SIGN_OUT:FAILURE'
