import styled, { keyframes, css } from 'styled-components'

const backgroundChange = keyframes`
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
`
const animation = () =>
    css`
        ${backgroundChange} 1.5s ease infinite;
    `

const GhostLoader = styled.div.attrs((props) => ({
    width: props.width ? `${props.width}px` : '100%',
    height: props.height || 50,
}))`
    width: ${(props) => props.width};
    height: ${(props) => props.height}px;
    background: linear-gradient(
        0.75turn,
        /* color of bootstrap theme 'grey' */ rgba(180, 186, 184, 0.2) 0,
        rgb(180, 186, 184) 50%,
        rgba(180, 186, 184, 0.2) 100%
    );
    background-size: 400% 400%;
    background-repeat: no-repeat;

    animation: ${animation};
`

export default GhostLoader
