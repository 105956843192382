import React, { useMemo, useContext } from 'react'
import { Row, Col, Container } from 'reactstrap'
import { RouteComponentProps } from '@reach/router'

import WelcomePage from './WelcomePage'
import { transformBuildingsWithNeighborhoodSeats } from '../../util/buildings'
import { Header } from '../../components/PageLayout'
import BuildingList from '../../components/Desks/BuildingList'
import LinkButton from '../../components/LinkButton'
import { DesksContext } from '../../providers/DesksContextProvider'
import SpinnerFullPage from '../../components/SpinnerFullPage'

const Desks = (props: RouteComponentProps) => {
    const {
        isBuildingsLoading,
        buildings,
        totalFloors,
        updateBuildingCapacity,
    } = useContext(DesksContext)

    const buildingsFromPods = useMemo(() => {
        if (buildings) {
            return transformBuildingsWithNeighborhoodSeats(buildings)
        }

        return []
    }, [buildings])

    if (isBuildingsLoading) {
        return <SpinnerFullPage className="my-5" />
    }

    if (totalFloors === 0) {
        return <WelcomePage />
    }

    return (
        <Container size="xl">
            <Row className="mt-3">
                <Col>
                    <Header title="Desks" />
                </Col>
                <Col className="align-self-center">
                    <div className="d-flex justify-content-end">
                        <LinkButton to="upload" isBordered>
                            Upload CSV
                        </LinkButton>
                    </div>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <BuildingList
                        onCapacityUpdated={updateBuildingCapacity}
                        buildings={buildingsFromPods}
                        isUploadMode={false}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Desks
