import React from 'react'
import PropTypes from 'prop-types'

import Content from './Content'

const CustomToolTip = ({ active, payload, ...rest }) => {
    if (!active || !payload?.length) {
        return null
    }

    return <Content {...rest} payload={payload} />
}

CustomToolTip.propTypes = {
    dataLabel: PropTypes.string.isRequired,
    granularity: PropTypes.string.isRequired,
    active: PropTypes.bool,
    dateFormatter: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                result: PropTypes.number,
                context: PropTypes.object,
            }),
        })
    ),
    onChartHover: PropTypes.func.isRequired,
}

export default CustomToolTip
