import { h3SetToMultiPolygon } from 'h3-js'
import { useNavigate } from '@reach/router'

import useLogger from 'hooks/useLogger/index.ts'
import useBuildingResource from '../../../../hooks/useBuildingResource/index.ts'
import useEmployeeExperienceResource from '../../../../hooks/useEmployeeExperienceResource/index.ts'
import useAsync from '../../../../hooks/useAsync'

import toast from '../../../../components/Toast/toast.tsx'

class BuildingCreationError extends Error {
    constructor({ name, message, responseError }, ...args) {
        super(...args)

        this.name = name
        this.message = message
        this.responseError = responseError
    }
}

/**
 * This hook exports a form submit handler and form submitting state.
 * Note that submitForm can return an object which will be
 * used by Final Form as a submit-error
 *
 * @param {String} spaceId
 *
 */
const useAddBuilding = (spaceId) => {
    const navigate = useNavigate()
    const logger = useLogger()
    const { isLoading: buildingIsLoading, exec } = useAsync()

    const {
        createBuilding,
        response: createBuildingResponse,
    } = useBuildingResource({ spaceId })

    const {
        createOrUpdateExperience,
        response: experienceResponse,
    } = useEmployeeExperienceResource({
        spaceId,
        type: 'checkInCapacity',
    })

    async function submitForm({ address, name, maxCapacity, h3Indices }) {
        // Convert h3 index set to multipolygon
        const [buildingPerimeterPolygon] = h3SetToMultiPolygon(
            Array.from(h3Indices),
            true
        )

        const capacity = maxCapacity ? parseInt(maxCapacity, 10) : maxCapacity

        const { building, error } = await exec(
            createBuildingAndCapacity({
                name,
                address,
                capacity,
                buildingPerimeterPolygon,
            })
        )

        if (error) {
            if (error.name === 'BuildingNameConflictError') {
                // the submit handler can return a field-level submission error
                // that is consumed by the Form and Fields
                // https://final-form.org/docs/react-final-form/examples/submission-errors
                return {
                    name: error.message,
                }
            }

            if (error.name === 'CapacityEnforcementError') {
                toast.error(
                    'The building was created successfully, however the Daily check-in limit could not be applied.'
                )
            } else {
                toast.error(error.message)
            }

            logger.error(error.message, { error, building })
        }

        if (building) {
            await navigate('all')
        }
    }

    async function createBuildingAndCapacity({
        name,
        address,
        capacity,
        buildingPerimeterPolygon,
    }) {
        let building
        try {
            building = await tryCreatingNewBuilding({
                name,
                address,
                capacity,
                buildingPerimeterPolygon,
            })

            if (capacity) {
                await createOrUpdateExperienceCapacity({
                    buildingId: building.id,
                    capacity,
                })
            }
        } catch (error) {
            return {
                building,
                error,
            }
        }

        return { building, error: null }
    }

    async function createOrUpdateExperienceCapacity({ buildingId, capacity }) {
        const eligibilityProfile = {
            building: { id: buildingId },
        }

        const responseData = await createOrUpdateExperience({
            eligibilityProfile,
            value: capacity,
        })

        if (experienceResponse.ok) {
            return responseData
        } else {
            const responseError = responseData?.error
            throw new BuildingCreationError({
                responseError,
                name: 'CapacityEnforcementError',
                message: `We could not enforce the capacity for building ${buildingId}`,
            })
        }
    }

    async function tryCreatingNewBuilding({
        name,
        capacity,
        buildingPerimeterPolygon,
        address,
    }) {
        const responseData = await createBuilding({
            name,
            capacity,
            coordinates: buildingPerimeterPolygon,
            address,
        })

        if (createBuildingResponse.ok) {
            return responseData
        } else {
            const responseError = responseData?.error

            if (responseError?.errorId === 'BUILDING_NAME_CONFLICT') {
                throw new BuildingCreationError({
                    responseError,
                    name: 'BuildingNameConflictError',
                    message:
                        'The building name already exists. Please enter a unique name.',
                })
            }

            if (responseError?.errorId === 'BUILDING_GEOMETRY_OVERLAP') {
                throw new BuildingCreationError({
                    responseError,
                    name: 'BuildingGeometryOverlapError',
                    message:
                        'This building’s footprint overlaps with another saved building. Please update your building and try again.',
                })
            }

            throw new BuildingCreationError({
                responseError,
                name: 'BuildingCreationError',
                message:
                    'We could not save your information. Please try again.',
            })
        }
    }

    return {
        submitForm,
        isFormSubmitting: buildingIsLoading,
    }
}

export default useAddBuilding
