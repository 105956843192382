import React from 'react'
import { Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import PolicyFeatureRow from './PolicyFeatureRow'

const PolicyError = (): JSX.Element => {
    return (
        <PolicyFeatureRow data-test="policy-error">
            <Col className="pl-0 text-danger py-1">
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="mr-3"
                    size="lg"
                />
                Policy couldn't be loaded. Please reload the page.
            </Col>
        </PolicyFeatureRow>
    )
}

export default PolicyError
