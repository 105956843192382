import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

import FeatureFlagsPopoverLink from './FeatureFlagPopoverLink/index.tsx'
import useEnvironment from '../../devTools/useEnvironment/index.ts'
import useAnalytics from '../../hooks/useAnalytics'

const NavBottom = () => {
    const { isLocal, isStaging } = useEnvironment()
    const { track } = useAnalytics()

    return (
        <Nav vertical>
            <NavItem>
                <NavLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.customers.scoopcommute.com/"
                    onClick={() =>
                        track('footer_action', {
                            action: 'Click',
                            label: 'footer_support',
                        })
                    }
                >
                    Support
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.scoopcommute.com"
                    onClick={() =>
                        track('footer_action', {
                            action: 'Click',
                            label: 'footer_scoopcommute',
                        })
                    }
                >
                    scoopcommute.com
                </NavLink>
            </NavItem>

            {(isLocal || isStaging) && <FeatureFlagsPopoverLink />}
        </Nav>
    )
}

export default NavBottom
