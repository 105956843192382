import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

const CloseButtonWrapper = styled(Button)`
    text-shadow: none !important;
    line-height: 0;
    float: none;
    padding-right: 20px !important;
`

type Props = {
    closeToast?: () => void
    closeButtonClass?: string
}

const CloseButton = ({ closeToast, closeButtonClass }: Props): JSX.Element => (
    <CloseButtonWrapper onClick={closeToast} color={'link'}>
        <FontAwesomeIcon
            className={closeButtonClass}
            icon={faTimes}
            style={{ fontSize: '1.5em' }}
        />
    </CloseButtonWrapper>
)

export default CloseButton
