import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import LineChartSection from '../../components/Chart/LineChartSection'
import { Content } from '../../components/PageLayout'
import withReloadable from '../../hocs/withReloadable'
import useAnalytics from '../../hooks/useAnalytics'
import { useCommute } from '../../providers/CommuteProvider'
import { getDateRangeProperties } from '../../util/analytics'

const TripActivity = () => {
    const { track } = useAnalytics()
    const { period } = useCommute()

    useEffect(() => {
        track('trip_activity_action', {
            action: 'View',
            label: 'trip_activity',
            ...getDateRangeProperties(period, { includeRangeSize: true }),
        })
    }, [track, period])

    return (
        <Content hasBackground={false} hasBorder={false}>
            <Row>
                <Col className="px-0">
                    <LineChartSection
                        id="one-way-trips"
                        title="One-way trips"
                        description={`
                    Number of individual trips taken. Each commuter can take up to
                    two one-way trips per day.
                `}
                        path={`/spaces/:spaceId/metrics/tripsTaken\\?from=:fromDate&until=:untilDate&statisticType=sum&calculationInterval=:granularity`}
                        analytics={{
                            category: 'trip_activity_action',
                            name: 'one_way_trips',
                            clickLabel: 'one_way_trips_click',
                            viewLabel: 'one_way_trips_view',
                        }}
                    />

                    <LineChartSection
                        id="average-trips"
                        title="Average trips per active commuter"
                        abbreviatedTitle="Trips per active commuter"
                        description={`
                   Average number of one-way trips divided by the number of active commuters in your program.
                `}
                        path={
                            '/spaces/:spaceId/metrics/tripsTaken\\?from=:fromDate&until=:untilDate&statisticType=mean&groupBy=activeCarpooler&calculationInterval=:granularity'
                        }
                        radioButtonLabel="Average"
                        analytics={{
                            category: 'trip_activity_action',
                            name: 'avg_trips_per_carpooler',
                            clickLabel: 'avg_trips_per_carpooler_click',
                            viewLabel: 'avg_trips_per_carpooler_view',
                        }}
                    />
                </Col>
            </Row>
        </Content>
    )
}

export default withReloadable(TripActivity)
