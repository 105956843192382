import React, { useMemo, useCallback } from 'react'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

import RangeSizeDropdown from '../RangeSizeDropdown'
import DateRangeDropdown from '../DateRangeDropdown'

import useAnalytics from '../../../hooks/useAnalytics'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'

import {
    isDateRangeOverlapped,
    getRangeSizeFromPeriod,
} from '../../../util/date'
import {
    getDateRangeProperties,
    prefixTermToObjectKeys,
} from '../../../util/analytics'
import { Header } from '../../../components/PageLayout'

const CommuteHeader = ({ title, period, analyticsCategory }) => {
    const { track } = useAnalytics()
    const { tripsStartDate } = useSpaces()
    const formattedTripsStartDate = useMemo(
        () => moment(tripsStartDate).format('MM/DD/YY'),
        [tripsStartDate]
    )

    const isPreviousDateSelectionDisabled = useMemo(() => {
        const now = moment().format('YYYY-MM-DD')

        return !isDateRangeOverlapped({
            earliestDate: tripsStartDate,
            latestDate: now,
            offset: -1,
            period,
        })
    }, [tripsStartDate, period])

    const isProgramStartDateVisible =
        tripsStartDate && isPreviousDateSelectionDisabled

    // <RangeSizeDropdown> UI handlers
    const handleRangeSizeDropdownToggleClick = useCallback(
        (period) => {
            const properties = getDateRangeProperties(period)

            track(analyticsCategory, {
                action: 'Click',
                label: 'range_size_drop_down',
                ...properties,
            })
        },
        [analyticsCategory, track]
    )

    const handleRangeSizeDropdownItemClick = useCallback(
        (previousRangeSize, currentRangeSize, period) => {
            const rangeSizeProperties = {
                previous_range_size: previousRangeSize,
                current_range_size: currentRangeSize,
            }
            const dateRangeProperties = getDateRangeProperties(period, {
                includeRangeSize: false,
            })

            track(analyticsCategory, {
                action: 'Click',
                label: 'range_size_select',
                ...rangeSizeProperties,
                ...dateRangeProperties,
            })
        },
        [analyticsCategory, track]
    )

    // <DateRangeDropdowns> UI handlers
    const handleDateRangeDropdownToggleClick = useCallback(
        (period) => {
            const properties = getDateRangeProperties(period)

            track(analyticsCategory, {
                action: 'Click',
                label: 'date_range_drop_down',
                ...properties,
            })
        },
        [analyticsCategory, track]
    )

    const handlePrevButtonClick = useCallback(
        (previousPeriod, newPeriod) => {
            const rangeSize = getRangeSizeFromPeriod(previousPeriod)
            const previousProperties = prefixTermToObjectKeys(
                getDateRangeProperties(previousPeriod, {
                    includeRangeSize: false,
                }),
                'previous'
            )
            const currentProperties = prefixTermToObjectKeys(
                getDateRangeProperties(newPeriod, { includeRangeSize: false }),
                'current'
            )
            track(analyticsCategory, {
                action: 'Click',
                label: 'date_range_left',
                range_size: rangeSize,
                ...previousProperties,
                ...currentProperties,
            })
        },
        [analyticsCategory, track]
    )

    const handleNextButtonClick = useCallback(
        (previousPeriod, newPeriod) => {
            const rangeSize = getRangeSizeFromPeriod(previousPeriod)
            const previousProperties = prefixTermToObjectKeys(
                getDateRangeProperties(previousPeriod, {
                    includeRangeSize: false,
                }),
                'previous'
            )
            const currentProperties = prefixTermToObjectKeys(
                getDateRangeProperties(newPeriod, { includeRangeSize: false }),
                'current'
            )
            track(analyticsCategory, {
                action: 'Click',
                label: 'date_range_right',
                range_size: rangeSize,
                ...previousProperties,
                ...currentProperties,
            })
        },
        [analyticsCategory, track]
    )

    const handleDateRangeDropdownItemClick = useCallback(
        (previousPeriod, newPeriod) => {
            const rangeSize = getRangeSizeFromPeriod(previousPeriod)
            const previousProperties = prefixTermToObjectKeys(
                getDateRangeProperties(previousPeriod, {
                    includeRangeSize: false,
                }),
                'previous'
            )
            const currentProperties = prefixTermToObjectKeys(
                getDateRangeProperties(newPeriod, { includeRangeSize: false }),
                'current'
            )

            track(analyticsCategory, {
                action: 'Click',
                label: 'date_range_select',
                range_size: rangeSize,
                ...previousProperties,
                ...currentProperties,
            })
        },
        [analyticsCategory, track]
    )

    return (
        <>
            <Header title={title}>
                <RangeSizeDropdown
                    onDropdownToggleClick={(period) => {
                        handleRangeSizeDropdownToggleClick(period)
                    }}
                    onDropdownItemClick={(
                        previousRangeSize,
                        currentRangeSize
                    ) => {
                        handleRangeSizeDropdownItemClick(
                            previousRangeSize,
                            currentRangeSize,
                            period
                        )
                    }}
                />

                <DateRangeDropdown
                    earliestDate={tripsStartDate}
                    period={period}
                    disabledText="Trips not started"
                    onDropdownToggleClick={(newPeriod) => {
                        handleDateRangeDropdownToggleClick(newPeriod)
                    }}
                    onPrevButtonClick={(newPeriod) => {
                        handlePrevButtonClick(period, newPeriod)
                    }}
                    onNextButtonClick={(newPeriod) => {
                        handleNextButtonClick(period, newPeriod)
                    }}
                    onDropdownItemClick={(newPeriod) => {
                        handleDateRangeDropdownItemClick(period, newPeriod)
                    }}
                />
            </Header>

            <Row>
                <Col className="d-flex justify-content-end px-0">
                    <p
                        test-attr="programStartDate"
                        className={`my-0 ${
                            isProgramStartDateVisible ? 'visible' : 'invisible'
                        }`}
                    >
                        Program start date: {formattedTripsStartDate}
                    </p>
                </Col>
            </Row>
        </>
    )
}

CommuteHeader.propTypes = {
    title: PropTypes.string.isRequired,
    analyticsCategory: PropTypes.string.isRequired,
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        quarter: PropTypes.number,
        month: PropTypes.number,
        week: PropTypes.number,
    }).isRequired,
}

export default CommuteHeader
