import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import {
    createCarpoolerDeactivationsDownloadRequest,
    createCarpoolerDownloadActivatedRequest,
} from '../../state/carpoolers'

const DownloadCSV = ({ onClose, status }) => {
    const { track } = useAnalytics()
    const dispatch = useDispatch()

    const downloadActiveCarpoolersCSV = () =>
        dispatch(createCarpoolerDownloadActivatedRequest())
    const downloadDeactivatedCarpoolersCSV = () =>
        dispatch(createCarpoolerDeactivationsDownloadRequest())

    const downloadCSV =
        status === 'active'
            ? downloadActiveCarpoolersCSV
            : downloadDeactivatedCarpoolersCSV

    function hanndleDownload() {
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'confirm_download_csv',
        })
        downloadCSV()
        onClose()
    }

    function handleCancel() {
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'cancel_download_csv',
        })
        onClose()
    }

    const inverseStatus = status === 'active' ? 'deactivated' : 'active'
    const capitalizedInverseStatus =
        inverseStatus.charAt(0).toUpperCase() + inverseStatus.slice(1)

    return (
        <>
            <ModalHeader toggle={handleCancel}>
                Download {status} commuters list
            </ModalHeader>
            <ModalBody>
                <p>
                    Your CSV download will contain all historical data for{' '}
                    {status} commuters from your organization.
                </p>
                <p>
                    To download a list of {inverseStatus} commuters, please
                    visit the {capitalizedInverseStatus} commuters tab.
                </p>
                <p>Your download may take a few minutes to complete.</p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={hanndleDownload}>
                    Download
                </Button>
            </ModalFooter>
        </>
    )
}

DownloadCSV.propTypes = {
    onClose: PropTypes.func,
    status: PropTypes.string,
}

export default DownloadCSV
