import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'reactstrap'

type Props = PropsWithChildren<{
    hasBackground?: boolean
    hasBorder?: boolean
    className?: string
    isRounded?: boolean
    'data-test'?: string
}>

const Content = ({
    children,
    hasBackground = true,
    hasBorder = true,
    className = '',
    isRounded,
    'data-test': dataTest = 'content-wrapper',
}: Props): JSX.Element => (
    <Row>
        <Col>
            <div
                data-test={dataTest}
                className={classNames(className, 'px-3', {
                    'bg-white': hasBackground,
                    border: hasBorder,
                    rounded: isRounded,
                })}
            >
                {children}
            </div>
        </Col>
    </Row>
)

export { Content }
export default Content
