import useFetch, { CachePolicies } from 'use-http'
import { saveAs } from 'file-saver'
import { format } from 'date-fns'

const fetchOptions = {
    headers: {
        Accept: 'text/csv',
    },
    cachePolicy: CachePolicies.NO_CACHE,
}

type Options = {
    onSuccess?: () => void
    onError?: () => void
}

type VaccinationDownloadCsvResource = {
    isLoading: boolean
    downloadCsv: () => Promise<void>
}

export default function useVaccinationLogDownloadCsvResource(
    spaceId: string,
    { onSuccess, onError }: Options = {}
): VaccinationDownloadCsvResource {
    const { loading: isLoading, get, response } = useFetch(
        `spaces/${spaceId}/vaccinationLog`,
        fetchOptions
    )

    const downloadCsv = async () => {
        await get()

        if (response.ok) {
            const csv = await response.blob()
            const date = format(new Date(), 'yyyy-MM-dd')
            const fileName = `Vaccination_log_${date}.csv`

            onSuccess?.()
            saveAs(csv, fileName)
        } else {
            onError?.()
        }
    }

    return {
        isLoading,
        downloadCsv,
    }
}
