import React from 'react'
import { Button, ButtonProps } from 'reactstrap'
import classnames from 'classnames'

import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

type Props = {
    isExpanded: boolean
    collapseLabel?: string
    expandLabel?: string
    className?: string
    iconProps?: Partial<FontAwesomeIconProps>
} & ButtonProps

// This could be a standalone component, perhaps ToggleButton
const CollapsibleButton = ({
    isExpanded = false,
    collapseLabel,
    expandLabel,
    className,
    iconProps,
    ...rest
}: Props) => {
    const label = isExpanded ? collapseLabel : expandLabel
    const icon = isExpanded ? faChevronUp : faChevronDown
    return (
        <Button
            data-test="collapsing-button"
            color="link"
            className={classnames(
                'd-flex align-items-center text-decoration-none',
                className
            )}
            {...rest}
        >
            <FontAwesomeIcon
                data-test="collapsing-icon"
                icon={icon}
                className="mr-1"
                {...iconProps}
            />
            {label}
        </Button>
    )
}

export default CollapsibleButton
