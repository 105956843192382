import { AnyObject } from 'react-final-form'

/** type of handleSubmit from final-form */
export type FinalFormHandleSubmit = (
    event?: Partial<
        Pick<
            React.SyntheticEvent<Element, Event>,
            'preventDefault' | 'stopPropagation'
        >
    >
) => Promise<AnyObject | undefined> | undefined

export type Quarter = 1 | 2 | 3 | 4
export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

export type LastUpdatedTimeAnalytics = {
    category: string
    period?: {
        year: number
        quarter?: Quarter
        month?: Month
        week?: number
    }
}

/**
 * Error returned from endpoints when the status code is not 200.
 *
 * See `/CosmosApi/Errors.js` for details.
 */
export type CosmosError = {
    message?: string
    statusCode?: number
    errorId?: string
}

/**
 * Response with error returned from endpoints when the status code is not 200.
 *
 * Note that the error is customized in `CosmosApi/index.js`
 */
export type ResponseError = {
    error: CosmosError
}

// To be used in conjunction with other types to model the API responses
export type CosmosResource<T> = {
    id: string
    createdAt: string
    updatedAt?: string
} & T

export type Pagination = {
    showPrevious: boolean
    showNext: boolean
    pagesToShow: number[]
    currentPage: number
    changePage: (pageNumber: number) => void
    fromResult?: number
    toResult?: number
}

export enum RosterStatus {
    active = 'active',
    pending = 'pending',
    deactivated = 'deactivated',
}

export enum InvitationStatus {
    invited = 'invited',
    notYetInvited = 'notYetInvited',
}

export enum StatusLabels {
    active = 'Active',
    pending = 'Pending',
    deactivated = 'Deactivated',
    notYetInvited = 'Not yet invited',
}

export enum IndicationStatus {
    workingFromHome = 'workingFromHome',
    workingFromOffice = 'workingFromOffice',
    outOfOffice = 'outOfOffice',
    noStatus = 'noStatus',
}

export enum AttendanceStatus {
    confirmedAttended = 'confirmedAttended',
    notConfirmed = 'notConfirmed',
}

type Department = {
    id: string
    label: string
    customerDirectory: {
        id: string
    }
}

export type Manager = {
    managerIdentifyingTraits: {
        email?: string
        externalIdentifier?: string
    }
    managerRosterRecord: {
        id: string
        firstName: string
        lastName: string
    } | null
}

export type RosterRecord = {
    id: string
    firstName: string
    lastName: string
    email: string
    externalIdentifier: string
    status: RosterStatus
    invitationStatus: InvitationStatus
    department: Department | null
    manager: Manager | null
    employmentType?: string | null
}

export enum Granularity {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
}

export enum RangeSize {
    week = 'week',
    month = 'month',
    quarter = 'quarter',
    year = 'year',
}

export type Statistic = {
    context: {
        from: string
        until: string
    }
    result: number | undefined
    type: string
}

/**
 * --- Buildings ---
 */
export type EligibleDepartment = {
    id: string
    label: string
    customerDirectory: {
        id: string
    }
}

export type EligibleRosterRecord = {
    id: string
    firstName: string
    lastName: string
    email: string
}

export type BasePodResource = CosmosResource<{
    seatLabels: Array<string>
    customer: {
        id: string
    }
    neighborhoodName: string
    eligibleRosterRecord?: EligibleRosterRecord
    eligibleDepartments?: Array<EligibleDepartment>
}>

export type PodWithEligibleRosterRecord = BasePodResource & {
    eligibleRosterRecord: EligibleRosterRecord
}

export type PodWithEligibleDepartments = BasePodResource & {
    eligibleDepartments: Array<EligibleDepartment>
}

export type Pod =
    | BasePodResource
    | PodWithEligibleRosterRecord
    | PodWithEligibleDepartments

export type Pods = Array<Pod>

export type FloorPlan = {
    filename: string
    fileType: string
    cacheKey: string
    url: string
}

export type Floor = {
    id: string
    floorNumber: number
    floorPlan: FloorPlan | null
    pods: Pods
}

export type FloorWithSeats = Floor & {
    seats: Array<string>
}

export type EmployeeExperienceConfigurationBase = CosmosResource<{
    customer: {
        id: string
    }
    startAt: string
    endAt: string | null
}>

export interface EmployeeExperienceConfigurationCheckInCapacity
    extends EmployeeExperienceConfigurationBase {
    type: 'checkInCapacity'
    value: number | null
    eligibilityProfile: {
        building: {
            id: string
        }
    }
}

export interface EmployeeExperienceConfigurationRecurringCheckInRequirementOverrides
    extends EmployeeExperienceConfigurationBase {
    type: 'recurringCheckInRequirementOverrides'
    value: {
        header: string
        body: string
    }
}

export type EmployeeExperienceConfiguration =
    | EmployeeExperienceConfigurationCheckInCapacity
    | EmployeeExperienceConfigurationRecurringCheckInRequirementOverrides

export type Coordinates = number[][][][]

export type MultiPolygonGeometry = {
    type: 'MultiPolygon'
    coordinates: Coordinates
}

export type Building = {
    id: string
    name: string
    customer: {
        id: string
    }
    baseAddress: string
    floors: Array<Floor>
    capacity: number | null
    startDate: string
    endDate: string | null
    timeZone: string
    createdAt: string
    totalAssignedDesks: number | null
    geometry: MultiPolygonGeometry
    employeeExperienceConfigurations: Array<EmployeeExperienceConfiguration>
}

export type BuildingWithFloorsAndSeats = Omit<Building, 'floors'> & {
    floors: Array<FloorWithSeats>
}

export type HealthAndSafetySubmission = {
    id: string
    createdAt: string
}

export type WorkAttendanceIndication = {
    id: string
    confirmationCode: string | null
    building: Partial<Building> | null
    status: IndicationStatus
}

export type WorkAttendanceActual = {
    id: string
    building: Pick<Building, 'id' | 'name'>
}

export type WorkAttendance = {
    actual: WorkAttendanceActual | null
    indication: WorkAttendanceIndication | null
    latestHealthAndSafetySubmission: HealthAndSafetySubmission | null
    rosterRecord: RosterRecord
}

export enum VaccinationStatus {
    fullyVaccinated = 'fullyVaccinated',
    notVaccinated = 'notVaccinated',
    preferNotToSay = 'preferNotToSay',
    exemptMedical = 'exemptMedical',
    exemptReligious = 'exemptReligious',
}

export enum VaccinationReviewStatus {
    pending = 'pending',
    verified = 'verified',
    rejected = 'rejected',
}

export type VaccinationProofResource = CosmosResource<{
    reviewStatus: VaccinationReviewStatus
    documentUrl: string
}>

export type VaccinationLogRecordResource = CosmosResource<{
    accountId: string
    firstName: string
    lastName: string
    status: VaccinationStatus
    proof?: VaccinationProofResource
    type?: 'covid19'
}>

export enum DeskAssignmentType {
    open = 'open',
    department = 'department',
    individual = 'individual',
}

export type NeighbhoodWithSeats = {
    id: string
    name: string
    seats: Array<{
        label: string
        assignmentType: DeskAssignmentType
        assignments: Array<string>
    }>
}

export type FloorWithNeighborhoods = Omit<Floor, 'pods'> & {
    neighborhoods: Array<NeighbhoodWithSeats>
}

export type BuildingByFloorAndNeighbhorhood = {
    id: string
    name: string
    floors: Array<FloorWithNeighborhoods>
    employeeExperienceConfigurations: Array<EmployeeExperienceConfiguration>
}

export enum EmployeeExperienceConfigurationTypes {
    checkInCapacity = 'checkInCapacity',
    recurringCheckInRequirementOverrides = 'recurringCheckInRequirementOverrides',
}

export type TestingLogRecordResource = CosmosResource<{
    rosterRecord: Pick<RosterRecord, 'id' | 'firstName' | 'lastName'>
    testDate: string
    disease: 'covid19'
    documentUrl?: string
}>

export type HrisIntegration = {
    id: string
    label: string
    type: string
    logoUrl: string
    iconUrl: string
    isAdminPortalSupported: boolean
    workosDirectory?: {
        id: string
        connected: boolean
        needsReconnect?: boolean
    }
}

export type CalendarIntegration = {
    id: string
    calendarType: 'google'
}
