/**
 * Utility for clearing a reducer on an event
 * 
 * Sometimes you want to reset a reducer to its initial state when an unrelated action is dispatched.
 * You can pass this to your reducer with an array of action types to listen to so you don't have 
 * to add extra logic to the reducer itself.
 * 
 * 
 * @param {types} - An array of action types
 * @param {reducer} - The reducer
 * Exmample
 * 
 * export default combineReducers({         
    someReducer: cleanUpReducerOnEvent(
        [SOME_ACTION_TYPE],
        someReducer
    ),
    anotherReducer,    
})
 */
const cleanUpReducerOnEvent = (types, reducer) => {
    return (state, action) => {
        if (types.includes(action.type)) {
            return reducer(undefined, action)
        }
        return reducer(state, action)
    }
}

export default cleanUpReducerOnEvent
