import React, { ChangeEvent } from 'react'
import {
    Button as ReactstrapButton,
    Input,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap'

import useLocalStorage from '../../hooks/useLocalStorage'
import useAsync from '../../hooks/useAsync/index'
import Button from '../../components/Button'
import ErrorAlert from '../../components/Alert/Error/index'

type Props = {
    reminderId?: string // human readable id to differentiate modal
    onClose: (params?: { didSave?: boolean }) => void
    onSave: () => Promise<void>
    onCancel?: () => void
    title: string
    description: JSX.Element
    saveButtonLabel?: string
    savingButtonLabel?: string
    saveButtonColor?: 'primary' | 'danger'
    couldNotSaveErrorMessage?: string
    useErrorMessageFromSave?: boolean // if true, will use the error message from the save action instead of couldNotSaveErrorMessage
}

/**
 * @param props.reminderId - If passed, the reminder checkbox will be visible.
 */
const ConfirmationModal = ({
    reminderId,
    onClose,
    onSave,
    onCancel,
    description,
    title,
    saveButtonLabel = 'Save',
    savingButtonLabel = 'Saving...',
    saveButtonColor = 'primary',
    couldNotSaveErrorMessage = 'Could not save',
    useErrorMessageFromSave = false,
}: Props): JSX.Element => {
    const { value: isReminderDisabled, write } = useLocalStorage(reminderId)
    const { isLoading, exec, isError, error } = useAsync()

    function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
        const isChecked = e.target.checked

        write(isChecked)
    }

    async function save() {
        try {
            await exec(onSave())
        } catch (error) {
            // Swallow error because we're already using it from useAsync and don't want to close modal
            return
        }

        onClose({ didSave: true })
    }

    function handleCancel() {
        onCancel?.()
        onClose()
    }

    return (
        <>
            <ModalHeader data-test="modal-header" toggle={() => onClose()}>
                {title}
            </ModalHeader>
            <ModalBody data-test="modal-body">
                {isError && (
                    <ErrorAlert>
                        {useErrorMessageFromSave &&
                            typeof error === 'string' &&
                            error}
                        {useErrorMessageFromSave &&
                            typeof error === 'object' &&
                            error.name}
                        {!useErrorMessageFromSave && couldNotSaveErrorMessage}
                    </ErrorAlert>
                )}
                {description}

                {reminderId && (
                    <FormGroup className="custom-input">
                        <Input
                            type="checkbox"
                            id="reminder-checkbox"
                            checked={Boolean(isReminderDisabled)}
                            onChange={handleCheckboxChange}
                        />
                        <Label
                            for="reminder-checkbox"
                            data-test="reminder-checkbox-label"
                        >
                            Don&apos;t show this again
                        </Label>
                    </FormGroup>
                )}
            </ModalBody>

            <ModalFooter data-test="modal-footer">
                <ReactstrapButton
                    data-test="modal-cancel-button"
                    color="secondary"
                    onClick={handleCancel}
                >
                    Cancel
                </ReactstrapButton>
                <Button
                    data-test="modal-save-button"
                    color={saveButtonColor}
                    label={saveButtonLabel}
                    loadingLabel={savingButtonLabel}
                    onClick={save}
                    isLoading={isLoading}
                    block={false}
                    size="md"
                />
            </ModalFooter>
        </>
    )
}

export default ConfirmationModal
