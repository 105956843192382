import { useEffect, useRef } from 'react'

/**
 * Calls callback function, once only when value is defined and changes from previous value
 * @param {Function} cb
 * @param {*} value
 */
export const useCallOnceWhenDefined = (cb, value) => {
    const lastValue = useRef(null)
    const lastCallback = useRef(null)

    useEffect(() => {
        if (
            value != null &&
            lastValue.current !== value &&
            lastCallback.current !== cb
        ) {
            cb()
        }
        lastValue.current = value
    }, [value, cb])
}

export default useCallOnceWhenDefined
