const analytics = {
    track(...args) {
        window.analytics.track(...args)
    },

    identify(userId, ...args) {
        window.analytics.identify(userId, ...args)
        window.googleTagManagerDataLayer.push({ userId })
    },

    group(...args) {
        window.analytics.group(...args)
    },

    identifySpace(spaceId) {
        window.googleTagManagerDataLayer.push({ spaceId })
    },
}

export default analytics
