import React from 'react'

const ErrorMessage = () => (
    <>
        <p>The CSV format is invalid. Please check that your file has:</p>
        <ul>
            <li>One column with the header text &quot;First name&quot;</li>
            <li>One column with the header text &quot;Last name&quot;</li>
            <li>One column with the header text &quot;Email&quot;</li>
            <li>One column with the header text &quot;Employee ID&quot;</li>
            <li>
                Each row has at least a first name, last name, email, and
                employee ID
            </li>
            <li>One item per cell</li>
            <li>Unique email for each employee</li>
            <li>The correct email format: &quot;@domain.com&quot;</li>
            <li>Unique employee IDs for each employee</li>
        </ul>
    </>
)

export default ErrorMessage
