import { useCallback, useMemo, useState } from 'react'
import useFetch from 'use-http'

import { createRequestInterceptor } from '../../../util/requestInterceptors'

const MB = 1000000 // 1 MB is 1 million bytes
const FILE_SIZE_LIMIT = MB * 10

const {
    prepareFileForPost,
    fileRequestFetchOptions,
} = createRequestInterceptor()

const useFloorPlanResource = ({ spaceId, floorId }) => {
    const [hasFileSizeError, setHasFileSizeError] = useState(false)
    const {
        data,
        loading: isLoading,
        response,
        post,
        error: networkError,
    } = useFetch(
        `/spaces/${spaceId}/buildings/floors/${floorId}/uploadFloorPlan`,
        fileRequestFetchOptions
    )

    const uploadFloorPlan = useCallback(
        async (floorPlan) => {
            setHasFileSizeError(false)

            const { file } = floorPlan

            if (file.size > FILE_SIZE_LIMIT) {
                setHasFileSizeError(true)
                return
            }

            const data = await post(prepareFileForPost(file))

            return response.ok ? data : undefined
        },
        [post, response]
    )

    const floorPlan = useMemo(() => (response.ok ? data : undefined), [
        data,
        response,
    ])

    const error = useMemo(
        () => (isLoading ? undefined : data?.error || networkError),
        [isLoading, data, networkError]
    )

    return {
        isLoading,
        hasFileSizeError,
        error,
        uploadFloorPlan,
        floorPlan,
    }
}

export default useFloorPlanResource
