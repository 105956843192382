/**
 *
 * @param {Object} chartDimensions
 * In production, this prop is not utilized by ReCharts since the <LineChart> renders inside of <ResponsiveContainer>.
 * However, the <ResponsiveContainer> relies on window width which does not exist in a test environment.
 * This will result in an empty render tree.
 *
 * So in order to properly test this component, you need to do two things.
 * 1. Mock <ResponsiveContainer> with a <div>. This is done jest.setup.js.
 * 2. Set explicit chart dimensions on the <LineChart>. i.e., chartDimensions
 */
const chartDimensionsForTesting = { height: 400, width: 400 }

export default chartDimensionsForTesting
