import React from 'react'
import Button from '../../../components/Button'
import { modalTypeMap } from '../../../components/ReduxModal/helpers'
import useModal from '../../../hooks/useModal'
import useSpaces from '../../../hooks/useSpaces'
import { VaccinationLogRecordResource } from '../../../types'

type Props = {
    vaccinationLogRecordsToReview: VaccinationLogRecordResource[]
    refreshData: () => void
}

const ReviewProofsInBulkButton = ({
    vaccinationLogRecordsToReview,
    refreshData,
}: Props): JSX.Element | null => {
    const { id: spaceId } = useSpaces()
    const openModal = useModal()

    if (vaccinationLogRecordsToReview.length === 0) {
        return null
    }

    const label =
        vaccinationLogRecordsToReview.length === 1
            ? 'Review 1 document'
            : `Review ${vaccinationLogRecordsToReview.length} documents`

    return (
        <Button
            data-test="review-proofs-in-bulk-button"
            className="mr-2"
            label={label}
            onClick={() => {
                openModal(modalTypeMap.REVIEW_VACCINATION_DOCUMENTATION, {
                    spaceId,
                    vaccinationLogRecordsToReview,
                    onClose: ({ shouldRefreshData }) =>
                        shouldRefreshData && refreshData(),
                    size: 'lg',
                    isStaticModal: true, // Don't allow user to close modal by clicking outside
                })
            }}
            size="md"
            color="primary"
            block={false}
        />
    )
}

export default ReviewProofsInBulkButton
