import { shape, string } from 'prop-types'
import React from 'react'

import ModuleFlag from '../../ModuleFlag'
import SideBarLink from '../SideBarLink'

const SettingsSideBar = ({ space }) => {
    return (
        <ModuleFlag
            modules={{
                names: ['workplacePlanner', 'integrations'],
                comparator: 'some',
            }}
        >
            <strong className="d-block ml-3 py-2">Settings</strong>

            <ModuleFlag name="workplacePlanner">
                <SideBarLink
                    text="Buildings"
                    to={`${space.alias}/settings/buildings`}
                />
            </ModuleFlag>

            <ModuleFlag name="integrations">
                <SideBarLink
                    text="Integrations"
                    to={`${space.alias}/settings/integrations`}
                />
            </ModuleFlag>
        </ModuleFlag>
    )
}

SettingsSideBar.propTypes = {
    space: shape({
        alias: string,
    }).isRequired,
}

export default SettingsSideBar
