import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import LineChartSection from '../../components/Chart/LineChartSection'
import withReloadable from '../../hocs/withReloadable'
import useAnalytics from '../../hooks/useAnalytics'
import { useCommute } from '../../providers/CommuteProvider'
import { getDateRangeProperties } from '../../util/analytics'
import Content from '../../components/PageLayout/Content.tsx'

const CommuterActivity = () => {
    const { track } = useAnalytics()
    const { period } = useCommute()

    useEffect(() => {
        track('carpooler_activity_action', {
            action: 'View',
            label: 'carpooler_activity',
            ...getDateRangeProperties(period, { includeRangeSize: true }),
        })
    }, [track, period])

    return (
        <Content hasBackground={false} hasBorder={false}>
            <Row>
                <Col className="px-0">
                    <LineChartSection
                        id="active-commuters"
                        title="Active commuters"
                        description="Unique number of commuters who took a trip."
                        path={`/spaces/:spaceId/metrics/activeCarpoolers\\?from=:fromDate&until=:untilDate&statisticType=sum&calculationInterval=:granularity`}
                        analytics={{
                            category: 'carpooler_activity_action',
                            name: 'active_carpoolers',
                            clickLabel: 'active_carpoolers_click',
                            viewLabel: 'active_carpoolers_view',
                        }}
                    />

                    <LineChartSection
                        id="cars-out-of-the-lot"
                        title="Average cars out of the lot per day"
                        abbreviatedTitle="Cars out of the lot per day"
                        description={`
                    Average number of Riders going to and from your site daily.
                    Each Rider is counted only once and is assumed to free up
                    one parking space. To better understand Scoop’s impact on
                    your facilities, multiply this number by your local SOV
                    rate.
                `}
                        path={`/spaces/:spaceId/metrics/carsOutOfTheLot\\?from=:fromDate&until=:untilDate&calculationInterval=:granularity&groupBy=day&statisticType=mean`}
                        radioButtonLabel="Average"
                        analytics={{
                            category: 'carpooler_activity_action',
                            name: 'avg_cars_out_of_lot',
                            clickLabel: 'avg_cars_out_of_lot_click',
                            viewLabel: 'avg_cars_out_of_lot_view',
                        }}
                    />
                </Col>
            </Row>
        </Content>
    )
}

export default withReloadable(CommuterActivity)
