import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'

import ErrorTryAgain from 'components/MemberManagementStates/ErrorTryAgain'
import SpinnerFullPage from 'components/SpinnerFullPage'
import { EmployeeRosterContext } from 'providers/EmployeeRosterProvider'

import NoRosterRecords from './NoRosterRecords'
import RosterList from './RosterList'
import useRosterInviteReminder from './useRosterInviteReminder'

import useSpaces from '../../hooks/useSpaces'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const RosterManagement = (props: RouteComponentProps): JSX.Element => {
    const { alias: spaceAlias } = useSpaces()
    const { hasRecords, isIdle, hasErrors } = useContext(EmployeeRosterContext)
    useRosterInviteReminder()

    if (hasErrors) {
        return (
            <ErrorTryAgain
                data-test="load-roster-records-error"
                errorText="We couldn't load your roster records"
            />
        )
    }

    if (isIdle) {
        return <SpinnerFullPage />
    }

    if (!hasRecords && spaceAlias) {
        return <NoRosterRecords spaceAlias={spaceAlias} />
    }

    return <RosterList />
}

export default RosterManagement
