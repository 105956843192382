import React from 'react'
import styled from 'styled-components'
import { Table, Card } from 'reactstrap'
import { capitalize } from 'lodash'

const TableCard = styled(Card)`
    max-height: 340px; // According to design spec
    overflow: auto;
    margin: 10px 0;
    th:not(:last-child) {
        width: 25%;
    }
`

type Seat = {
    label: string
    assignments: string[]
    assignmentType: string
}

type Props = {
    seats: Seat[]
}

const headers = ['Desk Label', 'Assignment type', 'Assignee']

const SeatTable = ({ seats }: Props) => {
    return (
        <TableCard>
            <Table data-test="seat-table" className="mb-0">
                <thead>
                    <tr>
                        {headers.map((label) => (
                            <th className="border-top-0" key={label}>
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {seats.map(
                        ({ label, assignments = [], assignmentType }, i) => (
                            <tr key={`row-${i}`}>
                                <td>{label}</td>
                                <td>{capitalize(assignmentType)}</td>
                                <td>{assignments.join(', ')}</td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>
        </TableCard>
    )
}

export default React.memo(SeatTable)
