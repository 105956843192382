import { useContext, useEffect } from 'react'

import { modalTypeMap } from '../../../components/ReduxModal/helpers.ts'
import useModal from '../../../hooks/useModal/index.tsx'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { EmployeeRosterContext } from '../../../providers/EmployeeRosterProvider.tsx'

const useRosterUploadReminder = () => {
    const openModal = useModal()
    const { value: isReminderDisabled } = useLocalStorage(
        'isRosterUploadReminderDisabled'
    )
    const { hasRecords, isLoading } = useContext(EmployeeRosterContext)

    useEffect(() => {
        if (hasRecords && !isReminderDisabled) {
            openModal(modalTypeMap.ROSTER_UPLOAD_REMINDER, {
                isStaticModal: true,
            })
        }
    }, [hasRecords, openModal, isReminderDisabled])

    return {
        isReminderDisabled: Boolean(isReminderDisabled),
        isLoading,
        hasRecords,
    }
}

export default useRosterUploadReminder
