import { reduce } from 'lodash'
import React, { useMemo } from 'react'

import FilterDropdown, {
    Options,
    OptionValue,
} from '../../../components/FilterDropdown'
import { UseBuildingResource } from '../../../hooks/useBuildingResource'
import { AttendanceStatus, IndicationStatus } from '../../../types'

/**
 * Available filters for CheckInTableFilters.
 */
type Filters = {
    buildings?: string[]
    indicationStatus?: string[]
    attendanceStatus?: string[]
}

type Props = {
    buildingResource: UseBuildingResource
    onChange: (filters: Filters) => void
    activeFilters: Filters
}

const { confirmedAttended, notConfirmed } = AttendanceStatus

const {
    workingFromHome,
    workingFromOffice,
    outOfOffice,
    noStatus,
} = IndicationStatus

/**
 * The key is the param name passed to the endpoint for filtering.
 * The top level key must match a filter key in `useFetchCheckins`
 */
const checkboxFilterOptions: Options = {
    indicationStatuses: {
        title: 'Status',
        options: [
            {
                key: workingFromOffice,
                label: 'Going into work',
            },
            {
                key: workingFromHome,
                label: 'Working remotely',
            },
            {
                key: outOfOffice,
                label: 'Out of office',
            },
            {
                key: noStatus,
                label: 'No status',
            },
        ],
    },

    attendanceStatuses: {
        title: 'Attendance',
        options: [
            {
                key: confirmedAttended,
                label: 'Confirmed',
            },
            {
                key: notConfirmed,
                label: 'Not confirmed',
            },
        ],
    },

    // Define an empty option here to keep the order correct.
    buildings: {
        title: 'Buildings',
        options: [],
        isLoading: true,
        error: undefined,
    },
}

const CheckInTableFilters = ({
    buildingResource,
    onChange,
    activeFilters,
}: Props): JSX.Element => {
    const {
        buildings,
        getBuildings,
        isLoading: areBuildingsLoading,
        error: buildingError,
    } = buildingResource

    /**
     * Take the static options and merge them with the active filters (from server)
     */
    const optionsWithSelection = useMemo<Options>(() => {
        const updatedFilterOptions = { ...checkboxFilterOptions }

        // Add buildings as options or show a loader/error.
        updatedFilterOptions.buildings = {
            ...updatedFilterOptions.buildings,
            isLoading: areBuildingsLoading,
            error: buildingError
                ? new Error('Could not load buildings')
                : undefined,
            tryAgain: () => getBuildings({}),
            options: (buildings || []).map((building) => ({
                key: building.id,
                label: building.name,
            })),
        }

        for (const [key, filterOptions] of Object.entries(
            updatedFilterOptions
        )) {
            updatedFilterOptions[key] = {
                ...filterOptions,
                options: filterOptions.options.map((option) => ({
                    isSelected: activeFilters[key]?.includes(option.key),
                    ...option,
                })),
            }
        }

        return updatedFilterOptions
    }, [
        buildings,
        getBuildings,
        areBuildingsLoading,
        buildingError,
        activeFilters,
    ])

    /**
     * Transform checkbox selections ("options") to filters
     */
    const handleChange = (newOptions: Options) => {
        const newFilters: Filters = reduce(
            newOptions,
            (result: Filters, value: OptionValue, key: string) => {
                result[key] = value.options
                    .filter((option) => option.isSelected)
                    .map((option) => option.key)

                return result
            },
            {}
        )

        onChange(newFilters)
    }

    return (
        <FilterDropdown
            options={optionsWithSelection}
            onChange={handleChange}
        />
    )
}

export default CheckInTableFilters
