import { useEffect, useState } from 'react'

const DEFAULT_DELAY = 500

function useDebounce(initialValue, delay = DEFAULT_DELAY) {
    const [debounced, updateDebounced] = useState(initialValue)
    const wait = delay
    useEffect(() => {
        const handler = setTimeout(() => updateDebounced(initialValue), wait)
        return () => clearTimeout(handler)
    }, [initialValue, wait, updateDebounced])

    return debounced
}

export default useDebounce
