import { useCallback, useRef, useReducer, useLayoutEffect } from 'react'

const IDLE = 'idle'
const PENDING = 'pending'
const RESOLVED = 'resolved'
const REJECTED = 'rejected'

/**
 * Important wrapper to ensure that we don't dispatch when the component
 * happens to be unmounted while a request is waiting to be completed
 *
 * @param {function} dispatch
 * @returns
 */
function useSafeDispatch(dispatch) {
    const mounted = useRef(false)
    useLayoutEffect(() => {
        mounted.current = true

        return () => (mounted.current = false)
    }, [])

    return useCallback(
        (...args) => (mounted.current ? dispatch(...args) : undefined),
        [dispatch]
    )
}

/**
 * useAsync Hook can be used to run a Promise transaction
 * as a single status.
 * It's helpful when we need a single loading indicator for async operations
 *
 * @example
 *
 * const {isLoading, data, error, status, exec} = useAsync()
 *  useEffect(() => {
 *  exec(postAllTheThings(data))
 * }, [data, exec])
 *
 * const response = await exec(postAllTheThings(data))
 *
 */
const defaultInitialState = { status: IDLE, data: null, error: null }
function useAsync(initialState) {
    const initialStateRef = useRef({
        ...defaultInitialState,
        ...initialState,
    })
    const [{ status, data, error }, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        initialStateRef.current
    )

    const safeSetState = useSafeDispatch(setState)

    const setData = useCallback(
        (data) => safeSetState({ data, status: RESOLVED }),
        [safeSetState]
    )
    const setError = useCallback(
        (error) => safeSetState({ error, status: REJECTED }),
        [safeSetState]
    )
    const reset = useCallback(() => safeSetState(initialStateRef.current), [
        safeSetState,
    ])

    const exec = useCallback(
        (promise) => {
            if (!promise || !promise.then) {
                throw new Error(
                    `The argument passed to useAsync().exec must be a promise.`
                )
            }

            safeSetState({ status: PENDING })

            return promise.then(
                (data) => {
                    setData(data)
                    return data
                },
                (error) => {
                    setError(error)
                    return Promise.reject(error)
                }
            )
        },
        [safeSetState, setData, setError]
    )

    return {
        // using the same names that react-query happens to use
        isIdle: status === IDLE,
        isLoading: status === PENDING,
        isError: status === REJECTED,
        isSuccess: status === RESOLVED,

        setData,
        setError,
        error,
        status,
        data,
        exec,
        reset,
    }
}

export { IDLE, PENDING, RESOLVED, REJECTED }
export default useAsync
