import React from 'react'
import classNames from 'classnames'

import Icon from '../Icon'
import FileUploadButton from '../FileUploadButton'
import colors from '../../styles/colors'

type Props = {
    acceptedFileExtensions: string[]
    onFileChange: (file: File) => void
    uploadInstructions?: string
    isDisabled?: boolean
}

const Upload = ({
    acceptedFileExtensions,
    onFileChange,
    uploadInstructions = 'Drag and drop your file to upload',
    isDisabled,
}: Props): JSX.Element => {
    return (
        <>
            <Icon
                name="file-upload"
                size="4x"
                className="mb-3"
                color={isDisabled ? colors.green[100] : colors.success}
            />

            <h5 className={classNames('mb-3', { 'text-muted': isDisabled })}>
                {uploadInstructions}
            </h5>
            <p className="text-muted mb-3">or</p>
            <FileUploadButton
                isDisabled={isDisabled}
                onFileChange={onFileChange}
                acceptableFileTypes={acceptedFileExtensions.join(',')}
            >
                Browse files
            </FileUploadButton>
        </>
    )
}

export default Upload
