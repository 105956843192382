import React from 'react'

import SideBarLink from '../SideBarLink'
import ModuleFlag from '../../ModuleFlag/index'
import useSpaces from '../../../hooks/useSpaces/index'

const HealthAndSafetySideBar = (): JSX.Element => {
    const { alias } = useSpaces()
    return (
        <ModuleFlag
            modules={{
                names: ['vaccinationPolicies', 'diseaseTestResults'],
                comparator: 'some',
            }}
        >
            <strong className="d-block ml-3 py-2">Health and Safety</strong>
            <ModuleFlag name="vaccinationPolicies">
                <SideBarLink
                    text="Vaccination log"
                    to={`${alias}/health-and-safety/vaccination-log`}
                    data-test="vaccination-log-link"
                />
            </ModuleFlag>

            <ModuleFlag name="diseaseTestResults">
                <SideBarLink
                    text="Testing log"
                    to={`${alias}/health-and-safety/test-results`}
                    data-test="testing-log-link"
                />
            </ModuleFlag>
        </ModuleFlag>
    )
}

export default HealthAndSafetySideBar
