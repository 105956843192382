import { Redirect } from '@reach/router'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CenteredLayout } from '../../components/Layout/Centered'
import PhoneNumber from '../../components/PhoneNumber'
import useAnalytics from '../../hooks/useAnalytics'
import { createFactorEnrollRequest } from '../../state/auth/actions'
import { selectSessionTransaction } from '../../state/auth/selectors'

const MFAEnroll = () => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    const {
        sessionTransactionId,
        stateToken,
        status: authStatus,
    } = useSelector(selectSessionTransaction)

    const enrollFactor = useCallback(
        (phoneNumber) =>
            dispatch(
                createFactorEnrollRequest({
                    sessionTransactionId,
                    stateToken,
                    phoneNumber,
                })
            ),
        [dispatch, sessionTransactionId, stateToken]
    )

    // If the user is trying to activate their 2FA (the step following this one), send them to that route
    if (authStatus === 'MFA_ACTIVATE') {
        return <Redirect to="/mfa-activate" noThrow />
    }

    // User should only see this page if they are trying to create a new 2FA phone number
    if (authStatus !== 'MFA_ENROLL') {
        return <Redirect to="/" noThrow />
    }

    return (
        <CenteredLayout squished>
            <PhoneNumber
                onSubmit={enrollFactor}
                onSubmitClick={({ disabled }) => {
                    if (disabled) {
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'send_code_grayed_out',
                        })
                    } else {
                        track('account_creation_action', {
                            action: 'Click',
                            label: 'send_code_enabled',
                        })
                    }
                }}
            />
        </CenteredLayout>
    )
}

export default MFAEnroll
