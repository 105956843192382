import React from 'react'
import { Row, Col, Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { saveAs } from 'file-saver'

import sampleCSVData from './sampleCSVData'
import { RecordsInviteBulkResponseType } from '../../../hooks/resources/useRosterRecordsInviteBulkResource'

import FileUploader from '../../../components/FileUploader'

type Props = {
    onUploadSuccess: (response: RecordsInviteBulkResponseType) => void
    fileUploadURL: string
    onFileChange: (file: File) => void
}

const UploadInstructions = ({
    fileUploadURL,
    onUploadSuccess,
    onFileChange,
}: Props) => {
    const downloadSampleCSV = () => {
        const file = new File([sampleCSVData], 'roster-invite-template.csv', {
            type: 'text/csv;charset=utf-8',
        })
        saveAs(file)
    }

    return (
        <div data-test="invite-upload-instructions">
            <h4>Upload CSV</h4>
            <FileUploader
                onUploadSuccess={onUploadSuccess}
                url={fileUploadURL}
                acceptedFileExtensions={['.csv']}
                displayErrorsAsModal
                uploadInstructions="Drag and drop your CSV to upload"
                onFileChange={onFileChange}
            />
            <h5 className="py-3">How to format your CSV</h5>
            <h6>Columns</h6>
            <Row>
                <Col xs="6">
                    <ul data-test="invite-roster-col-descriptions">
                        <li className="py-1">
                            <b>Email:</b> Should match an email on the employee
                            roster.
                        </li>
                        <li className="py-1">
                            <b>Employee ID:</b> Should match an employee ID on
                            the employee roster.
                        </li>
                    </ul>
                </Col>
            </Row>
            <p
                className="text-muted"
                data-test="invite-roster-columns-required-info"
            >
                <FontAwesomeIcon
                    data-test="info-icon"
                    icon={faInfoCircle}
                    className="mr-1"
                />
                Only 1 column is required.
            </p>
            <div className="pb-4">
                <Row className="align-items-center">
                    <Col className="my-3">
                        <h6>Example CSV</h6>
                    </Col>
                    <Col className="text-right">
                        <Button
                            data-test="invite-roster-download-sample-csv-button"
                            onClick={downloadSampleCSV}
                            color="outline-primary"
                            className="mb-2"
                        >
                            Download CSV Template
                        </Button>
                    </Col>
                </Row>

                <Table
                    data-test="invite-roster-sample-data-table"
                    className="border rounded"
                >
                    <thead data-test="table-head">
                        <tr className="font-weight-bold">
                            <td>Email</td>
                            <td>Employee ID</td>
                        </tr>
                    </thead>
                    <tbody data-test="table-body">
                        <tr>
                            <td>adam@piggyback.com</td>
                            <td>0001</td>
                        </tr>
                        <tr>
                            <td>alice@piggyback.com</td>
                            <td>0002</td>
                        </tr>
                        <tr>
                            <td>antonio@piggyback.com</td>
                            <td>0003</td>
                        </tr>
                        <tr>
                            <td>anthony@piggyback.com</td>
                            <td>0004</td>
                        </tr>
                        <tr>
                            <td>april@piggyback.com</td>
                            <td>0005</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default UploadInstructions
