import React from 'react'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    UncontrolledDropdown,
} from 'reactstrap'

import { Space } from '../../providers/SpacesProvider'

type Props = {
    availableSpaces: Array<Space>
    currentSpaceName: string
}

/** TODO: Take this out when we can replace the <a/> tag with a navigate call.
 * This is a temporary hack until we sort out the complexity with generating the
 * correct url path to switch smoothly between spaces with and without commuting.
 */
export const getBaseUrl = (): string => {
    const {
        location: { protocol, host },
    } = window
    return protocol + '//' + host
}

const SpacesDropdown = ({
    availableSpaces = [],
    currentSpaceName = '',
}: Props): JSX.Element => (
    <Nav data-test="spaces-dropdown">
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
                data-test="selected-space"
                nav
                caret
                className="btn btn-white border border-secondary rounded text-decoration-none nav-link d-flex align-items-center justify-content-between py-1 px-2"
            >
                {currentSpaceName}
            </DropdownToggle>

            <DropdownMenu
                data-test="dropdown-menu"
                style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                }}
            >
                {availableSpaces.map((space) => (
                    <a
                        key={space.id}
                        data-test="space-link"
                        href={`${getBaseUrl()}/spaces/${space.alias}`}
                        className="text-decoration-none text-dark"
                    >
                        <DropdownItem>{space.name}</DropdownItem>
                    </a>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    </Nav>
)

export default SpacesDropdown
