import PropTypes from 'prop-types'

export const chartData = {
    context: PropTypes.shape({
        from: PropTypes.string,
        until: PropTypes.string,
    }),
    result: PropTypes.number,
    type: PropTypes.string,
}
