import { Link, useLocation } from '@reach/router'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { LogoImg } from './styled'
import useAnalytics from '../../hooks/useAnalytics'
import scoopLoop from '../../images/scoop-logo-green.png'

type Props = {
    user?: {
        displayName?: string
    }
}

const LogoLink = ({ user = {} }: Props): JSX.Element => {
    const { track } = useAnalytics()
    const [cookies] = useCookies(['preferred-sign-in'])
    const location = useLocation()
    const { search } = location

    // Determine the href for the logo link based on the user's logged-in status and preferred sign-in
    const preferredSignIn =
        cookies['preferred-sign-in'] === 'okta' ? '/okta' : '/sign-in'
    const signInUrl = `${preferredSignIn}${search}`
    const isLoggedIn = !_.isEmpty(user)

    return (
        <Link
            className="navbar-brand"
            to={isLoggedIn ? '/' : signInUrl}
            onClick={() =>
                track('edit_carpoolers_action', {
                    action: 'Click',
                    label: 'scoop_logo',
                })
            }
        >
            <LogoImg src={scoopLoop} alt="Scoop Logo" />
        </Link>
    )
}

export default LogoLink
