import {
    faCalendarAlt,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO, isEqual, isBefore, isAfter } from 'date-fns'
import React, { useMemo } from 'react'
import { Button, ButtonGroup } from 'reactstrap'

import CustomDatePicker from '../../../components/DatePicker'
import useAnalytics from '../../../hooks/useAnalytics'
import {
    getNextDay,
    getPreviousDay,
    getWeekendDaysBetweenDates,
    dateFormats,
} from '../../../util/date'

type Props = {
    excludeWeekendDays?: boolean
    selectedDate?: string // date as YYYY-MM-DD
    maxDate?: string // date as YYYY-MM-DD
    minDate?: string // date as YYYY-MM-DD
    onSelectedDateChange?: (date: string) => void // outputs date as YYYY-MM-DD
    isLoading?: boolean
}

/**
 * Datepicker specifically for the Workplace checks ins page.
 *
 * Note: all dates passed into it must be in YYYY-MM-DD format.
 */
const WorkplaceCheckInsDatepicker = ({
    excludeWeekendDays,
    selectedDate,
    onSelectedDateChange,
    minDate,
    maxDate,
    isLoading,
}: Props): JSX.Element => {
    const { track } = useAnalytics()

    const exclusionDates = useMemo(() => {
        return excludeWeekendDays
            ? getWeekendDaysBetweenDates(minDate, maxDate).map((date) =>
                  date.toDate()
              )
            : []
    }, [minDate, maxDate, excludeWeekendDays])

    /**
     * Tracks date selection.
     *
     * @param {string} [props.selectionMethod] should be one of left_arrow, right_arrow, drop_down
     * @param {string} [props.previousDate] date string as YYYY-MM-DD
     * @param {string} [props.selectedDate] date string as YYYY-MM-DD
     */
    function trackDateSelect({
        selectionMethod,
        previousDate,
        selectedDate,
    }: {
        selectionMethod?: string
        previousDate?: string
        selectedDate?: string
    }) {
        track('workplace_check_in_action', {
            action: 'Click',
            label: 'calendar_pick_date',
            selection_method: selectionMethod,
            previous_date: previousDate,
            current_date: selectedDate,
        })
    }

    function incrementDate() {
        const newDate = getNextDay(selectedDate, { excludeWeekendDays }).format(
            'YYYY-MM-DD'
        )

        onSelectedDateChange?.(newDate)

        trackDateSelect({
            selectionMethod: 'right_arrow',
            previousDate: selectedDate,
            selectedDate: newDate,
        })
    }

    function decrementDate() {
        const newDate = getPreviousDay(selectedDate, {
            excludeWeekendDays,
        }).format('YYYY-MM-DD')

        onSelectedDateChange?.(newDate)

        trackDateSelect({
            selectionMethod: 'left_arrow',
            previousDate: selectedDate,
            selectedDate: newDate,
        })
    }

    function handleDateSelect(date: Date) {
        const newDate = format(date, dateFormats.date)

        onSelectedDateChange?.(newDate)

        trackDateSelect({
            selectionMethod: 'drop_down',
            previousDate: selectedDate,
            selectedDate: newDate,
        })
    }

    // These are needed because <CustomDatePicker /> accepts date objects only
    const now = new Date()
    const selectedDateObject = selectedDate ? parseISO(selectedDate) : now
    const minDateObject = minDate ? parseISO(minDate) : now
    const maxDateObject = maxDate ? parseISO(maxDate) : now

    return (
        <ButtonGroup>
            <Button
                outline
                color="primary"
                onClick={decrementDate}
                data-test="decrement-date-button"
                disabled={
                    isEqual(selectedDateObject, minDateObject) ||
                    isBefore(selectedDateObject, minDateObject) ||
                    isLoading
                }
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
                outline
                color="primary"
                disabled
                className="border-right-0 border-left-0 opacity-100 tabular-font"
            >
                {format(selectedDateObject, 'MM/dd/yyyy')}
            </Button>
            <CustomDatePicker
                customInput={
                    <Button
                        outline
                        color="primary"
                        className="h-100 rounded-0 border-right-0"
                    >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </Button>
                }
                selected={selectedDateObject}
                excludeDates={exclusionDates}
                onSelect={handleDateSelect}
                minDate={minDateObject}
                maxDate={maxDateObject}
                popperPlacement="bottom-end"
            />
            <Button
                outline
                color="primary"
                onClick={incrementDate}
                disabled={
                    isEqual(selectedDateObject, maxDateObject) ||
                    isAfter(selectedDateObject, maxDateObject) ||
                    isLoading
                }
                data-test="increment-date-button"
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </ButtonGroup>
    )
}

export default WorkplaceCheckInsDatepicker
