import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

import FeatureFlagsPopover from '../../../devTools/FeatureFlagsPopover/index.tsx'

const linkId = 'feature-flags-popover'

const FeatureFlagsPopoverLink = () => {
    return (
        <Nav className="position-absolute" style={{ right: 5, bottom: 10 }}>
            <NavItem>
                <NavLink id={linkId} href="#">
                    🤖
                </NavLink>

                <FeatureFlagsPopover targetId={linkId} />
            </NavItem>
        </Nav>
    )
}

export default FeatureFlagsPopoverLink
