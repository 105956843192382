import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import qs from 'qs'

import { getDateRangeProperties } from '../../../util/analytics'
import { isModuleEnabled } from '../../../components/ModuleFlag/util'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import Metric from '../../../components/Metric'

const CommuterMetrics = ({
    period,
    getPath,
    setLastRefreshedTime,
    isMetricUnavailable,
    isMetricFetchCacheDisabled,
    onMetricError,
}) => {
    const { modules } = useSpaces()
    const isActivityGraphsEnabled = isModuleEnabled('activityGraphs', modules)
    const periodQueryString = qs.stringify(period)
    const dateRangeProperties = useMemo(() => getDateRangeProperties(period), [
        period,
    ])

    return (
        <>
            <Row>
                <Col xs="12" className="px-0">
                    <h4>Commuters</h4>
                </Col>
            </Row>
            <Row className="bg-white flex-nowrap shadow-sm p-0 mb-5">
                <Col xs="6" className="p-0">
                    <Metric
                        title="Active commuters"
                        urlPath={getPath(
                            '/spaces/:spaceId/metrics/activeCarpoolers\\?from=:fromDate&until=:untilDate&statisticType=:statisticType',
                            'sum'
                        )}
                        linkTo={
                            isActivityGraphsEnabled
                                ? `../activity/commuter?${periodQueryString}#active-commuters`
                                : undefined
                        }
                        isUnavailable={isMetricUnavailable}
                        setLastRefreshedTime={setLastRefreshedTime}
                        isFetchCacheDisabled={isMetricFetchCacheDisabled}
                        onMetricError={onMetricError}
                        analytics={{
                            category: 'performance_action',
                            name: 'active_carpoolers',
                            dateRangeProperties,
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

CommuterMetrics.propTypes = {
    period: PropTypes.shape({
        year: PropTypes.number.isRequired,
        quarter: PropTypes.number,
        month: PropTypes.number,
        week: PropTypes.number,
    }).isRequired,
    getPath: PropTypes.func.isRequired,
    setLastRefreshedTime: PropTypes.func.isRequired,
    isMetricUnavailable: PropTypes.bool.isRequired,
    isMetricFetchCacheDisabled: PropTypes.bool,
    onMetricError: PropTypes.func.isRequired,
}

export default CommuterMetrics
