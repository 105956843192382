import { OPEN_MODAL, CLOSE_MODAL } from './types'

export const createOpenModal = ({ name, data }) => ({
    type: OPEN_MODAL,
    payload: { name, data },
})

export const createCloseModal = () => ({
    type: CLOSE_MODAL,
})
