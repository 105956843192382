import toast from '../components/Toast/toast.tsx'

const showConnectionErrorToast = () =>
    toast.connectionError(
        'Failed to connect. Please check your internet connection.',
        {
            autoClose: false,
        }
    )

const dismissToasts = () => toast.dismiss()

export function unregisterBrowserConnectivityEvents() {
    window.removeEventListener('offline', showConnectionErrorToast)
    window.removeEventListener('offline', dismissToasts)
}

export default function registerBrowserConnectivityEvents() {
    // Register browser offline and online events.
    // These events are discouraged by the W3C, but the alternative is to use service workers, which would be overkill just to show this toast.
    window.addEventListener('offline', showConnectionErrorToast)

    window.addEventListener('online', dismissToasts)
}
