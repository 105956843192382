import { call, getContext, takeLatest, put, select } from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import moment from 'moment'
import {
    CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST,
    createCarpoolerDownloadActivatedSuccess,
    createCarpoolerDownloadActivatedFailure,
} from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'
import toast from '../../components/Toast/toast.tsx'

export const getUri = (spaceId) => `/spaces/${spaceId}/activatedCarpoolers`

export function* downloadActivatedCarpoolersCsv() {
    const dateString = moment().format('YYYY-MM-DD-HHMMSS')

    try {
        const { id: spaceId } = yield select(selectCurrentSpace)
        if (!spaceId) {
            throw new Error(
                `Space ID is required to download active commuter csv.`
            )
        }
        const cosmosApi = yield getContext('cosmosApi')
        const { blob } = yield call([cosmosApi, 'call'], getUri(spaceId), {
            headers: { accept: 'text/csv' },
        })
        const fileName = `ActiveCarpoolers_${dateString}.csv`
        yield call(saveAs, blob, fileName)
        toast.success('Successfully downloaded CSV')
        yield put(createCarpoolerDownloadActivatedSuccess())
    } catch (error) {
        toast.error('Unable to prepare your CSV. Please try again.')
        yield put(createCarpoolerDownloadActivatedFailure({ error }))
    }
}

export default function* watchDownloadActivatedCarpoolersCsv() {
    yield takeLatest(
        CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST,
        downloadActivatedCarpoolersCsv
    )
}
