import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { AgGridColumn } from 'ag-grid-react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { format } from 'date-fns'
import ViewDocumentationButtonColumnRenderer from './ViewDocumentationButtonColumnRenderer'
import AgGridWrapper from '../../components/AgGridWrapper'
import Header from '../../components/PageLayout/Header'
import { SearchBar } from '../../components/SearchBar/index'
import useTestingLogResource from '../../hooks/resources/useTestingLogResource'
import useSpaces from '../../hooks/useSpaces'
import ModuleFlag from '../../components/ModuleFlag/index'

const dateFormat = 'MM/dd/yy'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TestResultLog = (props: RouteComponentProps): JSX.Element => {
    const [searchFilterText, setSearchFilterText] = React.useState('')
    const { id: spaceId } = useSpaces()
    const { testingLog, isLoading } = useTestingLogResource(spaceId, [])

    return (
        <ModuleFlag
            name="diseaseTestResults"
            fallback={<Redirect to="/spaces" />}
        >
            <Container fluid>
                <Header
                    title="Testing log"
                    description="View your unvaccinated employees’ COVID-19 test results."
                />
                <Row>
                    <Col sm="5" className="mb-3 d-flex">
                        <SearchBar
                            placeholder="Search by name"
                            onSearch={setSearchFilterText}
                            onClear={() => setSearchFilterText('')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col data-test="testing-log-grid" className="mb-5">
                        <AgGridWrapper
                            isLoading={isLoading}
                            rowData={testingLog}
                            noRowsOverlayComponentParams={{
                                description: 'No testing records found.',
                            }}
                            frameworkComponents={{
                                viewDocumentationButtonColumnRenderer: ViewDocumentationButtonColumnRenderer,
                            }}
                            defaultColDef={{
                                resizable: true,
                                suppressMovable: true,
                                flex: 1,
                                sortable: true,
                                unSortIcon: true,
                            }}
                            enableCellTextSelection={true}
                            quickFilterText={searchFilterText}
                        >
                            <AgGridColumn
                                colId="name"
                                headerName="Name"
                                valueGetter={(params) =>
                                    `${
                                        params.data?.rosterRecord?.firstName ||
                                        ''
                                    } ${
                                        params.data?.rosterRecord?.lastName ||
                                        ''
                                    }`
                                }
                                pinned
                            />
                            <AgGridColumn
                                field="testDate"
                                headerName="Negative test date"
                                valueFormatter={(params) =>
                                    params.data.testDate
                                        ? format(
                                              new Date(params.data.testDate),
                                              dateFormat
                                          )
                                        : ''
                                }
                            />
                            <AgGridColumn
                                field="createdAt"
                                headerName="Date responded"
                                valueFormatter={(params) =>
                                    params.data.createdAt
                                        ? format(
                                              new Date(params.data.createdAt),
                                              dateFormat
                                          )
                                        : ''
                                }
                            />
                            <AgGridColumn
                                colId="viewDocumentationButton"
                                headerName="Documentation"
                                cellRenderer="viewDocumentationButtonColumnRenderer"
                            />
                        </AgGridWrapper>
                    </Col>
                </Row>
            </Container>
        </ModuleFlag>
    )
}

export default TestResultLog
