import moment from 'moment'
import jsPDF from 'jspdf'

export const PANEL_WIDTH = 750 // in pixels

export function generatePDFFilename({ label, checkInDate }) {
    return `Check_in_confirmation_${label}_${moment(checkInDate).format(
        'YYYY-MM-DD'
    )}.pdf`
}

// TODO ACTUALS - temporary utility to get pdf labels. Remove once actuals ship
function getPDFLabels(data) {
    if (data.emails) {
        return {
            label: data.emails[0],
            checkInDate: data.workAttendanceIndication.localCalendarDate,
        }
    }

    const { rosterRecord, localCalendarDate } = data
    const name = `${rosterRecord.firstName}${rosterRecord.lastName}`

    return { label: name, checkInDate: localCalendarDate }
}

export function generatePDF(domElement, spaceWorkAttendanceIndication) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF()

    doc.html(domElement, {
        callback() {
            const { label, checkInDate } = getPDFLabels(
                spaceWorkAttendanceIndication
            )

            doc.save(
                generatePDFFilename({
                    label,
                    checkInDate,
                })
            )
        },
        html2canvas: {
            // need to have html2canvas in dependencies
            width: PANEL_WIDTH,
            scale: 0.2,
        },
        x: 10,
        y: 10,
    })
}
