import { DropdownMenu } from 'reactstrap'
import styled from 'styled-components'

import DropdownToggle from '../../../components/DropdownToggle'

export const StyledToggle = styled(DropdownToggle)`
    min-width: 200px;
`

export const StyledDropdownMenu = styled(DropdownMenu)`
    min-width: 200px;
`
