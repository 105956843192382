import qs from 'qs'
import { useCallback, useMemo, useState, useEffect } from 'react'

import { getToggledSortOrder, sortOrderOptions } from '../../../util/sort'
import useServerPagination, { PaginatedData, Pagination } from '../index'

type Sort = {
    sortBy?: string
    sortOrder?: string
}

export type Filter = Record<string, unknown>

export type Options = {
    date?: string
    sort: Sort
    filter: Filter
    searchKeyword?: string
}

export type QueryOptions = Partial<Options> // sort and filter are optional

type Props = {
    path: string
    pageSize?: number
    defaultSort?: Sort
    defaultFilter?: Filter
    defaultDate?: string
    defaultSearch?: string
    getQuery?: (queryOptions: QueryOptions) => Record<string, unknown>
}

export type UseServerPaginationWithQuery<T> = {
    getData: (newOptions: Record<string, unknown>) => void
    handleFilter: (filter: Record<string, unknown>) => void
    handleSort: (sortBy: string) => void
    handleSearch: (search: string) => void
    handleDate: (date: string) => void
    isLoading: boolean
    error?: Error
    paginatedData: PaginatedData<T>
    totalCount: number
    pagination: Pagination
    options: Options
}

function defaultGetQuery({ date, filter, sort, searchKeyword }) {
    return {
        ...filter,
        date,
        sortBy: sort?.sortBy,
        sortOrder: sort?.sortOrder,
        q: searchKeyword || undefined,
    }
}

function useServerPaginationWithQuery<T>(
    {
        path,
        pageSize,
        getQuery = defaultGetQuery,
        defaultSort = {},
        defaultFilter = {},
        defaultDate,
        defaultSearch,
    }: Props,
    dependencies?: []
): UseServerPaginationWithQuery<T> {
    const [date, setDate] = useState(defaultDate)
    const [sortOptions, setSortOptions] = useState(defaultSort)
    const [filterOptions, setFilterOptions] = useState(defaultFilter)
    const [searchKeyword, setSearchKeyword] = useState(defaultSearch)

    const {
        loading,
        error,
        fetchData,

        paginatedData,
        totalCount,
        resetPagination,

        pagination,
    } = useServerPagination<T>({
        pageSize,
        path,
    })

    const options = useMemo(
        () => ({
            date,
            sort: sortOptions,
            filter: filterOptions,
            searchKeyword,
        }),
        [date, sortOptions, filterOptions, searchKeyword]
    )

    /**
     * Set sort, filter, or search options. If not passed, the option is reset.
     *
     * @param {Object} [options]
     * @property {Object} [options.sort] - example: { sort: { sortBy: "email", sortOrder: "desc" }}
     * @property {Object} [options.filter] - filter by city. example {filter: { cities: [...] }}
     * @property {String} [options.searchKeyword] - search keyword.
     */
    const setOptions = useCallback((options = {}) => {
        setDate(options.date)
        setSortOptions(options.sort || {})
        setFilterOptions(options.filter || {})
        setSearchKeyword(options.searchKeyword)
    }, [])

    /**
     * Fetch with the given options.
     *
     * @param {Object} [newOptions] - sort, filter, or search options. Will get merged into existing options, but will take precendence.
     */
    const getData = useCallback(
        (newOptions = {}) => {
            const mergedOptions = { ...options, ...newOptions }
            const query = getQuery(mergedOptions)

            const queryString = qs.stringify(query, {
                addQueryPrefix: true,
                arrayFormat: 'repeat',
            })

            // console.log('queryString', queryString)

            resetPagination()
            setOptions(mergedOptions)
            fetchData(queryString)
        },
        [setOptions, fetchData, resetPagination, options, getQuery]
    )

    const handleSort = useCallback(
        (sortBy) => {
            const sortOrder = getToggledSortOrder(sortBy, options.sort)
            getData({
                ...options,
                sort: { sortBy, sortOrder },
            })
        },
        [options, getData]
    )

    const handleFilter = useCallback(
        (filter = {}) => {
            getData({
                filter,
            })
        },
        [getData]
    )

    const handleSearch = useCallback(
        (searchKeyword) => {
            getData({
                searchKeyword,
            })
        },
        [getData]
    )

    const handleDate = useCallback(
        (date) => {
            getData({ date })
        },
        [getData]
    )

    useEffect(() => {
        // Immediately fetch if dependencies is an array
        if (Array.isArray(dependencies)) {
            getData()
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, dependencies)

    return {
        getData,
        handleFilter,
        handleSort,
        handleSearch,
        handleDate,
        isLoading: loading,
        error,

        paginatedData,
        totalCount,

        options,
        pagination,
    }
}

export { sortOrderOptions }

export default useServerPaginationWithQuery
