import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import { AgGridColumn } from 'ag-grid-react'
import { shouldHideEmploymentType } from 'pages/RosterManagement/Table'
import AgGridWrapper from '../../../../components/AgGridWrapper'
import { RosterRecord } from '../../../../types'

type Props = {
    rosterRecords?: RosterRecord[]
    rosterRecordActionCopy: 'added' | 'deactivated' | 'updated'
}

const RosterRecordsPreviewToggle = ({
    rosterRecords,
    rosterRecordActionCopy,
}: Props): JSX.Element | null => {
    const [showPreview, setShowPreview] = useState(false)
    const toggleShowPreview = () => setShowPreview((prev) => !prev)

    if (!rosterRecords) {
        return null
    }

    const infoCopy = `${rosterRecords.length} item${
        rosterRecords.length !== 1 ? 's' : ''
    } ${rosterRecordActionCopy}`

    return (
        <div className="d-flex align-items-start flex-column">
            {rosterRecords.length ? (
                <Button
                    onClick={toggleShowPreview}
                    color="link"
                    className="pl-0 text-dark text-decoration-none"
                    data-test="toggle-btn"
                >
                    <FontAwesomeIcon
                        size="1x"
                        icon={showPreview ? faAngleDown : faAngleRight}
                        className="mr-2"
                    />
                    <strong>{infoCopy}</strong>
                </Button>
            ) : (
                <strong>{infoCopy}</strong>
            )}
            <Collapse isOpen={showPreview} className="w-100 py-2">
                <AgGridWrapper
                    data-test="preview-grid"
                    domLayout="normal"
                    containerStyle={{ height: 340 }}
                    isLoading={false}
                    rowData={rosterRecords}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: true,
                    }}
                >
                    <AgGridColumn field="firstName" pinned />
                    <AgGridColumn field="lastName" />
                    <AgGridColumn field="email" />
                    <AgGridColumn field="externalIdentifier" />
                    <AgGridColumn
                        field="department.label"
                        headerName="Department"
                        colId="department"
                    />
                    <AgGridColumn
                        field="manager.managerIdentifyingTraits.email"
                        headerName="Manager Email"
                        colId="managerEmail"
                    />
                    {!shouldHideEmploymentType(rosterRecords) ? (
                        <AgGridColumn field="employmentType" headerName="Employment Type" />
                    ) : null }
                </AgGridWrapper>
            </Collapse>
        </div>
    )
}

export default RosterRecordsPreviewToggle
