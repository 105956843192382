import React from 'react'
import PropTypes from 'prop-types'
import { DropdownToggle as ReactStrapDropdownToggle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'

/**
 * React Strap DropdownToggle with a customized caret icon.
 */
const DropdownToggle = ({ children, hasCaret = true, ...otherProps }) => {
    return (
        <ReactStrapDropdownToggle {...otherProps}>
            {children}
            {hasCaret && (
                <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ marginLeft: 10 }}
                />
            )}
        </ReactStrapDropdownToggle>
    )
}

DropdownToggle.propTypes = {
    hasCaret: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

export default DropdownToggle
