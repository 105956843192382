import moment from 'moment'

import {
    dateFormat,
    isFriday,
    isMonday,
    isSaturday,
    isSunday,
    monthDayFormat,
} from '../../util/date'

const TUESDAY = 2
const THURSDAY = 4
const FRIDAY = 5
const NEXT_MONDAY = 8
const NEXT_TUESDAY = 9
const LAST_FRIDAY = -2

/**
 * Gets the date of the last open time card - next Friday.
 *
 * TODO: subject to change logic
 */
export function getDateOfLastOpenTimeCard() {
    return moment().add(1, 'week').isoWeekday(FRIDAY).format('YYYY-MM-DD')
}

/**
 * Returns an array of days for which to display metrics. Will exclude weekend days if specified.
 *
 * @param {Boolean} options.excludeWeekendDays whether to exclude weekend days from the array
 */
export function getWorkplaceHeaderMetricDates({
    excludeWeekendDays = false,
} = {}) {
    const today = moment()

    let daysToReturn = []

    if (excludeWeekendDays) {
        if (isFriday(today) || isSaturday(today)) {
            daysToReturn = [
                moment().isoWeekday(THURSDAY),
                moment().isoWeekday(FRIDAY),
                moment().isoWeekday(NEXT_MONDAY),
            ]
        }

        if (isSunday(today)) {
            daysToReturn = [
                moment().isoWeekday(FRIDAY),
                moment().isoWeekday(NEXT_MONDAY),
                moment().isoWeekday(NEXT_TUESDAY),
            ]
        }

        if (isMonday(today)) {
            daysToReturn = [
                moment().isoWeekday(LAST_FRIDAY),
                today,
                moment().isoWeekday(TUESDAY),
            ]
        }
    }

    // If it is not a special case around the weekend, just take yesterday, today, and tomorrow
    if (!daysToReturn.length) {
        daysToReturn = [
            today.clone().subtract(1, 'day'),
            today,
            today.clone().add(1, 'day'),
        ]
    }

    return daysToReturn.map(getMetricDayFormat)
}

/**
 * From a moment object, returns properties about that day.
 *
 * @param {*} momentDay - the day
 *
 * @typedef {Object} DateProps
 * @property {String} date - YYYY-MM-DD formatted date
 * @property {String} title - The title for the day, usually the day name and date
 * @property {String} dayAfterDate - YYYY-MM-DD formatted date for the day following the given date
 *
 * @returns {DateProps} dateProps
 */
function getMetricDayFormat(momentDay) {
    const yesterday = moment().subtract(1, 'day')
    const today = moment()
    const tomorrow = moment().add(1, 'day')

    const isYesterday = momentDay.isSame(yesterday, 'day')
    const isToday = momentDay.isSame(today, 'day')
    const isTomorrow = momentDay.isSame(tomorrow, 'day')

    let titlePrefix = momentDay.format('dddd') // Day name
    if (isYesterday) {
        titlePrefix = 'Yesterday'
    } else if (isToday) {
        titlePrefix = 'Today'
    } else if (isTomorrow) {
        titlePrefix = 'Tomorrow'
    }

    return {
        date: momentDay.format(dateFormat),
        title: `${titlePrefix} ${momentDay.format(monthDayFormat)}`,
        dayAfterDate: momentDay.clone().add(1, 'day').format(dateFormat),
    }
}
