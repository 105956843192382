import { Redirect, useLocation, useMatch } from '@reach/router'
import { bool, node } from 'prop-types'
import React from 'react'

import NoAccess from '../../components/Error/NoAccess'
import TemporarilyUnavailable from '../../components/Error/TemporarilyUnavailable'
import LayoutWithBreadcrumbs from '../../components/LayoutWithBreadcrumbs'
import SideBar from '../../components/SideBar'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import TopNavBar from '../../components/TopNavBar'
import useCheckSession from '../../hooks/useCheckSession'
import useFetchSpaces from '../../hooks/useFetchSpaces'
import useFetchSpaceSettings from '../../hooks/useFetchSpaceSettings'
import BreadcrumbsProvider from '../../providers/BreadcrumbsProvider.tsx'
import SpacesProvider from '../../providers/SpacesProvider.tsx'
import { useUser } from '../../providers/UserProvider'
import generatePathUrl from '../../util/generatePathUrl'

/**
 * Spaces Provider
 *
 * Responsible for:
 * - Ensuring that we resolve the space alias and fetch the current space and user session
 * - Ensuring that we show a full page spinner while content is being received
 * - Ensuring that we Redirect /spaces to /spaces/:spaceAlias path after space alias has been resolved
 * - Provides current space data (id, name, alias, settings) via context to child components
 *
 * @param {children} - all child routes of the /spaces route
 */

const Spaces = ({ children, withLayout = true }) => {
    const { user } = useUser()
    const location = useLocation()
    const match = useMatch('/spaces/:spaceAlias/*')
    const spaceAlias = match ? match.spaceAlias : null
    const aliasPath = match && match['*']

    // Check for a session first before calling spaces hooks!
    const { isFetching: isFetchingCheckSession } = useCheckSession({
        redirect: true,
        next: location.pathname, // bring the user back here after signing in.
    })

    const {
        error: spacesError,
        isFetching: isFetchingSpaceId,
        currentSpace,
    } = useFetchSpaces({ spaceAlias })

    const {
        error: spaceSettingsError,
        loading: isFetchingSpaceSettings,
        spaceSettings,
    } = useFetchSpaceSettings(currentSpace?.id)

    const isLoading =
        isFetchingSpaceId ||
        isFetchingCheckSession ||
        isFetchingSpaceSettings ||
        !spaceSettings

    const error = spacesError || spaceSettingsError

    if (error) {
        const { statusCode } = error

        const isServerError =
            statusCode && statusCode >= 500 && statusCode < 600

        if (isServerError) {
            return <TemporarilyUnavailable />
        } else {
            return <NoAccess />
        }
    }

    if (isLoading) {
        return <SpinnerFullPage />
    }

    // Check if url contains /spaceAlias or one if its child paths
    // If currentSpace exists, we simply redirect to /spaces/:spaceAlias
    if (!spaceAlias && !aliasPath && currentSpace.alias) {
        return (
            <Redirect
                to={generatePathUrl('/spaces/:spaceAlias', {
                    spaceAlias: currentSpace.alias,
                })}
                noThrow
            />
        )
    }

    const spacesContextValue = {
        ...currentSpace,
        ...spaceSettings,
    }

    return (
        <SpacesProvider value={spacesContextValue}>
            {withLayout ? (
                <div className="d-flex flex-row h-100">
                    <SideBar />

                    <div className="d-flex flex-column w-100">
                        <TopNavBar user={user} />
                        <BreadcrumbsProvider>
                            <LayoutWithBreadcrumbs>
                                {children}
                            </LayoutWithBreadcrumbs>
                        </BreadcrumbsProvider>
                    </div>
                </div>
            ) : (
                children
            )}
        </SpacesProvider>
    )
}

Spaces.propTypes = {
    children: node,
    withLayout: bool,
}

export default Spaces
