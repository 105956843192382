import React from 'react'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'

// These are components to pass to the extraInfo prop in the time series data functions in ../util.js
// For example, getMonthlyTimeSeriesDataForReports() will generate an array of objects
// to be rendered as rows by the <ReportsTable /> Component. Some rows will have an
// "extraInfo" property which will be one of these components.

export const StaleData = (): JSX.Element => (
    <span className="text-muted">
        <FontAwesomeIcon
            className="text-danger mx-2"
            icon={faExclamationTriangle}
        />
        Trip data hasn&apos;t been refreshed in 48 hours. We&apos;re aware of
        and working on fixing the problem.
    </span>
)

export const StillPopulating = (): JSX.Element => (
    <span className="text-primary mx-2">
        This report is populating and will be available in less than 48 hours.
    </span>
)

export const YearToDate = (): JSX.Element => (
    <span className="text-muted">Year-to-date</span>
)

export const UnavailableData = (): JSX.Element => (
    <span data-test="unavailable-data" className="text-muted">
        <FontAwesomeIcon
            className="text-danger mx-2"
            icon={faExclamationTriangle}
        />
        Trip data isn&apos;t the most up-to-date. We&apos;re aware of and
        working on fixing the problem.
    </span>
)

type Props = {
    content: string
}

export const TooltipWithWarningIcon = ({ content }: Props): JSX.Element => {
    return (
        <span>
            <FontAwesomeIcon
                style={{ pointerEvents: 'all' }}
                className="text-danger mx-2"
                icon={faExclamationTriangle}
                id="warning-icon"
            />
            <UncontrolledTooltip target="warning-icon">
                {content}
            </UncontrolledTooltip>
        </span>
    )
}
