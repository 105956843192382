import React, { PropsWithChildren, useState, DragEvent } from 'react'
import styled from 'styled-components'

import dragAndDropPlusIconImg from '../../images/drag-and-drop-plus-icon.svg'

type Props = PropsWithChildren<{
    onFileChange: (file: File) => void
    className?: string
}>

export const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 1;
`

/**
 * Copied from chrome-extension
 *
 * Can drag and drop a file to the target element
 */
const FileDragAndDrop = ({
    onFileChange,
    className,
    children,
}: Props): JSX.Element => {
    const [inDropZone, setInDropZone] = useState(false)

    function handleDragEnter(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        setInDropZone(true)
    }

    function handleDragOver(e: DragEvent) {
        if (e.dataTransfer?.dropEffect) {
            e.dataTransfer.dropEffect = 'copy'
        }

        e.preventDefault()
        e.stopPropagation()
    }

    function handleDrop(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        setInDropZone(false)

        const file = e.dataTransfer?.files[0]

        if (file) {
            onFileChange(file)
        }
    }

    function handleDragLeave(e: DragEvent) {
        // dragleave fires when hovering over child element, so we need to stop it
        // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
        //
        // Casting "as Node" is inevitable.
        // https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node
        if (e.currentTarget?.contains(e.relatedTarget as Node)) {
            return
        }

        e.preventDefault()
        e.stopPropagation()
        setInDropZone(false)
    }

    return (
        <div
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            className={className}
        >
            {inDropZone && (
                <Overlay className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">
                    <img src={dragAndDropPlusIconImg} />
                </Overlay>
            )}

            {children}
        </div>
    )
}

export default FileDragAndDrop
