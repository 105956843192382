import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import { isFirstPeriodInRange } from '../../../../util/chart/index.tsx'

function getChartState(isLoading, error) {
    if (error) {
        return 'error'
    }

    if (isLoading) {
        return 'loading'
    }

    return 'loaded'
}

function getIsDataIncomplete(data, startDate) {
    if (!startDate) {
        return false
    }

    if (!data) {
        return false
    }

    return isFirstPeriodInRange({
        statistics: data.statistics,
        startDate,
    })
}

/**
 * This HOC is intended to be used for <Chart />.
 * This adds a feature where onVisible event is called when <Chart /> is visible.
 */
const withVisibilitySensor = (Chart) => {
    const ChartWithVisibilitySensor = (props) => {
        const { isLoading, error, data, onVisible, tripsStartDate } = props

        const handleVisibilityChange = (isVisible) => {
            // These constants are used by chart analytics events.
            const chartState = getChartState(isLoading, error)
            const isDataIncomplete = getIsDataIncomplete(data, tripsStartDate)

            onVisible({ isVisible, chartState, isDataIncomplete })
        }

        return (
            <InView onChange={handleVisibilityChange}>
                <Chart {...props} />
            </InView>
        )
    }

    ChartWithVisibilitySensor.propTypes = {
        isLoading: PropTypes.bool.isRequired,
        error: PropTypes.object,
        data: PropTypes.shape({
            statistics: PropTypes.array,
        }),
        onVisible: PropTypes.func.isRequired,
        tripsStartDate: PropTypes.string,
    }

    return ChartWithVisibilitySensor
}

export default withVisibilitySensor
