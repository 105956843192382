import qs from 'qs'

export const BLACK_LISTED_QUERY_STRING_NAMES = ['token']
export const REDACTED_VALUE = 'redacted-for-security'

const cleanUrl = (url) => {
    const parsed = new URL(url)
    if (parsed.search) {
        parsed.search = cleanSearch(parsed.search)
    }
    return parsed
}

const cleanSearch = (search) => {
    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    for (const name of BLACK_LISTED_QUERY_STRING_NAMES) {
        if (name in parsed) {
            parsed[name] = REDACTED_VALUE
        }
    }
    return qs.stringify(parsed)
}

const cleanLocation = (location) => {
    return cleanUrl(location.href)
}

export default cleanLocation
