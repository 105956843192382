/**
 * Checks to see if a particular module is enabled.
 * The modules object is a tree and can have multiple levels. This function recurses down the levels.
 *
 * @param {*} modulePath the module path to follow. The different levels are separated by a dot.
 * @param {*} parentModule for the recursive invocation, defaults to no parent.
 */
export function isModuleEnabled(modulePath, parentModule = {}) {
    const firstDotIndex = modulePath.indexOf('.')

    // base case - modulePath is a module name.
    if (firstDotIndex === -1) {
        const leafModule = parentModule[modulePath]
        return Boolean(leafModule?.isEnabled)
    }

    const moduleName = modulePath.slice(0, firstDotIndex)
    const subModulePath = modulePath.slice(firstDotIndex + 1, modulePath.length)
    const childModule = parentModule[moduleName]

    if (!childModule?.isEnabled) {
        return false
    }

    // Recursively check its child module exists/is enabled.
    return isModuleEnabled(subModulePath, childModule)
}

/**
 * Checks to see one or multiple modules are enabled.
 * Takes in 'comparator' argument that determines whether to check that ALL modules are enabled, or if AT LEAST ONE is enabled.
 *
 * @param {*} moduleNames array of module names as strings
 * @param {*} modules space setting modules object
 * @param {*} comparator either 'some', or 'every', defaults to 'every'
 */
export function getAreModulesEnabled(
    moduleNames,
    modules,
    comparator = 'every'
) {
    // const { names, comparator } = modulesToTest
    if (comparator === 'some') {
        return moduleNames.some((n) => isModuleEnabled(n, modules))
    } else if (comparator === 'every') {
        return moduleNames.every((n) => isModuleEnabled(n, modules))
    }

    return false
}
