import { Redirect, Router } from '@reach/router'
import React from 'react'

import ModuleFlag from '../../../components/ModuleFlag'
import CommuterActivity from '../../CommuterActivity'
import Overview from '../../Overview/index.tsx'
import TripActivity from '../../TripActivity'

const Routes = () => {
    return (
        <Router primary={false}>
            <Redirect from="/" to="overview" noThrow />
            <Overview path="overview" />

            <ModuleFlag
                path="activity"
                name="activityGraphs"
                fallback={<Redirect to="../" noThrow />}
            >
                <CommuterActivity path="commuter" />
                <TripActivity path="trip" />
            </ModuleFlag>
        </Router>
    )
}

export default Routes
