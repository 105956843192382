import React, { useState } from 'react'
import { Col, Container, Row, Tooltip } from 'reactstrap'

import LinkButton from '../../components/LinkButton'
import srcLocations from '../../images/locations.svg'
import { Header } from '../../components/PageLayout'

type Props = {
    spaceAlias: string
}

const NoRosterRecords = ({ spaceAlias }: Props): JSX.Element => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    return (
        <Container data-test="no-roster-records" size="xl">
            <Header title="Roster records" />
            <Row className="pt-2 justify-content-center">
                <Col md="5">
                    <img className="mx-auto my-4 d-block " src={srcLocations} />
                    <h4 className="text-center mb-4">
                        Upload CSV to populate roster
                    </h4>
                    <p>Your CSV should have at least these 4 columns:</p>
                    <ol>
                        <li>First name</li>
                        <li>Last name</li>
                        <li>Email</li>
                        <li>
                            <a href="#" id="employeeId">
                                Employee ID
                            </a>
                        </li>
                    </ol>
                    <p>You can also include the following optional column:</p>
                    <ul>
                        <li>Department</li>
                    </ul>
                    <p className="text-muted">
                        <small>
                            Note: Once the CSV is uploaded, an invite email will
                            be sent to all new roster additions to sign up.
                            (Reactivated employees won&apos;t receive an email
                            and can continue using their previous account)
                        </small>
                    </p>
                    <Tooltip
                        target="employeeId"
                        isOpen={isTooltipOpen}
                        toggle={() => setIsTooltipOpen(!isTooltipOpen)}
                    >
                        You can use an employee ID or any other unique
                        identifier for the employee, such as their email
                        address.
                    </Tooltip>
                    <LinkButton
                        isBlock
                        to={`/spaces/${spaceAlias}/roster/upload/choose-csv`}
                    >
                        Upload CSV
                    </LinkButton>
                </Col>
            </Row>
        </Container>
    )
}

export default NoRosterRecords
