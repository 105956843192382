import {
    CARPOOLER_DEACTIVATE_REQUEST,
    CARPOOLER_DEACTIVATE_SUCCESS,
    CARPOOLER_DEACTIVATE_FAILURE,
    CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST,
    CARPOOLERS_QUERY_FILTER_PARAMS_SUCCESS,
    CARPOOLERS_QUERY_FILTER_PARAMS_FAILURE,
    CARPOOLERS_ACTIVATED_ADD,
    CARPOOLERS_ACTIVATED_REPLACE,
    CARPOOLERS_UPDATE_QUERY_TOKEN,
    CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST,
    CARPOOLERS_DOWNLOAD_ACTIVATED_SUCCESS,
    CARPOOLERS_DOWNLOAD_ACTIVATED_FAILURE,
    CARPOOLER_ACTIVATED_ALL_RESET,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_SUCCESS,
    CARPOOLER_DEACTIVATIONS_DOWNLOAD_FAILURE,
    CARPOOLER_DEACTIVATIONS_DATA_REPLACE,
    CARPOOLER_DEACTIVATIONS_PAGINATION_REPLACE,
    CARPOOLER_DEACTIVATIONS_GET_REQUEST,
    CARPOOLER_DEACTIVATIONS_GET_SUCCESS,
    CARPOOLER_DEACTIVATIONS_GET_FAILURE,
    CARPOOLER_DEACTIVATIONS_DELETE_REQUEST,
    CARPOOLER_DEACTIVATIONS_DELETE_SUCCESS,
    CARPOOLER_DEACTIVATIONS_DELETE_FAILURE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST,
    CARPOOLER_DEACTIVATIONS_UPLOAD_SUCCESS,
    CARPOOLER_DEACTIVATIONS_UPLOAD_FAILURE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_RESET,
    CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_REPLACE,
    CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_RESET,
    CARPOOLER_DEACTIVATIONS_METADATA_REPLACE,
    CARPOOLER_DEACTIVATIONS_TOTAL_COUNT_UPDATE,
    CARPOOLERS_PAGINATE_REQUEST,
    CARPOOLERS_PAGINATE_REQUEST_SUCCESS,
    CARPOOLERS_PAGINATE_REQUEST_FAILURE,
    CARPOOLERS_TOTAL_COUNT_UPDATE,
    CARPOOLER_DEACTIVATE_RESET,
    CARPOOLER_DEACTIVATIONS_ALL_RESET,
} from './types'

export const createCarpoolersAddActivated = ({ activated }) => ({
    type: CARPOOLERS_ACTIVATED_ADD,
    payload: { activated },
})

export const createCarpoolersReplaceActivated = ({ activated }) => ({
    type: CARPOOLERS_ACTIVATED_REPLACE,
    payload: { activated },
})

export const createCarpoolerDeactivateRequest = ({ carpoolerId }) => ({
    type: CARPOOLER_DEACTIVATE_REQUEST,
    payload: {
        carpoolerId,
    },
})

export const createCarpoolerDeactivateSuccess = ({ carpoolerId }) => ({
    type: CARPOOLER_DEACTIVATE_SUCCESS,
    payload: {
        carpoolerId,
    },
})

export const createCarpoolerDeactivateFailure = ({ carpoolerId, error }) => ({
    type: CARPOOLER_DEACTIVATE_FAILURE,
    payload: {
        carpoolerId,
        error,
    },
})

export const createCarpoolerDeactivateReset = () => ({
    type: CARPOOLER_DEACTIVATE_RESET,
})

export const createActivatedCarpoolersQueryTokenUpdate = ({ queryToken }) => ({
    type: CARPOOLERS_UPDATE_QUERY_TOKEN,
    payload: {
        queryToken,
    },
})

export const createActivatedCarpoolersQueryFilterRequest = ({
    pageNumber,
    query,
    sortBy,
    sortOrder,
}) => ({
    type: CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST,
    payload: {
        pageNumber,
        query,
        sortBy,
        sortOrder,
    },
})

export const createActivatedCarpoolersQueryFilterSuccess = () => ({
    type: CARPOOLERS_QUERY_FILTER_PARAMS_SUCCESS,
})

export const createActivatedCarpoolersQueryFilterFailure = ({ error }) => ({
    type: CARPOOLERS_QUERY_FILTER_PARAMS_FAILURE,
    error: true,
    payload: error,
})

export const createCarpoolerDownloadActivatedRequest = () => ({
    type: CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST,
})

export const createCarpoolerDownloadActivatedSuccess = () => ({
    type: CARPOOLERS_DOWNLOAD_ACTIVATED_SUCCESS,
})

export const createCarpoolerDownloadActivatedFailure = ({ error }) => ({
    type: CARPOOLERS_DOWNLOAD_ACTIVATED_FAILURE,
    error: true,
    payload: error,
})

// Reset all active carpoolers reducers
export const createCarpoolerAllReset = () => ({
    type: CARPOOLER_ACTIVATED_ALL_RESET,
})

// Download Deactivations
export const createCarpoolerDeactivationsDownloadRequest = () => ({
    type: CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST,
})

export const createCarpoolerDeactivationsDownloadSuccess = () => ({
    type: CARPOOLER_DEACTIVATIONS_DOWNLOAD_SUCCESS,
})

export const createCarpoolerDeactivationsDownloadFailure = ({ error }) => ({
    type: CARPOOLER_DEACTIVATIONS_DOWNLOAD_FAILURE,
    error: true,
    payload: error,
})

// Deactivations Data
export const createCarpoolerDeactivationsDataReplace = ({ deactivations }) => ({
    type: CARPOOLER_DEACTIVATIONS_DATA_REPLACE,
    payload: deactivations,
})

// Deactivations Pagination
export const createCarpoolerDeactivationsPaginationReplace = ({
    limit,
    page,
    query,
    queryToken,
    sortBy,
    sortOrder,
    isLastPage,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_PAGINATION_REPLACE,
    payload: {
        limit,
        page,
        query,
        queryToken,
        sortBy,
        sortOrder,
        isLastPage,
    },
})

// Get Deactivations
export const createCarpoolerDeactivationsGetRequest = ({
    page,
    limit,
    query,
    prefetchPageCount,
    sortBy,
    sortOrder,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_GET_REQUEST,
    payload: {
        page,
        limit,
        query,
        prefetchPageCount,
        sortBy,
        sortOrder,
    },
})

export const createCarpoolerDeactivationsGetSuccess = () => ({
    type: CARPOOLER_DEACTIVATIONS_GET_SUCCESS,
})

export const createCarpoolerDeactivationsGetFailure = ({ error }) => ({
    type: CARPOOLER_DEACTIVATIONS_GET_FAILURE,
    error: true,
    payload: error,
})

export const createCarpoolerDeactivationsDeleteRequest = ({
    deactivationId,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_DELETE_REQUEST,
    payload: { deactivationId },
})

export const createCarpoolerDeactivationsDeleteSuccess = ({
    deactivationId,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_DELETE_SUCCESS,
    payload: { deactivationId },
})

export const createCarpoolerDeactivationsDeleteFailure = ({
    deactivationId,
    error,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_DELETE_FAILURE,
    error: true,
    payload: {
        deactivationId,
        error,
    },
})

// Deactivations Upload
export const createCarpoolerDeactivationsUploadRequest = ({
    csv,
    dryRun = true,
    redirectOnSuccess = false,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST,
    payload: {
        csv,
        dryRun,
        redirectOnSuccess,
    },
})

export const createCarpoolerDeactivationsUploadSuccess = () => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_SUCCESS,
})

export const createCarpoolerDeactivationsUploadFailure = ({ error }) => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_FAILURE,
    error: true,
    payload: error,
})

export const createCarpoolerDeactivationsUploadReset = () => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_RESET,
})

// Deactivations Preview
export const createCarpoolerDeactivationsUploadPreviewReplace = ({
    successfulEmails,
    failedEmails,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_REPLACE,
    payload: { successfulEmails, failedEmails },
})

export const createCarpoolerDeactivationsUploadPreviewReset = () => ({
    type: CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_RESET,
})

export const createCarpoolerDeactivationsMetadataReplace = ({
    page,
    query,
    sortBy,
    sortOrder,
    isLastPage,
}) => ({
    type: CARPOOLER_DEACTIVATIONS_METADATA_REPLACE,
    payload: {
        page,
        query,
        sortBy,
        sortOrder,
        isLastPage,
    },
})

export const createCarpoolerPaginationRequest = (currentPage) => ({
    type: CARPOOLERS_PAGINATE_REQUEST,
    payload: {
        currentPage,
    },
})

export const createCarpoolerTotalCountUpdate = (totalCount) => ({
    type: CARPOOLERS_TOTAL_COUNT_UPDATE,
    payload: {
        totalCount,
    },
})

export const createCarpoolerDeactivationsTotalCountUpdate = (totalCount) => ({
    type: CARPOOLER_DEACTIVATIONS_TOTAL_COUNT_UPDATE,
    payload: {
        totalCount,
    },
})

export const createCarpoolerPaginationRequestSuccess = () => ({
    type: CARPOOLERS_PAGINATE_REQUEST_SUCCESS,
})

export const createCarpoolerPaginationRequestFailure = (error) => ({
    type: CARPOOLERS_PAGINATE_REQUEST_FAILURE,
    payload: error,
    error: true,
})

export const createCarpoolerDeactivationsAllReset = () => ({
    type: CARPOOLER_DEACTIVATIONS_ALL_RESET,
})
