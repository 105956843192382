import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import useAnalytics from '../../../hooks/useAnalytics'

const DataNotReady = (props) => {
    const { track } = useAnalytics()

    useEffect(() => {
        track('performance_action', {
            action: 'View',
            label: 'data_not_ready',
        })
    }, [track])

    return (
        <Row>
            <Col className="px-0">
                <p className="h4">
                    Data may take up to 12 hours to populate, please check back
                    later.
                </p>
            </Col>
        </Row>
    )
}

export default DataNotReady
