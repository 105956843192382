import React, { useContext, useMemo } from 'react'
import useGenericResource, {
    getDefaultuseGenericResource,
    useGenericResourceType,
} from '../hooks/resources/useGenericResource'

import { HrisIntegration, CalendarIntegration } from '../types'
import useSpaces from '../hooks/useSpaces/index'

export type IntegrationsContextType = {
    hris: useGenericResourceType<HrisIntegration[]>
    connectedHrisIntegration?: HrisIntegration
    calendarIntegrations: useGenericResourceType<CalendarIntegration[]>
    connectedCalendarIntegration?: CalendarIntegration
}

export const defaultIntegrationsContextValue = {
    hris: getDefaultuseGenericResource<HrisIntegration[]>(),
    calendarIntegrations: getDefaultuseGenericResource<CalendarIntegration[]>(),
}

export const IntegrationsContext = React.createContext<IntegrationsContextType>(
    defaultIntegrationsContextValue
)

export const useIntegrations = (): IntegrationsContextType => {
    const context = useContext(IntegrationsContext)

    if (!context) {
        throw new Error(
            'useIntegrations must be wrapped in the IntegrationsProvider'
        )
    }
    return {
        ...context,
    }
}

/**
 * Provider for the context that houses HRIS, SSO and other 3rd party integrations
 */
const IntegrationsProvider = ({
    children,
}: {
    children: JSX.Element
}): JSX.Element => {
    const { id: spaceId } = useSpaces()
    const hrisFetch = useGenericResource<HrisIntegration[]>(
        `/spaces/${spaceId}/integrations/hris`,
        []
    )
    const calendarIntegrations = useGenericResource<CalendarIntegration[]>(
        `/spaces/${spaceId}/integrations/calendars`
    )

    const connectedHrisIntegration = useMemo(() => {
        return (hrisFetch.data || []).find(
            (hrisIntegration) => hrisIntegration.workosDirectory?.connected
        )
    }, [hrisFetch])

    const value = {
        hris: hrisFetch,
        connectedHrisIntegration,
        calendarIntegrations,
    }

    return (
        <IntegrationsContext.Provider value={value}>
            {children}
        </IntegrationsContext.Provider>
    )
}

export default IntegrationsProvider
