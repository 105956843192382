import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import styled from 'styled-components'

import { RosterRecord, RosterStatus, InvitationStatus } from '../../types'
import useModal from '../../hooks/useModal'
import MenuDropdownWithActions, {
    DropdownItemType,
} from '../../components/MenuDropdownWithActions'
import { modalTypeMap } from '../../components/ReduxModal/helpers'

export const ParagraphRespectingLineBreaks = styled.p`
    white-space: pre-line;
`

function getActionDescription({
    status,
    invitationStatus,
    firstName,
    lastName,
}: {
    status: RosterStatus
    invitationStatus: InvitationStatus
    firstName: string
    lastName: string
}): string {
    if (
        status === RosterStatus.pending &&
        invitationStatus !== InvitationStatus.notYetInvited
    ) {
        return `${firstName} ${lastName} will receive another invite via email.\n\nThe email will be sent from Scoop, containing a link for ${firstName} to accept the invitation and create an account.`
    }

    return `${firstName} ${lastName} will receive an invite via email.\n\nThe email will be sent from Scoop, containing a link for ${firstName} to accept the invitation and create an account.`
}

type Props = {
    sendInvite: (rosterRecordId: string) => void
} & ICellRendererParams

const TableActionCell = ({ sendInvite, data }: Props): JSX.Element => {
    const openModal = useModal()
    const {
        id,
        firstName,
        lastName,
        invitationStatus,
        status,
        externalIdentifier,
    }: RosterRecord = data
    const isMenuEnabled =
        invitationStatus === InvitationStatus.notYetInvited ||
        status === RosterStatus.pending

    const actionText =
        status === RosterStatus.pending ? 'Resend invite' : 'Send invite'

    const dropdownItems: DropdownItemType[] = [
        [
            actionText,
            {
                'data-test': 'invite-dropdown-item',
                onClick: () => {
                    openModal(modalTypeMap.GENERIC_CONFIRMATION, {
                        title: actionText,
                        description: (
                            <ParagraphRespectingLineBreaks>
                                {getActionDescription({
                                    status,
                                    invitationStatus,
                                    firstName,
                                    lastName,
                                })}
                            </ParagraphRespectingLineBreaks>
                        ),
                        saveButtonLabel: actionText,
                        savingButtonLabel: 'Sending...',
                        onSave: () => sendInvite(id),
                    })
                },
                disabled: false,
            },
        ],
    ]

    return (
        <MenuDropdownWithActions
            key={externalIdentifier}
            data-test="action-dropdown"
            dropdownItems={dropdownItems}
            disabled={!isMenuEnabled}
        />
    )
}

export default TableActionCell
