import React from 'react'
import { node } from 'prop-types'
import { Alert } from 'reactstrap'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ErrorAlert = ({ children }) => {
    return (
        <Alert
            data-test="error-alert"
            color="danger-disabled"
            className="d-flex mb-3 text-danger border-danger align-items-center"
            fade={false}
        >
            <FontAwesomeIcon
                size="lg"
                className="mr-3"
                icon={faExclamationCircle}
            />
            <div test-attr="description">{children}</div>
        </Alert>
    )
}

ErrorAlert.propTypes = {
    children: node,
}

export default ErrorAlert
