export default function getXAxisLabel(rangeSize, granularity) {
    if (rangeSize === 'week') {
        return 'Day'
    }

    if (rangeSize === 'month' || rangeSize === 'quarter') {
        if (granularity === 'daily') {
            return 'Day'
        }

        return 'Week of'
    }

    return 'Month'
}
