import _ from 'lodash'
import React from 'react'
import classnames from 'classnames'

import { string, number } from 'prop-types'

const Count = ({ count, label = 'item', pluralLabel = 'items', className }) => {
    if (!_.isNumber(count)) {
        return null
    }
    const displayLabel = count > 1 || count === 0 ? pluralLabel : label

    return (
        <span className={classnames('count', className)}>
            <span>{count}</span> <span>{displayLabel}</span>
        </span>
    )
}

Count.propTypes = {
    count: number,
    label: string,
    pluralLabel: string,
    className: string,
}

export default Count
