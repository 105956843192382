import { call, getContext, takeLatest, put } from 'redux-saga/effects'
import {
    FACTOR_ACTIVATE_REQUEST,
    createSessionTransactionAdd,
    createFactorAdd,
    createFactorActivateSuccess,
    createFactorActivateFailure,
} from '../../state/auth'
import toast from '../../components/Toast/toast.tsx'

const activateUri = ({ sessionTransactionId, factorId }) =>
    `/users/sessionTransactions/${sessionTransactionId}/authenticationFactors/${factorId}/activate`

export function* factorActivate({ payload, meta }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { factorId, sessionTransactionId, stateToken, code } = payload
    try {
        const { parsedBody } = yield call(
            [cosmosApi, 'call'],
            activateUri({ sessionTransactionId, factorId }),
            {
                method: 'POST',
                body: { stateToken, code },
            }
        )
        const { sessionTransaction } = parsedBody
        const { factor } = sessionTransaction
        const factorAddPayload = {
            factorId: factor.id,
            type: factor.type,
            phoneLast4: factor?.profile?.phoneLast4,
        }
        yield put(createFactorAdd(factorAddPayload))
        yield put(
            createSessionTransactionAdd({
                ...sessionTransaction,
                sessionTransactionId,
                factorId: factor.id,
            })
        )
        yield put(createFactorActivateSuccess({ factorId: factor.id }))
        return parsedBody
    } catch (err) {
        toast.error(`We couldn't verify your code. Please try again.`)
        yield put(createFactorActivateFailure({ error: err }))
        return err
    }
}

export default function* watchFactorActivate() {
    yield takeLatest(FACTOR_ACTIVATE_REQUEST, factorActivate)
}
