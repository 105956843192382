import React from 'react'
import styled from 'styled-components'

import CommuteMarketingSideBar from './CommuteMarketingSideBar'
import EmployeeRosterSideBar from './EmployeeRosterSideBar/index.tsx'
import MemberManagementSideBar from './MemberManagementSideBar'
import CommuteSideBar from './CommuteSideBar'
import SettingsSideBar from './SettingsSideBar'
import WorkplacePlannerSideBar from './WorkplacePlannerSideBar/index.tsx'
import HealthAndSafetySideBar from './HealthAndSafetySideBar/index.tsx'
import NavBottom from '../NavBottom'
import LogoLink from '../LogoLink/index.tsx'
import useSpaces from '../../hooks/useSpaces/index.ts'

const FullHeightContainer = styled.div`
    height: 100vh;
    min-width: 240px;
    overflow-x: hidden;
`

const SideBar = () => {
    const space = useSpaces()

    return (
        <FullHeightContainer className="bg-white border-right d-flex flex-column justify-content-between">
            <div>
                <div className="py-2 pl-3">
                    <LogoLink />
                </div>
                <div className="mt-3">
                    <CommuteSideBar space={space} />
                    <MemberManagementSideBar space={space} />
                    <WorkplacePlannerSideBar />
                    <HealthAndSafetySideBar space={space} />
                    <EmployeeRosterSideBar
                        space={space}
                        data-test="employee-roster-sidebar"
                    />
                    <CommuteMarketingSideBar space={space} />
                    <SettingsSideBar space={space} />
                </div>
            </div>

            <NavBottom />
        </FullHeightContainer>
    )
}

export default SideBar
