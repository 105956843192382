import _ from 'lodash'
import qs from 'qs'
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie'
import OktaSignInWidget from './OktaSignInWidget'
import OktaSignInError from './OktaSignInError'
import {
    createSessionTransactionOktaCreateRequest,
    selectSessionTransactionCreateRequest,
} from '../../state/auth'
import useCheckSession from '../../hooks/useCheckSession'

import { useAppConfiguration } from '../../providers/AppConfigurationProvider'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import CenteredLayout from '../../components/Layout/Centered'

const OktaSignIn = ({ location, cookies }) => {
    const dispatch = useDispatch()

    const { nebulaUrl, oktaOrgUrl, oktaClientId } = useAppConfiguration()

    const { next = '/' } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    })

    const {
        isFetching: createSessionTransactionIsFetching,
        error: createSessionTransactionError,
        sessionTransactionId,
    } = useSelector(selectSessionTransactionCreateRequest)

    const {
        isFetching: checkSessionTransactionIsFetching,
        error: checkSessionTransactionError,
        success: checkSessionTransactionSuccess,
    } = useCheckSession({ redirect: false })

    const dispatchCreateSessionTransactionOktaCreateRequest = useCallback(
        () => dispatch(createSessionTransactionOktaCreateRequest()),
        [dispatch]
    )

    useEffect(() => {
        const cookieSettings = {
            path: '/',
            httpOnly: false,
            sameSite: true,
        }

        cookies.set('preferred-sign-in', 'okta', cookieSettings)

        // Parse out the space alias from next path, if being taken to a specific dashboard
        // Space alias will always follow the "spaces/" part of the path.
        // ex: /spaces/12321/carpoolers/active
        const pathElements = next.split('/')
        if (_.includes(pathElements, 'spaces')) {
            const spacesPathElementIndex = pathElements.indexOf('spaces')
            const spaceAliasPathElementIndex = spacesPathElementIndex + 1
            const nextSpaceAlias = pathElements[spaceAliasPathElementIndex]

            if (nextSpaceAlias) {
                cookies.set(
                    'current-space-alias',
                    nextSpaceAlias,
                    cookieSettings
                )
            }
        }
    }, [next, cookies])

    useEffect(() => {
        if (checkSessionTransactionError) {
            dispatchCreateSessionTransactionOktaCreateRequest()
        }
    }, [
        checkSessionTransactionError,
        dispatchCreateSessionTransactionOktaCreateRequest,
    ])

    const sessionTransactionFetching =
        checkSessionTransactionIsFetching || createSessionTransactionIsFetching
    const sessionTransactionFetchSuccess =
        checkSessionTransactionSuccess || sessionTransactionId
    const sessionTransactionFetchFailure =
        checkSessionTransactionError && createSessionTransactionError

    // Show OktaSignInWidget only if error is resolvable by signing into Okta again
    const shouldShowOktaWidget =
        createSessionTransactionError?.errorId === 'OKTA_ACCESS_TOKEN_INVALID'

    return (
        <CenteredLayout>
            {sessionTransactionFetchFailure && shouldShowOktaWidget && (
                <OktaSignInWidget
                    orgUrl={oktaOrgUrl}
                    clientId={oktaClientId}
                    oktaRedirectUri={`${nebulaUrl}/authorization-code/callback`}
                    nextPath={next}
                />
            )}

            {sessionTransactionFetchFailure && !shouldShowOktaWidget && (
                <OktaSignInError />
            )}

            {sessionTransactionFetching && <SpinnerFullPage />}

            {sessionTransactionFetchSuccess && <Redirect to={next} noThrow />}
        </CenteredLayout>
    )
}

OktaSignIn.propTypes = {
    location: PropTypes.object.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
}

export default withCookies(OktaSignIn)
