import escapeRegExp from 'lodash/escapeRegExp'
import moment from 'moment'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalTypeMap } from '../../components/ReduxModal/helpers.ts'
import {
    createActivatedCarpoolersQueryFilterRequest,
    createCarpoolerAllReset,
    selectActivatedCarpoolers,
    selectQueryFilterParameters,
    selectQueryFilterParametersRequest,
} from '../../state/carpoolers'
import { createOpenModal } from '../../state/modal'
import useAnalytics from '../useAnalytics'
import { sortOrderOptions } from '../../util/sort'

const firstTripAt = 'firstTripAt'
const lastTripAt = 'lastTripAt'
const momentDateFormat = 'MM/DD/YY'
const initialActivatedCarpoolersRequest = {
    sortBy: lastTripAt,
    sortOrder: sortOrderOptions.descending,
}

export const formatTripWeek = (dateString) => {
    const parsedDate = moment(dateString)
    const startOfWeek = parsedDate
        .clone()
        .startOf('isoWeek')
        .format(momentDateFormat)
    const endOfWeek = parsedDate
        .clone()
        .endOf('isoWeek')
        .format(momentDateFormat)

    return `${startOfWeek} - ${endOfWeek}`
}

const compareEmails = (regexp) => (first, second) => {
    const indexFirst = first.search(regexp)
    const indexSecond = second.search(regexp)
    return 0 - (indexFirst - indexSecond)
}

const orderEmails = (searchQuery = '$^', emails = []) => {
    const escapedQuery = escapeRegExp(searchQuery)
    const comparator = compareEmails(new RegExp(escapedQuery, 'i'))
    return emails.sort(comparator)
}

const formatCarpoolerData = (
    searchQuery, // emails need to be ordered by match.
    actionPairs = [] // pair of action name and curried action function
) => ({ id, emails, firstTripAt, lastTripAt, ...params }) => {
    const orderedEmails = orderEmails(searchQuery, emails)
    const [displayEmail] = orderedEmails
    const firstTripWeek = formatTripWeek(firstTripAt)
    const lastTripWeek = formatTripWeek(lastTripAt)

    // Apply the carpooler ID and displayEmail to the curried action function
    const actions = actionPairs.map(([actionName, action]) => {
        return [
            actionName,
            { onClick: action({ carpoolerId: id, displayEmail }) },
        ]
    })

    return {
        ...params, // include the original data
        displayEmail,
        orderedEmails,
        firstTripWeek,
        lastTripWeek,
        actions,
    }
}

const useCarpoolers = ({ fetchData = true } = {}) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()
    const dispatchChangeQueryParams = useCallback(
        ({ query, sortBy, sortOrder } = {}) => {
            // Don't dispatch until we are ready.
            if (fetchData) {
                dispatch(
                    createActivatedCarpoolersQueryFilterRequest({
                        query,
                        sortBy,
                        sortOrder,
                    })
                )
            }
        },
        [dispatch, fetchData]
    )
    useEffect(() => {
        // request data on mount.
        dispatchChangeQueryParams(initialActivatedCarpoolersRequest)
        // Sanitize all active carpooler reducers on unMount to ensure
        // fresh data whenever the component remounts
        return () => dispatch(createCarpoolerAllReset())
    }, [dispatch, dispatchChangeQueryParams])

    const actions = [
        // Create a curried function that each carpooler will call with its own
        // carpoolerId.
        [
            'Deactivate',
            (carpoolerData) => () => {
                track('edit_carpoolers_action', {
                    action: 'Click',
                    label: 'actions_gear_deactivate',
                })
                dispatch(
                    createOpenModal({
                        name: modalTypeMap.DEACTIVATE_CARPOOLER,
                        data: { ...carpoolerData },
                    })
                )
            },
        ],
    ]
    const { isFetching, error } = useSelector(
        selectQueryFilterParametersRequest
    )
    const { query, sortBy, sortOrder } = useSelector(
        selectQueryFilterParameters
    )
    const carpoolerData = useSelector(selectActivatedCarpoolers)

    return {
        isFetching,
        error,
        query,
        sortBy,
        sortOrder,
        changeSearchQueryParams: dispatchChangeQueryParams,
        carpoolerData: carpoolerData
            ? carpoolerData.map(formatCarpoolerData(query, actions))
            : null,
    }
}

export const sortByOptions = { firstTripAt, lastTripAt }

export default useCarpoolers
