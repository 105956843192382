import React, { useState } from 'react'

import EmailInput from './EmailInput'
import EmailSent from './EmailSent'
import CenteredLayout from '../../components/Layout/Centered'

const ForgotPasswordInputEmail = () => {
    const [email, setEmail] = useState()

    return (
        <CenteredLayout squished>
            {email ? (
                <EmailSent email={email} />
            ) : (
                <EmailInput
                    onEmailSent={({ email }) => {
                        setEmail(email)
                    }}
                />
            )}
        </CenteredLayout>
    )
}

export default ForgotPasswordInputEmail
