import { takeLatest, put, call, getContext } from 'redux-saga/effects'
import {
    SESSION_TRANSACTION_CHECK_REQUEST,
    createSessionTransactionCheckSuccess,
    createSessionTransactionCheckFailure,
} from '../../state/auth'

const uri = '/users/me'

/* Encapsulate the logic of checking if there is an active session token. The
 * API doesn't offer a session transaction introspection endpoint, so we need to
 * use a [unrelated] protected endpoint.
 *
 * 1) attempt to access a "protected" API route
 * 2) if successful, dispatch "SUCCESS" action type, if not successful dispatch
 * "FAILURE" action type.
 */
export function* checkSessionTransaction() {
    const cosmosApi = yield getContext('cosmosApi')
    const analytics = yield getContext('analytics')
    try {
        const {
            parsedBody: { id: userId, type: userType, displayName },
        } = yield call([cosmosApi, 'call'], uri)
        yield put(
            createSessionTransactionCheckSuccess({
                displayName,
                type: userType,
            })
        )

        analytics.identify(userId, { userType })
    } catch (err) {
        yield put(createSessionTransactionCheckFailure({ error: err }))
    }
}

export default function* watchCheckSessionTransaction() {
    yield takeLatest(SESSION_TRANSACTION_CHECK_REQUEST, checkSessionTransaction)
}
