import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import SortArrows from '../../SortArrows'
import { sortOrderOptions } from '../../../util/sort'

/**
 * SortableColumn
 * @deprecated should be deprecated in favor of SortableHeading
 */
const SortableColumn = ({ direction, onClick, children }) => {
    return (
        <Button
            color="Link"
            className="py-1 px-0 w-100 d-flex justify-content-between font-weight-bold"
            onClick={onClick}
        >
            {children}

            <SortArrows direction={direction} />
        </Button>
    )
}

SortableColumn.propTypes = {
    direction: PropTypes.oneOf([
        sortOrderOptions.ascending,
        sortOrderOptions.descending,
    ]),
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}

export default SortableColumn
