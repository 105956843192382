import { PropTypes } from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

import useAnalytics from '../../hooks/useAnalytics'

const MetricTooltip = ({ tooltipBody, analytics = {} }) => {
    const includeAnalytics = Boolean(
        analytics.category && analytics.tooltipLabel
    )
    const { track } = useAnalytics()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const tooltipRef = useRef()

    const handleToggle = useCallback(() => {
        if (includeAnalytics && !isTooltipOpen) {
            track(analytics.category, {
                action: 'Hover',
                label: analytics.tooltipLabel,
                ...analytics.dateRangeProperties,
            })
        }

        setIsTooltipOpen(!isTooltipOpen)
    }, [
        includeAnalytics,
        isTooltipOpen,
        track,
        analytics.category,
        analytics.tooltipLabel,
        analytics.dateRangeProperties,
    ])

    return (
        <>
            <span ref={tooltipRef} className="ml-2 mr-4">
                <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />
            </span>
            <Tooltip
                placement="right"
                isOpen={isTooltipOpen}
                toggle={handleToggle}
                target={tooltipRef}
            >
                {tooltipBody}
            </Tooltip>
        </>
    )
}

MetricTooltip.propTypes = {
    tooltipBody: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
    analytics: PropTypes.shape({
        category: PropTypes.string,
        tooltipLabel: PropTypes.string,
        dateRangeProperties: PropTypes.shape({
            range_size: PropTypes.string,
            date_range_start: PropTypes.string.isRequired,
            date_range_end: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            quarter: PropTypes.number,
            month: PropTypes.number,
            week: PropTypes.number,
        }),
    }),
}

export default MetricTooltip
