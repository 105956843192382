import React, { PropsWithChildren, MouseEventHandler } from 'react'
import { Button as ReactStrapButton, ButtonProps, Spinner } from 'reactstrap'
import styled from 'styled-components'

const LoadingText = styled.div`
    display: inline-block;
    margin-left: 5px;
`

export type Props = PropsWithChildren<{
    size?: string
    isLoading?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    label?: string
    loadingLabel?: string
    isDisabled?: boolean
}> &
    Partial<ButtonProps>

const Button = ({
    isLoading,
    onClick,
    label,
    size = 'lg',
    loadingLabel,
    isDisabled,
    children,
    ...rest
}: Props): JSX.Element => (
    <ReactStrapButton
        disabled={isLoading || isDisabled}
        onClick={onClick}
        color="primary"
        size={size}
        block
        {...rest}
    >
        {isLoading ? (
            <>
                <Spinner data-test="spinner" size="sm" />{' '}
                {loadingLabel && <LoadingText>{loadingLabel}</LoadingText>}
            </>
        ) : (
            label || children
        )}
    </ReactStrapButton>
)

export default Button
