import React, { useMemo } from 'react'
import { bool, shape, number, string } from 'prop-types'

import BuildingPerimeterMap from '../../../../components/BuildingPerimeterMap'
import Placeholder from '../../../../components/BuildingPerimeterMap/Placeholder'
import { getCenterFromPolygon } from '../../../../components/BuildingPerimeterMap/utility'
import GhostLoader from '../../../../components/GhostLoader'
import { buildingMultiPolygonProp } from '../../../../models/building'

const ViewBuildingPerimeter = ({
    id,
    isLoading,
    hasError,
    buildingPerimeterMultiPolygon,
}) => {
    const coordinates = useMemo(
        () => getCenterFromPolygon(buildingPerimeterMultiPolygon),
        [buildingPerimeterMultiPolygon]
    )

    if (isLoading) {
        return (
            <Placeholder test-attr="loader">
                <GhostLoader height={25} />
            </Placeholder>
        )
    }

    if (hasError || !buildingPerimeterMultiPolygon) {
        return (
            // Simply show an empty placeholder because
            // 'Try again' button is shown in 'Building Details'.
            <Placeholder test-attr="error" />
        )
    }

    const [polygon] = buildingPerimeterMultiPolygon // multipolygons are nested one level deeper

    return (
        <BuildingPerimeterMap
            id={id}
            buildingPerimeterPolygon={polygon}
            initialCoordinates={coordinates}
        />
    )
}

ViewBuildingPerimeter.propTypes = {
    id: string,
    isLoading: bool,
    hasError: bool,
    coordinates: shape({
        longitude: number.isRequired,
        latitude: number.isRequired,
    }),
    buildingPerimeterMultiPolygon: buildingMultiPolygonProp,
}

export default ViewBuildingPerimeter
