export const getTitleFromLocation = (pathName) => {
    if (isOverviewPage(pathName)) {
        return 'Overview'
    }

    if (isCommuterActivityPage(pathName)) {
        return 'Commuter Activity'
    }

    return 'Trip Activity'
}

export const getAnalyticsCategoryFromLocation = (pathName) => {
    if (isOverviewPage(pathName)) {
        return 'performance_action'
    }

    if (isCommuterActivityPage(pathName)) {
        return 'carpooler_activity_action'
    }

    return 'trip_activity_action'
}

export const isOverviewPage = (pathName = '') => {
    return pathName.includes('overview')
}

export const isTripActivityPage = (pathName = '') => {
    return pathName.includes('activity/trip')
}

export const isCommuterActivityPage = (pathName = '') => {
    return pathName.includes('activity/commuter')
}
