import { useNavigate, Redirect } from '@reach/router'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useReducer } from 'react'
import useFetch from 'use-http'

import { useDispatch } from 'react-redux'
import ErrorStates from './ErrorStates'
import {
    ADD_EMAIL,
    ADD_METADATA_ERROR,
    ADD_PASSWORD,
    ADD_SESSION,
    forgotPasswordSteps,
    initialState,
    POST_METADATA,
    POST_SESSION_TRANSACTION,
} from './reducer'
import CenteredLayout from '../../components/Layout/Centered'
import Password from '../../components/Password'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import toast from '../../components/Toast/toast.tsx'
import Verify2FACode from '../../components/Verify2FACode'
import useAnalytics from '../../hooks/useAnalytics'
import { createSessionTransactionAdd } from '../../state/auth/actions'

const ForgotPasswordInputNewPassword = ({ location }) => {
    const navigate = useNavigate()
    const { track } = useAnalytics()
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const dispatch = useDispatch()
    const [state, dispatchLocalState] = useReducer(
        forgotPasswordSteps,
        initialState
    )

    // Click handler for submitting a new password
    const {
        post: postPassword,
        loading: isFetchingPassword,
        response: responsePassword,
    } = useFetch('/users/passwordReset/changePassword')

    const handlePasswordSubmit = (token) => async (password) => {
        track('reset_pw_action', { action: 'Click', label: 'save_password' })

        await postPassword({
            newPassword: password,
            resetToken: token,
        })

        if (responsePassword.ok) {
            dispatchLocalState({ type: ADD_PASSWORD, payload: password })
        } else {
            toast.error("We couldn't create your password. Please try again.")
        }
    }

    // Click handler for submitting 2FA
    const {
        post: post2FA,
        loading: isFetching2FA,
        response: response2FA,
    } = useFetch('/users/sessionTransactions')

    const handle2FASubmit = (session) => async (code) => {
        const sessionTransactionId = session.id
        const authenticationFactorId = session.factor.id
        const stateToken = session.stateToken

        await post2FA(
            `/${sessionTransactionId}/authenticationFactors/${authenticationFactorId}/verify`,
            { stateToken, code }
        )

        if (response2FA.ok) {
            navigate('/spaces', { replace: true })
            toast.success("You've successfully reset your password.")
        } else {
            toast.error("We couldn't verify your code. Please try again.")
        }
    }

    // Click handler for resending code
    const { post: postCodeResend, response: responseCodeResend } = useFetch(
        '/users/sessionTransactions'
    )
    const handleCodeResend = async (session) => {
        const sessionTransactionId = session.id
        const authenticationFactorId = session.factor.id
        const stateToken = session.stateToken

        const data = await postCodeResend(
            `/${sessionTransactionId}/authenticationFactors/${authenticationFactorId}/resend`,
            { stateToken }
        )

        if (responseCodeResend.ok) {
            toast.success('Code successfully resent.')
        } else {
            const errorMessage =
                data.error?.message ||
                "We couldn't resend your code. Please try again."
            toast.error(errorMessage)
        }
    }

    // Side effect handler for fetching metaData via POST
    const { post: postMetaData, response: responseMetaData } = useFetch(
        '/users/passwordReset/viewMeta'
    )

    // Side effect handler for creating a session transaction
    const {
        post: postCreateSessionTransaction,
        loading: isFetchingCreateSessionTransaction,
        response: responseCreateSessionTransaction,
    } = useFetch('/users/sessionTransactions')

    // Handle side effects
    useEffect(() => {
        async function handleSideEffects() {
            const { sideEffect } = state

            if (sideEffect === POST_METADATA) {
                const data = await postMetaData({ token })

                if (responseMetaData.ok) {
                    dispatchLocalState({
                        type: ADD_EMAIL,
                        payload: data.email,
                    })
                } else {
                    dispatchLocalState({
                        type: ADD_METADATA_ERROR,
                        payload: data,
                    })
                }
            }

            if (sideEffect === POST_SESSION_TRANSACTION) {
                const data = await postCreateSessionTransaction({
                    authenticationStrategy: {
                        type: 'emailAndPassword',
                        email: state.email,
                        password: state.password,
                    },
                })

                if (responseCreateSessionTransaction.ok) {
                    dispatchLocalState({
                        type: ADD_SESSION,
                        payload: data,
                    })
                }
            }
        }

        handleSideEffects()
    }, [
        state,
        token,
        postCreateSessionTransaction,
        responseCreateSessionTransaction,
        postMetaData,
        responseMetaData,
        dispatch,
    ])

    // Step 2: A valid token prompts the user to input a new password
    if (state.email && !state.session) {
        return (
            <CenteredLayout squished>
                <Password
                    userCreating={
                        isFetchingPassword || isFetchingCreateSessionTransaction
                    }
                    onSubmit={handlePasswordSubmit(token)}
                />
            </CenteredLayout>
        )
    }

    // Exception condition. If the sessionTransaction indicates that a user hasn't completed 2FA setup, then
    // send them to the complete 2FA enrollment. That page uses redux, so dispatch an action to populate session data into redux.
    if (state.session?.status === 'MFA_ENROLL') {
        dispatch(
            createSessionTransactionAdd({
                sessionTransactionId: state.session.id,
                stateToken: state.session.stateToken,
                status: state.session.status,
                factorId: state.session.factor?.id,
            })
        )

        return <Redirect to="/mfa-enroll" noThrow />
    }

    // Step 3: A successful password submission triggers a sessionTransaction which will return a factorid
    // A valid factorId texts a 6-digit code to the user and prompts the user to input that code
    if (state.session?.status === 'MFA_VERIFY') {
        const last4 = state.session.factor.profile.phoneLast4
        return (
            <CenteredLayout squished>
                <Verify2FACode
                    message={`Please enter the 6-digit code we sent to your phone number ending in ${last4}.`}
                    onSubmit={handle2FASubmit(state.session)}
                    resendCode={() => handleCodeResend(state.session)}
                    codeVerifying={isFetching2FA}
                />
            </CenteredLayout>
        )
    }

    // Handle any errors from fetching user metadata
    if (responseMetaData.ok === false) {
        return (
            <CenteredLayout squished>
                <ErrorStates error={responseMetaData.data} />
            </CenteredLayout>
        )
    }

    // Step 1: Show a FullPage Spinner while the component is fetching metadata
    return <SpinnerFullPage />
}

ForgotPasswordInputNewPassword.propTypes = {
    location: PropTypes.object,
}

export default ForgotPasswordInputNewPassword
