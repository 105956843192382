import styled from 'styled-components'
import { Button } from 'reactstrap'

export const ShowHideButton = styled(Button)`
    padding-top: 0px !important;
`

export const PasswordSuggestionsList = styled.ul`
    padding-left: 0px;
    min-height: 45px;
`
export const PasswordSuggestionsListItem = styled.li`
    list-style-position: inside;
`
