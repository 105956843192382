import { call, takeLatest, put } from 'redux-saga/effects'
import { createSessionTransaction } from '../sessionTransaction'
import {
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST,
    createSessionTransactionEmailPasswordCreateSuccess,
    createSessionTransactionEmailPasswordCreateFailure,
} from '../../state/auth'

/* Encapsulate the logic of creating a session for an email/password user.
 */
export function* createEmailPasswordSession({ payload = {} }) {
    // attempt to create a session for the Okta user.
    try {
        const sessionResponse = yield call(createSessionTransaction, payload)
        yield put(
            createSessionTransactionEmailPasswordCreateSuccess({
                sessionTransactionId: sessionResponse.id,
            })
        )
        return sessionResponse
    } catch (err) {
        yield put(
            createSessionTransactionEmailPasswordCreateFailure({ error: err })
        )
    }
}

export default function* watchCreateEmailPasswordSession() {
    yield takeLatest(
        SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST,
        createEmailPasswordSession
    )
}
