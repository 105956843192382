import { combineReducers } from 'redux'
import {
    TOKEN_METADATA_ADD,
    TOKEN_METADATA_REMOVE,
    TOKEN_METADATA_GET_REQUEST,
    TOKEN_METADATA_GET_SUCCESS,
    TOKEN_METADATA_GET_FAILURE,
} from './types'
import serializeError from '../serializeError'

export const tokenMetadata = (state = {}, { type, payload = {} }) => {
    const {
        tokenId,
        claimedAt,
        expiresAt,
        inviteeName,
        inviterName,
        spaceName,
    } = payload
    switch (type) {
        case TOKEN_METADATA_ADD:
            return {
                tokenId,
                claimedAt,
                expiresAt,
                inviteeName,
                inviterName,
                spaceName,
            }
        case TOKEN_METADATA_REMOVE:
            return {}
        default:
            return state
    }
}

export const getRequest = (state = {}, { type, payload = {} }) => {
    const { tokenId } = payload
    const error = serializeError(payload)
    switch (type) {
        case TOKEN_METADATA_GET_REQUEST:
            return {
                tokenId,
                isFetching: true,
            }
        case TOKEN_METADATA_GET_SUCCESS:
            return {
                isFetching: false,
            }
        case TOKEN_METADATA_GET_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export default combineReducers({
    tokenMetadata,
    getRequest,
})
