import { Redirect } from '@reach/router'
import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'

import { CenteredLayout } from '../../components/Layout/Centered'
import { isModuleEnabled } from '../../components/ModuleFlag/util'
import useSpaces from '../../hooks/useSpaces/index.ts'
import OverviewBackground from '../../images/overview-background.svg'

const CommuteMarketing = () => {
    const { modules } = useSpaces()

    if (isModuleEnabled('memberManagement', modules)) {
        return (
            <Redirect
                from="/spaces/:spaceAlias/commute-learn-more"
                to="/spaces/:spaceAlias"
                noThrow
            />
        )
    }

    return (
        <Container size="xl">
            <CenteredLayout>
                <h1 className="my-3">
                    Upgrade to manage the impact of your employees&lsquo;
                    commute
                </h1>

                <p>
                    Do more than manage workplace check-ins with Scoop. Provide
                    a safe, reliable, and convenient door-to-door commute option
                    for every employee while managing parking and facilities
                    issues and key HR challenges like employee stress,
                    engagement, and financial well-being.
                </p>

                <Button
                    href="https://www.scoopcommute.com/commute"
                    color="primary"
                    block
                    className="mt-5"
                    target="_blank"
                >
                    Learn More
                </Button>
            </CenteredLayout>
            <Row className="my-5">
                <Col>
                    <img src={OverviewBackground} />
                </Col>
            </Row>
        </Container>
    )
}

export default CommuteMarketing
