import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { chartData } from '../../../models/chart'

const SATURDAY = 6
const SUNDAY = 7

const WEEKDAYS_COUNT = 5
const FULL_WEEKDAYS_COUNT = 7

/**
 * By default, Recharts renders an x-axis tick for each vertical line
 * rendered on the x-axis. This custom <XAxisTick /> overrides that behavior
 * under certain circumstances, because we sometimes don't want to render
 * a tick for every single vertical line.
 *
 * The two special cases are when
 * 1. rangeSize = quarter, then render every other tick
 * 2. rangeSize = monthByDay, then render every Monday, which can be every 5 or 7 days
 *                depending on whether or not the range of data includes weekend trips (isShiftWorking)
 *
 */
const XAxisTick = ({
    x,
    y,
    payload,
    fill,
    textAnchor,
    index,
    tickFormatter,
    rangeSize,
    isMonthByDay,
    data,
}) => {
    const hasWeekendStats = useMemo(
        () =>
            data.some((stat) => {
                const isoDay = moment(stat.context.from).isoWeekday()

                return isoDay === SATURDAY || isoDay === SUNDAY
            }),
        [data]
    )

    // For quarter rangeSize, show every 2 ticks
    if (rangeSize === 'quarter' && index % 2 !== 0) {
        return null
    }

    // For month rangeSize and daily granularity
    if (isMonthByDay) {
        if (hasWeekendStats) {
            if (index % FULL_WEEKDAYS_COUNT !== 0) {
                return null
            }
        } else {
            if (index % WEEKDAYS_COUNT !== 0) {
                return null
            }
        }
    }

    return (
        <svg>
            <line
                transform={`translate(${x},${y})`}
                y1={-8}
                y2={3}
                style={{ stroke: 'black', strokeWidth: 1 }}
            />
            <g transform={`translate(${x},${y})`}>
                <text fill={fill} textAnchor={textAnchor} dy={25}>
                    {tickFormatter(payload.value)}
                </text>
                {rangeSize === 'week' && (
                    <text fill={fill} textAnchor={textAnchor} dy={45}>
                        {moment(payload.value).format('ddd')}
                    </text>
                )}
            </g>
        </svg>
    )
}

XAxisTick.propTypes = {
    // Recharts Props
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape({
        value: PropTypes.string,
    }),
    fill: PropTypes.string,
    textAnchor: PropTypes.string,
    index: PropTypes.number,

    // Custom Props
    tickFormatter: PropTypes.func.isRequired,
    rangeSize: PropTypes.string.isRequired,
    isMonthByDay: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape(chartData)).isRequired,
}

export default XAxisTick
