import React from 'react'
import { Router } from '@reach/router'

import CommuteOverviewAndActivity from '../CommuteOverviewAndActivity'
import CommuteReports from '../CommuteReports/index.tsx'

const Commute = () => {
    return (
        <Router primary={false}>
            <CommuteOverviewAndActivity path="*" />
            <CommuteReports path="reports/*" />
        </Router>
    )
}

export default Commute
