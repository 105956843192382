import { PropTypes } from 'prop-types'
import React from 'react'
import { Navbar } from 'reactstrap'

import WorkplaceCheckInPanelLoading from './Loading'
import WorkplaceCheckInPanelSuccess from './Success'
import CouldNotLoadData from '../../../components/Error/CouldNotLoadData'
import { LogoImg } from '../../../components/LogoLink/styled'
import scoopLoop from '../../../images/scoop-logo-green.png'
import { workAttendanceIndication } from '../../../models/workAttendanceIndication'
import { PANEL_WIDTH } from '../util'

const WorkplaceCheckInPanel = ({
    error,
    loading,
    workAttendanceIndication,
    onTryAgainClick,
    printableElementRef,
}) => {
    const hasError = Boolean(error)

    const hasData =
        !hasError && !loading && workAttendanceIndication !== undefined

    return (
        <div
            className="border bg-white"
            style={{ width: PANEL_WIDTH }}
            ref={printableElementRef}
        >
            <Navbar className="border-bottom">
                <LogoImg src={scoopLoop} alt="Scoop Logo" />
            </Navbar>
            <div className="p-5">
                {hasError && (
                    <CouldNotLoadData
                        onTryAgainClick={onTryAgainClick}
                        className="mb-5 pb-5"
                        style={{ height: 600 }}
                    />
                )}

                {loading && <WorkplaceCheckInPanelLoading />}

                {hasData && (
                    <WorkplaceCheckInPanelSuccess
                        workAttendanceIndication={workAttendanceIndication}
                    />
                )}
            </div>
        </div>
    )
}

WorkplaceCheckInPanel.propTypes = {
    workAttendanceIndication: workAttendanceIndication,
    error: PropTypes.object,
    loading: PropTypes.bool,
    onTryAgainClick: PropTypes.func.isRequired,
    printableElementRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
}

export default WorkplaceCheckInPanel
