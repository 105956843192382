import { useCallback } from 'react'
import useFetch, { CachePolicies, Res } from 'use-http'

import { InvitationStatus, RosterRecord } from '../../../types'
import { createRequestInterceptor } from '../../../util/requestInterceptors'

const {
    fileRequestFetchOptions,
    prepareFileForPost,
} = createRequestInterceptor()

export type RecordsInviteBulkResponseType = {
    id?: string
    expiresAt?: string
    rosterRecord: RosterRecord
}[]

type RecordsInviteBulkResource = {
    isLoading: boolean
    inviteBulk: (status: InvitationStatus) => Promise<void>
    inviteBulkWithCSV: (
        csvFile: File | undefined
    ) => Promise<RecordsInviteBulkResponseType | undefined>
    response: Res<ResponseType>
}

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

/**
 * @see https://api-docs.scoopcommute.com/cosmos#spaces__spaceid__rosterrecords_invitations_invitebulk_post
 */
function useRosterRecordsInviteBulkResource(
    spaceId: string
): RecordsInviteBulkResource {
    const { loading: isLoading, post, response } = useFetch(
        `/spaces/${spaceId}/rosterRecords/invitations/inviteBulk`,
        {
            ...fetchOptions,
            ...fileRequestFetchOptions,
        }
    )

    const inviteBulk = useCallback(
        async (status: InvitationStatus) => {
            await post(`?invitationStatus=${status}`, {})
        },
        [post]
    )

    const inviteBulkWithCSV = useCallback(
        async (csvFile: File) => {
            const data = await post(`?force=true`, prepareFileForPost(csvFile))
            return response.ok
                ? (data as RecordsInviteBulkResponseType)
                : undefined
        },
        [post, response]
    )

    return {
        isLoading,
        inviteBulk,
        inviteBulkWithCSV,
        response,
    }
}

export { RecordsInviteBulkResource }
export default useRosterRecordsInviteBulkResource
