import styled from 'styled-components'

const Container = styled.div.attrs({
    className:
        'd-flex flex-column align-items-center justify-content-center py-4',
})`
    border: 2px #dee2e6 dashed;
    min-height: 290px;
`

export default Container
