import classNames from 'classnames'
import { node } from 'prop-types'
import React, { useContext } from 'react'
import { Col, Container, Row } from 'reactstrap'

import { BreadcrumbsContext } from '../../providers/BreadcrumbsProvider.tsx'
import Breadcrumbs from '../Breadcrumbs'

/**
 * Component that is wrapped in the BreadcrumbsProvider
 */
const LayoutWithBreadcrumbs = ({ children }) => {
    const { breadcrumbs } = useContext(BreadcrumbsContext)

    const className = classNames('container-full-height minus-navbar', {
        'minus-navbar-and-breadcrumbs': breadcrumbs.length,
    })

    return (
        <>
            <Breadcrumbs />
            <Container className={className} fluid>
                <Row>
                    <Col>{children}</Col>
                </Row>
            </Container>
        </>
    )
}

LayoutWithBreadcrumbs.propTypes = {
    children: node.isRequired,
}

export default LayoutWithBreadcrumbs
