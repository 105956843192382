import React from 'react'
import { Redirect, Router } from '@reach/router'

import AllBuildings from '../AllBuildings'
import Details from '../Building/Details'
import AddBuilding from '../Building/Add'
import EditBuilding from '../Building/Edit'
import ModuleFlag from '../../components/ModuleFlag'
import useSpaces from '../../hooks/useSpaces'
import useBuildingDetails from '../../hooks/useBuildingDetails'

const Buildings = (): JSX.Element => {
    const { id: spaceId } = useSpaces()
    const buildingDetails = useBuildingDetails({
        spaceId,
    })

    return (
        <ModuleFlag
            name="workplacePlanner"
            fallback={<Redirect to="" noThrow />}
        >
            <Router primary={false} data-test="building-page">
                <AllBuildings path="buildings/all" />
                <AddBuilding path="buildings/add" />

                <Details
                    path="buildings/:buildingId"
                    buildingDetails={buildingDetails}
                />
                <EditBuilding
                    path="buildings/:buildingId/edit"
                    buildingDetails={buildingDetails}
                />

                <Redirect from="buildings" to="all" noThrow />
            </Router>
        </ModuleFlag>
    )
}

export default Buildings
