import React, { useEffect, useMemo } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { Button, Container } from 'reactstrap'

import YearlyReports from './YearlyReports'
import ReportsTable from '../../../components/ReportsTable'
import { useSpaces } from '../../../providers/SpacesProvider'

import {
    getMonthlyTimeSeriesDataForReports,
    getYearlyTimeSeriesDataForReports,
} from '../util'

import useAnalytics from '../../../hooks/useAnalytics'
import { Header } from '../../../components/PageLayout'
import { Month } from '../../../types'
import { Labels } from '../ReportsRouter'

export type TimeSeriesData = {
    period: {
        year: number
        month?: Month
    }
    isDisabled?: boolean
    extraInfo?: JSX.Element
    extraInfoRight?: JSX.Element
}[]

type Props = {
    isYearToDateSupported?: boolean
    labels: Labels
    reportsName: string
    refreshedAt?: string
    analytics: {
        prefix: string
    }
} & RouteComponentProps

const Reports = ({
    isYearToDateSupported,
    reportsName,
    labels: { title, description, modalContent },
    refreshedAt,
    analytics,
}: Props): JSX.Element => {
    const { tripsStartDate, id: spaceId } = useSpaces()
    const { track } = useAnalytics()

    useEffect(() => {
        track(`${analytics.prefix}_action`, {
            action: 'View',
            label: analytics.prefix,
        })
    }, [track, analytics])

    // Only retrieve full months in the past
    const monthlyTimeSeriesData = useMemo(
        () =>
            getMonthlyTimeSeriesDataForReports({
                startDate: tripsStartDate,
                lastUpdatedTimestamp: refreshedAt,
            }),
        [refreshedAt, tripsStartDate]
    )

    const yearlyTimeSeriesData: TimeSeriesData = useMemo(
        () =>
            getYearlyTimeSeriesDataForReports({
                startDate: tripsStartDate,
                lastUpdatedTimestamp: refreshedAt,
                analyticsCategory: `${analytics.prefix}_action`,
                isYearToDateSupported,
            }),

        [tripsStartDate, isYearToDateSupported, analytics, refreshedAt]
    )
    const isYearlyReportsVisible = yearlyTimeSeriesData.length > 0

    return (
        <Container size="xl">
            <Header title={title} description={description} />

            <div
                test-attr="monthly-reports"
                className="mb-5 p-4 bg-white border"
            >
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Monthly Reports</h5>

                    <Link to="example/monthly">
                        <Button color="primary" outline>
                            View an example
                        </Button>
                    </Link>
                </div>

                <ReportsTable
                    analytics={analytics}
                    data={monthlyTimeSeriesData}
                    spaceId={spaceId}
                    reportsName={reportsName}
                    test-attr="monthly-reports-table"
                    modalContent={modalContent}
                />
            </div>

            {isYearlyReportsVisible && (
                <YearlyReports
                    analytics={analytics}
                    modalContent={modalContent}
                    data={yearlyTimeSeriesData}
                    spaceId={spaceId}
                    reportsName={reportsName}
                />
            )}
        </Container>
    )
}

export default Reports
