import { navigate, Redirect, Router, RouteComponentProps } from '@reach/router'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import useFetch from 'use-http'

import { Container } from 'reactstrap'
import ChooseCSV from './ChooseCSV'
import ConfirmCSV from './ConfirmCSV'
import UploadRedirect from './Redirect'
import TryAgain from './TryAgain'
import useRosterUploadReminder from './useRosterUploadReminder'
import {
    defaultState,
    errorIds,
    fetchOptions,
    prepareFileForPost,
} from './utility'
import { EmployeeRosterContext } from '../../providers/EmployeeRosterProvider'
import useSpaces from '../../hooks/useSpaces/index'
import useBreadcrumbs from '../../hooks/useBreadcrumbs/index'
import toast from '../../components/Toast/toast'
import { Content, Header } from '../../components/PageLayout'

type Props = RouteComponentProps

const RosterUpload = (props: Props): JSX.Element => {
    const [state, setState] = useState(defaultState)
    const { id: spaceId, alias: spaceAlias } = useSpaces()
    const breadcrumbs = useMemo(
        () => [
            {
                link: `/spaces/${spaceAlias}/roster/management`,
                label: 'Roster',
            },
            { label: 'Upload' },
        ],
        [spaceAlias]
    )

    const {
        datatable: { getData: getRosterData },
        getNotYetInvitedData,
    } = useContext(EmployeeRosterContext)

    useRosterUploadReminder()
    useBreadcrumbs(breadcrumbs)

    const {
        post: dryRunPost,
        response: dryRunResponse,
        data: dryRunData,
        loading: dryRunIsLoading,
    } = useFetch(`/spaces/${spaceId}/rosterRecords?force=false`, fetchOptions)
    const {
        post: forceRunPost,
        response: forceRunResponse,
        loading: forceRunLoading,
    } = useFetch(`/spaces/${spaceId}/rosterRecords?force=true`, fetchOptions)

    const handleChoose = useCallback(
        async (file) => {
            const data = await dryRunPost(prepareFileForPost(file))

            if (dryRunResponse.ok) {
                setState({
                    file,
                    rosterUploadPreview: data.records,
                })

                await navigate('confirm-csv')
            } else {
                const error =
                    dryRunResponse?.data?.error?.message ||
                    'Something went wrong'

                toast.error(error)
            }
        },
        [dryRunPost, dryRunResponse]
    )

    const handleConfirm = useCallback(async () => {
        await forceRunPost(prepareFileForPost(state.file))

        if (forceRunResponse.ok) {
            toast.success('Employee roster has been successfully updated.')
            getRosterData({})
            await getNotYetInvitedData()
            await navigate('../')
        } else {
            await navigate('try-again')
        }
    }, [
        forceRunPost,
        forceRunResponse.ok,
        state.file,
        getRosterData,
        getNotYetInvitedData,
    ])

    const handleCancel = useCallback(async () => {
        setState(defaultState)
    }, [setState])

    const isInvalidCSV = useMemo(
        () => errorIds.includes(dryRunData?.error?.errorId),
        [dryRunData]
    )

    const isStep1Complete = state.rosterUploadPreview !== undefined

    return (
        <Container size="xl">
            <UploadRedirect isStep1Complete={isStep1Complete}>
                <Header title="Upload CSV to populate roster" />
                <Content isRounded>
                    <Router>
                        <Redirect from="/" to="choose-csv" noThrow />
                        <ChooseCSV
                            path="choose-csv"
                            isInvalidCSV={isInvalidCSV}
                            onComplete={handleChoose}
                            isSubmitting={dryRunIsLoading}
                        />
                        <ConfirmCSV
                            path="confirm-csv"
                            onComplete={handleConfirm}
                            onCancel={handleCancel}
                            rosterUploadPreview={state.rosterUploadPreview}
                            isSubmitting={forceRunLoading}
                        />
                        <TryAgain
                            path="try-again"
                            onComplete={handleConfirm}
                            onCancel={handleCancel}
                            file={state.file}
                            isSubmitting={forceRunLoading}
                        />
                    </Router>
                </Content>
            </UploadRedirect>
        </Container>
    )
}

export default RosterUpload
