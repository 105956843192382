import React from 'react'
import { Router, Redirect } from '@reach/router'

import { useSpaces } from '../../providers/SpacesProvider.tsx'
import MemberManagementActivated from '../MemberManagementActivated'
import MemberManagementDeactivated from '../MemberManagementDeactivated'
import BulkDeactivate from '../BulkDeactivate'
import ModuleFlag from '../../components/ModuleFlag'

const Carpoolers = () => {
    const {
        name: spaceName,
        publicDisplayName,
        id: spaceId,
        alias: spaceAlias,
    } = useSpaces()

    const props = {
        spaceId,
        spaceName,
        publicDisplayName,
        spaceAlias,
    }

    return (
        <ModuleFlag
            name="memberManagement"
            fallback={<Redirect to="../" noThrow />}
        >
            <Router>
                <Redirect from="/" to="active" noThrow />
                <MemberManagementActivated path="active" {...props} />
                <MemberManagementDeactivated path="deactivated" {...props} />
                <BulkDeactivate
                    path="deactivated/upload"
                    spaceName={spaceName}
                    spaceAlias={spaceAlias}
                />
            </Router>
        </ModuleFlag>
    )
}

export default Carpoolers
