import {
    TOKEN_METADATA_ADD,
    TOKEN_METADATA_REMOVE,
    TOKEN_METADATA_GET_REQUEST,
    TOKEN_METADATA_GET_SUCCESS,
    TOKEN_METADATA_GET_FAILURE,
} from './types'

export const createTokenAdd = ({
    tokenId,
    claimedAt,
    expiresAt,
    inviteeName,
    inviterName,
    spaceName,
}) => ({
    type: TOKEN_METADATA_ADD,
    payload: {
        tokenId,
        claimedAt,
        expiresAt,
        inviteeName,
        inviterName,
        spaceName,
    },
})

export const createTokenRemove = () => ({
    type: TOKEN_METADATA_REMOVE,
})

export const createTokenMetadataGetRequest = ({ tokenId }) => ({
    type: TOKEN_METADATA_GET_REQUEST,
    payload: {
        tokenId,
    },
})

export const createTokenMetadataGetSuccess = () => ({
    type: TOKEN_METADATA_GET_SUCCESS,
})

export const createTokenMetadataGetFailure = ({ error }) => ({
    type: TOKEN_METADATA_GET_FAILURE,
    error: true,
    payload: error,
})
