import { useCallback, useMemo } from 'react'
import { CachePolicies, Res, useFetch } from 'use-http'
import { CosmosResource, ResponseError } from '../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

export type AttendanceGuidelinesPoliciesResource = CosmosResource<{
    isEnabled: boolean
}>

type UseAttendanceGuidelinesPoliciesResource = {
    attendanceGuidelinesPolicies?: AttendanceGuidelinesPoliciesResource
    isLoading: boolean
    error?: Error | ResponseError
    getPolicies: () => Promise<
        Res<AttendanceGuidelinesPoliciesResource | ResponseError>
    >
    savePolicies: (payload: {
        isEnabled: boolean
    }) => Promise<Res<AttendanceGuidelinesPoliciesResource | ResponseError>>
    getResponse: Res<AttendanceGuidelinesPoliciesResource | ResponseError>
    isSavingPolicies: boolean
    saveResponse: Res<AttendanceGuidelinesPoliciesResource | ResponseError>
}

export default function useAttendanceGuidelinesPoliciesResource(
    spaceId: string,
    dependencies: Array<string | undefined> = []
): UseAttendanceGuidelinesPoliciesResource {
    const {
        loading: isLoading,
        get,
        data,
        error: networkError,
        response: getResponse,
    } = useFetch<AttendanceGuidelinesPoliciesResource | ResponseError>(
        `/spaces/${spaceId}/workAttendanceGuidelines/featureFlag`,
        fetchOptions,
        dependencies
    )

    const { loading: isSavingPolicies, put, response: saveResponse } = useFetch<
        AttendanceGuidelinesPoliciesResource | ResponseError
    >(`/spaces/${spaceId}/workAttendanceGuidelines/featureFlag`, fetchOptions)

    const attendanceGuidelinesPolicies = useMemo<
        AttendanceGuidelinesPoliciesResource | undefined
    >(() => {
        if (data && 'isEnabled' in data) {
            return data as AttendanceGuidelinesPoliciesResource
        }
        return undefined
    }, [data])

    const error = useMemo(() => {
        if (networkError) {
            return networkError
        }
        if (data && 'error' in data && data.error) {
            return data
        }
        return undefined
    }, [data, networkError])

    const savePolicies = useCallback(
        (payload: { isEnabled: boolean }) => put(payload),
        [put]
    )

    return {
        attendanceGuidelinesPolicies,
        isLoading,
        error,
        getPolicies: get,
        savePolicies,
        getResponse,
        isSavingPolicies,
        saveResponse,
    }
}
