import React, { useEffect, useState } from 'react'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { format } from 'date-fns'
import SetVaccinationStatusProofButton from './SetVaccinationStatusProofButton'
import useVaccinationProofResource from '../../hooks/resources/useVaccinationProofResource'
import { LoadingBar } from '../../components/LoadingBar/index'
import ErrorAlert from '../../components/Alert/Error/index'
import {
    VaccinationLogRecordResource,
    VaccinationReviewStatus,
} from '../../types'

const VaccineProofImage = styled.img`
    width: 100%;
    height: auto;
`

export type ReviewVaccinationDocumentationModalProps = {
    spaceId: string
    vaccinationLogRecordsToReview: VaccinationLogRecordResource[]
    onClose: ({ shouldRefreshData: boolean }) => void
}

const ReviewVaccinationDocumentationModal = ({
    spaceId,
    vaccinationLogRecordsToReview,
    onClose,
}: ReviewVaccinationDocumentationModalProps): JSX.Element => {
    const [error, setError] = useState<string | undefined>()
    const [
        currentIndexOfVaccinationLog,
        setCurrentIndexOfVaccinationLog,
    ] = useState<number>(0)

    const {
        get: getVaccinationProof,
        vaccinationProof,
        isLoading: isLoadingVaccinationProofImage,
        error: errorVaccinationProofImage,
    } = useVaccinationProofResource(spaceId)

    useEffect(() => {
        if (
            vaccinationLogRecordsToReview[currentIndexOfVaccinationLog] &&
            spaceId
        ) {
            getVaccinationProof(
                vaccinationLogRecordsToReview[currentIndexOfVaccinationLog].id
            )
        }
    }, [
        currentIndexOfVaccinationLog,
        spaceId,
        getVaccinationProof,
        vaccinationLogRecordsToReview,
    ])

    function moveOnToNextVaccinationLogOrClose(): void {
        if (
            currentIndexOfVaccinationLog + 1 <
            vaccinationLogRecordsToReview.length
        ) {
            setCurrentIndexOfVaccinationLog(currentIndexOfVaccinationLog + 1)
        } else {
            onClose({ shouldRefreshData: true })
        }
    }

    return (
        <>
            <ModalHeader
                toggle={() =>
                    onClose({
                        shouldRefreshData: currentIndexOfVaccinationLog > 0,
                    })
                }
                data-test="vaccination-proof-modal-header"
            >
                View vaccination documentation
            </ModalHeader>
            <ModalBody data-test="vaccination-proof-modal-body">
                {Boolean(error) && (
                    <ErrorAlert color="danger">{error}</ErrorAlert>
                )}
                <p className="mb-0">
                    <b>
                        {
                            vaccinationLogRecordsToReview[
                                currentIndexOfVaccinationLog
                            ].firstName
                        }{' '}
                        {
                            vaccinationLogRecordsToReview[
                                currentIndexOfVaccinationLog
                            ].lastName
                        }
                    </b>
                </p>
                {vaccinationProof?.updatedAt && (
                    <p className="text-muted">
                        Uploaded{' '}
                        {format(
                            new Date(vaccinationProof.updatedAt),
                            'MM/dd/yy'
                        )}
                    </p>
                )}

                <section>
                    {isLoadingVaccinationProofImage && (
                        <LoadingBar height="500" />
                    )}
                    {!isLoadingVaccinationProofImage &&
                        vaccinationProof?.documentUrl && (
                            <VaccineProofImage
                                data-test="vaccination-proof-image"
                                src={vaccinationProof.documentUrl}
                                alt="Documentation"
                            />
                        )}
                    {!isLoadingVaccinationProofImage &&
                        errorVaccinationProofImage && (
                            <ErrorAlert color="danger">
                                Could not fetch the vaccination proof image.
                            </ErrorAlert>
                        )}
                </section>
            </ModalBody>
            <ModalFooter>
                {vaccinationLogRecordsToReview.length > 1 && (
                    <span
                        className="text-muted"
                        data-test="viewing-index-of-label"
                    >
                        Viewing {currentIndexOfVaccinationLog + 1} of{' '}
                        {vaccinationLogRecordsToReview.length}
                    </span>
                )}
                {vaccinationProof && (
                    <div className="ml-auto">
                        <SetVaccinationStatusProofButton
                            className="mr-2"
                            disabled={isLoadingVaccinationProofImage}
                            data-test="vaccination-proof-reject-button"
                            type={VaccinationReviewStatus.rejected}
                            spaceId={spaceId}
                            proofId={vaccinationProof.id}
                            onSuccess={moveOnToNextVaccinationLogOrClose}
                            onError={() =>
                                setError(
                                    'Something went wrong. We could not submit the rejection.'
                                )
                            }
                        />

                        <SetVaccinationStatusProofButton
                            disabled={isLoadingVaccinationProofImage}
                            data-test="vaccination-proof-approve-button"
                            type={VaccinationReviewStatus.verified}
                            spaceId={spaceId}
                            proofId={vaccinationProof.id}
                            onSuccess={moveOnToNextVaccinationLogOrClose}
                            onError={() =>
                                setError(
                                    'Something went wrong. We could not submit the approval.'
                                )
                            }
                        />
                    </div>
                )}
            </ModalFooter>
        </>
    )
}

export default ReviewVaccinationDocumentationModal
