import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import { createSignOutRequest } from '../../state/user'

const SignOutModal = ({ onClose }) => {
    const { track } = useAnalytics()
    const dispatch = useDispatch()

    function handleSignOut() {
        track('signout_action', {
            action: 'Click',
            label: 'menu_confirm_sign_out',
        })
        dispatch(createSignOutRequest())
        onClose()
    }
    function handleCancel() {
        track('signout_action', {
            action: 'Click',
            label: 'menu_cancel_sign_out',
        })
        onClose()
    }

    return (
        <>
            <ModalHeader toggle={handleCancel}>Sign out</ModalHeader>
            <ModalBody>
                <p className="mb-0">Are you sure you want to sign out?</p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="danger" onClick={handleSignOut}>
                    Sign Out
                </Button>
            </ModalFooter>
        </>
    )
}

SignOutModal.propTypes = {
    onClose: PropTypes.func,
}

export default SignOutModal
