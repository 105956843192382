// TODO: Move this file into util/.

import { compile } from 'path-to-regexp'

const cache = new Map()

function getFromCache(path) {
    if (!cache.has(path)) {
        cache.set(path, compile(path))
    }
    return cache.get(path)
}

/**
 * Parses regular expression path patterns and replaces with values
 *
 * @example
 *
 * generatePathUrl('/spaces/:spaceAlias', { spaceAlias: '12345' })
 * result: /spaces/12345
 *
 * generate('/spaces/:spaceAlias/carpoolers/active\\?search=:searchValue', { spaceAlias: '12345', searchValue: 'hello' })
 * /spaces/12345/carpoolers/active?search=hello
 *
 *
 * @param {String} path - Regular expression pattern for path
 * @param {Object} params - Object with key values to replace url pattern
 */
export default function generatePathUrl(path, params = {}) {
    const toPath = getFromCache(path)
    return toPath(params)
}
