import React, { useState, useCallback, useEffect, useMemo } from 'react'
import {
    isBefore,
    parseISO,
    isSameMonth,
    getYear,
    getMonth,
    subMonths,
} from 'date-fns'
import { useLocation, useNavigate } from '@reach/router'

import { Container } from 'reactstrap'
import Header from './Header'
import Routes from './Routes'

import { getTitleFromLocation, getAnalyticsCategoryFromLocation } from './util'
import usePeriodFromUrl from '../../hooks/usePeriodFromUrl'
import { useSpaces } from '../../providers/SpacesProvider.tsx'
import CommuteProvider from '../../providers/CommuteProvider'

const topOfPageCharts = ['one-way-trips', 'active-commuters']
const PADDING = 10

const scrollElementByIdIntoView = (elementId) => {
    const element = document.getElementById(elementId)

    // If the chart is at the top of the page, don't scroll
    if (!topOfPageCharts.includes(element.id)) {
        const distanceFromTop = element.getBoundingClientRect().top
        window.scrollTo(0, distanceFromTop - PADDING)
    }
}

const CommuteOverviewAndActivity = () => {
    const navigate = useNavigate()
    const { pathname, hash } = useLocation()
    const { tripsStartDate } = useSpaces()
    const { period, hasPeriod, changePeriod } = usePeriodFromUrl()

    const [errorIds, setErrorIds] = useState([])
    const isDataReady = !errorIds.includes('SPACE_NOT_READY')

    const haveTripsStarted = useMemo(() => {
        const now = new Date()
        const parsed = parseISO(tripsStartDate)

        return isBefore(parsed, now)
    }, [tripsStartDate])

    const handleMetricError = useCallback((errorId) => {
        setErrorIds((prevErrorIDs) => [...prevErrorIDs, errorId])
    }, [])

    useEffect(() => {
        if (hash) {
            scrollElementByIdIntoView(hash.slice(1)) // Ignore the leading #
        }
    }, [hash])

    // If not specified, set the current time period as the previous month.
    // Unless the trips start date is this month, then set as this month
    if (!hasPeriod) {
        const now = new Date()
        const parsedTripsStartDate = parseISO(tripsStartDate)
        const isTripsStartDateThisMonth = isSameMonth(now, parsedTripsStartDate)

        const prevMonthDate = subMonths(now, 1)
        const month = getMonth(isTripsStartDateThisMonth ? now : prevMonthDate)
        const year = getYear(isTripsStartDateThisMonth ? now : prevMonthDate)

        navigate(`${pathname}?year=${year}&month=${month}`)
        // navigate is async, so render nothing while redirect happens
        return null
    }

    const commuteValue = {
        isDataReady,
        period,
        haveTripsStarted,
        changePeriod,
        handleMetricError,
    }

    const headerTitle = getTitleFromLocation(pathname)
    const analyticsCategory = getAnalyticsCategoryFromLocation(pathname)

    return (
        <Container size="xl">
            <CommuteProvider value={commuteValue}>
                <Header
                    title={headerTitle}
                    period={period}
                    analyticsCategory={analyticsCategory}
                />

                <Routes />
            </CommuteProvider>
        </Container>
    )
}

export default CommuteOverviewAndActivity
