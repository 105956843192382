import React from 'react'
import PropTypes from 'prop-types'
import { Label, Form, FormGroup, Input } from 'reactstrap'

import styled from 'styled-components'

const StyledFormGroup = styled(FormGroup)`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-right: 5rem;
    justify-self: flex-end;
    float: right;
`

const RadioButtons = ({
    legend,
    value,
    options,
    onChange,
    onClick,
    isVisible,
    chartId,
}) => {
    return (
        <StyledFormGroup className={isVisible ? 'visible' : 'invisible'}>
            <p className="mr-5">{legend}: </p>

            <Form className="d-flex">
                {Object.entries(options).map(([option, label]) => {
                    const inputId = `${chartId}-${option}`

                    return (
                        <FormGroup key={option} className="mr-4 custom-input">
                            <Input
                                type="radio"
                                id={inputId}
                                checked={value === option}
                                name={label}
                                onChange={() => {
                                    onClick(option)
                                    onChange(option)
                                }}
                            />
                            <Label for={inputId}>{label}</Label>
                        </FormGroup>
                    )
                })}
            </Form>
        </StyledFormGroup>
    )
}

RadioButtons.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    legend: PropTypes.string.isRequired,
    chartId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.string).isRequired,
    isVisible: PropTypes.bool.isRequired,
}
export default RadioButtons
