import React from 'react'
import { toast as toastify } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import _ from 'lodash'

import CloseButton from './CloseButton'
import ToastContent from './Content'
import defaultTheme from '../../styles/theme'

export enum ToastType {
    success = 'success',
    error = 'error',
    info = 'info',
    loading = 'loading',
    connectionError = 'connectionError',
}

/* Maps our custom toast types to the correct toastify type that should be used to call toastify */
const toastifyTypeMap = {
    [ToastType.success]: 'success',
    [ToastType.error]: 'error',
    [ToastType.info]: 'info',
    [ToastType.loading]: 'info',
    [ToastType.connectionError]: 'info',
}

/**
 * Curried function generating toast declaration function.
 * Will render the toast with sane-defaults and ensure proper styling.
 * @param customType - The type of toast you want to render. One of our custom types defined below
 */
const toastifyGenerator = (customType: ToastType) => {
    const toastifyType = toastifyTypeMap[customType]

    return (msg?: string, options = {}) => {
        return toastify[toastifyType](
            <ThemeProvider theme={defaultTheme}>
                <ToastContent
                    message={msg}
                    type={customType}
                    iconClass={defaultTheme.Toast[customType].iconClass}
                />
            </ThemeProvider>,
            {
                className: `rounded cursor-default ${defaultTheme.Toast[customType].containerClass}`,
                autoClose: 5000,
                hideProgressBar: true,
                bodyClassName: `d-flex flex-basis-auto ${defaultTheme.Toast[customType].textClass}`,
                closeButton: (
                    <CloseButton
                        closeButtonClass={
                            defaultTheme.Toast[customType].closeButtonClass
                        }
                    />
                ),
                draggable: false,
                closeOnClick: false,
                ...options,
            }
        )
    }
}

// Define custom types to be called like toast.success
export const success = toastifyGenerator(ToastType.success)
export const info = toastifyGenerator(ToastType.info)
export const error = toastifyGenerator(ToastType.error)
export const loading = toastifyGenerator(ToastType.loading)
export const connectionError = toastifyGenerator(ToastType.connectionError)
export const dismiss = (): void => toastify.dismiss()

export const debouncedError = _.debounce(
    toastifyGenerator(ToastType.error),
    500
)

export default {
    success,
    info,
    error,
    loading,
    connectionError,
    dismiss,
    debouncedError,
}
