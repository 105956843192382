import mime from 'mime/lite'
/**
 * Utility function that returns helper utilities for handling `useFetch` file uploads to support `File` object types
 * Currently `use-http` does not support `File` objects, this exposes a set of work-around utilities that
 * essentially, pass the `File` object into a `FormData` object with an identifier, FormData objects are supported by use-http
 *
 * // NOTE: Perhaps it will make more sense to add the request interceptor at the global FetchProvider level,
 *    given that we could just use the `prepareCsvFileForPost` helper in our useFetch cases
 *
 * @returns Object
 */
function createRequestInterceptor() {
    /**
     * The fileRequestInterceptor works in conjunction with prepareCSVFileForPost
     * it pulls the File object, out of the FormData object by the csv key
     *
     * @param {Object} options - useHTTP options: https://use-http.com/#/?id=requestresponse-interceptors-with-provider
     * @returns
     */
    function fileRequestInterceptor({ options }) {
        if (options.body instanceof FormData && options.body.get('file')) {
            // Parse out the original formData passed into post handler and override the post body
            // before sending it off to fetch
            const file = options.body.get('file')
            const contentType = mime.getType(file.name)

            options.body = file
            options.headers = {
                ...options.headers,
                'Content-Type': contentType,
                'x-scoop-filename': file.name,
            }
        }

        return options
    }

    /**
     * Passes File instance into FormData instance to be parsed by the request interceptor
     * @param {File} file - File
     * @returns
     */
    function prepareFileForPost(file) {
        const formData = new FormData()
        formData.append('file', file)

        return formData
    }

    /**
     * use-http fetch options with the defined interceptor
     */
    const fileRequestFetchOptions = {
        interceptors: {
            request: fileRequestInterceptor,
        },
    }

    return { fileRequestFetchOptions, prepareFileForPost }
}

export { createRequestInterceptor }
