import React from 'react'
import { Row, Col } from 'reactstrap'
import { string, node, oneOfType } from 'prop-types'

const Header = ({ title, description, children }) => {
    return (
        <Row className="py-3">
            <Col>
                <h2 data-test="title">{title}</h2>
                {description && (
                    <p className="text-muted" data-test="description">
                        {description}
                    </p>
                )}
            </Col>
            {children && (
                <Col className="d-flex justify-content-end align-items-center">
                    {children}
                </Col>
            )}
        </Row>
    )
}

Header.propTypes = {
    title: string,
    description: oneOfType([string, node]),
    children: node,
}

export { Header }
export default Header
