import { call, getContext, takeEvery, put, select } from 'redux-saga/effects'
import toast from '../../components/Toast/toast.tsx'
import {
    CARPOOLER_DEACTIVATE_REQUEST,
    createCarpoolerDeactivateSuccess,
    createCarpoolerDeactivateFailure,
} from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'

const deactivateUri = (spaceId) => `/spaces/${spaceId}/carpoolerDeactivations`

export function* deactivateCarpooler({ payload = {}, meta = {} }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { carpoolerId } = payload

    try {
        const { id: spaceId } = yield select(selectCurrentSpace)
        if (!spaceId) {
            throw new Error(`Space ID is required to deactivate a carpooler.`)
        }

        yield call([cosmosApi, 'call'], deactivateUri(spaceId), {
            method: 'POST',
            body: {
                account: {
                    id: carpoolerId,
                },
            },
        })

        yield put(createCarpoolerDeactivateSuccess({ carpoolerId }))
        toast.success('Commuter successfully deactivated')
    } catch (err) {
        yield put(createCarpoolerDeactivateFailure({ carpoolerId, error: err }))
        const message =
            err?.message ||
            'Something went wrong deactivating. Please try again.'
        toast.error(message)

        return err
    }
}

export default function* watchDeactivateCarpooler() {
    yield takeEvery(CARPOOLER_DEACTIVATE_REQUEST, deactivateCarpooler)
}
