import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Button as ReactStrapButton,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Label,
} from 'reactstrap'
import Button from '../Button.tsx'

const defaultCodeValidationFunc = (code) => code.length === 6
const Verify2FACode = ({
    codeVerifying = false,
    resendCode = () => {},
    message,
    onSubmit = () => {},
    onSubmitClick = () => {},
    codeValidationFunc = defaultCodeValidationFunc,
}) => {
    const [code, setCode] = useState('')
    const onFormSubmit = (e) => {
        e.preventDefault()
        onSubmit(code)
    }
    const valid = codeValidationFunc(code)
    const invalid = !valid
    const isButtonDisabled = invalid || codeVerifying

    return (
        <Form onSubmit={onFormSubmit}>
            <h1>Enter code</h1>
            <p>{message}</p>
            <FormGroup>
                <Label for="code">Code</Label>
                <Input
                    valid={valid}
                    invalid={Boolean(code) && invalid}
                    id="code"
                    type="text"
                    value={code}
                    placeholder="_ _ _ _ _ _"
                    onChange={(e) => setCode(e.target.value)}
                ></Input>
                <FormFeedback valid={!invalid}>
                    Please enter a valid code
                </FormFeedback>
            </FormGroup>
            <Button
                disabled={isButtonDisabled}
                type="submit"
                isLoading={codeVerifying}
                onClick={() => onSubmitClick({ disabled: invalid })}
                label="Verify code"
                loadingLabel="Verifying code"
            />
            <ReactStrapButton
                id="resend-2FA-code"
                block
                type="button"
                color="link"
                onClick={() => {
                    setCode('')
                    resendCode()
                }}
            >
                Resend code
            </ReactStrapButton>
        </Form>
    )
}

Verify2FACode.propTypes = {
    codeVerifying: PropTypes.bool,
    resendCode: PropTypes.func,
    message: PropTypes.string,
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
    codeValidationFunc: PropTypes.func,
}

export default Verify2FACode
