import { createSelector } from 'reselect'

export const selectActivatedCarpoolers = (state) => state?.carpoolers?.activated

export const selectDeactivateCarpoolerRequest = (state) =>
    state?.carpoolers?.deactivateCarpoolerRequest

export const selectQueryFilterParameters = (state) =>
    state.carpoolers.queryFilterParameters

export const selectQueryFilterParametersRequest = (state) =>
    state?.carpoolers?.queryFilterParametersRequest

export const selectDownloadActivatedCarpoolersRequest = (state) =>
    state?.carpoolers?.downloadActivatedRequest

export const selectDownloadDeactivatedCarpoolersRequest = (state) =>
    state?.carpoolers?.downloadDeactivatedRequest

export const selectCarpoolerCurrentPage = (state) =>
    state?.carpoolers?.pagination?.currentPage

export const selectCarpoolerTotalCount = (state) =>
    state?.carpoolers?.totalCount

export const selectDeactivatedCarpoolerTotalCount = (state) =>
    state?.carpoolers?.deactivations?.totalCount

export const selectDeactivationsData = (state) =>
    state?.carpoolers?.deactivations?.data

export const selectDeactivationsMetadata = (state) =>
    state?.carpoolers?.deactivations?.metadata

export const selectDeactivationsPagination = (state) =>
    state?.carpoolers?.deactivations?.pagination

export const selectDeactivationsGetRequest = (state) =>
    state?.carpoolers?.deactivations?.getRequest

export const selectDeactivationsUploadRequest = (state) =>
    state?.carpoolers?.deactivations?.uploadRequest

export const selectDeactivationsUploadPreview = (state) =>
    state?.carpoolers?.deactivations?.uploadPreview

export const selectDeactivationsDeleteRequests = (state) =>
    state?.carpoolers?.deactivations?.deleteRequests

export const selectDeleteRequestsForDeactivationId = (deactivationId) =>
    createSelector(
        selectDeactivationsDeleteRequests,
        (deleteRequestsByDeactivationId) =>
            deleteRequestsByDeactivationId[deactivationId]
    )
