import React from 'react'
import { Row, Col, FormGroup } from 'reactstrap'

import FormTextInput from '../../../components/FormTextInput'
import AddressTypeahead from '../../../components/AddressTypeahead'
import GhostLoader from '../../../components/GhostLoader'
import {
    validateRequired,
    buildingMaxCapacityValidator,
} from '../../../util/validators'
import { FinalFormHandleSubmit } from '../../../types'

function validateBuildingName(value) {
    return validateRequired(value, 'Please enter a building name')
}

function validateAddress(value) {
    return validateRequired(value, 'Please enter a valid address')
}

type LoaderProps = {
    children: JSX.Element | string
}

const Loader = ({ children }: LoaderProps): JSX.Element => (
    <>
        <span>{children}</span>
        <GhostLoader height={28} className="mt-3 mb-5" />
    </>
)

type Props = {
    isLoading?: boolean
    onSubmit: FinalFormHandleSubmit
    onAddressChange: (address: string) => void
    isAddressTypeaheadDisabled?: boolean

    // final-form's initial values.
    // Pass this in order to fill initial values of the form.
    initialValues?: {
        name?: string
        address?: string
        maxCapacity?: string
    }
}

/**
 * Building form used in adding a building and modifying a building.
 */
const BuildingForm = ({
    isLoading,
    onSubmit,
    onAddressChange,
    isAddressTypeaheadDisabled,
    initialValues,
}: Props): JSX.Element => {
    const initialAddress = initialValues?.address

    return (
        <>
            <Row>
                <Col className="pl-0 border-bottom mr-4">
                    <h5>Building details</h5>
                </Col>
            </Row>
            <Row>
                <Col className="pl-0 pt-4">
                    <form onSubmit={onSubmit}>
                        <FormGroup className="mb-4">
                            {isLoading ? (
                                <Loader>Building name</Loader>
                            ) : (
                                <FormTextInput
                                    data-test="building-name"
                                    label="Building name"
                                    name="name"
                                    bsSize="lg"
                                    showError
                                    invalid={false}
                                    validate={validateBuildingName}
                                />
                            )}
                        </FormGroup>

                        <FormGroup>
                            {isLoading ? (
                                <Loader>Address</Loader>
                            ) : (
                                <AddressTypeahead
                                    test-attr="address-typeahead"
                                    label="Address"
                                    name="address"
                                    id="address-typeahead"
                                    inputProps={{
                                        'data-test': 'address-typeahead-input',
                                    }}
                                    size="lg"
                                    onAddressChange={onAddressChange}
                                    validate={validateAddress}
                                    defaultSelected={
                                        initialAddress && [initialAddress]
                                    }
                                    showError
                                    disabled={isAddressTypeaheadDisabled}
                                />
                            )}
                        </FormGroup>
                        <FormGroup>
                            {isLoading ? (
                                <Loader>Daily check-in limit</Loader>
                            ) : (
                                <FormTextInput
                                    data-test="max-capacity"
                                    label="Daily check-in limit"
                                    inputHint="The maximum number of employees who can check in to this building per day."
                                    name="maxCapacity"
                                    type="number"
                                    bsSize="lg"
                                    showError
                                    showOptionalLabel
                                    min="1"
                                    invalid={false}
                                    validate={buildingMaxCapacityValidator}
                                />
                            )}
                        </FormGroup>
                    </form>
                </Col>
            </Row>
        </>
    )
}

export default BuildingForm
