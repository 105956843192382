import { put, call, getContext } from 'redux-saga/effects'
import { createSessionTransactionAdd, createFactorAdd } from '../../state/auth'

export const sessionTypeOkta = 'okta'
export const sessionTypeEmailAndPassword = 'emailAndPassword'
export const statusSuccess = 'SUCCESS'
export const statusEnroll = 'MFA_ENROLL'
export const statusVerify = 'MFA_VERIFY'

export const uri = '/users/sessionTransactions'

export const emailAndPasswordRequestBody = ({ email, password }) => ({
    authenticationStrategy: {
        type: sessionTypeEmailAndPassword,
        email,
        password,
    },
})

export const oktaRequestBody = () => {
    return {
        authenticationStrategy: {
            type: sessionTypeOkta,
        },
    }
}

/* Encapsulate logic for interacting with /sessionTransaction API.
 */
export function* createSessionTransaction({ email, password }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { parsedBody } = yield call([cosmosApi, 'call'], uri, {
        method: 'POST',
        body:
            email && password
                ? emailAndPasswordRequestBody({ email, password })
                : oktaRequestBody(),
    })
    const sessionTransaction = parsedBody
    const factor = sessionTransaction.factor || {}
    yield put(
        createSessionTransactionAdd({
            ...sessionTransaction,
            sessionTransactionId: sessionTransaction.id,
            factorId: factor.id,
        })
    )
    if (sessionTransaction.status === statusVerify) {
        // API indicates that there is a factor already enrolled.
        const factorAddPayload = {
            factorId: factor.id,
            type: factor.type,
            phoneLast4: factor?.profile?.phoneLast4,
        }
        yield put(createFactorAdd(factorAddPayload))
    }
    return sessionTransaction
}
