import React, { useContext, useState, useCallback } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Container } from 'reactstrap'

import { format } from 'date-fns'
import UploadInstructions from './UploadInstructions'
import PreviewDeskUpload from './PreviewDeskUpload'
import { Header } from '../../../components/PageLayout'
import FullPageModal from '../../../components/FullPageModal'
import HelpCenterLink from '../HelpCenterLink'
import useSpaces from '../../../hooks/useSpaces'
import { DesksContext } from '../../../providers/DesksContextProvider'
import useUploadDesks from '../../../hooks/useUploadDesks'
import { PodWithNeighborhood } from '../../../util/pods/getBuildingsFromPods'

const AddDesksModalPage = (props: RouteComponentProps): JSX.Element => {
    const { alias: spaceAlias, id: spaceId } = useSpaces()
    const { buildings, getBuildings } = useContext(DesksContext)
    const { updateBuildingCapacity } = useUploadDesks()
    const [podsToSave, updatePodsToSave] = useState<PodWithNeighborhood[]>([])
    const [showInstructions, setShowInstructions] = useState(true)
    const [file, updateFile] = useState<File>()

    const navigateToDesks = useCallback(() => {
        navigate(`/spaces/${spaceAlias}/workplace/desks`)
    }, [spaceAlias])

    const handleUploadCsvFile = (podsToSave: PodWithNeighborhood[]) => {
        updatePodsToSave(podsToSave)

        setTimeout(() => {
            setShowInstructions(false)
        }, 1000)
    }

    const onSaveSuccess = () => {
        getBuildings({ activeAfter: format(new Date(), 'yyyy-MM-dd') })
        navigateToDesks()
    }

    return (
        <FullPageModal
            hideBackButton={showInstructions}
            onClickBack={() => setShowInstructions(true)}
            onClose={() => {
                updatePodsToSave([])
                navigateToDesks()
            }}
        >
            <Container size="xl" className="my-4">
                <Header
                    title="Add desks"
                    description={
                        <span>
                            To add desks, upload a properly formatted CSV below.
                            Visit our <HelpCenterLink /> for more information.
                        </span>
                    }
                />
                {showInstructions ? (
                    <UploadInstructions
                        onUploadSuccess={handleUploadCsvFile}
                        hasNoBuildings={buildings && buildings.length === 0}
                        spaceAlias={spaceAlias}
                        spaceId={spaceId}
                        onFileChange={(file) => updateFile(file)}
                    />
                ) : (
                    <PreviewDeskUpload
                        spaceId={spaceId}
                        pods={podsToSave}
                        buildings={buildings}
                        updateBuildingCapacity={updateBuildingCapacity}
                        file={file}
                        onSaveSuccess={onSaveSuccess}
                    />
                )}
            </Container>
        </FullPageModal>
    )
}

export default AddDesksModalPage
