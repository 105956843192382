import { CachePolicies, useFetch } from 'use-http'
import { useCallback, useMemo } from 'react'
import {
    CosmosError,
    ResponseError,
    VaccinationProofResource,
    VaccinationReviewStatus,
} from '../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

type UseVaccinationProofResource = {
    isLoading: boolean
    vaccinationProof?: VaccinationProofResource
    error?: Error | CosmosError
    get: (vaccinationStatusId: string) => Promise<VaccinationProofResource>
    setStatus: (
        proofId: string,
        status: VaccinationReviewStatus
    ) => Promise<VaccinationProofResource>
    response: Response
}

/**
 * Hook to fetch a vaccination proof resource. Endpoints:
 * - GET /spaces/${spaceId}/vaccinationStatus/{vaccinationStatusId}/proof
 * - PATCH /spaces/${spaceId}/vaccinationStatus/proof/{proofId}
 * @params spaceId - space alias of the target customer.
 * @params dependencies - dependency array of the hook. Each change will trigger a new request to the API.
 */
export default function useVaccinationProofResource(
    spaceId: string
): UseVaccinationProofResource {
    const {
        data,
        loading,
        error: networkError,
        get,
        patch,
        response,
    } = useFetch<VaccinationProofResource | ResponseError>(
        `/spaces/${spaceId}/vaccinationStatus`,
        fetchOptions
    )

    const getProof = useCallback(
        (vaccinationStatusId: string) => get(`${vaccinationStatusId}/proof`),
        [get]
    )

    const vaccinationProof:
        | VaccinationProofResource
        | undefined = useMemo(() => {
        if (data && 'id' in data && 'reviewStatus' in data) {
            return data as VaccinationProofResource
        }
        return undefined
    }, [data])

    const error = useMemo(() => {
        if (data && 'error' in data) {
            return data.error as CosmosError
        } else if (networkError) {
            return networkError
        }

        return undefined
    }, [data, networkError])

    const setStatus = useCallback(
        async (proofId: string, status: VaccinationReviewStatus) =>
            patch(`proof/${proofId}`, { reviewStatus: status }),
        [patch]
    )

    return {
        isLoading: loading,
        vaccinationProof,
        get: getProof,
        error,
        setStatus,
        response,
    }
}
