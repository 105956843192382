import React, { Attributes, FunctionComponent, ReactHTML } from 'react'
import { VaccinationReviewStatus, VaccinationStatus } from '../../types'
import StatusBadge from '../StatusBadge'
import colors from '../../styles/colors'

type VaccinationStatusNodeDescriptor = {
    wrappingElement: FunctionComponent | keyof ReactHTML
    text: string
    props: Attributes & {
        color?: string
        'data-test': string
        className?: string
    }
}

/**
 * Returns the properties necessary to create the badge React element.
 * Doing it this way allows us to pluck the text only, which is used in AG grid value getters
 * @param {VaccinationStatus} vaccinationStatus
 * @param {VaccinationReviewStatus} reviewStatus
 * @returns {VaccinationStatusNodeDescriptor} description of React element to create
 */
export function getVaccinationStatusProps(
    vaccinationStatus: VaccinationStatus,
    reviewStatus?: VaccinationReviewStatus
): VaccinationStatusNodeDescriptor {
    if (
        vaccinationStatus === VaccinationStatus.fullyVaccinated &&
        reviewStatus
    ) {
        if (reviewStatus === VaccinationReviewStatus.verified) {
            return {
                wrappingElement: StatusBadge,
                text: 'Fully Vaccinated (Verified)',
                props: {
                    'data-test': 'badge-fully-vaccinated-verified',
                    color: colors.green[100],
                },
            }
        }
        if (reviewStatus === VaccinationReviewStatus.pending) {
            return {
                wrappingElement: StatusBadge,
                text: 'Fully Vaccinated (Pending review)',

                props: {
                    'data-test': 'badge-fully-vaccinated-pending-review',
                    color: colors.blue[100],
                },
            }
        }
        if (reviewStatus === VaccinationReviewStatus.rejected) {
            return {
                wrappingElement: StatusBadge,
                text: 'Fully Vaccinated (Document rejected)',
                props: {
                    'data-test': 'badge-fully-vaccinated-document-rejected',
                    color: colors.red[0],
                },
            }
        }
    }
    if (
        vaccinationStatus === VaccinationStatus.fullyVaccinated &&
        !reviewStatus
    ) {
        return {
            wrappingElement: StatusBadge,
            text: 'Fully Vaccinated',
            props: {
                'data-test': 'badge-fully-vaccinated-pending-upload',
                color: colors.blue[100],
            },
        }
    }

    if (vaccinationStatus === VaccinationStatus.notVaccinated) {
        return {
            wrappingElement: 'span',
            text: 'Not Vaccinated',
            props: {
                'data-test': 'not-vaccinated',
            },
        }
    }

    if (vaccinationStatus === VaccinationStatus.preferNotToSay) {
        return {
            wrappingElement: 'span',
            text: 'Prefer not to say',
            props: {
                'data-test': 'prefer-not-to-say',
            },
        }
    }

    if (vaccinationStatus === VaccinationStatus.exemptMedical) {
        return {
            wrappingElement: StatusBadge,
            text: 'Exempt - Medical',
            props: {
                'data-test': 'badge-exempt-medical',
                color: colors.yellow[0],
            },
        }
    }

    if (vaccinationStatus === VaccinationStatus.exemptReligious) {
        return {
            wrappingElement: StatusBadge,
            text: 'Exempt - Religious',
            props: {
                'data-test': 'badge-exempt-religious',
                color: colors.yellow[0],
            },
        }
    }

    return {
        wrappingElement: 'i',
        text: 'No Response',
        props: {
            'data-test': 'no-response',
            className: 'text-muted',
        },
    }
}

/**
 * Dynamically create the vaccination React element based on the status and review status
 * @param {VaccinationStatus} vaccinationStatus
 * @param {VaccinationReviewStatus} reviewStatus
 */
const VaccinationStatusBadge = ({
    vaccinationStatus,
    reviewStatus,
}: {
    vaccinationStatus: VaccinationStatus
    reviewStatus?: VaccinationReviewStatus
}): JSX.Element => {
    const definingObject = getVaccinationStatusProps(
        vaccinationStatus,
        reviewStatus
    )

    return React.createElement(
        definingObject.wrappingElement,
        definingObject.props,
        definingObject.text
    )
}

export default VaccinationStatusBadge
