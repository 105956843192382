import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import Desks from './Desks'
import AddDesksModalPage from './AddDesksModalPage'
import ModuleFlag from '../../components/ModuleFlag/index.js'
import { DesksContextProvider } from '../../providers/DesksContextProvider.js'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const DeskPages = (props: RouteComponentProps): JSX.Element => {
    return (
        <ModuleFlag name="workplacePlanner.deskManagement">
            <DesksContextProvider>
                <Router data-test="desk-page-with-neighborhoods">
                    <Desks path="/" />
                    <AddDesksModalPage path="upload" />
                </Router>
            </DesksContextProvider>
        </ModuleFlag>
    )
}

export default DeskPages
