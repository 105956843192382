export const CARPOOLERS_ACTIVATED_ADD = 'CARPOOLERS:ADD:ACTIVATED'
export const CARPOOLERS_ACTIVATED_REPLACE = 'CARPOOLERS:REPLACE:ACTIVATED'

// Download Activated
export const CARPOOLERS_DOWNLOAD_ACTIVATED_REQUEST =
    'CARPOOLER:DOWNLOAD:ACTIVATED:REQUEST'
export const CARPOOLERS_DOWNLOAD_ACTIVATED_FAILURE =
    'CARPOOLER:DOWNLOAD:ACTIVATED:FAILURE'
export const CARPOOLERS_DOWNLOAD_ACTIVATED_SUCCESS =
    'CARPOOLER:DOWNLOAD:ACTIVATED:SUCCESS'

// Get Activated
export const CARPOOLERS_GET_ACTIVATED_FAILURE =
    'CARPOOLERS:GET:ACTIVATED:FAILURE'
export const CARPOOLERS_GET_ACTIVATED_SUCCESS =
    'CARPOOLERS:GET:ACTIVATED:SUCCESS'

// Paginate
export const CARPOOLERS_PAGINATE_REQUEST = 'CARPOOLER:PAGINATE:REQUEST'
export const CARPOOLERS_PAGINATE_REQUEST_SUCCESS =
    'CARPOOLER:PAGINATE:REQUEST:SUCCESS'
export const CARPOOLERS_TOTAL_COUNT_UPDATE = 'CARPOOLER:TOTAL_COUNT'
export const CARPOOLERS_PAGINATE_REQUEST_FAILURE =
    'CARPOOLER:PAGINATE:REQUEST:FAILURE'

// Query Filter Params
export const CARPOOLERS_QUERY_FILTER_PARAMS_REQUEST =
    'CARPOOLER:QUERY_FILTER:REQUEST'
export const CARPOOLERS_QUERY_FILTER_PARAMS_SUCCESS =
    'CARPOOLER:QUERY_FILTER:SUCCESS'
export const CARPOOLERS_QUERY_FILTER_PARAMS_FAILURE =
    'CARPOOLER:QUERY_FILTER:FAILURE'
export const CARPOOLERS_UPDATE_QUERY_TOKEN = 'CARPOOLER:UPDATE:QUERY_TOKEN'

// Deactivate
export const CARPOOLER_DEACTIVATE_REQUEST = 'CARPOOLER:DEACTIVATE:REQUEST'
export const CARPOOLER_DEACTIVATE_FAILURE = 'CARPOOLER:DEACTIVATE:FAILURE'
export const CARPOOLER_DEACTIVATE_SUCCESS = 'CARPOOLER:DEACTIVATE:SUCCESS'
export const CARPOOLER_DEACTIVATE_RESET = 'CARPOOLER:DEACTIVATE:RESET'

// All Active Carpoolers SubReducers Reset
export const CARPOOLER_ACTIVATED_ALL_RESET = 'CARPOOLER:ACTIVATED:ALL:RESET'

// Deactivations
export const CARPOOLER_DEACTIVATIONS_METADATA_REPLACE =
    'CARPOOLER:DEACTIVATIONS:METADATA:REPLACE'
export const CARPOOLER_DEACTIVATIONS_DATA_REPLACE =
    'CARPOOLER:DEACTIVATIONS:DATA:REPLACE'
export const CARPOOLER_DEACTIVATIONS_DELETE_REQUEST =
    'CARPOOLER:DEACTIVATIONS:DELETE:REQUEST'
export const CARPOOLER_DEACTIVATIONS_DELETE_SUCCESS =
    'CARPOOLER:DEACTIVATIONS:DELETE:SUCCESS'
export const CARPOOLER_DEACTIVATIONS_DELETE_FAILURE =
    'CARPOOLER:DEACTIVATIONS:DELETE:FAILURE'

// Download Deactivations
export const CARPOOLER_DEACTIVATIONS_DOWNLOAD_REQUEST =
    'CARPOOLER:DOWNLOAD:DEACTIVATED:REQUEST'
export const CARPOOLER_DEACTIVATIONS_DOWNLOAD_SUCCESS =
    'CARPOOLER:DOWNLOAD:DEACTIVATED:SUCCESS'
export const CARPOOLER_DEACTIVATIONS_DOWNLOAD_FAILURE =
    'CARPOOLER:DOWNLOAD:DEACTIVATED:FAILURE'

// Get Deactivations
export const CARPOOLER_DEACTIVATIONS_GET_REQUEST =
    'CARPOOLER:DEACTIVATIONS:GET:REQUEST'
export const CARPOOLER_DEACTIVATIONS_GET_SUCCESS =
    'CARPOOLER:DEACTIVATIONS:GET:SUCCESS'
export const CARPOOLER_DEACTIVATIONS_GET_FAILURE =
    'CARPOOLER:DEACTIVATIONS:GET:FAILURE'

export const CARPOOLER_DEACTIVATIONS_PAGINATION_REPLACE =
    'CARPOOLER:DEACTIVATIONS:PAGINATION:REPLACE'
export const CARPOOLER_DEACTIVATIONS_TOTAL_COUNT_UPDATE =
    'CARPOOLER:DEACTIVATIONS:TOTAL_COUNT'

// Deactivations Upload
export const CARPOOLER_DEACTIVATIONS_UPLOAD_REQUEST =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:REQUEST'
export const CARPOOLER_DEACTIVATIONS_UPLOAD_SUCCESS =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:SUCCESS'
export const CARPOOLER_DEACTIVATIONS_UPLOAD_FAILURE =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:FAILURE'
export const CARPOOLER_DEACTIVATIONS_UPLOAD_RESET =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:RESET'

// Deactivations Upload Preview
export const CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_REPLACE =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:PREVIEW:REPLACE'
export const CARPOOLER_DEACTIVATIONS_UPLOAD_PREVIEW_RESET =
    'CARPOOLER:DEACTIVATIONS:UPLOAD:PREVIEW:RESET'

export const CARPOOLER_DEACTIVATIONS_CURRENT_PAGE_REPLACE =
    'CARPOOLER:DEACTIVATIONS:CURRENT_PAGE:REPLACE'

// All Deactivations SubReducers Reset
export const CARPOOLER_DEACTIVATIONS_ALL_RESET =
    'CARPOOLER:DEACTIVATIONS:ALL:RESET'
