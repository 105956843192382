import React from 'react'
import {
    AttendanceCellRenderer,
    BuildingCellRenderer,
    columnDefinitions,
    DayPassIdCellRenderer,
    getIsFooterVisible,
    HealthAndSafetyTimeStampCellRenderer,
    StatusCellRenderer,
} from './util'
import AgGridWrapper from '../../../components/AgGridWrapper'
import Pagination from '../../../components/Pagination'
import TotalPageCount from '../../../components/TotalPageCount'
import { WorkAttendance } from '../../../types'
import { Pagination as PaginationType } from '../../../hooks/useServerPagination'

type Props = {
    onSort: (sortIdentifier: string) => void
    sortBy?: string
    sortOrder?: string
    isLoading: boolean
    error?: Error
    checkIns?: WorkAttendance[]
    totalCount: number
    pagination: PaginationType
}

const CheckInsTable = ({
    onSort,
    sortBy,
    sortOrder,

    isLoading,
    error,
    checkIns,
    totalCount,
    pagination,
}: Props): JSX.Element => {
    const {
        fromResult,
        toResult,
        showPrevious,
        showNext,
        pagesToShow,
        currentPage,
        changePage,
    } = pagination
    const isFooterVisible = getIsFooterVisible({ isLoading, error, checkIns })

    return (
        <>
            <AgGridWrapper
                isLoading={isLoading}
                onSort={onSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
                pagination={false}
                rowData={checkIns}
                noRowsOverlayComponentParams={{
                    description: 'No check-ins found.',
                }}
                frameworkComponents={{
                    statusComponent: StatusCellRenderer,
                    attendanceComponent: AttendanceCellRenderer,
                    dayPassIdCellRenderer: DayPassIdCellRenderer,
                    healthAndSafetyTimeStampCellRenderer: HealthAndSafetyTimeStampCellRenderer,
                    buildingCellRenderer: BuildingCellRenderer,
                }}
                defaultColDef={{
                    resizable: true,
                    suppressMovable: true,
                }}
                columnDefs={columnDefinitions}
            />

            {isFooterVisible && (
                <div
                    test-attr="footer"
                    className="mt-3 d-flex justify-content-between"
                >
                    <TotalPageCount
                        fromResult={fromResult}
                        toResult={toResult}
                        totalCount={totalCount}
                        label="employees"
                    />

                    <Pagination
                        showPrevious={showPrevious}
                        showNext={showNext}
                        currentPage={currentPage}
                        pagesToShow={pagesToShow}
                        onPageChange={changePage}
                    />
                </div>
            )}
        </>
    )
}

export default CheckInsTable
