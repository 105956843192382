import { combineReducers } from 'redux'
import {
    SESSION_TRANSACTION_ADD,
    SESSION_TRANSACTION_CLEAR,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_SUCCESS,
    SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_FAILURE,
    SESSION_TRANSACTION_OKTA_CREATE_REQUEST,
    SESSION_TRANSACTION_OKTA_CREATE_SUCCESS,
    SESSION_TRANSACTION_OKTA_CREATE_FAILURE,
    SESSION_TRANSACTION_CHECK_REQUEST,
    SESSION_TRANSACTION_CHECK_SUCCESS,
    SESSION_TRANSACTION_CHECK_FAILURE,
    SESSION_TRANSACTION_CHECK_CLEAR,
    FACTOR_ADD,
    FACTOR_REMOVE,
    FACTOR_ENROLL_REQUEST,
    FACTOR_ENROLL_SUCCESS,
    FACTOR_ENROLL_FAILURE,
    FACTOR_ACTIVATE_REQUEST,
    FACTOR_ACTIVATE_SUCCESS,
    FACTOR_ACTIVATE_FAILURE,
    FACTOR_VERIFY_REQUEST,
    FACTOR_VERIFY_SUCCESS,
    FACTOR_VERIFY_FAILURE,
    FACTOR_RESEND_REQUEST,
    FACTOR_RESEND_SUCCESS,
    FACTOR_RESEND_FAILURE,
} from './types'
import serializeError from '../serializeError'

/*
 * Store all session transactions, keyed by ID.
 */
export const sessionTransaction = (state = {}, { type, payload = {} }) => {
    const { sessionTransactionId, status, stateToken, factorId } = payload
    switch (type) {
        case SESSION_TRANSACTION_ADD:
            return {
                sessionTransactionId,
                status,
                stateToken,
                factorId,
            }
        case SESSION_TRANSACTION_CLEAR:
            return {}
        default:
            return state
    }
}

/*
 * Store all requests to create a session transaction.
 */
export const sessionTransactionCreateRequest = (
    state = {},
    { type, payload = {} }
) => {
    const { sessionTransactionId } = payload
    const error = serializeError(payload)
    switch (type) {
        case SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_REQUEST:
        case SESSION_TRANSACTION_OKTA_CREATE_REQUEST:
            return {
                isFetching: true,
            }
        case SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_SUCCESS:
        case SESSION_TRANSACTION_OKTA_CREATE_SUCCESS:
            return {
                isFetching: false,
                sessionTransactionId,
            }
        case SESSION_TRANSACTION_EMAIL_PASSWORD_CREATE_FAILURE:
        case SESSION_TRANSACTION_OKTA_CREATE_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export const sessionTransactionCheckRequest = (
    state = {},
    { type, payload = {} }
) => {
    const error = serializeError(payload)
    switch (type) {
        case SESSION_TRANSACTION_CHECK_CLEAR:
            return {}
        case SESSION_TRANSACTION_CHECK_REQUEST:
            return {
                isFetching: true,
            }
        case SESSION_TRANSACTION_CHECK_SUCCESS:
            return {
                isFetching: false,
                success: true,
            }
        case SESSION_TRANSACTION_CHECK_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

/*
 * Store all factors by ID.
 */

export const factor = (state = {}, { type, payload = {} }) => {
    const { factorId, type: factorType, phoneLast4 } = payload
    switch (type) {
        case FACTOR_ADD:
            return {
                factorId,
                type: factorType,
                phoneLast4,
            }
        case FACTOR_REMOVE:
            return {}
        default:
            return state
    }
}

/*
 * Store all requests to enroll a factor.
 */
export const factorEnrollRequest = (state = {}, { type, payload = {} }) => {
    const { factorId } = payload
    const error = serializeError(payload)
    switch (type) {
        case FACTOR_ENROLL_REQUEST:
            return {
                isFetching: true,
            }
        case FACTOR_ENROLL_SUCCESS:
            return {
                isFetching: false,
                factorId,
            }
        case FACTOR_ENROLL_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

/*
 * Store all requests to activate a factor
 */
export const factorActivateRequest = (state = {}, { type, payload = {} }) => {
    const { factorId } = payload
    const error = serializeError(payload)
    switch (type) {
        case FACTOR_ACTIVATE_REQUEST:
            return {
                isFetching: true,
                factorId,
            }
        case FACTOR_ACTIVATE_SUCCESS:
            return {
                isFetching: false,
            }
        case FACTOR_ACTIVATE_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

/*
 * Store all requests to verify a factor.
 */
export const factorVerifyRequest = (state = {}, { type, payload = {} }) => {
    const { factorId } = payload
    const error = serializeError(payload)
    switch (type) {
        case FACTOR_VERIFY_REQUEST:
            return {
                isFetching: true,
                factorId,
            }
        case FACTOR_VERIFY_SUCCESS:
            return {
                isFetching: false,
            }
        case FACTOR_VERIFY_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

/*
 * Store all requests to resend a factor,
 */
export const factorResendRequest = (state = {}, { type, payload = {} }) => {
    const { factorId } = payload
    const error = serializeError(payload)
    switch (type) {
        case FACTOR_RESEND_REQUEST:
            return {
                factorId,
                isFetching: true,
            }
        case FACTOR_RESEND_SUCCESS:
            return {
                isFetching: false,
            }
        case FACTOR_RESEND_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export default combineReducers({
    sessionTransaction,
    sessionTransactionCheckRequest,
    sessionTransactionCreateRequest,
    factor,
    factorEnrollRequest,
    factorActivateRequest,
    factorResendRequest,
    factorVerifyRequest,
})
