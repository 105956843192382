import { Link } from '@reach/router'
import { PropTypes } from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import srcCSVImage from './csv-example.png'
import Button from '../../../components/Button.tsx'
import ImageContainer from '../../../components/ImageContainer'
import useAnalytics from '../../../hooks/useAnalytics'
import srcError from '../../../images/error.svg'
import { selectDeactivationsUploadRequest } from '../../../state/carpoolers/selectors'
import { ButtonsContainer } from '../../../styles/Layout'

export const ErrorMessage = () => {
    return (
        <>
            <ImageContainer src={srcError} />
            <p>The CSV format is invalid. Please check that your file has:</p>
            <ol>
                <li>One column with the header text &quot;Email&quot;</li>
                <li>One email address per row under that column</li>
            </ol>
        </>
    )
}

const UploadCSV = ({ spaceAlias, onUploadFile, onSubmit }) => {
    const fileInput = useRef()
    const { track } = useAnalytics()
    const {
        isFetching: isFetchingDeactivationsUpload,
        error: deactivationsUploadError,
    } = useSelector(selectDeactivationsUploadRequest)

    const isDryRunError = deactivationsUploadError?.dryRun
    useEffect(() => {
        if (isDryRunError) {
            track('deactivate_carpoolers_action', {
                action: 'View',
                label: 'error_upload_invalid_format',
            })
        }
    }, [track, isDryRunError])

    function handleUpload(event) {
        const { target } = event
        const payload = target.files[0]
        const fileName = payload.name

        onUploadFile({ payload, fileName })
        onSubmit({ payload })

        // Clear out file input value after use in case the user uploads a file with the same name.
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        target.value = null
    }

    function handleButtonClick() {
        // fileInput is hidden so we click it via the Button
        fileInput.current.click()

        track('deactivate_carpoolers_action', {
            action: 'Click',
            label: 'choose_CSV',
        })
    }

    return (
        <>
            <h3 className="mb-4">Upload CSV to deactivate carpoolers</h3>

            {isDryRunError ? (
                <ErrorMessage />
            ) : (
                <p>
                    Your CSV should have a column labeled{' '}
                    <b>&quot;Email&quot;</b> and include one carpooler email per
                    row.
                </p>
            )}

            <p>Example:</p>
            <ImageContainer src={srcCSVImage} width={350} height={137} />

            <ButtonsContainer>
                <Button
                    isLoading={isFetchingDeactivationsUpload}
                    onClick={handleButtonClick}
                    label="Choose CSV"
                    loadingLabel="Uploading CSV..."
                />
                <input
                    style={{ display: 'none' }}
                    ref={fileInput}
                    type="file"
                    accept=".csv"
                    onChange={handleUpload}
                />
                <Link
                    to="../"
                    className="nav-link text-primary text-decoration-none text-center"
                    onClick={() =>
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'cancel_CSV',
                        })
                    }
                >
                    Cancel
                </Link>
            </ButtonsContainer>
        </>
    )
}

UploadCSV.propTypes = {
    spaceAlias: PropTypes.string,
    deactivationsPreview: PropTypes.object,
    onUploadFile: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default UploadCSV
