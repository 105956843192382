import { call, getContext, takeLatest, put } from 'redux-saga/effects'

import {
    TOKEN_METADATA_GET_REQUEST,
    createTokenAdd,
    createTokenMetadataGetFailure,
    createTokenMetadataGetSuccess,
} from '../../state/registration'

const creationTokenMetaUri = '/users/creationTokens/viewMeta'

export function* verifyRegistrationToken({ payload = {} }) {
    const cosmosApi = yield getContext('cosmosApi')
    const { tokenId } = payload

    try {
        const { parsedBody } = yield call(
            [cosmosApi, 'call'],
            creationTokenMetaUri,
            {
                method: 'POST',
                body: {
                    token: tokenId,
                },
            }
        )
        const tokenData = {
            inviterName: parsedBody.invitedBy.displayName,
            inviteeName: parsedBody.inviteeDisplayName,
            spaceName: parsedBody.space.publicDisplayName,
            expiresAt: parsedBody.expiresAt,
            claimedAt: parsedBody.claimedAt,
        }
        yield put(createTokenAdd({ ...tokenData, tokenId }))
        yield put(createTokenMetadataGetSuccess())
    } catch (err) {
        return yield put(createTokenMetadataGetFailure({ error: err }))
    }
}

export default function* watchRegistrationToken() {
    yield takeLatest(TOKEN_METADATA_GET_REQUEST, verifyRegistrationToken)
}
