/** Copied from chrome-extension */

/**
 * Join words with comma and "and".
 *
 * @example
 *   ```
 *      const fileExtensions = ['.jpg', '.png', '.pdf']
 *      joinWords(fileExtensions) // '.jpg, .png, and .pdf'
 *   ```
 */
export function joinWords(words: string[]): string {
    if (words.length === 0) {
        return ''
    }

    if (words.length === 1) {
        return words[0]
    }

    const clonedWords = [...words]
    const lastWordIndex = clonedWords.length - 1
    const lastWord = clonedWords[lastWordIndex]

    clonedWords[lastWordIndex] = `and ${lastWord}`

    return clonedWords.join(', ')
}
