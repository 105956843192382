import _ from 'lodash'
import { combineReducers } from 'redux'

import {
    CHANGE_CURRENT_SPACE,
    GET_SPACES_FAILURE,
    GET_SPACES_REQUEST,
    GET_SPACES_SUCCESS,
    SPACES_ADD,
} from './types'
import serializeError from '../serializeError'

export const getSpacesRequest = (state = {}, { type, payload = {} }) => {
    const error = serializeError(payload)
    switch (type) {
        case GET_SPACES_REQUEST:
            return {
                isFetching: true,
            }
        case GET_SPACES_SUCCESS:
            return {
                isFetching: false,
            }
        case GET_SPACES_FAILURE:
            return {
                isFetching: false,
                error,
            }
        default:
            return state
    }
}

export const byId = (state = {}, { type, payload = {} }) => {
    const { spaces } = payload
    switch (type) {
        case SPACES_ADD: {
            const spacesById = _.keyBy(spaces, 'id')

            return {
                ...state,
                ...spacesById,
            }
        }
        default:
            return state
    }
}

export const current = (state = {}, { type, payload = {} }) => {
    const {
        alias,
        customers,
        id,
        name,
        publicDisplayName,
        tripsStartDate,
    } = payload
    switch (type) {
        case CHANGE_CURRENT_SPACE:
            return {
                alias,
                customers,
                id,
                name,
                publicDisplayName,
                tripsStartDate,
            }
        default:
            return state
    }
}

export default combineReducers({
    byId,
    current,
    getSpacesRequest,
})
