import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from 'prop-types'
import React, { useState } from 'react'
import { Button, Card, Collapse, Tooltip } from 'reactstrap'

import useAnalytics from '../../../../hooks/useAnalytics'

const UnMatchedSummarySection = ({ unMatchedUsers, spaceName }) => {
    const [showUsers, setShowUsers] = useState(false)
    const [showMoreInfoTooltip, setMoreInfoTooltip] = useState(false)
    const toggleShowUsers = () => setShowUsers((prev) => !prev)
    const toggleTooltip = () => setMoreInfoTooltip((prev) => !prev)
    const { track } = useAnalytics()
    if (!unMatchedUsers.length) {
        return (
            <p>
                All email addresses in your CSV match an active commuter in the{' '}
                {spaceName} Scoop program.
            </p>
        )
    }

    const emailAddressQuantityAndLabel = `${
        unMatchedUsers.length
    } email address${unMatchedUsers.length > 1 ? 'es' : ''}`

    const emailDropdownLabel = `${
        showUsers ? 'Hide' : 'Show'
    } unmatched email address${unMatchedUsers.length > 1 ? 'es' : ''}`
    return (
        <>
            <p>
                {emailAddressQuantityAndLabel} in your CSV didn&apos;t match an
                active commuter in the {spaceName} Scoop program.{' '}
                <strong
                    id="unMatchedUsersMoreInfoTooltip"
                    className="text-primary"
                    onClick={() =>
                        track('deactivate_carpoolers_action', {
                            action: 'Click',
                            label: 'unmatched_emails_more_info',
                        })
                    }
                >
                    More Info
                </strong>
            </p>
            <Tooltip
                placement="right"
                isOpen={showMoreInfoTooltip}
                target="unMatchedUsersMoreInfoTooltip"
                toggle={toggleTooltip}
            >
                As long as former employees no longer have access to your email
                inbox, they can&apos;t link an account with {spaceName} in the
                future.
            </Tooltip>
            <Button
                id="unMatchedUsersCollapsibleToggle"
                onClick={() => {
                    toggleShowUsers()
                    track('deactivate_carpoolers_action', {
                        action: 'Click',
                        label: 'unmatched_emails_show',
                    })
                }}
                color="link"
                style={{ textDecoration: 'none' }}
                className="pl-0 text-primary"
            >
                <FontAwesomeIcon
                    size="1x"
                    icon={showUsers ? faAngleDown : faAngleRight}
                    className="mr-2"
                />
                <strong>{emailDropdownLabel}</strong>
            </Button>
            <Collapse isOpen={showUsers}>
                <Card
                    body
                    className="p-2"
                    style={{ maxHeight: '200px', overflowY: 'scroll' }}
                >
                    {unMatchedUsers.map((user, i) => (
                        <p key={i} className="mb-0">
                            {user}
                        </p>
                    ))}
                </Card>
            </Collapse>
        </>
    )
}

UnMatchedSummarySection.propTypes = {
    unMatchedUsers: PropTypes.array,
    spaceName: PropTypes.string,
}

export default UnMatchedSummarySection
