import { all, call, spawn } from 'redux-saga/effects'

import { datadogLogs } from '@datadog/browser-logs'
import watchGetActivatedCarpoolers from './activatedCarpoolers'
import watchCarpoolerDeactivationsDelete from './carpoolerDeactivationsDelete'
import watchCarpoolerDeactivationsGet from './carpoolerDeactivationsGet'
import { watchCreateUserOkta, watchCreateUserTokenPassword } from './createUser'
import watchDeactivateCarpooler from './deactivateCarpooler'
import watchDownloadActivatedCarpoolersCsv from './downloadActivatedCarpoolersCsv'
import watchDownloadDeactivatedCarpoolersCsv from './downloadDeactivatedCarpoolersCsv'
import watchFactorActivate from './factorActivate'
import watchFactorEnroll from './factorEnroll'
import watchFactorResend from './factorResend'
import watchFactorVerify from './factorVerify'
import watchGetSpaces from './getSpaces/index.ts'
import watchRegistrationToken from './registrationToken'
import watchCheckSessionTransaction from './sessionTransactionCheck'
import watchCreateSessionTransactionEmailPassword from './sessionTransactionEmailPassword'
import watchCreateSessionTransactionOkta from './sessionTransactionOkta'
import watchSignOut from './signOut'
import watchUploadBulk from './uploadBulk'
import { CosmosApiError } from '../CosmosApi/Errors'

export default function* rootSaga() {
    const sagas = [
        watchCheckSessionTransaction,
        watchCreateSessionTransactionOkta,
        watchCreateSessionTransactionEmailPassword,
        watchRegistrationToken,
        watchCreateUserOkta,
        watchCreateUserTokenPassword,
        watchFactorEnroll,
        watchFactorActivate,
        watchFactorResend,
        watchFactorVerify,
        watchDeactivateCarpooler,
        watchGetActivatedCarpoolers,
        watchGetSpaces,
        watchDownloadActivatedCarpoolersCsv,
        watchDownloadDeactivatedCarpoolersCsv,
        watchUploadBulk,
        watchCarpoolerDeactivationsDelete,
        watchCarpoolerDeactivationsGet,
        watchSignOut,
    ]

    // Run all sagas with spawned generators. This approach catches unexpected errors gracefully.
    // Reference: https://redux-saga.js.org/docs/advanced/RootSaga.html#keeping-everything-alive
    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga)
                        break
                    } catch (unexpectedError) {
                        datadogLogs.logger.error(
                            'Unexpected error',
                            unexpectedError
                        )
                        // If the error is anything other than expected API errors, throw it and terminate the app
                        if (!(unexpectedError instanceof CosmosApiError)) {
                            throw unexpectedError
                        }
                    }
                }
            })
        )
    )
}
