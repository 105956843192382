import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Container } from 'reactstrap'

import srcError from '../../images/error.svg'

const DataNotReady = ({ spaceAlias, companyName }) => (
    <Container size="xl">
        <h2 className="my-3">Active commuters</h2>
        <Row className="justify-content-center pt-5">
            <Col md="5">
                <img className="mx-auto d-block mt-5" src={srcError} />
                <h4 className="text-center mb-4">Data not ready yet.</h4>
                <p>
                    The space {companyName} is now created, but the data may
                    take up to 12 hours to populate.
                </p>
            </Col>
        </Row>
    </Container>
)

DataNotReady.propTypes = {
    spaceAlias: PropTypes.string,
    companyName: PropTypes.string,
}

export default DataNotReady
