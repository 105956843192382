// DEPRECATED: Consider using <AgGridWrapper /> instead.

import React, { ReactNode, CSSProperties } from 'react'
import { Table as TableStyled } from 'reactstrap'
import _ from 'lodash'

import { LoadingBar } from '../LoadingBar'
import useDebounce from '../../hooks/useDebounce'

export type ColumnHeader = [header: JSX.Element, style?: CSSProperties]

export type TransformRows = ReactNode[][]

/** Row can be any object because it's consumed by transformRows() */
type Row = Record<string, unknown>

type Props = {
    'data-test'?: string
    columnHeaders?: ColumnHeader[]
    rows?: Row[]
    className?: string
    isLoading?: boolean
    transformRows: (rows: Row[]) => TransformRows
    placeHolderRowAmount?: number
    debounceTimeout?: number
    fallbackWhenEmpty?: JSX.Element
    loaderHeight?: number
    isFixed?: boolean
}

/**
 * @deprecated consider using <AgGridWrapper /> instead.
 *
 * @param columnHeaders - column headers. Each entry in the
 * array is a pair of 1) component to render and 2) sortableOptions. If
 * `sortableOptions` is given, that header will be sortable.
 *
 * @param rows - table rows. Each entry will be rendered, in
 * order from left-to-right, as <tr> elements. Each entry should be a React
 * component that can be rendered into the DOM.
 *
 * @param transformRows - A formatting function to apply to the row data
 *
 * @param placeHolderRowAmount - The number of placeHolder rows to render
 */
const Table = ({
    'data-test': dataTest,
    columnHeaders = [],
    rows = [],
    transformRows,
    isLoading = true,
    className = '',
    placeHolderRowAmount = 10,
    debounceTimeout = 1000,
    fallbackWhenEmpty,
    loaderHeight,
    isFixed = true,
}: Props): JSX.Element => {
    const isDebouncedLoading: boolean = useDebounce(isLoading, debounceTimeout)

    const placeHolderRows = _.times(placeHolderRowAmount, () =>
        columnHeaders.map((x) => (
            <LoadingBar data-test="loading-bar" key={x} height={loaderHeight} />
        ))
    )

    const data = isDebouncedLoading ? placeHolderRows : transformRows(rows)
    const styles: CSSProperties = isFixed ? { tableLayout: 'fixed' } : {}

    return (
        <>
            <TableStyled
                data-test={dataTest}
                responsive
                bordered
                size="sm"
                className={className}
                style={styles}
            >
                <thead>
                    <tr>
                        {columnHeaders.map(([header, style = {}], index) => (
                            <th
                                className={`text-nowrap align-middle px-4 ${className}`}
                                style={style}
                                key={index.toString()}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((rowComponents = [], index) => {
                        return (
                            <tr key={index}>
                                {rowComponents.map((component, index) => {
                                    // The last column should not be truncated
                                    const lastIndex = rowComponents.length - 1
                                    const overFlow =
                                        index === lastIndex
                                            ? ''
                                            : 'text-truncate overflow-hidden'
                                    return (
                                        <td
                                            className={`${overFlow} align-middle px-4`}
                                            key={index.toString()}
                                        >
                                            {component}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </TableStyled>

            {!isDebouncedLoading && !data.length && fallbackWhenEmpty}
        </>
    )
}

export default Table
