import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export const configureDatadogRUM = (
    applicationId: string,
    clientToken: string,
    environment: 'staging' | 'production'
): void => {
    datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.com',
        service: `nebula-${environment}`,
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
}

export const configureDatadogLogs = (
    clientToken: string,
    environment: 'staging' | 'production'
): void => {
    datadogLogs.init({
        clientToken,
        service: 'nebula',
        env: environment,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
    })

    const context = datadogLogs.getLoggerGlobalContext()

    datadogLogs.setLoggerGlobalContext({
        ...context,
        // Service category needed to match index pattern so that log is persisted in Datadog
        service_category: 'scoop',
    })
}
