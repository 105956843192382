import React from 'react'
import {
    ClearButton,
    SearchButton,
    SearchInput,
    SearchBarContainer,
    SearchInputGroup,
    ButtonInputGroup,
    GroupItem,
} from './styled'
import SearchIconSpinner from './SearchIconSpinner'
import useSearch from './useSearch'

type Props = {
    onSearch?: (searchValue: string) => void
    onClear?: () => void
    initialValue?: string
    placeholder?: string
    isSearching?: boolean
    label?: string
    onSearchClick?: () => void
}

export const SearchBar = ({
    onSearch,
    onClear,
    placeholder = 'Search by email',
    initialValue = '',
    isSearching,
    label = 'Search',
    onSearchClick,
}: Props): JSX.Element => {
    const {
        hasValue,
        searchValue,
        isFocused,
        updateFocus,
        updateSearchValue,
    } = useSearch(initialValue)

    const handleSubmit = (e) => {
        e.preventDefault()
        const trimmedSearchValue = searchValue.trim()
        onSearch?.(trimmedSearchValue)
    }
    const handleChange = (e) => {
        updateSearchValue(e.target.value)
    }
    const handleClear = () => {
        updateSearchValue('')
        onClear?.()
    }
    const handleFocus = () => {
        updateFocus(true)
    }
    const handleBlur = () => {
        updateFocus(false)
    }

    return (
        <SearchBarContainer data-test="search-bar" onSubmit={handleSubmit}>
            <SearchInputGroup isFocused={isFocused}>
                <GroupItem className="border-right-0">
                    <SearchIconSpinner isLoading={isSearching} />
                </GroupItem>
                <SearchInput
                    value={searchValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {hasValue && (
                    <GroupItem className="border-left-0">
                        <ClearButton
                            data-test="clear-search-bar-button"
                            onClick={handleClear}
                        />
                    </GroupItem>
                )}
            </SearchInputGroup>
            <ButtonInputGroup>
                <SearchButton onClick={onSearchClick}>{label}</SearchButton>
            </ButtonInputGroup>
        </SearchBarContainer>
    )
}

export default SearchBar
