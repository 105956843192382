import { useCallback, useState } from 'react'

/**
 * Tries to parse a JSON.stringfiable value back to javascript. If it can't,
 * then the value is a string.
 *
 * @param {*} value
 */
function tryParse(value) {
    try {
        return JSON.parse(value)
    } catch {
        return value
    }
}

/**
 *
 * Observes and returns the localStorage value, as well as setter and deleter methods
 *
 * @param {Any} value - value to be serialized and persisted to localStorage
 *
 */

const useLocalStorage = (item) => {
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])

    function write(value) {
        const valueToSet =
            typeof value === 'object' ? JSON.stringify(value) : value

        localStorage.setItem(item, valueToSet)
        forceUpdate() // Since React cannot hook into localStorage changes, we need to manually indicate the state has changed
    }

    function remove() {
        localStorage.removeItem(item)
    }

    return {
        get value() {
            return tryParse(localStorage.getItem(item))
        },
        write,
        remove,
    }
}

export default useLocalStorage
