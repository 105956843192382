import { useCallback } from 'react'
import { useNavigate } from '@reach/router'

import useLogger from 'hooks/useLogger'
import useBuildingResource from '../../../../hooks/useBuildingResource'
import useEmployeeExperienceResource from '../../../../hooks/useEmployeeExperienceResource'
import useAsync from '../../../../hooks/useAsync'
import useSpaces from '../../../../hooks/useSpaces'
import { GetBuildingCapacityConfiguration } from '../../../../hooks/useBuildingDetails'
import toast from '../../../../components/Toast/toast'
import { CosmosError, ResponseError, Building } from '../../../../types'

type ErrorProps = {
    name: string
    message: string
    responseError?: CosmosError
}

class EditBuildingError extends Error {
    responseError?: CosmosError

    constructor(
        { name, message, responseError }: ErrorProps,
        ...args: string[]
    ) {
        super(...args)

        this.name = name
        this.message = message
        this.responseError = responseError
    }
}

type Props = {
    building?: Building
    getBuildingCapacityConfiguration: GetBuildingCapacityConfiguration
}

type UseEditBuilding = {
    submitForm: ({
        address,
        name,
        maxCapacity,
        buildingPerimeterPolygon,
    }: {
        address?: string
        name?: string
        maxCapacity?: number | null
        buildingPerimeterPolygon?: number[][] | number[][][]
    }) => void

    isFormSubmitting?: boolean

    tryUpdatingExperienceCapacity: ({
        experienceId,
        capacity,
    }: {
        experienceId: string
        capacity: number | null
    }) => Promise<unknown>
}

/**
 * Similar to useAddBuilding except this is for modifying a building.
 *
 * This hook exports a form submit handler and form submitting state.
 * Note that submitForm can return an object which will be
 * used by Final Form as a submit-error
 *
 */
const useEditBuilding = ({
    building,
    getBuildingCapacityConfiguration,
}: Props): UseEditBuilding => {
    const logger = useLogger()
    const navigate = useNavigate()
    const { id: spaceId, alias: spaceAlias } = useSpaces()
    const { isLoading: isFormSubmitting, exec } = useAsync()

    const {
        patchBuilding,
        response: patchBuildingResponse,
    } = useBuildingResource({ spaceId })

    const { updateExperienceCapacity } = useEmployeeExperienceResource({
        spaceId,
        type: 'checkInCapacity',
    })

    /**
     * Make a request to update max capacity
     * and throw a custom error if needed.
     *
     * @param props.experienceId
     * @param props.capacity
     *
     * @returns {Object} response data
     *
     */
    const tryUpdatingExperienceCapacity = useCallback(
        async ({
            experienceId,
            capacity,
        }: {
            experienceId?: string
            capacity: number | null
        }) => {
            const responseData = await updateExperienceCapacity({
                buildingId: building?.id,
                experienceId,
                capacity,
            })

            const responseError = (responseData as ResponseError).error

            if (!responseError) {
                return responseData
            }

            if (responseError.errorId === 'MORE_DESKS_THAN_CAPACITY') {
                throw new EditBuildingError({
                    responseError,
                    name: 'MoreDesksThanCapacity',
                    message:
                        'The daily check-in limit cannot be lower than the number of desks in the building.',
                })
            }

            throw new EditBuildingError({
                responseError,
                name: 'CapacityEnforcementError',
                message: `We could not enforce the capacity for building ${building?.id}`,
            })
        },
        [updateExperienceCapacity, building]
    )

    /**
     * Make a patch request to update a building.
     *
     * @param {string} [props.name]
     * @param {Array<Array<Number>>} [props.coordinates]
     *
     */
    const tryPatchingBuilding = useCallback(
        async ({ address, name, coordinates }) => {
            const responseData = await patchBuilding({
                buildingId: building?.id,
                name,
                address,
                ...(coordinates && {
                    geometry: {
                        type: 'Polygon',
                        coordinates,
                    },
                }),
            })

            if (patchBuildingResponse.ok) {
                return responseData
            }

            const responseError = (responseData as ResponseError)?.error

            if (responseError?.errorId === 'BUILDING_NAME_CONFLICT') {
                throw new EditBuildingError({
                    responseError,
                    name: 'BuildingNameConflictError',
                    message:
                        'The building name already exists. Please enter a unique name.',
                })
            }

            if (responseError?.errorId === 'BUILDING_GEOMETRY_OVERLAP') {
                throw new EditBuildingError({
                    responseError,
                    name: 'BuildingGeometryOverlapError',
                    message:
                        'This building’s footprint overlaps with another saved building. Please update your building and try again.',
                })
            }

            throw new EditBuildingError({
                responseError,
                name: 'BuildingCreationError',
                message:
                    'We could not save your information. Please try again.',
            })
        },
        [building, patchBuildingResponse, patchBuilding]
    )

    /**
     * Make requests to update capacity and building.
     * Note: Creating experience capacity must be called before patching the building.
     *
     * @param {string} [name]
     * @param {number | null} [capacity] - If a number is passed, capacity is updated.
     *                                      If null is passed, capacity is disabled.
     * @param {Array<Array<Number>>} [buildingPerimeterPolygon]
     * @returns { error }
     */
    const updateCapacityAndBuilding = useCallback(
        async ({ address, name, capacity, buildingPerimeterPolygon }) => {
            try {
                if (name || buildingPerimeterPolygon) {
                    await tryPatchingBuilding({
                        name,
                        address,
                        coordinates: buildingPerimeterPolygon,
                    })
                }

                if (capacity || capacity === null) {
                    const experienceId = getBuildingCapacityConfiguration(
                        building?.id || ''
                    )?.id

                    await tryUpdatingExperienceCapacity({
                        experienceId,
                        capacity,
                    })
                }
            } catch (error) {
                return {
                    error,
                }
            }

            return { error: null }
        },
        [
            building,
            getBuildingCapacityConfiguration,
            tryPatchingBuilding,
            tryUpdatingExperienceCapacity,
        ]
    )

    /**
     * Submit a form to modify the building.
     * Props are expected to be returned from final-form.
     *
     * @param {string} [props.name] - building name.
     * @param {number | null} [props.maxCapacity] - max capacity. If null is passed, maxCapacity will be disabled.
     * @param {Array<Array<number>>} [props.buildingPerimeterPolygon] - buildingPerimeterPolygon.
     *
     * @returns {Error} returns an error, compatible for final-form,
     *                  if there's a conflict in the building name.
     */
    const submitForm = useCallback(
        async ({
            address,
            name,
            maxCapacity,
            buildingPerimeterPolygon,
        }: {
            address?: string
            name?: string
            maxCapacity?: number | null

            buildingPerimeterPolygon?: number[][] | number[][][]
        }) => {
            const { error } = await exec(
                updateCapacityAndBuilding({
                    buildingId: building?.id,
                    name,
                    buildingPerimeterPolygon,
                    address,
                    capacity: maxCapacity,
                })
            )

            if (!error) {
                await navigate(
                    `/spaces/${spaceAlias}/settings/buildings/${building?.id}`
                )
                return
            }

            if (error.name === 'BuildingNameConflictError') {
                // the submit handler can return a field-level submission error
                // that is consumed by the Form and Fields
                // https://final-form.org/docs/react-final-form/examples/submission-errors
                return {
                    name: error.message,
                }
            }

            if (error.name === 'MoreDesksThanCapacity') {
                return {
                    maxCapacity: error.message,
                }
            }

            toast.error(
                error.name === 'CapacityEnforcementError'
                    ? 'The Daily check-in limit could not be applied.'
                    : error.message
            )

            logger.error(error.message, { error, building })
        },
        [
            building,
            spaceAlias,
            exec,
            navigate,
            updateCapacityAndBuilding,
            logger,
        ]
    )

    return {
        submitForm,
        isFormSubmitting,
        tryUpdatingExperienceCapacity,
    }
}

export { EditBuildingError }
export default useEditBuilding
