import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Form,
} from 'reactstrap'

export const SearchBarContainer = styled(({ children, ...rest }) => (
    <Form {...rest}>
        <InputGroup>{children}</InputGroup>
    </Form>
))`
    width: 100%;
`

export const GroupItem = styled(InputGroupText).attrs({
    className: `bg-transparent`,
})``

export const SearchInputGroup = styled(({ isFocused, ...rest }) => (
    <InputGroupAddon {...rest} />
)).attrs(({ isFocused }) => {
    const className = classNames('bg-white', {
        'sd-input-group-focused': isFocused,
    })
    return {
        className,
        addonType: 'prepend',
    }
})`
    flex: auto;
`

export const SearchInput = styled(Input).attrs({
    className: 'border-input-border border-left-0 border-right-0',
    type: 'search',
    color: 'secondary',
})`
    border-radius: 0;

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }

    &:focus {
        box-shadow: none;
    }
`

export const ClearButton = styled(Button).attrs({
    close: true,
})``

export const SearchButton = styled(Button).attrs({
    type: 'submit',
    color: 'sd-secondary',
})``

export const ButtonInputGroup = styled(InputGroupAddon).attrs((props) => ({
    addonType: 'append',
}))``
