import { clone, setWith, curry } from 'lodash/fp'

/**
 * Helper function to make immutable and deeply nested updates to an object by returning a new object.
 * Note: by using "curry" from lodash/fp, arguments are passed to "setWith" in reverse order.
 * See https://github.com/lodash/lodash/wiki/FP-Guide
 *
 * @param {String | String[]} path - the path to the value to update
 * @param {*} value - the new value which can be anything
 * @param {Object} object - the object being updated
 *
 * @example
 *
 * const objectToUpdate = {
 *  a: {b: 'hello'}
 * }
 *
 * const updated = immutableObjectUpdate({
 *  path: ['a', 'b'],
 *  value: 'world',
 *  object: objectToUpdate
 * })
 *
 * updated -> {
 *  a: {b: 'world'}
 * }
 */

const immutableObjectUpdate = curry(({ path, value, object }) =>
    setWith(clone, path, value, clone(object))
)

export default immutableObjectUpdate
