import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import { Space } from '../../providers/SpacesProvider'
import {
    createSpacesAdd,
    createChangeCurrentSpace,
    createGetSpacesFailure,
    createGetSpacesSuccess,
    GET_SPACES_REQUEST,
} from '../../state/spaces'

const getSpacesUri = (alias: string): string => {
    const params = alias ? `?alias=${alias}` : ''

    return `/spaces${params}`
}

export function* getSpaces({ payload, meta }) {
    const cosmosApi = yield getContext('cosmosApi')
    const analytics = yield getContext('analytics')
    try {
        const { alias } = payload
        const { parsedBody: spaces }: { parsedBody: Space[] } = yield call(
            [cosmosApi, 'call'],
            getSpacesUri(alias),
            {
                method: 'GET',
            }
        )

        // Empty array can be returned if no space is associated with the given alias
        if (!spaces.length) {
            return yield put(
                createGetSpacesFailure({
                    error: new Error(
                        'You do not have access to view this space.'
                    ),
                })
            )
        }

        yield put(createSpacesAdd(spaces))

        // Use the first returned space as the current space
        const [currentSpace] = spaces
        yield put(createChangeCurrentSpace(currentSpace))
        yield put(createGetSpacesSuccess())

        spaces.forEach((space) => {
            analytics.group(space.id, {
                name: space.name,
                publicDisplayName: space.publicDisplayName,
                alias: space.alias,
            })
        })

        // Identify the first customer as current customer (most only have one)
        analytics.identifySpace(currentSpace.id)
    } catch (error) {
        // Possible errors: 401 when not authenticated, 403 when not authorized.
        return yield put(createGetSpacesFailure({ error }))
    }
}

export default function* watchGetSpaces() {
    yield takeLatest(GET_SPACES_REQUEST, getSpaces)
}
