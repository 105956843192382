import { call, getContext, takeLatest, put } from 'redux-saga/effects'
import { CosmosApiError } from '../../CosmosApi/Errors'
import {
    USER_CREATE_OKTA_REQUEST,
    USER_CREATE_TOKEN_PASSWORD_REQUEST,
    createUserAdd,
    createUserCreateSuccess,
    createUserCreateFailure,
} from '../../state/user'
import toast from '../../components/Toast/toast.tsx'

const createUri = '/users'

const createTokenPasswordBody = ({ password, token }) => ({
    authenticationStrategy: {
        type: 'emailAndPassword',
        password,
    },
    userCreationToken: token,
})

const createOktaBody = () => ({
    authenticationStrategy: {
        type: 'okta',
    },
})

export function* createUser({ body }) {
    const cosmosApi = yield getContext('cosmosApi')
    try {
        const { parsedBody } = yield call([cosmosApi, 'call'], createUri, {
            method: 'POST',
            body,
        })

        const userId = parsedBody.id
        yield put(createUserAdd({ ...parsedBody, userId }))
        yield put(createUserCreateSuccess({ userId }))
        return parsedBody
    } catch (err) {
        toast.error(`We couldn't save your password. Please try again.`)
        yield put(createUserCreateFailure({ error: err }))
        return err
    }
}

export function* createUserTokenPassword({ payload }) {
    yield call(createUser, { body: createTokenPasswordBody(payload) })
}

export function* createUserOkta() {
    const response = yield call(createUser, { body: createOktaBody() })
    if (response instanceof CosmosApiError) {
        throw response // In this case, 'response' is really an error
    }
}

export function* watchCreateUserTokenPassword() {
    yield takeLatest(
        USER_CREATE_TOKEN_PASSWORD_REQUEST,
        createUserTokenPassword
    )
}

export function* watchCreateUserOkta() {
    yield takeLatest(USER_CREATE_OKTA_REQUEST, createUserOkta)
}
