import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import {
    defaultVaccinationPolicies,
} from '..'
import ModuleFlag from '../../../components/ModuleFlag/index'
import PolicyError from '../../../components/Policy/PolicyError'
import { ResponseError } from '../../../types'
import PolicyFeature from '../../../components/PolicyFeature'
import { CovidFeatureTypes, CovidVaccinationPolicies } from '../../../hooks/resources/useVaccinationPoliciesResource'

const ListItem = styled.li`
    list-style-type: none;
`

export type Covid19PoliciesProps = {
    error?: Error | ResponseError
    covid19VaccinationPolicies?: CovidVaccinationPolicies
    isLoading: boolean
    isLoadingPolicyChange: boolean
    onChange: (featureName: CovidFeatureTypes, checked: boolean) => void
}

const Covid19Policies = ({
    error,
    covid19VaccinationPolicies,
    isLoading,
    isLoadingPolicyChange,
    onChange,
}: Covid19PoliciesProps): JSX.Element => {
    return (
        <ModuleFlag name="vaccinationPolicies">
            <ListItem>
                <Row data-test="covid19-policy" className="mb-3">
                    <Col>
                        <Row className="d-flex bg-light">
                            <Col className="px-3 py-3 border-top border-left border-right rounded-top">
                                <h5>COVID-19 vaccination policy</h5>
                                <p className="text-muted mb-0">
                                    Require employees to indicate if they have
                                    been vaccinated and require them to be
                                    vaccinated to go into the workplace.
                                </p>
                            </Col>
                        </Row>
                        {error ? (
                            <PolicyError />
                        ) : (
                            <>
                                <PolicyFeature
                                    key="requireCollection"
                                    name="requireCollection"
                                    description="Collect vaccination status from employees"
                                    detail={`We'll ask employees once for their vaccination status when they set their status to "Going into work".`}
                                    isLoading={isLoading}
                                    isLoadingPolicyChange={
                                        isLoadingPolicyChange
                                    }
                                    isEnabled={
                                        covid19VaccinationPolicies?.requireCollection ||
                                        defaultVaccinationPolicies.requireCollection
                                    }
                                    onChange={(checked) =>
                                        onChange('requireCollection', checked)
                                    }
                                    indentLevel={0}
                                />
                                <ModuleFlag name="vaccinationPolicies.collectVaccinationProofPolicy">
                                    <PolicyFeature
                                        key="collectVaccinationProof"
                                        name="collectVaccinationProof"
                                        description="Require documentation of vaccination for employees to be fully vaccinated"
                                        detail={`We’ll prompt employees to upload a photo of their vaccination card for admin approval.`}
                                        isLoading={isLoading}
                                        isLoadingPolicyChange={
                                            isLoadingPolicyChange
                                        }
                                        isEnabled={
                                            covid19VaccinationPolicies?.collectVaccinationProof ||
                                            defaultVaccinationPolicies.collectVaccinationProof
                                        }
                                        onChange={(checked) =>
                                            onChange(
                                                'collectVaccinationProof',
                                                checked
                                            )
                                        }
                                        indentLevel={1}
                                    />
                                </ModuleFlag>
                                <ModuleFlag
                                    modules={{
                                        names: [
                                            'vaccinationPolicies.requireVaccinationPolicy',
                                            'diseaseTestResults',
                                        ],
                                        comparator: 'some',
                                    }}
                                >
                                    <Row className="bg-light border-bottom border-left border-right pl-4 py-3">
                                        <h6 className="m-0">
                                            Set requirements for going into the
                                            workplace
                                        </h6>
                                    </Row>
                                </ModuleFlag>
                                <ModuleFlag name="vaccinationPolicies.requireVaccinationPolicy">
                                    <PolicyFeature
                                        key="requireVaccination"
                                        name="requireVaccination"
                                        description="Only vaccinated employees can go into the workplace"
                                        detail={`We’ll block employees who are not fully vaccinated from setting their status to “Going into work”.`}
                                        isLoading={isLoading}
                                        isLoadingPolicyChange={
                                            isLoadingPolicyChange
                                        }
                                        isEnabled={
                                            covid19VaccinationPolicies?.requireVaccination ||
                                            defaultVaccinationPolicies.requireVaccination
                                        }
                                        onChange={(checked) =>
                                            onChange(
                                                'requireVaccination',
                                                checked
                                            )
                                        }
                                        indentLevel={1}
                                    />
                                </ModuleFlag>

                                <ModuleFlag name="diseaseTestResults">
                                    <PolicyFeature
                                        key="requireTestForUnvaccinated"
                                        name="requireTestForUnvaccinated"
                                        description="Unvaccinated employees can go into the workplace with a weekly negative test"
                                        detail="We’ll allow employees who are not fully vaccinated to set status as “Going into work” if they upload negative COVID-19 test results from the past 7 days."
                                        isLoading={isLoading}
                                        isLoadingPolicyChange={
                                            isLoadingPolicyChange
                                        }
                                        isEnabled={
                                            covid19VaccinationPolicies?.requireTestForUnvaccinated ||
                                            defaultVaccinationPolicies.requireTestForUnvaccinated
                                        }
                                        onChange={(checked) =>
                                            onChange(
                                                'requireTestForUnvaccinated',
                                                checked
                                            )
                                        }
                                        indentLevel={1}
                                    />
                                </ModuleFlag>
                            </>
                        )}
                    </Col>
                </Row>
            </ListItem>
        </ModuleFlag>
    )
}

export default Covid19Policies
