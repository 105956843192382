import React from 'react'

// Use search hook ensures that initial value updates state when it changes.
//   also passes back update and hasValue flag
export const useSearch = (initialValue = '') => {
    const [searchValue, updateSearchValue] = React.useState(initialValue)
    const [isFocused, updateFocus] = React.useState(false)

    React.useEffect(() => {
        // Update state only when initial value changes
        updateSearchValue(initialValue)
    }, [initialValue])
    return {
        hasValue: Boolean(searchValue),
        isFocused,
        searchValue,
        updateFocus,
        updateSearchValue,
    }
}

export default useSearch
