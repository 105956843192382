import React, { useMemo } from 'react'
import { useFetch } from 'use-http'

import useSpaces from '../hooks/useSpaces'
import { RosterRecord } from '../types'
import { sortOrderOptions } from '../util/sort/index'
import useServerPaginationWithQuery, {
    UseServerPaginationWithQuery,
} from '../hooks/useServerPagination/useServerPaginationWithQuery/index'

export type EmployeeRosterContextType = {
    hasErrors: boolean
    hasRecords: boolean
    isIdle: boolean
    spaceAlias?: string
    getNotYetInvitedData: () => Promise<RosterRecord[]>
    notYetInvitedCount: number
    getPendingData: () => Promise<RosterRecord[]>
    pendingCount: number
    datatable: UseServerPaginationWithQuery<RosterRecord>
}

export const pageSize = 50

export const defaultContext: EmployeeRosterContextType = {
    hasErrors: false,
    hasRecords: false,
    isIdle: true,
    spaceAlias: undefined,
    getNotYetInvitedData: () =>
        Promise.reject(new Error('Not yet implemented')),
    notYetInvitedCount: 0,

    getPendingData: () => Promise.reject(new Error('Not yet implemented')),
    pendingCount: 0,
    datatable: {
        handleSearch: () => Promise.reject(new Error('Not yet implemented')),
        handleSort: () => Promise.reject(new Error('Not yet implemented')),
        handleFilter: () => Promise.reject(new Error('Not yet implemented')),
        handleDate: () => Promise.reject(new Error('Not yet implemented')),
        getData: () => Promise.reject(new Error('Not yet implemented')),
        isLoading: false,
        paginatedData: [],
        totalCount: 0,
        pagination: {
            changePage: () => Promise.reject(new Error('Not yet implemented')),
            currentPage: 1,
            fromResult: 0,
            last: 1,
            toResult: 0,
            pagesToShow: [],
            showNext: false,
            showPrevious: false,
        },
        options: {
            sort: {
                sortBy: 'firstName',
                sortOrder: sortOrderOptions.ascending,
            },
            filter: {},
        },
    },
}

export const EmployeeRosterContext = React.createContext<EmployeeRosterContextType>(
    defaultContext
)

export const _useEmployeeRosterContextProvider = (): EmployeeRosterContextType => {
    const { id: spaceId, alias: spaceAlias } = useSpaces()
    const { error, loading: isLoading, data: rosterFirstRecord } = useFetch<
        RosterRecord[]
    >(`/spaces/${spaceId}/rosterRecords?limit=1`, [])

    const {
        get: getNotYetInvitedData,
        response: notYetInvitedResponse,
    } = useFetch<RosterRecord[]>(
        `/spaces/${spaceId}/rosterRecords?invitationStatus=notYetInvited`,
        []
    )

    const { get: getPendingData, response: pendingResponse } = useFetch<
        RosterRecord[]
    >(`/spaces/${spaceId}/rosterRecords?invitationStatus=invited`, [])

    const serverPaginationOptions = useMemo(
        () => ({
            path: `/spaces/${spaceId}/rosterRecords`,
            pageSize,
            defaultSort: {
                sortBy: 'firstName',
                sortOrder: sortOrderOptions.ascending,
            },
        }),
        [spaceId]
    )

    const datatableProperties = useServerPaginationWithQuery<RosterRecord>(
        serverPaginationOptions,
        []
    )

    const hasErrors = Boolean(error)
    const isIdle = !error && isLoading
    const hasRecords =
        rosterFirstRecord !== undefined && rosterFirstRecord.length > 0

    return {
        ...defaultContext,
        hasRecords,
        isIdle,
        hasErrors,
        spaceAlias,

        getNotYetInvitedData,
        notYetInvitedCount: Number(
            notYetInvitedResponse.headers?.get('x-total-count')
        ),

        getPendingData,
        pendingCount: Number(pendingResponse.headers?.get('x-total-count')),

        datatable: datatableProperties,
    }
}

const EmployeeRosterProvider = ({ children }): JSX.Element => {
    const value = _useEmployeeRosterContextProvider()

    return (
        <EmployeeRosterContext.Provider value={value}>
            {children}
        </EmployeeRosterContext.Provider>
    )
}

export default EmployeeRosterProvider
