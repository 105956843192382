import { useReducer, useEffect } from 'react'

type FeatureFlags = {
    [featureFlag: string]: boolean
}

const eventName = 'featureFlagUpdate'

export const enableFeatureFlag = (featureFlag: string): void => {
    localStorage.setItem(featureFlag, 'true')
    document.dispatchEvent(new CustomEvent(eventName))
}

export const disableFeatureFlag = (featureFlag: string): void => {
    localStorage.removeItem(featureFlag)
    document.dispatchEvent(new CustomEvent(eventName))
}

/**
 * This hook returns feature flags and whether they are enabled or not.
 * These feature flags are mainly used for hiding unreleased features before launching.
 *
 *
 * **How to define feature flags**
 *
 * ```js
 * return {
 *   // Enabled
 *   releasedFeature: true
 *
 *   // Enabled only if it is manually turned on.
 *   notYetReleasedFeature: localStorage.getItem('not-yet-released-feature') === 'true'
 * }
 * ```
 *
 * **How to use feature flags**
 *
 * ***Option 1: Use `useFeatureFlags()`***
 *
 * ```js
 * // How to use the feature flag with this hook
 * const { coolFeature } = useFeatureFlags()
 *
 * if (coolFeature) {
 *   // show feature
 * }
 * ```
 *
 * ***Option 2: Use `<FeatureFlag />`***
 *
 * ```jsx
 * <FeatureFlag name="coolFeature">
 *   <CoolFeature />
 * </FeatureFlag>
 * ```
 *
 * **Feature Flags & Launching process**
 *
 * 1. For an unreleased feature, check whether the feature flag is enabled from localStorage.
 * 2. In order to launch a feature, set its feature flag to `true`.
 * 3. If a launched feature needs to be reverted, set its feature flag to `false`.
 * 4. Once a feature is launched, its feature flag is expected to be removed after a certain period (i.e. 1 month), confirming the feature is stable.
 *
 * @returns keys are feature flags and values are true/false.
 */
function useFeatureFlags(): FeatureFlags {
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    // Force render components when a feature flag is updated.
    // This is inevitable because React doesn't check localStorage change for re-rendering components.
    useEffect(() => {
        document.addEventListener(eventName, forceUpdate)
        return () => document.removeEventListener(eventName, forceUpdate)
    }, [])

    const featureFlags = {}

    return featureFlags
}

export default useFeatureFlags
