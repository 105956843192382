import React from 'react'
import PropTypes from 'prop-types'

import ChartDisplay from './ChartDisplay'
import { checkInTrendsData } from '../../../models/checkInTrends'
import CouldNotLoadData from '../../Error/CouldNotLoadData'
import GhostLoader from '../../GhostLoader'

import withMetricOrChartData from '../../../hocs/withMetricOrChartData'
import withVisibilitySensor from '../hoc/withVisibilitySensor'

export const BarChart = ({
    isLoading,
    data,
    error,
    refetchData,
    granularity,

    onChartClick,
    onChartHover,
    onTryAgain,

    ...otherProps
}) => {
    const tryAgain = () => {
        onTryAgain()
        refetchData()
    }

    if (error) {
        return <CouldNotLoadData onTryAgainClick={tryAgain} />
    }

    if (isLoading || !data) {
        return <GhostLoader height={590} />
    }

    return (
        <div
            className="bg-white border"
            onClick={() => onChartClick({ isRightClick: false })}
            onContextMenu={() => onChartClick({ isRightClick: true })}
        >
            <ChartDisplay
                data={data.statistics}
                rangeSize="month"
                granularity={granularity}
                onChartHover={onChartHover}
                {...otherProps}
            />
        </div>
    )
}

BarChart.propTypes = {
    // withMetricOrChartData props
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.shape(checkInTrendsData),
    error: PropTypes.object,
    refetchData: PropTypes.func.isRequired,
    granularity: PropTypes.oneOf(['daily', 'weekly']).isRequired,

    onChartClick: PropTypes.func.isRequired,
    onChartHover: PropTypes.func.isRequired,
    onTryAgain: PropTypes.func.isRequired,
}

export default withMetricOrChartData(withVisibilitySensor(BarChart))
