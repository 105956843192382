import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Col, Container, Row } from 'reactstrap'
import { format } from 'date-fns'

import DownloadCsvButton from './DownloadCsvButton'
import VaccinationStatusColumnRenderer from './VaccinationStatusColumnRenderer'
import ActionsButtonColumnRenderer, {
    reviewStatusButtonTextMap,
} from './ActionsButtonColumnRenderer/index'
import ReviewProofsInBulkButton from './ReviewProofsInBulkButton/index'
import AgGridWrapper from '../../components/AgGridWrapper'
import Header from '../../components/PageLayout/Header'
import useSpaces from '../../hooks/useSpaces'
import useVaccinationLogResource from '../../hooks/resources/useVaccinationLogResource'
import { getVaccinationStatusProps } from '../../components/VaccinationStatusBadge/index'
import {
    VaccinationLogRecordResource,
    VaccinationReviewStatus,
} from '../../types'

function getAllVaccinationRecordsToReview(
    vaccinationLogs: VaccinationLogRecordResource[]
): VaccinationLogRecordResource[] {
    return vaccinationLogs.filter(
        (vaccinationLogRecord) =>
            vaccinationLogRecord.proof?.reviewStatus ===
            VaccinationReviewStatus.pending
    )
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const VaccinationLog = (props: RouteComponentProps): JSX.Element => {
    const { id: spaceId, modules } = useSpaces()
    const { vaccinationLogs, isLoading, get } = useVaccinationLogResource(
        spaceId,
        []
    )
    const isCollectVaccinationProofEnabled = Boolean(
        modules?.vaccinationPolicies?.collectVaccinationProofPolicy?.isEnabled
    )

    // Add the action column based on the module
    const columnDefs = useMemo(() => {
        return [
            {
                colId: 'name',
                headerName: 'Name',
                valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
            },
            {
                colId: 'vaccinationStatusBadge',
                headerName: 'Vaccinated',
                cellRenderer: 'vaccinationStatusColumnRenderer',
                valueGetter: ({ data }) =>
                    getVaccinationStatusProps(
                        data.status,
                        data.proof?.reviewStatus
                    ).text,
            },
            {
                headerName: 'Actions',
                colId: 'actionsButton',
                cellRenderer: 'actionsButtonColumnRenderer',
                cellRendererParams: {
                    refreshData: get,
                },
                valueGetter: ({ data }) =>
                    reviewStatusButtonTextMap[data.proof?.reviewStatus],
                hide: !isCollectVaccinationProofEnabled,
            },
            {
                colId: 'dateResponded',
                headerName: 'Date responded (UTC)',
                valueGetter: ({ data }) =>
                    data.updatedAt
                        ? format(new Date(data.updatedAt), 'MM/dd/yy')
                        : '',
            },
        ]
    }, [isCollectVaccinationProofEnabled, get])

    return (
        <Container fluid>
            <Header
                title="Vaccination log"
                description="View your employees' COVID-19 vaccine information."
            />
            <Row>
                <Col className="mb-3 d-flex justify-content-end">
                    <ReviewProofsInBulkButton
                        vaccinationLogRecordsToReview={
                            vaccinationLogs
                                ? getAllVaccinationRecordsToReview(
                                      vaccinationLogs
                                  )
                                : []
                        }
                        refreshData={get}
                    />
                    <DownloadCsvButton />
                </Col>
            </Row>
            <Row>
                <Col data-test="vaccination-log-grid" className="mb-5">
                    <AgGridWrapper
                        isLoading={isLoading}
                        rowData={vaccinationLogs}
                        noRowsOverlayComponentParams={{
                            description: 'No vaccination records found.',
                        }}
                        frameworkComponents={{
                            vaccinationStatusColumnRenderer: VaccinationStatusColumnRenderer,
                            actionsButtonColumnRenderer: ActionsButtonColumnRenderer,
                        }}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            resizable: true,
                            suppressMovable: true,
                            flex: 1,
                            sortable: true,
                            unSortIcon: true,
                        }}
                        enableCellTextSelection={true}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default VaccinationLog
