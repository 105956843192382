import React from 'react'
import styled from 'styled-components'

import colors from '../../styles/colors'

const Badge = styled.span`
    background: ${({ color }) => color || colors.grey};
    color: ${colors.text.dark};
    padding: 2px 7px;
    margin: 2px 2px;
    font-size: 0.75rem;
`

type Props = {
    'data-test'?: string
    label?: string
    color: string
    children?: string
}

const StatusBadge = ({
    'data-test': dataTest,
    label,
    color,
    children,
}: Props): JSX.Element => {
    return (
        <Badge
            data-test={dataTest}
            color={color}
            className="rounded-pill font-weight-bold"
        >
            {label || children}
        </Badge>
    )
}

export default React.memo(StatusBadge)
