import styled, { keyframes } from 'styled-components'

const movingGradient = keyframes`
0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
`

export const LoadingBar = styled.div`
    min-width: ${(props) => props.wide && '232px'};
    height: ${(props) => `${props.height || 18}px`};
    margin: 10px 0;
    background: linear-gradient(to right, #eee 20%, #d9dcdb 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: ${movingGradient};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
`
