import { createRequestInterceptor } from '../../util/requestInterceptors'

const {
    prepareFileForPost,
    fileRequestFetchOptions,
} = createRequestInterceptor()

export const defaultState = {
    file: undefined,
    rosterUploadPreview: undefined,
}

export const errorIds = [
    'INVALID_CSV_FORMAT',
    'ROSTER_DUPLICATE_EXTERNAL_IDENTIFIERS',
    'ROSTER_DUPLICATE_EMAILS',
]

export { prepareFileForPost }
export { fileRequestFetchOptions as fetchOptions }
