import { ButtonDropdown, DropdownItem } from 'reactstrap'
import React, { useState, useCallback } from 'react'
import { PropTypes } from 'prop-types'

import { StyledToggle, StyledDropdownMenu } from './styled'
import {
    past30Days,
    next30Days,
    get30DayDateRange,
} from '../../../hooks/useCheckInRangeFromUrl'
import useAnalytics from '../../../hooks/useAnalytics'

const CHECKIN_RANGE_OPTIONS = new Map([
    [past30Days, 'Past 30 days'],
    [next30Days, 'Next 30 days'],
])

const CheckInRangeDropdown = ({ onChange, checkInRange, checkInDateRange }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)
    const { track } = useAnalytics()

    const handleOptionClick = useCallback(
        (period) => {
            const currentCheckInDateRange = get30DayDateRange(period)
            track('check_in_trends_action', {
                action: 'Click',
                label: 'date_range_select',
                previous_date_range: checkInRange,
                current_date_range: period,
                previous_date_range_start: checkInDateRange.from,
                previous_date_range_end: checkInDateRange.until,
                current_date_range_start: currentCheckInDateRange.from,
                current_date_range_end: currentCheckInDateRange.until,
            })

            onChange({
                range: period,
            })
        },
        [checkInDateRange, checkInRange, track, onChange]
    )

    return (
        <ButtonDropdown
            onClick={() =>
                track('check_in_trends_action', {
                    action: 'Click',
                    label: 'date_range_drop_down',
                    date_range: checkInRange,
                })
            }
            className="mr-4"
            isOpen={isDropdownOpen}
            toggle={toggleDropdown}
            direction="down"
        >
            <StyledToggle
                outline
                color="primary"
                className="btn btn-outline-primary rounded"
            >
                {CHECKIN_RANGE_OPTIONS.get(checkInRange)}
            </StyledToggle>

            <StyledDropdownMenu>
                {[...CHECKIN_RANGE_OPTIONS.entries()].map(
                    ([period, periodText]) => {
                        return (
                            <DropdownItem
                                key={period}
                                onClick={() => handleOptionClick(period)}
                            >
                                {periodText}
                            </DropdownItem>
                        )
                    }
                )}
            </StyledDropdownMenu>
        </ButtonDropdown>
    )
}

CheckInRangeDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    checkInRange: PropTypes.string.isRequired,
    checkInDateRange: PropTypes.shape({
        from: PropTypes.string,
        until: PropTypes.string,
    }),
}

export default CheckInRangeDropdown
