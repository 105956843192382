import { startOfDay, addDays, getISODay } from 'date-fns'

type DayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thur' | 'Fri' | 'Sat' | 'Sun'

export function getPreviousISODay(
    dayOfWeek: DayOfWeek,
    fromDate: Date = startOfDay(new Date())
): Date {
    // getISODay format (7 for Sunday, 1 for Monday)
    const dayOfWeekMap = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thur: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
    }

    // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek

    // e.g. If today is Sunday, getISODay(fromDate) will returns 7
    // if the day we want to find is Thursday(4),we need to account for the days between Sunday(7) and Thursday(4)
    // Hence we need to subtract (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])
    const offsetDays = -(getISODay(fromDate) - dayOfWeekMap[dayOfWeek])

    return addDays(fromDate, offsetDays)
}
