import React from 'react'
import PropTypes from 'prop-types'

import { graphSectionAnalytics } from '../../../models/analytics'
import BarChart from '../BarChart'
import useAnalytics from '../../../hooks/useAnalytics'
import generatePathUrl from '../../../util/generatePathUrl'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import getXAxisLabel from '../../../util/chart/getXAxisLabel'

const BarChartSection = ({
    title,
    description,
    path,
    granularity,
    analytics,
}) => {
    const rangeSize = 'month'
    const { track } = useAnalytics()
    const { id: spaceId } = useSpaces()
    const urlPath = generatePathUrl(path, { spaceId })
    const xLabel = getXAxisLabel(rangeSize, granularity)

    const trackChartLoading = ({ loadingDuration, hasError }) => {
        track(analytics.category, {
            action: 'Load',
            label: 'chart_load_duration',
            milliseconds_to_load: loadingDuration,
            chart_name: analytics.name,
            loaded: !hasError,
        })
    }

    const trackChartViewing = ({ isVisible, chartState, isDataIncomplete }) => {
        if (!isVisible) {
            return
        }

        track(analytics.category, {
            action: 'View',
            label: analytics.viewLabel,
            chart_state: chartState,
            incomplete_data: isDataIncomplete,
            chart_name: analytics.name,
            // TODO: update params
        })
    }

    const trackChartTryAgain = () => {
        track(analytics.category, {
            action: 'Click',
            label: 'chart_try_again',
            chart_name: analytics.name,
            // TODO: update params
        })
    }

    const trackChartClick = ({ isRightClick }) => {
        track(analytics.category, {
            action: 'Click',
            is_right_click: isRightClick,
            label: analytics.clickLabel,
            // TODO: update params
        })
    }

    const trackTooltipHover = ({ tooltipValue, tooltipDate }) => {
        track(analytics.category, {
            action: 'Hover',
            label: 'chart_tooltip',
            chart_name: analytics.name,
            tooltip_value: tooltipValue,
            tooltip_date: tooltipDate,
            // TODO: update params
        })
    }

    return (
        <section className="mb-5">
            <h4>{title}</h4>
            <p className="text-muted mb-4">{description}</p>

            <BarChart
                urlPath={urlPath}
                rangeSize={rangeSize}
                granularity={granularity}
                xLabel={xLabel}
                yLabel={title}
                toolTipLabel={title}
                onChange={trackChartLoading}
                onVisible={trackChartViewing}
                onChartHover={trackTooltipHover}
                onChartClick={trackChartClick}
                onTryAgain={trackChartTryAgain}
            />
        </section>
    )
}

BarChartSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    granularity: PropTypes.oneOf(['daily', 'weekly']).isRequired,
    analytics: PropTypes.shape(graphSectionAnalytics).isRequired,
}

export default BarChartSection
