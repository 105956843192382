import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Form } from 'react-final-form'

import toast from '../../components/Toast/toast.tsx'
import { selectCurrentSpace } from '../../state/spaces'
import { buildingMaxCapacityValidator } from '../../util/validators'
import useAsync from '../../hooks/useAsync'
import useEmployeeExperienceResource from '../../hooks/useEmployeeExperienceResource/index.ts'

import FormTextInput from '../../components/FormTextInput'
import Button from '../../components/Button.tsx'

const DEFAULT_ERROR_MESSAGE = 'We could not save your information'

const EditDailyCheckInLimit = ({
    onClose,
    onCapacityUpdated,
    buildingId,
    buildingName,
    checkInCapacity,
}) => {
    const { id: spaceId } = useSelector(selectCurrentSpace)

    const { updateExperienceCapacity } = useEmployeeExperienceResource({
        spaceId,
        type: 'checkInCapacity',
    })

    const { exec, isLoading } = useAsync()

    const initialCheckInLimit = checkInCapacity?.value

    const handleFormSubmit = async (values) => {
        const { checkInLimit } = values

        if (parseInt(checkInLimit, 10) === initialCheckInLimit) {
            // Exit early, there are no changes
            onClose()
            return
        }

        // Save capacity
        try {
            const result = await exec(
                updateExperienceCapacity({
                    buildingId,
                    experienceId: checkInCapacity?.id,
                    capacity: checkInLimit,
                })
            )

            if (result?.error) {
                return {
                    checkInLimit:
                        (result.error.errorId && result.error.message) ||
                        DEFAULT_ERROR_MESSAGE,
                }
            }

            onCapacityUpdated({
                buildingId,
                updatedCheckInCapacityConfiguration: result,
            })

            toast.success('Check-in limit updated successfully')

            onClose()
        } catch (error) {
            return {
                checkInLimit: DEFAULT_ERROR_MESSAGE,
            }
        }
    }
    return (
        <>
            <ModalHeader toggle={onClose}>
                Edit daily check-in limit
            </ModalHeader>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={{
                    checkInLimit:
                        initialCheckInLimit === undefined
                            ? undefined
                            : `${initialCheckInLimit}`,
                }}
            >
                {({ handleSubmit, hasValidationErrors, hasSubmitErrors }) => (
                    <>
                        <ModalBody>
                            <form onSubmit={handleSubmit}>
                                <FormTextInput
                                    data-test="edit-check-in-limit-input"
                                    label={`${buildingName} daily check-in limit`}
                                    type="number"
                                    name="checkInLimit"
                                    bsSize="lg"
                                    showError
                                    min="1"
                                    validate={buildingMaxCapacityValidator}
                                />
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                block={false}
                                color="secondary"
                                onClick={onClose}
                                size="sm"
                            >
                                Cancel
                            </Button>
                            <Button
                                data-test="edit-check-in-limit-save-button"
                                block={false}
                                color="primary"
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                size="sm"
                                isDisabled={hasValidationErrors}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </>
    )
}

EditDailyCheckInLimit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCapacityUpdated: PropTypes.func.isRequired,
    checkInCapacity: PropTypes.shape({
        value: PropTypes.number,
        id: PropTypes.string,
    }),
    buildingId: PropTypes.string.isRequired,
    buildingName: PropTypes.string.isRequired,
}

export default EditDailyCheckInLimit
