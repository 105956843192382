import React, { useCallback, useEffect, useMemo } from 'react'
import { instanceOf, func, bool, shape, number, string } from 'prop-types'
import {
    geoToH3,
    kRing,
    h3IndexesAreNeighbors,
    h3SetToMultiPolygon,
    polyfill,
} from 'h3-js'
import { Button } from 'reactstrap'

import GhostLoader from '../../../components/GhostLoader'
import BuildingPerimeterMap from '../../../components/BuildingPerimeterMap'
import MapPlaceholder from '../../../components/BuildingPerimeterMap/Placeholder'
import { HEX_RESOLUTION } from '../../../components/BuildingPerimeterMap/utility'
import { buildingPolygonProp } from '../../../models/building'

const EditBuildingPerimeter = ({
    id,
    h3Indices,
    handleUpdateH3Indices,
    coordinates,
    isLoading,
    hasError,
    onTryAgain,
    hasAddress,
    initialBuildingPerimeterPolygon,
}) => {
    useEffect(() => {
        // When adding a building for the first time, there will not be an initialBuildingPerimeterPolygon
        if (coordinates && !initialBuildingPerimeterPolygon) {
            // Find the h3Index that maps to the initial coordinates
            const { latitude, longitude } = coordinates
            const initialH3Index = geoToH3(latitude, longitude, HEX_RESOLUTION)
            // Find the neighboring indices of that initial index
            const h3Neighbors = kRing(initialH3Index, 2)

            handleUpdateH3Indices(new Set(h3Neighbors))
        }
        // When editing a building, we can compute H3 indices from the initialBuildingPerimeterPolygon
        else if (initialBuildingPerimeterPolygon) {
            const h3IndicesFromPolygon = polyfill(
                initialBuildingPerimeterPolygon,
                HEX_RESOLUTION,
                true
            )

            handleUpdateH3Indices(new Set(h3IndicesFromPolygon))
        }
    }, [coordinates, initialBuildingPerimeterPolygon, handleUpdateH3Indices])

    const handleHexUpdate = useCallback(
        (hex) => {
            // Set is a mutable data structure so modifying won't trigger a state update
            // so you have to create a new one - https://stackoverflow.com/questions/58806883/how-to-use-set-with-reacts-usestate
            const updatedH3Indices = new Set(h3Indices)
            const isAlreadySelected = updatedH3Indices.has(hex.object)

            if (isAlreadySelected) {
                updatedH3Indices.delete(hex.object)
            } else {
                // Loop through the set and see if the selected hex is a neighbor.
                // The user can only add hexes that are neighbors of the polygon in order to keep
                // one contiguous geometry
                const isAdjacent = Array.from(
                    updatedH3Indices
                ).some((h3Index) => h3IndexesAreNeighbors(h3Index, hex.object))

                if (!isAdjacent) {
                    return
                }

                updatedH3Indices.add(hex.object)
            }

            const buildingPerimeterMultipolygon = h3SetToMultiPolygon(
                Array.from(updatedH3Indices),
                true
            )

            // Check to make sure that the selected h3 indices do NOT create two distinct polygons.
            // This is to prevent that removing a polygon does NOT create an island, i.e, a detached polygon
            if (buildingPerimeterMultipolygon.length > 1) {
                return
            }

            // Now check to make sure the single polygon does not contain a hole. This ensures one contiguous geometry
            const [buildingPerimeterPolygon] = buildingPerimeterMultipolygon
            if (
                !buildingPerimeterPolygon ||
                buildingPerimeterPolygon.length > 1
            ) {
                return
            }

            handleUpdateH3Indices(updatedH3Indices)
        },
        [h3Indices, handleUpdateH3Indices]
    )

    // Convert h3 index set to multipolygon.
    // Note that building perimeter is always a polygon.
    const [buildingPerimeterPolygon] = useMemo(
        () => h3SetToMultiPolygon(Array.from(h3Indices), true),
        [h3Indices]
    )

    if (hasError) {
        return (
            <MapPlaceholder className="d-flex flex-column align-items-center justify-content-center ">
                <h5>Unable to load the map</h5>
                <Button onClick={() => onTryAgain()} color="primary">
                    Try Again
                </Button>
            </MapPlaceholder>
        )
    }

    if (isLoading) {
        return (
            <MapPlaceholder>
                <GhostLoader height={25} />
            </MapPlaceholder>
        )
    }

    if ((!coordinates && !initialBuildingPerimeterPolygon) || !hasAddress) {
        return (
            <MapPlaceholder>
                <p className="text-muted" test-attr="placeholder-text">
                    Enter an address under building details to see the building
                    perimeter
                </p>
            </MapPlaceholder>
        )
    }

    return (
        <BuildingPerimeterMap
            id={id}
            isEditable={true}
            buildingPerimeterPolygon={buildingPerimeterPolygon}
            selectedH3Indices={h3Indices}
            onHexUpdate={handleHexUpdate}
            initialCoordinates={coordinates}
        />
    )
}

EditBuildingPerimeter.propTypes = {
    id: string,
    coordinates: shape({
        longitude: number.isRequired,
        latitude: number.isRequired,
    }),
    hasAddress: bool,
    isLoading: bool,
    h3Indices: instanceOf(Set), // This is a Set of strings
    handleUpdateH3Indices: func,
    hasError: bool,
    onTryAgain: func,
    initialBuildingPerimeterPolygon: buildingPolygonProp,
}

export default EditBuildingPerimeter
