import React from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { Router, RouteComponentProps } from '@reach/router'

import Reports from '../Reports'
import Example from '../Example'
import { Month } from '../../../types'

const fetchOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
}

export type Labels = {
    exampleMonthlyTitle: string
    exampleMonthlyDescription: string
    exampleYearlyTitle: string
    exampleYearlyDescription: string
    title: string
    description: string
    modalContent: {
        title: string
        body: string
    }
}

type Props = {
    spaceId: string
    reportsName: 'carpoolers' | 'trips'
    labels: Labels
    isYearToDateSupported?: boolean
    'data-test'?: string
    analytics: {
        prefix: string
    }
    expectedNumColumnsInExample: {
        monthly: Month
        yearly: number
    }
} & RouteComponentProps

const ReportsRouter = ({
    spaceId,
    reportsName,
    labels,
    isYearToDateSupported,
    'data-test': dataTest,
    analytics,
    expectedNumColumnsInExample,
}: Props): JSX.Element => {
    const metaPath = `/spaces/${spaceId}/reports/meta`
    const { data, loading: isMetaLoading, get: fetchMeta } = useFetch(
        metaPath,
        fetchOptions,
        []
    )

    const meta = data && data[reportsName]
    const monthlySampleData = meta?.sampleData?.monthly
    const yearlySampleData = meta?.sampleData?.yearly

    return (
        <Router primary={false} data-test={dataTest}>
            <Reports
                default
                labels={labels}
                reportsName={reportsName}
                refreshedAt={meta?.refreshedAt}
                isYearToDateSupported={isYearToDateSupported}
                analytics={analytics}
            />

            <Example
                path="example/monthly"
                data={monthlySampleData}
                isLoading={isMetaLoading}
                fetchData={fetchMeta}
                title={labels.exampleMonthlyTitle}
                description={labels.exampleMonthlyDescription}
                expectedNumColumns={expectedNumColumnsInExample.monthly}
            />

            <Example
                path="example/yearly"
                data={yearlySampleData}
                isLoading={isMetaLoading}
                fetchData={fetchMeta}
                title={labels.exampleYearlyTitle}
                description={labels.exampleYearlyDescription}
                expectedNumColumns={expectedNumColumnsInExample.yearly}
            />
        </Router>
    )
}

export default ReportsRouter
