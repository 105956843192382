import moment from 'moment'
import React from 'react'
import _ from 'lodash'

import { YearsData } from './formatWholeYearTimeSeries'
import LastUpdatedTimeForTrips from '../../../components/LastUpdatedTime/LastUpdatedTimeForTrips'
import {
    StaleData,
    StillPopulating,
    YearToDate,
    UnavailableData,
} from '../DataNotYetReady'

type Props = {
    arrayOfYears: YearsData
    lastUpdatedTimestamp?: string
    analyticsCategory: string
}

/**
 * Mutates the arrayOfYears to address edge cases where lastUpdatedTimeStamp is either
 * 1. over 48 hours old
 * 2. equal to "null"
 * 3. in the previous year
 *
 * This function indiscriminately formats the first year with a timestamp.
 * If one of the above edge cases applies, it will clone the first year and format it accordingly
 *
 * @param arrayOfYears - Should be in reverse chronological order e.g., [ { period: { year: 2020 }}, { period: { year: 2019 }} ].
 * @param lastUpdatedTimestamp - the datetime at which the last data rollup occurred
 * @param analyticsCategory
 *
 */
function formatYearToDateTimeSeries({
    arrayOfYears,
    lastUpdatedTimestamp,
    analyticsCategory,
}: Props): YearsData {
    const firstYear = { ...arrayOfYears[0] }
    const arrayOfYearsClone = _.cloneDeep(arrayOfYears)

    // Add "year-to-date" and time stamp to first row
    arrayOfYearsClone[0] = {
        ...firstYear,
        extraInfo: <YearToDate />,
        extraInfoRight: (
            <LastUpdatedTimeForTrips
                timestamp={lastUpdatedTimestamp}
                analytics={{
                    category: analyticsCategory,
                    period: { year: firstYear.period.year },
                }}
            />
        ),
    }

    const today = moment()
    const momentRefreshedAt = moment(lastUpdatedTimestamp)
    const time48HoursAgo = moment().subtract(2, 'days')
    const isRefreshedAtBeforeLast48Hours = momentRefreshedAt.isBefore(
        time48HoursAgo
    )

    const isRefreshedAtInPreviousYear = momentRefreshedAt.isBefore(
        today,
        'year'
    )

    // For the following conditions,
    // * we clone the first row,
    // * disable the clone,
    // * add explanation text,
    // * and insert it after the first row

    // Timestamp is over 48 hours old,
    if (isRefreshedAtBeforeLast48Hours) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: <StaleData />,
            isDisabled: true,
        }

        return [
            arrayOfYearsClone[0],
            firstYearDuplicate,
            ...arrayOfYearsClone.slice(1),
        ]
    }

    if (lastUpdatedTimestamp === null) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: <UnavailableData />,
            isDisabled: true,
        }

        return [
            arrayOfYearsClone[0],
            firstYearDuplicate,
            ...arrayOfYearsClone.slice(1),
        ]
    }

    // Timestamp is in previous year
    if (isRefreshedAtInPreviousYear) {
        const firstYearDuplicate = {
            ...firstYear,
            extraInfo: <StillPopulating />,
            isDisabled: true,
        }

        return [
            arrayOfYearsClone[0],
            firstYearDuplicate,
            ...arrayOfYearsClone.slice(1),
        ]
    }

    return arrayOfYearsClone
}

export default formatYearToDateTimeSeries
