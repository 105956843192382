import {
    getMonth,
    getQuarter,
    getWeek,
    getYear,
    isBefore,
    parseISO,
    setMonth,
    setQuarter,
    setWeek,
    setYear,
} from 'date-fns'
import { getDateRange, getPeriodFromDateString, startOf } from './date'

type Period = Partial<{
    year: number
    quarter: number
    month: number
    week: number
}>

type RangeTypes = 'year' | 'quarter' | 'month' | 'week'

/**
 * Returns the new period based on the previous rangeSize and new rangeSize
 * The new period will be the earliest valid position, taking into account the currentRange, new rangeSize,
 * earliestStartDate and currentPeriod.
 *
 * Example
 * earliestStartDate = '2019-03-01'
 * currentPeriod = { year: 2020, week: 18 } // 04-27-20 - 05-03-20
 * currentRangeSize = 'year'
 * newRangeSize = 'week'
 *
 * The user is switching from 'year' to 'week' so the function will return the earliest week
 * given the current year. The new time period will be { year: 2020, week: 1 }.
 *
 * But if earliestStartDate = '2020-03-01', then returning { year: 2020, week: 1 } will not work.
 * It will instead return the period that corresponds with the earliestStartDate: { year: 2020, week: 9 }
 *
 *
 * @param {Date} earliestStartDate - 2020-02-03
 * @param {Object} currentPeriod - { year: 2020, quarter: any, month: any, week: any} note: quarter/month/week must be mutually exclusive
 * @param {String} currentRangeSize - 'year'
 * @param {String} newRangeSize - 'week'
 */

export function getUpdatedPeriod({
    earliestStartDate,
    currentPeriod,
    currentRangeSize,
    newRangeSize,
}: {
    earliestStartDate: string
    currentPeriod: Period
    currentRangeSize: RangeTypes
    newRangeSize: RangeTypes
}): Period {
    let updatedPeriod = { ...currentPeriod }

    // First check if fromDate precedes earliest start date since we will use it
    // to calculate new period. It cannot precede earliestStartDate
    const { fromDate } = getDateRange(updatedPeriod) as {
        fromDate: string
        untilDate: string
    }
    const isFromDateBeforeEarliestDate = isBefore(
        parseISO(fromDate),
        parseISO(earliestStartDate)
    )

    if (isFromDateBeforeEarliestDate) {
        const period = getPeriodFromDateString(earliestStartDate)

        updatedPeriod = {
            [newRangeSize]: period[newRangeSize],
            year: period.year,
        }

        return updatedPeriod
    }

    // Otherwise, calculate new period based on new rangeSize
    let rangeSizeDate: Date
    const year = getYear(parseISO(fromDate))
    if (currentRangeSize === 'year') {
        rangeSizeDate = setYear(parseISO(fromDate), currentPeriod.year!)
    } else if (currentRangeSize === 'quarter') {
        rangeSizeDate = setQuarter(parseISO(fromDate), currentPeriod.quarter!)
    } else if (currentRangeSize === 'month') {
        rangeSizeDate = setMonth(parseISO(fromDate), currentPeriod.month!)
    } else if (currentRangeSize === 'week') {
        rangeSizeDate = setWeek(parseISO(fromDate), currentPeriod.week!)
    } else {
        return updatedPeriod
    }

    if (newRangeSize === 'week') {
        const newWeek = getWeek(startOf(rangeSizeDate, currentRangeSize))

        updatedPeriod = {
            week: newWeek,
            year,
        }
    } else if (newRangeSize === 'month') {
        const newMonth = getMonth(startOf(rangeSizeDate, currentRangeSize))

        updatedPeriod = {
            month: newMonth,
            year,
        }
    } else if (newRangeSize === 'quarter') {
        const newQuarter = getQuarter(startOf(rangeSizeDate, currentRangeSize))

        updatedPeriod = {
            quarter: newQuarter,
            year,
        }
    } else {
        updatedPeriod = {
            year,
        }
    }

    return updatedPeriod
}
