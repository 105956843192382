import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'

import { useCommute } from '../../../providers/CommuteProvider'
import { useSpaces } from '../../../providers/SpacesProvider.tsx'
import DropdownToggle from '../../../components/DropdownToggle'

import { getRangeSizeFromPeriod } from '../../../util/date'
import { getUpdatedPeriod } from '../../../util/getUpdatedPeriod.ts'

const RangeSizeDropdownMenu = styled(DropdownMenu)`
    min-width: 135px;
`

const RangeSizeToggle = styled(DropdownToggle)`
    min-width: 135px;
`

const rangeSizeOptions = {
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
}

const RangeSizeDropdown = ({
    // UI Events
    // These events return updated period ({year, quarter, month, week}) as a parameter
    onDropdownToggleClick = () => {},
    onDropdownItemClick = () => {},
}) => {
    const { changePeriod, period } = useCommute()
    const { tripsStartDate } = useSpaces()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)

    const currentRangeSize = getRangeSizeFromPeriod(period)

    function handleDropdownItemClick(newRangeSize) {
        const updatedPeriod = getUpdatedPeriod({
            earliestStartDate: tripsStartDate,
            currentPeriod: period,
            newRangeSize: newRangeSize,
            currentRangeSize,
        })

        changePeriod(updatedPeriod)
    }

    return (
        <ButtonDropdown
            className="mr-4"
            isOpen={isDropdownOpen}
            toggle={toggleDropdown}
            direction="down"
        >
            <RangeSizeToggle
                outline
                color="primary"
                className="btn btn-outline-primary rounded"
                onClick={() => {
                    onDropdownToggleClick(period)
                }}
            >
                {rangeSizeOptions[currentRangeSize]}
            </RangeSizeToggle>

            <RangeSizeDropdownMenu>
                {Object.entries(rangeSizeOptions).map(
                    ([rangeSize, rangeSizeText]) => {
                        return (
                            <DropdownItem
                                key={rangeSize}
                                onClick={() => {
                                    handleDropdownItemClick(rangeSize)
                                    onDropdownItemClick(
                                        currentRangeSize,
                                        rangeSize
                                    )
                                }}
                            >
                                {rangeSizeText}
                            </DropdownItem>
                        )
                    }
                )}
            </RangeSizeDropdownMenu>
        </ButtonDropdown>
    )
}

RangeSizeDropdown.propTypes = {
    // UI Events
    onDropdownToggleClick: PropTypes.func.isRequired,
    onDropdownItemClick: PropTypes.func.isRequired,
}
export default RangeSizeDropdown
