import { CachePolicies, useFetch } from 'use-http'
import { useMemo } from 'react'
import {
    CosmosError,
    ResponseError,
    TestingLogRecordResource,
} from '../../types'

const fetchOptions = {
    headers: {
        Accept: 'application/json',
    },
    cachePolicy: CachePolicies.NO_CACHE,
}

type TestResultsLogResource = {
    isLoading: boolean
    testingLog?: TestingLogRecordResource[]
    error?: Error | CosmosError
    totalCount: number
    get: () => Promise<TestingLogRecordResource[]>
}

/**
 *
 * @params spaceId - space alias of the target customer.
 * @params dependencies - dependency array of the hook. Each change will trigger a new request to the API.
 */
export default function useTestingLogResource(
    spaceId: string,
    dependencies?: []
): TestResultsLogResource {
    const { data, loading, error: networkError, get } = useFetch<
        TestingLogRecordResource[] | ResponseError
    >(`/spaces/${spaceId}/testResultsLog`, fetchOptions, dependencies)

    const testingLog: TestingLogRecordResource[] = useMemo(() => {
        if (Array.isArray(data)) {
            return data
        }

        return []
    }, [data])
    const totalCount = testingLog.length

    const error = useMemo(
        () => (data as ResponseError)?.error || networkError,
        [data, networkError]
    )

    return {
        isLoading: loading,
        testingLog,
        totalCount,
        error,
        get,
    }
}
