import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import useAnalytics from '../../hooks/useAnalytics'
import { createCarpoolerDeactivateRequest } from '../../state/carpoolers'
import { selectCurrentSpace } from '../../state/spaces'
import { addApostropheS } from '../../util/grammar'

const DeactivateCarpooler = ({ onClose, displayEmail, carpoolerId }) => {
    const { track } = useAnalytics()
    const dispatch = useDispatch()
    const { publicDisplayName: companyName } = useSelector(selectCurrentSpace)

    function handleDeactivate() {
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'deactivate_confirm',
        })
        dispatch(createCarpoolerDeactivateRequest({ carpoolerId }))
        onClose()
    }

    function handleCancel() {
        track('edit_carpoolers_action', {
            action: 'Click',
            label: 'deactivate_cancel',
        })
        onClose()
    }

    return (
        <>
            <ModalHeader toggle={handleCancel}>
                Are you sure you want to deactivate this commuter?
            </ModalHeader>
            <ModalBody>
                <p>
                    Deactivating{' '}
                    <strong className="text-break">{displayEmail}</strong> means
                    this commuter can no longer bill Scoop trips to{' '}
                    <strong>{addApostropheS(companyName)}</strong> account.
                </p>
                <p>
                    Although the commuter associated with this email and any
                    alternate emails will be removed from your Scoop program,
                    they can still use their personal Scoop account.
                </p>
                <p>
                    Once deactivated, this commuter will move to the{' '}
                    <strong>Deactivated commuters</strong> tab.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleDeactivate}>
                    Confirm
                </Button>
            </ModalFooter>
        </>
    )
}

DeactivateCarpooler.propTypes = {
    onClose: PropTypes.func,
    displayEmail: PropTypes.string,
    carpoolerId: PropTypes.string,
}

export default DeactivateCarpooler
