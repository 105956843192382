import { useMatch } from '@reach/router'
import React, { useRef } from 'react'
import { Button } from 'reactstrap'

import WorkplaceCheckInPanel from './Panel'
import { PANEL_WIDTH, generatePDF } from './util'
import useFetchCheckInById from '../../hooks/useFetchCheckInById'
import useSpaces from '../../hooks/useSpaces'

const WorkplaceCheckIn = (): JSX.Element => {
    const printablePanelRef = useRef(null)
    const { checkInId }: { [param: string]: string } =
        useMatch('/spaces/:spaceAlias/workplace/check-ins/:checkInId') || {}

    const { id: spaceId } = useSpaces()

    const { data, error, loading } = useFetchCheckInById({
        checkInId,
        spaceId,
    })

    return (
        <div className="p-5">
            <WorkplaceCheckInPanel
                workAttendanceIndication={data}
                error={error}
                loading={loading}
                onTryAgainClick={() => window.location.reload()}
                printableElementRef={printablePanelRef}
            />

            {data && (
                <div
                    className="mt-5 d-flex justify-content-center"
                    style={{ width: PANEL_WIDTH }}
                >
                    <Button
                        color="primary"
                        className="py-2 px-5"
                        onClick={() =>
                            generatePDF(printablePanelRef.current, data)
                        }
                        test-attr="download-pdf-button"
                    >
                        Download PDF
                    </Button>
                </div>
            )}
        </div>
    )
}

export default WorkplaceCheckIn
