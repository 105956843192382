import React from 'react'

import { shape, string } from 'prop-types'
import useAnalytics from '../../../hooks/useAnalytics'
import ModuleFlag from '../../ModuleFlag'
import ReportsSideBar from '../ReportsSideBar'
import SideBarLink from '../SideBarLink'

const COMMUTE_MODULES = Object.freeze([
    'overview',
    'activityGraphs',
    'carpoolerReports',
    'tripReports',
])

const CommuteSideBar = ({ space }) => {
    const { track } = useAnalytics()

    return (
        <ModuleFlag
            modules={{
                names: COMMUTE_MODULES,
                comparator: 'some',
            }}
        >
            <strong className="d-block ml-3 py-2">Commute</strong>
            <SideBarLink
                text="Overview"
                to={`${space.alias}/commute/overview`}
            />

            <ModuleFlag name="activityGraphs">
                <SideBarLink
                    text="Trip Activity"
                    to={`${space.alias}/commute/activity/trip`}
                    onClick={() => {
                        track('trip_activity_action', {
                            action: 'Click',
                            label: 'trip_activity_sidebar',
                        })
                    }}
                />
                <SideBarLink
                    text="Commuter Activity"
                    to={`${space.alias}/commute/activity/commuter`}
                    onClick={() => {
                        track('carpooler_activity_action', {
                            action: 'Click',
                            label: 'carpooler_activity_sidebar',
                        })
                    }}
                />
            </ModuleFlag>

            <ReportsSideBar space={space} />
        </ModuleFlag>
    )
}

CommuteSideBar.propTypes = {
    space: shape({
        alias: string,
    }).isRequired,
}

export default CommuteSideBar
