import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import getColumnHeaders from './column-headers'
import Button from '../../components/Button.tsx'
import TotalPageCount from '../../components/TotalPageCount/index.tsx'
import EmailWithPopover from '../../components/EmailWithPopover'
import ErrorTryAgain from '../../components/MemberManagementStates/ErrorTryAgain.tsx'
import DataNotReady from '../../components/MemberManagementStates/DataNotReady'
import NoCarpoolersActive from '../../components/MemberManagementStates/NoCarpoolersActive'
import NoSearchResultsActive from '../../components/MemberManagementStates/NoSearchResultsActive'
import MenuDropdownWithActions from '../../components/MenuDropdownWithActions/index.tsx'
import Pagination from '../../components/Pagination/index.tsx'
import SearchBar from '../../components/SearchBar/index.tsx'
import SpinnerFullPage from '../../components/SpinnerFullPage'
import Table from '../../components/Table/index.tsx'

import useAnalytics from '../../hooks/useAnalytics'
import useCallOnceWhenDefined from '../../hooks/useCallOnceWhenDefined'
import useCarpoolers, { sortByOptions } from '../../hooks/useCarpoolers'
import { sortOrderOptions } from '../../util/sort'
import usePagination from '../../hooks/usePagination/index.ts'
import useToggleThroughStates from '../../hooks/useToggleThroughStates'

import { PAGE_SIZE } from '../../sagas/activatedCarpoolers'
import {
    createCarpoolerPaginationRequest,
    selectCarpoolerCurrentPage,
    selectCarpoolerTotalCount,
    selectDownloadActivatedCarpoolersRequest,
    selectQueryFilterParameters,
} from '../../state/carpoolers'
import { createOpenModal } from '../../state/modal/actions'

import withReloadable from '../../hocs/withReloadable'
import { Header } from '../../components/PageLayout'

// Column sort order states should transition in the following order.
const sortOrderStates = [
    sortOrderOptions.descending,
    sortOrderOptions.ascending,
]

const MemberManagementActivated = ({
    spaceAlias,
    spaceId,
    publicDisplayName,
}) => {
    const dispatch = useDispatch()
    const { track } = useAnalytics()

    const {
        isFetching: memberManagementIsFetching,
        error: memberManagementError,
        sortBy,
        sortOrder,
        query,
        carpoolerData,
        changeSearchQueryParams,
    } = useCarpoolers()

    // Define all the UI states
    const isPageLoading = carpoolerData === null && !memberManagementError
    const isCarpoolersLoading =
        Boolean(memberManagementIsFetching) &&
        carpoolerData !== null &&
        !memberManagementError
    const isEmptyCarpoolers =
        !query &&
        !memberManagementIsFetching &&
        carpoolerData &&
        carpoolerData.length === 0 &&
        !memberManagementError
    const isEmptySearch =
        query &&
        !memberManagementIsFetching &&
        carpoolerData &&
        carpoolerData.length === 0 &&
        !memberManagementError

    const { queryToken } = useSelector(selectQueryFilterParameters)
    const currentPage = useSelector(selectCarpoolerCurrentPage)
    const totalCount = useSelector(selectCarpoolerTotalCount)

    const handlePageChange = useCallback(
        (currentPage) =>
            dispatch(createCarpoolerPaginationRequest(currentPage)),
        [dispatch]
    )
    const dataSize =
        (carpoolerData ? carpoolerData.length : 0) + (queryToken ? 1 : 0) // add one extra page if we have a query token
    const {
        showPrevious,
        showNext,
        pagesToShow,
        fromResult,
        toResult,
    } = usePagination({
        dataSize,
        pageSize: PAGE_SIZE,
        initialPage: currentPage,
    })

    const INITIAL_SORT_ORDER_STATE = sortOrderOptions.descending
    const INITIAL_SORT_BY_STATE = sortByOptions.lastTripAt

    const [currentSortBy, setCurrentSortBy] = useState(INITIAL_SORT_BY_STATE)

    const {
        state: currentSortOrder,
        toggleState: toggleSortOrderState,
    } = useToggleThroughStates({
        states: sortOrderStates,
        initialState: INITIAL_SORT_ORDER_STATE,
    })

    const toggleFirstWeekState = () => {
        setCurrentSortBy(sortByOptions.firstTripAt)

        if (currentSortBy === sortByOptions.firstTripAt) {
            toggleSortOrderState()
        }
    }
    const toggleLastWeekState = () => {
        setCurrentSortBy(sortByOptions.lastTripAt)

        if (currentSortBy === sortByOptions.lastTripAt) {
            toggleSortOrderState()
        }
    }

    // Hook for handling changes to both sort order and sort by
    // TODO: This logic should be encapsulated in the useCarpoolers hook
    useEffect(() => {
        changeSearchQueryParams({
            query, // Query does not change
            sortBy: currentSortBy,
            sortOrder: currentSortOrder,
        })
    }, [changeSearchQueryParams, query, currentSortBy, currentSortOrder])

    const { isFetching: downloadCsvLoading } = useSelector(
        selectDownloadActivatedCarpoolersRequest
    )
    const placeHolderRowAmount = carpoolerData?.length || PAGE_SIZE

    const transformCarpoolerData = (carpoolers) =>
        carpoolers
            .slice(fromResult - 1, toResult)
            .map(
                (
                    { orderedEmails, firstTripWeek, lastTripWeek, actions },
                    index
                ) => [
                    <EmailWithPopover
                        key={orderedEmails && orderedEmails[0]}
                        emails={orderedEmails}
                        index={index}
                        handleTrackEvent={() =>
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'expand_account_emails',
                            })
                        }
                    />,
                    firstTripWeek,
                    lastTripWeek,
                    <MenuDropdownWithActions
                        dropdownItems={actions}
                        key={actions.toString()}
                        onClick={() =>
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'actions_gear',
                            })
                        }
                    />,
                ]
            )

    const searchBar = (
        <SearchBar
            initialValue={query}
            onSearch={(query) =>
                changeSearchQueryParams({
                    query,
                    sortBy: undefined,
                    sortOrder: undefined,
                })
            }
            onClear={() =>
                changeSearchQueryParams({
                    query: undefined,
                    sortBy: undefined,
                    sortOrder: undefined,
                })
            }
            isSearching={memberManagementIsFetching}
            onSearchClick={() =>
                track('edit_carpoolers_action', {
                    action: 'Click',
                    label: 'search_bar_click_search',
                })
            }
        />
    )

    useCallOnceWhenDefined(() => {
        track('edit_carpoolers_action', {
            action: 'View',
            label: 'active_carpoolers',
        })
    }, spaceId)

    if (isPageLoading) {
        return <SpinnerFullPage currentPage="active" />
    }

    if (isEmptyCarpoolers) {
        return (
            <NoCarpoolersActive
                spaceAlias={spaceAlias}
                companyName={publicDisplayName}
            />
        )
    }

    if (isEmptySearch) {
        return (
            <Container size="xl">
                <Header title="Active commuters" />
                <div className="w-50">{searchBar}</div>
                <Row>
                    <Col
                        md={{ size: 6, offset: 3 }}
                        sm={{ size: 6, offset: 3 }}
                        className="d-flex flex-column justify-content-center"
                    >
                        <NoSearchResultsActive spaceAlias={spaceAlias} />
                    </Col>
                </Row>
            </Container>
        )
    }

    if (memberManagementError?.errorId === 'SPACE_NOT_READY') {
        return (
            <DataNotReady
                spaceAlias={spaceAlias}
                companyName={publicDisplayName}
            />
        )
    }

    if (memberManagementError) {
        return (
            <Container size="xl">
                <Header title="Active commuters" />
                <div className="w-50">{searchBar}</div>
                <ErrorTryAgain errorText="We couldn't load your commuters" />
            </Container>
        )
    }

    return (
        <Container size="xl">
            <Header title="Active commuters" />
            <Row className="mb-4 mx-0 justify-content-between">
                <Col className="px-0">{searchBar}</Col>
                <Col className="px-0 text-right">
                    <Button
                        color="primary"
                        onClick={() => {
                            track('edit_carpoolers_action', {
                                action: 'Click',
                                label: 'download_csv',
                            })
                            dispatch(
                                createOpenModal({
                                    name: 'DOWNLOAD_CSV',
                                    data: { status: 'active' },
                                })
                            )
                        }}
                        label="Download CSV"
                        loadingLabel="Preparing CSV"
                        size="md"
                        block={false}
                        isLoading={downloadCsvLoading}
                    />
                </Col>
            </Row>
            <Row className="mb-2 mx-0">
                <TotalPageCount totalCount={totalCount} />
            </Row>
            <Table
                className="bg-white px-0 mb-4"
                columnHeaders={getColumnHeaders({
                    toggleFirstWeekState,
                    toggleLastWeekState,
                    sortBy,
                    sortOrder,
                    track,
                })}
                rows={carpoolerData}
                transformRows={transformCarpoolerData}
                isLoading={isCarpoolersLoading}
                placeHolderRowAmount={placeHolderRowAmount}
            />
            <Row className="mb-4 mx-0">
                <TotalPageCount
                    totalCount={totalCount}
                    fromResult={fromResult}
                    toResult={toResult}
                />
                <Pagination
                    className="ml-auto"
                    showPrevious={showPrevious}
                    showNext={showNext}
                    currentPage={currentPage}
                    pagesToShow={pagesToShow}
                    onPageChange={handlePageChange}
                    onPreviousClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'previous_page',
                        })
                    }
                    onNextClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'next_page',
                        })
                    }
                    onPageNumberClick={() =>
                        track('edit_carpoolers_action', {
                            action: 'Click',
                            label: 'page_number',
                        })
                    }
                />
            </Row>
        </Container>
    )
}

MemberManagementActivated.propTypes = {
    spaceId: PropTypes.string.isRequired,
    publicDisplayName: PropTypes.string.isRequired,
    spaceAlias: PropTypes.string.isRequired,
}

export default withReloadable(MemberManagementActivated)
