import React from 'react'
import { Row, Col } from 'reactstrap'
import { node, bool } from 'prop-types'

CenteredLayout.defaultProps = {
    squished: false,
}
export function CenteredLayout(props) {
    const columnSizes = props.squished ? { md: 4, sm: 8 } : { md: 5, sm: 8 }

    return (
        <Row className="justify-content-center mt-5">
            <Col {...columnSizes}>{props.children}</Col>
        </Row>
    )
}
CenteredLayout.propTypes = {
    children: node,
    squished: bool,
}

export default CenteredLayout
