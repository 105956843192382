import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'

const StyledDiv = styled.div`
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    overflow: scroll;
`

const StyledButton = styled.button`
    position: absolute;
    background: none;
    border: none;
    top: 0;
`
const LeftButton = styled(StyledButton)`
    left: 0;
`

const RightButton = styled(StyledButton)`
    right: 0;
`

export type Props = {
    onClickBack: () => void
    onClose: () => void
    hideBackButton?: boolean
    hideCloseButton?: boolean
    children?: React.ReactNode
}

const FullPageModal = ({
    onClickBack,
    onClose,
    hideBackButton = false,
    hideCloseButton = false,
    children,
}: Props): JSX.Element => (
    <StyledDiv data-test="full-page-modal">
        {!hideBackButton && (
            <LeftButton
                data-test="back-button"
                onClick={onClickBack}
                className="m-3"
            >
                <Icon name="arrow-left" className="text-muted" size="2x" />
            </LeftButton>
        )}
        {children}
        {!hideCloseButton && (
            <RightButton
                data-test="close-button"
                onClick={onClose}
                className="m-3"
            >
                <Icon
                    name="times-circle-regular"
                    className="fal text-muted"
                    size="2x"
                />
            </RightButton>
        )}
    </StyledDiv>
)

export default FullPageModal
