import { Link } from '@reach/router'
import { string } from 'prop-types'
import React from 'react'

const className =
    'd-flex flex-column justify-content-center align-items-center text-dark text-decoration-none border-success py-2 my-1'

function getPartiallyActive({ isPartiallyCurrent }) {
    return {
        className: `${className} ${isPartiallyCurrent ? 'pl-4' : 'px-4'}`,

        style: {
            ...(isPartiallyCurrent && {
                borderRight: '0.25rem solid',
                backgroundColor: '#E5F7F1',
                paddingRight: '0.25rem',
            }),
        },
    }
}

const SideBarLink = ({ text, ...linkProps }) => {
    return (
        <Link getProps={getPartiallyActive} {...linkProps}>
            <span className="d-inline-block w-100">{text}</span>
        </Link>
    )
}

SideBarLink.propTypes = {
    text: string.isRequired,
}

export default SideBarLink
