export const sortOrderOptions = { ascending: 'asc', descending: 'desc' }

const { ascending, descending } = sortOrderOptions

/**
 * Get the sort order of `sortBy` in the given `sortOrder`
 *
 * @param {String} sortBy - the field name to be sorted, such as 'email'
 * @param {Object} sortOptions - { sortBy, sortOrder }
 * @returns Either 'asc' or 'desc' if sort order is defined. undefined if not.
 */
export function getSortOrder(sortBy, sortOptions) {
    const sortOrder =
        sortBy === sortOptions?.sortBy ? sortOptions.sortOrder : undefined

    return sortOrder
}

/**
 * Get the toggled sort order
 *
 * @param {String} sortBy - the field name to be sorted, such as 'email'
 * @param {Object} sortOptions - { sortBy, sortOrder }
 * @returns - 'asc' if the sort order was 'desc' or undefined
 *          - 'desc' if the sort order was 'asc'
 */
export function getToggledSortOrder(sortBy, sortOptions) {
    const sortOrder = getSortOrder(sortBy, sortOptions) || descending

    return sortOrder === descending ? ascending : descending
}
